import { ACrypto } from "../classes/ACrypto.js";
export class ACCCClientSettings {
    constructor() {
        this.s = "29282171-f8ec-43a1-9168-42be84394721";
    }
    loadItem(key, parse, defaultValue, settingsInfo) {
        let value = localStorage.getItem(key);
        if (value !== null) {
            if (!parse)
                return value;
            try {
                return JSON.parse(value);
            }
            catch (e) {
                console.error(e);
            }
        }
        if (settingsInfo[key] == undefined || settingsInfo[key].DefaultValue == undefined) {
            return defaultValue;
        }
        return settingsInfo[key].DefaultValue;
    }
    async load() {
        this.settingsInfo = await $.getJSON("/settings.json");
        this.UseEncryption = this.loadItem("UseEncryption", true, false, this.settingsInfo);
        if (!ACrypto.subtleAvailable()) {
            this.Key = this.loadItem("Key", false, "", this.settingsInfo);
        }
        else {
            let KeyT = localStorage.getItem("Key");
            if (KeyT !== null) {
                localStorage.removeItem("Key");
                localStorage.setItem("KeyS", await ACrypto.encrypt(KeyT, this.s));
            }
            const KeyS = localStorage.getItem("KeyS");
            this.Key = KeyS == null ? "" : await ACrypto.decrypt(KeyS, this.s);
        }
        this.NodeType = this.loadItem("NodeType", false, "", this.settingsInfo);
        this.NodeIndexNumber = this.loadItem("NodeIndexNumber", true, 0, this.settingsInfo);
        this.NodeCustomerNumber = this.loadItem("NodeCustomerNumber", true, 0, this.settingsInfo);
        this.NodeProjectNumber = this.loadItem("NodeProjectNumber", true, 0, this.settingsInfo);
    }
    values() {
        return {
            UseEncryption: this.UseEncryption,
            Key: this.Key,
            NodeType: this.NodeType,
            NodeIndexNumber: this.NodeIndexNumber,
            NodeCustomerNumber: this.NodeCustomerNumber,
            NodeProjectNumber: this.NodeProjectNumber
        };
    }
    async store() {
        localStorage.setItem("UseEncryption", JSON.stringify(this.UseEncryption));
        if (!ACrypto.subtleAvailable()) {
            localStorage.setItem("Key", this.Key);
        }
        else {
            localStorage.setItem("KeyS", await ACrypto.encrypt(this.Key, this.s));
        }
        localStorage.setItem("NodeType", this.NodeType);
        localStorage.setItem("NodeIndexNumber", JSON.stringify(this.NodeIndexNumber));
        localStorage.setItem("NodeCustomerNumber", JSON.stringify(this.NodeCustomerNumber));
        localStorage.setItem("NodeProjectNumber", JSON.stringify(this.NodeProjectNumber));
    }
    async apply(newSettings) {
        if (newSettings["UseEncryption"] !== undefined) {
            this.UseEncryption = JSON.parse(newSettings["UseEncryption"]);
        }
        if (newSettings["Key"] !== undefined) {
            this.Key = newSettings["Key"];
        }
        if (newSettings["NodeType"] !== undefined) {
            this.NodeType = newSettings["NodeType"];
        }
        if (newSettings["NodeIndexNumber"] !== undefined) {
            this.NodeIndexNumber = JSON.parse(newSettings["NodeIndexNumber"]);
        }
        if (newSettings["NodeCustomerNumber"] !== undefined) {
            this.NodeCustomerNumber = JSON.parse(newSettings["NodeCustomerNumber"]);
        }
        if (newSettings["NodeProjectNumber"] !== undefined) {
            this.NodeProjectNumber = JSON.parse(newSettings["NodeProjectNumber"]);
        }
        await this.store();
    }
}
