import { AEngine } from "../core/AEngine.js";
// SOURCE https://gis.stackexchange.com/questions/121212/google-streetview-api-overlays
// the main application object (StreetViewObject)
export class ASVO {
    constructor(panorama, cameraCoords, targetCoords, moveCamera = true) {
        this.panorama = panorama;
        this.zoom = 16;
        this.image = "img/down.png";
        this.fromPoint = new google.maps.LatLng(cameraCoords.lat(), cameraCoords.lng());
        this.toPoint = new google.maps.LatLng(targetCoords.lat(), targetCoords.lng());
        if (moveCamera)
            this.panorama.setPosition(this.fromPoint);
        // initial POV
        this.sheading = 69.58;
        this.spitch = 0;
        this.szoom = 1;
        this.distance = 0; // distance in metres from street view to marker
        this.maximumDistance = 200; // distance beyond which marker is hidden
        // dimensions of street view container (fixed)
        this.panWidth = 0; // 480;
        this.panHeight = 0; // 480;
        // dimensions of marker container (resized according to current pov)
        this.markerWidth = 0; // 120;
        this.markerHeight = 0; // 80;
    }
    initPanorama() {
        this.fromPoint = this.panorama.getPosition();
        google.maps.event.addListener(this.panorama, 'pov_changed', _ => {
            this.updateMarker();
        });
        google.maps.event.addListener(this.panorama, 'zoom_changed', _ => {
            this.updateMarker();
        });
        google.maps.event.addListener(this.panorama, 'position_changed', _ => {
            this.fromPoint = this.panorama.getPosition();
            // map.setCenter(this.streetPt)
            this.updateMarker();
        });
    }
    destroyListeners() {
        AEngine.log('SVO DESTROYED');
        google.maps.event.clearListeners(this.panorama, 'pov_changed');
        google.maps.event.clearListeners(this.panorama, 'zoom_changed');
        google.maps.event.clearListeners(this.panorama, 'position_changed');
    }
    // convert the current heading and pitch (degrees) into pixel coordinates
    convertPointProjection(p_pov, p_zoom) {
        var l_fovAngleHorizontal = 90 / p_zoom;
        var l_fovAngleVertical = 90 / p_zoom;
        var l_midX = this.panWidth / 2;
        var l_midY = this.panHeight / 2;
        var l_diffHeading = this.sheading - p_pov.heading;
        l_diffHeading = normalizeAngle(l_diffHeading);
        l_diffHeading /= l_fovAngleHorizontal;
        var l_diffPitch = (p_pov.pitch - this.spitch) / l_fovAngleVertical;
        var x = l_midX + l_diffHeading * this.panWidth;
        var y = l_midY + l_diffPitch * this.panHeight;
        var l_point = new google.maps.Point(x, y);
        return l_point;
    }
    // create the 'marker' (a div containing an image which can be clicked)
    initMarker() {
        var l_markerDiv = document.getElementById("markerDiv");
        if (l_markerDiv == null) {
            throw new Error(`ASVO element [id="markerDiv"] not found`);
        }
        l_markerDiv.style.width = this.markerWidth + "px";
        l_markerDiv.style.height = this.markerHeight + "px";
        l_markerDiv.innerHTML = "<img src='" + this.image + "' width='100%' height='100%' alt='' />";
        this.updateMarker();
    }
    updateMarker() {
        var l_pov = this.panorama.getPov();
        if (l_pov) {
            var l_zoom = this.panorama.getZoom();
            // scale according to street view zoom level
            var l_adjustedZoom = Math.pow(2, l_zoom) / 2;
            // recalulate icon heading and pitch now
            this.sheading = google.maps.geometry.spherical.computeHeading(this.fromPoint, this.toPoint);
            this.distance = google.maps.geometry.spherical.computeDistanceBetween(this.fromPoint, this.toPoint);
            var l_pixelPoint = this.convertPointProjection(l_pov, l_adjustedZoom);
            var l_markerDiv = document.getElementById("markerDiv");
            if (l_markerDiv == null) {
                throw new Error(`ASVO element [id="markerDiv"] not found`);
            }
            var l_distanceScale = 7 / this.distance;
            l_adjustedZoom = l_adjustedZoom * l_distanceScale;
            // _TODO scale marker according to distance from view point to marker 
            // beyond maximum range a marker will not be visible
            // apply position and size to the marker div
            var wd = this.markerWidth * l_adjustedZoom;
            var ht = this.markerHeight * l_adjustedZoom;
            var x = l_pixelPoint.x - Math.floor(wd / 2);
            var y = l_pixelPoint.y - Math.floor(ht / 2);
            l_markerDiv.style.display = "block";
            l_markerDiv.style.left = x + "px";
            l_markerDiv.style.top = y + "px";
            l_markerDiv.style.width = wd + "px";
            l_markerDiv.style.height = ht + "px";
            // hide marker when its beyond the maximum distance
            l_markerDiv.style.display = this.distance < this.maximumDistance ? "block" : "none";
        }
    }
    lookAt() {
        // TODO: Fix rotation, camera doesn't rotate in slow situations
        // const heading = google.maps.geometry.spherical.computeHeading(from, to)
        this.sheading = google.maps.geometry.spherical.computeHeading(this.fromPoint, this.toPoint);
        const pitch = 1;
        PageScript.panorama.setPov({
            heading: this.sheading,
            zoom: this.zoom,
            pitch
        });
    }
}
export function createSVO(panorama, from, to, moveCamera = true) {
    const svo = new ASVO(panorama, from, to, moveCamera);
    svo.initPanorama();
    svo.initMarker();
    return svo;
}
export function normalizeAngle(a) {
    while (a > 180) {
        a -= 360;
    }
    while (a < -180) {
        a += 360;
    }
    return a;
}
