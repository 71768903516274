// @ts-nocheck
class APercentColumn extends bryntum.grid.Column {
    static get type() {
        return 'apercent';
    } // Type to use when auto adding field
    static get fieldType() {
        return 'number';
    }
    static get fields() {
        return ['showValue', 'thresholds'];
    }
    static get defaults() {
        return {
            /**
             * PercentColumn uses a {@link Core.widget.NumberField} configured with an allowed interval 0 - 100 as
             * its default editor.
             * @config {Object|String}
             * @default Core.widget.NumberField
             * @category Misc
             */
            editor: {
                type: 'number',
                min: 0,
                max: 100
            },
            /**
             * Set to `true` to render the number value inside the bar, for example "15%"
             * @config {Boolean}
             * @default
             * @category Rendering
             */
            showValue: true,
            /**
             * When below this percentage the bar will have `b-low` CSS class added. By default it turns the bar red.
             * @config {Number}
             * @default
             * @category Rendering
             */
            thresholds: [
                { color: 'orange', range: [25, 50] },
                { color: 'red', range: [0, 25] },
            ],
            filterType: 'number',
            htmlEncode: false,
            searchable: false,
            summaryRenderer: sum => `${sum}%`,
            fitMode: false
        };
    }
    constructor(config, store) {
        super(...arguments);
        this.internalCellCls = 'b-percent-bar-cell';
    }
    /**
     * Renderer that displays a progress bar in the cell.
     * @private
     */
    defaultRenderer({ value }) {
        const internalValue = value || 0;
        const lowThreshold = 15; // TODO: Use this.lowthreshold
        return {
            className: 'b-percent-bar-outer',
            children: [{
                    tag: 'div',
                    className: {
                        'b-percent-bar': 1,
                        'b-zero': internalValue === 0,
                        'b-low': internalValue < lowThreshold
                    },
                    style: {
                        width: internalValue + '%'
                    },
                    children: [this.showValue ? {
                            tag: 'span',
                            text: (value != null) ? (value + '%') : '-'
                        } : undefined]
                }]
        };
    }
    resizeToFitContent() { }
}
function registerColumnType() {
    APercentColumn.sum = 'average';
    bryntum.grid.ColumnStore.registerColumnType(APercentColumn, true);
    APercentColumn._$name = 'APercentColumn';
}
export { APercentColumn, registerColumnType };
