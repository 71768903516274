import { AError } from "../classes/AError.js";
import { MYSQL_FALSE, MYSQL_TRUE } from "../utils/query.js";
export class ARegimeOrm {
    get emptyRegime() {
        return {
            RegimeCode: '',
            RegimeText: null,
            MustFollowUp: false,
            IsRegimeChangeOption: false,
            HasCapacity: false,
            EnableDefaultMaxStayDuration: false,
            DefaultMaxStayDuration: null,
            UsedInGeo: null,
            FineGroupText: null,
            RegimeType: null,
            ForceFollowUp: null,
            OffenceCode: null,
            ChannelCode: null,
            FineGroupCode: null,
            PrdbCheck: 0,
            ModificationDevice: null,
            ModificationTime: null,
            ModificationUser: null
        };
    }
    async find({ RegimeCode }) {
        return await requestService.query({
            Query: `SELECT RegimeCode FROM config_regimes WHERE RegimeCode=:RegimeCode`,
            Params: { RegimeCode }
        });
    }
    // private async findByOffenceCode({ RegimeCode, OffenceCode }: { RegimeCode?: string, OffenceCode: string }) {
    //   const result = await requestService.fetch<{ RegimeCode: string, OffenceCode: string }>({
    //     AssertValues: true,
    //     Query: `SELECT RegimeCode, OffenceCode FROM config_regimes WHERE OffenceCode=:OffenceCode AND IF(:RegimeCode IS NULL, TRUE, RegimeCode != :RegimeCode)`,
    //     Params: { RegimeCode, OffenceCode },
    //   })
    //   return result
    // }
    async fetchAll() {
        const rows = await requestService.fetch({
            AssertValues: true,
            Query: ( /*SQL*/`
        SELECT
          RegimeCode,
          IFNULL(RegimeText, '') AS RegimeText,
          HasCapacity,
          MustFollowUp,
          IF(IsRegimeChangeOption, ${MYSQL_TRUE}, ${MYSQL_FALSE}) AS IsRegimeChangeOption,
          ForceFollowUp,
          PrdbCheck,
          CONCAT('D', DefaultMaxStayDuration) AS DefaultMaxStayDuration,
          IF(DefaultMaxStayDuration IS NOT NULL, ${MYSQL_TRUE}, ${MYSQL_FALSE}) AS EnableDefaultMaxStayDuration,
          GROUP_CONCAT(p.PrdbText SEPARATOR ', ') AS LinkedPrdbs,
          RegimeType,
          RegimeType AS RegimeTypeTranslated,
          OffenceCode,
          '' AS OffenceText,
          '' AS OffenceDescription,
          '' AS OffencePrice,
          ChannelCode,
          ChannelCode AS ChannelCodeTranslated,
          FineGroupCode,
          fg.FineGroupText AS FineGroup,
          IF(IsUsedInGeo, ${MYSQL_TRUE}, ${MYSQL_FALSE}) AS UsedInGeo,
          r.ModificationUser,
          r.ModificationDevice,
          r.ModificationTime
        FROM config_regimes r
        LEFT JOIN config_regime_prdbs pl USING (RegimeCode)
        LEFT JOIN config_prdb p USING (PrdbCode)
        LEFT JOIN config_finegroups fg USING (FineGroupCode)
        GROUP BY RegimeCode
        ORDER BY
          IsUsedInGeo DESC,
          RegimeCode ASC
      `),
            Params: {
                MYSQL_TRUE,
                MYSQL_FALSE,
            },
            Translate: ['RegimeTypeTranslated', 'ChannelCodeTranslated'],
            Language: Language
        }, {
            valueMapper: {
                DefaultMaxStayDuration(input) {
                    return input?.substring(1).split(':').slice(0, -1).join(':');
                },
            }
        });
        return rows;
    }
    async fetchRegimePrdbLinks(options) {
        const rows = await requestService.fetch({
            AssertValues: true,
            Query: ( /*SQL*/`
        SELECT
          pl.RegimeCode,
          pl.PrdbCode,
          p.PrdbText
        FROM config_regime_prdbs pl
        INNER JOIN config_prdb p USING (PrdbCode)
        WHERE pl.RegimeCode=:RegimeCode
      `),
            Params: options,
            Translate: [],
            Language: Language
        });
        return rows;
    }
    async create(options) {
        const found = await this.find(options);
        if (found.Rows.length > 0) {
            AError.handleSilent(`db entry already exists! config_regimes.RegimeCode=${options.RegimeCode}`, 'RegimeCode Already Exists');
            return {
                success: false,
                category: 'DUPLICATE_ENTRY',
                msg: [`RegimeCode ${options.RegimeCode} Already Exists`],
            };
        }
        // if (options.MustFollowUp) {
        //   const foundOffenceCodes = await this.findByOffenceCode({ OffenceCode: options.OffenceCode })
        //   if (foundOffenceCodes.Size > 0) {
        //     return {
        //       success: false,
        //       category: 'DUPLICATE_ENTRY',
        //       msg: [`OffenceCode ${options.OffenceCode} Already Exists`],
        //     }
        //   }
        // }
        const ModificationUser = stateService.User;
        const ModificationDevice = _.getSession().DeviceName;
        if (options["DefaultMaxStayDuration"] == "00:00")
            options["DefaultMaxStayDuration"] = null;
        await requestService.query({
            Query: ( /*SQL*/`
        INSERT INTO config_regimes (
          RegimeCode,
          RegimeText,
          HasCapacity,
          DefaultMaxStayDuration,
          MustFollowUp,
          IsRegimeChangeOption,
          ForceFollowUp,
          RegimeType,
          OffenceCode,
          ChannelCode,
          PrdbCheck,
          FineGroupCode,
          ModificationUser,
          ModificationDevice
        )
        VALUES (
          :RegimeCode,
          NULLIF(:RegimeText, ''),
          :HasCapacity,
          :DefaultMaxStayDuration,
          :MustFollowUp,
          :IsRegimeChangeOption,
          :ForceFollowUp,
          :RegimeType,
          :OffenceCode,
          :ChannelCode,
          :PrdbCheck,
          :FineGroupCode,
          :ModificationUser,
          :ModificationDevice
        )
      `),
            Params: {
                ...Object.assign(this.emptyRegime, options),
                ModificationUser,
                ModificationDevice
            }
        });
        await this.applyRestrictions(options);
        return { success: true };
    }
    async update(RegimeCode, changes) {
        const oldCode = await this.find({ RegimeCode: RegimeCode });
        if (oldCode.Rows.length === 0) {
            AError.handleSilent(`Couldn't find db entry config_regimes.RegimeCode=${changes.RegimeCode}`, 'RegimeCode Not Found');
            return {
                success: false,
                category: 'DUPLICATE_ENTRY',
                msg: [`RegimeCode ${changes.RegimeCode} Not Found`],
            };
        }
        if (changes.RegimeCode !== RegimeCode) {
            const newCode = await this.find({ RegimeCode: changes.RegimeCode });
            if (newCode.Rows.length > 0) {
                AError.handleSilent(`Regime already exists! config_regimes.RegimeCode=${changes.RegimeCode}`, 'RegimeCode Not Found');
                return {
                    success: false,
                    category: 'DUPLICATE_ENTRY',
                    msg: [`RegimeCode ${changes.RegimeCode} Already Exists`],
                };
            }
        }
        // if (changes.MustFollowUp) {
        //   const foundOffenceCodes = await this.findByOffenceCode({ OffenceCode: changes.OffenceCode, RegimeCode })
        //   if (foundOffenceCodes.Size > 0) {
        //     return {
        //       success: false,
        //       category: 'DUPLICATE_ENTRY',
        //       msg: [`OffenceCode ${changes.OffenceCode} Already Exists`],
        //     }
        //   }
        // }
        const ModificationUser = stateService.User;
        const ModificationDevice = _.getSession().DeviceName;
        if (changes["DefaultMaxStayDuration"] == "00:00")
            changes["DefaultMaxStayDuration"] = null;
        await requestService.query({
            Query: ( /*SQL*/`
        UPDATE config_regimes
        SET
          RegimeCode=:RegimeCode,
          RegimeText=NULLIF(:RegimeText, ''),
          HasCapacity=:HasCapacity,
          DefaultMaxStayDuration=:DefaultMaxStayDuration,
          MustFollowUp=:MustFollowUp,
          IsRegimeChangeOption=:IsRegimeChangeOption,
          ForceFollowUp=:ForceFollowUp,
          RegimeType=:RegimeType,
          OffenceCode=:OffenceCode,
          ChannelCode=:ChannelCode,
          PrdbCheck=:PrdbCheck,
          FineGroupCode=:FineGroupCode,
          ModificationUser=:ModificationUser,
          ModificationDevice=:ModificationDevice
        WHERE RegimeCode=:RegimeCodeClause
      `),
            Params: {
                ...Object.assign(this.emptyRegime, changes),
                ModificationUser,
                ModificationDevice,
                RegimeCodeClause: RegimeCode
            }
        });
        await this.applyRestrictions(changes);
        return { success: true };
    }
    async delete(options) {
        await requestService.query({
            Query: (`DELETE FROM config_regimes WHERE RegimeCode=:RegimeCode`),
            Params: options
        });
    }
    async deleteRestrictions(options) {
        await Promise.all([
            requestService.query({
                Query: `DELETE FROM config_regime_prdbs WHERE RegimeCode=:RegimeCode`, Params: options
            })
        ]);
    }
    async applyRestrictions(options) {
        const { RegimeCode, LinkedPrdbCodes } = options;
        const ModificationUser = stateService.User;
        const ModificationDevice = _.getSession().DeviceName;
        await this.deleteRestrictions({ RegimeCode });
        const baseQueryParams = { RegimeCode, ModificationUser, ModificationDevice };
        await Promise.all([
            ...(LinkedPrdbCodes || []).map(PrdbCode => {
                return requestService.query({
                    Query: `INSERT INTO config_regime_prdbs (RegimeCode, PrdbCode) VALUES (:RegimeCode, :PrdbCode)`,
                    Params: { PrdbCode, ...baseQueryParams }
                });
            })
        ]);
    }
}
