import { AKpiOrm } from "../orm/AKpiOrm.js";
import { AWidgetOrm } from "../orm/AWidgetOrm.js";
import { EVENTS } from "./AEventService.js";
export class AWidgetService {
    get activeWidgets() { return this.allWidgets.filter((w) => w.Active === 1); }
    baseDataStore(opt) {
        return [
            { View: opt.View, KpiType: 'AKpiBlockTreeBreakdown', Name: 'TreeBreakdown', Settings: null, Sort: 0, Width: 'col-2', Active: 1 },
            { View: opt.View, KpiType: 'AKpiBlockServerChart', Name: 'Control Center | All', Settings: { "viewType": "all" }, Sort: 1, Width: 'col-6', Active: 1 },
            { View: opt.View, KpiType: 'AKpiBlockServerChart', Name: 'Control Center | Operational', Settings: { "viewType": "operational" }, Sort: 1, Width: 'col-6', Active: 0 },
            { View: opt.View, KpiType: 'AKpiBlockServerChart', Name: 'Control Center | Process', Settings: { "viewType": "process" }, Sort: 1, Width: 'col-6', Active: 0 },
            { View: opt.View, KpiType: 'AKpiBlockUnifications', Name: 'ParkingRight', Settings: null, Sort: 2, Width: 'col-4', Active: 1 },
            { View: opt.View, KpiType: 'AKpiBlockMap', Name: 'Map', Settings: null, Sort: 3, Width: 'col-4', Active: 1 },
            { View: opt.View, KpiType: 'AKpiBlockSanctions', Name: 'Sanctions', Settings: null, Sort: 4, Width: 'col-4', Active: 1 },
            { View: opt.View, KpiType: 'AKpiBlockUnifications', Name: 'Verification', Settings: null, Sort: 5, Width: 'col-4', Active: 1 },
            { View: opt.View, KpiType: 'AKpiBlockDetectionStateTable', Name: 'DetectionStateDuration', Settings: null, Sort: 0, Width: 'col-4', Active: 0 },
            { View: opt.View, KpiType: 'AKpiBlockOldestDetectionTable', Name: 'OldestDetectionState', Settings: null, Sort: 0, Width: 'col-4', Active: 0 },
            { View: opt.View, KpiType: 'AKpiBlockUnifications', Name: 'IllegallyParked', Settings: null, Sort: 100, Width: 'col-4', Active: 0 },
            { View: opt.View, KpiType: 'AKpiBlockUnifications', Name: 'DetectionState', Settings: null, Sort: 100, Width: 'col-4', Active: 0 },
            { View: opt.View, KpiType: 'AKpiBlockUnifications', Name: 'Digital', Settings: null, Sort: 100, Width: 'col-4', Active: 0 },
            { View: opt.View, KpiType: 'AKpiBlockUnifications', Name: 'Occupancy', Settings: null, Sort: 100, Width: 'col-4', Active: 0 },
            { View: opt.View, KpiType: 'AKpiBlockUnifications', Name: 'TimeLimitedParking', Settings: null, Sort: 100, Width: 'col-4', Active: 0 },
            { View: opt.View, KpiType: 'AKpiBlockChannels', Name: 'Channel Queues', Settings: null, Sort: 100, Width: 'col-4', Active: 0 },
            // { View: opt.View, KpiType: 'AKpiBlockVehicleSpeed', Name: 'Vehicle Speed', Settings: null, Sort: 100, Width: 'col-4', Active: 0 },
            // ...(AKpiBlockVehicleSpeed.getVariantNames().map(name => {
            //   return {
            //     View: opt.View,
            //     KpiType: 'AKpiBlockVehicleSpeed',
            //     Name: name,
            //     Settings: null,
            //     Sort: 100,
            //     Width: 'col-4',
            //     Active: 0
            //   } as AWidgetOptions
            // }))
            // ...(AKpiBlockThematicChart.Variants.map(variant => {
            //   return { View: opt.View, KpiType: 'AKpiBlockThematicChart', Name: variant, Settings: null, Sort: 100, Width: 'col-4', Active: 0 as 0|1 }
            // }))
        ];
    }
    constructor() {
        this.widgetOrm = new AWidgetOrm();
        this.kpiOrm = new AKpiOrm();
        this.requireReload = true;
        this.allWidgets = [];
    }
    async autoInit() {
        Events.h_once(EVENTS.PREFETCH, async () => {
            await this.reloadData({ View: 'Operations' });
        });
    }
    async reloadData(opt) {
        const widgetsdb = await this.widgetOrm.fetchAll(opt);
        // const defaultStore = this.baseDataStore.filter(w => w.Active === 1)
        const datastructure = {};
        this.baseDataStore(opt).map((w) => {
            if (!datastructure.hasOwnProperty(w.KpiType)) {
                datastructure[w.KpiType] = {};
            }
            if (opt.View !== 'Operations') {
                w.Active = 0;
            }
            datastructure[w.KpiType][w.Name] = w;
        });
        widgetsdb.map((widget, index) => {
            if (!datastructure.hasOwnProperty(widget.KpiType)) {
                datastructure[widget.KpiType] = {};
            }
            if (!datastructure[widget.KpiType].hasOwnProperty(widget.Name)) {
                datastructure[widget.KpiType][widget.Name] = widget;
            }
            else {
                Object.assign(datastructure[widget.KpiType][widget.Name], widget);
            }
        });
        this.allWidgets = Object.values(datastructure).map(v => {
            return Object.values(v);
        }).flat().sort((w1, w2) => {
            return w1.Sort - w2.Sort;
        });
        this.requireReload = false;
    }
    optionEnabled(KpiType, subOption, customOptions, kpiCfg) {
        const optionsForKpi = this.allWidgets.filter((w) => w.KpiType === KpiType && (subOption === undefined) ? true : w.Name === subOption);
        if (subOption === undefined || optionsForKpi.filter(w => w.Active === 1).length === 0) {
            return true;
        }
        for (let option of optionsForKpi) {
            if (option.Active === 0) {
                return true;
            }
        }
        return false;
    }
    async fetchActiveWidgets(opt) {
        // if (this.requireReload) {
        await this.reloadData(opt);
        // }
        return this.activeWidgets;
    }
    async createWidget(options) {
        await this.widgetOrm.create(options);
        const createdObj = await this.widgetOrm.find(options);
        this.requireReload = true;
        if (createdObj) {
            this.allWidgets.push(createdObj);
        }
        return createdObj;
    }
    widgetExistsFast({ View, KpiType, Name }) {
        return this.activeWidgets.find(w => w.View === View && w.KpiType === KpiType && w.Name === Name);
    }
    async widgetExists(options) {
        const w = await this.widgetOrm.find(options);
        return w && w.Active === 1;
    }
    async deleteWidget(options) {
        await this.widgetOrm.disableWidget(options);
        this.requireReload = true;
    }
    async updateWidth(widget) {
        await this.widgetOrm.update(widget, ['Width']);
        this.requireReload = true;
    }
    async updateOrder(widgets) {
        await Promise.all(widgets.map((widget) => {
            return this.widgetOrm.update(widget, ['Sort']);
        }));
        this.requireReload = true;
    }
    // =================================== // KPI SECTION // =================================== //
    async fetchAllViews() {
        return await this.kpiOrm.fetchAllViews({ allowDefaultView: true });
    }
    async createOrUpdateView(options) {
        return await this.kpiOrm.createOrUpdateView(options);
    }
    async fetchAllCustomCharts(options) {
        return await this.kpiOrm.fetchAll(options);
    }
    async fetchCustomChartByName(name) {
        return await this.kpiOrm.fetchByName(name);
    }
    async customChartExists(options) {
        const kpi = await this.fetchCustomChartByName(options.Name);
        return kpi !== undefined;
    }
    async createCustomChart(options) {
        await this.kpiOrm.create({
            CreatedBy: stateService.UserDisplayName,
            ...options
        });
    }
    async deleteCustomChart(options) {
        await this.kpiOrm.deleteCustom(options);
    }
    async deleteView(options) {
        await Promise.all([
            this.kpiOrm.deleteView(options),
            this.widgetOrm.deleteWidgetsInView(options)
        ]);
    }
}
