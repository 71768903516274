import { AError } from "../../classes/AError.js";
import { COLUMN_TEXT, COLUMN_NUMBER, COLUMN_DATETIME, COLUMN_BOOLEAN, DATA_DATETIME, COLUMN_HIDDEN } from "../../classes/AGridTypes.js";
import { ASortCards } from "../../classes/ASortCards.js";
import { AUnifyTreeItem } from "../../classes/ATreeBreakdown.js";
import { AEngine } from "../../core/AEngine.js";
import { ALERT_TITLES, ALERTS } from "../../services/AAlertService.js";
import { AStatisticsService } from "../../services/AStatisticsService.js";
import { secondsToHHMMSS, AShowTable, AGridColumns, AGridData, createTreeBreakdown, ARound } from "../../utils/tools.js";
const COLUMN_DISTANCE = { renderer: ({ value }) => value ? ARound(value, 2) + 'm' : '-' };
const ROW_FINAL_VERIFICATION_USER = (v) => v || Translate.getCacheFast('system');
const ROW_FOLLOWUP_DURATION = (v) => secondsToHHMMSS(v || 0);
export class PerformancePage {
    constructor(opt) {
        this.opt = opt;
    }
    async init() {
        FilterManager.load();
        this.translationKeys = [
            'Driver',
            'Operation Hours',
            'Scans',
            'Suspects',
            'Followups',
            'Discarded Followups',
            'Assigned Followups',
            'Not Sanctioned',
            'Sanctions',
            'AverageDistanceToVehicle',
            'AverageFollowupDuration',
            'Followup history',
            'Close',
            'Average Per Hour',
            'Unknown',
            'CentralVerification',
            'PDA'
        ];
        this.translations = await Translate.get(this.translationKeys);
        const $export = $('#export');
        Events.on(`ACI_TABS_CHANGED->views`, ({ tabview, $tabview }) => {
            $export.toggleClass('hidden', !(tabview === 'tab-table'));
        });
        Loading.waitForPromises(this.refresh()).catch(AError.handle);
        $('#RefreshButton').on('click', _ => Loading.waitForPromises(this.refresh()).catch(AError.handle));
    }
    async createPdaTable(response) {
        const data = response.toTuple().map(([obj, stats]) => {
            const Suspects = stats.getSuspects();
            const SuccessfulFollowups = stats.getSuccessfulFollowUps();
            const DiscardedFollowups = stats.getUserDiscardedFollowUps(); // Suspects - SuccessfulFollowups
            const Sanctions = stats.getSanctions();
            const NotSanctioned = (SuccessfulFollowups - Sanctions);
            return Object.assign(obj, {
                Suspects,
                Followups: SuccessfulFollowups,
                Sanctions,
                NotSanctioned,
                DiscardedFollowups
                // Followups: stats.getSuccessfulFollowUps(),
                // DiscardedFollowups: stats.getUserDiscardedFollowUps(),
                // Sanctions: stats.getSanctions(),
            });
        });
        this.grid = AShowTable({
            appendTo: 'table-bryntum',
            columns: await AGridColumns({
                'FinalVerificationUser': COLUMN_TEXT,
                'NodeType': COLUMN_TEXT,
                'Suspects': COLUMN_NUMBER,
                'Followups': COLUMN_NUMBER,
                'Sanctions': COLUMN_NUMBER,
                'NotSanctioned': COLUMN_NUMBER,
                'DiscardedFollowups': COLUMN_NUMBER,
                'AverageDistanceToVehicle': Object.assign({}, this.opt.show === 'pda' ? {} : COLUMN_HIDDEN, COLUMN_DISTANCE),
                'AverageFollowupDuration': COLUMN_TEXT,
            }),
            data: AGridData(data, {
                'AverageFollowupDuration': ROW_FOLLOWUP_DURATION,
                'FinalVerificationUser': ROW_FINAL_VERIFICATION_USER
            }, { makeCopy: true })
        });
    }
    async drawPdas(response) {
        this.sortCards = new ASortCards({
            $parent: $('.visual-parent'),
            $chips: $('.chips-select'),
            fadeIn: true
        });
        // const DiscardedFollowupsExplain = await ADetectionStatistics.getUserDiscardedFollowUpsExplain()
        // const explainIconHtml = `<i atooltip="${DiscardedFollowupsExplain}" class="fa-solid fa-circle-question"></i>`
        for (const [data, statistics] of response.toTuple()) {
            const { FinalVerificationUser, NodeType, AverageDistanceToVehicle, AverageFollowupDuration } = data;
            let username = FinalVerificationUser || Translate.getCacheFast('system');
            let suffix = FinalVerificationUser ? `(${NodeType})` : '';
            const initials = username.replace(/\s+/g, ' ').split(' ').map(n => {
                return (n || ' ')[0].toUpperCase();
            }).splice(0, 2).join('');
            const Suspects = statistics.getSuspects();
            const FollowUps = statistics.getFollowUps();
            const UnsuccessfulFollowUps = statistics.getUnsuccessfulFollowUps();
            const Unhandled = Suspects - FollowUps;
            const Sanctions = statistics.Verification.Options.Fined.Count;
            const NotSanctioned = statistics.Verification.Options.NotFined.Count;
            const convert = (opt) => {
                return Promise.all(opt.map(async (v) => {
                    const children = Object.values(v.Options).filter(({ Count }) => Count > 0);
                    return {
                        data: new AUnifyTreeItem({
                            Text: await Translate.get(v.KeyShort),
                            Count: v.Count
                        }),
                        children: children.length ? await convert(children) : []
                    };
                }));
            };
            const idPrefix = username + '-';
            const breakdown = createTreeBreakdown({
                data: new AUnifyTreeItem({
                    Text: await Translate.get('Suspects'),
                    Count: Suspects
                }),
                children: [{
                        data: new AUnifyTreeItem({
                            Text: await Translate.get('Followups'),
                            Count: FollowUps
                        }),
                        children: [{
                                data: new AUnifyTreeItem({
                                    Text: await Translate.get('Sanctions'),
                                    Count: Sanctions
                                })
                            },
                            {
                                data: new AUnifyTreeItem({
                                    Text: await Translate.get('Not Sanctioned'),
                                    Count: NotSanctioned,
                                })
                            },
                            {
                                data: new AUnifyTreeItem({
                                    Text: await Translate.get('Discarded'),
                                    Count: UnsuccessfulFollowUps,
                                }),
                                children: await convert(statistics.getUnsuccessfulFollowUpOptions()),
                            }]
                    }, {
                        data: new AUnifyTreeItem({
                            Text: await Translate.get('Unhandled'),
                            Count: Unhandled
                        }),
                        children: await convert(statistics.getUnhandledSuspectOptions()),
                        collapse: true
                    }]
            }, { idPrefix });
            this.sortCards.append(/*html*/ `
        <div class="column col-4 fade-item"
          data-name="${(FinalVerificationUser || '').toLowerCase()}"
          data-followups="${FollowUps}"
          data-sanctions="${Sanctions}"
          data-distance="${AverageDistanceToVehicle || 0}"
          data-duration="${AverageFollowupDuration || 0}">
          <div class="columns card-backoffice" style="padding: 10px; background: #fff;">
            <div class="chip" style="zoom: 1.2; margin: 0 auto 5px auto;">
                <figure class="avatar avatar-sm" data-initial="${initials}" style="background-color: #5755d9;"></figure>${username} ${suffix}
            </div>
            <div class="fw">${breakdown}</div>
            <table class="styled-table grid-like fw table-text-right">
              <tr class="${this.opt.show === 'pda' ? '' : 'hidden'}"><td>${this.translations['AverageDistanceToVehicle']}</td><td>${AverageDistanceToVehicle ? ARound(AverageDistanceToVehicle, 2) + 'm' : '-'}</td></tr>
              <tr><td>${this.translations['AverageFollowupDuration']}</td><td>${secondsToHHMMSS(AverageFollowupDuration || 0)}</td></tr>
                <td colspan="2">
                  <button class="btn btn-primary btn-sm fw" onclick="PageScript.showPdaDataForUser('${FinalVerificationUser || ''}', ${FollowUps || 0})">
                    ${await Translate.get('Show Details')}
                  </button>
                </td>
              </tr>
            </table>

          </div>
        </div>
      `);
        }
    }
    async fetchPdaAverages(filters) {
        const statisticsService = AEngine.get(AStatisticsService);
        filters["PreviousDetectionState"] = this.opt.show === 'cvs' ? "InProgress_AssignedToCentralVerification" : "InProgress_AssignedToPda";
        const result = await statisticsService.fetchDynamic(filters, {
            baseTable: (`(
        select * from (SELECT DetectionId, DetectionDeviceId, max(DetectionFinalTime) as DetectionFinalTime
        FROM detections_final_history 
        where DetectionFinalTime BETWEEN :FromDate AND :ToDate and PreviousDetectionState = :PreviousDetectionState and FinalVerificationUser is not null
        group by DetectionId, DetectionDeviceId, VerificationVersion) g
        left join detections_final_history df using(DetectionFinalTime, DetectionId, DetectionDeviceId)
      )`),
            columns: [{
                    name: 'FinalVerificationUser',
                    select: `IF(FinalVerificationUser='',NULL,FinalVerificationUser)`,
                    groupBy: true
                }, {
                    name: 'NodeType',
                    select: 'IF(:PreviousDetectionState = "InProgress_AssignedToCentralVerification", "CentralVerification", "PDA")',
                    groupBy: true
                }, {
                    name: 'AverageDistanceToVehicle',
                    select: `SUM(IF(FinalVerificationDistanceToVehicle < 10000, FinalVerificationDistanceToVehicle, NULL))`,
                    groupBy: false,
                    method: "average"
                }, {
                    name: 'AverageFollowupDuration',
                    select: `SUM(TIMESTAMPDIFF(SECOND, VerificationStartTime, VerificationEndTime))`,
                    groupBy: false,
                    method: "average"
                }],
            joinTables: [`INNER JOIN verifications v USING (DetectionId, DetectionDeviceId, VerificationVersion)`]
        });
        return result;
    }
    showPdaDataForUser(FinalVerificationUser, Followups) {
        return Loading.waitForPromises(this._showPdaDataForUser(FinalVerificationUser, Followups));
    }
    async _showPdaDataForUser(FinalVerificationUser, Followups) {
        try {
            if (Followups > 5000) {
                return Alerts.show({
                    title: ALERT_TITLES.Warning,
                    content: await Translate.get(`There is too much information requested. Please adjust the Time selection!`)
                });
            }
            const filters = FilterManager.saveExplicit();
            const res = await this.fetchPdaDataForUser(Object.assign({ FinalVerificationUser }, filters));
            const height = Math.round(window.innerHeight / 3 * 2);
            const html = (`<div id="modal-table-bryntum" style="height: ${height}px"></div>`);
            Alerts.show({
                title: ALERT_TITLES.History,
                content: html,
                type: ALERTS.Mega,
            });
            this.subGrid = AShowTable({
                aci: { isModal: true },
                appendTo: 'modal-table-bryntum',
                columns: await AGridColumns({
                    'DetectionTime': COLUMN_DATETIME,
                    'FinalVerificationUser': COLUMN_TEXT,
                    'NodeType': COLUMN_TEXT,
                    'VerificationEndTime': COLUMN_DATETIME,
                    'FollowupStatus': COLUMN_TEXT,
                    'FollowupResult': COLUMN_TEXT,
                    'SanctionGiven': COLUMN_BOOLEAN,
                    'DistanceToVehicle': COLUMN_DISTANCE,
                    'FollowupDuration': COLUMN_TEXT,
                    'DetectionId': COLUMN_TEXT,
                    'DetectionDeviceId': COLUMN_TEXT,
                }),
                data: AGridData(res.toJSON({
                    apply: (stats) => ({ SanctionGiven: stats.getSanctions() > 0 })
                }), {
                    'DetectionTime': DATA_DATETIME,
                    'FinalVerificationUser': ROW_FINAL_VERIFICATION_USER,
                    'FollowupDuration': ROW_FOLLOWUP_DURATION,
                    'VerificationEndTime': DATA_DATETIME
                })
            });
            // Resize Columns
            this.subGrid.columns.visibleColumns.map(column => column.resizeToFitContent());
        }
        catch (err) {
            AError.handle(err);
        }
    }
    async fetchPdaDataForUser(filters) {
        if (!filters.hasOwnProperty('FinalVerificationUser')) {
            throw new Error(`fetchPdaData expects filters parameter to contain FinalVerificationUser`);
        }
        filters["PreviousDetectionState"] = this.opt.show === 'cvs' ? "InProgress_AssignedToCentralVerification" : "InProgress_AssignedToPda";
        const res = await AEngine.get(AStatisticsService).fetchDynamic(filters, {
            baseTable: (`(
        select * from (SELECT DetectionId, DetectionDeviceId, max(DetectionFinalTime) as DetectionFinalTime
        FROM detections_final_history 
        where DetectionFinalTime BETWEEN :FromDate AND :ToDate and PreviousDetectionState = :PreviousDetectionState and FinalVerificationUser is not null
        group by DetectionId, DetectionDeviceId, VerificationVersion) g
        left join detections_final_history df using(DetectionFinalTime, DetectionId, DetectionDeviceId)
      )`),
            joinTables: [`INNER JOIN verifications v USING (DetectionId, DetectionDeviceId, VerificationVersion)`],
            columns: [{
                    name: 'FinalVerificationUser',
                    select: `IF(FinalVerificationUser='' OR FinalVerificationUser='Onbekend', NULL, FinalVerificationUser)`,
                    groupBy: false
                }, {
                    name: 'NodeType',
                    select: 'IF(NULLIF(FinalVerificationUser, "") IS NULL, "Unknown", IF(FinalVerificationDevice LIKE "CentralVerification%", "CentralVerification", "PDA"))',
                    groupBy: false,
                    translate: true
                }, {
                    name: 'VerificationEndTime',
                    select: 'VerificationEndTime',
                    groupBy: true
                }, {
                    name: 'FollowupStatus',
                    select: 'Verification',
                    groupBy: false,
                    translate: true
                }, {
                    name: 'FollowupResult',
                    select: 'VerificationResult',
                    groupBy: false,
                    translate: true
                }, {
                    name: 'DistanceToVehicle',
                    select: 'ROUND(IF(df.FinalVerificationDistanceToVehicle < 10000, df.FinalVerificationDistanceToVehicle, NULL), 2)',
                    groupBy: false
                }, {
                    name: 'FollowupDuration',
                    select: 'TIMESTAMPDIFF(SECOND, VerificationStartTime, VerificationEndTime)',
                    groupBy: false
                }, {
                    name: 'DetectionId',
                    select: 'DetectionId',
                    groupBy: false
                }, {
                    name: 'DetectionDeviceId',
                    select: 'DetectionDeviceId',
                    groupBy: false
                }, {
                    name: 'DetectionTime',
                    select: 'DetectionTime',
                    groupBy: false
                }],
            where: [
                'FinalVerificationUser=:FinalVerificationUser',
            ]
        });
        return res;
    }
    async refresh() {
        try {
            const filters = FilterManager.saveExplicit();
            FilterManager.setActive(false);
            if (this.sortCards) {
                this.sortCards.clearCards();
            }
            const response = await this.fetchPdaAverages(filters);
            await Loading.waitForPromises([
                this.drawPdas(response),
                this.createPdaTable(response)
            ]);
            this.sortCards.createToolTips();
        }
        catch (err) {
            AError.handle(err);
        }
        FilterManager.setActive(true);
    }
}
export function render() {
    return ( /*html*/`
    <div id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column c-scroll col-12">
        <div class="form-group">
          <label class="form-label" for="FromDate">From</label>
          <input class="form-input" type="date" id="FromDate" required="required">
          <input class="form-input" type="time" id="FromTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="ToDate">To</label>
          <input class="form-input" type="date" id="ToDate" required="required">
          <input class="form-input" type="time" id="ToTime" required="required">
        </div>

      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </div>
    <div id="Rapport" class="flex-child bryntum-container has-footer-2">
      <div class="flex-content">
        <div class="aci-tabs tabs-sticky tabs-fixed-md top-zero tabs-fw" tabgroup="views">
          <button class="aci-tab active" tab="tab-visual">
            <!-- <i class="fa-solid fa-calendar-day"></i> -->
            <span>Visual</span>
          </button>
          <button class="aci-tab" tab="tab-table">
            <!-- <i class="fa-solid fa-clock-rotate-left"></i> -->
            <span>Table</span>
          </button>
        </div>
        <div class="columns col-gapless" style="height: calc(100% - 60px);">
          <div class="column col-12">
            <div tabgroup="views" tabview="tab-visual" class="h-padding">
              <div class="column col-12 chips-select">
                <span sortkey="data-name" sortDirection="ASC" class="chip active">
                  Sort by Name
                </span>
                <span sortkey="data-followups" sortDirection="DESC" sortType="Number" class="chip">
                  Sort by Followups
                </span>
                <span sortkey="data-sanctions" sortDirection="DESC" sortType="Number" class="chip">
                  Sort by Sanctions
                </span>
                <span sortkey="data-distance" sortDirection="DESC" sortType="Number" class="chip">
                  Sort by Average Distance
                </span>
                <span sortkey="data-duration" sortDirection="DESC" sortType="Number" class="chip">
                  Sort by Average Duration
                </span>
              </div>
              <div class="visual-parent columns card-list" style="justify-content: left;"></div>
            </div>
            <div tabgroup="views" tabview="tab-table" class="h-padding fh">
              <div id="table-bryntum" class="fh"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="columns footer aci">
        <div class="column col-2 col-ml-auto">
          <button id="export" class="btn btn-primary col-12 hidden" disabled="disabled">Export</button>
        </div>
      </div>
    </div>
  `);
}
