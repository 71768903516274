import { ADynamicChartUtils } from "../charts/ADynamicChartUtils.js";
import { AChartTooltipFormatter, MakeColumnChart, QueryResponseToSeries, ResizeChart } from "../charts/charts.js";
import { AVerification } from "../classes/AUnificationTypes.js";
import { AEngine } from "../core/AEngine.js";
import { EVENTS } from "../services/AEventService.js";
import { AStatisticsService } from "../services/AStatisticsService.js";
import { AConvertToGridData, AShowTable, AConvertToGridColumns, hoursToHHMMSS, AFormatNumber, ARound, AConvertMatrixToResponse } from "../utils/tools.js";
import { AKpiBlock, AKpiState } from "./AKpiBlock.js";
// No Overview Images: NotProcessed_NoOverviewImages
// Outdated Control Center: NotProcessed_OutdatedControlCenter
// Outdated External: NotProcessed_OutdatedExternal
export class AKpiBlockUnhandledQueue extends AKpiBlock {
    get chartId() { return `${this.idFilter}-Chart`; }
    get tableId() { return `${this.idFilter}-Table`; }
    constructor(opt) {
        super({
            TranslateTitle: true,
            refreshImplemented: true,
            optionsImplemented: true,
            tableImplemented: true,
            ...opt
        });
        this.chartUtils = new ADynamicChartUtils();
        this.groupToTableMap = {
            HourOfDay: { columnName: 'HourOfDay', columnSelect: 'df.HourOfDay' },
            DayOfWeek: { columnName: 'DayOfWeek', columnSelect: 'df.DayOfWeek' },
            MonthOfYear: { columnName: 'MonthOfYear', columnSelect: 'df.MonthOfYear' },
            Year: { columnName: 'Year', columnSelect: 'df.Year' },
        };
        this.chartType = opt?.Settings?.ChartType ?? 'column';
        this.groupBy = opt?.Settings?.GroupBy ?? 'HourOfDay';
        Events.on(EVENTS.CONTENT_RESIZE, this.resize.bind(this));
    }
    isMounted() {
        return this.chart !== undefined && Object.keys(this.chart).length > 0 && this.chart.container.isConnected === true;
    }
    resize() {
        if (this.isMounted()) {
            ResizeChart(this.chart, super.getSize(), true, { silentErrors: true });
        }
    }
    toggleTableView() {
        this.viewTable = !this.viewTable;
        this.$kpiView.find('[filter]').toArray().map(e => $(e)).map($view => {
            $view.toggleClass('hidden', $view.attr('filter') !== (this.viewTable ? 'table' : 'chart'));
        });
        Loading.waitForPromises([
            this.refreshTable(),
            this.refreshChart(),
        ]).catch(err => {
            this.errorOccurred(err);
        });
    }
    async fetchChartData(filters) {
        const statisticService = AEngine.get(AStatisticsService);
        let vRef = new AVerification();
        const { FirstIndex, LastIndex } = vRef.Options.Fined;
        const hardFilters = {
            FirstIndex,
            LastIndex,
            DetachedFromPda: detectionStateRef.Options.InProgress.Options.DetachedFromPda.FirstIndex,
            DetachedFromCentralVerification: detectionStateRef.Options.InProgress.Options.DetachedFromCentralVerification.FirstIndex,
        };
        const unhandledQueueRes = await statisticService.fetchDynamic({
            ...hardFilters,
            ...filters,
        }, {
            baseTable: /*SQL*/ `(
        SELECT
          DetectionId,
          DetectionDeviceId,
          MAX(dfh.DetectionFinalTime) AS DetectionFinalTime,
          (CASE
            WHEN dfh.DetectionState='InProgress_PdaVerificationQueue' THEN 'Pda'
            WHEN dfh.DetectionState='InProgress_CentralVerificationQueue' THEN 'CentralVerification'
            ELSE NULL
          END) AS VerificationDevice
        FROM detections_final df
        LEFT JOIN detections_final_history dfh USING (DetectionId, DetectionDeviceId)
        WHERE
          df.DetectionId BETWEEN (((UNIX_TIMESTAMP(:FromDate)-946684800)<<16)*1000) AND (((UNIX_TIMESTAMP(:ToDate)-946684800)<<16)*1000) AND
          df.Verification IN ('NotProcessed_OutdatedControlCenter', 'NotProcessed_OutdatedExternal') AND
          dfh.DetectionState IN ('InProgress_PdaVerificationQueue', 'InProgress_CentralVerificationQueue')
        GROUP BY DetectionId, DetectionDeviceId
      )`,
            joinTables: [
                'LEFT JOIN detections_final_history dfh USING (DetectionId, DetectionDeviceId, DetectionFinalTime)',
                'LEFT JOIN detections_final df USING (DetectionId, DetectionDeviceId)',
            ],
            columns: [{
                    name: 'VerificationDevice',
                    select: 'df.VerificationDevice',
                    groupBy: true,
                }, {
                    name: this.groupToTableMap[this.groupBy].columnName,
                    select: this.groupToTableMap[this.groupBy].columnSelect,
                    groupBy: true,
                }],
            where: [
                'df.FinalizedSuccess=1'
                //   `df.Verification BETWEEN :FirstIndex AND :LastIndex`,
                //   `df.DetectionState IN (:DetachedFromCentralVerification, :DetachedFromPda)`,
            ],
        });
        if (unhandledQueueRes.isEmpty) {
            return { hasData: false, chartData: { Columns: [], ColumnsTranslated: [], Rows: [] } };
        }
        const matrix = await unhandledQueueRes.toMatrix({
            groups: ['VerificationDevice', this.groupBy],
            mapTo: {
                'Total': ([obj, stats]) => stats.Verification.Count,
            }
        });
        const output = await AConvertMatrixToResponse(matrix, false);
        return {
            hasData: output.Rows.length > 0,
            chartData: output
        };
    }
    async refresh(filters = this.filterOption) {
        const { hasData, chartData } = await this.fetchChartData(filters);
        if (!hasData) {
            return AKpiState.noResults;
        }
        this.cachedChartData = chartData;
        await Promise.all([
            this.refreshTable(),
            this.refreshChart(),
        ]);
    }
    async refreshChart() {
        if (this.viewTable || !this.cachedChartData) {
            return;
        }
        const chartData = this.cachedChartData;
        const unit = 'Suspects';
        const label = 'Suspects';
        const chartType = this.chartType || 'bar';
        const inverted = false;
        const series = QueryResponseToSeries(chartData, { chartType, unit });
        const formatter = await AChartTooltipFormatter({
            unit,
            label,
            addPercentage: false,
            addTotal: true,
            hideZeroValues: true
        });
        const categories = chartData.Columns.slice(1);
        // const categories = hSelection.Translate || hSelection.TransformData ? chartData.ColumnsTranslated.slice(1) : chartData.Columns.slice(1)
        const chart = this.chart;
        const isMounted = this.isMounted();
        if (!isMounted) {
            this.chart = await MakeColumnChart({
                bind: this.chartId,
                type: chartType,
                inverted: inverted,
                polar: false,
                series: series,
                allowExport: false,
                flat: true,
                unit: unit,
                xAxis: { categories },
                yAxis: { title: { text: unit } },
                tooltip: { formatter },
                legend: { enabled: true },
                // translate: hSelection.Translate,
                // ...vSelection,
            });
        }
        else {
            chart.update({
                chart: {
                    type: chartType,
                    inverted: inverted,
                    polar: false,
                },
                series: series.map(s => {
                    // If new serie is added, make sure it's visible!
                    const foundSerie = chart?.series?.find(oldSerie => oldSerie.name === s.name);
                    if (foundSerie !== undefined) {
                        s.visible = foundSerie.visible ?? true;
                    }
                    return s;
                }),
                xAxis: { categories },
                yAxis: { title: { text: label } },
                tooltip: { formatter },
                // plotOptions,
                legend: { enabled: true },
            }, true, true, true);
        }
        // this.chart?.setSize(this.getWidthFromKpi(), this.getHeightFromKpi())
        return { chartData, hasData: chartData.Rows.length > 0, instantiated: !isMounted };
    }
    async refreshTable() {
        if (!this.viewTable || !this.cachedChartData) {
            return;
        }
        const data = AConvertToGridData(this.cachedChartData);
        // const { Vertical } = this.chartOptions
        if (this.grid) {
            const scrollTop = this.grid.storeScroll();
            this.grid.store.data = data;
            this.grid.restoreScroll(scrollTop);
        }
        else {
            this.grid = AShowTable({
                appendTo: this.tableId,
                columns: AConvertToGridColumns(this.cachedChartData, {
                    'Bars': { text: '' },
                    OperationHours: { htmlEncode: false, renderer: ({ value }) => { return value ? hoursToHHMMSS(value) : ''; } },
                    ScanHours: { htmlEncode: false, renderer: ({ value }) => { return value ? hoursToHHMMSS(value) : ''; } },
                    DistanceKM: { htmlEncode: false, renderer: ({ value }) => { return value ? AFormatNumber(ARound(value, 2)) + ' km' : ''; } },
                }),
                data: data
            });
        }
        // this.cachedChartData = undefined
    }
    async render() {
        return await super.renderView({
            title: this.Name,
            viewHtml: ( /*html*/`
        <div filter="chart" class="fh">
          <div id="${this.chartId}" class="fh"></div>
        </div>
        <div filter="table" class="fh pb-2 hidden">
          <div id="${this.tableId}" class="fh"></div>
        </div>
      `),
        });
    }
}
