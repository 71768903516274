export class ACVSImage {
    static get DEFAULT_ZOOM() {
        return 1.0;
    }
    static scrollOffset() {
        let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft, scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return { top: scrollTop, left: scrollLeft };
    }
    static rectOffset(ele) {
        let rect = ele.getBoundingClientRect();
        return {
            top: rect.top,
            left: rect.left
        };
    }
    static offset(ele) {
        let rect = ACVSImage.rectOffset(ele), scroll = ACVSImage.scrollOffset();
        return {
            top: rect.top + scroll.top,
            left: rect.left + scroll.left
        };
    }
    static fetch(url) {
        return new Promise((resolve) => {
            const img = new Image();
            img.onload = function () {
                resolve({
                    width: this['width'],
                    height: this['height']
                });
            };
            img.src = url;
        });
    }
    static addListeners($img, options) {
        const { $scrollParent } = Object.assign({
            $scrollParent: $img.parents('.photos-scroll-container')
        }, options || {});
        if ($scrollParent.length === 0) {
            console.warn(`[ACSVImage] ACVSImage $scrollParent not defined!`);
        }
        $img.data('zoom', {
            $ele: $img,
            $scrollParent: $scrollParent,
            offset: ACVSImage.offset($img.get(0)),
            updateOffset: () => $img.data('zoom').offset = ACVSImage.offset($img.get(0)),
            getOffset: () => ACVSImage.offset($img.get(0)),
            getOffsets: () => {
                return {
                    rect: ACVSImage.rectOffset($img.get(0)),
                    scroll: ACVSImage.scrollOffset()
                };
            },
            enabled: false,
            lastZoom: this.DEFAULT_ZOOM,
            scrollSpeed: 5,
            updateZoom: delta => {
                let self = $img.data('zoom');
                let zoom = self.lastZoom;
                if (delta !== undefined) {
                    delta *= self.scrollSpeed;
                    zoom = Math.max(zoom - delta, 1);
                    self.lastZoom = zoom;
                }
                const { width, height } = $img.data('zoom_size');
                $img.css({
                    width: `${Math.round(width * zoom)}px`,
                    height: `${Math.round(height * zoom)}px`
                });
            },
            reset: _ => {
                const { width, height } = $img.data('zoom_size');
                $img.css({
                    width: `${width}px`,
                    height: `${height}px`
                });
            },
            refresh: _ => {
                $img.data('zoom').offset = ACVSImage.offset($img.parent().get(0));
            }
        });
        if (options.allowZoom) {
            $img.click(e => {
                let data = $img.data('zoom');
                if (data.enabled !== true) {
                    // $ele.css('cursor', 'crosshair')
                    let lastMouse = { x: 0, y: 0 };
                    $img.mousemove((e, triggerData) => {
                        if (!e.pageX || !e.pageY) {
                            e = triggerData;
                        }
                        else {
                            data.pageX = e.pageX;
                            data.pageY = e.pageY;
                        }
                        const oldOffset = $img.data('zoom').offset;
                        const { $scrollParent } = $img.data('zoom');
                        const { width, height } = $img.data('zoom_size');
                        const dyn_width = $img.width();
                        const dyn_height = $img.height();
                        const mouse = (e == null) ? lastMouse : { x: e.pageX, y: e.pageY };
                        const s = {
                            top: $scrollParent.scrollTop(),
                            left: $scrollParent.scrollLeft()
                        };
                        const normalizedX = Math.min((mouse.x - oldOffset.left + s.left) / width, 1); // from LEFT: 0 to RIGHT: 1
                        const normalizedY = Math.min((mouse.y - oldOffset.top + s.top) / height, 1); // from TOP: 0 to BOTTOM: 1
                        // console.log(
                        //     mouse.y,
                        //     oldOffset.top,
                        //     (mouse.y - oldOffset.top + s.top),
                        //     (mouse.y - oldOffset.top + s.top) / height
                        // )
                        const xFactor = dyn_width - width;
                        const yFactor = dyn_height - height;
                        let new_x = -normalizedX * xFactor;
                        let new_y = -normalizedY * yFactor;
                        $img.css({
                            left: `${new_x}px`,
                            top: `${new_y}px`
                        });
                        lastMouse = mouse;
                    });
                    data.updateZoom();
                    $img.trigger('mousemove', {
                        pageX: e.pageX,
                        pageY: e.pageY
                    });
                    $img.parent().addClass('zooming');
                    data.enabled = true;
                }
                else {
                    // $ele.css('cursor', 'crosshair')
                    $img.unbind('mousemove');
                    $img.parent().removeClass('zooming');
                    data.enabled = false;
                }
            });
        }
        $img.dblclick(e => {
            $img.css({
                top: 0,
                left: 0,
                width: $img.parent().width(),
                height: $img.parent().height()
            });
            $img.data('zoom').lastZoom = this.DEFAULT_ZOOM;
        });
        $img.bind('mousewheel DOMMouseScroll MozMousePixelScroll', e => {
            const data = $img.data('zoom');
            if (!data.enabled)
                return;
            e.stopPropagation();
            e.preventDefault();
            data.updateZoom(e.originalEvent.deltaY / 1000);
            $img.trigger('mousemove', {
                pageX: e.pageX,
                pageY: e.pageY
            });
        });
        $img.css('cursor', options.allowZoom ? 'crosshair' : 'default');
        return $img;
    }
    static addZoomableImage($container, url, size) {
        const $img = $(`<div class="image"></div>`);
        $img.css({
            'background-size': 'cover',
            'position': 'absolute',
            'top': '-1px',
            'left': '-1px',
            'right': '-3px',
            'bottom': '-1px',
            'background-image': `url('${url}')`,
            'border': '0px solid transparent'
        });
        $img.data('zoom_size', size);
        $container.append($img);
        return $img;
    }
    static scale($ele, defaultSize, originalSize) {
        let { width, height } = defaultSize;
        if (width != null) {
            if (height == null) {
                const f = width / $ele.width();
                height = (originalSize?.height ?? $ele.height()) * f;
            }
        }
        else {
            if (height != null) {
                const f = height / $ele.height();
                width = $ele.width() * f;
            }
        }
        if (width == null && height == null) {
            width = $ele.width();
            height = $ele.height();
        }
        const container = $ele.parent();
        container.width(width);
        container.height(height);
        $ele.width(width);
        $ele.height(height);
        $ele.data('zoom').offset = ACVSImage.offset($ele.get(0));
        $ele.data('zoom_size', { width, height });
        $ele.css({
            'left': 0,
            'top': 0
        });
        $ele.data('zoom').lastZoom = this.DEFAULT_ZOOM;
        $ele.data('cachedDomSize', { width, height });
        return $ele;
    }
    static refresh($ele) {
        const zoomObj = $ele.data('zoom');
        if (zoomObj.hasOwnProperty('refresh')) {
            zoomObj.refresh();
        }
    }
    static createImageButtons(opt) {
        const { allowFilter, allowFullscreen, allowPrint } = Object.assign({ allowFilter: false, allowFullscreen: false, allowPrint: false }, opt ?? {});
        return ( /*html*/`
      <div class="acvs-btns">
        ${allowFilter ? /*html*/ `<div class="imageFilterBtn btn btn-primary btn-sm"> <i class="fa fa-sliders"></i> </div>` : ''}
        ${allowFullscreen ? /*html*/ `<div class="imageZoomBtn btn btn-primary btn-sm"> <i class="fa fa-expand"></i> </div>` : ''}
        ${allowPrint ? /*html*/ `<div class="imagePrintBtn btn btn-primary btn-sm"> <i class="fa-solid fa-print"></i> </div>` : ''}
      </div>
    `);
    }
    static async load(url, opt) {
        // Fetch dimensions of image in base64 format
        const size = await ACVSImage.fetch(url);
        const $container = $(`<div class="imageContainer">${ACVSImage.createImageButtons(opt)}</div>`);
        $container.css({
            'position': 'relative',
            'width': `${size.width}px`,
            'height': `${size.height}px`,
            'border': '1px solid black',
            'overflow': 'hidden',
            'background': 'white'
        });
        return { $container, size };
    }
    static async create($parent, url, options = {}) {
        const { defaultSize } = Object.assign({
            defaultSize: {
                width: null,
                height: null
            }
        }, options);
        const { $container, size } = await ACVSImage.load(url, options);
        $parent.append($container);
        const $img = ACVSImage.addZoomableImage($container, url, size);
        return ACVSImage.scale(ACVSImage.addListeners($img, options), defaultSize, size);
    }
}
