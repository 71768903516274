import { _getEle$ } from "../../utils/maps.js";
import { initTableAccordions } from "../../utils/table_accordions.js";
import { AInfoWindowHelper } from "../AInfoWindowHelper.js";
import { AResizeHandler } from "./AResizeHandler.js";
import { AWindowToolbar } from "./AWindowToolbar.js";
export class AWindowBase {
    async generate(opt) {
        // this.id = idAllocatorService.getNextId({prefix: 'win-'})
        const { alignTo, content } = opt;
        const parent = opt.lifeCycle === 'CONTINUOUS' ? 'body' : '#AjaxContent';
        let $win = $(`
      <div class="awin custom-scroll">
        <div class="awin-header">
          <i class="fa-regular fa-note-sticky"></i>
          <div class="toolbar-collection hidden"></div>
          <div class="awin-title hidden"></div>
          <i class="exit fa-light fa-xmark fa-fw"></i>
        </div>
        <div class="awin-content">
          ${content}
        </div>
        <div class="awin-anchor anchor-tl" anchor="tl"></div> <div class="awin-anchor anchor-tr" anchor="tr"></div>
        <div class="awin-anchor anchor-bl" anchor="bl"></div> <div class="awin-anchor anchor-br" anchor="br"></div>
      </div>
    `);
        // this.initResize($win)
        initTableAccordions($win);
        const fheight = $win.find('.awin-content tr').length * 21;
        $win.attr('summary-height', fheight);
        $win.attr('full-height', fheight * 2);
        AInfoWindowHelper.initDraggableWindow({ $window: $win });
        $win.find('.exit').on('click', () => this.invokeClose());
        $win.appendTo(parent);
        this.initSize($win, opt, { parent });
        this.initPos($win, { alignTo, parent });
        this.handler = new AResizeHandler(opt).attachTo($win);
        this.$internal = $win;
        AWindowBase.genFocusHandler($win);
        await this.initToolbar();
    }
    initToolbar() {
        return this.reloadToolbar();
    }
    async reloadToolbar() {
        const toolbarCfg = await Promise.resolve().then(() => this.configureToolbar());
        if (toolbarCfg) {
            this.toolbar = new AWindowToolbar(this, toolbarCfg);
            this.toolbar?.attachTo(this.$internal);
        }
    }
    set title(val) {
        this.$internal.find('.awin-title').removeClass('hidden').text(val.trim());
    }
    get title() {
        return this.$internal.find('.awin-title').text().trim();
    }
    initSize($win, sizeOpt, opt) {
        var sizeOrder = [sizeOpt.minSize, sizeOpt.size];
        const $p = _getEle$(opt.parent);
        // Set Default size
        sizeOrder.map(size => {
            var { width, height } = size ?? {};
            if (width === 'inherit') {
                width = $p.width();
            }
            if (height === 'inherit') {
                height = $p.height();
            }
            $win.css({ ...(height ? { height } : {}), ...(width ? { width } : {}) });
        });
        // Set Minimum Size
        if (sizeOpt.minSize) {
            $win.css({
                ...(sizeOpt.minSize.height ? { minHeight: sizeOpt.minSize.height } : {}),
                ...(sizeOpt.minSize.width ? { minWidth: sizeOpt.minSize.width } : {}),
            });
        }
    }
    initPos($win, opt) {
        // Move to middle if needed
        let offset;
        const $p = _getEle$(opt.parent);
        switch (opt.alignTo) {
            case 'right':
                offset = $p.offset();
                offset.left += $p.width() ?? 0;
                offset.left -= $win.width() ?? 0;
                break;
            case 'center':
                offset = this.getCenterOffset($win);
                break;
            default:
                offset = $p.offset();
                break;
        }
        $win.css({ ...offset });
    }
    getCenterOffset($win) {
        let { innerWidth: w, innerHeight: h } = window;
        return {
            left: (w - $win.width()) / 2,
            top: (h - $win.height()) / 2,
        };
    }
    static genFocusHandler($win) {
        if (!this.hasFocusHandler) {
            this.hasFocusHandler = true;
            $(document).on('click.awin-focus', (e) => {
                let $win = $(e.target);
                let $closest = $win.closest('.awin');
                this.focusOnWin(($closest.length > 0) ? $closest : $win);
            });
        }
        this.focusOnWin($win);
    }
    static focusOnWin($win) {
        if ($win.is('.awin') && !$win.is('.awin.awin-focus')) {
            $('.awin.awin-focus').removeClass('awin-focus');
            $win.addClass('awin-focus');
        }
    }
}
AWindowBase.hasFocusHandler = false;
