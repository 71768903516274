import { AEngine } from "../../core/AEngine.js";
import { ADragService } from "../../services/ADragService.js";
import { AFormatDate } from "../../utils/tools.js";
export class APage {
    constructor() {
        this.useDeprecated = false;
        const dragService = AEngine.getOrCreateInstance(ADragService);
        const eventKeys = this.useDeprecated ? [
            dragService.createDraggable({
                left: '.part-one',
                right: '.part-two',
                separator: '#separator1',
                direction: 'H',
            }),
            dragService.createDraggable({
                left: '.part-two',
                right: '.part-three',
                separator: '#separator2',
                direction: 'H',
            })
        ] : dragService.createDraggableGeneric();
        for (const eventKey of eventKeys) {
            Events.on(eventKey, () => {
                console.log('changed!', AFormatDate(new Date()));
            });
        }
    }
}
export function render() {
    return ( /*html*/`
    <div class="fh splitter">
      <div class="drag-section part-one"></div>
      <div id="separator1" class="drag-seperator separator"></div>
      <div class="drag-section part-two"></div>
      <div id="separator2" class="drag-seperator separator"></div>
      <div class="drag-section part-three"></div>
    </div>
  `);
}
