import { AError } from "./AError.js";
import { sleep } from "../core/AEngine.js";
import { initToolTips } from "../utils/tooltip.js";
export class ASortCards {
    constructor(options) {
        this.options = Object.assign({ fadeIn: false }, options);
        const { $parent, $chips } = this.options;
        if (!$parent || $parent.length === 0) {
            throw new Error(`$parent not defined!`);
        }
        if (!$chips || $chips.length === 0) {
            throw new Error(`$chips not defined!`);
        }
        const $sortkeys = $chips.find('[sortkey]');
        if ($sortkeys.length === 0) {
            throw new Error(`No chips found with [sortkey] attribute`);
        }
        $sortkeys.each((_, ele) => {
            $(ele).off('click');
            $(ele).on('click', (e) => {
                e.preventDefault();
                const sortKey = $(ele).attr('sortkey');
                const sortDir = $(ele).attr('sortDirection');
                const sortType = $(ele).attr('sortType');
                this.sortData({ sortKey, sortDir, sortType }).catch(AError.handle);
                $chips.find('.active').removeClass('active');
                $(ele).addClass('active');
            });
        });
    }
    async sortData({ sortKey, sortDir, sortType }) {
        const sortTime = Date.now();
        this.sortTime = sortTime;
        const sortMember = sortKey.replace('data-', '');
        const subjects = this.options.$parent.eq(0).find(`[${sortKey}]`);
        subjects.each((i, e) => { $(e).addClass('faded'); });
        let subjectsArray = Array.from(subjects);
        let sorted = subjectsArray.sort(this.createComparator({ sortMember, sortDir, sortType }));
        for (let e of sorted) {
            let parent = document.querySelector('.visual-parent');
            if (parent != null) {
                parent.appendChild(e);
            }
        }
        let i = 0;
        for (let e of sorted) {
            if (sortTime == this.sortTime) {
                $(e).removeClass('faded');
            }
            if (++i >= 4 && this.options.fadeIn) {
                i = 0;
                await sleep(80);
            }
        }
    }
    createComparator({ sortMember, sortDir, sortType }) {
        const convert = (sortType == 'Number') ? (v) => Number(v) : (v) => v;
        switch (sortDir) {
            case 'ASC':
                return function (a, b) {
                    let av = convert(a.dataset[sortMember]);
                    let bv = convert(b.dataset[sortMember]);
                    if (av < bv)
                        return -1;
                    if (av > bv)
                        return 1;
                    return 0;
                };
            case 'DESC':
                return function (a, b) {
                    let av = convert(a.dataset[sortMember]);
                    let bv = convert(b.dataset[sortMember]);
                    if (av < bv)
                        return 1;
                    if (av > bv)
                        return -1;
                    return 0;
                };
            default:
                throw new Error(`Sort direction not identified!`);
        }
    }
    append(htmlOrEle) {
        const $ele = (typeof htmlOrEle === 'string') ? $(htmlOrEle) : htmlOrEle;
        this.options.$parent.append($ele);
    }
    clearCards() {
        this.options.$parent.html('');
    }
    createToolTips() {
        initToolTips(this.options.$parent.find('[atooltip]'), {
            includeNewlines: true,
            align: 'left'
        });
    }
}
