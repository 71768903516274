export class AArrayService {
    orderedSort(arrayToSort, sortingTemplate, keyStr) {
        const result = [];
        if (keyStr !== undefined) {
            sortingTemplate.forEach(function (key) {
                var found = false;
                arrayToSort = arrayToSort.filter(function (item) {
                    if (!found && item[keyStr] == key) {
                        result.push(item);
                        found = true;
                        return false;
                    }
                    else {
                        return true;
                    }
                });
            });
        }
        else {
            sortingTemplate.forEach(function (key) {
                var found = false;
                arrayToSort = arrayToSort.filter(function (item) {
                    if (!found && item == key) {
                        result.push(item);
                        found = true;
                        return false;
                    }
                    else {
                        return true;
                    }
                });
            });
        }
        return result.concat(arrayToSort);
    }
}
