export class ATableKey {
    constructor(column, columnTranslated, parent) {
        this.column = column;
        this.columnTranslated = columnTranslated;
        this.parentKey = parent;
        // this.parentKey?.link(this)
    }
    link(child) {
        if (!this.children) {
            this.children = [];
        }
        if (!this.children.includes(child)) {
            this.children.push(child);
        }
    }
    ancestry() {
        let ancestors = [];
        let currKey = this;
        while (currKey !== undefined) {
            ancestors.unshift(currKey);
            currKey = currKey.parentKey;
        }
        return ancestors;
    }
    traversary(opt) {
        if (opt?.removeArrayIndices) {
            return this.ancestry().map(tableKey => {
                const c = tableKey.getColumn();
                return /[0-9]+/g.test(c) ? '*' : c;
            }).join('->');
        }
        else {
            return this.ancestry().map(tableKey => {
                return tableKey.getColumn();
            }).join('->');
        }
    }
    hasParent() {
        return this.parentKey !== undefined;
    }
    isWildcard() {
        return this.column === '*';
    }
    getColumn() {
        return this.column;
    }
    getColumnTranslated() {
        return this.columnTranslated.trim();
    }
}
