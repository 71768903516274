import { ATableFormatter } from "./ATableFormatter.js";
import { createArray } from "../../../utils/tools.js";
import { AEngine } from "../../AEngine.js";
export class ATableBuilder {
    constructor(options) {
        const opt = Object.assign({}, {
            tableFormatter: new ATableFormatter(),
            greyOutFields: true
        }, options || {});
        this.tableId = opt.tableId;
        this.insertHistory = [];
        this.tableFormatter = opt.tableFormatter;
        this.greyOutFields = opt.greyOutFields;
        this.checkForUnknownColumns = true;
    }
    /**
     * Inserts [column, column, column...] to the table
     * Syntax is insert([a, b, c], boolean)
     * @param array
     * @param nestedLevel
     */
    insert([tableKey, value]) {
        this.insertHistory.push({
            tableKey: tableKey,
            value: value
        });
    }
    sortHistory(arrayToSort, sortingTemplate) {
        const result = [];
        sortingTemplate.forEach(function (key) {
            let found = false;
            arrayToSort = arrayToSort.filter(function (item) {
                if (!found && item.tableKey.getColumn() == key) {
                    result.push(item);
                    found = true;
                    return false;
                }
                else {
                    return true;
                }
            });
        });
        return result.concat(arrayToSort);
    }
    prepareBuildData(opt) {
        const meta = { builder: this, unknown: [], missing: [] };
        const rows = this.sortHistory(this.insertHistory, opt.sorting).map((pair) => {
            return this.tableFormatter.formatTableColumn({ ...pair, meta });
        }).flat().filter((args) => args[2].hidden !== true);
        if (meta.missing.length) {
            const missing = [...new Set(meta.missing)];
            AEngine.warn(`TableFormatter Columns Missing: %c`, missing);
            // AError.handleSilent(`TableFormatter Columns Missing: ${missing.join(', ')}`, `TABLE_MISSING_${routeService.url?.hash ?? ''}`)
        }
        return rows;
    }
    /**
     * Created and returns a table formatted in formatted HTML
     * @param options options
     * @returns html
     */
    build(options) {
        const opt = Object.assign({ sorting: [], visible: true, padding: true }, options ?? {});
        const rows = this.prepareBuildData(opt);
        const { visible, padding } = opt;
        let html = rows.map(([key, value, c]) => {
            const { tableKey, hasChildren, formatOpt } = c;
            const attributeMap = {
                ...(this.greyOutFields && c.greyOut ? { class: 'tr-error' } : {}),
                ...(hasChildren ? { ropen: formatOpt.expanded ? "true" : "false", rkey: tableKey.traversary() } : {}),
                ...(tableKey.hasParent() ? { pkey: tableKey.parentKey.traversary() } : {})
            };
            const trAttrs = Object.entries(attributeMap).map(([attrName, attrVal]) => (`${attrName}="${attrVal}"`)).join(' ');
            const htmlIcon = (hasChildren) ? `<i class="icon icon-arrow-down ml-1"></i>` : '';
            const valueAttrs = formatOpt.style ? `style="${formatOpt.style?.replace('{value}', value)?.replace('{type}', formatOpt.type) ?? ''}"` : '';
            return ( /*html*/`
        <tr ${trAttrs}>
          <td style="white-space: pre;">${createArray((c.depth || 0) * 2, ' ').join('')}${key}${htmlIcon}</td>
          <td ${valueAttrs}>${value}</td>
        </tr>
      `);
        }).join('');
        const css = [
            ...(visible ? [] : ['display: none']),
            ...(opt.minHeight ? [`min-height: ${opt.minHeight}`] : []),
            ...(opt.minWidth ? [`min-width: ${opt.minWidth}`] : [])
        ].join('; ');
        const clsList = [
            ...(padding ? ['table-center-padding'] : []),
            ...(opt.classes ? opt.classes : []),
        ].join(' ');
        return ( /*html*/`<table id="${this.tableId}" class="${clsList}" style="${css}">${html}</table>`);
    }
}
//   _________________________________________
//  /                                         \  
// /===============\ Unit Test /===============\ 
// (_ => {
//     var unit = new ATableBuilder(2)
//     unit.insert(['name',    'wisse'])
//     unit.insert(['sibling', 'jaap'])
//     unit.insert(['Address', {"Street":"Louises gate","HouseNumber":"4","City":"Oslo"}])
//     console.log('table:\n', unit.build())
// })()
// \---------------/ Unit Test \---------------/ 
//  \_________________________________________/  
