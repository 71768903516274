import { AColor, HUE_MAX, SV_MAX } from "../classes/AColor.js";
import { AColorHSV } from "../classes/AColorHSV.js";
import { ABezier } from "../utils/bezier.js";
import { clamp } from "../utils/tools.js";
export class AInterpolateService {
    constructor() { }
    /**
     * Interpolates between two colors.
     * @param {AColor} a
     *  Starting color.
     * @param {AColor} b
     *  Target color.
     * @param {Number} t
     *  How far should it interpolate, 0 being a, 1 being b.
     */
    colorRGB(a, b, t) {
        return new AColor(a.r + (b.r - a.r) * t, a.g + (b.g - a.g) * t, a.b + (b.b - a.b) * t);
    }
    /**
     * Interpolates between two colors.
     * @param {AColorHSV} a
     *  Starting color.
     * @param {AColorHSV} b
     *  Target color.
     * @param {Number} t
     *  How far should it interpolate, 0 being a, 1 being b.
     */
    colorHSV(a, b, t) {
        a = a.normalized;
        b = b.normalized;
        // Hue interpolation
        let h = 0;
        let d = b.h - a.h;
        if (a.h > b.h) {
            let temp = b.h;
            b.h = a.h;
            a.h = temp;
            let tempV = b.v;
            b.v = a.v;
            a.v = tempV;
            d = -d;
            t = 1 - t;
        }
        // 180deg
        if (d > 0.5) {
            a.h = a.h + 1; // 360deg
            h = (a.h + t * (b.h - a.h)) % 1; // 360deg
        }
        // 180deg
        if (d <= 0.5) {
            h = a.h + t * d;
        }
        // Interpolates the rest
        return new AColorHSV(h * HUE_MAX, // H
        (a.s + t * (b.s - a.s)) * SV_MAX, // S
        (a.v + t * (b.v - a.v)) * SV_MAX);
    }
    /**
     * Interpolates between two colors.
     * @param {AColorHSV} a
     *  Starting color.
     * @param {AColorHSV} b
     *  Target color.
     * @param {Number} chunkCount
     *  How many different colors should be returned
     */
    colorsHSV(a, b, chunkCount) {
        const colors = [];
        const step = 1 / Math.max(chunkCount - 1, 1);
        for (let i = 0; i < chunkCount; i++) {
            const clr = this.colorHSV(a, b, step * i).rgb.hexi;
            colors.push(clr);
        }
        colors.push(b.rgb.hexi);
        return colors;
    }
    lerpTo(opt) {
        if (opt.transition !== undefined) {
            opt.colors = opt.transition.map(v => v.color);
            opt.bounds = opt.transition.map(v => v.x);
        }
        const inputColors = (opt.colors?.length > 0) ? opt.colors : ['#3366cc', '#dc3912', '#ff9900']; //, '#109618', '#990099', '#0099c6', '#dd4477', '#66aa00', '#b82e2e', '#316395', '#994499']
        const bounds = (opt.bounds?.length > 0) ? opt.bounds : [25, 50, 75];
        const colors = inputColors.map(hex => new AColor(hex).hsv);
        if (opt.value <= bounds[0]) {
            // first
            return colors[0];
        }
        else if (opt.value >= bounds[bounds.length - 1]) {
            // last
            return colors[colors.length - 1];
        }
        // let left = -1, right = -1
        const val = clamp(opt.value, bounds[0], bounds[bounds.length - 1]);
        for (let i = 1; i < bounds.length; i++) {
            if (opt.value < bounds[i]) {
                // ['#3366cc', '#dc3912', '#ff9900']
                // [25, 50, 75]
                let bound = {
                    lower: bounds[i - 1],
                    upper: bounds[i]
                };
                let color = {
                    lower: colors[i - 1],
                    upper: colors[i]
                };
                const t = (val - bound.lower) / (bound.upper - bound.lower);
                return this.colorHSV(color.lower, color.upper, t);
            }
        }
        return new AColor('#ff00a3').hsv;
    }
    lerpToExp(opt) {
        var b = ABezier({ x: 0, y: 1 }, { x: 0, y: 0 }, { x: 0, y: 0 }, { x: 1, y: 0 });
        for (let x = 0; x <= 50; x++) {
            let v = b.y(x === 0 ? 0 : (x / 50));
            console.log(`${x}\t ${(x / 50).toFixed(2)}\t`, v.toFixed(2), ''.padStart(v * 100, '='));
        }
    }
    bezierTest() {
        const b = ABezier({ x: 0, y: 1 }, { x: 0.5, y: 1 }, { x: 1, y: 0.5 }, { x: 1, y: 0 });
        for (let x = 0; x < 11; x++)
            console.log(x, globalThis.b.y(x * 0.1));
        return b;
    }
}
