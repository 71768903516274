import { AError } from "../classes/AError.js";
import { APolicy } from "../classes/APolicy.js";
export class APolicyService {
    constructor() {
        this.policies = [];
    }
    add(method) {
        if (method instanceof Function) {
            this.policies.push(new APolicy(method));
        }
        else {
            throw new Error(`Policy received isn't a function or promise!`);
        }
    }
    addRange(methods) {
        for (let p of methods) {
            this.add(p);
        }
    }
    getPolicyResults() {
        return Promise.all(this.policies.map(p => p.run()));
    }
    async run() {
        const results = await this.getPolicyResults();
        for (const data of results) {
            if (data.result !== true) {
                if (data.details) {
                    AError.handle(data.details);
                }
                return false;
            }
        }
        return true;
    }
}
