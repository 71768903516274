export class AVerificationService {
    constructor() { }
    /**
     * @private
     */
    get __osloOptions() {
        return {
            "FINED": {
                "Text": {
                    "EN": "Fined",
                    "NO": "Bøtelagt"
                },
                "Options": [
                    {
                        "Code": "FINED|NOTPAID",
                        "Text": {
                            "EN": "Fine - not paid",
                            "NO": "Kontrollsanksjon"
                        },
                        "Description": {
                            "EN": "Car gets fine for not having paid"
                        }
                    },
                    {
                        "Code": "FINED|ILLEGAL",
                        "Text": {
                            "EN": "Fine - illegal parking",
                            "NO": "Parkeringsgebyr"
                        },
                        "Description": {
                            "EN": "Car gets fine for illegal parking"
                        }
                    }
                ]
            },
            "EXC": {
                "Text": {
                    "EN": "Exception",
                    "NO": "Unntak"
                },
                "Options": [
                    {
                        "Code": "OK|PERMIT",
                        "Text": {
                            "EN": "Valid parking",
                            "NO": "Gyldig parkering"
                        },
                        "Description": {
                            "EN": "Car has parking right"
                        }
                    },
                    {
                        "Code": "EXC|LOADUNLOAD",
                        "Text": {
                            "EN": "Loading/Unloading",
                            "NO": "Lovlig aktivitet"
                        },
                        "Description": {
                            "EN": "Loading/Unloading or legal activity"
                        }
                    },
                    {
                        "Code": "NOTFOUND",
                        "Text": {
                            "EN": "Car not found",
                            "NO": "Kjøretøy borte"
                        },
                        "Description": {
                            "EN": "Car not found or no car detected"
                        }
                    },
                    {
                        "Code": "OK|DISABLED",
                        "Text": {
                            "EN": "Handicap card",
                            "NO": "HC-kort"
                        },
                        "Description": {
                            "EN": "Handicap card"
                        }
                    },
                    {
                        "Code": "EXC|WRONGSIGNES",
                        "Text": {
                            "EN": "Signage not valid",
                            "NO": "Avvik skilting"
                        },
                        "Description": {
                            "EN": "Signage not valid"
                        }
                    },
                    {
                        "Code": "OK|FINED",
                        "Text": {
                            "EN": "Already fined",
                            "NO": "Har ileggelse"
                        },
                        "Description": {
                            "EN": "Car already has a fine"
                        }
                    },
                    {
                        "Code": "EXC|CONTAINER",
                        "Text": {
                            "EN": "Container",
                            "NO": "Container"
                        },
                        "Description": {
                            "EN": "Container on parking spot"
                        }
                    },
                    {
                        "Code": "EXC|WASTESACK",
                        "Text": {
                            "EN": "Waste sack/other",
                            "NO": "Avfallsekk/andre"
                        },
                        "Description": {
                            "EN": "Waste sack or other on parking spot"
                        }
                    },
                    {
                        "Code": "EXC|UNREGVEH",
                        "Text": {
                            "EN": "Unregist. vehicle",
                            "NO": "Uregistrert kjøretøy"
                        },
                        "Description": {
                            "EN": "Unregistered vehicle/vehicle without licensplate"
                        }
                    },
                    {
                        "Code": "OK|POLAMBFIRE",
                        "Text": {
                            "EN": "Police/Amb./Fire",
                            "NO": "Nødetat"
                        },
                        "Description": {
                            "EN": "Police/Ambulance/Firebrigade vehicle"
                        }
                    },
                    {
                        "Code": "EXC|OTHER",
                        "Text": {
                            "EN": "Other",
                            "NO": "Annet"
                        },
                        "Description": {
                            "EN": "Other reason"
                        }
                    }
                ]
            }
        };
    }
    /**
     * @private
     */
    get __thesselonikiOptions() {
        return [
            {
                "Code": "EXCEPTION",
                "Text": "Εξαίρεση",
                "Options": [
                    {
                        "Text": "Φορτοεκφόρτωση",
                        "Code": "EXCEPTION|LU"
                    },
                    {
                        "Text": "Θέση ΑμΕΑ",
                        "Code": "EXCEPTION|HC"
                    },
                    {
                        "Text": "Θέση κατόχου άδειας",
                        "Code": "EXCEPTION|PH"
                    },
                    {
                        "Text": "Θέση carsharing",
                        "Code": "EXCEPTION|CS"
                    },
                    {
                        "Text": "Είσοδος σε γκαράζ",
                        "Code": "EXCEPTION|DW"
                    },
                    {
                        "Text": "Πιάτσα ταξί",
                        "Code": "EXCEPTION|TX"
                    },
                    {
                        "Text": "Μπλε ζώνη",
                        "Code": "EXCEPTION|BZ"
                    },
                    {
                        "Text": "Άλλο",
                        "Code": "EXCEPTION|OT"
                    }
                ]
            },
            {
                "Code": "OK",
                "Text": "Έγκυρο δικαίωμα στάθμευσης",
                "Options": [
                    {
                        "Text": "Άδεια στάθμευσης από μηχάνημα",
                        "Code": "OK|PM"
                    },
                    {
                        "Text": "Άδεια κατοίκου",
                        "Code": "OK|RP"
                    },
                    {
                        "Text": "ΑμΕΑ",
                        "Code": "OK|DS"
                    },
                    {
                        "Text": "Εξαίρεση",
                        "Code": "OK|EX"
                    },
                    {
                        "Text": "Άδεια επισκέπτη",
                        "Code": "OK|VP"
                    },
                    {
                        "Text": "Άδεια εταιρίας",
                        "Code": "OK|CP"
                    },
                    {
                        "Text": "Αστυνομικό / Ασθενοφόρο / Πυροσβεστικό",
                        "Code": "OK|PO"
                    },
                    {
                        "Text": "Συνδεδεμένο τρέιλερ",
                        "Code": "OK|TR"
                    },
                    {
                        "Text": "Έχει ήδη κλήση",
                        "Code": "OK|AF"
                    },
                    {
                        "Text": "Άλλο",
                        "Code": "OK|OT"
                    }
                ]
            },
            {
                "Code": "GIVEFINE",
                "Text": "Έκδοση κλήσης"
            }
        ];
    }
    /**
     * @private
     */
    get VerifyResultOptions() {
        return Config.VerifyResultOptions || {};
    }
    getOptions() {
        const options = this.VerifyResultOptions;
        if (!(options instanceof Array)) {
            return options;
        }
        const output = {};
        for (let { Code, Options } of options) {
            output[Code] = { Options: [] };
            if (Options) {
                for (let subOption of Options) {
                    output[Code].Options.push({ Code: subOption.Code });
                }
            }
        }
        return output;
    }
}
