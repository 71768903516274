import { AError } from "../classes/AError.js";
import { EVENTS } from "../services/AEventService.js";
import { mergeDeep } from "../utils/tools.js";
Highcharts.setOptions({
    global: {
        useUTC: false
    }
});
Highcharts.setOptions({
    // colors: ['#7cb5ec','#434348','#90ed7d','#f7a35c','#8085e9','#f15c80','#e4d354','#2b908f','#f45b5b','#91e8e1'],
    chart: {
        style: {
            // fontFamily: '"Lucida Grande", "Lucida Sans Unicode", Verdana, Arial, Helvetica, sans-serif',
            fontFamily: `Roboto,sans-serif,-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI","Helvetica Neue"!important`,
            fontSize: '16px'
        }
    },
    title: { style: { fontWeight: 'normal', fontSize: '18px' } },
    plotOptions: {
        area: { lineWidth: 2 },
        column: { borderRadius: 0 },
        pie: { borderRadius: 0, dataLabels: { connectorShape: 'fixedOffset' } },
        line: { lineWidth: 2 },
        spline: { lineWidth: 2 }
    },
    tooltip: { borderWidth: 1, shadow: true },
    legend: {
        itemStyle: { fontWeight: 'bold', fontSize: '12px' },
        itemHiddenStyle: { color: '#cccccc', textDecoration: 'none' },
        squareSymbol: true,
    },
    xAxis: { labels: { distance: 8, style: { color: '#666666', fontSize: '11px' } }, lineColor: '#ccd6eb' },
    yAxis: { labels: { distance: 8, style: { color: '#666666', fontSize: '11px' } } },
    colorAxis: { labels: { distance: 8, style: { color: '#666666', fontSize: '11px' } }, maxColor: '#003399', minColor: '#e6ebf5' },
    scrollbar: { barBorderRadius: 0, barBorderWidth: 1, buttonsEnabled: true, height: 14, margin: 0, rifleColor: '#333', trackBackgroundColor: '#f2f2f2', trackBorderRadius: 0 }
});
export function ResizeChart(highchart, size, animate, opt) {
    if (highchart?.container?.isConnected !== true) {
        return highchart;
    }
    const { width, height } = Object.assign({
        width: highchart?.chartWidth ?? 100,
        height: highchart?.chartHeight ?? 100,
    }, size || {});
    try {
        highchart?.setSize(width, height, animate);
    }
    catch (err) {
        if (opt?.silentErrors === true) {
            console.error(err);
        }
        else {
            AError.handle(err);
        }
    }
    return highchart;
}
export function QueryResponseToSeries(QueryResponse, opt) {
    // 'line'|'column'|'bar'|'spline'|'area'|'areaspline'|'scatter'|'pie'
    const { chartType, unit, meta } = Object.assign({ unit: Translate.getCacheFast('detections') }, opt);
    let Series = [];
    switch (chartType) {
        case 'pie':
            Series = [{
                    type: opt.chartType,
                    name: unit,
                    // colorByPoint: true,
                    data: QueryResponse.Rows.map((row, i) => {
                        return {
                            // color: colorArray[i],
                            key: row[0],
                            name: row[0],
                            y: row.slice(1).reduce((total, v) => total + v, 0),
                        };
                    })
                }];
            break;
        default:
            Series = QueryResponse.Rows.map((row, i) => {
                return {
                    type: opt.chartType,
                    // colorByPoint: false,
                    // color: colorArray[i],
                    name: row[0],
                    data: row.slice(1),
                    drilldown: meta?.drilldown ? meta.drilldown[row[0]] : undefined,
                };
            });
            break;
    }
    return Series;
}
const toFixed = (x, d) => {
    if (!d)
        return x.toFixed(d); // don't go wrong if no decimal
    return x.toFixed(d).replace(/\.?0+$/, '');
};
export async function AChartTooltipFormatter({ unit, label, addPercentage, hideZeroValues, addTotal, mapToLabel }) {
    return function () {
        let context = this;
        let points = context.points;
        if (points === undefined) {
            points = [this.point];
            addPercentage = false;
            addTotal = false;
            // return (/*html*/`<b>${context.point.y}</b> ${context.series.name}`)
        }
        const sum = points.map(p => p.y).reduce((a, b) => a + b, 0);
        const parts = (hideZeroValues ? points.filter(p => p.y !== 0) : points).map((point) => {
            const perc = Math.round(1000.0 * point.y / sum) / 10.0;
            let percStr = sum ? (`${perc}%`) : '';
            const isUnitPerc = point.series.name.trim() === '%';
            return ( /*html*/`
        <tr>
          <td><span class="chart-dot" style="background-color: ${point.series.color}"></span> ${isUnitPerc ? point.key : point.series.name}: </td>
          <td>${point.y} ${unit}</td>
          ${addPercentage ? `<td>${percStr}</td>` : ''}
        </tr>
      `);
        });
        if (addTotal !== false) {
            parts.push(/*html*/ `
        <tr>
          <td><b>${Translate.getCacheFast('total')}: </b></td>
          <td><b>${toFixed(sum, 2)} ${unit}</b></td>
          ${addPercentage ? `<td><b>100%</b></td>` : ''}
        </tr>
      `);
        }
        return ( /*html*/`
      <table class="tooltip-chart-table">
        <thead>
          <tr>
            <th colspan="${addPercentage ? '3' : '2'}">${mapToLabel ? mapToLabel(context) : context.x ?? label ?? context.key}</th>
          </tr>
        </thead>
        <tbody>
          ${parts.join('')}
        </tbody>
      </table>
    `);
    };
}
export async function MakeColumnChart(makeOpt) {
    const { series, drilldown, bind, title, type, inverted, polar, flat, unit, height, allowExport } = makeOpt;
    return Highcharts.chart({
        series: series,
        drilldown: drilldown,
        // categories: categories, // translate ? opt.res.ColumnsTranslated.slice(1) : opt.res.Columns.slice(1),
        xAxis: makeOpt.xAxis ?? {
            tickmarkPlacement: 'on',
            title: { text: '' }
        },
        yAxis: makeOpt.yAxis ?? { title: { text: unit } },
        chart: {
            renderTo: bind,
            type: type,
            height: height,
            inverted: inverted,
            polar: polar,
            options3d: (flat === true) ? undefined : {
                enabled: true,
                alpha: 20,
                beta: 0,
                depth: 50
            },
            events: {
                afterPrint: () => {
                    console.log('afterPrint');
                    Events.tryInvoke(EVENTS.CONTENT_RESIZE);
                },
                beforePrint: () => {
                    // Events.tryInvoke(EVENTS.CONTENT_RESIZE)
                }
            }
        },
        title: {
            text: title ?? ''
        },
        subtitle: {
            text: ''
        },
        tooltip: {
            formatter: makeOpt.tooltip?.formatter ?? await AChartTooltipFormatter({ ...makeOpt, addPercentage: true, hideZeroValues: false }),
            shared: true,
            useHTML: true,
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            borderWidth: 0,
            padding: 0,
            valueSuffix: ` ${unit}`,
        },
        plotOptions: mergeDeep({}, makeOpt.plotOptions ?? {}, {
            pie: {
                allowPointSelect: true,
                dataLabels: {
                    enabled: true,
                    format: '<b class="{point.labelClass}">{point.name}</b>: {point.percentage:.1f} %',
                    distance: 10
                },
                showInLegend: true
            },
            area: {
                stacking: true
            },
            areaspline: {
                stacking: true
            },
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: true,
                    // format: `{point.y} ${unit === '%' ? '%' : ''}`,
                },
                depth: 25
            },
        }),
        exporting: {
            enabled: allowExport
        },
        legend: makeOpt.legend,
    });
}
