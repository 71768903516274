import { AError } from "../classes/AError.js";
import { AIdAllocator } from "../core/allocator/AIdAllocator.js";
import { AEngine } from "../core/AEngine.js";
import { ALERT_TITLES, ALERT_BUTTONS, ALERT_STATUS } from "../services/AAlertService.js";
import { EVENTS, AEventService } from "../services/AEventService.js";
import { ADurationToSeconds } from "../utils/tools.js";
import { AKpiBlock, AKpiState } from "./AKpiBlock.js";
import { AKpiBlockDeprecated } from "./AKpiBlockDeprecated.js";
import { globalKpiConfig, globalKpiWidthOptions } from "./AKpiConfig.js";
import { escapeHtml } from "../utils/json.js";
export class AKpiSystem {
    constructor(options) {
        this.options = options;
        this.widthOptions = globalKpiWidthOptions;
        this.allKpiTypes = Object.keys(globalKpiConfig);
        this.allKpiTypesEnabled = this.allKpiTypes.filter(kpiType => !['AKpiBlockDeprecated'].includes(kpiType));
        this.enableRefreshing = true;
        this.idAllocator = new AIdAllocator();
        this.allowRealtimeWidgets = options.isOperationsPage;
        this.$container = options.$container;
        this.$showIfEmpty = options.$showIfEmpty;
        this.$btnCreate = options.$btnCreate;
        this.widgets = options.widgets;
        this.kpis = options.kpis || undefined;
        this.$popover = $();
        this.checkForEmptyPage();
        this.translate();
        this.createResizeListener();
        this.createKpiEventHandlers();
    }
    get View() {
        return this.options.getView();
    }
    get AllowEdit() {
        return this.options.allowEdit ?? false;
    }
    get ShowSubtitle() {
        return this.options.showSubtitle ?? false;
    }
    get DisableMenu() {
        return this.options.disableMenu ?? false;
    }
    getSubtitle($select) {
        if (this.options.overrideSubtitle) {
            return this.options.overrideSubtitle();
        }
        return $select.find('option:selected').text();
    }
    checkForEmptyPage() {
        this.$showIfEmpty.toggleClass('hidden', (this.widgets.length !== 0));
    }
    static calcCombinations(arrays) {
        return arrays.reduce((a, b, i) => a.flatMap(x => b.map(y => (i === 0) ? y : (x + ' | ' + y))), ['']);
    }
    static calcCombination(...values) {
        return AKpiSystem.calcCombinations(values.map(v => [v])).pop();
    }
    findKpiCfg(KpiType) {
        if (!globalKpiConfig.hasOwnProperty(KpiType)) {
            KpiType = 'AKpiBlockDeprecated';
            // return undefined
        }
        const cfgPartial = globalKpiConfig[KpiType];
        let cfg;
        cfg = Object.assign({
            KpiType,
            getSettings: () => { return {}; },
            getAllPrimaryKeys: async () => {
                const settings = await cfg.getSettings();
                let combinations = AKpiSystem.calcCombinations(Object.keys(settings).map(k => {
                    const arr = settings[k];
                    return arr.map(v => {
                        return v.key ?? v.text ?? v;
                    });
                }));
                const View = this.options.getView();
                const KpiType = cfg.KpiType;
                return combinations.map(Name => {
                    return { View, KpiType, Name };
                });
            }
        }, cfgPartial);
        return cfg;
    }
    shouldCheckSubOption(KpiType) {
        const cfg = globalKpiConfig[KpiType];
        return cfg?.shouldCheckSubOption ?? true;
    }
    // private kpiClsToInstance(kpiCls: string, options?: {[key: string]: any}): AKpiBlock|undefined {
    kpiClsToInstance(widget) {
        const querySelector = `#${this.idAllocator.getNextHexId({ prefix: widget.KpiType })}`;
        const kpiCfg = this.findKpiCfg(widget.KpiType);
        const ClsType = kpiCfg?.getCls();
        const kpiOptions = {
            kpiSystem: this,
            widgetOptions: widget,
            querySelector,
            Name: widget.Name,
            KpiType: widget.KpiType,
            Width: widget.Width,
            Settings: widget.Settings,
        };
        if (ClsType == null) {
            return new AKpiBlockDeprecated(kpiOptions);
        }
        return new ClsType(kpiOptions);
    }
    async translate() {
        this.translations = await Translate.get([
            'toggle fullscreen',
            'toggle table view',
            'add widget',
            'edit widget',
            'delete from view',
            'Custom KPI'
        ]);
    }
    /**
     * Update KPI Block heights
     */
    updateBlockHeight($kpiParent) {
        const unitHeight = Math.round(($('#AjaxContent').eq(0).outerHeight() || 0) / 12), unitMarginStart = 18, unitMargin = 15;
        const $findIn = ($kpiParent !== undefined) ? $kpiParent : $('#AjaxContent');
        $findIn.find('.kpi-block:not(.kpi-fullscreen):not(.kpi-skip-resize)').each((_, block) => {
            const $block = $(block);
            const vertVal = parseInt((($block.attr('class') || '').split(' ').filter(v => v.indexOf('kpi-vert-') > -1).pop() || 'kpi-vert-4').replace('kpi-vert-', ''));
            const subtract = (unitMarginStart + (44.0) + unitMargin * vertVal / 12);
            $block.css({
                height: Math.round(Math.max(this.options?.maxKpiHeight ?? 300, unitHeight * vertVal - subtract)) + 'px'
            });
        });
    }
    /**
     * Notifies all KPI Block to resize
     */
    createResizeListener() {
        Events.on(EVENTS.CONTENT_RESIZE, () => {
            this.updateBlockHeight();
        });
    }
    /**
     * Creates event handlers for creating new KPIS and opening & closing KPI options popover menu
     */
    createKpiEventHandlers() {
        if (this.DisableMenu) {
            return;
        }
        this.$btnCreate.on('click', (e) => {
            this.showCreateModal();
        });
        const $popover = $('.menu-kpi');
        if ($popover.length === 0) {
            throw new Error(`$('.menu-kpi') couldn't be found!`);
        }
        $(document).on('mouseup.menu-kpi', (e) => {
            const $clicked = $(e.target);
            const $found = $clicked.closest('.menu-kpi');
            if ($found.length === 0) {
                menuService.setVisible($('.menu-kpi'), false);
            }
        });
        Events.on(EVENTS.DESTRUCT, () => {
            $(document).off('mouseup.menu-kpi');
        });
        $('#AjaxContent').append($popover);
        this.$popover = $popover;
    }
    toggleFullScreen({ $kpis, $kpi }) {
        $kpi.toggleClass('kpi-fullscreen');
        if ($kpi.hasClass('kpi-fullscreen')) {
            $kpi.css({ height: '' });
        }
        $kpis.toggleClass('kpis-has-fullscreen', $kpi.hasClass('kpi-fullscreen'));
        $('.kpi-block:not(.kpi-fullscreen)').toArray().map(e => $(e)).map($iKpi => {
            $iKpi.toggleClass('invisible', $kpi.hasClass('kpi-fullscreen'));
            $iKpi.toggleClass('hidden', $kpi.hasClass('kpi-fullscreen'));
        });
        this.updateBlockHeight();
        AEngine.get(AEventService).tryInvoke(EVENTS.CONTENT_RESIZE);
    }
    async showCreateModal() {
        const kpiCfgs = this.allKpiTypesEnabled.map(type => {
            return this.findKpiCfg(type);
        });
        // const customKpiTypes = await widgetService.fetchAllCustomCharts()
        const kpiSelectOptions = (await Promise.all(kpiCfgs.map(async ({ KpiType, overrideKpiType }, i) => {
            const isEnabled = this.shouldCheckSubOption(KpiType) ? widgetService.optionEnabled(KpiType) : true;
            const attr = (isEnabled) ? '' : 'disabled';
            const text = await Translate.get(overrideKpiType ?? KpiType.replace('AKpiBlock', ''));
            return {
                text: text,
                option: ( /*html*/`
          <option kpitype="${escapeHtml(KpiType)}" ${attr} ${(i === 0) ? 'selected="selected"' : ''} value="${escapeHtml(KpiType)}">
            ${text}
          </option>
        `),
            };
        }))).sort((a, b) => a.text.localeCompare(b.text)).map(v => v.option);
        const alert = Alerts.show({
            title: ALERT_TITLES.None,
            buttons: ALERT_BUTTONS.createCancel,
            content: ( /*html*/`
        <div class="columns">
        ${kpiCfgs.filter(kpiCfg => kpiCfg.hasImage === true).map((cfg, i) => ( /*html*/`
            <div kpi-value="${cfg.KpiType}" class="column text-center ${(i !== 0) ? 'hidden' : ''}" style="height: 200px;">
              <img src="/img/kpi/${cfg.KpiType}.png" style="height: calc(100% - 20px); object-fit: cover;" />
            </div>
          `)).join('')}
        </div>
        <div>
          <label for="kpi-type" class="form-label">Type</label>
          <select id="kpi-type" class="form-input">
            ${kpiSelectOptions.join('')}
          </select>
        </div>
        <div class="kpi-custom-options"></div>
        <div>
          <label for="width" class="form-label">Width</label>
          <select id="width" class="form-input">
            ${this.widthOptions.map(({ value, text }) => {
                return (`<option value="${value}">${text}</option>`);
            }).join('')}</select>
        </div>
      `),
        });
        const { $ele } = alert;
        $ele.find('#width').val('col-4');
        let settings = {};
        const $kpiType = $ele.find('#kpi-type');
        $kpiType.on('change', async () => {
            settings = {};
            const kpiCfg = this.findKpiCfg($kpiType.find('option:selected').attr('kpitype'));
            // const kpiType = $kpiType.find('option:selected').attr('kpitype')! as string
            // const Name = $kpiType.val()! as string
            // if (kpiCfg.KpiType === 'AKpiBlockKpiChart') {
            //   settings['kpiName'] = $kpiType.val()
            // }
            $ele.find('[kpi-value]').addClass('hidden');
            $ele.find(`[kpi-value="${kpiCfg.KpiType}"]`).removeClass('hidden');
            const customOptions = await kpiCfg.getSettings();
            const valuesToTranslate = [
                ...Object.keys(customOptions),
                ...Object.values(customOptions).map(arr => arr.filter(item => item.translate !== false).map(item => item.text)).flat()
            ];
            const translations = await Loading.waitForPromises(Translate.get(valuesToTranslate));
            const $customOptions = $ele.find('.kpi-custom-options');
            $customOptions.html('');
            const $customOptionsArr = Object.keys(customOptions).map((key) => {
                const $inputGroup = $(/*html*/ `
          <div>
            <label for="${key}" class="form-label">${translations[key]}</label>
            <select id="${key}" class="form-input">
              ${customOptions[key].map(({ key, text }) => {
                    const attr = widgetService.optionEnabled(kpiCfg.KpiType, key) ? '' : 'disabled';
                    return (`<option ${attr} value="${escapeHtml(key)}">${translations[text] ?? text}</option>`);
                }).join('')}
            </select>
          </div>
        `);
                const $input = $inputGroup.find('.form-input');
                $input.on('change', (e) => {
                    settings[key] = $input.val();
                    if ($input.hasClass('aci-initialized')) {
                        this.updateModal(kpiCfg, settings);
                    }
                    else {
                        $input.addClass('aci-initialized');
                    }
                });
                $input.trigger('change');
                return $inputGroup;
            });
            $customOptionsArr.map($inputGroup => $customOptions.append($inputGroup));
            this.updateModal(kpiCfg, settings);
        });
        $kpiType.trigger('change');
        alert.on(ALERT_STATUS.ON_ACTION_PROCEED, async () => {
            const kpiCfg = this.findKpiCfg($kpiType.find('option:selected').attr('kpiType'));
            if (!stateService.isUserACI() && (kpiCfg.KpiType === 'AKpiBlockKpiChart' || kpiCfg.KpiType === 'AKpiBlockChartAggragation')) {
                Alerts.show({
                    title: ALERT_TITLES.Warning,
                    buttons: ALERT_BUTTONS.ok,
                    content: await Translate.get(`Deze KPI is niet beschikbaar voor deze pagina!`)
                });
                return false;
            }
            // const staticCls = this.findKpiCls(KpiType)
            // if (kpiCfg?.getCls() == null) {
            //   return false
            // }
            // const Name: string = this.getKpiInsertName(KpiType, settings)
            const widgetPK = {
                View: this.options.getView(),
                KpiType: kpiCfg.KpiType,
                Name: kpiCfg.getUniqueName(settings),
            };
            if (await widgetService.widgetExists(widgetPK)) {
                return false;
            }
            const createdObj = await widgetService.createWidget({
                ...widgetPK,
                Width: $ele.find('#width').val() || 'col-4',
                Settings: (Object.keys(settings || {}).length > 0) ? settings : null,
                Sort: this.widgets.map(v => v.Sort).reduce((a, b) => Math.max(a, b), 0) + 1,
                Active: 1
            });
            this.options.onWidgetCreated(createdObj);
        });
        alert.on(ALERT_STATUS.ON_MODAL_CLOSED, () => {
            this.enableRefreshing = true;
        });
    }
    updateModal(kpiCfg, settings) {
        // $('.modal.active .modal-title').text(kpiCfg.getUniqueName(settings))
        // $('.modal.active .modal-title').removeClass('invisible')
        const widgetPK = {
            View: this.options.getView(),
            KpiType: kpiCfg.KpiType,
            Name: kpiCfg.getUniqueName(settings),
        };
        const validateSuccess = kpiCfg.validate ? kpiCfg.validate(settings) : true;
        const isDisabled = widgetService.widgetExistsFast(widgetPK) != null || !validateSuccess;
        $('.modal.active #option1').prop('disabled', isDisabled);
    }
    async showEditModal(opt) {
        const $kpis = $('.configurable-kpis .kpi-block').toArray().map(kpi => $(kpi));
        const widgetNames = $('.configurable-kpis .kpi-title').toArray().map(e => $(e)).map($e => {
            return $e.find(':not(.kpi-filter-text)').toArray().map(span => $(span).text()).join('');
        });
        const found = $kpis.map(($k, i) => ($k.is(opt.$kpi)) ? { $k, i } : undefined).find(v => v !== undefined);
        if (found === undefined) {
            return;
        }
        let { $k, i } = found;
        console.log('found', $k, this.widgets[i]);
        const alert = Alerts.show({
            translatedTitle: await Translate.get('Edit'),
            buttons: ALERT_BUTTONS.saveCancel,
            content: await requestService.translateDom(/*html*/ `
        <form onsubmit="return false;">
          <label for="type" class="form-label hidden">Type</label>
          <input id="type" type="text" class="form-input hidden" value="${this.widgets[i].KpiType}" />

          <label for="order" class="form-label">Order</label>
          <select id="order" class="form-input" style="min-height: 180px;" rows="${this.widgets.length}" multiple disabled>
            ${this.widgets.map(({ KpiType, Settings }, index) => {
                const attrs = [
                    `data-kpi-type="${KpiType}"`,
                    (i === index) ? `selected="selected"` : null
                ].filter(v => v !== null).join(' ');
                return (`<option ${attrs}>${widgetNames[index]}</option>`);
            }).join('')}</select>
          <div class="input-group">
            <button id="move-up" class="btn btn-aci btn-shadow-sm input-group-btn btn-sm" style="width: 50%"><i class="fa fa-plus fa-sm" aria-hidden="true"></i>Move Up</button>
            <button id="move-down" class="btn btn-white btn-shadow-sm input-group-btn btn-sm" style="width: 50%"><i class="fa fa-minus fa-sm" aria-hidden="true"></i>Move Down</button>
          </div>

          <div class="kpi-custom-options"></div>

          <label for="width" class="form-label">Width</label>
          <select id="width" class="form-input">
            ${this.widthOptions.map(({ value, text }) => {
                const attrs = (value === this.widgets[i].Width) ? `class="active" selected="selected"` : '';
                return (`<option ${attrs} value="${value}">${text}</option>`);
            }).join('')}</select>
        </form>
      `)
        });
        const { $ele } = alert;
        let { KpiType, Settings } = this.widgets[i];
        const kpiCfg = this.findKpiCfg(KpiType);
        const customOptions = await kpiCfg.getSettings();
        const valuesToTranslate = [
            ...Object.keys(customOptions),
            ...Object.values(customOptions).map(arr => arr.filter(item => item.translate !== false).map(item => item.text)).flat()
        ];
        const translations = await Loading.waitForPromises(Translate.get(valuesToTranslate));
        const $customOptions = $ele.find('.kpi-custom-options');
        $customOptions.html('');
        const $customOptionsArr = Object.keys(customOptions).map((key) => {
            const $inputGroup = $(/*html*/ `
        <div>
          <label for="${key}" class="form-label">${translations[key]}</label>
          <select id="${key}" class="form-input" disabled="disabled">
            ${customOptions[key].map(({ key, text }) => {
                // const attr = widgetService.optionEnabled(kpiCfg.KpiType, key) ? '' : 'disabled'
                return (`<option value="${key}">${translations[text] ?? text}</option>`);
            }).join('')}
          </select>
        </div>
      `);
            const $input = $inputGroup.find('.form-input');
            $input.on('change', (e) => {
                if (Settings === null) {
                    Settings = {};
                }
                Settings[key] = $input.val();
                if ($input.hasClass('aci-initialized')) {
                    this.updateModal(kpiCfg, (Settings !== null && Object.keys(Settings).length > 0) ? Settings : null);
                }
                else {
                    $input.addClass('aci-initialized');
                }
            });
            $input.trigger('change');
            return $inputGroup;
        });
        $customOptionsArr.map($inputGroup => $customOptions.append($inputGroup));
        $ele.find('#width').on('change', (e) => {
            const value = $(e.target).val();
            const $kpiParent = $k.parent();
            this.widthOptions.map(({ value }) => {
                $kpiParent.removeClass(value);
            });
            $kpiParent.addClass(value);
            this.widgets[i].Width = value;
            Loading.waitForPromises(widgetService.updateWidth(this.widgets[i])).catch(AError.handle);
            Events.tryInvoke(EVENTS.CONTENT_RESIZE);
        });
        $ele.find('#move-up,#move-down').on('click', (e) => {
            e.preventDefault();
            const $btn = $(e.target);
            const $opt = $btn.closest('form').find('#order option:selected');
            console.log('clicked', $btn);
            const $kpiParent = $k.parent();
            if ($btn.is('#move-up')) {
                $kpiParent.after($kpiParent.prev());
                $opt.after($opt.prev());
                if (i > 0) {
                    let tmp = this.widgets[i - 1];
                    this.widgets[i - 1] = this.widgets[i];
                    this.widgets[i] = tmp;
                    this.widgets[i - 1].Sort = i - 1;
                    this.widgets[i].Sort = i;
                    i = i - 1;
                    Loading.waitForPromises(widgetService.updateOrder(this.widgets)).catch(AError.handle);
                }
            }
            else if ($btn.is('#move-down')) {
                $kpiParent.before($kpiParent.next());
                $opt.before($opt.next());
                if (i < this.widgets.length - 1) {
                    let tmp = this.widgets[i + 1];
                    this.widgets[i + 1] = this.widgets[i];
                    this.widgets[i] = tmp;
                    this.widgets[i + 1].Sort = i + 1;
                    this.widgets[i].Sort = i;
                    i = i + 1;
                    Loading.waitForPromises(widgetService.updateOrder(this.widgets)).catch(AError.handle);
                }
            }
        });
        alert.on(ALERT_STATUS.ON_MODAL_CLOSED, () => {
            // TODO: Implement changing kpi settings
            this.enableRefreshing = true;
        });
    }
    async showDeleteModal(opt) {
        const alert = Alerts.show({
            translatedTitle: this.translations['delete from view'],
            buttons: ALERT_BUTTONS.yesNo,
            content: await Loading.waitForPromises(Translate.get(/*html*/ `
        Are you sure you want to delete this widget?
      `))
        });
        alert.on(ALERT_STATUS.ON_ACTION_PROCEED, async () => {
            try {
                const $kpis = $('.configurable-kpis .kpi-block').toArray().map(kpi => $(kpi));
                const found = $kpis.map(($k, i) => ($k.is(opt.$kpi)) ? { $k, i } : undefined).find(v => v !== undefined);
                if (found === undefined) {
                    return;
                }
                let { $k, i } = found;
                console.log('DELETE:', this.widgets[i].Name);
                // kpiService.removeWidget()
                await Loading.waitForPromises(widgetService.deleteWidget(this.widgets[i]));
                this.widgets.splice(i, 1);
                this.options.onWidgetDeleted();
            }
            catch (err) {
                AError.handle(err);
            }
        });
        alert.on(ALERT_STATUS.ON_MODAL_CLOSED, () => {
            this.enableRefreshing = true;
        });
    }
    async loadAll(opt) {
        if (opt.clearContainer === true) {
            this.clearContainer();
        }
        const widgets = opt.widgets.filter(widgetToAdd => this.widgets.find((w) => {
            return widgetToAdd.View == w.View && widgetToAdd.Name == w.Name && widgetToAdd.KpiType == w.KpiType;
        }) == null);
        const kpis = await this.constructAll(widgets);
        this.widgets = (this.widgets || []).concat(widgets);
        this.kpis = (this.kpis || []).concat(kpis);
        this.checkForEmptyPage();
    }
    clearContainer() {
        this.$container.html('');
        this.kpis = [];
        this.widgets = [];
    }
    async constructAll(widgets) {
        // const $main = $('#AjaxContent')
        // const $renderTos = $main.find('[kpi-class]').toArray().map(e => $(e))
        const kpis = await Promise.all(widgets.map(async (widgetOptions, i) => {
            // await sleep(200 * i)
            return await this.constructSingle(widgetOptions);
        }));
        return kpis.filter(v => v !== undefined);
    }
    async constructSingle(widgetOptions, $container) {
        let widgetInstance = (widgetOptions instanceof AKpiBlock) ? widgetOptions : this.kpiClsToInstance(widgetOptions);
        if (widgetInstance === undefined) {
            AError.handle(new Error(`KpiClsToInstance couldn't find class for widgetOptions.Name=${widgetOptions.Name}`));
            console.warn(`KPI Not Created!`, widgetOptions);
            return;
        }
        if (widgetInstance === null) {
            console.warn(`KPI Not Created!`, widgetOptions);
            AError.handleSilent(new Error(`KPI Not Created! ${JSON.stringify(widgetOptions || 'null')}`));
            return;
        }
        try {
            const $kpiParent = await widgetInstance.renderPlaceholder();
            if ($container) {
                $container.html('');
                $container.append($kpiParent);
            }
            else {
                this.$container.append($kpiParent);
            }
            // this.updateBlockHeight($kpiParent)
            // await widgetInstance.ensureViewApplied()
        }
        catch (err) {
            widgetInstance.errorOccurred(err);
            AError.handleSilent(err);
        }
        return widgetInstance;
    }
    async invokeRefreshAll(options, filters) {
        return (this.enableRefreshing || options.force) ? await Promise.all((this.kpis || []).map((kpi) => {
            // AEngine.log(`Refreshing kpi-class="${kpi.constructor.name}"`)
            return this.invokeRefresh(kpi, filters, options);
        })) : undefined;
    }
    async invokeRefresh(kpi, filters, options) {
        const startVisibility = document.visibilityState;
        const startTime = performance.now();
        try {
            const { userInput } = options;
            await kpi.ensureViewApplied();
            if (options.enableLoadingIndicators) {
                kpi.stripState();
                kpi.toggleLoading(true);
            }
            kpi.updateKpiSubtitle();
            const overrideFilters = (filters === undefined && this.options.filtersFallback !== undefined);
            const state = await kpi.refresh(overrideFilters ? this.options.filtersFallback(kpi) : filters, { userInput });
            if (state === AKpiState.error) {
                kpi.errorOccurred(new Error(`Unknown KPI Error, kpi = ${JSON.stringify(kpi.toJSON())}`));
            }
            if (state === AKpiState.noResults) {
                kpi.noResults();
            }
        }
        catch (err) {
            // Don't spam admin alerts for every error that occurred!
            if (!kpi.hasError()) {
                AError.handleSilent(err);
            }
            kpi.errorOccurred(err);
        }
        finally {
            kpi.checkForAnimations();
            kpi.toggleLoading(false);
            if (this.allowRealtimeWidgets && startVisibility === 'visible') {
                const deltaTime = (performance.now() - startTime) / 1000.0;
                const deltaTimeF = deltaTime.toFixed(2);
                if (performance.getEntriesByType("visibility-state").some(e => e.name === "hidden" && e.startTime > startTime)) {
                    AEngine.log(`Skipped background tab time-tracking for: ${kpi.KpiType.replace('AKpiBlock', 'AKpiBlock%c')}`);
                }
                else {
                    if (deltaTime > AKpiSystem.MAX_REFRESH_TIME) {
                        debugger;
                        AEngine.warn(`${kpi.KpiType.replace('AKpiBlock', 'AKpiBlock%c').padStart(32, ' ')} %nduration: %c${deltaTimeF.padStart(5, ' ')}s%n`, kpi.getOptions());
                        AError.handleSilent(`AKpiBlock took ${deltaTimeF}s to refresh WidgetOptions ${JSON.stringify(kpi.getOptions())}`, `SLOW KPI ${kpi.Name}`);
                    }
                    else if (Events.logLevel >= 1) {
                        AEngine.log(`${kpi.KpiType.replace('AKpiBlock', 'AKpiBlock%c').padStart(32, ' ')} %nduration: %c${deltaTimeF.padStart(5, ' ')}s%n`, kpi.getOptions());
                    }
                }
            }
            return kpi;
        }
    }
}
AKpiSystem.MAX_REFRESH_TIME = ADurationToSeconds('5 minutes');
