import { AFormatDate, AInputTime, ARound, AToHHMMSS, AUrlEncodedImageFromBase64, formatPrice, secondsToHHMM } from "../utils/tools.js";
const IMAGE_RENDERER = function ({ record, value, size }) {
    if (!size && this.field === 'Image') {
        return '';
    }
    size.height = 120;
    return value;
};
export const COLUMN_TEXT = {};
export const COLUMN_HIDDEN = { hidden: true };
export const COLUMN_DATETIME = { type: 'date', renderer: ({ value }) => AFormatDate(value) };
export const COLUMN_TIME = {};
export const COLUMN_TIME_HHMMSS = {
    htmlEncode: false,
    renderer: ({ value }) => { return AToHHMMSS(value instanceof Date ? value : new Date(value)); }
};
export const COLUMN_NUMBER = { type: 'number' };
export const COLUMN_IMAGE = { htmlEncode: false, renderer: IMAGE_RENDERER };
export const COLUMN_BOOLEAN = { htmlEncode: false, renderer: ({ value }) => { return value == '1' ? `<i class="fa fa-check fa-lg"></i><p class="hidden">true</p>` : `<i class="fa fa-times fa-lg"></i><p class="hidden">false</p>`; } };
export const COLUMN_BOOLEAN_NULLABLE = { htmlEncode: false, renderer: ({ value }) => { return value === null ? '' : COLUMN_BOOLEAN.renderer({ value }); } };
export const COLUMN_PRICE = { htmlEncode: false, renderer: ({ value }) => { return value ? formatPrice(value) : ''; } };
export const COLUMN_PRICE_NO_CURR = { htmlEncode: false, renderer: ({ value }) => { return value ? formatPrice(value, '', '') : ''; } };
export const COLUMN_MULTI_SELECT = {
    htmlEncode: false,
    renderer: ({ value, record }) => {
        return (value === null) ? Translate.getCacheFast('all') : (Array.isArray(value) ? value.join(', ') : value);
    }
};
export const COLUMN_MULTI_SELECT_TRANSLATE = {
    htmlEncode: false,
    renderer: ({ value, record }) => {
        return (value === null) ? Translate.getCacheFast('all') : (Array.isArray(value) ? value.map(v => Translate.getCache(v)).join(', ') : value);
    }
};
export const DATA_DATETIME = (value) => new Date(value);
export const DATA_TIME = (value) => AInputTime(new Date(value));
export const DATA_HHMMSS = (value) => AToHHMMSS(new Date(value));
export const DATA_IMAGE = (base64) => (`<img class="table-img" src="${AUrlEncodedImageFromBase64(base64)}" />`);
export const DATA_NUMBER = (value) => Number(value);
export const DATA_ROUND = (value) => ARound(value);
export const DATA_ROUND1 = (value) => ARound(value, 1);
export const DATA_ROUND2 = (value) => ARound(value, 2);
export const DATA_DURATION = (value) => secondsToHHMM(value ?? 0);
export function COLUMN_ACTION(opt) {
    return {
        width: 40,
        autoWidth: 60,
        fitMode: 'exact',
        searchable: false,
        hideInCSV: true,
        text: '',
        htmlEncode: false,
        cellCls: 'np-i',
        region: 'right',
        onClick: opt.onClick,
        renderer: function ({ record }) {
            const styleList = [
                ...(opt.renderStyle ? opt.renderStyle(record) : []),
            ].filter(str => str?.length > 0);
            const classList = [
                opt.renderCls ? opt.renderCls(record) : '',
                opt.disablePredicate && opt.disablePredicate(record) ? 'disabled' : '',
            ].filter(str => str?.length > 0);
            return ( /*html*/`
        <button class="fw grid-btn-bottomline btn ${opt.btnCls || ''} ${classList.join(' ')} fh-i" style="${styleList.join(';')}">
          <i class="${opt.iconCls}"></i>
        </button>
      `);
        }
    };
}
