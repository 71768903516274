import { AError } from "../../classes/AError.js";
import { AMapOverlay } from "../../classes/AMapOverlay.js";
import { ADetectionState, AIllegallyParked, AParkingRight, AVerification } from "../../classes/AUnificationTypes.js";
import { MAP_OPTIONS } from "../../core/maps/AMapStructs.js";
import { ALERT_TITLES } from "../../services/AAlertService.js";
import { createMap } from "../../utils/maps.js";
import { generateTreeDropdown } from "../../utils/tools.js";
export class APage {
    constructor() {
        this.SessionMarkers = {};
        this.map = createMap('map', { zoom: 24 });
        this.Markers = [];
    }
    async init() {
        FilterManager.load();
        await Loading.waitForPromises([
            generateTreeDropdown('#ParkingRight', new AParkingRight()),
            generateTreeDropdown('#Verification', new AVerification()),
            generateTreeDropdown('#IllegallyParked', new AIllegallyParked()),
            generateTreeDropdown('#DetectionState', new ADetectionState()),
        ]);
        mapHelperService.prepareMapItems(MAP_OPTIONS.Default, {
            showLegend: false,
            createToggleItems: true,
            showOneScale: true
        }).then(_ => {
            return this.fillZones();
        }).catch(AError.handle);
        $('#RefreshButton').on('click', _ => FilterManager.showFilterWarning().then(_ => this.refresh()));
        this.map.fit();
        nodeSessionService.bindSessionsToMap({
            interpolate: false,
            mapMarkers: this.SessionMarkers,
            map: this.map
        });
    }
    /**
     * @deprecated
     */
    createTestPiechart(options) {
        const { title } = options;
        const element = document.createElement("div");
        const chart = Highcharts.chart({
            chart: {
                renderTo: element,
                backgroundColor: 'rgba(255, 255, 255, 0.0)',
                // plotBackgroundColor: null,
                // plotBorderWidth: null,
                borderWidth: 0,
                plotShadow: false,
                type: 'pie'
            },
            title: {
                text: title
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false,
                        // format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                    }
                }
            },
            series: [{
                    type: 'pie',
                    name: 'Brands',
                    data: [{
                            name: 'Chrome',
                            y: 61.41,
                            sliced: true,
                            selected: true
                        }, {
                            name: 'Internet Explorer',
                            y: 11.84
                        }, {
                            name: 'Firefox',
                            y: 10.85
                        }, {
                            name: 'Edge',
                            y: 4.67
                        }, {
                            name: 'Safari',
                            y: 4.18
                        }, {
                            name: 'Sogou Explorer',
                            y: 1.64
                        }, {
                            name: 'Opera',
                            y: 1.6
                        }, {
                            name: 'QQ',
                            y: 1.2
                        }, {
                            name: 'Other',
                            y: 2.61
                        }]
                }],
            exporting: {
                enabled: false
            },
            legend: {
                enabled: false
            }
        });
        return { element, chart };
    }
    getBoundsOfPolygon(polygon) {
        const bounds = new google.maps.LatLngBounds();
        for (var i = 0; i < polygon.getPath().getLength(); i++) {
            const path = polygon.getPath().getAt(i);
            bounds.extend(path);
        }
        return bounds;
    }
    async fillZones() {
        let allZonesOnMap = mapHelperService.getGeoInstancesOnMap(this.map, MAP_OPTIONS.Zone);
        let zonesOnMap = allZonesOnMap.slice(0, Math.min(25, allZonesOnMap.length));
        if (zonesOnMap.length < allZonesOnMap.length) {
            Alerts.show({
                title: ALERT_TITLES.Info,
                content: `Showing ${zonesOnMap.length} of ${allZonesOnMap.length} charts`,
            });
        }
        this.overlays = zonesOnMap.map(zone => {
            var title = (`[${zone.data.area}] ${zone.data.id}`);
            var { element, chart } = this.createTestPiechart({ title });
            var bounds = PageScript.getBoundsOfPolygon(zone);
            var overlay = new AMapOverlay(title, bounds, element, chart);
            overlay.setMap(this.map);
            return overlay;
        });
        setTimeout(_ => {
            this.overlays.map(overlay => overlay.chart.reflow());
        }, 100);
    }
    async refresh() {
        try {
            FilterManager.setActive(false);
            Alerts.notImplementedYet();
        }
        catch (err) {
            AError.handle(err);
        }
        finally {
            FilterManager.setActive(true);
        }
    }
}
export function render() {
    return ( /*html*/`
    <div id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column col-12">
          <div class="form-group">
              <label class="form-label" for="FromDate">From</label>
              <input class="form-input" type="date" id="FromDate" required="required">
              <input class="form-input" type="time" id="FromTime" required="required">
          </div>

          <div class="form-group">
              <label class="form-label" for="ToDate">To</label>
              <input class="form-input" type="date" id="ToDate" required="required">
              <input class="form-input" type="time" id="ToTime" required="required">
          </div>
          
          <div class="form-group">
              <label class="form-label" for="ParkingRight">ParkingRight</label>
              <div id="ParkingRight" class="wrapper-dropdown tree-config dd-disallow-none noselect" maxlength="18">
                  <span>All</span>
              </div>
          </div>
          
          <div class="form-group">
              <label class="form-label" for="Verification">Verification</label>
              <div id="Verification" class="wrapper-dropdown tree-config dd-disallow-none noselect" maxlength="18">
                  <span>All</span>
              </div>
          </div>
          
          <div class="form-group">
              <label class="form-label" for="IllegallyParked">IllegallyParked</label>
              <div id="IllegallyParked" class="wrapper-dropdown tree-config dd-disallow-none noselect" maxlength="18">
                  <span>All</span>
              </div>
          </div>
    
          <div class="form-group">
            <label class="form-label" for="DetectionState">DetectionState</label>
            <div id="DetectionState" class="wrapper-dropdown tree-config dd-disallow-none noselect" maxlength="18">
              <span>All</span>
            </div>
          </div>

          <div class="form-group">
              <label class="form-label" for="DeviceName">Device</label>
              <select class="form-select" id="DeviceName">
                  <option value="%">All</option>
              </select>
          </div>

          <div class="form-group">
              <label class="form-label" for="Area">Area</label>
              <select class="form-select" id="Area">
                  <option value="%" SELECTED>All</option>
                  <option value="Unknown">Unknown</option>
              </select>
          </div>

          <div class="form-group">
              <label class="form-label" for="Limit">Max results</label>
              <input class="form-input" type="number" id="Limit" value="2000">
          </div>

          <div class="form-group">
              <label class="form-switch">
                  <input id="ShowRoute" type="checkbox">
                  <i class="form-icon"></i> Show Route
              </label>
          </div>

          <div class="form-group">
              <label class="form-label" for="RouteLimit">Max Route Points</label>
              <input enabled-if="ShowRoute" class="form-input" type="number" id="RouteLimit" value="5000">
          </div>
      </div>
      <div class="column col-12">
          <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </div>
    <div class="flex-child">
      <div id="map" class="aci-map"></div>
    </div>
   `);
}
