import { AResponse } from "./AResponse.js";
import { ADetectionService } from "../services/ADetectionService.js";
import { AEngine } from "../core/AEngine.js";
import { AError } from "./AError.js";
import { isVariablePrimitive } from "../utils/tools.js";
const minify = (markup) => markup; // .replace(/\s+/g, ' ')
export class AKML {
    constructor(name, description) {
        this.collections = {};
        this.altitude = [0];
        this.colors = {};
        this.mapElementTypes = this.googleTypes();
        this.nextColorIndex = 0;
        this.name = name;
        this.description = description;
    }
    get style() {
        return Object.keys(this.colors).map(key => {
            return this.colors[key];
        }).join('\r\n');
    }
    get nextColorName() {
        this.nextColorIndex++;
        return (`C${this.nextColorIndex}`);
    }
    get layers() {
        return Object.keys(this.collections);
    }
    createLayer(layer) {
        if (this.collections.hasOwnProperty(layer)) {
            throw new Error(`Collection '${layer}' already exists!`);
        }
        this.collections[layer] = { array: [] };
    }
    createLayers(layers) {
        return layers.map(layer => this.createLayer(layer));
    }
    getCollection(layer) {
        if (!this.collections.hasOwnProperty(layer)) {
            throw new Error(`Collection '${layer}' couldn't be found!`);
        }
        return this.collections[layer];
    }
    convertBGRtoRGB(hexString) {
        const bgr = hexString.replace('#', '');
        const blue = bgr.substring(2, 4), green = bgr.substring(4, 6), red = bgr.substring(6, 8);
        return red + green + blue;
    }
    convertRGBtoBGR(hexString) {
        const rgb = hexString.replace('#', '');
        const red = rgb.substring(0, 2), green = rgb.substring(2, 4), blue = rgb.substring(4, 6);
        return blue + green + red;
    }
    /**
     * Converts css color "rgb(255,255,255)" to hexidecimal color
     */
    transformColor(color) {
        const rgbNeedle = 'rgb(';
        const rgbaNeedle = 'rgba(';
        const indexOfRgb = color.indexOf(rgbNeedle); // ?
        if (indexOfRgb !== -1) {
            const rgbString = color.substring(indexOfRgb + rgbNeedle.length, color.length - 1);
            const rgb = rgbString.replace(/\s/g, '').split(',');
            const toHex = (primaryColor) => parseInt(primaryColor).toString(16);
            const concat = (p, c) => p + c;
            return rgb.map(toHex).reduce(concat); // ?
        }
        const indexOfRgba = color.indexOf(rgbaNeedle);
        if (indexOfRgba !== -1) {
            const rgbString = color.substring(indexOfRgba + rgbaNeedle.length, color.length - 1);
            const rgb = rgbString.replace(/\s/g, '').split(',');
            const toHex = (primaryColor) => parseInt(primaryColor).toString(16);
            const concat = (p, c) => p + c;
            return rgb.map(toHex).reduce(concat); // ?
        }
        return color;
    }
    findOrCreateColor(color) {
        const hexString = this.transformColor(color);
        const bgr = this.convertRGBtoBGR(hexString);
        if (!this.colors.hasOwnProperty(bgr)) {
            // console.log(color, hexString, bgr)
            // BGR IS (BLUE | GREEN | RED)
            this.colors[bgr] = minify(`
    <Style id="s${bgr}">
        <LineStyle>
            <color>FF${bgr}</color>
            <width>4</width>
        </LineStyle>
        <PolyStyle>
            <color>80${bgr}</color>
        </PolyStyle>
    </Style>
      `);
        }
        return bgr;
    }
    /**
     * Google Map Types
     * @returns
     */
    googleTypes() {
        return {
            'Polygon': new google.maps.Marker().constructor,
            'Point': new google.maps.Point(0, 0).constructor,
            'LineString': null
        };
    }
    /**
     * @deprecated
     * Extract Name
     */
    extractName(data) {
        if (data._Name) {
            const name = data._Name;
            // delete data._Name
            return name;
        }
        return '';
    }
    /**
     * Adds point to the KML export
     */
    addPoint(layer, point, data, opt) {
        const { altitude } = this;
        const coordinates = point.concat(altitude).join(',');
        const tags = this.stringifyData(data || {});
        this.getCollection(layer).array.push(minify(/*xml*/ `
      <Placemark>
        <name>${opt.kmlName ?? this.extractName(data)}</name>
        <ExtendedData>${tags}</ExtendedData>
        <Point>
          <extrude>1</extrude>
          <altitudeMode>clampToGround</altitudeMode>
          <coordinates>
            ${coordinates}
          </coordinates>
        </Point>
      </Placemark>
    `));
    }
    /**
     * Adds Line String to KML export
     */
    addLineString(layer, points, data, opt) {
        const bgr = this.findOrCreateColor(opt.color);
        const { altitude } = this;
        const coordinates = points.map((coordinate) => coordinate.concat(altitude).join(',')).join(' ');
        const tags = this.stringifyData(data || {});
        this.getCollection(layer).array.push(minify(/*xml*/ `
      <Placemark>
        <name>${opt.kmlName ?? this.extractName(data)}</name>
        <ExtendedData>${tags}</ExtendedData>
        <styleUrl>#s${bgr}</styleUrl>
        <LineString>
          <extrude>1</extrude>
          <altitudeMode>clampToGround</altitudeMode>
          <coordinates>
            ${coordinates}
          </coordinates>
        </LineString>
      </Placemark>
    `));
    }
    /**
     * Adds polygon to kml export
     */
    addPolygon(layer, points, data, opt) {
        const bgr = this.findOrCreateColor(opt.color);
        const { altitude } = this;
        const coordinates = points.map((coordinate) => coordinate.concat(altitude).join(',')).join(' ');
        const tags = this.stringifyData(data || {});
        this.getCollection(layer).array.push(minify(/*xml*/ `
      <Placemark>
        <name>${opt.kmlName ?? this.extractName(data)}</name>
        <ExtendedData>${tags}</ExtendedData>
        <styleUrl>#s${bgr}</styleUrl>
        <Polygon>
          <extrude>1</extrude>
          <altitudeMode>clampToGround</altitudeMode>
          <outerBoundaryIs>
            <LinearRing>
              <coordinates>
                ${coordinates}
              </coordinates>
            </LinearRing>
          </outerBoundaryIs>
        </Polygon>
      </Placemark>
    `));
    }
    /**
     * Transform point to KML notation
     * @returns coordinates in KML notation
     */
    pointToKml(point, altitude) {
        const latLng = { lat: point.X, lng: point.Y };
        return `${latLng.lng},${latLng.lat},${altitude}`;
    }
    /**
     * Transform latLng to KML notation
     * @returns coordinates in KML notation
     */
    latLngToKml(latLng, altitude) {
        return `${latLng.lng},${latLng.lat},${altitude}`;
    }
    /**
     * Add Detections to the kml using QueryResponse
     */
    addMarkersWithResponse(layer, response, options) {
        const { markersArePolygons } = Object.assign({ markersArePolygons: true }, options);
        const ares = response instanceof AResponse ? response : new AResponse(response);
        const { altitude } = this;
        if (!markersArePolygons) {
            console.warn('Processing cached query as traffic signs');
        }
        const collection = this.getCollection(layer);
        ares.loop(row => {
            // Get data from current row
            let { LicensePlate, VehicleBounds, TrafficSignText, TrafficSignCenter } = row;
            // Stringify detection data
            const tags = this.stringifyData(row || {});
            // Determine the marker color
            AEngine.get(ADetectionService).setMarkerFinal(row, row);
            let bgr = null;
            try {
                if (markersArePolygons) {
                    const clr = mapHelperService.calcLegendColor(row._final) || "#000";
                    const colorSet = (typeof clr === 'string') ? { fill: clr, stroke: clr } : clr;
                    bgr = this.findOrCreateColor(colorSet.stroke);
                }
            }
            catch (err) {
                console.error(err);
            }
            let markup = null;
            if (markersArePolygons) {
                // Convert coordinates to right format
                const { coordinates } = mapHelperService.geoJsonToPolygonCoords(VehicleBounds);
                // Transform points to kml coordinates
                const coordsToExport = [];
                for (const point of coordinates) {
                    coordsToExport.push(this.latLngToKml(point, altitude[0]));
                }
                if (coordinates.length) {
                    coordsToExport.push(this.latLngToKml(coordinates[0], altitude[0]));
                }
                markup = minify(/*xml*/ `
          <Polygon>
            <Extrude>1</Extrude>
            <altitudeMode>clampToGround</altitudeMode>
            <outerBoundaryIs>
              <LinearRing>
                <coordinates>${coordsToExport.join(' ')}</coordinates>
              </LinearRing>
            </outerBoundaryIs>
          </Polygon>
        `);
            }
            else {
                const { lat, lng } = mapHelperService.geoPointToCoords(TrafficSignCenter);
                markup = minify(/*xml*/ `
          <Point>
            <Extrude>1</Extrude>
            <altitudeMode>clampToGround</altitudeMode>
            <coordinates>${lng},${lat},0</coordinates>
          </Point>
        `);
            }
            collection.array.push(minify(/*xml*/ `
        <Placemark>
          <name>${TrafficSignText || LicensePlate || 'Unknown'}</name>
          <ExtendedData>${tags}</ExtendedData>
          <styleUrl>#s${bgr}</styleUrl>
          ${markup}
        </Placemark>
      `));
        });
    }
    /**
     * Created markup key-value pairs
     */
    stringifyData(data) {
        return '\r\n' + Object.keys(data).map(key => {
            const value = isVariablePrimitive(data[key]) ? data[key] : JSON.stringify(data[key]);
            return (`\t\t\t\t\t<Data name="${key}"><value>${value}</value></Data>`);
        }).join('\r\n') + '\r\n\t\t\t\t';
        // return Object.keys(data).map((key) =>(`<${key}>${data[key]}</${key}>`))
    }
    /**
     * Converts inner data to KML xml format
     * @param opt pretty printing option
     * @returns kml string
     */
    stringify(opt) {
        const { style } = this;
        const kml = ( /*xml*/`
      <?xml version="1.0" encoding="UTF-8"?>
      <kml xmlns="http://www.opengis.net/kml/2.2">
      <Document>
        <name>${this.name}</name>
        <description>${this.description}</description>
        ${style}
        ${this.layers.map(layer => {
            return ( /*xml*/`
            <Folder id="${layer}">
              <name>${layer}</name>
              <open>1</open>
              ${this.collections[layer].array.join('\r\n')}
            </Folder>
          `);
        }).join('\r\n')}
      </Document>
      </kml>
    `).trim();
        try {
            return (opt?.prettyPrint) ? prettifyXml(kml) : kml;
        }
        catch (err) {
            AError.handleSilent(err);
            return kml;
        }
    }
}
function prettifyXml(sourceXml) {
    // https://stackoverflow.com/questions/730133/what-are-invalid-characters-in-xml
    const src = sourceXml.replace(/&/g, "&#38;"); // .replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&#039;").replace(/\//g, '&#x2F;')
    var xmlDoc = new DOMParser().parseFromString(src, 'application/xml');
    const template = [
        // describes how we want to modify the XML - indent everything
        /*xml*/ `<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform">`,
        /*xml*/ `  <xsl:strip-space elements="*"/>`,
        /*xml*/ `  <xsl:template match="text()">`,
        /*xml*/ `    <xsl:value-of select="normalize-space(.)"/>`,
        /*xml*/ `  </xsl:template>`,
        /*xml*/ `  <xsl:template match="node()|@*">`,
        /*xml*/ `    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>`,
        /*xml*/ `  </xsl:template>`,
        /*xml*/ `  <xsl:output omit-xml-declaration="yes" indent="yes"/>`,
        /*xml*/ `</xsl:stylesheet>`,
    ].join('\n');
    var xsltDoc = new DOMParser().parseFromString(template, 'application/xml');
    var xsltProcessor = new XSLTProcessor();
    xsltProcessor.importStylesheet(xsltDoc);
    var resultDoc = xsltProcessor.transformToDocument(xmlDoc);
    var resultXml = new XMLSerializer().serializeToString(resultDoc);
    return resultXml;
}
