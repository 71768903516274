import { EVENTS } from '../services/AEventService.js';
export class AProductionTodos {
    static async run() {
        Events.h_once(EVENTS.PREFETCH, () => {
            this.todos.map((todo) => {
                adminAlertsService.createAlert(todo);
            });
        });
    }
}
AProductionTodos.todos = [
// { GroupId: 'ini_mysql_doupdates', Message: 'Set [MySQL] DoUpdates to true when the MySqlDef.json has been recompiled into executable!', Importance: 1 }
];
