import { AStatisticsChart } from '../charts/AStatisticsChart.js';
import { AVerification, ADetectionState, AIllegallyParked, AParkingRight, ATimeLimitedParking, ADigital } from './AUnificationTypes.js';
import { AOccupancy } from './AUnificationTypesExtensions.js';
import { ARound } from '../utils/tools.js';
import { AError, ERROR_GROUPS } from './AError.js';
import { embedJsonInHtml } from '../utils/json.js';
export var CHART_TYPE_ENUM;
(function (CHART_TYPE_ENUM) {
    CHART_TYPE_ENUM["CAPACITY"] = "Capacity";
    CHART_TYPE_ENUM["OCCUPANCY"] = "Occupancy";
    CHART_TYPE_ENUM["PERMIT_RATE"] = "PermitRate";
    CHART_TYPE_ENUM["VISITOR_RATE"] = "VisitorRate";
    CHART_TYPE_ENUM["COMPLIANCY"] = "Compliancy";
    CHART_TYPE_ENUM["COMPLIANCY_VISITOR"] = "CompliancyVisitor";
    CHART_TYPE_ENUM["DIGITAL"] = "Digital";
    CHART_TYPE_ENUM["ILLEGALY_PARKED"] = "IllegallyParked";
    CHART_TYPE_ENUM["PARKING_RIGHT"] = "ParkingRight";
    CHART_TYPE_ENUM["TIME_LIMITED_PARKING"] = "TimeLimitedParking";
    CHART_TYPE_ENUM["VERIFICATION"] = "Verification";
    CHART_TYPE_ENUM["DETECTION_STATE"] = "DetectionState";
})(CHART_TYPE_ENUM || (CHART_TYPE_ENUM = {}));
export const CHART_TYPE = {
    CAPACITY: 'Capacity',
    OCCUPANCY: 'Occupancy',
    PERMIT_RATE: 'PermitRate',
    VISITOR_RATE: 'VisitorRate',
    COMPLIANCY: 'Compliancy',
    COMPLIANCY_VISITOR: 'CompliancyVisitor',
    DIGITAL: 'Digital',
    ILLEGALY_PARKED: 'IllegallyParked',
    PARKING_RIGHT: 'ParkingRight',
    TIME_LIMITED_PARKING: 'TimeLimitedParking',
    VERIFICATION: 'Verification',
    DETECTION_STATE: 'DetectionState'
};
export class ADetectionStatistics {
    constructor(dummyData = {}) {
        this.Digital = new ADigital;
        this.IllegallyParked = new AIllegallyParked;
        this.TimeLimitedParking = new ATimeLimitedParking;
        this.ParkingRight = new AParkingRight;
        this.Verification = new AVerification;
        this.Occupancy = new AOccupancy;
        this.DetectionState = new ADetectionState;
        this.EntriesPerGroup = 0;
        Object.keys(dummyData).map(key => {
            Object.assign(this[key], dummyData[key]);
        });
    }
    addEntryPerGroup(increment = 1) {
        this.EntriesPerGroup += increment;
    }
    addDetectionFinalCount(DigitalOption, IllegallyParkedOption, TimeLimitedParkingOption, ParkingRightOption, VerificationOption, DetectionStateOption, Total) {
        this.Digital.addOption(DigitalOption, Total);
        this.IllegallyParked.addOption(IllegallyParkedOption, Total);
        this.TimeLimitedParking.addOption(TimeLimitedParkingOption, Total);
        this.ParkingRight.addOption(ParkingRightOption, Total);
        this.Verification.addOption(VerificationOption, Total);
        this.DetectionState.addOption(DetectionStateOption, Total);
    }
    getTotal() {
        return this.Verification.Count;
    }
    static getSuspectsSQL() {
        var v = new AVerification();
        var p = new AParkingRight();
        return (`
      IF(
        df.Verification BETWEEN ${v.Options.NoVerificationNeeded.FirstIndex} AND ${v.Options.NoVerificationNeeded.FirstIndex} OR
        df.ParkingRight BETWEEN ${p.Options.InProgress.FirstIndex} AND ${p.Options.InProgress.LastIndex},
        'OTHER',
        'SUSPECT'
      )
    `).trim();
    }
    getSuspects() {
        // Suspects:   Total - (NoVerificationNeeded + ParkingRight.InProgress)
        const Total = this.Verification.Count;
        const NoVerificationNeeded = this.Verification.Options.NoVerificationNeeded.Count;
        const PR_InProgress = this.ParkingRight.Options.InProgress.Count;
        return Total - (NoVerificationNeeded + PR_InProgress);
    }
    getSuspectsFormula() {
        const Total = this.Verification.Count;
        const NoVerificationNeeded = this.Verification.Options.NoVerificationNeeded.Count;
        const PR_InProgress = this.ParkingRight.Options.InProgress.Count;
        return {
            value: this.getSuspects(),
            formula: 'Total - (Verification.NoVerificationNeeded + ParkingRight.InProgress)',
            formulaText: (`${Total} - (${NoVerificationNeeded} + ${PR_InProgress})`)
        };
    }
    getFollowUps() {
        /**
          Fined:
            No Parking Right: Fined_NoParkingRight
            Illegally Parked: Fined_IllegallyParked
            Time Limited Parking Experired: Fined_TimeLimitedParkingExperired
            Unknown: Fined_Unknown
          Not Fined: NotFined
          Not Processed:
            Vehicle Not Found: NotProcessed_VehicleNotFound
            Wrong Recognition: NotProcessed_WrongRecognition
         */
        const vOptions = this.Verification.Options;
        const FollowUps = (vOptions.Fined.Count +
            vOptions.NotFined.Count +
            vOptions.Reprimanded.Count +
            vOptions.NotProcessed.Options.VehicleNotFound.Count +
            vOptions.NotProcessed.Options.WrongRecognition.Count);
        return FollowUps;
    }
    getUnsuccessfulFollowUpOptions() {
        const notProcOptions = this.Verification.Options.NotProcessed.Options;
        const unsuccessful = [
            notProcOptions.VehicleNotFound,
            notProcOptions.WrongRecognition
        ];
        return Object.values(unsuccessful);
        // .filter(v => (v.Count > 0))
    }
    getUnhandledSuspectOptions() {
        let notProcOptions = this.Verification.Options.NotProcessed.Options;
        notProcOptions["Unknown"].Count += this.Verification.Options.InProgress.Count;
        const unsuccessful = this.getUnsuccessfulFollowUpOptions();
        return Object.values(notProcOptions).filter(v => !unsuccessful.includes(v));
    }
    getUnsuccessfulFollowUps() {
        return this.getUnsuccessfulFollowUpOptions().map(stats => stats.Count).reduce((a, b) => a + b, 0) || 0;
    }
    getSuccessfulFollowUps() {
        return this.Verification.Options.Fined.Count + this.Verification.Options.NotFined.Count + this.Verification.Options.Reprimanded.Count;
    }
    getSuccessfulFollowUpsFormula() {
        const Fined = this.Verification.Options.Fined.Count;
        const NotFined = this.Verification.Options.NotFined.Count;
        const Reprimanded = this.Verification.Options.Reprimanded.Count;
        return {
            value: this.getSuccessfulFollowUps(),
            formula: 'Verification.Fined + Verification.NotFined + Verification.Reprimanded',
            formulaText: (`${Fined} + ${NotFined} + ${Reprimanded}`)
        };
    }
    getSystemNotProcessedFollowUps() {
        return this.Verification.Options.NotProcessed.Count - this.getUserDiscardedFollowUps();
    }
    getUserDiscardedFollowUps() {
        const { VehicleNotFound, WrongRecognition, Rejected } = this.Verification.Options.NotProcessed.Options;
        return VehicleNotFound.Count + WrongRecognition.Count + Rejected.Count;
    }
    getUserDiscardedFollowUpsFormula() {
        const value = this.getUserDiscardedFollowUps();
        const { VehicleNotFound, WrongRecognition, Rejected } = this.Verification.Options.NotProcessed.Options;
        return {
            value,
            formula: 'Verification.NotProcessed.VehicleNotFound + Verification.NotProcessed.WrongRecognition + Verification.NotProcessed.Rejected',
            formulaText: (`${VehicleNotFound} + ${WrongRecognition} + ${Rejected}`),
        };
    }
    static getUserDiscardedFollowUpsExplain() {
        return Translate.get(`
      The Discarded followups are:
      - vehicle_not_found
      - wrong_recognition
      - rejected
      Discarded followups do not reflect the performance of an employee, therefore they are seperated!
    `);
    }
    getSanctions() {
        return this.Verification.Options.Fined.Count;
    }
    getSanctionsFormula() {
        const value = this.getSanctions();
        return {
            value,
            formula: 'Verification.Fined',
            formulaText: value.toString()
        };
    }
    addOccupancy(OccupancyAvg, CapacityAvg, SeenCapacityAvg, EntryCount) {
        if (EntryCount > 0) {
            this.Occupancy.OccupancyAvg += OccupancyAvg; /// Calculate Avg instead of using sum
            this.Occupancy.CapacityAvg += CapacityAvg; /// Calculate Avg instead of using sum
            this.Occupancy.SeenCapacityAvg += SeenCapacityAvg; ///added SeenCapacity and Calculate Avg
            if (EntryCount > this.Occupancy.EntryCount) /// Keep the max entry count as EntryCount
             {
                this.Occupancy.EntryCount = EntryCount;
            }
        }
    }
    /**
     * Parking pressure || between 0.0 and 1.0 or null
     */
    getOccupancy() {
        if (this.Occupancy.SeenCapacityAvg == 0) { /// use SeenCapacityAvg
            return null;
        }
        return this.Occupancy.OccupancyAvg / this.Occupancy.SeenCapacityAvg; /// use SeenCapacityAvg
    }
    getCapacity() {
        return this.Occupancy.Options.Capacity.Count;
    }
    getOccupancyFormula() {
        return {
            value: this.getOccupancy(),
            formulaText: `Occupancy.OccupancyAvg / Occupancy.SeenCapacityAvg`.trim(),
            formula: `${this.Occupancy.OccupancyAvg} / ${this.Occupancy.SeenCapacityAvg}`.trim() /// use SeenCapacityAvg
        };
    }
    getNotPaidCount() {
        return this.ParkingRight.Options.NoParkingRight.Count + this.ParkingRight.Options.Indecisive.Count;
    }
    getNotPaidCountMeta() {
        return {
            formulaText: (`ParkingRight.NoParkingRight + ParkingRight.Indecisive`).trim(),
            formula: (`${this.ParkingRight.Options.NoParkingRight.Count} + ${this.ParkingRight.Options.Indecisive.Count}`).trim()
        };
    }
    getCompiantVisitorMeta() {
        return {
            formulaText: (`ParkingRight.ParkingRight.Visitor`).trim(),
            formula: (`${this.ParkingRight.Options.ParkingRight.Options.Visitor.Count}`).trim()
        };
    }
    getVisitorCount() {
        return this.ParkingRight.Options.ParkingRight.Options.Visitor.Count + this.getNotPaidCount();
    }
    getVisitorCountMeta() {
        return {
            formulaText: (`ParkingRight.HasParkingRight.Visitor + ${this.getNotPaidCountMeta().formulaText}`).trim(),
            formula: (`${this.ParkingRight.Options.ParkingRight.Options.Visitor.Count} + ${this.getNotPaidCountMeta().formula}`).trim()
        };
    }
    getPermitRate() {
        let VisitorCount = this.getVisitorCount();
        let PermitCount = this.ParkingRight.Options.ParkingRight.Options.Permit.Count;
        if (VisitorCount + PermitCount === 0) {
            return null;
        }
        return (PermitCount) / (VisitorCount + PermitCount);
    }
    getPermitRateFormula() {
        let PermitCount = this.ParkingRight.Options.ParkingRight.Options.Permit.Count;
        return {
            value: this.getPermitRate(),
            formulaText: (`ParkingRight.HasParkingRight.Permit / (${this.getVisitorCountMeta().formulaText} + ParkingRight.HasParkingRight.Permit)`),
            formula: (`${PermitCount} / (${this.getVisitorCountMeta().formula} + ${PermitCount})`)
        };
    }
    getVisitorRate() {
        let VisitorCount = this.getVisitorCount();
        let PermitCount = this.ParkingRight.Options.ParkingRight.Options.Permit.Count;
        if (VisitorCount + PermitCount === 0) {
            return null;
        }
        return (VisitorCount) / (VisitorCount + PermitCount);
    }
    getVisitorRateFormula() {
        let PermitCount = this.ParkingRight.Options.ParkingRight.Options.Permit.Count;
        return {
            value: this.getVisitorRate(),
            formulaText: (`${this.getVisitorCountMeta().formulaText} / (${this.getVisitorCountMeta().formulaText} + ParkingRight.HasParkingRight.Permit)`),
            formula: (`${this.getVisitorCountMeta().formula} / (${this.getVisitorCountMeta().formula} + ${PermitCount})`)
        };
    }
    /**
     * @deprecated
     */
    getDigitizationRate() {
        if (this.getTotal() === 0) {
            return null;
        }
        const IsDigital = this.Digital.Options.Digital.Count;
        return (IsDigital / this.getTotal() * 100);
    }
    /**
     * @deprecated
     */
    getDigitizationRateFormula() {
        const Total = this.getTotal();
        const IsDigital = this.Digital.Options.Digital.Count;
        return {
            value: this.getDigitizationRate(),
            formulaText: (`Digital.IsDigital / Total * 100`).trim(),
            formula: (`${IsDigital} / ${Total} * 100`).trim()
        };
    }
    getSuccessfulFollowUpRate() {
        if (this.getTotal() === 0) {
            return null;
        }
        return this.getSuccessfulFollowUps() * 100 / this.getSuspects();
    }
    getCompliancy() {
        let hasParkingRight = this.ParkingRight.Options.ParkingRight.Count;
        let hasNoParkingRight = this.getNotPaidCount();
        const sum = hasParkingRight + hasNoParkingRight;
        if (sum === 0) {
            return null;
        }
        return hasParkingRight / (hasParkingRight + hasNoParkingRight);
    }
    getCompliancyFormula() {
        let hasParkingRight = this.ParkingRight.Options.ParkingRight.Count;
        // const NotPaidMeta = this.getNotPaidCountMeta() 
        // const hasNoParkingRight = this.getNotPaidCount() 
        return {
            value: this.getCompliancy(),
            formulaText: (`(ParkingRight.ParkingRight) / (ParkingRight.ParkingRight + ${this.getNotPaidCountMeta().formulaText})`).trim(),
            formula: (`(${hasParkingRight}) / (${hasParkingRight} + ${this.getNotPaidCountMeta().formula})`).trim()
        };
    }
    // TODO
    getCompliancyVisitors() {
        let hasParkingRight = this.ParkingRight.Options.ParkingRight.Options.Visitor.Count;
        let hasNoParkingRight = this.getNotPaidCount();
        const sum = hasParkingRight + hasNoParkingRight;
        if (sum === 0) {
            return null;
        }
        return hasParkingRight / (sum);
    }
    getCompliancyVisitorsFormula() {
        let hasParkingRight = this.ParkingRight.Options.ParkingRight.Options.Visitor.Count;
        // let hasNoParkingRight = this.getNotPaidCount() 
        return {
            value: this.getCompliancyVisitors(),
            formulaText: (`(ParkingRight.HasParkingRight.Visitor) / (ParkingRight.HasParkingRight.Visitor + ${this.getNotPaidCountMeta().formulaText})`),
            formula: (`${hasParkingRight} / (${hasParkingRight} + ${this.getNotPaidCountMeta().formula})`)
        };
    }
    /**
     * // TODO: REWORK
     * @deprecated
     */
    getCompliancyVisitorsFormulaDeprecated() {
        const CompliancyMeta = this.getCompiantVisitorMeta();
        const NotPaidMeta = this.getNotPaidCountMeta();
        return {
            value: this.getCompliancyVisitors(),
            formulaText: (`(${CompliancyMeta.formulaText}) / (${CompliancyMeta.formulaText} + ${NotPaidMeta.formulaText})`).trim(),
            formula: (`(${CompliancyMeta.formula}) / (${CompliancyMeta.formula} + ${NotPaidMeta.formula})`).trim()
        };
    }
    getEnforcementIntensity() {
        return this.Occupancy.EntryCount; /// Use the max entry count as Intensity
    }
    getEnforcementIntensityFormula() {
        return {
            value: this.getEnforcementIntensity(),
            formulaText: (`(Occupancy.EntryCount)`),
            formula: (`${this.Occupancy.EntryCount})`)
        };
    }
    getOccupancyEntryCount() {
        return this.Occupancy.EntryCount;
    }
    getEnforcementIntensityMap(precalc) {
        const { min, left, right, max } = precalc ?? { left: 0, right: this.Occupancy.EntryCount };
        if (precalc?.hideOutsideBounds) {
            if (this.Occupancy.EntryCount < left) {
                return null;
            }
            if (this.Occupancy.EntryCount > right) {
                return null;
            }
        }
        return this.Occupancy.EntryCount;
    }
    getEnforcementIntensityMapFormula(precalc) {
        // const {min, left, right, max, hideOutsideBounds, unit} = precalc
        const { left, right } = precalc ?? { left: 0, right: this.Occupancy.EntryCount };
        return {
            value: this.getEnforcementIntensityMap(precalc),
            formulaText: (`(Occupancy.EntryCount - MINIMUM) / (MAXIMUM - MINIMUM)`),
            formula: (`(${this.Occupancy.EntryCount} - ${left}) / (${right} - ${left})`)
        };
    }
    /**
     * Get Statistics for specific category (ex. Compliancy, Occupancy)
     * @param category one of [Capacity, PermitRate, Occupancy, VisitorRate, Compliancy, CompliancyVisitor, EnforcementIntensity]
     * @param precalc data such as min, max, median, average
     */
    getDataByCategory(args) {
        const { category, precalc } = args;
        switch (category) {
            // @ts-ignore
            case 1:
                return null;
            case 'Capacity':
            case 'Occupancy':
                // 'thematic.occupancy':
                return this.getOccupancy();
            case 'VisitorRate':
                // 'thematic.visitorRate':
                return this.getVisitorRate();
            case 'PermitRate':
                return this.getPermitRate();
            case 'Compliancy':
                // 'thematic.compliancy':
                return this.getCompliancy();
            case 'CompliancyVisitor':
                // 'thematic.compliancyVisitor':
                return this.getCompliancyVisitors();
            case 'EnforcementIntensity':
                // 'thematic.enforcementIntensity':
                const mapIntensity = this.getEnforcementIntensityMap(precalc);
                // this.getEnforcementIntensityMap returns null when necessary
                return mapIntensity;
        }
        AError.handleSilent(`"${category}" is not a valid category`, ERROR_GROUPS.InvalidDetectionStatisticsCategory);
        return null;
    }
    getFormulaByCategory(category, precalc) {
        const NULL_VALUE = {
            value: '...',
            formula: '...',
            formulaText: '...'
        };
        switch (category) {
            case 'Total':
                return NULL_VALUE;
            case 'Suspects':
                return this.getSuspectsFormula();
            case 'FollowUps':
                return this.getSuccessfulFollowUpsFormula();
            case 'Sanctions':
                return this.getSanctionsFormula();
            case 'Occupancy':
                return this.getOccupancyFormula();
            case 'VisitorRate':
                return this.getVisitorRateFormula();
            case 'PermitRate':
                return this.getPermitRateFormula();
            case 'Compliancy':
                return this.getCompliancyFormula();
            case 'CompliancyVisitor':
                return this.getCompliancyVisitorsFormula();
            case 'EnforcementIntensity':
                return this.getEnforcementIntensityMapFormula(precalc);
            // TODO: Find out if you want to use the map formula in reports
            // return this.getEnforcementIntensityMapFormula(precalculated)
        }
        console.warn(`"${category}" is not a valid category`);
        return NULL_VALUE;
    }
    /**
     * Get additional information to display to the user
     * @param category one of [Occupancy, VisitorRate, Compliancy, CompliancyVisitor, EnforcementIntensity]
     * @returns html with additional information about the statistic
     */
    async getAdditionalInfoByCategory(category) {
        switch (category) {
            case 'EnforcementIntensity':
                return (`
					<span>${await Translate.get(`Segment Seen:`)} </span><b>${this.Occupancy.EntryCount}</b><br>
					<span>${await Translate.get(`Segment Count:`)} </span><b>${this.EntriesPerGroup}</b>
				`);
        }
        return '';
    }
    /**
     * Transforms thematic map category to ADetectionStatistics chart key
     * @param {string} category thematic category (ex. Occupancy/EnforcementIntensity/Compliancy/CompliancyVisitor/VisitorRate)
     */
    getStatsChartOption(category) {
        switch (category) {
            case 'EnforcementIntensity':
                return null;
            case 'Occupancy':
                return 'Occupancy';
            case 'Total':
            case 'Suspects':
            case 'FollowUps':
            case 'Sanctions':
                return 'Verification';
            default:
                return 'ParkingRight';
        }
    }
    async createFormulaHtml(args) {
        const { category } = args;
        if (!stateService.isUserACI() || !category) {
            return '';
        }
        const formula = this.getFormulaByCategory(category, args?.precalc);
        const additionalHtml = await this.getAdditionalInfoByCategory(category);
        if (formula == null) {
            return additionalHtml;
        }
        const precalcHtml = embedJsonInHtml(args?.precalc, { indendation: 4 });
        const html = await requestService.translateDom(/*html*/ `
			<div class="text-center v-padding">
				<button id="show-formula" class="btn btn-primary col-6">
					<i class="fas fa-function"></i>
          Request Formula
				</button>
			</div>
			<div class="formula hidden">
				<code>
					<table>
						<tr>
							<td>Formula:</td>
							<td>${formula.formulaText}</td>
						</tr>
						<tr>
							<td>Values:</td>
							<td>${formula.formula}</td>
						</tr>
						<tr>
							<td>Result:</td>
							<td>${formula.value}</td>
						</tr>
						<tr>
							<td>Percentage:</td>
							<td>${ARound(formula.value * 100, 2)}%</td>
						</tr>
            <tr>
              <td>Precalc:</td>
              <td notranslate="true">${precalcHtml}</td>
            </tr>
					</table>
				</code>
			</div>
		`);
        return html;
    }
    /**
     * Creates a chart with the breakdown of all statistics & Displays it in a popup modal
     */
    async createChart(opt, unification = 'ParkingRight') {
        let { id, category, title, modalTitle, formulaHtml, disableHover } = opt;
        if (id === undefined) {
            id = idAllocatorService.getNextId({ prefix: 'id-' });
        }
        if (modalTitle == null) {
            modalTitle = await Translate.get(category);
        }
        const chartHtml = ( /*html*/`
      <div>
        <div class="statistics-container statistics-border">
          <div class="statistics-title hidden">${title}</div>
          <div id="${id}" class="pie-chart"></div>
          <div class="route"></div>
        </div>
        ${formulaHtml || ''}
      </div>
    `);
        const events = Alerts.show({
            translatedTitle: modalTitle,
            content: chartHtml
        });
        const $modal = events.$ele;
        if (formulaHtml) {
            const $showFormulaBtn = $modal.find('#show-formula');
            $showFormulaBtn.on('click', () => {
                $modal.find('.formula').removeClass('hidden');
                $showFormulaBtn.addClass('hidden');
            });
            if (unification === null) {
                // If no chart available
                $showFormulaBtn.trigger('click');
            }
        }
        if (unification === null) {
            $modal.find('.statistics-container').addClass('hidden');
        }
        else {
            return new AStatisticsChart({
                id,
                title: title,
                statistics: this,
                //chartType: Occupancy, VisitorRate, Compliancy, CompliancyVisitor, EnforcementIntensity
                chartType: category,
                disableHover: disableHover
            }).setPrefferedHeight('350px').show();
        }
    }
}
