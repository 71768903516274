import { ATemplates } from "../core/ATemplateService.js";
export class APage {
    constructor() { }
}
export function render() {
    return ( /*html*/`
    <div class="flex-child fh">
      <div template="${ATemplates.Reload}"></div>
    </div>
  `);
    // return (/*html*/`
    //   <div style="position:relative;width:100%;height:100%;padding:2.5vw;"> 
    //     <button id="ReloadButton" onclick="location.reload(true); ">Reload </button>
    //   </div>
    // `)
}
