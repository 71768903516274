import { EVENTS } from "../services/AEventService.js";
export class APage {
    constructor() {
    }
    async init() {
        // TODO: Implement authenticator as popup in the login screen
        // const loginPromise = this.waitForLogin()
        // return {
        //     username,
        //     loginSuccess: await loginPromise,
        //     loadFramework: AfterLogin
        // }
        eventService.once(EVENTS.STATE_STREAM, ({ Status }) => {
            if (Status === 'LoggedIn') {
                window.location.reload();
            }
        });
        eventService.once(EVENTS.SESSION_CHANGE_STREAM, (a, b, c) => {
            console.log(a, b, c);
        });
        if (globalThis.QRImageData) {
            $('#QRImage').attr('src', "data:image/png;base64," + globalThis.QRImageData);
        }
        globalThis.QRImageData = null;
        $("#AuthenticatorCode").on('keypress', (e) => {
            if (e.which == 13) {
                $("#Validate").trigger("click");
            }
        });
        $("#Validate").on('click', function () {
            requestService.send("AuthenticatorSyncResponse", { AuthenticatorCode: $("#AuthenticatorCode").val() });
        });
    }
}
export function render() {
    return ( /*html*/`
    <div class="wrapper">
      <div class="columns">

        <div class="column col-3 col-mx-auto">
          <div class="columns">
            <div class="column col-12 form-group" id="AuthenticatorCodeHolder">
              <label for="AuthenticatorCode" class="form-label">Authenticator Code</label>
              <input id="AuthenticatorCode" class="form-input" type="text" placeholder="AuthenticatorCode">
            </div>
            <div class="column col-12">
              <button id="Validate" class="btn btn-primary col-12 mb-2">Validate</button>
            </div>
            <div class="column col-12">
              <img id="QRImage" width="450" height="450" style="width: 100%; height: auto;">
            </div>
          </div>
        </div>
        
      </div>
    </div>
  `);
}
