export class AMenuHelperService {
    constructor() {
        this.args = {};
    }
    autoInit() { }
    setIdMr(id_mr) {
        this.id_mr = id_mr;
    }
    setMenuItem(menuItem) {
        this.currentMenuItem = menuItem;
        this.setIdMr(menuItem.id_mr);
    }
    getMenuItem(id_mr) {
        const keys = Object.keys(menuService.menu);
        for (let key of keys) {
            const value = menuService.menu[key];
            if (id_mr === value.id_mr) {
                return value;
            }
            else if (value.children && value.children.length > 0) {
                const keys2 = Object.keys(value.children);
                for (let key2 of keys2) {
                    const value2 = value.children[key2];
                    if (id_mr === value2.id_mr) {
                        return value2;
                    }
                }
            }
        }
        return null;
    }
    pressMenuItem(id_mr, args) {
        const menuItem = this.getMenuItem(id_mr);
        if (menuItem !== null) {
            window.location.href = '/#!' + menuItem.url;
        }
        this.args = args;
        setTimeout(_ => {
            this.args = {};
        }, 1000);
    }
}
