import { AEngine } from "../../core/AEngine.js";
export class AResizeHandler {
    get minWidth() { return this.opt.minSize?.width ?? 80; }
    get minHeight() { return this.opt.minSize?.height ?? 200; }
    constructor(opt) {
        this.opt = opt;
        // Which anchor is selected for dragging (top-left, top-right, bottom-left, bottom-right)
        this.anchorType = 'br';
        // The Current Position of the window
        this.currPos = { left: 0, top: 0 };
        this.prevPos = { left: 0, top: 0 };
        // Start Offset (The starting point is when mouse down event is triggered)
        this.stOffset = { left: 0, top: 0 };
        // Start Size (The starting point is when mouse down event is triggered)
        this.stSize = { width: 0, height: 0 };
    }
    attachTo($win) {
        this.$all = $win.find('.awin-anchor');
        const startElementResize = (e) => {
            // Prevent any default action on this element (you can remove if you need this element to perform its default action)
            e.preventDefault();
            this.anchorType = $(e.target).attr('anchor');
            this.stOffset = $win.offset();
            this.stSize = { width: $win.width(), height: $win.height() };
            this.prevPos = { left: e.clientX, top: e.clientY };
            document.addEventListener('mouseup', stopElementResize);
            document.addEventListener('mousemove', elementResize);
        };
        const elementResize = (e) => {
            e.preventDefault();
            this.currPos = { left: this.prevPos.left - e.clientX, top: this.prevPos.top - e.clientY };
            const props = this.calcTransform();
            // AEngine.log('resize', {...props, target: $(e.target!)})
            $win.offset(props.offset);
            $win.width(props.width);
            $win.height(props.height);
        };
        const stopElementResize = () => {
            document.removeEventListener('mouseup', stopElementResize);
            document.removeEventListener('mousemove', elementResize);
        };
        this.elementResize = elementResize;
        this.stopElementResize = stopElementResize;
        // The mousedown event starts the listening process
        this.$all.on('mousedown', (e) => startElementResize(e.originalEvent));
        return this;
    }
    calcTransform() {
        let { anchorType, stSize, stOffset, currPos, minHeight, minWidth } = this;
        let outOffset = { top: stOffset.top, left: stOffset.left };
        let outSize = { height: stSize.height, width: stSize.width };
        // let outHeight = stSize.height, outWidth = stSize.width
        switch (anchorType[0]) {
            case 't':
                var overdraft = Math.max(0, minHeight - (stSize.height + currPos.top));
                outOffset.top = stOffset.top - (currPos.top + overdraft);
                outSize.height = stSize.height + (currPos.top + overdraft);
                break;
            case 'b':
                outSize.height = Math.max(minHeight, stSize.height - currPos.top);
                break;
        }
        switch (anchorType[1]) {
            case 'l':
                var overdraft = Math.max(0, minWidth - (stSize.width + currPos.left));
                outOffset.left = stOffset.left - (currPos.left + overdraft);
                outSize.width = stSize.width + (currPos.left + overdraft);
                break;
            case 'r':
                outSize.width = Math.max(minWidth, stSize.width - currPos.left);
                break;
        }
        return { offset: outOffset, width: outSize.width, height: outSize.height };
    }
    destruct() {
        this.$all.off('mousedown');
        document.removeEventListener('mouseup', this.stopElementResize);
        document.removeEventListener('mousemove', this.elementResize);
        AEngine.log('AResizeHandler.%cdestruct%p()%n Invoked!');
    }
}
