import { ADetectionStatistics } from '../classes/ADetectionStatistics.js';
import { AResponse } from '../classes/AResponse.js';
import { AStatisticResponse } from '../classes/AStatisticResponse.js';
import { AParkingRight, AVerification } from '../classes/AUnificationTypes.js';
import { AError } from '../classes/AError.js';
import { AEngine } from '../core/AEngine.js';
import { AConfig } from '../classes/AConfig.js';
var vRef = new AVerification();
var finedRef = vRef.Options.Fined;
export class AStatisticsService {
    constructor() {
        this.groupToTableMap = {
            '*': { columnName: 'SegmentId', columnSelect: 'dgob.GeoId' },
            All: { columnName: 'All', columnSelect: '"All"' },
            HourOfDay: { columnName: 'HourOfDay', columnSelect: 'df.HourOfDay' },
            DayOfWeek: { columnName: 'DayOfWeek', columnSelect: 'df.DayOfWeek' },
            MonthOfYear: { columnName: 'MonthOfYear', columnSelect: 'df.MonthOfYear' },
            Year: { columnName: 'Year', columnSelect: 'df.Year' },
            DetectionDeviceId: { columnName: 'DetectionDeviceId', columnSelect: 'df.DetectionDeviceId' },
            ScanDeviceType: { columnName: 'DetectionDeviceId', columnSelect: 'df.DetectionDeviceId' },
            VerificationDevice: { columnName: 'VerificationDevice', columnSelect: 'df.FinalVerificationDevice' },
            VerificationDeviceType: { columnName: 'VerificationDeviceType', columnSelect: 'df.VerificationDeviceType' },
            FinalVerificationUser: { columnName: 'FinalVerificationUser', columnSelect: 'df.FinalVerificationUser' },
            VerificationUser: { columnName: 'VerificationUser', columnSelect: `NULLIF(df.FinalVerificationUser, '')` },
            FollowupRate: { columnName: 'FollowupRate', columnSelect: `IF(df.Verification BETWEEN ${finedRef.FirstIndex} AND ${finedRef.LastIndex}, 'FOLLOWUP', 'OTHER')` },
            SuspectRate: { columnName: 'SuspectRate', columnSelect: ADetectionStatistics.getSuspectsSQL() },
            SanctionRate: { columnName: 'SanctionRate', columnSelect: `IF(df.Verification BETWEEN ${finedRef.FirstIndex} AND ${finedRef.LastIndex}, 'FINED', 'OTHER')` },
        };
    }
    async fetchDynamicSingle(filters) {
        const dynamicStats = await this.fetchDynamic(filters);
        return dynamicStats.toTuple().map(([data, stats]) => stats).pop() || new ADetectionStatistics();
    }
    async fetchDynamic(filters, options) {
        const { joinTables, where, columns, skipDefaultWhereClause } = Object.assign({
            joinTables: [],
            where: [],
            columns: [],
            skipDefaultWhereClause: false,
        }, options);
        const selects = columns.map(obj => `${obj.select} as \`${obj.name}\``);
        const groups = columns.filter(col => col.groupBy === true).map(obj => `\`${obj.name}\``);
        const additionalColumns = selects.length ? `,${selects.join(', ')}` : '';
        const additionalGroupBy = groups.length ? `${groups.join(', ')},` : '';
        const additionalOrderBy = groups.length ? `ORDER BY ${groups.join(', ')}` : '';
        const additionalJoins = joinTables.join(' ');
        const whereClause = (skipDefaultWhereClause ? [] : [
            'df.DetectionTime BETWEEN :FromDate AND :ToDate',
            'df.FinalizedSuccess = 1'
        ]).concat(where).join(' AND ');
        let detections = await requestService.query({
            Query: (`
        SELECT
          df.Digital,
          df.IllegallyParked,
          df.TimeLimitedParking,
          df.ParkingRight,
          df.Verification,
          df.DetectionState,
          count(*) as Total
          ${additionalColumns}
        FROM ${options?.baseTable || "detections_final"} df
        ${additionalJoins}
        WHERE
          ${whereClause}
        GROUP BY
          ${additionalGroupBy}
          df.Digital,
          df.TimeLimitedParking,
          df.IllegallyParked,
          df.ParkingRight,
          df.DetectionState,
          df.Verification
        ${additionalOrderBy}
      `),
            Params: filters,
            Translate: columns.filter(v => v.translate === true).map(v => v.name),
            Language: Language
        });
        // globalThis.peepeepoopoo = new AResponse(detections)
        // console.log(globalThis.peepeepoopoo)
        const output = [];
        const count = [];
        for (let row of detections.Rows) {
            let key = '_';
            for (let i = 0; i < columns.length; i++) {
                const { groupBy } = columns[i];
                if (groupBy === true) {
                    key += row[7 + i];
                }
            }
            if (!output.hasOwnProperty(key)) {
                output[key] = [{}, new ADetectionStatistics()];
            }
            if (!count.hasOwnProperty(key)) {
                count[key] = {};
            }
            for (let i = 0; i < columns.length; i++) {
                const { name, method } = columns[i];
                if (method == "average") {
                    if (row[7 + i] !== null) {
                        if (!output[key][0].hasOwnProperty(name)) {
                            output[key][0][name] = 0;
                        }
                        // AEngine.log('avg += ', row[7 + i])
                        output[key][0][name] += Number(row[7 + i]);
                        if (!count[key].hasOwnProperty(name)) {
                            count[key][name] = 0;
                        }
                        count[key][name] += Number(row[6]);
                    }
                }
                else {
                    output[key][0][name] = row[7 + i];
                }
            }
            output[key][1].addDetectionFinalCount(row[0], row[1], row[2], row[3], row[4], row[5], Number(row[6]));
        }
        for (let key in output) {
            for (let i = 0; i < columns.length; i++) {
                const { name, method } = columns[i];
                if (method == "average") {
                    if (!output[key][0].hasOwnProperty(name) || !count[key][name]) {
                        output[key][0][name] = null;
                    }
                    else {
                        output[key][0][name] /= count[key][name];
                    }
                }
            }
        }
        return new AStatisticResponse(Object.values(output), detections);
    }
    /**
     * Creates DetectionsStatistics with given filters
     * returns one or more hashtables with key being SegmentId and value of ADetectionStatistics
     *
     * Returns one hashtable by default
     * Returns one hashtable if one or zero mapTo methods are used
     * Returns an array of hashtabled if more than one mapTo methods are used
     */
    async fetch(filters, options) {
        const defaultOpt = {
            baseTable: 'detections_final',
            groups: ['SegmentId'],
            ignoreDetectionsOutsideSegment: false,
            onlyAllowFinalized: true,
            skipSegmentsQuery: false,
            mapTo: (segmentId) => segmentId
        };
        const opt = Object.assign({}, defaultOpt, options);
        let added = 0;
        opt.groups.map((g, i) => {
            const key = ['groupA', 'groupB'][added++];
            Object.assign(opt, {
                [key]: (this.groupToTableMap.hasOwnProperty(g)) ? this.groupToTableMap[g] : this.groupToTableMap['*']
            });
        });
        let { detections, segmentEntries } = await this.fetchQueries(filters, opt);
        let statisticsTotal = new ADetectionStatistics();
        let statistics = {};
        let processedDetections = 0, skippedDetections = 0;
        let success = 0, warnings = [];
        const { mapTo } = opt;
        const detectionsObj = new AResponse(detections);
        detectionsObj.loop((item) => {
            let mappedKey = mapTo(item.GroupA, item.GroupB);
            // TODO: Optimize code
            const overlap = 1;
            // const key = (mappedKey != null && typeof mappedKey === 'object') ? mappedKey.key : mappedKey
            // const overlap = (mappedKey != null && typeof mappedKey === 'object') ? mappedKey.overlap : 1
            if (mappedKey !== null) {
                const key = mappedKey ?? '?';
                if (!statistics.hasOwnProperty(key)) {
                    statistics[key] = new ADetectionStatistics();
                }
                statistics[key].addDetectionFinalCount(item.Digital, item.IllegallyParked, item.TimeLimitedParking, item.ParkingRight, item.Verification, item.DetectionState, Number(item.Total) * overlap);
                success++;
                processedDetections += Number(item.Total);
            }
            else {
                warnings.push(`'${item.GroupA}' to mappedKey [${[item.GroupA, item.GroupB].filter(v => v != null).join(', ')}]!`);
                skippedDetections += Number(item.Total);
            }
            statisticsTotal.addDetectionFinalCount(item.Digital, item.IllegallyParked, item.TimeLimitedParking, item.ParkingRight, item.Verification, item.DetectionState, Number(item.Total));
        });
        const segmentEntriesObj = new AResponse(segmentEntries);
        segmentEntriesObj.loop(({ OccupancyAvg, CapacityAvg, SeenCapacityAvg, EntryCount, GroupA, GroupB }) => {
            let mappedKey = mapTo(GroupA, GroupB);
            // TODO: Optimize code
            // const key = (mappedKey != null && typeof mappedKey === 'object') ? mappedKey.key : mappedKey
            // const overlap = (mappedKey != null && typeof mappedKey === 'object') ? mappedKey.overlap : 1
            const overlap = 1;
            if (mappedKey !== null) {
                const key = mappedKey ?? '?';
                if (!statistics.hasOwnProperty(key)) {
                    statistics[key] = new ADetectionStatistics();
                }
                statistics[key]
                    .addOccupancy(OccupancyAvg * overlap, Number(CapacityAvg) * overlap, Number(SeenCapacityAvg) * overlap, Number(EntryCount) * overlap); /// added SeenCapacitySum
                success++;
            }
            else {
                warnings.push(`'${GroupA}' to mappedKey [${[GroupA, GroupB].filter(v => v != null).join(', ')}]!`);
            }
            statisticsTotal
                .addOccupancy(OccupancyAvg, Number(CapacityAvg), Number(SeenCapacityAvg), Number(EntryCount)); /// added SeenCapacitySum
        });
        if (warnings.length) {
            AEngine.log(`Processed:`, success);
            AEngine.warn(`Couldn't link ${warnings.length} statistics\n`, warnings);
        }
        return {
            statisticsTotal: statisticsTotal,
            statistics: statistics,
            processedDetections,
            skippedDetections,
            rowCounts: detections.Rows.length
        };
    }
    async fetchQueries(filters, options) {
        const { baseTable, onlyAllowFinalized, ignoreDetectionsOutsideSegment, extraWhereClause, groupA, groupB, skipSegmentsQuery } = options;
        const { DetectionDeviceId } = filters;
        const conditions = [
            (DetectionDeviceId && DetectionDeviceId !== '%') ? 'df.DetectionDeviceId=:DetectionDeviceId' : null,
            (onlyAllowFinalized === true) ? 'FinalizedSuccess = 1' : null,
            (ignoreDetectionsOutsideSegment === true) ? 'se.SegmentTimeStamp IS NOT NULL' : null,
            (extraWhereClause) ? extraWhereClause : null,
        ].filter(v => v !== null);
        const additionalWhere = conditions?.length ? (' AND ' + conditions.join(' AND ')) : '';
        // filters["MinEnforcing"] = configService.get('general.minEnforcingForOccupancy')
        filters["MinEnforcing"] = AConfig.get(`general.minEnforcingForOccupancy`, 0.1);
        // ${extraGroupBy ? `${extraGroupBy} AS GroupB,` : ''}
        // ${gdgob.GeoId AS SegmentId}
        const sGroupByA = groupA ? groupA.columnSelect : undefined;
        const sGroupByB = groupB ? groupB.columnSelect : undefined;
        let detections = await requestService.query({
            Query: (`
        SELECT
          Digital,
          IllegallyParked,
          TimeLimitedParking,
          ParkingRight,
          Verification,
          DetectionState,
          count(*) as Total,
          ${sGroupByB ? `${sGroupByB} AS GroupB,` : ''}
          ${sGroupByA ? `${sGroupByA}` : 'dgob.GeoId'} AS GroupA
        FROM ${baseTable} df
        LEFT JOIN detection_geo_object_best dgob ON (df.DetectionId = dgob.DetectionId AND df.DetectionDeviceId = dgob.DetectionDeviceId AND dgob.GeoType='Segment')
        LEFT JOIN segment_entries se ON (se.SegmentTimeStamp = dgob.TimeStamp AND se.SegmentId = dgob.GeoId)
        ${filters.ZoneMulti ? `INNER JOIN detection_geo_object_best zone ON (df.DetectionId = zone.DetectionId AND df.DetectionDeviceId = zone.DetectionDeviceId AND zone.GeoType='Zone' AND zone.GeoId ${FilterManager.buildQueryFindInArray(filters.ZoneMulti)})` : ''}
        ${filters.AreaMulti ? `INNER JOIN detection_geo_object_best area ON (df.DetectionId = area.DetectionId AND df.DetectionDeviceId = area.DetectionDeviceId AND area.GeoType='Area' AND area.GeoId ${FilterManager.buildQueryFindInArray(filters.AreaMulti)})` : ''}
        WHERE
          DetectionTime BETWEEN :FromDate AND :ToDate
          ${additionalWhere}
        GROUP BY
          ${sGroupByA ? `${sGroupByA},` : 'dgob.GeoId,'}
          ${sGroupByB ? `${sGroupByB},` : ''}
          Digital,
          TimeLimitedParking,
          IllegallyParked,
          ParkingRight,
          Verification,
          DetectionState
      `),
            Params: filters
        });
        const groupBySegments = [
            sGroupByB,
            (filters.ZoneMulti) ? 'gc.ToGeoId' : undefined,
            (filters.AreaMulti) ? 'gc2.ToGeoId' : undefined,
        ].filter(v => v != null).join(', ');
        let segmentEntries = (skipSegmentsQuery) ? {
            Columns: [],
            ColumnsTranslated: [],
            Rows: []
        } : await requestService.query({
            Query: (`
        SELECT
          IFNULL(AVG(if(Enforcing < :MinEnforcing, null, OccupancyCount)), 0) AS OccupancyAvg,
          AVG(Capacity) AS CapacityAvg,
          IFNULL(AVG(if(Enforcing < :MinEnforcing, null, Capacity)), 0) AS SeenCapacityAvg,
          COUNT(*) AS EntryCount,
          ${sGroupByB ? `${sGroupByB} AS GroupB,` : ''}
          ${sGroupByA ? `${sGroupByA}` : 'dgob.GeoId'} AS GroupA
        FROM
          segment_entries se
          LEFT JOIN detection_geo_object_best dgob ON (se.SegmentTimeStamp = dgob.TimeStamp AND se.SegmentId = dgob.GeoId)
          LEFT JOIN detections_final df ON (df.DetectionId=dgob.DetectionId AND df.DetectionDeviceId=dgob.DetectionDeviceId)
          ${filters.ZoneMulti ? `LEFT JOIN geo_connections gc ON (se.SegmentId=gc.FromGeoId AND gc.FromGeoType='Segment' AND gc.ToGeoType='Zone' AND (gc.ToGeoId ${FilterManager.buildQueryFindInArray(filters.ZoneMulti)}))` : ''}
          ${filters.AreaMulti ? `LEFT JOIN geo_connections gc2 ON (se.SegmentId=gc2.FromGeoId AND gc2.FromGeoType='Segment' AND gc2.ToGeoType='Area' AND (gc2.ToGeoId ${FilterManager.buildQueryFindInArray(filters.AreaMulti)}))` : ''}
        WHERE
          se.SegmentTimeStamp BETWEEN :FromDate AND :ToDate AND
          Capacity > 0
          ${filters.ZoneMulti ? `AND gc.FromGeoId IS NOT NULL` : ''}
          ${filters.AreaMulti ? `AND gc2.FromGeoId IS NOT NULL` : ''}
        GROUP BY
          ${sGroupByA ? `${sGroupByA}` : 'se.SegmentId'}
          ${groupBySegments ? `,${groupBySegments}` : ''}
      `),
            Params: filters
        });
        return { detections, segmentEntries };
    }
    async fetchGeoConnectionNames(opt) {
        const map = {};
        const imap = {};
        try {
            const connections = await this.fetchGeoConnections(opt);
            for (const [FromGeoId, ToGeoId, Name] of connections) {
                map[FromGeoId] = Name;
                imap[Name] = FromGeoId;
            }
        }
        catch (err) {
            this.catchError(err);
        }
        return { map, imap };
    }
    async fetchGeoConnectionIds(opt) {
        const map = {};
        const imap = {};
        try {
            const connections = await this.fetchGeoConnections(opt);
            for (const [FromGeoId, ToGeoId, Name] of connections) {
                map[FromGeoId] = ToGeoId;
                imap[ToGeoId] = FromGeoId;
            }
        }
        catch (err) {
            this.catchError(err);
        }
        return { map, imap };
    }
    async fetchGeoConnections(filters) {
        Object.keys(filters).map(key => {
            if (['FromGeoType', 'ToGeoType'].includes(key)) {
                if (filters[key] === 'Street') {
                    filters[key] = 'WaySegment';
                }
            }
        });
        const { Rows } = await requestService.query({
            Query: ( /*sql*/`
        SELECT FromGeoId, ToGeoId, go1.Name
        FROM (
          SELECT 
            gobj.GeoId AS ToGeoId,
            gobj.Geo,
            gobj.Attributes,
            gobj.Name,
            gobj.Visible,
            gobj.Active,
            gobj.GeoCreated,
            gobj.GeoVersion
          FROM geo_objects gobj
          WHERE (gobj.GeoType = :ToGeoType)
        ) go1
        INNER JOIN (
          SELECT 
            o.FromGeoId AS FromGeoId,
            o.ToGeoId AS ToGeoId
          FROM (
            geo_connections o
            LEFT JOIN geo_connections b ON (((o.FromGeoId = b.FromGeoId)
              AND (o.FromGeoType = b.FromGeoType)
              AND (o.ToGeoType = b.ToGeoType)
              AND (o.FromGeoOverlap < b.FromGeoOverlap)))
          )
          WHERE ((o.FromGeoType = :FromGeoType) AND (o.ToGeoType = :ToGeoType) AND ISNULL(b.FromGeoId))
          GROUP BY o.FromGeoId, o.ToGeoId
        ) go2 USING (ToGeoId)
        WHERE active=1
        GROUP BY FromGeoId, ToGeoId, go1.Name
      `),
            Params: { ...filters }
        });
        return Rows;
    }
    /**
     * @deprecated
     */
    fetchSegmentToStreetName() {
        const map = {};
        const imap = {};
        return requestService.query(`
      SELECT SegmentId, Name
      FROM geo_waysegments
      INNER JOIN geo_segments2waysegments USING (WaySegmentId)
      WHERE active=1 AND LENGTH(Name) > 0
      GROUP BY SegmentId
    `).then(({ Rows }) => {
            for (const [segmentId, name] of Rows) {
                map[segmentId] = name;
                imap[name] = segmentId;
            }
            return { map, imap };
        }).catch(err => this.catchError(err));
    }
    /**
     * @deprecated
     */
    fetchSegmentToWaySegmentId() {
        const map = {};
        const imap = {};
        return requestService.query(`
      SELECT SegmentId, WaySegmentId
      FROM geo_waysegments
      INNER JOIN geo_segments2waysegments USING (WaySegmentId)
      WHERE active=1
      GROUP BY SegmentId
    `).then(({ Rows }) => {
            for (const [segmentId, id] of Rows) {
                map[segmentId] = id;
                if (!imap.hasOwnProperty(id)) {
                    imap[id] = [];
                }
                imap[id].push(segmentId);
            }
            return { map, imap };
        }).catch(err => this.catchError(err));
    }
    /**
     * @deprecated
     */
    fetchSegmentToZone() {
        const map = {};
        const imap = {};
        return requestService.query(`
      SELECT SegmentId, ZoneId
      FROM geo_zones
      INNER JOIN geo_segments2zones USING (ZoneId)
      WHERE active=1
      GROUP BY SegmentId
    `).then(({ Rows }) => {
            for (const [segmentId, id] of Rows) {
                map[segmentId] = id;
                if (!imap.hasOwnProperty(id)) {
                    imap[id] = [];
                }
                imap[id].push(segmentId);
            }
            return { map, imap };
        }).catch(err => this.catchError(err));
    }
    catchError(err) {
        const TABLE_ERROR_IDENTIFIER = 'Prepare failed:Table ';
        try {
            if (!err.message.startsWith(TABLE_ERROR_IDENTIFIER)) {
                return AError.handle(err);
            }
            const tablename = err.message.split(`'`)[1];
            const specificError = new Error(`Table '${tablename}' doesn't exist in the database!`);
            return AError.handle({
                useAdminAlerts: true,
                err: [err, specificError]
            });
        }
        catch (err) {
            return AError.handle(err);
        }
    }
    async fetchUsernameMap() {
        const map = {};
        const users = await requestService.fetch({
            AssertValues: true,
            Query: (`SELECT User, DisplayName FROM users`)
        });
        users.map((u) => {
            const { User, DisplayName } = u;
            map[User] = DisplayName || User;
            if (DisplayName && DisplayName.length > 0) {
                map[DisplayName] = DisplayName;
            }
        });
        return map;
    }
    async fetchDriverHours(filters, options) {
        const { usernameMap } = options;
        console.warn(`// TODO: Fix subquery`);
        const userData = await requestService.fetch({
            AssertValues: true,
            Query: (`
        SELECT
          Username,
          SUM(TravelDuration) / 3600 AS OperationHours,
          SUM(IF((EnforcingLeft > 0 or EnforcingRight > 0 ), TravelDuration, 0)) / 3600 AS ScanHours,
          SUM(TravelDistance) / 1000 AS DistanceKM
        FROM waysegment_entries ways
        LEFT JOIN (SELECT max(UserSessionId) AS UserSessionId, SessionId, COALESCE(UserDisplayName, User, '') AS Username FROM user_session_start group by SessionId) ss ON (ways.SessionId = ss.SessionId)
        WHERE ToDateTime > :FromDate AND :ToDate > FromDateTime
        GROUP BY Username
      `),
            // Query: (`
            //   SELECT
            //     SUM(TravelDuration) / 3600 AS OperationHours,
            //     SUM(IF((EnforcingLeft > 0 or EnforcingRight > 0 ), TravelDuration, 0)) / 3600 AS ScanHours,
            //     SUM(TravelDistance) / 1000 AS DistanceKM
            //   FROM waysegment_entries
            //   WHERE :ToDate > FromDateTime AND :FromDate < ToDateTime
            // `),
            Params: filters,
        });
        const output = {};
        userData.loop((item) => {
            const Username = (usernameMap.hasOwnProperty(item.Username)) ? usernameMap[item.Username] : item.Username;
            if (!output.hasOwnProperty(Username)) {
                output[Username] = {
                    Username,
                    ScanHours: 0,
                    OperationHours: 0,
                    DistanceKM: 0,
                };
            }
            output[Username].ScanHours += item.ScanHours;
            output[Username].OperationHours += item.OperationHours;
            output[Username].DistanceKM += item.DistanceKM;
        });
        return output;
    }
    async fetchFollowUpHours(filters) {
        const ares = await requestService.fetch({
            AssertValues: true,
            Query: (`
        SELECT
          s.UserDisplayName,
          SEC_TO_TIME(SUM(SessionInSeconds)) AS SessionDuration,
          s.DeviceId,
          s.SessionMode,
          s.UserSessionStart,
          s.UserSessionEnd
        FROM (
          SELECT
          UserSessionId,
          DeviceId,
          SessionMode,
          UserSessionStart,
          IFNULL(UserSessionEnd, NOW()) AS UserSessionEnd,
          TIMESTAMPDIFF(SECOND, UserSessionStart, IFNULL(UserSessionEnd, NOW())) AS SessionInSeconds,
          UserDisplayName
          FROM user_session_start
          LEFT JOIN user_session_end USING (UserSessionId)
          WHERE UserSessionStart BETWEEN '2023-09-01 00:00:00' AND '2023-09-11 17:00:00'
        ) s
        GROUP BY UserDisplayName, DeviceId, SessionMode;
      `),
            Params: filters
        });
        AEngine.log('ares', ares);
        return ares;
    }
    async fetchGroupedByDetectionUser(filters, options) {
        const { ignoreDetectionsOutsideSegment, usernameMap, where } = Object.assign({ where: [], ignoreDetectionsOutsideSegment: false, usernameMap: undefined }, options || {});
        let additionalWhere = where.join(' AND ') || '';
        if (ignoreDetectionsOutsideSegment === true) {
            additionalWhere += ' AND SegmentTimeStamp IS NOT NULL';
        }
        const response = await requestService.query({
            Query: (`
        SELECT
          DetectionUser,
          Digital,
          IllegallyParked,
          TimeLimitedParking,
          ParkingRight,
          Verification,
          DetectionState,
          COUNT(*) as Total
        FROM detections_final
        WHERE
          DetectionTime BETWEEN :FromDate AND :ToDate AND
          FinalizedSuccess = 1 ${additionalWhere}
        GROUP BY
          DetectionUser,
          Digital,
          TimeLimitedParking,
          IllegallyParked,
          ParkingRight,
          Verification,
          DetectionState
      `),
            Params: filters
        });
        const output = {};
        response.Rows.map(([DetectionUser, Digital, IllegallyParked, TimeLimitedParking, ParkingRight, Verification, DetectionState, Total]) => {
            let user = DetectionUser;
            if (usernameMap && usernameMap.hasOwnProperty(user)) {
                user = usernameMap[user];
            }
            if (!output.hasOwnProperty(user)) {
                output[user] = new ADetectionStatistics();
            }
            output[user].addDetectionFinalCount(Digital, IllegallyParked, TimeLimitedParking, ParkingRight, Verification, DetectionState, Number(Total));
        });
        return output;
    }
    async fetchTotals(filters, options) {
        const { ignoreDetectionsOutsideSegment } = Object.assign({ ignoreDetectionsOutsideSegment: false }, options || {});
        let additionalWhere = '';
        if (ignoreDetectionsOutsideSegment === true) {
            additionalWhere += ' AND SegmentTimeStamp IS NOT NULL';
        }
        const response = await requestService.query({
            Query: (`
        SELECT
          Digital,
          IllegallyParked,
          TimeLimitedParking,
          ParkingRight,
          Verification,
          DetectionState,
          COUNT(*) as Total
        FROM detections_final
        WHERE
          DetectionTime BETWEEN :FromDate AND :ToDate AND
          FinalizedSuccess = 1 ${additionalWhere}
        GROUP BY
          Digital,
          TimeLimitedParking,
          IllegallyParked,
          ParkingRight,
          Verification,
          DetectionState
      `),
            Params: filters
        });
        const output = new ADetectionStatistics();
        response.Rows.map(([Digital, IllegallyParked, TimeLimitedParking, ParkingRight, Verification, DetectionState, Total]) => {
            output.addDetectionFinalCount(Digital, IllegallyParked, TimeLimitedParking, ParkingRight, Verification, DetectionState, Number(Total));
        });
        return output;
    }
    async fetchTreeBreakDown(filters) {
        let parkingRightRef = new AParkingRight();
        let verificationRef = new AVerification();
        var UnificationRanges = {};
        UnificationRanges["ParkingRight_InProgress_Start"] = parkingRightRef.Options.InProgress.FirstIndex;
        UnificationRanges["ParkingRight_InProgress_End"] = parkingRightRef.Options.InProgress.LastIndex;
        UnificationRanges["ParkingRight_NotProcessed_Start"] = parkingRightRef.Options.NotProcessed.FirstIndex;
        UnificationRanges["ParkingRight_NotProcessed_End"] = parkingRightRef.Options.NotProcessed.LastIndex;
        UnificationRanges["Verification_InProgress_Start"] = verificationRef.Options.InProgress.FirstIndex;
        UnificationRanges["Verification_InProgress_End"] = verificationRef.Options.InProgress.LastIndex;
        UnificationRanges["Verification_NotProcessed_Start"] = verificationRef.Options.NotProcessed.FirstIndex;
        UnificationRanges["Verification_NotProcessed_End"] = verificationRef.Options.NotProcessed.LastIndex;
        UnificationRanges["Verification_NoVerificationNeeded_Start"] = verificationRef.Options.NoVerificationNeeded.FirstIndex;
        UnificationRanges["Verification_NoVerificationNeeded_End"] = verificationRef.Options.NoVerificationNeeded.LastIndex;
        UnificationRanges["Verification_Fined_Start"] = verificationRef.Options.Fined.FirstIndex;
        UnificationRanges["Verification_Fined_End"] = verificationRef.Options.Fined.LastIndex;
        UnificationRanges["Verification_Reprimanded_Start"] = verificationRef.Options.Reprimanded.FirstIndex;
        UnificationRanges["Verification_Reprimanded_End"] = verificationRef.Options.Reprimanded.LastIndex;
        UnificationRanges["Verification_NotFined_Start"] = verificationRef.Options.NotFined.FirstIndex;
        UnificationRanges["Verification_NotFined_End"] = verificationRef.Options.NotFined.LastIndex;
        const response = await requestService.query({
            Query: ( /*sql*/`
        SELECT
          Total,
          _InProgress,
          _NotProcessed,
          Total - (_InProgress + _NotProcessed + _Suspects) as _Compliant,
          _Suspects,
          __InProgress,
          __NotProcessedAll - ___Discarded as __NotProcessed,
          ___Fined + ___NotFined + ___Discarded + ___Reprimanded as __FollowUps,
          ___Fined,
          ___Reprimanded,
          ___NotFined,
          ___Discarded
        FROM (
          SELECT
          SUM(Total) as Total,
          SUM(
            if(
              !ifnull(IsSuspect,0)
              and ParkingRight between :ParkingRight_InProgress_Start
              and :ParkingRight_InProgress_End,
              total,
              0
            )
          ) _InProgress,
          SUM(
            if(
              !ifnull(IsSuspect,0)
              and ParkingRight between :ParkingRight_NotProcessed_Start
              and :ParkingRight_NotProcessed_End,
              total,
              0
            )
          ) _NotProcessed,
          SUM(if(IsSuspect, Total, 0)) as _Suspects,
          SUM(
            if(
              IsSuspect
              and Verification between :Verification_InProgress_Start
              and :Verification_InProgress_End,
              total,
              0
            )
          ) __InProgress,
          SUM(
            if(
              IsSuspect
              and Verification between :Verification_Reprimanded_Start
              and :Verification_Reprimanded_End,
              total,
              0
            )
          ) ___Reprimanded,
          SUM(
            if(
              IsSuspect
              and Verification between :Verification_Fined_Start
              and :Verification_Fined_End,
              total,
              0
            )
          ) ___Fined,
          SUM(
            if(
              IsSuspect
              and (
                Verification between :Verification_NotFined_Start
                and :Verification_NotFined_End
                or Verification between :Verification_NoVerificationNeeded_Start
                and :Verification_NoVerificationNeeded_End
              ),
              total,
              0
            )
          ) as ___NotFined,
          SUM(
            if(
              IsSuspect
              and Verification between :Verification_NotProcessed_Start
              and :Verification_NotProcessed_End,
              total,
              0
            )
          ) __NotProcessedAll,
          SUM(
            if(
              IsSuspect
              and (
                Verification = "NotProcessed_VehicleNotFound"
                or Verification = "NotProcessed_WrongRecognition"
              ),
              total,
              0
            )
          ) as ___Discarded
        FROM (
          SELECT
            ParkingRight,
            Verification,
            IsSuspect,
            count(*) as Total
          FROM
            detections_final
          where
            DetectionTime BETWEEN :FromDate AND :ToDate 
          group by
            ParkingRight,
            Verification,
            IsSuspect
          ) T1
        ) T2
      `),
            Params: { ...filters, ...UnificationRanges }
        });
        if (response?.Rows?.length > 0) {
            let result = {};
            for (let i = 0; i < response.Columns.length; i++) {
                result[response.Columns[i]] = response.Rows[0][i];
            }
            return result;
        }
        throw new Error("Failed to load TreeBreakdown");
    }
}
