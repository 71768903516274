import { AConfig } from "../../../classes/AConfig.js";
import { AError } from "../../../classes/AError.js";
import { AEngine } from "../../../core/AEngine.js";
import { AErrorWithContext } from "../../../core/errors.js";
import { EVENTS } from "../../../services/AEventService.js";
import { _getEle$ } from "../../../utils/maps.js";
import { formatStringToPascal } from "../../../utils/tools.js";
export class AConfigComponent {
    get CfgPath() { return this.opt.cfgItem.CfgPath; }
    get CfgData() { return this.cfgData; }
    constructor(opt) {
        this.opt = opt;
        this.internalId = idAllocatorService.getNextId({ prefix: 'cfg-comp-' });
        this.cfgPath = opt.cfgItem.CfgPath;
    }
    bindValidator(form) {
        this.validator = form;
    }
    getFormInstance() {
        return this.validator;
    }
    isPendingChange() {
        return !this.validator.$form().closest('.cfg-group').hasClass('hidden');
    }
    validate(data, opt) {
        const $CfgValue = this.validator.$form().find('#CfgValue');
        const CfgPath = this.CfgPath;
        if (opt?.skipParse !== true) {
            const IsPrimitive = (configService.isPrimitive(CfgPath) === 1);
            const newValue = (IsPrimitive) ? `"${data}"` : JSON.stringify(data, null, 4); // 
            // const newValue = IsPrimitive ? (data + '') : JSON.stringify(data, null, 4)
            $CfgValue.val(newValue);
            AEngine.log('aci-change', { CfgPath, IsPrimitive, newValue });
        }
        else {
            AEngine.log('aci-change', { CfgPath, newValue: data });
            $CfgValue.val(data);
        }
        $CfgValue.trigger('change');
        this.validator.$form().closest('.cfg-group').removeClass('hidden');
        Events.tryInvoke(EVENTS.COMPONENT_PENDING_CHANGE_ADDED, [this, data, opt]);
    }
    getInternalId() { return this.internalId; }
    $ele() { return $(`[comp="${this.internalId}"]`); }
    getCfgItem() { return this.opt.cfgItem; }
    getTitle() {
        // return (this.opt.titleTranslated) ? this.opt.titleTranslated : (await Translate.get((this.opt.title) ? this.opt.title : this.cfgPath.split('.').pop()))
        return this.cfgPath.split('.').map(str => formatStringToPascal(str)).pop();
    }
    findConfigValueLegacy(cfgPath) {
        let json = Config.BackOfficeConfig || {};
        let value = json;
        try {
            for (let p of cfgPath.split('.')) {
                if (!value.hasOwnProperty(p)) { // Has Missing Part(s)
                    const fullkey = `${cfgPath}`;
                    value = AConfig.get(fullkey);
                    // cacheFlat[cfgPath] = null
                    break;
                }
                value = value[p];
            }
            return value;
        }
        catch (err) {
            AError.handleSilent(err);
            // $ele.closest('.form-group').addClass('has-error')
            // value = null
            // cacheFlat[cfgPath] = null
            return null;
        }
    }
    async preRender() { }
    async render() { return ''; }
    async postRender() { }
    async attachTo(parent) {
        const $p = _getEle$(parent);
        await this.preRender();
        const html = await this.render();
        $p.html('');
        if (html.length > 0) {
            const $comp = $(html);
            let qs = `[comp="${this.getInternalId()}"]`;
            if (!$comp.is(qs)) {
                throw new AErrorWithContext(`AConfigComponent.render() doesn't have the correct attribute, expected:`, qs);
            }
            $p.append($comp);
            await this.postRender();
        }
        else {
            throw new AErrorWithContext(`Invalid AConfigComponent for Primitive with CfgPath:`, this.cfgPath);
        }
        return this;
    }
}
