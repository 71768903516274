import { AError } from "../../classes/AError.js";
import { COLUMN_DATETIME, DATA_DATETIME } from "../../classes/AGridTypes.js";
import { ATemplates } from "../../core/ATemplateService.js";
import { AShowTable, AConvertToGridColumns, AConvertToGridData, ADeviceIdToName, ARound } from "../../utils/tools.js";
export class APage {
    constructor() {
        FilterManager.load();
        $('#RefreshButton').on('click', _ => FilterManager.showFilterWarning().then(_ => this.refresh()).catch(AError.handle));
    }
    async refresh() {
        const filters = FilterManager.saveExplicit();
        FilterManager.setActive(false);
        const response = await Loading.waitForPromises(requestService.query({
            Query: (`
          SELECT
            DeviceId,
            DeviceId AS DeviceName,
            RouteStart,
            RouteEnd,
            RouteLengthKm,
            OnRouteKm,
            OffRouteKm,
            OnRouteKm * 100 / RouteLengthKm As OnRoutePercentage
          FROM route_summary
          WHERE :FromDate < RouteEnd AND :ToDate > RouteStart
          ORDER BY RouteStart DESC
          LIMIT :Limit
        `),
            Params: Object.assign({ Limit: 10000 }, filters),
            Language: Language
        }));
        FilterManager.setActive(true);
        if (!response.Rows.length) {
            return Alerts.noResults();
        }
        this.grid = AShowTable({
            appendTo: 'table-bryntum',
            columns: AConvertToGridColumns(response, {
                'RouteStart': COLUMN_DATETIME,
                'RouteEnd': COLUMN_DATETIME
            }),
            data: AConvertToGridData(response, {
                'DeviceName': (value) => ADeviceIdToName(value),
                'RouteStart': DATA_DATETIME,
                'RouteEnd': DATA_DATETIME,
                'OnRoutePercentage': (value) => ARound(value, 1) + '%'
            })
        });
    }
}
export function render() {
    return ( /*html*/`
    <div id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column col-12">
        <div class="form-group">
          <label class="form-label" for="FromDate">From</label>
          <input class="form-input" type="date" id="FromDate" required="required">
          <input class="form-input" type="time" id="FromTime" required="required">
        </div>
        <div class="form-group">
          <label class="form-label" for="ToDate">To</label>
          <input class="form-input" type="date" id="ToDate" required="required">
          <input class="form-input" type="time" id="ToTime" required="required">
        </div>
      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </div>
    <div id="Rapport" class="flex-child bryntum-container has-footer-2">
      <div template="${ATemplates.WaitingForInput}"></div>
      <div id="table-bryntum" class="hidden"></div>

      <div class="columns footer aci">
        <div class="column col-2">
          <div id="count" class="text">Viewing <span>0</span> Rows</div>
        </div>
        <div class="column col-2 col-ml-auto">
          <button id="export" class="btn btn-primary col-12" disabled="disabled">Export</button>
        </div>
      </div>
    </div>
  `);
}
