import { _getEle$ } from "./maps.js";
export function initTableAccordions(parent) {
    const $parent = _getEle$(parent);
    $parent.find(`[ropen]`).toArray().map(ele => $(ele)).map($rkey => {
        const $table = $rkey.closest('table');
        if ($rkey.length && $table.length) {
            const isOpen = $rkey.is('[ropen="true"]');
            const rkey = $rkey.attr('rkey');
            const $children = $table.find(`[pkey="${rkey}"],[pkey^="${rkey}->"]`);
            $children.toggleClass('hidden', !isOpen);
            $rkey.find('.icon-arrow-down').toggleClass('rotated-180', isOpen);
        }
    });
}
export function listenToTableAccordionEvents() {
    $(document).on('click', (e) => {
        const $ele = $(e.target);
        const $clicked = $ele.is('.aci-blob') ? $ele : $ele.closest('.aci-blob');
        if ($clicked.length > 0) {
            const id = $clicked.attr('id');
            Events.tryInvoke(id);
        }
    });
    function setChildrenVisible($table, $parent) {
        const parentKey = $parent.attr('rkey');
        const $directChildren = $table.find(`[pkey="${parentKey}"]`).toArray().map(e => $(e));
        // Make all direct children visible
        for (let $child of $directChildren) {
            $child.removeClass('hidden');
        }
        const $collapsableChildren = $directChildren.filter($child => { return $child.is('[ropen="true"]'); });
        for (let $child of $collapsableChildren) {
            setChildrenVisible($table, $child);
        }
        $parent.find('.icon-arrow-down').toggleClass('rotated-180', true);
    }
    function setChildrenInvisible($table, $parent) {
        const parentKey = $parent.attr('rkey');
        const $allChildren = $table.find(`[pkey="${parentKey}"],[pkey^="${parentKey}->"]`).toArray().map(e => $(e));
        // Make Invisible
        for (let $child of $allChildren) {
            $child.addClass('hidden');
        }
        $parent.find('.icon-arrow-down').toggleClass('rotated-180', false);
    }
    $(document).on('click', (e) => {
        const $clicked = $(e.target);
        const $rkey = ($clicked.is('[rkey]') ? $clicked : $clicked.closest('[rkey]'));
        if ($rkey.length === 0) {
            return;
        }
        const wasVisible = $rkey.attr('ropen') === 'true';
        $rkey.attr('ropen', (wasVisible) ? 'false' : 'true');
        const $table = $rkey.closest('table');
        if (wasVisible) {
            setChildrenInvisible($table, $rkey);
        }
        else {
            setChildrenVisible($table, $rkey);
        }
    });
}
