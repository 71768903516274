import { _getEle$ } from "../../utils/maps.js";
export class ADropDownTree {
    constructor($ddw, opt) {
        this.useIndices = true;
        this.$ddw = $ddw;
        this.$title = $ddw.find('> span');
        this.$ddw.data('DropDown', this);
        this.useIndices = opt?.useIndices ?? true;
        this._attributeToUse = opt?.attributeToUse ?? undefined;
    }
    get attributeToUse() {
        return this._attributeToUse ?? (this.useIndices ? `unificationindex` : `unificationkey`);
    }
    set attributeToUse(val) {
        this._attributeToUse = val;
    }
    get $dd() {
        return this.$ddw.find('.dropdown');
    }
    get disallowNone() {
        return this.$ddw.hasClass('dd-disallow-none');
    }
    get disallowAll() {
        return this.$ddw.hasClass('dd-disallow-all');
    }
    validate() {
        // (this.disallowNone && this.disallowAll) ? true : !this.$ddw.hasClass('is-error')
        return !this.$ddw.hasClass('is-error');
    }
    async addSelectShortcuts() {
        const { $dd } = this;
        const shortcutT = await Translate.get(['Select All', 'Select None']);
        const $selectAll = $(`<li class="shortcut"><span class="lbl lbl-shortcut">${shortcutT['Select All']}</span></li>`);
        const $selectNone = $(`<li class="shortcut"><span class="lbl lbl-shortcut">${shortcutT['Select None']}</span></li>`);
        $selectAll.on('click', e => {
            e.preventDefault();
            this.selectAll();
        });
        $selectNone.on('click', e => {
            e.preventDefault();
            this.selectNone();
        });
        $dd.prepend($selectNone);
        $dd.prepend($selectAll);
    }
    isDisabled() {
        return this.$ddw.is('.disabled') || this.$ddw.closest('.disabled').length > 0;
    }
    initEvents(loadCache) {
        const { $ddw, $dd } = this;
        $ddw.on('click', e => {
            if ($(e.target).closest('.dropdown').length) {
                return;
            }
            // const isLoading = $ddw.closest('#Filters').hasClass('disabled')
            const isLoading = this.isDisabled();
            $ddw.prop('disabled', isLoading);
            if (!isLoading) {
                $ddw.toggleClass('active');
            }
            this.recalcTreeDropdownSize();
            e.stopPropagation();
        });
        $dd.find('input[type=checkbox]').each((_, check) => {
            const $check = $(check);
            $check.on('change', (e) => {
                const val = $check.prop('checked');
                $check.parent().find('input[type=checkbox]').each((_, cCheck) => {
                    $(cCheck).prop('checked', val);
                    $(cCheck).prop('indeterminate', false);
                    $(cCheck).toggleClass('is-indeterminate', false);
                });
                $check.parents('ul:not(.dropdown-tree)').each((_, pUl) => {
                    const $pUl = $(pUl);
                    const $pCheck = $pUl.parent().find('> input[type=checkbox]');
                    const allLength = $pUl.find('input[type=checkbox]').length;
                    const checkedLength = $pUl.find('input[type=checkbox]:checked').length;
                    $pCheck.prop('checked', checkedLength == allLength);
                    $pCheck.prop('indeterminate', (allLength !== (checkedLength || allLength)));
                    $pCheck.toggleClass('is-indeterminate', (allLength !== (checkedLength || allLength)));
                });
                this.updateState();
                this.recalcTreeDropdownSize();
            });
        });
        const accordions = $dd.find('.tree-item > .accordion');
        accordions.each((_, accordion) => {
            const $a = $(accordion);
            $a.on('click', e => {
                e.preventDefault();
                e.stopPropagation();
                $a.parent().toggleClass('collapsed');
                const $i = $a.closest('.accordion').find('i');
                $i.toggleClass('fa-minus');
                $i.toggleClass('fa-plus');
                this.recalcTreeDropdownSize();
            });
        });
        $(document).on('mouseup.dropdown', (e) => {
            const $clicked = $(e.target);
            const $found = $clicked.closest('.wrapper-dropdown.tree-config');
            if ($ddw.hasClass('active') && ($found.length === 0 || !$found.is($ddw))) {
                $ddw.removeClass('active');
            }
        });
        if (loadCache === true) {
            this.restoreState();
        }
        return { $ddw, $dd, context: this };
    }
    onShortcutClick(opt) {
        const $checkboxes = this.$ddw.find('.dropdown > li > input[type="checkbox"]').toArray().map(c => $(c));
        $checkboxes.map($c => {
            $c.prop('checked', opt.checked);
            $c.trigger('change');
        });
        this.updateState();
    }
    selectAll() {
        this.onShortcutClick({ checked: true });
    }
    selectNone() {
        this.onShortcutClick({ checked: false });
    }
    // loadTreeDropDownFilters({ $ddw, $dd, $title }) {
    restoreState(filterArrOrObj) {
        const { $ddw, $dd } = this;
        const id = $ddw.attr('id');
        const settings = (filterArrOrObj !== undefined) ? filterArrOrObj : FilterSettings[id];
        if (settings !== undefined) {
            if (settings !== '%') {
                $dd.find('input[type="checkbox"]').toArray().map(c => $(c)).map($c => $c.prop('checked', false));
                const { useIndices, attributeToUse } = this;
                $dd.find(`[${attributeToUse}]`).toArray().map(c => $(c)).map($c => {
                    const unificationKeyOrIndex = useIndices ? Number($c.attr(attributeToUse)) : $c.attr(attributeToUse);
                    if (settings.includes(unificationKeyOrIndex)) {
                        $c.prop('checked', true);
                        $c.trigger('change');
                    }
                });
                this.updateState();
            }
            else {
                this.selectAll();
            }
        }
        return this;
    }
    async translateUnification(v) {
        const promises = [Translate.get(v.KeyShort)];
        for (var k in v.Options) {
            if (v.Options.hasOwnProperty(k)) {
                promises.push(this.translateUnification(v.Options[k]));
            }
        }
        const [_title] = await Promise.all(promises);
        v._title = _title;
        return v;
    }
    updateState() {
        const { $ddw, $dd, $title } = this;
        const findSelectedGroupsRec = ($ul) => {
            return $ul.find('> li > input[type="checkbox"]:checked, > li > input[type="checkbox"].is-indeterminate')
                .toArray()
                .map(c => $(c))
                .map($c => ($c.is('.is-indeterminate')) ? findSelectedGroupsRec($c.parent().find('ul').first()) : $c.next().text().trim())
                .join(', ');
        };
        $ddw.removeClass('is-error');
        let title = '';
        if ($dd.find('input[type="checkbox"]:not(:checked)').length === 0) {
            title = Translate.getCacheFast('all');
            if (this.disallowAll && !this.isDisabled()) {
                $ddw.addClass('is-error');
            }
        }
        else if ($dd.find('input[type="checkbox"]:checked').length === 0) {
            title = Translate.getCacheFast('none');
            if (this.disallowNone && !this.isDisabled()) {
                $ddw.addClass('is-error');
            }
        }
        else {
            title = findSelectedGroupsRec($ddw.find('ul').first()) || '...';
        }
        $title.text(title);
    }
    recalcTreeDropdownSize() {
        const { $ddw, $dd } = this;
        if ($ddw.hasClass('active')) {
            const $body = $('body');
            let { left, top } = $ddw.offset();
            top += $ddw.outerHeight();
            const bodyHeight = $body.outerHeight();
            const bodyWidth = $body.width() ?? 0;
            const ddHeight = $dd.outerHeight();
            // @ts-ignore
            if (top + ddHeight > bodyHeight) {
                // @ts-ignore
                top = bodyHeight - ddHeight;
            }
            const right = bodyWidth - left - $ddw.outerWidth();
            $dd.css('position', 'fixed');
            $dd.css('right', right + 'px');
            $dd.css('left', 'auto');
            $dd.css('top', top + 'px');
            if ($dd.closest('#Filters').length) {
                $dd.css('min-width', Math.floor($ddw.outerWidth()) + 'px');
            }
        }
    }
    get selectedKeyTextArr() {
        const { $dd } = this;
        const unificationOptions = $dd.find('[unificationkey]').toArray();
        const output = unificationOptions.filter(c => c.checked).map(c => $(c).attr('unificationkey'));
        return output;
    }
    get selectedKeys() {
        const { $dd, attributeToUse } = this;
        const unificationOptions = $dd.find(`[${attributeToUse}]`).toArray();
        // const unificationIndexesUnchecked = unificationOptions.filter(c => !c.checked).map(c => Number($(c).attr(`unificationindex`)))
        const unificationIndexes = unificationOptions.filter(c => c.checked).map(c => this.useIndices ? Number($(c).attr(attributeToUse)) : $(c).attr(attributeToUse));
        return unificationIndexes;
    }
    get notSelectedKeys() {
        const { $dd, attributeToUse } = this;
        const unificationOptions = $dd.find(`[${attributeToUse}]`).toArray();
        const unificationIndexesUnchecked = unificationOptions.filter(c => !c.checked).map(c => this.useIndices ? Number($(c).attr(attributeToUse)) : $(c).attr(attributeToUse));
        return unificationIndexesUnchecked;
    }
    get selectedTextsQuery() {
        const { selectedKeys, notSelectedKeys } = this;
        if (notSelectedKeys.length === 0) {
            return '%';
        }
        return selectedKeys;
    }
    static async generateTreeDropdown(ele, unification, opt) {
        let i = Date.now();
        const $ele = _getEle$(ele);
        const getUid = () => $ele.attr('id').toString() + '-' + (i++).toString(16);
        const rec = ({ Options }) => {
            let html = [];
            Object.keys(Options).map(k => {
                const subOption = Options[k];
                const uid = getUid();
                if (Object.keys(subOption.Options || {}).length) {
                    html.push(`
            <li class="tree-item collapsed">
              <div class="accordion"><i class="fa-solid fa-plus"></i></div>
              <input id="${uid}" type="checkbox" checked="checked">
              <label for="${uid}">${subOption['_title']}</label>
              <ul>${rec(subOption)}</ul>
            </li>
          `);
                }
                else {
                    if (subOption.FirstIndex !== subOption.LastIndex) {
                        console.log(subOption);
                        throw new Error(`Expected Unification FirstIndex & LastIndex to be identical because it this subOption doesn't have children!`);
                    }
                    html.push(`
            <li>
              <input id="${uid}" unificationindex="${subOption.FirstIndex}" unificationkey="${subOption.Key}" type="checkbox" checked="checked">
              <label for="${uid}">${subOption['_title']}</label>
            </li>
          `);
                }
            });
            return html.join('').replace(/\s+/g, ' ');
        };
        const $filter = $ele;
        $filter.find('.dropdown').remove();
        const $ddw = ($filter.is('.wrapper-dropdown.tree-config')) ? $filter : $filter.find('.wrapper-dropdown.tree-config');
        const $dd = $('<ul class="dropdown dropdown-tree c-scroll"></ul>');
        const dd = $ddw.data('DropDown');
        $filter.append($dd);
        await Loading.waitForPromises(dd.translateUnification(unification));
        $dd.append(rec(unification));
        await Loading.waitForPromises(dd.addSelectShortcuts());
        return dd.initEvents(true);
    }
}
