import { AError } from "../../classes/AError.js";
import { ATemplates } from "../../core/ATemplateService.js";
import { AConvertToGridColumns, AConvertToGridData, appendResponseRow, AShowTable } from "../../utils/tools.js";
export class APage {
    constructor() {
        FilterManager.load();
        $('#RefreshButton').on('click', _ => FilterManager.showFilterWarning().then(_ => this.refresh().catch(AError.handle)));
    }
    async showRecordInfo({ record }) {
        Alerts.notImplementedYet();
    }
    async fetchData(conditions) {
        return {
            Columns: ['Invoice Amount', 'PaymentTime', 'LicensePlate', 'User'],
            ColumnsTranslated: ['Invoice Amount', 'PaymentTime', 'LicensePlate', 'User'],
            Rows: [
                ['10.00', '2021-11-19 14:40:00', '54-RD-TX', 'Ivan'],
                ['10.00', '2021-11-19 15:40:00', '54-RD-TX', 'Ivan']
            ]
        };
    }
    async refresh() {
        const filters = FilterManager.saveExplicit();
        FilterManager.setActive(false);
        const conditions = [];
        if (filters.ParkingRightType)
            conditions.push('ParkingRightType = :ParkingRightType');
        // TODO: ORDER BY Time DESC (latest first)
        const response = await Loading.waitForPromises(this.fetchData(conditions));
        FilterManager.setActive(true);
        if (!response.Rows.length) {
            return Alerts.noResults();
        }
        appendResponseRow(response, 'Action1');
        const moreInfo = await Translate.get('More Info');
        this.grid = AShowTable({
            appendTo: 'table-bryntum',
            columns: AConvertToGridColumns(response, {
                'Action1': {
                    type: 'widget',
                    field: 'Action1',
                    width: 300,
                    cellCls: 'np-i',
                    widgets: [{
                            type: 'button',
                            cls: 'b-blue b-raised',
                            onAction: ({ source }) => {
                                const { cellInfo } = source;
                                if (!cellInfo || !cellInfo.record)
                                    return;
                                const { record } = cellInfo;
                                this.showRecordInfo({ record }).catch(AError.handle);
                            }
                        }]
                }
            }),
            data: AConvertToGridData(response, {
                'Action1': (value) => moreInfo,
            })
        });
    }
}
export function render() {
    return ( /*html*/`
    <div id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column col-12">

        <div class="form-group">
          <label class="form-label" for="LicensePlate">License Plate</label>
          <input class="form-input" type="text" id="LicensePlate" maxlength="32" LicensePlate />
        </div>

      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </div>
    <div id="Rapport" class="flex-child bryntum-container has-footer-2">
      <div template="${ATemplates.WaitingForInput}"></div>
      <div id="table-bryntum" class="hidden"></div>

      <div class="columns footer aci">
        <div class="column text-center">
          <div id="count" class="text">Total Invoices: <span>90,- bhir</span></div>
        </div>
        <div class="column text-center">
          <div id="" class="text">Payments: <span>90</span></div>
        </div>
        <div class="column text-center">
          <div id="" class="text">Outstanding Amount: <span>10</span></div>
        </div>
        <div class="column col-2 col-ml-auto">
          <button id="export" class="btn btn-primary col-12" disabled="disabled">Export</button>
        </div>
      </div>
    </div>
  `);
}
