export class ASearchHandler {
    constructor(grid) {
        this.prevSearch = '';
        this._grid = grid;
    }
    get grid() {
        return this._grid ?? PageScript.grid;
    }
    get searchValue() {
        const $inp = $(this.grid.element).find(`[data-ref="${this.ref}"] input`);
        return $inp.val() ?? '';
    }
    bind(opt) {
        this.ref = opt.ref;
        return this;
    }
    applyFilter(e) {
        // AEngine.log('ASearchHandler.applyFilter', e)
        const searchData = this.grid.features['search'];
        let { found, find } = searchData;
        if (this.searchValue === '') {
            this.onSearchFieldClear();
        }
        if (this.prevSearch === find || find === undefined) {
            // AEngine.log('ASearchHandler.applyFilter canceled!', {found, find, searchValue: this.searchValue})
            // if (find.length === 0) {
            //   this.onSearchFieldClear()
            // }
            return;
        }
        const ids = found.map(row => row.data.id);
        this.prevSearch = find;
        // const searchColumns = columns.visibleColumns.filter(c => c['searchable'] === true).map(c => c['field'])
        this.grid.store.filter({
            id: 'searchFilter',
            filterBy: (record) => ids.includes(record['id']),
        });
    }
    onSearchFieldChange(e) {
        if (e.value === '') {
            this.onSearchFieldClear();
            return;
        }
        this.grid.store.removeFilter('searchFilter');
        const nonHtmlEncodeFields = [...this.grid.columns.records].map(c => c.data).filter(c => c.htmlEncode !== false).map(c => c.field);
        this.grid.features.search.search(e.value, false, false, nonHtmlEncodeFields);
        // AEngine.log("ASearchHandler.onSearchFieldChange", {value: e.value, searchValue: this.searchValue})
    }
    onSearchFieldClear(e) {
        // AEngine.log("ASearchHandler.onSearchFieldClear", e)
        this.resetFilter();
        // Prevent infinite loop
        if (e?.type !== 'clearsearch') {
            this.grid.features.search.clear();
        }
        if (this.grid.selectedRecords.length > 0) {
            this.grid.scrollRowIntoView(this.grid.selectedRecord);
        }
    }
    resetFilter() {
        this.prevSearch = '';
        this.grid.store.removeFilter('searchFilter');
    }
}
