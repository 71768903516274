import { AConfig } from "../classes/AConfig.js";
import { AError } from "../classes/AError.js";
import { AImageHelper } from "../classes/AImageHelper.js";
import { APhotoFetcher } from "../classes/APhotoFetcher.js";
import { AResponse } from "../classes/AResponse.js";
import { AEngine } from "../core/AEngine.js";
import { MAP_OPTIONS } from "../core/maps/AMapStructs.js";
import { AIsLatLngValid, createMap, getCenterAny, ShowMapScans } from "../utils/maps.js";
import { DetectionsFullSingle } from "../utils/query.js";
import { AFormatDate, initAccordions } from "../utils/tools.js";
import { ALERTS, ALERT_TITLES } from "./AAlertService.js";
export class ADetectionService {
    pack(res, index, excluded) {
        return purgatoryService.responseToTable(res, {
            // className: 'table table-striped table-hover',
            excluded,
            index
        });
    }
    async modalShowOnMap({ DetectionId, DetectionDeviceId, DetectionTime }) {
        const height = Math.round(window.innerHeight / 3 * 2);
        const events = Alerts.show({
            title: ALERT_TITLES.None,
            content: ( /*html*/`
      <div class="columns col-gapless">
        <div class="column col-4 sideview-split" id="modal-info-window-container" style="height: ${height}px"></div>
        <div class="column col-8">
          <div id="modal-map" class="aci-map fw" style="height: ${height}px"></div>
        </div>
      </div>
      `),
            type: ALERTS.Large
        });
        const $map = events.$ele.find('#modal-map');
        const $splitView = events.$ele.find('#modal-info-window-container');
        const newHeight = events.$ele.find('.modal-body').height() || '300';
        $map.height(newHeight + 'px');
        $splitView.height(newHeight + 'px');
        // const mapCfg = configService.get('general.map')!
        const map = createMap($map, {
            // position: {lat: mapCfg.lat, lng: mapCfg.lng},
            position: AConfig.get('general.map.pos', { lat: 0, lng: 0 }),
            center: AConfig.get('general.map.pos', { lat: 0, lng: 0 }),
            streetViewControl: true,
            zoom: 21,
        });
        await mapHelperService.prepareMapItems(MAP_OPTIONS.Default, {
            map,
            showLegend: true,
            allowExport: false,
            click: () => { console.warn('Surpressed Input!'); }
        });
        return Loading.waitForPromises(requestService.fetch({
            AssertValues: true,
            Name: "MapSearch",
            Query: (`
          SELECT *
          FROM (${DetectionsFullSingle}) full
          ORDER BY DetectionId
          LIMIT :Limit
        `),
            Params: {
                DetectionId,
                DetectionDeviceId,
                Limit: 10
            },
            Language: Language,
            Translate: ["VehicleType", "ParkingAreaType", "ParkingRightType", "IsIllegallyParked"]
        }, { cacheQuery: true })).then((response) => {
            if (response.Rows.length === 0) {
                return Alerts.noResults(); //.on(ALERT_STATUS.ON_MODAL_CLOSED, () => {})
            }
            const bounds = new google.maps.LatLngBounds();
            const markers = ShowMapScans({
                infoParent: '#modal-info-window-container',
                response,
                bounds,
                map,
            });
            map.fitBounds(bounds);
            this.selectMarkerAndDisplayPda(map, response, markers, { stretchOut: true });
        }).catch(AError.handle);
    }
    async selectMarkerAndDisplayPda(map, response, markers, opt) {
        const hasMarkers = response.Rows.length > 0;
        const shouldDisplayPda = response.Rows.length === 1;
        if (!hasMarkers || !shouldDisplayPda) {
            return;
        }
        const data = new AResponse(await purgatoryService.onMarkerClickRealtime(markers[0], opt));
        const detection = data.First;
        const { VerificationLatitude, VerificationLongitude } = detection;
        AEngine.log('selectMarkerAndDisplayPda', { shouldDisplayPda, detection, VerificationLatitude, VerificationLongitude });
        if (AIsLatLngValid([VerificationLatitude, VerificationLongitude])) {
            this.drawLine(map, getCenterAny(markers[0]), { lat: VerificationLatitude, lng: VerificationLongitude });
        }
    }
    drawLine(map, from, to) {
        // Create the polyline, passing the symbol in the 'icons' property.
        // Give the line an opacity of 0.
        // Repeat the symbol at intervals of 20 pixels to create the dashed effect.
        const dottedLineToPda = new google.maps.Polyline({
            path: [from, to],
            strokeOpacity: 0,
            icons: [{
                    icon: {
                        path: "M 0,-1 0,1",
                        strokeOpacity: 1,
                        scale: 4,
                    },
                    offset: "0",
                    repeat: "20px",
                }],
            map: map,
        });
        return dottedLineToPda;
    }
    async findHistory(Params) {
        const WHERE = [
            'DetectionId=:DetectionId',
            'DetectionDeviceId=:DetectionDeviceId'
        ].join(' AND ');
        const TranslateColumns = [
            "DetectionState",
            "ParkingRight",
            "IllegallyParked",
            "Verification",
            "TimeLimitedParking",
            "Digital",
            "VerificationChannel"
        ];
        const [detections, detections_geo, detections_geo_mapping, parkingrights, verifications, corrections, overviewimages, history] = await Loading.waitForPromises(requestService.queries([
            { Query: `SELECT UNIX_TIMESTAMP(DetectionTime) AS _DATE, a.* FROM detections a WHERE ${WHERE}`, Params, Language, Translate: TranslateColumns },
            { Query: `SELECT UNIX_TIMESTAMP(GeoCheckTime) AS _DATE, a.* FROM detections_geo a WHERE ${WHERE}`, Params, Language, Translate: TranslateColumns },
            { Query: `SELECT GeoType, Confidence, Name, Attributes, GeoId, GeoVersion, Active,TimeStamp FROM detection_geo_object LEFT JOIN geo_objects_history USING(GeoType,GeoId, GeoVersion) WHERE ${WHERE} ORDER BY GeoType+0 ASC, Confidence DESC`, Params, Language, Translate: TranslateColumns },
            { Query: `SELECT UNIX_TIMESTAMP(ParkingRightCheckTime) AS _DATE, a.* FROM parkingrights a WHERE ${WHERE}`, Params, Language, Translate: TranslateColumns },
            { Query: `SELECT UNIX_TIMESTAMP(VerificationEndTime) as _DATE, a.* FROM verifications a WHERE ${WHERE}`, Params, Language, Translate: TranslateColumns },
            { Query: `SELECT UNIX_TIMESTAMP(CorrectionTime) AS _DATE, a.* FROM corrections a WHERE ${WHERE}`, Params, Language, Translate: TranslateColumns },
            { Query: `SELECT UNIX_TIMESTAMP(ReceiveTime) AS _DATE FROM overviewimages a WHERE ${WHERE}`, Params, Language, Translate: TranslateColumns },
            { Query: `SELECT UNIX_TIMESTAMP(DetectionFinalTime) AS _DATE, DetectionState, ParkingRight, IllegallyParked, Verification, Digital, IsSuspect, MustFollowUp, VerificationChannel, FinalVerificationUser, FinalVerificationDistanceToVehicle, FinalVerificationDevice, DetectionExpireTime FROM detections_final_history a WHERE ${WHERE}`, Params, Language, Translate: TranslateColumns },
        ]));
        const translations = await Loading.waitForPromises(Translate.get([
            'Overview Images', 'Detection', 'Geography', 'ParkingRight', 'Verification', 'Correction'
        ]));
        const htmls = [];
        // ========================================= OVERVIEW IMAGES ========================================= //
        if (overviewimages.Rows.length) {
            const iDate = overviewimages.Columns.indexOf('_DATE');
            const linuxtime = overviewimages.Rows[0][iDate];
            const d = new Date(linuxtime * 1000);
            htmls.push([linuxtime, ( /*html*/`
        <div class="columns accordion-wrapper">
          <div class="column col-12">
            <a href="#" class="h6 load-images-async">
              <i class="icon icon-arrow-right mr-1"></i>
              ${AFormatDate(d)} &#124; ${translations['Overview Images']}
            </a>
          </div>
          <div class="column col-12 hidable" style="display: none; padding-left: 16px; padding-top: 8px;">
            <div class="overview-image-container" style="height: 200px;"></div>
          </div>
        </div>
      `)]);
        }
        // ========================================= DETECTIONS ========================================= //
        if (detections.Rows.length) {
            const iDate = detections.Columns.indexOf('_DATE');
            const linuxtime = detections.Rows[0][iDate];
            const d = new Date(linuxtime * 1000);
            htmls.push([linuxtime, /*html*/ `
        <div class="columns accordion-wrapper">
          <div class="column col-12">
            <a href="#" class="h6">
              <i class="icon icon-arrow-right mr-1"></i>
              ${AFormatDate(d)} &#124; ${translations['Detection']}
            </a>
          </div>
          <div class="column col-12 hidable" style="display: none; padding-left: 16px; padding-top: 8px;">
            ${this.pack(detections, 0, ['_DATE'])}
          </div>
        </div>
      `]);
        }
        let geoMapingHtml = `Geo Mapping`;
        detections_geo_mapping.Rows.forEach((row, index) => {
            const iGeoType = detections_geo_mapping.Columns.indexOf('GeoType');
            const geoType = detections_geo_mapping.Rows[index][iGeoType];
            const decimals = 10000;
            const iConfidence = detections_geo_mapping.Columns.indexOf('Confidence');
            const confidence = Math.round(detections_geo_mapping.Rows[index][iConfidence] * decimals) / decimals;
            const confidenceString = confidence > 0 ? `${confidence * 100}% Overlap` : `${-confidence} Meters Distance`;
            geoMapingHtml += ( /*html*/`
        <div class="column col-12">
          <b>${geoType}</b> (${confidenceString}) <br>
          ${this.pack(detections_geo_mapping, index, ['GeoType', 'Confidence'])}
          <br>
        </div>
      `);
        });
        if (detections_geo.Rows.length) {
            const iDate = detections_geo.Columns.indexOf('_DATE');
            const linuxtime = detections_geo.Rows[0][iDate];
            const d = new Date(linuxtime * 1000);
            htmls.push([linuxtime, /*html*/ `
        <div class="columns accordion-wrapper">
          <div class="column col-12">
            <a href="#" class="h6">
              <i class="icon icon-arrow-right mr-1"></i>
              ${AFormatDate(d)} &#124; ${translations['Geography']}
            </a>
          </div>
          <div class="column col-12 hidable" style="display: none; padding-left: 16px; padding-top: 8px;">
            ${this.pack(detections_geo, 0, ['_DATE', 'DetectionId', 'DetectionDeviceId'])}
            ${geoMapingHtml}
          </div>
        </div>
      `]);
        }
        parkingrights.Rows.forEach((row, index) => {
            const iDate = parkingrights.Columns.indexOf('_DATE');
            const linuxtime = row[iDate];
            const d = new Date(linuxtime * 1000);
            htmls.push([linuxtime, /*html*/ `
        <div class="columns accordion-wrapper">
          <div class="column col-12">
            <a href="#" class="h6">
              <i class="icon icon-arrow-right mr-1"></i>
              ${AFormatDate(d)} &#124; ${translations['ParkingRight']}
            </a>
          </div>
          <div class="column col-12 hidable" style="display: none; padding-left: 16px; padding-top: 8px;">
            ${this.pack(parkingrights, index, ['_DATE', 'DetectionId', 'DetectionDeviceId', 'DetectionTime'])}
          </div>
        </div>
      `]);
        });
        verifications.Rows.forEach((row, index) => {
            const iDate = verifications.Columns.indexOf('_DATE');
            const linuxtime = row[iDate];
            const d = new Date(linuxtime * 1000);
            htmls.push([linuxtime, /*html*/ `
        <div class="columns accordion-wrapper">
          <div class="column col-12">
            <a href="#" class="h6">
              <i class="icon icon-arrow-right mr-1"></i>
              ${AFormatDate(d)} &#124; ${translations['Verification']}
            </a>
          </div>
          <div class="column col-12 hidable" style="display: none; padding-left: 16px; padding-top: 8px;">
            ${this.pack(verifications, index, ['_DATE', 'DetectionId', 'DetectionDeviceId'])}
          </div>
        </div>
      `]);
        });
        corrections.Rows.forEach((row, index) => {
            const iDate = corrections.Columns.indexOf('_DATE');
            const linuxtime = row[iDate];
            const d = new Date(linuxtime * 1000);
            htmls.push([linuxtime, /*html*/ `
        <div class="columns accordion-wrapper">
          <div class="column col-12">
            <a href="#" class="h6">
              <i class="icon icon-arrow-right mr-1"></i>
              ${AFormatDate(d)} &#124; ${translations['Correction']}
            </a>
          </div>
          <div class="column col-12 hidable" style="display: none; padding-left: 16px; padding-top: 8px;">
            ${this.pack(corrections, index, ['_DATE', 'DetectionId', 'DetectionDeviceId'])}
          </div>
        </div>
      `]);
        });
        history.Rows.forEach((row, index) => {
            const iDate = history.Columns.indexOf('_DATE');
            const iDetectionState = history.Columns.indexOf('DetectionState');
            const linuxtime = row[iDate] - 0.000001; /// When the same this one goes on top
            const DetectionState = row[iDetectionState];
            const d = new Date(linuxtime * 1000);
            htmls.push([linuxtime, /*html*/ `
        <div class="columns accordion-wrapper">
          <div class="column col-12">
            <a href="#" class="h6">
              <i class="icon icon-arrow-right mr-1"></i>
              <span class="Grey">${AFormatDate(d)} &#124; ${DetectionState}</span>
            </a>
          </div>
          <div class="column col-12 hidable" style="display: none; padding-left: 16px; padding-top: 8px;">
            ${this.pack(history, index, ['_DATE', 'DetectionId', 'DetectionDeviceId'])}
          </div>
        </div>
      `]);
        });
        const html = htmls.sort((a, b) => {
            // @ts-ignore
            return a[0] - b[0];
        }).map((arr) => arr[arr.length - 1]).join('<br>');
        const events = Alerts.show({
            title: ALERT_TITLES.History,
            type: ALERTS.Medium,
            content: html,
        });
        const { $ele } = events;
        $ele.find('.load-images-async').one('click', async (e) => {
            e.preventDefault();
            const imageHelper = new AImageHelper({
                // $rh: $ele.find('.overview-image-container'),
                $photos: $ele.find('.overview-image-container')
            }, true);
            const imageOptions = {
                allowFilter: false,
                allowFullscreen: true,
                allowZoom: false,
                defaultSize: {
                    height: imageHelper.$photos.height()
                }
            };
            const photos = await Loading.waitForPromises(new APhotoFetcher().fetchPhotos('all', Params));
            imageHelper.addImageSet(photos.map(p => p.src), imageOptions);
            imageHelper.$photos.css('height', 'auto');
        });
        initAccordions($ele);
        return events;
    }
    toUniqueId(inp) {
        if ('DetectionId' in inp && 'DetectionDeviceId' in inp) {
            if ('TrafficSign' in inp) {
                return (`${inp.DetectionDeviceId}_${inp.DetectionId}_${inp.TrafficSign}`);
            }
            else {
                return (`${inp.DetectionDeviceId}_${inp.DetectionId}`);
            }
        }
        return undefined;
    }
    setMarkerIdentifier(marker, ddid, did, extra) {
        // const DETECTIONDEVICEID_LENGTH = 15 bullshit
        // const DETECTIONDEID_LENGTH = 17 bulshit
        if (!ddid || !ddid.length) {
            return AError.handle(`DetectionDeviceId is not correct: ${ddid} }`);
        }
        if (!did || !did.length) {
            return AError.handle(`DetectionId is not correct: ${did}`);
        }
        marker.set('PK', { DetectionDeviceId: ddid, DetectionId: did });
        marker.set('Id', `${ddid}_${did}`);
        marker.set('DetectionId', did);
        marker.set('DetectionDeviceId', ddid);
    }
    getMarkerIdentifier(marker) {
        const obj1 = marker.get('PK');
        if (obj1) {
            return obj1;
        }
        const obj2 = marker.get('DetectionId');
        const obj3 = marker.get('DetectionDeviceId');
        if (obj2 && obj3) {
            return {
                DetectionId: obj2,
                DetectionDeviceId: obj3
            };
        }
        const identifier = marker.get('Id');
        if (identifier) {
            const [DetectionDeviceId, DetectionId] = identifier.split('_');
            return { DetectionId, DetectionDeviceId };
        }
        throw new Error(`No Marker Id Found!`);
    }
    setMarkerFinal(marker, data) {
        const dataToApply = {
            HasParkingRight: data.HasParkingRight,
            IsIllegallyParked: data.IsIllegallyParked,
            ParkingRight: data.keyParkingRight,
            Verification: data.keyVerification,
            DetectionState: data.keyDetectionState,
            Digital: data.keyDigital,
            IllegallyParked: data.keyIllegallyParked,
        };
        if (marker.set !== undefined) {
            marker.set('_final', dataToApply);
        }
        else {
            marker['_final'] = dataToApply;
        }
    }
    getMarkerFinal(marker) {
        return marker.get('_final') ?? marker['_final'];
    }
}
