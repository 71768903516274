import { AError } from '../classes/AError.js';
import { ALoopTimer } from '../classes/ALoopTimer.js';
import { AEngine } from '../core/AEngine.js';
import { secondsToHHMMSS } from '../utils/tools.js';
import { EVENTS } from './AEventService.js';
import { APrefs } from './APreferenceService.js';
export class AUserActionService {
    constructor() {
        this.history = [];
        this.sessionStart = -1;
    }
    get sessionDuration() {
        return (performance.now() - this.sessionStart) / 1000.0;
    }
    autoInit() {
        Events.h_once(EVENTS.PREFETCH, () => {
            this.sessionStart = performance.now();
        });
        Events.hardwire(EVENTS.CONSTRUCT, () => {
            const { url, meta } = routeService;
            this.addToHistory({
                timestamp: Date.now(),
                category: 'USER',
                action: 'NAV',
                desc: {
                    // title: meta.menuItem?.title ?? '',
                    hash: url.hash
                }
            });
        });
        let hist = preferenceService.get(APrefs.USER_ACTIONS) || [];
        this.history = (Array.isArray(hist)) ? (hist || []) : [];
        let prevSize = this.history.length;
        new ALoopTimer(() => {
            if (prevSize === this.history.length) {
                return;
            }
            prevSize = this.history.length;
            preferenceService.set(APrefs.USER_ACTIONS, this.history);
        }, { loopLifeCycle: 'CONTINUOUS', timeout: 5000 }).start();
        this.bindPageUnload();
    }
    bindPageUnload() {
        window.addEventListener('beforeunload', async (e) => {
            AEngine.log(`LOG ACTION > SESSION SAVE`);
            preferenceService.set(APrefs.USER_ACTIONS, this.history);
            await this.logAction('USER', 'SESSION', `Session Ended: ${secondsToHHMMSS(this.sessionDuration)}`).catch(AError.handle);
            preferenceService.set(APrefs.USER_ACTIONS, this.history);
            AEngine.log(`LOG ACTION > SESSION SAVED`);
            return true;
        });
    }
    getHistory(opt) {
        const sortFn = (opt.sort === 'ASC') ? ((a, b) => a.timestamp - b.timestamp) : ((a, b) => b.timestamp - a.timestamp);
        this.history.sort(sortFn);
        return this.history.slice(-opt.limit);
    }
    addToHistory(item) {
        if ((this.history?.length ?? 0) > 0) {
            const lastInsert = this.history[this.history.length - 1] ?? {};
            const { action, category, desc } = lastInsert;
            if (action === item.action && category === item.category && desc === item.desc) {
                return;
            }
        }
        return this.history.push(item);
    }
    async logAction(Category, Action, Inputs) {
        if (typeof Inputs === 'object' && Inputs !== null) {
            Inputs = Object.keys(Inputs).map(key => `${key}=${JSON.stringify(Inputs[key])}`).join(', ');
        }
        if (Action && Action.length > 64) {
            Action = Action.substring(0, 64);
            console.warn('CUT OFF USER ACTION WITH LENGTH > 64');
        }
        if (Inputs && Inputs.length > 1024) {
            Inputs = Inputs.substring(0, 1024);
            console.warn('CUT OFF DESCRIPTION WITH LENGTH > 1024');
        }
        this.addToHistory({ timestamp: Date.now(), category: Category, action: Action, desc: Inputs });
        try {
            return (stateService.User) ? await Loading.waitForPromises(requestService.query({
                Query: (`INSERT INTO user_actions (User, Category, Action, Target, Description) VALUES (:User, :Category, :Action, :Target, :Inputs)`),
                Params: {
                    User: stateService.User,
                    Category,
                    Action,
                    Target: document.location.hash.substr(2),
                    Inputs
                }
            })) : Promise.resolve();
        }
        catch (err) {
            console.error(err);
        }
    }
}
