import { ADetectionStatistics, CHART_TYPE } from "../../classes/ADetectionStatistics.js";
import { AError } from "../../classes/AError.js";
import { AStatisticsChart } from "../../charts/AStatisticsChart.js";
import { AEngine } from "../../core/AEngine.js";
import { AStatisticsService } from "../../services/AStatisticsService.js";
import { addTabListenersFind } from "../../utils/tools.js";
export class APage {
    // private segmentToGenericId: {[segmentId: string]: {key: string, overlap: number}}
    // private transformTitleMap: {[key: string]: string}|null
    // private segmentToAreaName: any
    // private areaIdToAreaName: any
    constructor() {
        this.viewEvents = [];
        $('#RefreshButton').on('click', _ => FilterManager.showFilterWarning().then(_ => Loading.waitForPromises(this.refresh())));
    }
    async init() {
        FilterManager.load();
        Loading.waitForPromises(this.refresh());
    }
    get chartTypes() {
        return [
            // CHART_TYPE.DIGITAL,
            CHART_TYPE.ILLEGALY_PARKED,
            CHART_TYPE.OCCUPANCY,
            CHART_TYPE.PARKING_RIGHT,
            CHART_TYPE.TIME_LIMITED_PARKING,
            CHART_TYPE.VERIFICATION,
            CHART_TYPE.DETECTION_STATE
        ];
    }
    prepareTabs(opt) {
        const { $container, statistics, filters } = opt;
        let $tabs = $();
        $container.html('');
        const tabs = {};
        if (!filters.hasOwnProperty('GroupBy') && statistics instanceof ADetectionStatistics) {
            $tabs = this.createTabs();
        }
        else {
            Object.keys(statistics).map(key => { tabs[key] = { id: -1, key, name: key }; });
            $tabs = this.createTabs(tabs, filters);
        }
        $container.append($tabs);
        addTabListenersFind($container);
        return {
            tabs,
            length: $tabs.find('.piecharts').length
        };
    }
    isGroupByAreaEnabled(filters) {
        return (filters.GroupBy === 'Indicator');
    }
    createTabs(tabs, filters) {
        if (tabs == null) {
            return $(`
        <div class="columns">
          <div class="column col-12">
            <div class="piecharts"></div>
          </div>
        </div>
      `);
        }
        const htmlTabs = [];
        const htmlTabviews = [];
        let tabHierarchy = [];
        if (this.isGroupByAreaEnabled(filters)) {
            tabHierarchy = this.chartTypes.map((chartType, i) => {
                const id = i + '-' + idAllocatorService.getNextId();
                // TODO: translate tabTitle
                const tabTitle = chartType;
                const keyAttr = chartType;
                return { id, tabTitle, keyAttr };
            });
        }
        else {
            tabHierarchy = Object.keys(tabs).map((key, i) => {
                const id = i + '-' + idAllocatorService.getNextId();
                tabs[key].id = id;
                const tabTitle = tabs[key].name;
                const keyAttr = key;
                return { id, tabTitle, keyAttr };
            });
        }
        tabHierarchy.map(({ id, tabTitle, keyAttr }, i) => {
            htmlTabs.push(`
        <button class="aci-tab${i === 0 ? ' active' : ''}" tab="tab-${id}">
          <span>${tabTitle}</span>
        </button>
      `);
            htmlTabviews.push(`
        <div tabgroup="charts" tabview="tab-${id}">
          <div key="${keyAttr}" class="piecharts"></div>
        </div>
      `);
        });
        const html = (`
      <div class="aci-tabs tabs-flex tabs-sticky" tabgroup="charts">
        ${htmlTabs.join('')}
      </div>
      <div class="columns">
        <div class="column col-12">
            ${htmlTabviews.join('')}
        </div>
      </div>
    `);
        return $(html);
    }
    fetchMapping(opt) {
        const { filters } = opt;
        if (!filters.hasOwnProperty('GroupBy')) {
            return undefined;
        }
        switch (filters.GroupBy) {
            case 'Zone':
                return this.fetchZoneMapping({ filters });
            case 'Area':
            case 'Indicator':
                return this.fetchAreaMapping({ filters });
        }
        throw new Error(`Group "${filters.GroupBy}" Not Implemented Yet!`);
    }
    async fetchRouteAreaMapping({ filters }) {
        // TODO: Implement mapping
    }
    async fetchAreaMapping({ filters }) {
        const geoMap = await AEngine.get(AStatisticsService).fetchGeoConnectionNames({ FromGeoType: 'Segment', ToGeoType: 'Area' });
        const opt = filters.hasOwnProperty('GroupBy') ? { mapTo: (segmentId) => geoMap.map[segmentId] ?? null } : undefined;
        return opt;
    }
    async fetchZoneMapping({ filters }) {
        const geoMap = await AEngine.get(AStatisticsService).fetchGeoConnectionNames({ FromGeoType: 'Segment', ToGeoType: 'Zone' });
        const opt = filters.hasOwnProperty('GroupBy') ? { mapTo: (segmentId) => geoMap.map[segmentId] ?? null } : undefined;
        return opt;
    }
    async createCharts(opt) {
        const { $container, statistics, filters, tabs } = opt;
        const charts = this.chartTypes;
        const chartContainers = $container.find('.piecharts').toArray();
        const output = chartContainers.map((chartContainer, containerI) => {
            const $chartContainer = $(chartContainer);
            const $view = $chartContainer.closest('[tabgroup][tabview]');
            const tabgroup = $view.attr('tabgroup');
            // const tabview = $view.attr('tabview')
            const key = $chartContainer.attr('key');
            const hasTabs = (Object.keys(tabs).length > 0);
            let chartOptions = null;
            if (this.isGroupByAreaEnabled(filters)) {
                let chartType = key;
                chartOptions = Object.keys(statistics).map(statsEntryKey => {
                    const id = `chart-${statsEntryKey}-${chartType}`;
                    const $ele = $(`<div id="chart-container-${containerI}-${id}" style="display: inline-block; vertical-align: top; width: 33.33%; height: 300px"></div>`);
                    $chartContainer.append($ele);
                    return {
                        id,
                        title: hasTabs && tabs.hasOwnProperty(statsEntryKey) ? tabs[statsEntryKey].name : statsEntryKey,
                        statistics: hasTabs ? statistics[statsEntryKey] : statistics,
                        chartType: chartType,
                        wrap: true,
                        $wrapParent: $ele,
                        roundValues: true,
                        __key: chartType
                    };
                });
            }
            else if (key !== undefined && hasTabs) {
                chartOptions = charts.map(chartType => {
                    const id = `chart-${chartType}-${key}`;
                    const $ele = $(`<div id="chart-container-${containerI}-${id}" style="display: inline-block; vertical-align: top; width: 33.33%; height: 300px"></div>`);
                    $chartContainer.append($ele);
                    return {
                        id,
                        // TODO: Translate chartType
                        // title: chartType,
                        statistics: key ? statistics[key] : statistics,
                        chartType: chartType,
                        wrap: true,
                        $wrapParent: $ele,
                        roundValues: true,
                        __key: key
                    };
                });
            }
            else {
                chartOptions = charts.map(chartType => {
                    const id = `chart-${chartType}`;
                    const $ele = $(`<div id="chart-container-${containerI}-${id}" style="display: inline-block; vertical-align: top; width: 33.33%; height: 300px"></div>`);
                    $chartContainer.append($ele);
                    return {
                        id,
                        statistics: statistics,
                        chartType: chartType,
                        wrap: true,
                        $wrapParent: $ele,
                        roundValues: true,
                        __key: undefined
                    };
                });
            }
            const CHART_EVENT = `ACI_TABS_CHANGED->${tabgroup}`;
            return chartOptions.map((opt, i) => {
                const chart = new AStatisticsChart(opt);
                if (filters.hasOwnProperty('GroupBy')) {
                    const eventId = Events.on(CHART_EVENT, () => chart.forceReflow());
                    this.viewEvents.push({ eventName: CHART_EVENT, eventId });
                    // if (i !== 0) {
                    // Events.once(CHART_EVENT, () => chart.show())
                    // return chart.setPrefferedHeight('300px')
                    // }
                }
                return chart.setPrefferedHeight('300px').show();
            });
        });
        return await Promise.all(output);
    }
    async refresh() {
        const filters = FilterManager.saveExplicit();
        FilterManager.setActive(false);
        this.viewEvents.map(({ eventName, eventId }) => {
            Events.off(eventName, eventId);
        });
        this.viewEvents = [];
        try {
            const opt = await this.fetchMapping({ filters });
            const stats = await AEngine.get(AStatisticsService).fetch(filters, opt);
            const statistics = filters.hasOwnProperty('GroupBy') ? stats.statistics : stats.statisticsTotal;
            const $container = $('.flex-content');
            const { tabs } = this.prepareTabs({ $container, statistics, filters });
            this.charts = await this.createCharts({ $container, statistics, filters, tabs });
        }
        catch (err) {
            AError.handle(err);
        }
        FilterManager.setActive(true);
    }
}
export function css() {
    return ( /*html*/`
    <style>
      .aci-tabs.tabs-flex {
        flex-wrap: wrap;
      }
    </style>
  `);
}
export function render() {
    return ( /*html*/`
    <div id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column c-scroll col-12">
        <div class="form-group">
          <label class="form-label" for="FromDate">From</label>
          <input class="form-input" type="date" id="FromDate" required="required">
          <input class="form-input" type="time" id="FromTime" required="required">
        </div>
        <div class="form-group">
          <label class="form-label" for="ToDate">To</label>
          <input class="form-input" type="date" id="ToDate" required="required">
          <input class="form-input" type="time" id="ToTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="GroupBy">Group By</label>
          <select class="form-select" id="GroupBy">
            <option value="%">All</option>
            <option value="Zone">Zone</option>
            <option value="Area">Area</option>
            <option value="Indicator">Indicator</option>
            <option disabled="disabled" value="RouteArea">RouteArea</option>
          </select>
        </div>

      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </div>
    <div class="flex-child fh">
      <div class="flex-content vh-padding"></div>
    </div>
  `);
}
