import { AError } from "../classes/AError.js";
export class AValidateEnforcementProcessService {
    constructor() { }
    async validate() {
        await requestService.send('ValidateEnforcementProcessRequest', {}, {
            waitForEvent: 'ValidateEnforcementProcessResponse'
        }).then((res) => {
            if (res.Success === false) {
                throw new Error(`ValidateEnforcementProcessResponse failed!`);
            }
        }).catch(err => {
            AError.handle({
                err: err,
                useModal: false,
                useConsoleLog: true,
                useAdminAlerts: true,
                useCentralServerLogging: true,
                adminAlertGroup: 'INVALID_ENFORCEMENT_PROCESS'
            });
        });
    }
}
