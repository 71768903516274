export class AGeoUtils {
    static filterInactive(geoObject) {
        return geoObject.Active ? true : false;
    }
    static clampHeading(heading) {
        if (heading > 180.0) {
            return heading - 360.0;
        }
        else if (heading < -180.0) {
            return heading + 360.0;
        }
        return heading;
    }
    static parsePolgonUsingBounds(vehicleBounds, options) {
        const vehicleBoundsLatLng = vehicleBounds.Points.map(({ X, Y }) => {
            return { lat: X, lng: Y };
        });
        const polygon = new google.maps.Polygon({
            paths: vehicleBoundsLatLng,
            strokeColor: '#000000',
            strokeOpacity: 1,
            strokeWeight: 0.5,
            fillColor: '#0000ff',
            fillOpacity: 0.3,
            zIndex: 3,
        });
        if (options.parseToMap === true) {
            polygon.setMap(PageScript.map);
        }
        return polygon;
    }
    static parseAny(map, geoObject, data, options) {
        switch (geoObject.type) {
            case "MultiPolygon":
                return this.parsePolygons(map, geoObject, data, options);
            case 'Polygon':
                return this.parsePolygon(map, geoObject, data, options);
            case 'LineString':
                return this.parseLineString(map, geoObject, data, options);
            case 'Point':
                return this.parseMarker(map, geoObject, data, options);
            case 'Collection':
            case "GeometryCollection":
                let geometries = geoObject.geometries || geoObject.items; /// ACCCServer incorrectly calls this items  (http://gitserver/aci-software/ACCCServer/issues/28)
                return geometries.map((geo) => {
                    // let newGeoObject: AGeoObject = {
                    //   type: geometries[i],
                    //   Index: geoObject.Index,
                    //   Geo: geometries[i],
                    //   Name: geoObject.Name,
                    //   Attributes: geoObject.Attributes,
                    //   Created: geoObject.Created,
                    //   Modified: geoObject.Modified,
                    //   RefList: geoObject.RefList,
                    //   Center: geoObject.Center
                    // }
                    return this.parseAny(map, geo, data, options);
                });
            default:
                let polygon = new google.maps.Polygon();
                Object.assign(polygon, { data });
                return polygon; // Don't break : throw new Error(`GeoObject Type "${geoObject.type}" not implemented!`)
        }
    }
    static parsePolygons(map, geoObject, data, options) {
        let firstPolygon = undefined;
        const polygons = [];
        for (let g = 0; g < geoObject.coordinates.length; g++) {
            let coordinates = geoObject.coordinates[g];
            let path = [];
            for (let r = 0; r < coordinates.length; r++) {
                let ring = [];
                for (let p = 0; p < coordinates[r].length; p++) {
                    let latlng = { lat: coordinates[r][p][1], lng: coordinates[r][p][0] };
                    ring.push(latlng);
                }
                path.push(ring);
            }
            if (path.length == 1) {
                path = path[0];
            }
            let polygon = new google.maps.Polygon({
                paths: path,
                strokeColor: '#000000',
                strokeOpacity: 1,
                strokeWeight: 0.5,
                fillColor: '#ff00ff',
                fillOpacity: 0.3,
                zIndex: 3,
            });
            if (firstPolygon === undefined) {
                firstPolygon = polygon;
            }
            Object.assign(polygon, { data });
            if (options.parseToMap === true) {
                polygon.setMap(map);
            }
            polygons.push(polygon);
        }
        return polygons;
    }
    static parsePolygon(map, geoObject, data, options) {
        let coordinates = geoObject.coordinates;
        let path = [];
        for (let r = 0; r < coordinates.length; r++) {
            let ring = [];
            for (let p = 0; p < coordinates[r].length; p++) {
                let latlng = { lat: coordinates[r][p][1], lng: coordinates[r][p][0] };
                ring.push(latlng);
            }
            path.push(ring);
        }
        if (path.length == 1) {
            path = path[0];
        }
        let polygon = new google.maps.Polygon({
            paths: path,
            strokeColor: '#000000',
            strokeOpacity: 1,
            strokeWeight: 0.5,
            fillColor: '#ffffff',
            fillOpacity: 0.3,
            zIndex: 3,
        });
        Object.assign(polygon, { data });
        if (options.parseToMap === true) {
            polygon.setMap(map);
        }
        return polygon;
    }
    static parseLineString(map, geoObject, data, options) {
        let coordinates = geoObject.coordinates;
        let path = [];
        for (let p = 0; p < coordinates.length; p++) {
            let latlng = {
                lat: coordinates[p][1],
                lng: coordinates[p][0]
            };
            path.push(latlng);
        }
        let polyLine = new google.maps.Polyline({
            path: path,
            strokeColor: '#9d9d9d',
            strokeOpacity: 1,
            strokeWeight: 5,
            zIndex: 3,
        });
        Object.assign(polyLine, { data });
        if (options.parseToMap === true) {
            polyLine.setMap(map);
        }
        return polyLine;
    }
    static parseMarker(map, geoObject, data, options) {
        const [lng, lat] = geoObject.coordinates;
        let marker = new google.maps.Marker({
            position: new google.maps.LatLng(lat, lng),
            animation: google.maps.Animation.DROP,
        });
        Object.assign(marker, { data });
        if (options.parseToMap === true) {
            marker.setMap(map);
        }
        return marker;
    }
    static convertPathToArray(path) {
        const output = [];
        for (let i = 0; i < path.getLength(); i++) {
            output.push(path.getAt(i).toJSON());
        }
        return output;
    }
    // TODO: Find out which is more efficient, convertPathToArray or convertPathToArray2
    static convertPathToArray2(geoInstance) {
        const collection = geoInstance.getPath().getArray();
        const path = collection.map((latLng) => latLng.toJSON());
    }
    static calcCenter(obj) {
        if (obj instanceof google.maps.LatLngBounds) {
            const latLngBounds = obj;
            return latLngBounds.getCenter().toJSON();
        }
        if (obj instanceof google.maps.Polyline || obj instanceof google.maps.Polygon) {
            const geoInstance = obj;
            // For google.maps.Polygon we also use .getPath, in order to get the outer ring
            const path = AGeoUtils.convertPathToArray(geoInstance.getPath());
            let center = { lat: 0, lng: 0 };
            for (let latLng of path) {
                center.lat += latLng.lat;
                center.lng += latLng.lng;
            }
            center.lat /= path.length;
            center.lng /= path.length;
            return center;
        }
        if (obj instanceof google.maps.Marker) {
            return obj.getPosition()?.toJSON();
        }
        throw new Error(`Unexpected argument ${JSON.stringify(obj)}`);
    }
    static getRandomPointInside(geoInstance) {
        let bounds = new google.maps.LatLngBounds();
        for (let i = 0; i < geoInstance.getPath().getLength(); i++) {
            bounds.extend(geoInstance.getPath().getAt(i));
        }
        var sw = bounds.getSouthWest();
        var ne = bounds.getNorthEast();
        // Guess 100 random points inside the bounds, 
        // put a marker at the first one contained by the polygon and break out of the loop
        for (var i = 0; i < 100; i++) {
            var lat = Math.random() * (ne.lat() - sw.lat()) + sw.lat();
            var lng = Math.random() * (ne.lng() - sw.lng()) + sw.lng();
            var point = { lat, lng };
            if (google.maps.geometry.poly.containsLocation(point, geoInstance)) {
                return point;
            }
        }
        return AGeoUtils.calcCenter(geoInstance);
    }
    static calcDetectionHeading(parkingSpace) {
        const points = AGeoUtils.convertPathToArray(parkingSpace.getPath());
        const distances = [];
        for (let i = 1; i < points.length; i++) {
            distances.push(this.distanceGoogleMaps(points[i - 1], points[i]));
        }
        let minDistance = Number.MAX_VALUE;
        let minDistanceIndex = -1;
        distances.map((distance, i) => {
            if (distance < minDistance) {
                minDistance = distance;
                minDistanceIndex = i;
            }
        });
        return google.maps.geometry.spherical.computeHeading(points[minDistanceIndex], points[(minDistanceIndex + 1) % points.length]);
    }
    static rotateDetectionBounds(center, context) {
        const detectionWidth = 1.8049227462465927 / 2.0;
        const detectionLength = 4.9325814100307 / 2.0;
        const heading = (context.data?.target !== undefined)
            ? this.calcDetectionHeading(context.data?.target) + Math.random() * 3 : (Math.random() * 360 - 180);
        const sideHeading = AGeoUtils.clampHeading(heading + 90);
        const front = google.maps.geometry.spherical.computeOffset(center, detectionLength, heading);
        const back = google.maps.geometry.spherical.computeOffset(center, -detectionLength, heading);
        const points = [
            google.maps.geometry.spherical.computeOffset(back, detectionWidth, sideHeading).toJSON(),
            google.maps.geometry.spherical.computeOffset(back, -detectionWidth, sideHeading).toJSON(),
            google.maps.geometry.spherical.computeOffset(front, -detectionWidth, sideHeading).toJSON(),
            google.maps.geometry.spherical.computeOffset(front, detectionWidth, sideHeading).toJSON(),
        ];
        const detectionBounds = {
            Points: points.map(({ lat, lng }) => ({ 'X': lat, 'Y': lng }))
        };
        return detectionBounds;
    }
    static transformPoint(point, heading, distanceInMeters) {
        const h = heading ?? AGeoUtils.clampHeading(Math.random() * 360);
        return google.maps.geometry.spherical.computeOffset(point, distanceInMeters ?? 10, h).toJSON();
    }
    static distanceGoogleMaps(a, b) {
        return google.maps.geometry.spherical.computeDistanceBetween(a, b);
    }
}
