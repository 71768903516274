import { AConfig } from "./classes/AConfig.js";
import { AError } from "./classes/AError.js";
import { AEngine } from "./core/AEngine.js";
import { EVENTS } from "./services/AEventService.js";
import { APolicyService } from "./services/APolicyService.js";
import { addTabListeners, AInputDate, AInputTime, editFilterProfile, initAccordions, initQuickSelectShortcutPopovers, saveFilterProfile } from "./utils/tools.js";
import { initToolTips } from "./utils/tooltip.js";
export function initPolicies() {
    const policyService = AEngine.get(APolicyService);
    // Change button color when filters change
    policyService.add(_ => {
        const $btn = $('#RefreshButton');
        const FiltersEdited = $btn => {
            if (!$btn.hasClass('changed')) {
                $btn.addClass('changed');
            }
        };
        $('#Filters :input').on('change', _ => {
            FiltersEdited($btn);
        });
        FiltersEdited($btn);
        $btn.on('click', _ => {
            Events.tryInvoke(EVENTS.REFRESH_INVOKED);
            if ($btn.hasClass('changed')) {
                // Change button color to blue
                $btn.removeClass('changed');
                // User actions log
                userActionService.logAction('USER', 'VIEW', FilterManager.saveExplicit({ cacheFilters: false, parseNumbers: true }));
            }
        });
        $btn.on('aci-event-enabled', (event) => {
            $btn.removeClass('loading');
        });
        $btn.on('aci-event-disabled', (event, options) => {
            if (options.silent === true) {
                return;
            }
            $btn.addClass('loading');
        });
        return true;
    });
    // Add min value to limit filter
    policyService.add(_ => {
        const $limit = $('#Limit');
        $limit.attr('min', 1);
        // $limit.attr('max', configService.get('filters.maxResults').maxResultsCeiling)
        $limit.attr('max', AConfig.get('filters.maxResultsCeiling', 100000));
        $limit.on('change', e => {
            const $ele = $(e.target);
            const val = parseInt($ele.val());
            if (val <= 0) {
                $ele.val(1);
            }
        });
        return true;
    });
    // Initialize tabviews
    policyService.add(_ => {
        const $aciTabs = $('.aci-tabs');
        if ($aciTabs.length > 0) {
            $(`[tabview]`).hide();
            $aciTabs.each((i, tabs) => {
                addTabListeners($(tabs));
            });
        }
        const $alignedTabs = $('.aci-tabs.tabs-align');
        if ($alignedTabs.length > 0) {
            $alignedTabs.each((i, tabs) => {
                const $tabs = $(tabs);
                const $items = $tabs.find('.aci-tab');
                let maxHeight = -1;
                $items.each((i, tab) => {
                    const $tab = $(tab);
                    const height = $tab.outerHeight();
                    if (height > maxHeight) {
                        maxHeight = height;
                    }
                });
                $items.css('height', maxHeight);
            });
        }
        return true;
    });
    // Remove .display-once after pressing Refresh Button
    policyService.add(async (_) => {
        const $displayOnce = $('.display-once.translate-subtitle .empty-white .empty-subtitle');
        if ($displayOnce.length > 0) {
            const partial = await Translate.get(`Press '' to fetch results`);
            $displayOnce.text(partial.replace(`''`, `"${$('#RefreshButton').text()}"`));
        }
        return true;
    });
    // Add name attributes to filters
    policyService.add(async (_) => {
        const $filters = $('#Filters');
        $filters.find('[id]').toArray().map(e => $(e)).map($input => {
            $input.attr('name', $input.attr('id'));
        });
        if ($filters.is('form')) {
            $filters.on('submit', (e) => {
                return false;
            });
        }
        return true;
    });
    // Add Quick select filter options
    policyService.add(async (_) => {
        let $filters = $('#Filters');
        let a = $filters.find('#FromDate');
        let b = $filters.find('#ToDate');
        // Check if filters element exists
        if ($filters.length === 0 || a.length === 0 || b.length === 0) {
            return true;
        }
        // Add event listener for page construct
        const eventId = Events.hardwire(EVENTS.CONSTRUCT, async () => {
            // Set filters variable after page has been constructor
            $filters = $('#Filters');
            // Get translation for "shortcuts"
            const labelText = await Translate.get('Shortcuts');
            // Declare interface referencing to the current PageScript
            const ps = PageScript;
            // Declare default quick select shortcuts
            let btns = ps?.quickSelectBtns ?? {
                'Today': {
                    onClick: () => {
                        const { todayDate, tomorrowDate } = FilterManager;
                        $filters.find('#FromDate').val(AInputDate(todayDate));
                        $filters.find('#FromTime').val(AInputTime(todayDate));
                        $filters.find('#ToDate').val(AInputDate(tomorrowDate));
                        $filters.find('#ToTime').val(AInputTime(tomorrowDate));
                    }
                },
                'Yesterday': {
                    onClick: () => {
                        const { yesterdayDate, todayDate } = FilterManager;
                        $filters.find('#FromDate').val(AInputDate(yesterdayDate));
                        $filters.find('#FromTime').val(AInputTime(yesterdayDate));
                        $filters.find('#ToDate').val(AInputDate(todayDate));
                        $filters.find('#ToTime').val(AInputTime(todayDate));
                    }
                },
                'This Week': {
                    onClick: () => {
                        const { startOfWeek, endOfWeek } = FilterManager;
                        $filters.find('#FromDate').val(AInputDate(startOfWeek));
                        $filters.find('#FromTime').val(AInputTime(startOfWeek));
                        $filters.find('#ToDate').val(AInputDate(endOfWeek));
                        $filters.find('#ToTime').val(AInputTime(endOfWeek));
                    }
                },
                'This Month': {
                    onClick: () => {
                        const { startOfMonth, endOfMonth } = FilterManager;
                        $filters.find('#FromDate').val(AInputDate(startOfMonth));
                        $filters.find('#FromTime').val(AInputTime(startOfMonth));
                        $filters.find('#ToDate').val(AInputDate(endOfMonth));
                        $filters.find('#ToTime').val(AInputTime(endOfMonth));
                    }
                },
            };
            // Translate quick select buttons
            const btnsT = await Translate.get(Object.keys(btns));
            // Construct HTML
            const $shortcuts = $(/*html*/ `
        <div class="form-group">
          <div class="children-inline-flex toggle toggle-shortcut">
            <label class="form-label">${labelText}</label>
            <i class="fa-solid fa-chevron-up fa-xs"></i>
          </div>
          <div class="shortcut-buttons">
            ${Object.keys(btns).map((key, i) => (`
                <button class="quick-btn quick-${i} btn btn-grey btn-sm fw" style="display: block; margin-bottom: 3px;">${btnsT[key]}</button>
              `)).join('')}
          </div>

          <div class="">
            <div class="filter-profile-buttons input-group mb-1">
              <button id="filters-save" class="btn btn-grey input-group-btn btn-sm col-6">
                <i class="fa-solid fa-sd-card"></i>
                Save
              </button>
              <button id="filters-load" class="btn btn-grey input-group-btn btn-sm col-6">
                <i class="fa-regular fa-folder-open"></i>
                Load
              </button>
              <button id="filters-edit" class="btn btn-grey input-group-btn btn-sm col-6">
                <i class="fa-solid fa-trash"></i>
              </button>
            </div>
  
          </div>
        </div>
      `);
            // Initialize toggle collapse functionality
            FilterManager.createToggle($shortcuts.find('.toggle-shortcut'), $shortcuts.find('.shortcut-buttons, .filter-profile-buttons'), preferenceService.load('filters-shortcut-collapsed', false));
            // Initialize toggle collapse functionality
            FilterManager.createToggle($filters.find('.toggle-advanced-filters'), $filters.find('.advanced-filters'), false, null);
            // Inject quick select buttons into the filters element
            $filters.find('.column:first-child').eq(0).prepend($shortcuts);
            // Remove highlight when filters are changed
            // TODO: Add all filter inputs
            $filters.find('#FromDate, #FromTime, #ToDate, #ToTime').on('change', e => {
                FilterManager.unselectShortcut();
            });
            // Attach click listeners to buttons
            $filters.find('#filters-save').on('click', async (e) => saveFilterProfile().catch(AError.handle));
            $filters.find('#filters-edit').on('click', async (e) => editFilterProfile().catch(AError.handle));
            // Listen to dynamic quick select button click events
            Object.keys(btns).map((key, i) => {
                const clsIdentifier = `.quick-${i}`;
                $filters.find(clsIdentifier).on('click', (e) => {
                    btns[key].onClick($filters);
                    FilterManager.selectShortcut(clsIdentifier);
                });
            });
            // Initialize popover events
            initQuickSelectShortcutPopovers();
        });
        Events.h_once(EVENTS.DESTRUCT, () => {
            Events.off(EVENTS.CONSTRUCT, eventId);
        });
        return true;
    });
    // Initialize tooltip overlays
    policyService.add(_ => {
        initToolTips($('[atooltip]'));
        return true;
    });
    policyService.add(_ => {
        const $ajaxContent = $('#AjaxContent');
        $ajaxContent.toggleClass('has-filters', $ajaxContent.find('#Filters').length > 0);
        return true;
    });
    policyService.add(_ => {
        initAccordions($('body'));
        return true;
    });
    policyService.add(_ => {
        const $customScrollInstances = $('.c-scroll');
        $customScrollInstances.toArray().map(ele => $(ele)).map(($ele) => {
            const isAlwaysVisible = (overflow) => ['visible', 'scroll'].includes(overflow);
            const [x, y] = $ele.css('overflow').split(' ');
            if (isAlwaysVisible(x)) {
                $ele.attr('scrollbar-height', 10);
            }
            if (isAlwaysVisible(y)) {
                $ele.attr('scrollbar-width', 10);
            }
        });
        return true;
    });
}
