import { AEngine } from "../core/AEngine.js";
import { AHotReload } from "../classes/AHotReload.js";
export class ALoadHotReload {
    static async run() {
        if (AEngine.isDevelopmentMode && AEngine.devConfig['hotreload-enabled'] == true) {
            const hotreload = new AHotReload();
            hotreload.listen();
            globalThis.hotreload = hotreload;
        }
    }
}
