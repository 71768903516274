import { AError } from "../classes/AError.js";
import { EVENTS } from "../services/AEventService.js";
export var ATemplates;
(function (ATemplates) {
    ATemplates[ATemplates["WaitingForInput"] = 0] = "WaitingForInput";
    ATemplates[ATemplates["Reload"] = 1] = "Reload";
    ATemplates[ATemplates["RouteInformation"] = 2] = "RouteInformation";
    ATemplates[ATemplates["RouteExeption"] = 3] = "RouteExeption";
    ATemplates[ATemplates["EmptyList"] = 4] = "EmptyList";
})(ATemplates || (ATemplates = {}));
export class ATemplateService {
    constructor() {
        this.cache = {};
        this.templates = {
            [ATemplates.EmptyList]: ( /*html*/`
      <div class="display-once translate-subtitle fw">
        <div class="empty empty-white" style="height: 100%">
          <div class="empty-icon">
            <i class="fa-solid fa-glass-half fa-6x"></i>
          </div>
          <p class="empty-title h5">No Data</p>
        </div>
      </div>
    `),
            [ATemplates.WaitingForInput]: ( /*html*/`
      <div class="display-once translate-subtitle">
        <div class="empty empty-white" style="height: 100%">
          <div class="empty-icon">
            <i class="fa-regular fa-timer fa-6x"></i>
          </div>
          <p class="empty-title h5">Waiting for input</p>
          <p class="empty-subtitle">Press 'Show' to fetch results</p>
        </div>
      </div>
    `),
            [ATemplates.Reload]: ( /*html*/`
      <div class="display-once">
        <div class="empty empty-white" style="height: 100%">
          <div class="empty-icon">
            <i class="fa-regular fa-triangle-exclamation fa-6x"></i>
          </div>
          <p class="empty-title h5">Oh no!</p>
          <p class="empty-subtitle">Connection Lost with the server</p>
          <button id="ReloadButton" class="btn btn-primary" onclick="location.reload(true);">Refresh Page</button>
        </div>
      </div>
    `),
            [ATemplates.RouteInformation]: ( /*html*/`
      <div class="display-once">
        <div class="empty" style="height: 100%; color:lightgray;"">
          <div class="empty-icon">
            <i class="fa-regular fa-route fa-3x"></i>
          </div>
          <p class="empty-title h5">Route Information</p>
        </div>
      </div>
    `),
            [ATemplates.RouteExeption]: ( /*html*/`
      <div class="display-once">
        <div class="empty" style="height: 100%; color:lightgray;"">
          <div class="empty-icon">
            <i class="fa-regular fa-road-barrier fa-3x"></i>
          </div>
          <p class="empty-title h5">Route Exeptions</p>
        </div>
      </div>
    `)
        };
    }
    async autoInit() {
        Events.h_once(EVENTS.PREFETCH, _ => {
            this.getOrFetchTemplate(ATemplates.WaitingForInput).catch(AError.handle),
                this.getOrFetchTemplate(ATemplates.RouteInformation).catch(AError.handle);
        });
    }
    async getOrFetchTemplate(template, opt) {
        if (!this.templates.hasOwnProperty(template)) {
            throw new Error(`Couldn't find template=${template}`);
        }
        if (this.cache.hasOwnProperty(template)) {
            return this.cache[template];
        }
        const templateHtml = this.templates[template];
        const translatedHtml = await requestService.translateDom(templateHtml);
        if (opt?.cache === true) {
            this.cache[template] = translatedHtml;
        }
        return translatedHtml;
    }
    async render(template) {
        return this.getOrFetchTemplate(template);
    }
    fromCache(template) {
        if (this.cache.hasOwnProperty(template)) {
            return this.cache[template];
        }
    }
}
