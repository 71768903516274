import { AError } from "../../../classes/AError.js";
import { AEngine } from "../../../core/AEngine.js";
import { ALERT_BUTTONS, ALERT_STATUS, ALERT_TITLES } from "../../../services/AAlertService.js";
import { clamp } from "../../../utils/tools.js";
export class AConfigTransitionCursorComponent {
    get x() { return this.xValue; }
    get min() { return Math.min.apply(null, [100000, ...this.parentComp.CfgData.map(item => item.x)]); }
    get max() { return Math.max.apply(null, [0, ...this.parentComp.CfgData.map(item => item.x)]); }
    constructor(parentComp, { pValue, xValue, color, locked }) {
        this.parentComp = parentComp;
        this.internalId = idAllocatorService.getNextId({ prefix: `clr-cursor-` });
        // this.opts = { pValue, xValue, color}
        this.pValue = pValue;
        this.xValue = xValue;
        this.color = color;
        this.$ruler = this.parentComp.$ele().find('.cfg-gradient');
        this.$cursor = $(/*html*/ `
      <div uid="${this.internalId}" class="gradient-cursor ${locked ? 'cursor-locked' : ''}" style="position: absolute; z-index: 2">
        <div class="cursor-compact">
          <div class="cursor-control cursor-ruler">
            <i class="fa-solid fa-ruler"></i>
          </div>
          <div class="cursor-control cursor-lock">
            <i class="fa-solid fa-lock"></i>
          </div>
          <div class="cursor-control cursor-grip hide-on-shift">
            <i class="fa-solid fa-grid"></i>
          </div>
          <input type="color" value="${'#000000'}" />
          <div class="color-proxy" style="background-color: ${'#000000'}"></div>

          <div class="cursor-control cursor-edit">
            <i class="fa-solid fa-gear"></i>
          </div>
          <div class="cursor-control cursor-check">
            <i class="fa-solid fa-check"></i>
          </div>
        </div>
        <div class="cursor-expanded">
          <div class="cursor-row">
            <input type="number" step="0.01" class="form-input input-sm inp-proxy-x" value="0.0" />
          </div>
          <div class="cursor-row">
            <input type="text" class="form-input input-sm inp-proxy-color" required="required" minlength="7" pattern="^[#][a-fA-F0-9]+$" />
          </div>
          <div class="cursor-row">
            <div class="cursor-control cursor-destroy">
              <i class="fa-regular fa-trash"></i>
            </div>
          </div>
        </div>
      </div>
    `);
        $(document).on('keyup keydown', (e) => {
            this.shifted = e.shiftKey;
            this.parentComp.$ele().toggleClass('aci-shiftkey', this.shifted);
        });
        const $color = this.$cursor.find('input[type="color"]');
        const $inpClr = this.$cursor.find('.inp-proxy-color');
        const $x = this.$cursor.find('.inp-proxy-x');
        $color.on('change', (e) => {
            this.color = $color.val();
            $inpClr.val(this.color);
            this.parentComp.reconstruct();
        });
        this.$cursor.find('.cursor-destroy').on('click', async (e) => {
            try {
                Alerts.show({
                    title: ALERT_TITLES.None,
                    buttons: ALERT_BUTTONS.deleteCancel,
                    // type: ALERTS.Error,
                    content: await Translate.get(`Do you want to delete this color gradient cursor?`)
                }).on(ALERT_STATUS.ON_ACTION_PROCEED, () => {
                    this.$cursor.remove();
                    this.parentComp.removeCursor(this);
                });
            }
            catch (err) {
                AError.handle(err);
            }
        });
        this.$cursor.find('.cursor-edit').on('click', (e) => {
            this.$cursor.toggleClass('selected');
            $x.val(this.x);
            $inpClr.val(this.color);
        });
        this.$cursor.find('.cursor-check').on('click', (e) => {
            const xValue = Number($x.val());
            const clr = $inpClr.val();
            this.xValue = isNaN(xValue) ? this.xValue : xValue;
            this.color = clr;
            this.$cursor.toggleClass('selected');
            this.parentComp.reconstruct();
        });
        this.$cursor.find('.cursor-grip').on('mousedown', (e) => {
            this.parentComp.dragCursor = this;
            AEngine.warn(`$dragCursor = `, this.$cursor);
        });
        if (locked) {
            this.lock();
        }
        this.set({ xValue: this.xValue, skipClamp: this.isLocked() });
        this.parentComp.$ele().find('.cfg-cursors').append(this.$cursor);
    }
    isLocked() {
        return this.$cursor.hasClass('cursor-locked');
    }
    lock() {
        this.$cursor.addClass('cursor-locked');
    }
    set(opt) {
        const { min, max } = this;
        if ('color' in opt) {
            this.color = opt.color;
        }
        if ('pValue' in opt) {
            if (opt.skipClamp !== true)
                opt.pValue = clamp(opt.pValue, 1.0, 99.0);
            const xValue = (max - min) / 100.0 * opt.pValue;
            // const hexi = opt.color ? opt.color : interpolateService.lerpTo({ transition: CfgData, value: xValue }).hexi
            this.pValue = opt.pValue;
            this.xValue = xValue;
            // this.opts = {
            //   locked: this.opts.locked,
            //   color: opt.color ?? this.opts.color,
            //   pValue: opt.pValue,
            //   xValue: xValue
            // }
        }
        else if ('xValue' in opt) {
            if (opt.skipClamp !== true)
                opt.xValue = clamp(opt.xValue, min + 0.01, max - 0.01);
            const pValue = (opt.xValue - min) * 100.0 / (max - min);
            // const hexi = opt.color ? opt.color : interpolateService.lerpTo({ transition: CfgData, value: opt.xValue }).hexi
            this.xValue = opt.xValue;
            this.pValue = pValue;
            // this.opts = {
            //   locked: this.opts.locked,
            //   color: opt.color ?? this.opts.color,
            //   pValue: pValue,
            //   xValue: opt.xValue
            // }
        }
        this.$cursor.css('left', `${this.pValue}%`);
        this.$cursor.find('input[type="color"]').val(this.color);
        this.$cursor.find('.color-proxy').css('background-color', this.color);
    }
    dragStep(clientX) {
        const { $ruler } = this;
        let mX = clientX - $ruler.offset().left;
        let p = clamp(mX / $ruler.outerWidth() * 100, 0, 100);
        this.set({ pValue: p });
    }
    dragStopped() { }
    toJSON() {
        return { color: this.color, x: this.xValue };
    }
}
