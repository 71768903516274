var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { AResponse } from "../classes/AResponse.js";
import { ACCCClient } from "../core/ACCCClient.js";
import { AEngine } from "../core/AEngine.js";
import { APacketIdAllocator } from "../core/allocator/APacketIdAllocator.js";
import { AErrorWithContext } from "../core/errors.js";
import { executeOrder } from "../types/attributes.js";
import { EVENTS } from "./AEventService.js";
export class AMySQLError extends AErrorWithContext {
    constructor(message, context) {
        super(message !== undefined ? message : undefined);
        this.context = context;
        this.name = 'AMySQLError';
    }
}
export let ARequestService = class ARequestService {
    constructor() {
        this.client = null;
        this.allocator = new APacketIdAllocator();
        Object.defineProperty(window, 'Request', {
            get: () => this
        });
    }
    autoInit() {
        Events.hardwire(EVENTS.JSONS_LOADED, _ => {
            let CCCClient = new ACCCClient(Settings);
            this.client = CCCClient;
            globalThis.CCCClient = CCCClient;
            Events.tryInvoke(EVENTS.API_READY);
        });
    }
    async fetch(queryWrapper, options = {}) {
        const res = await this.query(queryWrapper, options);
        const wrapped = new AResponse(res, { ...options });
        return wrapped;
    }
    // Send query to the server using ajax/websocket
    query(queryWrapper, options = {}) {
        const { skipIdAllocator, cacheQuery } = Object.assign({
            skipIdAllocator: false,
            cacheQuery: false
        }, options);
        if (typeof queryWrapper === 'string') {
            return this.query({
                Query: queryWrapper.replace(/\s\s+/g, ' ').trim()
            });
        }
        const pid = skipIdAllocator === false ? this.allocator.getNextPacketId(queryWrapper.Name) : queryWrapper.Name;
        // Add packet id
        queryWrapper.Name = pid;
        // Remove all unnecessary whitespaces in the query
        queryWrapper.Query = queryWrapper.Query.replace(/\s\s+/g, ' ').trim();
        // Add context for translation
        queryWrapper.Context = document.location.hash.substr(2) || '/';
        // Add Params if the key doesn't exist
        if (!queryWrapper.Params)
            queryWrapper.Params = {};
        // Optional query cache
        if (cacheQuery === true) {
            this.prevQuery = queryWrapper.Query;
            this.prevParams = queryWrapper.Params;
        }
        const promiseToAwait = Loading.waitForEvent(`query->${pid}`, false);
        this.client.SendMessage("QueryRequest", 2, queryWrapper);
        return promiseToAwait.then((data) => {
            if (data.Success === false || data instanceof Error) {
                if (Events.logLevel === 2) {
                    AEngine.error(data.Error, queryWrapper);
                }
                throw new AMySQLError(data.Error, queryWrapper);
            }
            return data;
        });
    }
    queries(queryWrappers) {
        return Promise.all(queryWrappers.map((wrapper) => this.query(wrapper)));
    }
    indexes(response, keyArray) {
        const indexes = {};
        for (const key of keyArray) {
            indexes[key] = -1;
        }
        Object.keys(indexes).map(key => indexes[key] = response.Columns.indexOf(key));
        return indexes;
    }
    translate(array, lang) {
        let returnArray = true;
        if (!(array instanceof Array)) {
            array = [array];
            returnArray = false;
        }
        // if (array[0] === null) throw new Error(`Unexpected error!`)
        const pid = this.allocator.getNextPacketId('Translate');
        this.client.SendMessage("TranslationRequest", 1, {
            Name: pid,
            Translate: array,
            Context: document.location.hash.substr(2),
            Language: lang ? lang : Language
        });
        return new Promise((resolve) => {
            Events.h_once(`TranslationResponse->${pid}`, async (Data) => {
                // await sleep({ min: 0, max: 10000 })
                // console.log('TranslationResponse', Data)
                // if (typeof Data === 'string') 
                // Object.keys(Data).map(key => {
                //   Data[key] = ATranslateService.sanitize()
                // })
                returnArray === true ? resolve(Data) : resolve(Object.values(Data)[0]);
            });
        });
    }
    translateDom(html) {
        const pid = this.allocator.getNextPacketId('TranslateDom');
        this.client.SendMessage("TranslationRequest", 1, {
            Name: pid,
            Translate: [html],
            Type: 'Html',
            Context: document.location.hash.substr(2),
            Language: Language
        });
        return new Promise((resolve, reject) => {
            Events.once(`TranslationResponse->${pid}`, async (Data) => {
                if (Object.keys(Data).length !== 1) {
                    return reject(new Error(`Unexpected Translation Response Length`));
                }
                resolve(Object.values(Data)[0]);
            });
        });
    }
    locationRequest(params) {
        // TODO: Find out if deprecated
        this.client.SendMessage("LocationRequest", 1, params);
        return new Promise((resolve) => {
            Events.once(`LocationResponse`, (data) => {
                return resolve(data);
            });
        });
    }
    stream(name, data, opt = { version: 1, priority: 0 }) {
        const { version, priority } = $.extend(true, { version: 1, priority: 0 }, opt);
        this.client.SendMessage(name, version, data, priority);
    }
    sendLog(data) {
        CCCClient.SendMessage("LogStream", 1, data);
    }
    send(name, data, opt) {
        let promise;
        if (opt?.waitForEvent !== undefined) {
            const { waitForEvent, timeout } = opt;
            promise = (timeout == undefined) ? Loading.waitForEvent(waitForEvent) : Loading.awaitTimeout(Loading.waitForEvent(waitForEvent), timeout);
        }
        // const promise = (opt?.waitForEvent !== undefined) ? Loading.waitForEvent(opt!.waitForEvent) : undefined
        this.client.SendMessage(name, opt?.version ?? 1, data, opt?.priority || 0);
        return promise;
    }
};
ARequestService = __decorate([
    executeOrder(100)
], ARequestService);
