import { ADynamicChartSnapshotDetections } from "../charts/ADynamicChartSnapshotDetections.js";
import { AShowTable, AConvertToGridColumns, AConvertToGridData } from "../utils/tools.js";
import { AKpiBlock, AKpiState } from "./AKpiBlock.js";
export class AKpiBlockKpiChart extends AKpiBlock {
    get chartId() { return `${this.idFilter}-Chart`; }
    get tableId() { return `${this.idFilter}-Table`; }
    constructor(opt) {
        super({
            refreshImplemented: true,
            optionsImplemented: true,
            tableImplemented: true,
            TranslateTitle: false,
            ...opt,
        });
        this.chartOptions = opt.chartOptions;
        this.snapshot = new ADynamicChartSnapshotDetections(this.chartId, {
            allowExport: false,
            calcDimensions: () => this.getSize()
        });
    }
    async init() {
        super.init();
        const opt = await widgetService.fetchCustomChartByName(this.Name);
        this.chartOptions = { ...opt };
    }
    toggleTableView() {
        this.viewTable = !this.viewTable;
        this.$kpiView.find('[filter]').toArray().map(e => $(e)).map($view => {
            $view.toggleClass('hidden', $view.attr('filter') !== (this.viewTable ? 'table' : 'chart'));
        });
        this.refreshTable();
    }
    async refresh(filters = this.filterOption) {
        const { hasData, chartData } = await this.snapshot.update(this.chartOptions, { ...filters, DeviceName: '%' }, (chartData) => chartData.Rows.length > 0);
        if (!hasData) {
            return AKpiState.noResults;
        }
        this.snapshot.resize();
        this.cachedChartData = chartData;
        if (this.viewTable) {
            this.refreshTable();
        }
    }
    async refreshTable() {
        if (!this.viewTable || this.cachedChartData === undefined) {
            return;
        }
        const data = AConvertToGridData(this.cachedChartData);
        const { Vertical } = this.chartOptions;
        if (this.grid) {
            const scrollTop = this.grid.storeScroll();
            this.grid.store.data = data;
            this.grid.restoreScroll(scrollTop);
        }
        else {
            this.grid = AShowTable({
                appendTo: this.tableId,
                columns: AConvertToGridColumns(this.cachedChartData, {
                    'Bars': { text: await Translate.get(Vertical.replace('Chart_', '')) },
                }),
                data: data
            });
        }
        this.cachedChartData = undefined;
    }
    async render() {
        return await super.renderView({
            title: this.Name,
            viewHtml: ( /*html*/`
        <div filter="chart" class="fh">
          <div id="${this.chartId}"></div>
        </div>
        <div filter="table" class="fh hidden">
          <div id="${this.tableId}" class="fh"></div>
        </div>
      `),
        });
    }
}
