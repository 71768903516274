import { AForm } from "./AForm.js";
export function combine(...args) {
    // console.log(JSON.stringify(args))
    for (let item of args) {
        Object.keys(item || {}).map(k => {
            if (item[k] === undefined) {
                delete item[k];
            }
        });
    }
    // console.log(JSON.stringify(args))
    return Object.assign.apply(undefined, [{}, ...args]);
}
export class AFormMapper {
    constructor() { throw new Error(`AFormMapper.constructor is not allowed to be called`); }
}
AFormMapper.formGeneratorMap = {
    number: (field) => AForm.genNumberInput(combine({}, field)),
    text: (field) => AForm.genTextInput(combine({}, field)),
    jsontext: (field) => AForm.genTextAreaInput(combine({ autocomplete: false }, field)),
    password: (field) => AForm.genTextInput(combine({ isPassword: true }, field)),
    textarea: (field) => AForm.genTextAreaInput(combine({ autocomplete: true }, field)),
    button: (field) => AForm.genButton(combine({}, field)),
    date: (field) => AForm.genDateInput(combine({}, field)),
    time: (field) => AForm.genTimeInput(combine({ value: '01:00:00' }, field)),
    duration: (field) => AForm.genDurationInput(combine({ value: '24:00:00' }, field)),
    checkbox: (field) => AForm.genCheckboxInput(combine({}, field)),
    color: (field) => AForm.genColorInput(combine({}, field)),
    select: (field) => AForm.genSelectInput(combine({}, field)),
    multiselect: (field) => AForm.genMultiSelectInput(combine({ disallowNone: false }, field)),
    treedropdown: (field) => AForm.genTreeDropdownInput(combine({ disallowNone: false }, field)),
    rangeslider: (field) => AForm.genRangeSliderInput(combine({}, field)),
    seperator: (field) => AForm.genSeperator(field),
};
AFormMapper.formToValueMap = {
    number: (key, $input) => {
        const num = Number($input.val());
        if (isNaN(num))
            throw new Error(`Expected number but got value: ${$input.val()}`);
        return { key, value: num };
    },
    text: (key, $input) => {
        return { key, value: $input.val() };
    },
    jsontext: (key, $input) => {
        return { key, value: $input.val() };
    },
    password: (key, $input) => {
        return { key, value: $input.val() };
    },
    textarea: (key, $input) => {
        return { key, value: $input.val() };
    },
    button: (key, $input) => {
        return { key, value: $input.val() };
    },
    date: (key, $input) => {
        return { key, value: $input.val() };
    },
    time: (key, $input) => {
        return { key, value: $input.val() };
    },
    duration: (key, $input) => {
        const $eles = $input.find('input');
        let value = '00:00';
        if ($eles.length > 1) {
            const hh = $eles.eq(0).val();
            const mm = $eles.eq(1).val();
            value = `${(hh ?? '00')}:${(mm ?? '00')}`;
        }
        return { key, value };
    },
    checkbox: (key, $input) => {
        return { key, value: $input.prop('checked') };
    },
    color: (key, $input) => {
        return { key, value: $input.val() };
    },
    select: (key, $input) => {
        let v = $input.val();
        return { key, value: v === 'null' ? null : v };
    },
    multiselect: (key, $input, opt) => {
        const dd = $input.data('DropDown');
        if (dd !== undefined) {
            if (dd.selectedTextsQuery === '%' && opt.ignoreWildcards === true) {
                return { key, value: null };
            }
            return { key, value: dd.selectedKeysWithoutPrefix };
        }
        return undefined;
    },
    treedropdown: (key, $input, opt) => {
        const dd = $input.data('DropDown');
        if (dd !== undefined) {
            if (dd.selectedTextsQuery === '%' && opt.ignoreWildcards === true) {
                return { key, value: null };
            }
            return { key, value: dd.selectedTextsQuery };
        }
        return undefined;
    },
    rangeslider: (key, $input) => {
        const value = {
            min: $input.prop('min'),
            left: $input.prop("value1"),
            right: $input.prop("value2"),
            max: $input.prop('max'),
        };
        return { key, value };
    },
    seperator: (key, $input) => {
        return { key, value: $input.val() };
    },
};
