export class AIdAllocatorService {
    constructor() {
        this.packetNum = 1000;
    }
    _addPadding(s, len = 8) {
        s = s.toString();
        if (s.length < len) {
            s = ('0000000000' + s).slice(-len);
        }
        return s;
    }
    get _nextPacketId() {
        return this._addPadding((this.packetNum++).toString(16));
    }
    getNextId(opt) {
        return (opt?.prefix !== undefined) ? opt.prefix + this._nextPacketId.toString() : this._nextPacketId.toString();
    }
}
