import { AErrorWithContext } from "../core/errors.js";
import { _getEle, _getEle$ } from "../utils/maps.js";
import { createArray } from "../utils/tools.js";
import { EVENTS } from "./AEventService.js";
export class ADragService {
    constructor() {
        this.eventCount = 1;
        this.eventPrefix = "[DragService]";
    }
    generateEventKey() {
        return (this.eventPrefix) + this.eventCount++;
    }
    createDraggableGeneric(options) {
        const $p = _getEle$(options?.directParent ?? '.splitter');
        let sections = $p.find('> .drag-section').toArray();
        let seperators = $p.find('> .drag-seperator').toArray();
        if (sections.length === 0) {
            throw new Error(`ADragService .splitter > .drag-section couldn't be found!`);
        }
        if (seperators.length === 0) {
            throw new Error(`ADragService .splitter > .drag-seperator couldn't be found!`);
        }
        if (sections.length !== seperators.length + 1) {
            throw new Error(`ADragService invalid element ratio sections:seperators!`);
        }
        console.log({ $p, sections, seperators, defaultSize: options?.defaultSize });
        console.log('defaultSizeLength:', options?.defaultSize?.length);
        if (options?.defaultSize?.length && options?.defaultSize?.length !== sections.length) {
            throw new AErrorWithContext(`ADragService invalid defaultSize length:`, options.defaultSize);
        }
        if (options?.defaultSize !== undefined) {
            sections.map((sec, i) => { sec.style.width = options.defaultSize[i]; });
        }
        return createArray(sections.length - 1).map((_, i) => {
            const siblings = [sections[i], sections[i + 1]].map(s => _getEle(s));
            return this.createDraggableStrict(siblings[0], siblings[1], _getEle(seperators[i]), options?.direction ?? 'H', options?.eventName ?? EVENTS.CONTENT_RESIZE);
        });
    }
    /**
     * @deprecated
     * Use createDraggableGeneric Instead!
     */
    createDraggable(options) {
        const { left, right, separator, direction, eventName } = Object.assign({
            left: '.part-one',
            right: '.part-two',
            separator: '#separator1',
            direction: 'H',
            eventName: EVENTS.CONTENT_RESIZE
        }, options);
        return this.createDraggableStrict(_getEle(left), _getEle(right), _getEle(separator), direction, eventName);
    }
    getLayout(splitter) {
        const $splitter = _getEle$(splitter);
        return $splitter.attr('layout');
    }
    toggleLayout(splitter, layout, force) {
        const $spl = _getEle$(splitter);
        const $p1 = $spl.find('.part-one');
        const $p2 = $spl.find('.part-two');
        const $s2 = $p2.next();
        const $p3 = $spl.find('.part-three');
        const addLayout = force ?? !$spl.is(`[layout="${layout}"]`);
        const g = $spl.find('.drag-seperator').eq(0).width() ?? $spl.find('.separator').eq(0).width();
        const fw = $spl.closest('.flex-child,.flex-content,#AjaxContent').width();
        const lw = $p1.width();
        $s2.toggleClass('hidden', !addLayout);
        $p3.toggleClass('hidden', !addLayout);
        if (addLayout) {
            $spl.attr('layout', layout);
            const newWidth = ($p2.width() + $p3.width()) / 2.0;
            $p3.width(newWidth - g);
            $p2.width(newWidth);
            $s2.css('left', $s2.position().left - newWidth);
            return { active: true, layout: layout };
        }
        else {
            $spl.removeAttr('layout');
            const pw = $p3.width();
            $p3.width(0);
            $p2.width(fw - lw);
            $s2.css('left', $s2.position().left + pw);
            return { active: false, layout: null };
        }
    }
    createDraggableStrict(first, second, separator, direction, overrideEvent = null) {
        const EVENT_DRAG_CALL_INTERVAL = 60;
        const eventKey = overrideEvent || this.generateEventKey();
        var md = null;
        first.classList.add('aci-draggable-view');
        second.classList.add('aci-draggable-view');
        let lastDragEventInvoke = 0;
        separator.onmousedown = onMouseDown;
        function onMouseDown(e) {
            //console.log("mouse down: " + e.clientX);
            md = {
                e,
                offsetLeft: separator.offsetLeft,
                offsetTop: separator.offsetTop,
                firstWidth: first.offsetWidth,
                secondWidth: second.offsetWidth
            };
            document.onmousemove = onMouseMove;
            document.onmouseup = () => {
                Events.tryInvoke(eventKey);
                return document.onmousemove = document.onmouseup = null;
            };
        }
        function onMouseMove(e) {
            var delta = {
                x: e.clientX - md.e.clientX,
                y: e.clientY - md.e.clientY
            };
            if (direction === "H") {
                delta.x = Math.min(Math.max(delta.x, -md.firstWidth), md.secondWidth);
                separator.style.left = md.offsetLeft + delta.x + "px";
                first.style.width = (md.firstWidth + delta.x) + "px";
                second.style.width = (md.secondWidth - delta.x) + "px";
            }
            else {
                console.warn(`Not Implemented Yet!`);
            }
            if (performance.now() - lastDragEventInvoke > EVENT_DRAG_CALL_INTERVAL) {
                Events.tryInvoke(EVENTS.CONTENT_DRAG);
                lastDragEventInvoke = performance.now();
            }
        }
        return eventKey;
    }
}
