export class AClusterIcon extends google.maps.OverlayView {
    constructor(cluster, styles, opt_padding) {
        super();
        this.styles_ = styles || [];
        this.padding_ = opt_padding || 0;
        this.cluster_ = cluster;
        this.center_ = null;
        this.map_ = cluster.getMap();
        this.div_ = null;
        this.visible_ = false;
        this.setMap(this.map_);
    }
    /**
     * Triggers the clusterclick event and zoom's if the option is set.
     */
    triggerClusterClick() {
        var markerClusterer = this.cluster_.getMarkerClusterer();
        // Trigger the clusterclick event.
        google.maps.event.trigger(markerClusterer, 'clusterclick', this.cluster_);
        if (markerClusterer.isZoomOnClick()) {
            // Zoom into the cluster.
            this.map_.fitBounds(this.cluster_.getBounds());
        }
    }
    /**
     * Adding the cluster icon to the dom.
     * @ignore
     */
    onAdd() {
        this.div_ = document.createElement('DIV');
        if (this.visible_) {
            var pos = this.getPosFromLatLng_(this.center_);
            this.div_.style.cssText = this.createCss(pos);
            this.div_.innerHTML = this.sums_.text;
        }
        var panes = this.getPanes();
        panes.overlayMouseTarget.appendChild(this.div_);
        this.div_.addEventListener('click', () => {
            this.triggerClusterClick();
        });
        // google.maps.event.addDomListener(this.div_, 'click', () => {
        //   this.triggerClusterClick()
        // })
    }
    /**
     * Returns the position to place the div dending on the latlng.
     *
     * @param latlng The position in latlng.
     * @return The position in pixels.
     * @private
     */
    getPosFromLatLng_(latlng) {
        var pos = this.getProjection().fromLatLngToDivPixel(latlng);
        // @ts-ignore
        pos.x -= parseInt(this.width_ / 2, 10);
        // @ts-ignore
        pos.y -= parseInt(this.height_ / 2, 10);
        return pos;
    }
    /**
     * Draw the icon.
     * @ignore
     */
    draw() {
        if (this.visible_) {
            var pos = this.getPosFromLatLng_(this.center_);
            this.div_.style.top = pos.y + 'px';
            this.div_.style.left = pos.x + 'px';
        }
    }
    /**
     * Hide the icon.
     */
    hide() {
        if (this.div_) {
            this.div_.style.display = 'none';
        }
        this.visible_ = false;
    }
    /**
     * Position and show the icon.
     */
    show() {
        if (this.div_) {
            var pos = this.getPosFromLatLng_(this.center_);
            this.div_.style.cssText = this.createCss(pos);
            this.div_.style.display = '';
        }
        this.visible_ = true;
    }
    /**
     * Remove the icon from the map
     */
    remove() {
        this.setMap(null);
    }
    /**
     * Implementation of the onRemove interface.
     * @ignore
     */
    onRemove() {
        if (this.div_ && this.div_.parentNode) {
            this.hide();
            this.div_.parentNode.removeChild(this.div_);
            this.div_ = null;
        }
    }
    /**
     * Set the sums of the icon.
     *
     * @param sums The sums containing:
     *   'text': (string) The text to display in the icon.
     *   'index': (number) The style index of the icon.
     */
    setSums(sums) {
        this.sums_ = sums;
        this.text_ = sums.text;
        this.index_ = sums.index;
        if (this.div_) {
            this.div_.innerHTML = sums.text;
        }
        this.useStyle();
    }
    /**
     * Sets the icon to the the styles.
     */
    useStyle() {
        var index = Math.max(0, this.sums_.index - 1);
        index = Math.min(this.styles_.length - 1, index);
        var style = this.styles_[index];
        this.url_ = style['url'];
        this.height_ = style['height'];
        this.width_ = style['width'];
        this.textColor_ = style['textColor'];
        this.anchor_ = style['anchor'];
        this.textSize_ = style['textSize'];
        this.backgroundPosition_ = style['backgroundPosition'];
    }
    /**
     * Sets the center of the icon.
     *
     * @param center The latlng to set as the center.
     */
    setCenter(center) {
        this.center_ = center;
    }
    /**
     * Create the css text based on the position of the icon.
     *
     * @param pos The position.
     * @return The css style text.
     */
    createCss(pos) {
        var style = [];
        // @ts-ignore
        style.push('background-image:url(' + this.url_ + ');');
        var backgroundPosition = this.backgroundPosition_ ? this.backgroundPosition_ : '0 0';
        // @ts-ignore
        style.push('background-position:' + backgroundPosition + ';');
        if (typeof this.anchor_ === 'object') {
            if (typeof this.anchor_[0] === 'number' && this.anchor_[0] > 0 &&
                this.anchor_[0] < this.height_) {
                // @ts-ignore
                style.push('height:' + (this.height_ - this.anchor_[0]) +
                    'px; padding-top:' + this.anchor_[0] + 'px;');
            }
            else {
                // @ts-ignore
                style.push('height:' + this.height_ + 'px; line-height:' + this.height_ +
                    'px;');
            }
            if (typeof this.anchor_[1] === 'number' && this.anchor_[1] > 0 &&
                this.anchor_[1] < this.width_) {
                // @ts-ignore
                style.push('width:' + (this.width_ - this.anchor_[1]) +
                    'px; padding-left:' + this.anchor_[1] + 'px;');
            }
            else {
                // @ts-ignore
                style.push('width:' + this.width_ + 'px; text-align:center;');
            }
        }
        else {
            // @ts-ignore
            style.push('height:' + this.height_ + 'px; line-height:' +
                this.height_ + 'px; width:' + this.width_ + 'px; text-align:center;');
        }
        var txtColor = this.textColor_ ? this.textColor_ : 'black';
        var txtSize = this.textSize_ ? this.textSize_ : 11;
        // @ts-ignore
        style.push('cursor:pointer; top:' + pos.y + 'px; left:' +
            pos.x + 'px; color:' + txtColor + '; position:absolute; font-size:' +
            txtSize + 'px; font-family:Arial,sans-serif; font-weight:bold');
        return style.join('');
    }
}
