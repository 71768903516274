export class ADomEventService {
    constructor() {
        this.elements = {};
    }
    get $container() {
        return $('#ADomEvents');
    }
    /**
     * Whether the collection has an entry with the given id
     * @param {string} id
     */
    hasId(id) {
        return this.elements.hasOwnProperty(id);
    }
    /**
     * Get element by id
     * @param {string} id
     */
    get(id) {
        return this.elements[id];
    }
    /**
     * Adds element to container & collection
     * Upon existing id, old element will be removed
     * @param {string} id
     * @param {Element|JQuery} ele
     */
    add(id, ele) {
        const { $container, elements } = this;
        const $ele = ((ele instanceof jQuery) ? ele : $(ele));
        $container.append($ele);
        if (this.hasId(id)) {
            this.remove(id);
        }
        elements[id] = $ele;
    }
    /**
     * Removes element from container & collection
     * @param {string} id
     */
    remove(id) {
        const $entry = this.get(id);
        delete this.elements[id];
        return $entry.detach();
    }
}
