import { AError } from "../../classes/AError.js";
import { sleep } from "../../core/AEngine.js";
import { ATemplates } from "../../core/ATemplateService.js";
import { AConvertToGridColumns, AConvertToGridData, AShowTable } from "../../utils/tools.js";
export class APage {
    constructor() {
        FilterManager.load();
        $('#RefreshButton').on('click', _ => this.refresh());
        this.refresh(true);
    }
    get query() {
        return `SELECT FromDevice device, CAST(substr(JSON_EXTRACT(message, '$.Content.DebugData.VersionCode'),2,2) AS UNSIGNED) version
            FROM Log
            JOIN (
                SELECT max(LogIndex) idx from log
                WHERE
                JSON_EXTRACT(message, '$.Source') = 'SystemData'
                AND substr(FromDevice, 1, 3) = 'Pda'
                AND cast(substr(FromDevice, 4,3) as UNSIGNED) < 100
                GROUP BY FromDevice
            ) i
            ON i.idx = LogIndex
            ORDER BY cast(substr(FromDevice, 4,3) as UNSIGNED)
            LIMIT :Limit`;
    }
    refresh(first) {
        FilterManager.setActive(false);
        const { Limit } = FilterManager.save();
        return Loading.waitForPromises(requestService.query({
            Query: this.query,
            Params: {
                FromDate: new Date('1970-01-01'),
                ToDate: new Date(),
                Limit
            },
            Language: Language,
            Translate: ["Device", "Version"]
        })).then(async (response) => {
            FilterManager.setActive(true);
            // Check whether there a result for the current query
            if (response.Rows.length == 0 && !first) {
                return Alerts.noResults();
            }
            this.grid = AShowTable({
                appendTo: 'table-bryntum',
                columns: AConvertToGridColumns(response),
                data: AConvertToGridData(response)
            });
            // Loading is too fast to see the loading bar
            return sleep(100);
        }).catch(AError.handle);
    }
}
export function render() {
    return ( /*html*/`
    <div id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column col-12">
        <div class="form-group">
          <label class="form-label" for="Limit">Max results</label>
          <input class="form-input" type="number" id="Limit" value="2000">
        </div>
      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </div>
    <div id="Rapport" class="flex-child bryntum-container has-footer-2">
      <div template="${ATemplates.WaitingForInput}"></div>
      <div id="table-bryntum" class="hidden"></div>

      <div class="columns footer aci">
        <div class="column col-2">
          <div id="count" class="text">Viewing <span>0</span> Devices</div>
        </div>
        <div class="column col-2 col-ml-auto">
          <button id="export" class="btn btn-primary col-12" disabled="disabled">Export</button>
        </div>
      </div>
    </div>
  `);
}
