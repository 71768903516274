import { EVENTS } from "../../services/AEventService.js";
import { AExclusionArray } from "./AExclusionArray.js";
export class ADropDown {
    constructor($ddw) {
        this.$ddw = $ddw;
        this.$dd = $ddw.find('.dropdown');
        this.opts = $ddw.find('ul.dropdown > li');
        this.title = $ddw.find('> span');
        this.values = {};
        this.initEvents();
    }
    get allowNone() {
        return !this.$ddw.hasClass('dd-disallow-none');
    }
    validate() {
        return (this.allowNone) ? true : this.selectedKeys.length > 0; // !this.$ddw.hasClass('is-error') 
    }
    isDisabled() {
        return this.$ddw.is('.disabled') || this.$ddw.closest('.disabled').length > 0;
    }
    initEvents() {
        const self = this;
        const { $ddw, $dd } = this;
        $ddw.on('dropdown.change', (e) => {
            // console.log(e)
        });
        $ddw.on('click', (e) => {
            const $t = $(e.target);
            // Open & close dropdown list
            const isLoading = this.isDisabled();
            $ddw.prop('disabled', isLoading);
            if (!isLoading && $t.closest('.dropdown').length === 0) {
                $ddw.toggleClass('active');
            }
            if ($ddw.hasClass('active')) {
                let { left, top } = $ddw.offset();
                top += $ddw.outerHeight();
                const bodyHeight = $('body').height() || 0;
                const ddHeight = $dd.height() || 0;
                if (top + ddHeight > bodyHeight) {
                    top = bodyHeight - ddHeight;
                }
                const bodyWidth = $('body').width() || 0;
                const ddWidth = $dd.width() || 0;
                if (left + ddWidth > bodyWidth) {
                    left = bodyWidth - ddWidth;
                }
                $dd.css('position', 'fixed');
                $dd.css('left', left + 'px');
                $dd.css('top', top + 'px');
                $dd.css('right', 'auto');
                $dd.css('bottom', 'auto');
                // $dd.css('min-width', `${$ddw.width()||0}px`)
            }
            e.stopPropagation();
        });
        $(document).on('mouseup.dropdown', (e) => {
            const $clicked = $(e.target);
            const $found = $clicked.closest(`.wrapper-dropdown:not(.tree-config)`);
            if ($ddw.hasClass('active') && ($found.length === 0 || !$found.is($ddw))) {
                if (this.prevSelectedKeys.join(',') !== this.selectedKeys.join(',')) {
                    $ddw.trigger('dropdown.change');
                    this.prevSelectedKeys = this.selectedKeys;
                }
                $ddw.removeClass('active');
            }
        });
        Events.once(EVENTS.DESTRUCT, () => {
            $(document).off('mouseup.dropdown');
        });
        this.opts.find('label').each((i, ele) => {
            const $label = $(ele);
            const $checkbox = $label.find('input[type=checkbox]');
            if ($checkbox.attr('key')) {
                Object.assign(self.values, { [$checkbox.attr('key')]: $checkbox.prop('checked') });
            }
            $label.on('click', function (e) {
                var opt = $(this).parent(), chbox = opt.find('input'), key = chbox.attr('key'), val = chbox.prop('checked');
                if (key !== undefined && e.eventPhase === 3) {
                    Object.assign(self.values, { [key]: val });
                    self.updateState();
                }
            });
        });
        $ddw.data('DropDown', self);
        this.prevSelectedKeys = this.selectedKeys;
        this.updateState();
    }
    selectAll() {
        Object.keys(this.values).map(key => {
            if (key === undefined)
                return;
            this.values[key] = true;
            const $checkboxes = this.$ddw.find(`[key='${key}']`);
            $checkboxes.prop('checked', true);
        });
        this.updateState();
    }
    selectNone() {
        Object.keys(this.values).map(key => {
            if (key !== undefined) {
                this.values[key] = false;
                const $checkboxes = this.$ddw.find(`[key='${key}']`);
                $checkboxes.prop('checked', false);
            }
        });
        this.updateState();
    }
    restoreState(filterArrOrObj, opt) {
        let keys = this.opts.find('[key]').map((i, ele) => $(ele).attr('key')).toArray();
        let filters = {};
        if (filterArrOrObj !== null) {
            if (Array.isArray(filterArrOrObj)) {
                if (opt?.useKeysWithoutPrefix === true) {
                    keys.map(key => filters[key] = filterArrOrObj.includes(key.split('->').pop()));
                }
                else {
                    keys.map(key => filters[key] = filterArrOrObj.includes(key));
                }
            }
            else {
                filters = filterArrOrObj;
            }
            for (const key of keys) {
                if (filters.hasOwnProperty(key)) {
                    const $checkbox = this.$ddw.find(`[key="${key}"]`);
                    const val = filters[key];
                    $checkbox.prop('checked', val);
                    Object.assign(this.values, { [key]: val });
                }
            }
            this.updateState();
        }
        else {
            this.selectAll();
        }
        this.prevSelectedKeys = this.selectedKeys;
        return this;
    }
    translatedUsingDom(key) {
        return this.$ddw.find(`input[type=checkbox][key="${key}"]`).parent().text().trim();
    }
    stripPrefix(key) {
        return key.split('->').pop();
    }
    updateState() {
        const keys = Object.keys(this.selected);
        if (!keys.length) {
            this.$ddw.toggleClass('is-error', !this.allowNone);
            return this.title.text(Translate.getCache('None'));
        }
        if (!this.allowNone) {
            this.$ddw.toggleClass('is-error', false);
        }
        const optionsLength = Object.keys(this.values).length;
        if (optionsLength === keys.length) {
            return this.title.text(Translate.getCache('All'));
        }
        const output = keys.map(k => this.translatedUsingDom(k)).filter(v => v != '').join(', ');
        this.title.text(output);
    }
    get selected() {
        const copy = Object.assign({}, this.values);
        Object.keys(copy).map((key) => {
            if (copy[key] === false) {
                delete copy[key];
            }
        });
        return copy;
    }
    get notSelected() {
        const copy = Object.assign({}, this.values);
        Object.keys(copy).map((key) => {
            if (copy[key] === true) {
                delete copy[key];
            }
        });
        return copy;
    }
    get selectedTexts() {
        const { selected } = this;
        return Object.keys(selected).map(v => this.translatedUsingDom(v));
    }
    get selectedKeys() {
        const { values } = this;
        return Object.keys(values).filter((key) => values[key] === true);
    }
    get selectedKeysWithoutPrefix() {
        return this.selectedKeys.map(key => {
            return key.split('->').pop();
        });
    }
    get selectedTextsQuery() {
        const { notSelected, selected, values } = this;
        const selectedLength = Object.keys(selected).length;
        const optionsLength = Object.keys(values).length;
        if (selectedLength == optionsLength) {
            return '%';
        }
        const half = Math.floor(optionsLength / 2);
        if (selectedLength > half) {
            return new AExclusionArray().concat(Object.keys(notSelected).map(v => this.stripPrefix(v)));
        }
        return Object.keys(selected).map(v => this.stripPrefix(v));
    }
}
