import { ACrypto } from "../classes/ACrypto.js";
import { CHART_TYPE_ENUM } from "../classes/ADetectionStatistics.js";
import { AError } from "../classes/AError.js";
import { APagination } from "../classes/APagination.js";
import { AStatisticsChart } from "../charts/AStatisticsChart.js";
import { AEngine } from "../core/AEngine.js";
import { AAutoRefreshService, LoopLifeCycle } from "../services/AAutoRefreshService.js";
import { AStatisticsService } from "../services/AStatisticsService.js";
import { AKpiSystem } from "../kpi/AKpiSystem.js";
import { AKpiBlockSessions } from "../kpi/AKpiBlockSessions.js";
export class APage {
    constructor() {
        this.SessionMarkers = {};
        this.USE_FAKE_AREAS = false;
        this.USE_FAKE_DATE = false;
        this.autoRefreshService = AEngine.get(AAutoRefreshService);
        this.statisticsService = AEngine.get(AStatisticsService);
    }
    get CurrentView() {
        return 'Operations';
    }
    async init() {
        try {
            $('#state-queues').hide();
            // const queueDataFilters = (this.USE_FAKE_DATE) ? { FromDate: '2020-11-13', ToDate: '2022-08-14' } : FilterManager.betweenLastMonthAndNow
            // this.fetchService.fetchQueueData(queueDataFilters, {
            //     useFakeAreas: this.USE_FAKE_AREAS
            // }).then(queueData => {
            //     this.queueData = queueData
            //     this.initPagination()
            // }).catch(AError.handle)
            this.translations = await Translate.get([
                "Scans", "GeoQueue", "ImageQueue", "PRDBQueue", "PdaVerificationQueue", "BackOfficeVerificationQueue", "AssignedPdaVerifications", "AssignedBackofficeVerifications", "StorageQueue", "Memory", "Session Timeline", "ScanDevice", "Duration", "Online", "Offline", "From", "To"
            ]);
            this.kpiSystem = new AKpiSystem({
                allowEdit: true,
                showSubtitle: true,
                getView: () => this.CurrentView,
                onWidgetCreated: (widget) => {
                    routeService.reload();
                },
                onWidgetDeleted: (widget) => {
                    routeService.reload();
                },
                isOperationsPage: true,
                $container: $('.kpis .configurable-kpis'),
                $showIfEmpty: $(),
                $btnCreate: $('#create-kpi'),
                widgets: await widgetService.fetchActiveWidgets({ View: this.CurrentView })
            });
            this.kpiSessions = new AKpiBlockSessions({
                kpiSystem: this.kpiSystem,
                KpiType: 'AKpiBlockSessions',
                Name: 'Sessions',
                Width: 'col-12',
                querySelector: '.sessions-kpi-block',
                listenToSessions: true,
                autoReflow: false,
                blockList: ('.kpi-block-list'),
                blockWidth: 'col-4'
            });
            // this.kpiSessions.$kpiBlock.html(await this.kpiSessions.render())
            await this.kpiSystem.loadAll({
                widgets: await widgetService.fetchActiveWidgets({ View: this.CurrentView }),
                clearContainer: true
            });
            await this.kpiSystem.constructSingle(this.kpiSessions, $('.footer'));
            await this.kpiSystem.invokeRefresh(this.kpiSessions, undefined, { userInput: false, enableLoadingIndicators: true });
            // await this.kpiSessions.ensureViewApplied()
            // this.kpiSessions.refresh()
            this.autoRefreshService.startLoop({
                call: async () => {
                    await this.kpiSystem.invokeRefreshAll({ userInput: false, enableLoadingIndicators: false });
                    await this.kpiSystem.invokeRefresh(this.kpiSessions, undefined, { userInput: false, enableLoadingIndicators: false });
                    // await this.kpiSessions.ensureViewApplied()
                    // this.kpiSessions.refresh()
                },
                lifecycle: LoopLifeCycle.PAGE,
                context: this,
                interval: 5000,
                args: []
            });
            $('#BuildVersion').text(Config ? Config.ServerBuildInfo || '-' : '-');
        }
        catch (err) {
            AError.handle(err);
        }
    }
    // Queues
    initPagination() {
        const $tabgroup = $('[tabgroup="queue-charts"]');
        const visibleTab = $tabgroup.find('.active[tab]').attr('tab');
        const loaded = [visibleTab];
        // @ts-ignore
        this.loadPaginationView({ tabview: visibleTab });
        $tabgroup.on('ACI_TABS_CHANGED', (e, { tabview }) => {
            if (!loaded.includes(tabview)) {
                loaded.push(tabview);
                this.loadPaginationView({ tabview });
            }
        });
    }
    loadPaginationView({ tabview }) {
        const options = {
            'area': () => {
                return new APagination({
                    $pagesParent: $('[tabview="area"] .pagination-views'),
                    $parent: $('[tabview="area"] .pagination-nav'),
                    page: 1,
                    pageCount: Math.ceil(Object.keys(this.queueData.Areas).length / 3.0),
                    load: ({ $view, page }) => this.displayQueuePerArea(this.queueData.Areas, $view, page - 1)
                });
            },
            'user': () => {
                return new APagination({
                    $pagesParent: $('[tabview="user"] .pagination-views'),
                    $parent: $('[tabview="user"] .pagination-nav'),
                    page: 1,
                    pageCount: Math.ceil(Object.keys(this.queueData.DetectionUsers).length / 3.0),
                    load: ({ $view, page }) => this.displayQueuePerUser(this.queueData.DetectionUsers, $view, page - 1)
                });
            }
        };
        if (!options.hasOwnProperty(tabview)) {
            throw new Error(`Tabview "${tabview}" not found! Expected one of: ${JSON.stringify(Object.keys(options))}`);
        }
        const areaPagination = options[tabview]();
        return areaPagination;
    }
    // Queues
    displayQueuePerArea(Areas, $view, page) {
        const allKeys = Object.keys(Areas).sort();
        const keys = allKeys.slice(page * 3, (page * 3) + 3);
        keys.map(area => {
            const $chartParent = $(`<div class="column col-4"></div>`);
            $view.append($chartParent);
            const chartOptions = {
                id: 'CHART_' + ACrypto.randomHexString([0, 0, 0, 0, 0, 0]),
                title: area,
                statistics: Areas[area],
                chartType: CHART_TYPE_ENUM.DETECTION_STATE,
                wrap: true,
                $wrapParent: $chartParent
            };
            return new AStatisticsChart(chartOptions)
                .setPrefferedHeight('300px').show();
        });
    }
    // Queues
    displayQueuePerUser(DetectionUsers, $view, page) {
        const allKeys = Object.keys(DetectionUsers).sort();
        const keys = allKeys.slice(page * 3, (page * 3) + 3);
        keys.map(user => {
            const $chartParent = $(`<div class="column col-4"></div>`);
            $view.append($chartParent);
            const chartOptions = {
                id: 'CHART_' + ACrypto.randomHexString([0, 0, 0, 0, 0, 0]),
                title: user,
                statistics: DetectionUsers[user],
                chartType: CHART_TYPE_ENUM.DETECTION_STATE,
                wrap: true,
                $wrapParent: $chartParent
            };
            return new AStatisticsChart(chartOptions)
                .setPrefferedHeight('300px').show();
        });
    }
}
export function render() {
    return ( /*html*/`
    <div class="kpis flex-child bryntum-container has-footer-3" style="position: relative;">
      <div class="flex-content panel-padding">
        <div class="custom-scroll">
          <div class="columns configurable-kpis">
            <div class="column col-12">
            </div>
          </div>

          <div class="columns col-gapless">
            <div class="column col-12">
              <div class="columns session-kpis kpi-block-list"></div>
            </div>
          </div>
        </div>


        <div class="columns" style="margin-top: 15px;">
          <div class="column col-3 col-mx-auto">
            <button id="create-kpi" class="btn btn-primary fw">Add Widget</button>
          </div>
        </div>
        
        <div class="columns col-gapless">
          <div class="column col-10 col-mx-auto v-padding">
            <sub id="BuildVersion" class="text-center"></sub>
            <br>
          </div>
        </div>
      </div>

      <div class="columns col-gapless footer aci custom-scroll">
        <div class="column col-12">
        </div>
      </div>
    </div>

    <div class="menu-kpi sidebar-popover">
      <ul>
        <li class="kpi-label kpi-label-actions noselect">
          <a href="#" class="noselect">Actions</a>
        </li>
        <li>
          <a href="#">
            <i class="fa-solid fa-expand"></i>
            Fullscreen
          </a>
        </li>
        <li class="kpi-label kpi-label-filters noselect">
          <a href="#" class="noselect">Filters</a>
        </li>
        <li class="kpi-label kpi-label-settings noselect">
          <a href="#" class="noselect">Settings</a>
        </li>
      </ul>
    </div>
  `);
}
