import { AEngine } from "../core/AEngine.js";
import { ADynamicChartUtils } from "../charts/ADynamicChartUtils.js";
import { AStatisticsService } from "../services/AStatisticsService.js";
import { AConvertToGridData, AShowTable, AConvertToGridColumns, hoursToHHMMSS, AFormatNumber, ARound } from "../utils/tools.js";
import { AKpiBlock, AKpiState } from "./AKpiBlock.js";
import { AChartTooltipFormatter, MakeColumnChart, QueryResponseToSeries, ResizeChart } from "../charts/charts.js";
import { EVENTS } from "../services/AEventService.js";
export class AKpiBlockSanctionsAdvanced extends AKpiBlock {
    get chartId() { return `${this.idFilter}-Chart`; }
    get tableId() { return `${this.idFilter}-Table`; }
    constructor(opt) {
        super({
            TranslateTitle: true,
            refreshImplemented: true,
            optionsImplemented: true,
            tableImplemented: true,
            ...opt
        });
        this.chartUtils = new ADynamicChartUtils();
        this.groupBy = opt.Settings['GroupBy'];
        Events.on(EVENTS.CONTENT_RESIZE, this.resize.bind(this));
    }
    isMounted() {
        return this.chart !== undefined && Object.keys(this.chart).length > 0 && this.chart.container.isConnected === true;
    }
    resize() {
        if (this.isMounted()) {
            ResizeChart(this.chart, super.getSize(), true, { silentErrors: true });
        }
    }
    toggleTableView() {
        this.viewTable = !this.viewTable;
        this.$kpiView.find('[filter]').toArray().map(e => $(e)).map($view => {
            $view.toggleClass('hidden', $view.attr('filter') !== (this.viewTable ? 'table' : 'chart'));
        });
        Loading.waitForPromises([
            this.refreshTable(),
            this.refreshChart(),
        ]).catch(err => {
            this.errorOccurred(err);
        });
    }
    async fetchChartData(filters) {
        const statisticService = AEngine.get(AStatisticsService);
        const mapTo = await this.chartUtils.genStatisticsHashmapper(this.groupBy, {
            mapGeoUsingSegmentId: false
            // groups: ['DetectionDeviceId'],
            // sort: opt.sort
        });
        const { FirstIndex, LastIndex } = verificationRef.Options.Fined;
        const hardFilters = {
            FirstIndex,
            LastIndex,
            DetachedFromPda: detectionStateRef.Options.InProgress.Options.DetachedFromPda.FirstIndex,
            DetachedFromCentralVerification: detectionStateRef.Options.InProgress.Options.DetachedFromCentralVerification.FirstIndex,
        };
        const fetchRes = await statisticService.fetch({
            ...hardFilters,
            ...filters,
            Unknown: Translate.getCache('unknown')
        }, {
            skipSegmentsQuery: this.groupBy === 'VerificationDeviceType',
            baseTable: ( /*SQL*/`(
        SELECT
          dfh.*,
          (CASE
            WHEN DetectionState=:DetachedFromPda THEN 'Pda'
            WHEN DetectionState=:DetachedFromCentralVerification THEN 'CentralVerification'
            ELSE :Unknown
          END) AS VerificationDeviceType
        FROM detections_final_history dfh
      )`),
            extraWhereClause: 'df.Verification BETWEEN :FirstIndex AND :LastIndex AND df.DetectionState IN (:DetachedFromCentralVerification, :DetachedFromPda)',
            groups: [this.groupBy],
            mapTo: mapTo,
        });
        // Object.keys(fetchRes.statistics).map(key => {
        //   const stats = fetchRes.statistics[key]
        //   console.log(key, 'sanctions', stats.getSanctions())
        // })
        // console.log('fetchRes', fetchRes)
        if (fetchRes.processedDetections === 0) {
            return { hasData: false, chartData: { Columns: [], ColumnsTranslated: [], Rows: [] } };
        }
        const Columns = ['Bars', ...Object.values(verificationRef.Options.Fined.Options).map(v => v.Key)];
        const translations = await Translate.get(Columns);
        const res = {
            Columns: Columns,
            ColumnsTranslated: Columns.map(c => translations[c]),
            Rows: Object.keys(fetchRes.statistics).map(key => {
                const stats = fetchRes.statistics[key];
                if (stats.Verification.Options.Fined.Count === 0) {
                    return null;
                }
                return [
                    key,
                    ...Object.values(stats.Verification.Options.Fined.Options).map(v => v.Count)
                ];
            }).filter(v => v !== null)
        };
        return { hasData: res.Rows.length > 0, chartData: res };
    }
    async refresh(filters = this.filterOption) {
        const { hasData, chartData } = await this.fetchChartData(filters);
        if (!hasData) {
            return AKpiState.noResults;
        }
        this.cachedChartData = chartData;
        await Promise.all([
            this.refreshTable(),
            this.refreshChart(),
        ]);
    }
    async refreshChart() {
        if (this.viewTable || !this.cachedChartData) {
            return;
        }
        const chartData = this.cachedChartData;
        const unit = 'Sanctions';
        const label = 'Sanctions';
        const chartType = 'bar';
        const inverted = true;
        const series = QueryResponseToSeries(chartData, { chartType, unit });
        const formatter = await AChartTooltipFormatter({
            unit,
            label,
            addPercentage: false,
            addTotal: true,
            hideZeroValues: true
        });
        const categories = chartData.ColumnsTranslated.slice(1);
        // const categories = hSelection.Translate || hSelection.TransformData ? chartData.ColumnsTranslated.slice(1) : chartData.Columns.slice(1)
        const chart = this.chart;
        const isMounted = this.isMounted();
        if (!isMounted) {
            this.chart = await MakeColumnChart({
                bind: this.chartId,
                type: chartType,
                inverted: inverted,
                polar: false,
                series: series,
                allowExport: false,
                flat: true,
                unit: unit,
                xAxis: { categories },
                yAxis: { title: { text: unit } },
                tooltip: { formatter },
                legend: { enabled: true },
                // translate: hSelection.Translate,
                // ...vSelection,
            });
        }
        else {
            chart.update({
                chart: {
                    type: chartType,
                    inverted: inverted,
                    polar: false,
                },
                series: series.map(s => {
                    // If new serie is added, make sure it's visible!
                    const foundSerie = chart?.series?.find(oldSerie => oldSerie.name === s.name);
                    if (foundSerie !== undefined) {
                        s.visible = foundSerie.visible ?? true;
                    }
                    return s;
                }),
                xAxis: { categories },
                yAxis: { title: { text: label } },
                tooltip: { formatter },
                // plotOptions,
                legend: { enabled: true },
            }, true, true, true);
        }
        // this.chart?.setSize(this.getWidthFromKpi(), this.getHeightFromKpi())
        return { chartData, hasData: chartData.Rows.length > 0, instantiated: !isMounted };
    }
    async refreshTable() {
        if (!this.viewTable || !this.cachedChartData) {
            return;
        }
        const data = AConvertToGridData(this.cachedChartData);
        // const { Vertical } = this.chartOptions
        if (this.grid) {
            const scrollTop = this.grid.storeScroll();
            this.grid.store.data = data;
            this.grid.restoreScroll(scrollTop);
        }
        else {
            this.grid = AShowTable({
                appendTo: this.tableId,
                columns: AConvertToGridColumns(this.cachedChartData, {
                    'Bars': { text: '' },
                    OperationHours: { htmlEncode: false, renderer: ({ value }) => { return value ? hoursToHHMMSS(value) : ''; } },
                    ScanHours: { htmlEncode: false, renderer: ({ value }) => { return value ? hoursToHHMMSS(value) : ''; } },
                    DistanceKM: { htmlEncode: false, renderer: ({ value }) => { return value ? AFormatNumber(ARound(value, 2)) + ' km' : ''; } },
                }),
                data: data
            });
        }
        // this.cachedChartData = undefined
    }
    async render() {
        return await super.renderView({
            title: this.Name,
            viewHtml: ( /*html*/`
        <div filter="chart" class="fh">
          <div id="${this.chartId}" class="fh"></div>
        </div>
        <div filter="table" class="fh pb-2 hidden">
          <div id="${this.tableId}" class="fh"></div>
        </div>
      `),
        });
    }
}
