import { ADetectionStateSnapshot } from "../classes/ADetectionStateSnapshot.js";
import { APascal, autoReflowChart } from "../utils/tools.js";
import { AKpiBlock } from "./AKpiBlock.js";
export class AKpiBlockServerChart extends AKpiBlock {
    get viewType() {
        return this.$filter.val();
    }
    constructor(opt) {
        super({
            refreshImplemented: false,
            optionsImplemented: true,
            ...opt
        });
        this.viewTypes = ['all', 'process', 'operational'];
        this._viewType = (opt.Settings['viewType'] || 'all').toLowerCase().split('|').pop().trim();
        if (!this._viewType || !this.viewTypes.includes(this._viewType)) {
            this._viewType = this.viewTypes[0];
        }
        this.detectionStateSnapshots = [
            new ADetectionStateSnapshot({ interval: 5 }),
            new ADetectionStateSnapshot({ interval: 5 }),
            new ADetectionStateSnapshot({ interval: 5 })
        ];
    }
    async init() {
        super.init();
        this.$filter.on('change', (e) => {
            const filterVal = this.viewType;
            // const visibleChartId = `${this.idFilter}-${this.$filter.val()! as string}`
            const $chartWrapperArr = this.$kpiView.find('.kpi-chart').toArray().map(e => $(e));
            $chartWrapperArr.map($chart => {
                $chart.toggleClass('hidden', $chart.attr('filter') !== filterVal);
                console.log($chart.attr('filter') + '!==' + filterVal);
            });
        });
        this.ServerCharts = await Promise.all(this.viewTypes.map((viewType, i) => {
            return this.detectionStateSnapshots[i].makeTimeChart(`${this.idFilter}-${viewType}`, { viewType });
        }));
        this.ServerCharts.map(chart => autoReflowChart(chart));
    }
    async refresh() {
        await Promise.all(this.detectionStateSnapshots.map(snapshot => {
            return snapshot.updateLastHour();
        }));
    }
    async render() {
        return ( /*html*/`
      <div id="${this.idBlock}" class="kpi-block kpi-vert-6">
        <div class="kpi-header">
          <div class="kpi-title">
            <label for="${this.idFilter}" class="legend-label">Control Center</label><span> | ${this._viewType}</span>
          </div>
          <select id="${this.idFilter}" class="legend-label kpi-select">
            ${this.viewTypes.map((viewType) => {
            const attrs = (this._viewType === viewType) ? `selected="selected"` : '';
            return ( /*html*/`<option ${attrs} value="${viewType}">${APascal(viewType)}</option>`);
        }).join('')}
          </select>
        </div>
        <div class="kpi-view">
          ${this.viewTypes.map((viewType) => {
            const clsName = (this._viewType === viewType) ? '' : 'hidden';
            return ( /*html*/`
                <div filter="${viewType}" class="kpi-chart fh ${clsName}">
                  <div id="${this.idFilter}-${viewType}" class="Chart fh"></div>
                </div>
              `);
        }).join('')}
        </div>
      </div>
    `);
    }
}
