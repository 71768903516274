import { AColor } from "./AColor.js";
import { AError } from "./AError.js";
import { AInfoWindowHelper } from "./AInfoWindowHelper.js";
import { AKML } from "./AKML.js";
import { AFormatDate, CreateUtfDownloadUrl } from "../utils/tools.js";
import { ALERT_BUTTONS, ALERT_TITLES } from "../services/AAlertService.js";
import { ALL_GEO_TYPES } from "../core/maps/AMapStructs.js";
export class AExportMap {
    constructor(name, options) {
        this.geoLayers = ALL_GEO_TYPES;
        this.dynLayers = ['Scans', 'Lines'];
        this.name = name;
        this.options = Object.assign({ scales: true, markersArePolygons: true, desc: '...' }, options);
        this.initKmlCreation();
        this.cache = {
            cachePage: null,
            prevQuery: null,
            prevParams: null
        };
    }
    getLayer(layer) {
        return this.dynLayers.includes(layer) ? layer : layer;
    }
    initKmlCreation() {
        const { name, desc } = Object.assign({}, { name: this.name, desc: this.desc });
        this.kml = new AKML(name, desc);
        this.kml.createLayers([...this.geoLayers, ...this.dynLayers]);
    }
    get allowScales() {
        return this.options.scales;
    }
    get desc() {
        return this.options.desc;
    }
    hasCacheQuery() {
        return document.URL === this.cache.cachePage;
    }
    /**
     * Stores last cached query from ARequest.js
     */
    prepareCache() {
        this.cache.cachePage = document.URL;
        this.cache.prevQuery = requestService.prevQuery;
        this.cache.prevParams = requestService.prevParams;
    }
    /**
     * Add markers from the last cached query
     */
    async addMarkersFromCache() {
        if (!this.hasCacheQuery()) {
            return;
        }
        const response = await Loading.waitForPromises(requestService.query({
            Query: this.cache.prevQuery,
            Params: this.cache.prevParams
        }));
        this.kml.addMarkersWithResponse(this.getLayer('Scans'), response, this.options);
    }
    /**
     * Adds array or list of markers to the kml export
     */
    async addMarkers(markers, opt) {
        markers.map(marker => {
            const primaryKey = marker.get('PK');
            const points = mapHelperService.getPoints(marker);
            const color = marker.strokeColor || '#ff00ff';
            const data = (opt?.dontExportData === true) ? {} : primaryKey;
            this.kml.addPolygon(this.getLayer('Scans'), points, data, {
                kmlName: marker.get('kmlName'),
                color: color,
            });
        });
    }
    /**
     * Adds Line String to the kml export
     */
    addLines(lines, opt) {
        lines.map(line => {
            const points = mapHelperService.getPoints(line);
            let color = line.strokeColor || '#ff00ff';
            // TODO: Implement other conversion code
            if (color.startsWith('rgba(')) {
                const rgba = color.substring(color.indexOf('(') + 1, color.indexOf(')'));
                const [r, g, b, a] = rgba.split(',').map(str => Number(str.trim()));
                color = new AColor(r, g, b).hexi;
            }
            const data = (opt?.dontExportData === true) ? {} : line.data;
            this.kml.addLineString(this.getLayer('Lines'), points, data, {
                kmlName: line.get('kmlName'),
                color
            });
        });
    }
    /**
     * Adds Areas, Zones, Parking Spaces, Segments to the kml export if they are visible on the map
     */
    addScalesIfEnabled() {
        if (this.allowScales) {
            return this.addScales();
        }
    }
    /**
     * Adds scales to the kml export
     */
    addScales() {
        // Get Map
        const map = this.options.map ?? PageScript.map;
        // Get all visible layers on map
        const layers = Object.keys(map._geoObjectsVisible)
            .map((key) => (map._geoObjectsVisible[key] === true) ? key : null)
            .filter(v => v != null);
        // Add all visible Geo Layers to kml
        return layers.map((layer) => {
            const collection = mapHelperService.getGeoInstancesOnMap(map, layer);
            for (let polygon of collection) {
                const points = mapHelperService.getPoints(polygon);
                this.kml.addPolygon(layer, points, polygon.data, {
                    kmlName: polygon.get('kmlName'),
                    color: polygon.fillColor
                });
            }
        });
    }
    /**
     * Enables & Rebinds "Export KML" Button
     *
     * Click Handler:
     * * Displays download popup window
     * * Adds markers from cache
     * * Adds Areas, Zones, ParkingSpaces, Segments if enabled
     * * Creates download link using UTF encoding
     */
    async allowExport() {
        // Visual clickable button
        const $visualButton = await AInfoWindowHelper.waitForElement('#DownloadButton');
        // Remove old listeners
        $visualButton.off('click');
        // Add new listener
        $visualButton.on('click', async (_) => {
            try {
                this.addScalesIfEnabled();
                await Loading.waitForPromises(this.addMarkersFromCache());
                await Loading.waitForPromises(this.showDownloadModal(this.kml.name, CreateUtfDownloadUrl(this.stringify({ prettyPrint: true }), "application/kml")));
                userActionService.logAction('USER', 'EXPORT MAP', FilterManager.save({ cacheFilters: false }));
            }
            catch (err) {
                AError.handle(err);
            }
        });
        $visualButton.removeAttr('disabled');
        this.$visualButton = $visualButton;
    }
    async showDownloadModal(title, href) {
        $('#proxyDownload').remove();
        const $functionalButton = $(`<a id="proxyDownload" href="${href}" class="hidden">Download</a>`);
        $functionalButton.attr('download', `${title}_${AFormatDate(new Date(), "__", "_", "_")}.kml`);
        $('body').append($functionalButton);
        this.$functionalButton = $functionalButton;
        const translations = await Translate.get([
            `KML Export`,
            `Download`,
            `KML Export has been created!`,
        ]);
        const filename = `${title}_${AFormatDate(new Date(), "__", "_", "_")}`;
        const html = translations[`KML Export has been created!`];
        const events = Alerts.show({
            title: ALERT_TITLES.Export,
            content: html,
            buttons: ALERT_BUTTONS.downloadCancel
        });
        // Insert download button
        events.$ele.find('#option1').replaceWith(/*html*/ `
      <a id="option1" href="${href}" download="${filename}.kml" class="btn btn-primary">
        ${translations[`Download`]}
      </a>
    `);
        // Click close
        events.$ele.find('#option1').on('click', _ => events.$ele.find('#option2').trigger('click'));
    }
    stringify(opt) {
        return this.kml.stringify(opt);
    }
}
