import { AError } from "./AError.js";
import { AMeterService } from "../services/AMeterService.js";
import { AVerificationService } from "../services/AVerificationService.js";
import { ADurationToSeconds, AInputDateTime, AUrlEncodedImageFromBase64, secondsPassed } from "../utils/tools.js";
import { ROUTES } from "../services/ARouteService.js";
import { AEngine } from "../core/AEngine.js";
import { ALERT_STATUS, ALERT_TITLES } from "../services/AAlertService.js";
import { APhotoHandler } from "./APhotoHandler.js";
export class AControls {
    constructor(actions) {
        this.controls = $(`#Filters .btn, #Filters input`);
        this.actions = {};
        if (actions) {
            this.bindActions(actions);
        }
    }
    get buttons() {
        return this.controls.filter('[type!=text]');
    }
    get canBeDisabled() {
        return this.controls.filter('[canBeDisabled=true]');
    }
    /**
     * @param {boolean} active
     */
    set enabled(active) {
        this.setActive(active);
    }
    startTimerUI($ele, date) {
        this.timerEnabled = true;
        this.timerId = this.timerId ? this.timerId + 1 : 1;
        this.tickTimer($ele, date, this.timerId);
    }
    tickTimer($ele, date, id) {
        let ss = secondsPassed(date, new Date());
        let mm = Math.floor(ss / 60);
        let hh = Math.floor(mm / 60);
        ss = (ss % 60).toString();
        if (ss < 10)
            ss = '0' + ss;
        // @ts-ignore
        mm = (mm % 60).toString();
        // @ts-ignore
        if (mm < 10)
            mm = '0' + mm;
        // @ts-ignore
        hh = Math.min(hh, 99).toString();
        // @ts-ignore
        if (hh < 10)
            hh = '0' + hh;
        const stringified = `${hh}:${mm}:${ss}`;
        $ele.text(stringified);
        setTimeout(_ => {
            if (this.timerEnabled === true && id === this.timerId) {
                this.tickTimer($ele, date, id);
            }
        }, 1000);
    }
    stopTimerUI() {
        this.timerId++;
        this.timerEnabled = false;
        this.previousOldestDate = null;
    }
    bindActions(object, rebind = true) {
        Object.assign(this.actions, object);
        if (rebind) {
            this.rebindActions();
        }
    }
    rebindActions() {
        this.buttons.unbind('click');
        for (let key in this.actions) {
            $(`[data-action="${key}"]`).click(this.actions[key]);
        }
    }
    executeAction(key, e) {
        this.actions[key](e);
    }
    setActive(active) {
        if (active) {
            if (this.canBeDisabled.hasClass('disabled')) {
                this.canBeDisabled.removeClass('disabled');
            }
        }
        else {
            if (!this.canBeDisabled.hasClass('disabled')) {
                this.canBeDisabled.addClass('disabled');
            }
        }
    }
}
export class ADeskControl {
    /**
     * @param {{ isCVS?: boolean, controls: AControls, CVSProcess: ACVSProcess, imageHelper: AImageHelper }} options
     */
    constructor(options) {
        const { controls, CVSProcess, imageHelper, isCVS } = options || {};
        this.isCVS = isCVS;
        this.CVSProcess = CVSProcess;
        this.controls = controls;
        this.photoHandler = new APhotoHandler();
        this.detections = [];
        this.mouse = { x: 0, y: 0 };
        this.hideImageControls();
        this.initModal();
        this.editing = false;
        this.cachedIdentifiers = {};
        this.imageHelper = imageHelper;
        this.photoHandler.imageHelper = this.imageHelper;
        if (this.isCVS) {
            this.initCVSUI().catch(err => {
                // TODO: Remove warning message
                console.warn(`ERROR Might be caused by ini file 'VerifyResultLanguage', try setting it to an empty string`);
                AError.handle(err);
            });
        }
    }
    async initCVSUI() {
        if (Config.EnableBackofficeVerification !== true) {
            return Alerts.show({
                title: ALERT_TITLES.Error,
                content: await Loading.waitForPromises(Translate.get('EnableBackofficeVerification is set to false, Please Contact ACI'))
            }).on(ALERT_STATUS.ON_MODAL_CLOSED, _ => {
                routeService.navigateTo(ROUTES.Home, { setBrowserUrl: true });
            });
        }
        if (!Config.VerifyResultOptions) {
            return Alerts.show({
                title: ALERT_TITLES.Error,
                content: await Loading.waitForPromises(Translate.get('"Config.VerifyResultOptions" is not set!'))
            }).on(ALERT_STATUS.ON_MODAL_CLOSED, _ => {
                routeService.navigateTo(ROUTES.Home, { setBrowserUrl: true });
            });
        }
        const $selectMain = $('select[data-id=reviewResponse].form-main');
        const $selectSub = $('select[data-id=reviewResponse].form-sub');
        $selectMain.html('');
        const verificationService = AEngine.get(AVerificationService);
        const options = verificationService.getOptions();
        const $mainOptions = await Loading.waitForPromises(Object.keys(options).map(async (key) => {
            const mainItem = options[key];
            const mainOption = key;
            const mainOptionText = await Translate.get(key);
            const subOptionCodes = await Promise.all(mainItem.Options.map((subItem) => Translate.get(subItem.Code)));
            const $mainOption = $(`<option value="${mainOption}">${mainOptionText}</option>`);
            $mainOption.on('updateValues', async () => {
                $selectSub.html('');
                if (mainItem.Options != null) {
                    const $subOptions = await Loading.waitForPromises(subOptionCodes.map(async (subOption) => {
                        const subOptionText = await Translate.get(subOption);
                        return $(`<option value="${subOption}">${subOptionText}</option>`);
                    }));
                    $subOptions.map($subOption => $selectSub.append($subOption));
                }
            });
            return $mainOption;
        }));
        $mainOptions.map($mainOption => $selectMain.append($mainOption));
        $selectMain.change(({ target }) => $(target).find('option:selected').trigger('updateValues'));
        $selectMain.find('option:selected').trigger('updateValues');
    }
    get map() {
        return PageScript.map;
    }
    get panorama() {
        return PageScript.panorama;
    }
    get selectedDetectionDom() {
        return $('#detection-list tr.selected');
    }
    get selectedMarker() {
        const data = this.selectedDetectionDom.data();
        if (data !== undefined && data.marker) {
            return data.marker;
        }
        return null;
    }
    get oldestDetectionDate() {
        let oldestDate = Number.MAX_SAFE_INTEGER;
        for (let marker of this.detections) {
            if (marker.Date.getTime() < oldestDate) {
                oldestDate = marker.Date;
            }
        }
        return oldestDate !== Number.MAX_SAFE_INTEGER ? new Date(oldestDate) : undefined;
    }
    get scanVehicleCount() {
        const types = ['ScanAuto', 'ScanScooter', 'ScanSegway', 'ScanBike', 'ScanCam'];
        let count = 0;
        for (let node of Sessions) {
            if (types.includes(node.NodeType) && node.Status !== 'Disconnected') {
                count += 1;
            }
        }
        return count;
    }
    setPreferredImageHeight(val) {
        this.preferredImageHeight = val;
    }
    initModal() {
        const modal = $('.modal');
        const exitButtons = modal.find("[aria-label='Close']");
        exitButtons.click(e => {
            e.preventDefault();
            modal.fadeOut(500);
        });
    }
    initStreetView() {
        google.maps.event.addListener(this.panorama, "position_changed", _ => {
            this.map.setCenter(this.panorama.getPosition());
        });
    }
    createDetectionList(sort) {
        let output = [];
        let detections = this.detections;
        if (sort !== undefined) {
            const { field, ascending } = sort;
            detections.sort((a, b) => {
                let output = 0;
                if (a[field] > b[field]) {
                    output = -1;
                }
                else if (a[field] < b[field]) {
                    output = 1;
                }
                return ascending === true ? output : -output;
            });
        }
        for (let marker of detections) {
            output.push(this.createDetectionElement(marker));
        }
        return output;
    }
    createDetectionElement(marker) {
        const hasImage = marker.LPImage != null && marker.LPImage.length > 0;
        const img = hasImage ? `<img class="lp" src="${AUrlEncodedImageFromBase64(marker.LPImage)}" />` : '';
        const lp = this.controls ? `
            <span data-id="editable" maxlength="10">${marker.LicensePlate}</span>
            <i class="fa fa-pencil hover-visible"></i> ${img}
            ` : `<span>${marker.LicensePlate}</span> ${img}`;
        const meter = this.controls ? (`
            <meter class="meter" value="0" min="0" max="100" low="50" high="80" optimum="10">
                <label class="timeleft" expire="${marker.Date.toJSON()}">${AInputDateTime(marker.Date)}</label>
            </meter>
        `) : '';
        const html = (marker.FineNumber == null || marker.FineNumber.length == 0 || marker.FineNumber == 0) ?
            `<tr>
               <td data-id="${marker.Identifier}">${lp}</td>
               <td>${meter}</td>
           </tr>`
            :
                `<tr>
              <td data-id="${marker.Identifier}">${lp}</td>
              <td>${AInputDateTime(marker.Date)}${meter}</td>
              <td>${marker.FineNumber}</td>
              </tr>`;
        const $ele = $(html);
        $ele.find(`td[data-id] i`).on('click', _ => {
            $ele.find(`td[data-id] span`).trigger('click');
        });
        const meterService = AEngine.get(AMeterService);
        // If is cvs and not advanced scans
        if (this.controls && meterService) {
            const timeout = ADurationToSeconds(Config.ShelfLife.Detection);
            meterService.add(marker.Identifier, {
                $meter: $ele.find('meter'),
                expire: new Date(marker.Date.getTime() + timeout * 1000),
                timeout: timeout,
                onRefresh: ({ $meter, timeleft }) => {
                    const $expireLabel = $meter.parent().find('[expire]');
                    $expireLabel.text(timeleft);
                },
                onFinish: (id) => {
                    // On lifespan ended.
                    const selectNext = this.currentSelectedDetection == marker && this.detections.length > 1;
                    const { Result, ResultText } = PageScript.unhandledOpt;
                    // marker, reason, selectNext, mutateArray, logging
                    this.removeDetection({
                        marker, Result, ResultText, selectNext
                    });
                    if (this.detections.length === 0) {
                        this.controls.enabled = false;
                        this.controls.stopTimerUI();
                    }
                }
            });
            // meterService.add(marker.Identifier, $ele.find('meter'), marker.Date, onRefresh, id => {
            // })
        }
        $ele.data('marker', marker);
        $ele.on('click', (e) => {
            this.markerClick($ele.data('marker'), true);
        });
        this.$lastLabel = $();
        this.lastStartValue = '';
        const $label = $ele.find('[data-id=editable]');
        let startValue = $label.text();
        $label.on('click', (e) => {
            // Prevents label to be selected twice
            if (this.$lastLabel == $label)
                return;
            // Disable last edit when the content hasn't been changed
            if (this.$lastLabel && this.lastStartValue == this.$lastLabel.text()) {
                this.$lastLabel.removeAttr('contenteditable');
                this.$lastLabel.removeAttr('spellcheck');
                this.$lastLabel.removeAttr('style');
                this.$lastLabel.unbind('keydown');
            }
            // Prevents listitem from being selected
            e.stopPropagation();
            $label.attr('contenteditable', 'true');
            $label.attr('spellcheck', 'false');
            $label.css({ 'color': 'red' });
            $label.css({ 'background': 'yellow' });
            $label.focus();
            this.$lastLabel = $label;
            this.lastStartValue = $label.text();
            this.editing = true;
        });
        $label.keydown(e => {
            // Prevents google streetview from taking over the controls (arrow keys)
            e.stopPropagation();
            // If user pressed enter, it'll save the values
            if (e.keyCode === 13) {
                $label.text($label.text());
                if ($label.text().length < 3) {
                    return $label.text('...');
                }
                if ($label.text().length > 32)
                    $label.text($label.text().substr(-32));
                $label.removeAttr('contenteditable');
                $label.removeAttr('spellcheck');
                $label.removeAttr('style');
                $label.unbind('keydown');
                this.editing = false;
                // If License Plate Number has been changed
                if (this.controls && startValue != $label.text()) {
                    $(e.target).data('marker', marker);
                    this.controls.executeAction('ChangeLP', e);
                }
                return false;
            }
        });
        function onKeyPress(e) {
            console.log('1');
            // @ts-ignore
            const max = $label.attr('maxlength') | 32;
            if (['ArrowUp', 'ArrowRight', 'ArrowDown', 'ArrowLeft', 'Backspace'].includes(e.key)) {
                return true;
            }
            if (/[0-9]/g.test(String.fromCharCode(e.which)) && e.shiftKey === false) {
                return true;
            }
            if ((!/[a-zA-Z]/g.test(String.fromCharCode(e.which)) || $(e.target).text().length > max) && e.which !== 8) {
                e.preventDefault();
            }
        }
        $ele.on('keyup', onKeyPress);
        $ele.on('keydown', onKeyPress);
        return $ele;
    }
    updateTimer() {
        const oldestDate = this.oldestDetectionDate;
        if (oldestDate !== undefined) {
            const oldestTime = oldestDate.getTime();
            this.controls.startTimerUI($('.time-passed'), oldestDate);
            this.previousOldestDate = oldestTime;
        }
    }
    addDetection(marker) {
        if (marker === null || marker === undefined) {
            throw new Error(`Expected marker value, received null value!`);
        }
        this.cachedIdentifiers[marker.Identifier] = marker;
        this.detections.push(marker);
    }
    get selectedException() {
        return {
            // TODO: Fix key and value, because they are both the same value...
            key: $('select.form-sub option:selected').text(),
            value: $('select.form-sub').val()
        };
    }
    removeDetection(options) {
        const DEFAULT_OPTIONS = { selectNext: true, mutateArray: true, logging: false };
        const { marker, Result, ResultText, selectNext, mutateArray, logging } = Object.assign({}, DEFAULT_OPTIONS, options);
        if (marker === null || marker === undefined) {
            throw new Error(`Expected marker value, received null value!`);
        }
        if (this.controls && this.selectedMarker == marker) {
            this.controls.enabled = false;
        }
        // Delete image viewer if the detection is expired
        const suffix = marker.Identifier;
        $(`#cvs-image-viewer-${suffix}`).find('a[href="#close"]').eq(0).trigger('click');
        purgatoryService.closeInfoWindow();
        // Remove marker from map
        marker.setMap(null);
        if (logging) {
            console.log(`Removed detection ${marker.Identifier} because of the following reason:`, Result, ResultText);
        }
        const { CVSProcess } = this;
        if (CVSProcess.readyToFollowUp) {
            CVSProcess.processDetection({
                detection: marker,
                Result,
                ResultText
            });
        }
        // Remove ui element
        $(`td[data-id=${marker.Identifier}]`).parent().remove();
        // Remove element from array
        if (mutateArray) {
            const index = this.detections.indexOf(marker);
            this.detections.splice(index, 1);
        }
        if (selectNext === true) {
            this.selectNextDetection();
        }
        if (this.controls) {
            AEngine.get(AMeterService).remove(marker.Identifier);
            this.updateTimer();
        }
    }
    /**
     *
     * @param {{ redraw: boolean }} options
     */
    clearDetections(options = { redraw: false }) {
        const { redraw } = Object.assign({ redraw: false }, options);
        purgatoryService.closeInfoWindow();
        if (this.isCVS) {
            for (let marker of this.detections) {
                const { Result, ResultText } = PageScript.stoppedOpt;
                // marker, reason, selectNext, mutateArray, logging
                this.removeDetection({
                    marker,
                    Result,
                    ResultText,
                    selectNext: false,
                    mutateArray: false
                });
            }
        }
        else {
            for (let marker of this.detections) {
                marker.setMap(null);
            }
        }
        this.detections = [];
        if (redraw === true) {
            const { table } = this;
            const { grid } = PageScript;
            this.clearDetectionListUI({ table, grid });
        }
    }
    get currentSelectedDetection() {
        const $tr = $('#detection-list tr.selected');
        const data = $tr.data();
        return data !== undefined ? data.marker : undefined;
    }
    /**
     * Select next detection in old UI
     */
    selectNextDetection() {
        const $tr = $('#detection-list tr');
        if ($tr.length > 1) {
            $tr.eq(1).click();
            return true;
        }
        return false;
    }
    /**
     * Update marker in old UI
     * @param {*} marker
     * @param {*} scrollToItem
     */
    updateSelectedItem(marker, scrollToItem) {
        if (this.selectedListItem !== undefined) {
            this.selectedListItem.removeClass('selected');
        }
        const select = $(`td[data-id='${marker.Identifier}']`).parent();
        if (select.length > 0) {
            select.addClass('selected');
            this.selectedListItem = select;
            // @ts-ignore
            if (scrollToItem === true && !select.isInViewport(200, 0)) {
                // TODO: Fix scrolling to element
                $('.detection-list-container').animate({
                    // @ts-ignore
                    scrollTop: select.offset().top
                }, 500);
            }
        }
    }
    /**
     * Gets cached lat lng from scandevice and uses param marker position as backup
     * @param {any} marker representing a vehicle that has been detected
     * @returns { {lat: any, lng: any} } object representing coordinates for google maps api v3
     */
    getScanDeviceCoords(marker) {
        let lat, lng;
        if (marker.hasOwnProperty('scanDevicePos')) {
            lat = marker.scanDevicePos.lat,
                lng = marker.scanDevicePos.lng;
        }
        else {
            lat = marker.position.lat,
                lng = marker.position.lng;
        }
        return { lat, lng };
    }
    createPanoramaMarker(from, to) {
        if (to === undefined) {
            const marker = from;
            const cameraPosition = this.getScanDeviceCoords(marker);
            from = new google.maps.LatLng(cameraPosition);
            to = new google.maps.LatLng(marker.position);
        }
        // Change position of the streetview
        this.panorama.setPosition(from);
        setTimeout(_ => {
            from = this.panorama.getPosition();
            const heading = google.maps.geometry.spherical.computeHeading(from, to);
            const pitch = 1;
            this.panorama.setPov({
                heading,
                pitch
            });
        }, 80);
    }
    markerClick(marker, overrideMapCenter) {
        const PhotoFetcher = PageScript.PhotoFetcher;
        const map = this.map;
        // TODO: Remove commented out code
        // const zoomPromise = AConfig.get('general.map.centerOnClick', false) ? this.smoothZoom(map, 20, map.getZoom()) : Promise.resolve()
        Promise.resolve().then(async () => {
            // Clear Images
            this.imageHelper.clearImageSet();
            // Fetch detection photo's
            let fetchedImages = [];
            if (marker.images && marker.images.length) {
                fetchedImages = await PhotoFetcher.fetchPhotos('all', { marker });
                // fetchedImages = await PhotoFetcher.fetchOverviewPhoto(marker.DetectionId!, marker.DetectionDeviceId!)
            }
            else {
                fetchedImages = marker.images.map((img, i) => {
                    return {
                        src: img,
                        Index: i,
                        Caption: 'Overview'
                    };
                });
            }
            this.imageHelper.addImageSet(fetchedImages.map(obj => obj.src), {
                allowFilter: true,
                allowFullscreen: true,
                allowZoom: false,
                defaultSize: {
                    height: 200,
                    width: null
                }
            });
            // this.photoHandler.updateImages(fetchedImages)
            Events.tryInvoke('DeskControl->ImagesLoaded');
            this.createPanoramaMarker(marker);
            if (overrideMapCenter === true) {
                map.setCenter(marker.getPosition());
            }
            // Jaap 2022-11-09 @Ivan yhis gives an error marker._onclick({ target: marker })
            // It IS a bug It completly brakes advanced scans! Don't put it back please!
            this.selectMarker(marker);
            if (this.controls) {
                this.controls.enabled = true;
            }
        }).catch(AError.handle);
    }
    selectMarker(marker) {
        this.updateSelectedItem(marker, true);
    }
    smoothZoom(map, max, cnt) {
        return new Promise((resolve, reject) => {
            if (cnt >= max) {
                // @ts-ignore
                return resolve();
            }
            else {
                google.maps.event.addListenerOnce(map, 'zoom_changed', event => {
                    this.smoothZoom(map, max, cnt + 1).then(resolve).catch(reject);
                });
                setTimeout(function () {
                    map.setZoom(cnt);
                }, 80); // 80ms is what I found to work well on my system -- it might not work well on all systems
            }
        });
    }
    get table() {
        return $('table#detection-list');
    }
    addDetectionInstant(marker) {
        this.detections.push(marker);
        this.table.append(this.createDetectionElement(marker));
    }
    redrawDetectionListUI(sort) {
        this.clearDetectionListUI(this);
        if (this.detections.length) {
            for (let element of this.createDetectionList(sort)) {
                this.table.append(element);
            }
        }
    }
    clearDetectionListUI({ table, grid }) {
        if (table) {
            table.find('tr:not(.head)').remove();
        }
        if (grid) {
            grid.store.clear();
        }
    }
    hideImageControls() {
        const $photos = $('.photos');
        $photos.find(`[data-id='controls']`).hide();
        $photos.find(`[data-id='warning']`).hide();
    }
}
