import { AError } from "./AError.js";
import { sleep } from "../core/AEngine.js";
export class AInfoWindowHelper {
    constructor(pageType) {
        this.pageType = pageType || AInfoWindowHelper.getDefaultPageType();
    }
    static getDefaultPageType() {
        const DEFAULT = 'default';
        if (!PageScript)
            return DEFAULT;
        const { _infowindowhelper } = PageScript;
        if (!_infowindowhelper)
            return DEFAULT;
        const { pageType } = _infowindowhelper;
        if (!pageType)
            return DEFAULT;
        return pageType;
    }
    static setDefaultPageType(pageType) {
        if (!PageScript)
            throw new Error(`PageScript is not defined!`);
        Object.assign(PageScript, {
            _infowindowhelper: {
                pageType
            }
        });
    }
    /**
     * @deprecated
     */
    replaceCustoms(tableBuilder, marker) {
        const { data } = tableBuilder;
        const customs = data.filter(item => item.type === 'CUSTOM');
        for (let custom of customs) {
            let $eleToReplace = $(`#${tableBuilder.tableId} [rid="${custom.id}"]`);
            const generateElement = this[custom.mapTo];
            /** @type {JQuery} */
            const $eleToInsert = generateElement(marker, custom.value != null ? custom.value : '');
            if ($eleToInsert instanceof jQuery) {
                const $parent = $eleToReplace.parent();
                $eleToReplace.remove();
                $parent.append($eleToInsert);
            }
            else {
                $eleToReplace.replaceWith($eleToInsert);
            }
        }
    }
    generateLabelField(detection, currentLabel) {
        const { DetectionId, DetectionDeviceId } = detection;
        // TODO: Translate options
        const $datalist = $(`
      <datalist id="labelOptions">
        <option>Not parked</option>
        <option>Illegally parked</option>
        <option>Private parking</option>
        <option>No vehicle</option>
        <option>Double detection</option>
        <option>No parking strip</option>
      </datalist>
    `);
        const $input = $(`
      <input type="text" list="labelOptions" value="${currentLabel || ""}" maxlength="15"/>
    `);
        $input.on('keyup', (e) => {
            if (e.keyCode == 13) {
                const TagDevice = _.getSession().NodeName?.substr(0, 15);
                const TagUser = stateService.User.substring(0, 15);
                // Update label in database
                Loading.waitForPromises(requestService.query({
                    Query: (`
              INSERT INTO
                detections_usertags (DetectionId,DetectionDeviceId,TagDevice,TagUser,Label)
              VALUES
                (:DetectionId,:DetectionDeviceId,:TagDevice,:TagUser,:Label)
              ON DUPLICATE KEY UPDATE
                TagDevice=:TagDevice,
                TagUser=:TagUser,
                Label=:Label
            `),
                    Params: {
                        DetectionId,
                        DetectionDeviceId,
                        TagDevice,
                        TagUser,
                        // @ts-ignore
                        Label: e.target.value
                    }
                })).then(() => 
                // @ts-ignore
                e.target.style = '').catch(AError.handle);
            }
        });
        const $parent = $(`<div></div>`);
        $parent.append($datalist);
        $parent.append($input);
        return $parent;
    }
    static shouldPreventDrag($ele) {
        const prvntSelectors = ['.awin-toolbar-item', '.exit'];
        const preventEleIndex = prvntSelectors.findIndex(qs => $ele.is(qs) || $ele.closest(qs)?.length > 0);
        return preventEleIndex > -1;
    }
    static initDraggableWindow(options) {
        const { $window } = options;
        $window.css('width', 'max-content');
        const windowEle = $window.get(0);
        const headerEle = $window.find('.iw-header, .awin-header').get(0);
        // Make an element draggable (or if it has a .window-top class, drag based on the .window-top element)
        let currentPosX = 0, currentPosY = 0, previousPosX = 0, previousPosY = 0;
        headerEle.onmousedown = dragMouseDown;
        function dragMouseDown(e) {
            if (AInfoWindowHelper.shouldPreventDrag($(e.target))) {
                return;
            }
            // Prevent any default action on this element (you can remove if you need this element to perform its default action)
            e.preventDefault();
            windowEle.style.marginTop = '0';
            // Get the mouse cursor position and set the initial previous positions to begin
            previousPosX = e.clientX;
            previousPosY = e.clientY;
            // When the mouse is let go, call the closing event
            document.addEventListener('mouseup', closeDragElement); // document.onmouseup = closeDragElement;
            // call a function whenever the cursor moves
            document.addEventListener('mousemove', elementDrag); // document.onmousemove = elementDrag;
        }
        function elementDrag(e) {
            const { innerWidth, innerHeight } = window;
            // Prevent any default action on this element (you can remove if you need this element to perform its default action)
            e.preventDefault();
            // Calculate the new cursor position by using the previous x and y positions of the mouse
            currentPosX = previousPosX - e.clientX;
            currentPosY = previousPosY - e.clientY;
            // Replace the previous positions with the new x and y positions of the mouse
            previousPosX = e.clientX;
            previousPosY = e.clientY;
            // Set the element's new position
            windowEle.style.top = Math.max(Math.min(windowEle.offsetTop - currentPosY, innerHeight - headerEle.clientHeight), 0) + 'px';
            windowEle.style.left = Math.round(Math.max(Math.min(windowEle.offsetLeft - currentPosX, innerWidth - windowEle.clientWidth / 2), -windowEle.clientWidth / 2)) + 'px';
        }
        function closeDragElement() {
            // Stop moving when mouse button is released and release events
            document.removeEventListener('mouseup', closeDragElement); // document.onmouseup = null;
            document.removeEventListener('mousemove', elementDrag); // document.onmousemove = null;
        }
    }
    static createToggleButtonListeners(options) {
        // const t = await Loading.waitForPromises(Translate.get(['View all', 'View less', 'History']))
        const { button, tables, translations, callback } = Object.assign({}, options);
        const [tablePriority, tableFull] = tables;
        tableFull.hide();
        button.on('click', (e) => {
            if (tableFull.is(':visible')) {
                tableFull.hide();
                tablePriority.show();
                button.text(translations['View all']);
                if (callback)
                    callback(false);
            }
            else {
                tableFull.show();
                tablePriority.hide();
                button.text(translations['View less']);
                if (callback)
                    callback(true);
            }
        });
    }
    static async waitForElement(select, maxAttempts = 20) {
        let attempts = 0;
        let obj = null;
        do {
            obj = $(select);
            await sleep(200);
        } while (obj.length === 0 && ++attempts < maxAttempts);
        if (obj === null) {
            throw new Error(`Element $('${select}') couldn't be found!`);
        }
        return obj;
    }
    static waitForElements(selects) {
        return Promise.all(selects.map((selector) => AInfoWindowHelper.waitForElement(selector)));
    }
}
