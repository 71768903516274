import { AParkingRight } from "../../classes/AUnificationTypes.js";
import { sleep } from "../../core/AEngine.js";
import { generateTreeDropdown } from "../../utils/tools.js";
export class APage {
    constructor() {
        filterService.load();
        $('#RefreshButton').on('click', _ => FilterManager.showFilterWarning().then(_ => this.refresh()));
    }
    async init() {
        // #VerificationUser Logic is implemented in FilterService
        await Loading.waitForPromises([
            generateTreeDropdown('#ParkingRight', new AParkingRight())
        ]);
    }
    refresh() {
        // Placeholder
        return Loading.waitForPromises(sleep(200));
    }
}
export function render() {
    return ( /*html*/`
    <div id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column col-12">
        <div class="form-group">
          <label class="form-label" for="FromDate">From</label>
          <input class="form-input" type="date" id="FromDate" required="required">
          <input class="form-input" type="time" id="FromTime" required="required">
        </div>
        <div class="form-group">
          <label class="form-label" for="ToDate">To</label>
          <input class="form-input" type="date" id="ToDate" required="required">
          <input class="form-input" type="time" id="ToTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="VerificationUser">Multi Select</label>
          <div id="VerificationUser" class="copycat noselect" maxlength="18">
            <span>None</span>
            <ul class="dropdown c-scroll"></ul>
          </div>
        </div>

        <div class="form-group">
          <label class="form-label" for="ParkingRight">Tree Structure</label>
          <div id="ParkingRight" class="wrapper-dropdown tree-config dd-disallow-none noselect" maxlength="18">
            <span>All</span>
          </div>
        </div>
      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </div>
    <div class="flex-child vh-padding">
    </div>
  `);
}
