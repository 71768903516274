import { EVENTS } from "./AEventService.js";
export class AStateService {
    constructor() {
        this.state = {
            Status: "Disconnected",
            StatusString: "ControlCenter Disconnected"
        };
        this.sessionModeMap = {
            Enforce: ['ReadyToEnforce', 'WaitingForVerificationChannel'],
            FollowUp: ['ReadyToFollowUp', 'WaitingForVerificationChannel'],
            Online: ['LoggedIn'],
            Link: ['Linking', 'Linked'],
            StandBy: ['ReadyToSurvey'],
        };
    }
    autoInit() { }
    get User() { return this.state.User; }
    get UserDisplayName() { return this.state.UserDisplayName; }
    get UserGroups() { return this.state.UserGroups?.map(gr => gr.toLowerCase()) ?? []; }
    isUserACI() { return this.UserGroups.includes(ACI_ADMIN); }
    /**
     * @deprecated
     */
    async changeSessionMode(opt) {
        const desiredStateArr = this.sessionModeMap[opt.SessionMode];
        let eventId = '-1';
        const p = new Promise((resolve, reject) => {
            eventId = Events.hardwire(EVENTS.STATE_CHANGED, ({ state, prevState }) => {
                if (desiredStateArr.includes(state)) {
                    console.log(`SUCCESS! state=${state} desiredStateArr=`, desiredStateArr);
                    resolve();
                }
                else {
                    console.log(`... state=${state} desiredStateArr=`, desiredStateArr);
                }
            });
        });
        requestService.send('ChangeSessionModeRequest', { ...opt });
        try {
            const output = await Loading.awaitTimeout(p, opt.msTimeout);
            return output;
        }
        catch (err) {
            console.error(err);
        }
        finally {
            Events.off(EVENTS.STATE_CHANGED, eventId);
        }
    }
    isInvalid(loginResponse) {
        const InvalidStates = [
            'InvalidLink',
            'InvalidLogin',
            'InvalidSessionMode',
            'InvalidVerificationChannel',
            'Disconnected'
        ];
        return InvalidStates.includes(loginResponse?.Status || this.state.Status);
    }
    isLoggedIn(loginResponse) {
        const SuccessStates = [
            'LoggedIn',
            'ReadyToFollowUp',
            'ReadyToEnforce',
            'ReadyToVerify',
            'Linked',
            'WaitingForVerificationChannel'
        ];
        return SuccessStates.includes(loginResponse?.Status || this.state.Status);
    }
    handleStateStream(newState) {
        if (this.state.Status != newState.Status) {
            this.prevState = this.state;
            this.state = newState;
            const { state, prevState } = this;
            Events.tryInvoke(EVENTS.STATE_CHANGED, { state, prevState });
        }
    }
}
