import { AEngine } from '../core/AEngine.js';
import { EVENTS } from '../services/AEventService.js';
export class ADeveloperTodos {
    static async run() {
        if (AEngine.isDevelopmentMode) {
            Events.h_once(EVENTS.PREFETCH, () => {
                this.todos.map((todo) => {
                    adminAlertsService.createAlert(todo);
                });
            });
        }
    }
}
ADeveloperTodos.todos = [
// { GroupId: 'backoffice_widgets_v2', Message: 'Rename table backoffice_widgets_v2 to backoffice_widgets', Importance: 1 }
];
