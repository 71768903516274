import { AError } from "../../classes/AError.js";
import { COLUMN_DATETIME, COLUMN_HIDDEN, DATA_DATETIME } from "../../classes/AGridTypes.js";
import { AEngine, sleep } from "../../core/AEngine.js";
import { ATemplates } from "../../core/ATemplateService.js";
import { AConvertToGridColumns, AConvertToGridData, AShowTable } from "../../utils/tools.js";
export class APage {
    constructor() {
        this.defaultValues = {
            users: [],
            log_categories: [],
            user_actions: [],
        };
        FilterManager.setActive(false);
        this.blank = '';
        this.EXTRA_LOOP = 1;
        this.rowIdAllocator = 0;
        this.tableCache = {};
    }
    async init() {
        await this.prepareFilters();
        FilterManager.load();
        $('#FromTime').val('00:00');
        $('#ToTime').val('00:00');
        $('#RefreshButton').on('click', _ => FilterManager.showFilterWarning().then(_ => this.refresh()));
        this.refresh(true).catch(AError.handle);
        FilterManager.setActive(true);
    }
    async prepareFilters() {
        await Loading.waitForPromises([
            requestService.query({
                Name: "USERS",
                Query: "SELECT DISTINCT User FROM users"
            }).then((Data) => {
                this.defaultValues['users'] = Data.Rows.map(row => row[0]);
            }),
            requestService.query({
                Name: 'LOG_CATEGORIES',
                Query: "SELECT DISTINCT Category FROM user_actions"
            }).then((Data) => {
                this.defaultValues['categories'] = Data.Rows.map(row => row[0]);
            }),
            requestService.query({
                Name: 'LOG_USER_ACTIONS',
                Query: "SELECT DISTINCT Action FROM user_actions"
            }).then((Data) => {
                this.defaultValues['actions'] = Data.Rows.map(row => row[0]);
            }),
        ]);
        const { users, log_categories, user_actions } = this.defaultValues;
        $('#User').each(function () { users.map(str => $(this).append(`<option value="${str}">${str}</option>`)); });
        $('#Category').each(function () { log_categories.map(str => $(this).append(`<option value="${str}">${str}</option>`)); });
        $('#Action').each(function () { user_actions.map(str => $(this).append(`<option value="${str}">${str}</option>`)); });
    }
    genWhereClause(filters) {
        const { User, Category, Action, Target, Description } = filters;
        const conditions = [
            'CreatedAt BETWEEN :FromDate AND :ToDate',
            ...((User !== '%') ? ['User=:User'] : []),
            ...((Category !== '%') ? ['Category=:Category'] : []),
            ...((Action !== '%') ? ['Action=:Action'] : []),
            ...((Target.length !== 0) ? [`Target LIKE CONCAT('%', :Target, '%')`] : []),
            ...((Description.length !== 0) ? [`Description LIKE CONCAT('%', :Description, '%')`] : []),
        ];
        return conditions.join(' AND ');
    }
    async fetchSessions() {
        const filters = filterService.saveExplicit({ cacheFilters: false });
        const ares = await requestService.fetch({
            AssertValues: true,
            Query: ( /*SQL*/`
        SELECT
          IF(a.Status = 'Disconnected',
            (SELECT SessionId FROM sessionstatus f WHERE (f.SessionStatusId < a.SessionStatusId AND f.Time BETWEEN DATE_SUB(a.Time, INTERVAL 7 DAY) AND  a.Time AND f.DeviceId=a.DeviceId AND f.DeviceName=a.DeviceName) ORDER BY SessionStatusId DESC LIMIT 1),
            (a.SessionId)
          ) AS SessionId,
          Time,
          User,
          Status,
          StatusString
        FROM sessionstatus a
        LEFT JOIN (
          SELECT * FROM (select max(UserSessionId) AS UserSessionId FROM user_session_start GROUP BY SessionId) t
          LEFT JOIN user_session_start USING (UserSessionId)
          LEFT JOIN user_session_end USING (UserSessionId)
        ) s
        WHERE Time BETWEEN :FromDate AND :ToDate AND DeviceName LIKE :DeviceName
        ORDER BY SessionStatusId ASC
        LIMIT :Limit
      `),
            Params: Object.assign({ DeviceName: '%' }, filters),
            Language: Language,
            // Translate: ["DeviceName", "Status", "ComState", "StatusString"]
        });
        AEngine.log('ares', ares.toArray());
    }
    async refresh(first) {
        FilterManager.setActive(false);
        const filters = FilterManager.save();
        const whereClause = this.genWhereClause(filters);
        return Loading.waitForPromises(requestService.fetch({
            AssertValues: true,
            Query: ( /*SQL*/`
          SELECT
            Id,
            CreatedAt as \`Time\`,
            User,
            Category,
            Action,
            Target,
            Description
          FROM user_actions
          WHERE ${whereClause}
          ORDER BY id DESC
          LIMIT :Limit
        `),
            Params: filters,
            Language: Language
        }).then(async (ares) => {
            FilterManager.setActive(true);
            if (ares.isEmpty && !first) {
                return Alerts.noResults();
            }
            this.grid = AShowTable({
                appendTo: 'table-bryntum',
                columns: AConvertToGridColumns(ares, {
                    'Id': COLUMN_HIDDEN,
                    'Category': COLUMN_HIDDEN,
                    'Description': { htmlEncode: true },
                    'Time': COLUMN_DATETIME,
                }),
                data: AConvertToGridData(ares, {
                    'Time': DATA_DATETIME,
                }),
            });
            // Loading is too fast to see the loading bar
            return sleep(100);
        })).catch(AError.handle);
    }
}
export function render() {
    return ( /*html*/`
    <div id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column c-scroll col-12">
        <div class="form-group">
          <label class="form-label" for="FromDate">From</label>
          <input class="form-input" type="date" id="FromDate" required="required">
          <input class="form-input hidden" type="time" id="FromTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="ToDate">To</label>
          <input class="form-input" type="date" id="ToDate" required="required">
          <input class="form-input hidden" type="time" id="ToTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="User">User</label>
          <select class="form-select" id="User">
            <option value="%" SELECTED>All</option>
          </select>
        </div>

        <div class="form-group">
          <label class="form-label" for="Category">Category</label>
          <select class="form-select" id="Category">
            <option value="%" SELECTED>All</option>
          </select>
        </div>

        <div class="form-group">
          <label class="form-label" for="Action">Action</label>
          <select class="form-select" id="Action">
            <option value="%" SELECTED>All</option>
          </select>
        </div>

        <div class="form-group">
          <label class="form-label" for="Target">Target</label>
          <input type="text" class="form-input" id="Target">
        </div>

        <div class="form-group">
          <label class="form-label" for="Description">Description</label>
          <input type="text" class="form-input" id="Description">
        </div>

        <div class="form-group">
          <label class="form-label" for="Limit">Max results</label>
          <input class="form-input" type="number" id="Limit" value="2000">
        </div>
      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </div>
    <div id="Rapport" class="flex-child bryntum-container has-footer-2">
      <div template="${ATemplates.WaitingForInput}"></div>
      <div id="table-bryntum" class="hidden"></div>

      <div class="columns footer aci">
        <div class="column col-2">
          <div id="count" class="text">Viewing <span>0</span> Records</div>
        </div>
        <div class="column col-2 col-ml-auto">
          <button id="export" class="btn btn-primary col-12" disabled="disabled">Export</button>
        </div>
      </div>
    </div>
  `);
}
