export class APagination {
    constructor({ $parent, $pagesParent, page, pageCount, load }) {
        this.$parent = $parent;
        this.$pagesParent = $pagesParent;
        this.$ele = this.createElement();
        this.$pagination = this.$ele.find('.pagination');
        this.$hrefs = this.$pagination.find('a[href]');
        this.$hrefsNum = this.$pagination.find('a[href]:not(:first-child):not(:last-child)');
        this.$moveLeft = this.$pagination.find('a[href]:first-child');
        this.$moveRight = this.$pagination.find('a[href]:last-child');
        this.page = page;
        this.pageCount = pageCount || 1;
        this.originalPageCount = pageCount;
        this.createListeners();
        this.preparePageParent();
        this.updateHrefs();
        this.$parent.append(this.$ele);
        this.load = load;
        this.setPage(page);
    }
    get $view() {
        let $found = this.$pagesParent.find('.active');
        if ($found.length === 0) {
            console.warn(`Couldn't find active $view!`);
            $found = this.$pagesParent.children().eq(0);
        }
        return $found;
    }
    setPage(page) {
        this.page = page;
        this.$hrefs.removeClass('active');
        const $found1 = this.$pagination.find(`[num=${page}]`);
        $found1.addClass('active');
        const $found2 = this.$pagesParent.children();
        $found2.eq(page - 1).addClass('active');
        this.$view.html('');
        this.load({ $view: this.$view, page });
        if (this.originalPageCount === 0) {
            this.$view.html($(`#empty-placeholder`).html());
        }
        this.$pagination.toggleClass('hidden', (this.originalPageCount === 0));
    }
    preparePageParent() {
        this.$pagesParent.html('');
        for (let i = 0; i < this.pageCount; i++) {
            this.$pagesParent.append(`<div class="columns pagination-view"></div>`);
        }
    }
    createListeners() {
        this.$hrefs.click(e => {
            e.preventDefault();
            const $clicked = $(e.target);
            const $a = $clicked.is('[num]') ? $clicked : $clicked.closest('[num]');
            if ($a.hasClass('disabled')) {
                return;
            }
            const pageStr = $a.attr('num');
            if (pageStr === undefined) {
                return console.error('no page! ' + pageStr);
            }
            let page = parseInt(pageStr);
            if (isNaN(page)) {
                if (pageStr === '+') {
                    if (this.page + 1 > this.pageCount) {
                        return;
                    }
                    page = this.page + 1;
                }
                else {
                    if (this.page - 1 <= 0) {
                        return;
                    }
                    page = this.page - 1;
                }
            }
            let start = 1;
            if (page > 3) {
                start = page - 2;
            }
            if (page > this.pageCount - 2) {
                start = Math.max(this.pageCount - 4, 1);
            }
            this.$hrefsNum.each((i, href) => {
                $(href).text(start + i);
                $(href).attr('num', (start + i));
            });
            this.updateHrefs();
            this.setPage(page);
        });
    }
    updateHrefs() {
        const max = this.pageCount;
        this.$hrefsNum.each((i, href) => {
            const numAttr = $(href).attr('num');
            const num = Number(numAttr);
            $(href).toggleClass('disabled', (num > this.pageCount));
            // console.log(num, (num > this.pageCount), $(href))
        });
    }
    createElement() {
        const $element = $(`
      <div class="column col-12 text-center">
        <div class="pagination">
          <a num="-" href="#">&laquo;</a>
          <a num="1" class="active" href="#">1</a>
          <a num="2" href="#">2</a>
          <a num="3" href="#">3</a>
          <a num="4" href="#">4</a>
          <a num="5" href="#">5</a>
          <a num="+" href="#">&raquo;</a>
        </div>
      </div>
    `);
        return $element;
    }
}
