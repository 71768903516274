import { AEngine, sleep } from "../core/AEngine.js";
import { AStatisticsService } from "../services/AStatisticsService.js";
import { EVENTS } from "../services/AEventService.js";
import { AChartEvent, AStatisticsChart } from "../charts/AStatisticsChart.js";
import { CHART_TYPE_ENUM } from "../classes/ADetectionStatistics.js";
import { AKpiBlock, AKpiState } from "./AKpiBlock.js";
export class AKpiBlockUnifications extends AKpiBlock {
    constructor(opt) {
        super({
            refreshImplemented: true,
            optionsImplemented: true,
            ...opt
        });
        this.statisticsService = AEngine.get(AStatisticsService);
        this.chartType = opt.Name;
        this.visibleChartIndex = -1;
        this.createResizeListener();
    }
    createResizeListener() {
        Events.on(EVENTS.CONTENT_RESIZE, async () => {
            await sleep(1);
            this.chart?.setSize(this.getSize());
        });
    }
    async createChart(opt) {
        const chart = new AStatisticsChart({
            id: `${opt.id}`,
            statistics: opt.statistics,
            chartType: this.chartType,
            roundValues: true,
            animation: false,
        }).setPrefferedHeight(this.getSize().height);
        chart.on(AChartEvent.EVENT_CHANGE, async () => {
            await sleep(1);
            const { width, height } = this.getSize();
            chart.setSize({ width, height });
            chart.reflow();
        });
        await chart.show({ catchError: false });
        return chart;
    }
    async refresh(filters = this.filterOption) {
        let statistics;
        if (this.chartType === CHART_TYPE_ENUM.OCCUPANCY) {
            const statsRes = await this.statisticsService.fetch(filters);
            statistics = statsRes.statisticsTotal;
        }
        else {
            statistics = await this.statisticsService.fetchDynamicSingle(filters);
        }
        if (statistics.getTotal() === 0) {
            return AKpiState.noResults;
        }
        if (this.visibleChartIndex !== filters.index) {
            this.visibleChartIndex = filters.index ?? -1;
            await this.chart?.destroy();
            this.chart = undefined;
        }
        // const arrIndex = filters.index ?? 0
        if (this.chart == null) {
            this.chart = await this.createChart({ id: `${this.idBlock}-chart`, statistics: statistics });
        }
        else {
            this.chart.updateChart(statistics);
        }
    }
    async render() {
        return await super.renderView({
            title: this.chartType.toString(),
            viewHtml: ( /*html*/`
        <div class="statistics-container statistics-border">
          <div class="statistics-title hidden">...</div>
          <div id="${this.idBlock}-chart" class="pie-chart"></div>
          <div class="route"></div>
        </div>
      `),
        });
    }
}
