import { AEngine, sleep } from "./core/AEngine.js";
export function ARound(val, decimals = 0) {
    const pow = Math.pow(10, decimals);
    return Math.round(val * pow) / pow;
}
function createArray(length, defaultValue = undefined) {
    let output = [];
    if (typeof defaultValue === 'function') {
        for (let i = 0; i < length; i++) {
            output.push(defaultValue(i));
        }
    }
    else {
        // if (isPrimitive(defaultValue)) { }
        for (let i = 0; i < length; i++) {
            output.push(defaultValue);
        }
    }
    return output;
}
function yieldFrame() {
    const promise = new Promise(requestAnimationFrame);
    return promise.then(function frameCallback(timestamp) {
        // console.log('timestamp', timestamp)
        return performance.now();
    });
}
export class AThread {
    constructor(items, opt) {
        this.items = items;
        this.maxSleep = 60;
        this.msDeltaTime = 0.3;
        this.prefix = opt.prefix ?? 'AsyncMapArray';
        this.events = opt.events ?? {};
        this.chunks = Math.min(items.length, opt.chunks);
        this.chunkSize = Math.ceil(items.length / this.chunks);
        this.minSeconds = opt.seconds;
    }
    isEmpty() { return this.items.length === 0; }
    chunkItems(items) {
        const output = [];
        let itemsCpy = Object.assign([], items);
        do {
            const partition = itemsCpy.splice(0, this.chunkSize);
            output.push(partition);
        } while (itemsCpy.length > 0);
        return output;
    }
    async asyncMap(callback) {
        return await Loading.waitForPromises(this._asyncMap(callback));
    }
    async _asyncMap(callback) {
        if (this.isEmpty())
            return [];
        const output = [];
        const chunks = this.chunkItems(this.items);
        let startTime = await yieldFrame();
        let prevTime = 0;
        for (let i = 0; i < chunks.length; i++) {
            this.events.onProgress(this, i, chunks.length, this.items.length);
            output.push(await Promise.all(chunks[i].map(async (v, i2) => callback(v, chunks[0].length * i + i2, i2 / chunks[i].length)))); // Process chunk
            let timeSinceStart = (await yieldFrame()) - startTime; // Wait for next frame
            let msDeltaTime = timeSinceStart - prevTime;
            if (i === 0) {
                this.msDeltaTime = msDeltaTime;
            }
            prevTime = timeSinceStart;
            if (this.minSeconds !== undefined) {
                const msPerChunk = (this.minSeconds * 1000) / chunks.length;
                const sleepTimeUnbound = (msPerChunk - msDeltaTime);
                const sleepTime = Math.min(Math.max(sleepTimeUnbound, 0), this.maxSleep);
                // AEngine.log(`Sleeping: ${Math.round(sleepTime)} ms`, { sleepTimeUnbound, msDeltaTime, msPerChunk, sleepTime, chunks: chunks.length, size: this.items.length })
                if (sleepTime > 0) {
                    await sleep(sleepTime);
                }
            }
        }
        this.events.onProgress(this, chunks.length, chunks.length, this.items.length);
        // AEngine.log(`AsyncMapArray %p[%c${createArray(10, '⣿').join('')}%p]%n 100.00%`)
        const arrays = await Promise.all(output);
        return [].concat.apply([], arrays);
    }
    static prepare(items, opt) {
        const chunkSize = {
            1: 1,
            2: 10,
            3: 10,
            4: 20,
            5: 100,
            6: 1000,
            7: 10000, // 1000000 items = 10000 chunks
        };
        return new AThread(items, Object.assign({
            chunks: chunkSize[items.length.toFixed(0).length] ?? chunkSize[7],
            events: {
                onProgress(context, chunkIndex, chunkCount, size) {
                    const progress = (chunkIndex * 100 / chunkCount);
                    const pbar = createArray(10, null).map((_, i) => (i * 10) >= progress ? '⣀' : '⣿').join('');
                    AEngine.log(`${context.prefix} %p[%c${pbar}%p]%n ${ARound(progress, 2).toFixed(2)}%`);
                }
            }
        }, opt));
    }
}
