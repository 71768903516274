import { AColor } from "../../classes/AColor.js";
import { AError } from "../../classes/AError.js";
import { sleep } from "../../core/AEngine.js";
import { MAP_OPTIONS } from "../../core/maps/AMapStructs.js";
import { ALERT_BUTTONS, ALERT_STATUS, ALERT_TITLES } from "../../services/AAlertService.js";
import { createMap, getPolygonCenter } from "../../utils/maps.js";
import { ACombineDateTime, AInputCleanDateTime, AInputDate, AInputTime } from "../../utils/tools.js";
export class APage {
    constructor() {
        FilterManager.setActive(false);
        this.SCREEN_TYPE = {
            List: 1,
            Rights: 2,
            Info: 3,
            Create: 4,
            Edit: 5
        };
        this.selectedPolygonColor = new AColor(43, 255, 0).hexi;
        this.selectedStrokeColor = new AColor(31, 189, 0).hexi;
        this.map = createMap('map', {
            zoom: 24,
            styles: [{ "featureType": "road", "elementType": "labels", "stylers": [{ "visibility": "on" }] }, { "featureType": "poi", "stylers": [{ "visibility": "off" }] }, { "featureType": "administrative", "stylers": [{ "visibility": "off" }] }, { "featureType": "road", "elementType": "geometry.fill", "stylers": [{ "color": "#dadada" }, { "weight": 1 }] }, { "featureType": "road", "elementType": "geometry.stroke", "stylers": [{ "color": "#b9b9b9" }, { "weight": 0.8 }] }, { "featureType": "landscape", "stylers": [{ "color": "#e4e4e4" }] }, { "featureType": "water", "stylers": [{ "visibility": "off" }] }, { "featureType": "transit", "stylers": [{ "visibility": "off" }] }, { "elementType": "labels", "stylers": [{ "visibility": "off" }] }, { "elementType": "labels.text", "stylers": [{ "visibility": "on" }] }, { "elementType": "labels.text.stroke", "stylers": [{ "color": "#ffffff" }] }, { "elementType": "labels.text.fill", "stylers": [{ "color": "#000000" }] }, { "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] }],
            mapTypeId: google.maps.MapTypeId.ROADMAP
        });
        FilterManager.load();
        $('#RefreshButton').on('click', _ => FilterManager.showFilterWarning().then(_ => this.refresh()));
        // TODO: Find out if its worth unloading & having mapHelperService.cache publicly available
        // Events.h_once(EVENTS.DESTRUCT, () => {
        //   // if (InfoWindow) InfoWindow.close()
        //   mapHelperService.revertColors()
        //   Object.keys(mapHelperService.cache).map((scale) => {
        //     const collection = mapHelperService.cache[scale]
        //     // Keeping the click event listeners for cached scales
        //     mapHelperService.unload(collection, UNLOAD_OPTIONS.Default)
        //   })
        // })
    }
    async init() {
        this.screenType = this.SCREEN_TYPE.List;
        this.translations = await Loading.waitForPromises(Translate.get([
            'Edit Parking Right',
            'Parking Right',
            'Start',
            'End',
            'Create New Parking Right',
            'Edit',
            'Delete',
            'Create',
            'Update',
            'Cancel',
            'License Plate',
            'Start Date',
            'Start Time',
            'End Date',
            'End Time',
            'Parking Right Type',
            'Phone',
            'Type',
            'Id',
            'Information',
            'Click on the map to change the location',
            'Area',
            'Zone',
            'ParkingSpace',
            'Vehicle Type',
            'Car',
            'Truck'
        ]));
        this.fillPanel([]);
        FilterManager.setActive(true);
        const { Area: Areas, Zone: Zones, ParkingSpace: ParkingSpaces } = MAP_OPTIONS;
        mapHelperService.prepareMapItems(Areas | Zones | ParkingSpaces, {
            showLegend: false,
            showOneScale: true,
            createToggleItems: true,
            fitPolygons: true,
            allowExport: false,
            click: function () {
                // Select new polygon if editing / creating a record
                const ps = PageScript;
                if ([ps.SCREEN_TYPE.Create, ps.SCREEN_TYPE.Edit].includes(ps.screenType)) {
                    let marker = this;
                    ps.selectPolygon(ps.map, marker);
                    const { id, area, scale } = marker.data;
                    const scaleClass = ps.transformEnumToClass(scale);
                    $('#createForm #input1').val(scaleClass);
                    $('#createForm #input2').val(id);
                    $('#editForm #input1').val(scaleClass);
                    $('#editForm #input2').val(id);
                }
            }
        }).catch(AError.handle);
        $('#lastPanel button').click((e) => {
            this.goBack(this.screenType);
        });
    }
    switchTo(type) {
        if (type > this.SCREEN_TYPE.List) {
            $('#lastPanel button').removeAttr('disabled');
        }
        else {
            $('#lastPanel button').attr('disabled', '1');
        }
        switch (type) {
            case this.SCREEN_TYPE.List:
                $('#forthPanel').html('');
                $('#fifthPanel').html('');
                $('#firstPanel').show();
                $('#secondPanel').hide();
                $('#thirdPanel').hide();
                $('#forthPanel').hide();
                $('#fifthPanel').hide();
                break;
            case this.SCREEN_TYPE.Rights:
                $('#forthPanel').html('');
                $('#fifthPanel').html('');
                $('#firstPanel').hide();
                $('#secondPanel').show();
                $('#thirdPanel').hide();
                $('#forthPanel').hide();
                $('#fifthPanel').hide();
                break;
            case this.SCREEN_TYPE.Info:
                $('#forthPanel').html('');
                $('#fifthPanel').html('');
                $('#firstPanel').hide();
                $('#secondPanel').hide();
                $('#thirdPanel').show();
                $('#forthPanel').hide();
                $('#fifthPanel').hide();
                break;
            case this.SCREEN_TYPE.Create:
                $('#firstPanel').hide();
                $('#secondPanel').hide();
                $('#thirdPanel').hide();
                $('#forthPanel').show();
                $('#fifthPanel').hide();
                break;
            case this.SCREEN_TYPE.Edit:
                $('#firstPanel').hide();
                $('#secondPanel').hide();
                $('#thirdPanel').hide();
                $('#forthPanel').hide();
                $('#fifthPanel').show();
                break;
        }
        this.screenType = type;
    }
    goBack(type) {
        console.log('[GO BACK] ' + type);
        switch (type) {
            case this.SCREEN_TYPE.List:
                AError.handle(`Can't go back!`);
                break;
            case this.SCREEN_TYPE.Rights:
                this.switchTo(this.SCREEN_TYPE.List);
                break;
            case this.SCREEN_TYPE.Info:
                this.switchTo(this.SCREEN_TYPE.Rights);
                break;
            case this.SCREEN_TYPE.Create:
                $('#forthPanel').html('');
                this.switchTo(this.SCREEN_TYPE.List);
                break;
            case this.SCREEN_TYPE.Edit:
                $('#fifthPanel').html('');
                this.switchTo(this.SCREEN_TYPE.Info);
                break;
        }
    }
    refresh(selectedLicense) {
        FilterManager.setActive(false);
        const filters = FilterManager.save();
        $('#firstPanel tr[license]').remove();
        return Loading.waitForPromises(this.fetchList(filters).then((response) => {
            if (!response.Rows.length) {
                Alerts.noResults();
            }
            // Make sure the left panel is showing the list.
            this.switchTo(this.SCREEN_TYPE.List);
            // Fill side panel
            this.fillPanel(response.Rows);
            // Fit Map borders
            this.map.fit();
            // Enabled the filters & buttons
            FilterManager.setActive(true);
            // If operator just edited an entry, it'll reselect the edited row
            if (selectedLicense) {
                this.clickLicensePlate({ license: selectedLicense });
            }
        })).catch(AError.handle);
    }
    selectPolygon(map, polygon) {
        // Return if polygon is already selected
        if (this.selectedPolygon === polygon)
            return;
        // Makes sure the map zooms out enough for the whole polygon to show
        map.fitBounds(mapHelperService.getPolygonBounds(polygon));
        // Get polygon original color properties
        const { fillColor, fillOpacity, strokeColor, strokeOpacity } = polygon;
        // TODO: Reset color for polygons when visiting other pages!
        // Change polygon colors
        mapHelperService.changeColorMarker(polygon, {
            fillColor: this.selectedPolygonColor,
            strokeColor: this.selectedStrokeColor
        });
        // If previous polygon exists
        if (this.selectedPolygon && this.colorCache) {
            // Change back to original color
            mapHelperService.changeColorMarker(this.selectedPolygon, this.colorCache);
        }
        // Set new color cache
        this.colorCache = { fillColor, fillOpacity, strokeColor, strokeOpacity };
        // Set selected polygon
        this.selectedPolygon = polygon;
    }
    hideInfoWindow() {
        if (globalThis.InfoWindow)
            globalThis.InfoWindow.close();
        if (this.selectedPolygon && this.colorCache) {
            mapHelperService.changeColorMarker(this.selectedPolygon, this.colorCache);
            this.selectedPolygon = null;
            this.colorCache = null;
        }
    }
    fetchList(filters) {
        const { FromDate, ToDate, LicensePlate, Limit } = filters;
        return Loading.waitForPromises(requestService.query({
            Query: `
                    SELECT LicensePlate, COUNT(LicensePlate) Count, PrdbId, PrdbVersion FROM prdb p INNER JOIN (
                        SELECT prdbId, prdbdeviceId, MAX(PrdbVersion) PrdbVersion FROM prdb
                        WHERE ${LicensePlate != '' ? 'LicensePlate LIKE :LicensePlate AND ' : ''}
                            :FromDate < StopTime AND :ToDate > StartTime
                        GROUP BY prdbId, prdbdeviceId
                    ) t 
                    USING (prdbId, prdbdeviceId, PrdbVersion)
                    WHERE Removed = 0
                    GROUP BY LicensePlate
                    ORDER BY PrdbId
                    LIMIT :Limit`,
            Params: {
                FromDate,
                ToDate,
                LicensePlate,
                Limit
            }
        }));
    }
    fetchDataFromLicense(LicensePlate) {
        return Loading.waitForPromises(requestService.query({
            Query: `
                    SELECT * FROM prdb p INNER JOIN (
                        SELECT prdbId, prdbdeviceId, MAX(PrdbVersion) PrdbVersion FROM prdb
                        WHERE LicensePlate LIKE :LicensePlate
                        GROUP BY prdbId, prdbdeviceId
                    ) t 
                    USING (prdbId, prdbdeviceId, PrdbVersion)
                    WHERE Removed = 0
                    ORDER BY PrdbId`,
            Params: {
                LicensePlate
            },
            Language
        }));
    }
    fetchDataFromPrdbId(PrdbId, PrdbDeviceId) {
        return Loading.waitForPromises(requestService.fetch({
            AssertValues: true,
            Query: (`
          SELECT * FROM prdb p INNER JOIN (
              SELECT PrdbId, PrdbDeviceId, MAX(PrdbVersion) PrdbVersion FROM prdb
              WHERE PrdbId=:PrdbId AND PrdbDeviceId=:PrdbDeviceId
              GROUP BY PrdbId, PrdbDeviceId
          ) t 
          USING (PrdbId, PrdbDeviceId, PrdbVersion)
          WHERE Removed = 0
          ORDER BY PrdbId
        `),
            Params: {
                PrdbId,
                PrdbDeviceId
            },
            Language
        }));
    }
    fillPanel(rows) {
        const $panel = $('#firstPanel table');
        for (let i = 0; i < rows.length; i++) {
            const $ele = $(`
                <tr license="${rows[i][0]}">
                    <td>${rows[i][0]}</td>
                    <td>${rows[i][1]}</td>
                </tr>
            `);
            $ele.click((e) => this.clickLicensePlate(e));
            $panel.append($ele);
        }
        const $trCreatePrdb = $(`
            <tr id="createPrdb" license="">
                <td colspan="2" style="text-align: center">
                    <span class="text-primary">${this.translations['Create New Parking Right']}  <i class="fa fa-plus"></i></span>
                </td>
            </tr>
        `);
        $trCreatePrdb.click((e) => this.createForm({}));
        $panel.append($trCreatePrdb);
    }
    async clickLicensePlate(e) {
        let license;
        if (e.target) {
            const $target = $(e.target);
            const $ele = $target.is('td') ? $target.parent().children(':first') : $target.children(':first');
            license = $ele.text();
        }
        else if (e.LicensePlate) {
            license = e.LicensePlate;
        }
        else {
            return console.warn('Target & LicensePlate are not defined!');
        }
        const $panel = $('#secondPanel table');
        $panel.find('.prdb-entry').remove();
        const data = await this.fetchDataFromLicense(license);
        const $panelContent = await this.createSecondPanelContent(data);
        $panelContent.map(() => $panel.append($panelContent));
        this.hideInfoWindow();
        this.switchTo(this.SCREEN_TYPE.Rights);
    }
    removeFromSecondPanel(prdbId, PrdbDeviceId) {
        const $panel = $('#secondPanel table');
        $panel.find(`[prdbidentifier="${prdbId}-${PrdbDeviceId}"]`).remove();
    }
    async createSecondPanelContent(data) {
        let output = [];
        const PrdbIdIndex = data.Columns.indexOf('PrdbId');
        const PrdbDeviceIdIndex = data.Columns.indexOf('PrdbDeviceId');
        const PrdbVersionIndex = data.Columns.indexOf('PrdbVersion');
        const LicenseIndex = data.Columns.indexOf('LicensePlate');
        const ParkingRightIndex = data.Columns.indexOf('ParkingRightType');
        const ParkingRightAIndex = data.Columns.indexOf('ParkingRightAttributes');
        const GeoTypeIndex = data.Columns.indexOf('GeoType');
        const GeoIdIndex = data.Columns.indexOf('GeoId');
        const VehicleTypeIndex = data.Columns.indexOf('VehicleType');
        const PhoneIndex = data.Columns.indexOf('Phone');
        const StartTimeIndex = data.Columns.indexOf('StartTime');
        const StopTimeIndex = data.Columns.indexOf('StopTime');
        const CreatedDeviceIndex = data.Columns.indexOf('CreatedDeviceId');
        for (let i = 0; i < data.Rows.length; i++) {
            const PrdbId = data.Rows[i][PrdbIdIndex];
            const PrdbDeviceId = data.Rows[i][PrdbDeviceIdIndex];
            const PrdbVersion = data.Rows[i][PrdbVersionIndex];
            const LicensePlate = data.Rows[i][LicenseIndex];
            const ParkingRightType = data.Rows[i][ParkingRightIndex];
            const ParkingRightAttributes = data.Rows[i][ParkingRightAIndex];
            const GeoType = data.Rows[i][GeoTypeIndex];
            const GeoId = data.Rows[i][GeoIdIndex];
            const VehicleType = data.Rows[i][VehicleTypeIndex];
            const Phone = data.Rows[i][PhoneIndex];
            const StartTime = new Date(data.Rows[i][StartTimeIndex]);
            const StopTime = new Date(data.Rows[i][StopTimeIndex]);
            const CreatedDeviceId = data.Rows[i][CreatedDeviceIndex];
            $('#DisplayLicense').text(LicensePlate);
            const $tr = $(/*html*/ `
        <tr prdbId="${PrdbId}-${PrdbDeviceId}" class="prdb-entry">
          <td>
            <span>${this.translations['Parking Right']}</span>
            <span>${this.translations['Start']}</span>
            <span>${this.translations['End']}</span>
          </td>
          <td>
            <span>${ParkingRightType != null ? ParkingRightType : 'None'}</span>
            <span>${AInputCleanDateTime(StartTime)}</span>
            <span>${AInputCleanDateTime(StopTime)}</span>
          </td>
          <td>
            ${CreatedDeviceId ? `<i class="fas fa-check"></i>` : ''}
          </td>
        </tr>
      `);
            $tr.on('click', async (e) => {
                const mapOption = mapHelperService.geoTypeToMapOption(GeoType);
                await mapHelperService.toggleTo(this.map, GeoType);
                let array = mapHelperService.getGeoInstancesOnMap(this.map, mapOption);
                let found = false;
                array.map((polygon) => {
                    if (polygon.data.id != GeoId)
                        return;
                    found = true;
                    this.showInfoWindow($tr, PrdbId, PrdbDeviceId, polygon);
                });
                if (!found) {
                    Loading.waitForPromises(Translate.get([
                        `Warning`,
                        `Polygon Not Found`
                    ])).then((translations) => {
                        Alerts.somethingWentWrong();
                        console.warn({ GeoType, GeoId });
                    }).catch(AError.handle);
                }
            });
            $tr.data('prdb', {
                PrdbId,
                PrdbDeviceId,
                PrdbVersion,
                LicensePlate,
                ParkingRightType,
                ParkingRightAttributes,
                GeoType,
                GeoId,
                VehicleType,
                Phone,
                StartTime,
                StopTime
            });
            output.push($tr);
        }
        return output;
    }
    showInfoWindow($tr, prdbId, prdbDeviceId, polygon) {
        const translations = this.translations;
        Loading.waitForPromises(this.fetchDataFromPrdbId(prdbId, prdbDeviceId).then(async (ares) => {
            throw new Error(`Not Implemented Yet!`);
            // const table = await purgatoryService.buildAndShowInfoWindowCustom({
            //   data: ares,
            //   marker: (PageScript.map as AMap).getCenter()!,
            //   tableFormatter: default_tableformatter(),
            //   greyOutFields: true,
            //   sorting: [],
            // })
            // const $ele = $(`
            //   ${this.buildInfoWindowContent(collection)}
            //   <div class="btn-group btn-group-block buttons">
            //     <button class="btn btn-primary"><i class="fa fa-pencil" aria-hidden="true"></i> ${translations['Edit']}</button>
            //     <button class="btn btn-error"><i class="fa fa-trash" aria-hidden="true"></i> ${translations['Delete']}</button>
            //   </div>
            // `)
            // $ele.find('.btn-primary').click((e) => this.editForm($tr))
            // $ele.find('.btn-error').click((e) => this.deleteForm($tr))
            // $('#thirdPanel').html('')
            // $('#thirdPanel').append($ele)
            // this.switchTo(this.SCREEN_TYPE.Info)
            // // this.openInfoWindow(map, polygon)
            // this.map.setZoom(16)
            // this.scrollToPolygon(polygon)
            // this.selectPolygon(this.map, polygon)
        }).catch(AError.handle));
    }
    scrollToPolygon(polygon) {
        this.map.panTo(getPolygonCenter(polygon));
    }
    async createForm(data) {
        const { id, scale, area } = data;
        const translations = this.translations;
        const startTime = FilterManager.todayDate;
        const stopTime = new Date(startTime.getTime() + (24 * 60 * 60 * 1000));
        const $ele = $(/*html*/ `
      <div id="createForm" class="form-group" style="padding: 0 15px">
        <div class="columns">
          <span class="label label-info label-rounded">${translations['Click on the map to change the location']}</span>
        </div>
        <div class="columns">
          <label class="form-label col-6" for="input1">${translations['Type']}</label>
          <label class="form-label col-6" for="input2">${translations['Id']}</label>
        </div>
        <div class="columns">
          <select id="input1" class="form-input col-6 selectpicker" disabled>
            <option ${scale & MAP_OPTIONS.Area ? 'selected' : ''} value="Area">${translations['Area']}</option>
            <option ${scale & MAP_OPTIONS.Zone ? 'selected' : ''} value="Zone">${translations['Zone']}</option>
            <option ${scale & MAP_OPTIONS.ParkingSpace ? 'selected' : ''} value="ParkingSpace">${translations['ParkingSpace']}</option>
          </select>
          <input class="form-input col-6" autocomplete="off" type="number" id="input2" placeholder="${translations['Id']}" value="${id}" disabled>
        </div>
        <div class="columns">
          <label class="form-label col-6" for="input3">${translations['License Plate']}</label>
          <label class="form-label col-6" for="input4">${translations['Parking Right Type']}</label>
        </div>
        <div class="columns">
          <input class="form-input col-6" type="text" id="input3" placeholder="XX-XX-XX" maxlength="32">
          ${FilterManager.createParkingRightTypeOptions(null, 'input4', 'form-select col-6')}
        </div>
        <div class="columns">
          <label class="form-label col-6" for="input5-2">${translations['Vehicle Type']}</label>
          <label class="form-label col-6" for="input5">${translations['Phone']}</label>
        </div>
        <div class="columns">
          ${FilterManager.createVehicleTypeOptions(null, 'input5-2', 'form-select col-6')}
          <input class="form-input col-6" autocomplete="off" type="tel" id="input5" placeholder="${translations['Phone']}" maxlength="15">
        </div>
        <label class="form-label" for="input6">${translations['Start Date']}</label>
        <div class="columns">
          <input class="form-input col-6" autocomplete="off" type="date" id="input6" placeholder="${translations['Start Date']}" value="${AInputDate(startTime)}">
          <input class="form-input col-6" autocomplete="off" type="time" id="input7" placeholder="${translations['Start Time']}" value="${AInputTime(startTime)}">
        </div>
        <label class="form-label" for="input8">${translations['End Date']}</label>
        <div class="columns">
          <input class="form-input col-6" autocomplete="off" type="date" id="input8" placeholder="${translations['End Date']}" value="${AInputDate(stopTime)}">
          <input class="form-input col-6" autocomplete="off" type="time" id="input9" placeholder="${translations['End Time']}" value="${AInputTime(stopTime)}">
        </div>
        <br/>
        <div class="columns">
          <button id="createNew" class="btn btn-primary col-12">${translations['Create']}</button>
        </div>
      </div>`.replace(/\s\s+/g, ' '));
        // const events = Alerts.showDialog(translations['Create New Parking Right'], html, ALERTS.None, translations['Create'], translations['Cancel'])
        $('#forthPanel').html('');
        $('#forthPanel').append($ele);
        FilterManager.applyRegularExpressions();
        this.switchTo(this.SCREEN_TYPE.Create);
        // $ele.find('#selectLocation').click((e) => this.selectLocation())
        $ele.find('#createNew').on('click', (e) => {
            if (!this.isFilledOut())
                return;
            const $createForm = $('#createForm');
            const LicensePlate = $createForm.find('#input3').val(); // License Plate
            const ParkingRightType = $createForm.find('#input4').val(); // Parking Right Type
            const GeoType = $createForm.find('#input1').val(); // Geo Type
            const GeoId = $createForm.find('#input2').val(); // Geo Id
            const VehicleType = $createForm.find('#input5-2').val(); // Vehicle Type
            const Phone = $createForm.find('#input5').val(); // Phone
            const StartTime = ACombineDateTime($createForm.find('#input6').val(), $createForm.find('#input7').val()); // Start Date
            const StopTime = ACombineDateTime($createForm.find('#input8').val(), $createForm.find('#input9').val()); // Stop Date
            this.createRow({
                LicensePlate,
                ParkingRightType,
                ParkingRightAttributes: null,
                GeoType,
                GeoId,
                Phone,
                StartTime,
                StopTime,
                CreatedUser: stateService.User,
                VehicleType
            }).then((data) => this.inserted(null, data));
        });
    }
    editForm($tr) {
        const translations = this.translations;
        const { PrdbId, PrdbDeviceId, PrdbVersion, LicensePlate, ParkingRightType, VehicleType, Phone, GeoType, GeoId, StartTime, StopTime } = $tr.data('prdb');
        const scale = mapHelperService.geoTypeToMapOption(GeoType);
        Loading.waitForPromises(mapHelperService.toggleTo(this.map, GeoType)).catch(AError.handle);
        const $ele = $(/*html*/ `
      <div id="editForm" class="form-group" style="padding: 0 15px">
        <div class="columns">
          <span class="label label-info label-rounded">${translations['Click on the map to change the location']}</span>
        </div>
        <div class="columns">
          <label class="form-label col-6" for="input1">${translations['Type']}</label>
          <label class="form-label col-6" for="input2">${translations['Id']}</label>
        </div>
        <div class="columns">
          <select id="input1" class="form-input col-6 selectpicker" disabled>
            <option ${scale & MAP_OPTIONS.Area ? 'selected' : ''} value="Area">${translations['Area']}</option>
            <option ${scale & MAP_OPTIONS.Zone ? 'selected' : ''} value="Zone">${translations['Zone']}</option>
            <option ${scale & MAP_OPTIONS.ParkingSpace ? 'selected' : ''} value="ParkingSpace">${translations['ParkingSpace']}</option>
          </select>
          <input class="form-input col-6" autocomplete="off" type="number" id="input2" placeholder="${translations['Id']}" value="${GeoId}" disabled>
        </div>
        <div class="columns">
          <label class="form-label col-6" for="input3">${translations['License Plate']}</label>
          <label class="form-label col-6" for="input4">${translations['Parking Right Type']}</label>
        </div>
        <div class="columns">
          <input class="form-input col-6" type="text" id="input3" placeholder="XX-XX-XX" value="${LicensePlate}">
          ${FilterManager.createParkingRightTypeOptions(ParkingRightType, 'input4', 'form-select col-6')}
        </div>
        
        <div class="columns">
          <label class="form-label col-6" for="input5-2">${translations['Vehicle Type']}</label>
          <label class="form-label col-6" for="input5">${translations['Phone']}</label>
        </div>
        <div class="columns">
          ${FilterManager.createVehicleTypeOptions(VehicleType, 'input5-2', 'form-select col-6')}
          <input class="form-input col-6" autocomplete="off" type="tel" id="input5" placeholder="${translations['Phone']}" value="${Phone.toString()}" maxlength="15">
        </div>
        <label class="form-label" for="input6">${translations['Start Date']}</label>
        <div class="columns">
          <input class="form-input col-6" autocomplete="off" type="date" id="input6" placeholder="${translations['Start Date']}" value="${AInputDate(StartTime)}">
          <input class="form-input col-6" autocomplete="off" type="time" id="input7" placeholder="${translations['Start Time']}" value="${AInputTime(StartTime)}">
        </div>
        <label class="form-label" for="input8">${translations['End Date']}</label>
        <div class="columns">
          <input class="form-input col-6" autocomplete="off" type="date" id="input8" placeholder="${translations['End Date']}" value="${AInputDate(StopTime)}">
          <input class="form-input col-6" autocomplete="off" type="time" id="input9" placeholder="${translations['End Time']}" value="${AInputTime(StopTime)}">
        </div>
        <br>
        <div class="columns">
          <button id="update" class="btn btn-primary col-12">${translations['Update']}</button>
        </div>
      </div>`.replace(/\s\s+/g, ' '));
        // const events = Alerts.showDialog(translations['Edit Parking Right'], html, ALERTS.None, translations['Update'], translations['Cancel'])
        // events.on('option1', () => this.editFormClick($tr, $tr.data('prdb')))
        // $ele.find('#selectLocation').click((e) => this.selectLocation())
        $ele.find('#update').click(() => this.editFormClick($tr, $tr.data('prdb')));
        $('#fifthPanel').html('');
        $('#fifthPanel').append($ele);
        FilterManager.applyRegularExpressions();
        this.switchTo(this.SCREEN_TYPE.Edit);
    }
    editFormClick($tr, data) {
        if (!this.isFilledOut())
            return;
        const $editForm = $('#editForm');
        const { PrdbId, PrdbDeviceId, PrdbVersion, GeoType, GeoId, LicensePlate, ParkingRightType, VehicleType, Phone, StartTime, StopTime } = Object.assign({}, data, {
            GeoType: $editForm.find('#input1').val(),
            GeoId: $editForm.find('#input2').val(),
            LicensePlate: $editForm.find('#input3').val(),
            ParkingRightType: $editForm.find('#input4').val(),
            VehicleType: $editForm.find('#input5-2').val(),
            Phone: $editForm.find('#input5').val(),
            StartTime: ACombineDateTime($editForm.find('#input6').val(), $editForm.find('#input7').val()),
            StopTime: ACombineDateTime($editForm.find('#input8').val(), $editForm.find('#input9').val())
        });
        Loading.waitForPromises(this.createRow({
            PrdbId,
            PrdbDeviceId,
            PrdbVersion,
            GeoType,
            GeoId,
            LicensePlate,
            ParkingRightType,
            ParkingRightAttributes: null,
            VehicleType,
            Phone,
            StartTime,
            StopTime,
            CreatedUser: stateService.User
        }).then((data) => {
            // Remove prdb create button
            $('#createPrdb').remove();
            // Inserts prdb entry into the table (UI)
            return this.inserted($tr, data, 0);
        }).then((data) => {
            // Re-fetch prdb entries from database
            return this.refresh(data.LicensePlate);
        })).catch(AError.handle);
    }
    async deleteForm($tr) {
        const translations = await Translate.get([
            'Warning',
            'Do you want to delete this entry?',
            'Yes',
            'No'
        ]);
        const prdbData = $tr.data('prdb');
        const { PrdbId, PrdbDeviceId } = prdbData;
        const events = Alerts.show({
            title: ALERT_TITLES.Warning,
            content: translations['Do you want to delete this entry?'],
            buttons: ALERT_BUTTONS.yesNo
        });
        events.on(ALERT_STATUS.ON_ACTION_PROCEED, () => {
            Loading.waitForPromises(requestService.query({
                Query: 'UPDATE prdb SET Removed=1 WHERE PrdbId=:PrdbId AND PrdbDeviceId=:PrdbDeviceId',
                Params: {
                    PrdbId,
                    PrdbDeviceId
                }
            }).then(() => {
                this.hideInfoWindow();
                this.removed(prdbData);
            })).catch(AError.handle);
        });
    }
    createRow(data) {
        return new Promise((resolve, reject) => {
            let responseReceived = false;
            requestService.send("PrdbInsertRequest", data);
            Events.once("PrdbInsertResponse", (response) => {
                responseReceived = true;
                if (!response.Success) {
                    return reject(new Error(response.Error));
                }
                resolve(Object.assign({}, data, response));
            });
            Loading.waitForPromises(sleep(5000)).then(_ => {
                if (!responseReceived) {
                    AError.handle(`PrdbInsertResponse it never received! -ivan`);
                }
            });
        });
    }
    /**
     * inserts new prdb into the table (UI)
     **/
    async inserted($tr, data, incrementAmount = 1) {
        const { PrdbVersion, LicensePlate, } = data;
        let licenseChanged = false;
        if ($tr && $tr.length) {
            const proxy = $tr.data('prdb');
            proxy.PrdbVersion = PrdbVersion;
            if (proxy.LicensePlate !== LicensePlate) {
                proxy.LicensePlate = LicensePlate;
                licenseChanged = true;
            }
        }
        await Loading.waitForPromises(this.clickLicensePlate({ LicensePlate }).catch(AError.handle));
        const $td = $(`tr[license=${LicensePlate}] td:last-child`);
        if (!licenseChanged) {
            if ($td.length > 0) {
                $td.text(parseInt($td.text()) + incrementAmount);
            }
            else {
                this.fillPanel([[LicensePlate, 1]]);
            }
        }
        return data;
    }
    removed(data, decrementAmount = 1) {
        const { PrdbId, PrdbDeviceId, LicensePlate } = data;
        const $tr = $(`tr[license=${LicensePlate}]`);
        const $td = $tr.find('td:last-child');
        if ($td.length > 0) {
            const amount = parseInt($td.text()) - decrementAmount;
            if (amount <= 0) {
                $tr.remove();
            }
            this.removeFromSecondPanel(PrdbId, PrdbDeviceId);
        }
        const $trPrdb = $(`tr[prdbid="${PrdbId}-${PrdbDeviceId}"]`);
        const $parent = $trPrdb.parent();
        // Go back to prdb listing if exists, otherwise go back to license plate listing
        this.goBack(($parent.children().length === 2) ? this.screenType - 1 : this.screenType);
        // Remove prdb listing
        $trPrdb.remove();
    }
    isFilledOut() {
        let output = true;
        const start = ACombineDateTime($('#input6').val(), $('#input7').val());
        const end = ACombineDateTime($('#input8').val(), $('#input9').val());
        if (start > end) {
            return Alerts.incorrectDate();
            // @ts-ignore
        }
        else if (!$('#input1').val().length) {
            output = false;
            // @ts-ignore
        }
        else if (!$('#input2').val().length) {
            output = false;
            // @ts-ignore
        }
        else if (!$('#input3').val().length) {
            output = false;
            // @ts-ignore
        }
        else if (!$('#input4').val().length) {
            output = false;
            // @ts-ignore
        }
        else if (!$('#input5-2').val().length) {
            output = false;
        }
        if (!output) {
            Alerts.incomplete();
        }
        return output;
    }
}
export function render() {
    return ( /*html*/`
    <div id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column col-12">
        <div class="form-group">
          <label class="form-label" for="FromDate">From</label>
          <input class="form-input" type="date" id="FromDate" required="required">
          <input class="form-input" type="time" id="FromTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="ToDate">To</label>
          <input class="form-input" type="date" id="ToDate" required="required">
          <input class="form-input" type="time" id="ToTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="LicensePlate">License Plate</label>
          <input class="form-input" type="text" id="LicensePlate" maxlength="32" LicensePlate />
        </div>

        <div class="form-group">
          <label class="form-label" for="Limit">Max results</label>
          <input class="form-input" type="number" id="Limit" value="2000">
        </div>
      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </div>
    <div id="Rapport" class="flex-child">
      <div class="fh" style="position: relative">
        <div class="wrapper lg columns col-gapless">
          <div class="column col-4" style="background: #f1f0f0; height: 100%">
            <div id="firstPanel" class="list-container" style="overflow-y: auto; height: calc(100% - 45px)">
              <table class="styled-table grid-like fw">
                <thead>
                  <tr class="head noselect">
                    <th>License Plates</th>
                    <th>Rights</th>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
            </div>
            <div id="secondPanel" class="list-container"
              style="overflow-y: auto; height: calc(100% - 45px); display: none;">
              <table class="table table-striped table-hover">
                <tr>
                  <th id="DisplayLicense">Parking Rights</th>
                  <th>Details</th>
                  <th>Editable</th>
                </tr>
              </table>
            </div>
            <div id="thirdPanel" class="list-container" style="overflow-y: auto; height: calc(100% - 45px); display: none;">
            </div>
            <div id="forthPanel" class="list-container" style="overflow-y: auto; height: calc(100% - 45px); display: none;">
            </div>
            <div id="fifthPanel" class="list-container" style="overflow-y: auto; height: calc(100% - 45px); display: none;">
            </div>
            <div id="lastPanel" class="list-container" style="background: #f8f9fa; height: 45px">
              <div class="columns">
                <button class="btn btn-link column col-11 col-mx-auto" style="margin-top: 5px" disabled><i
                    class="fa fa-arrow-left"></i> Go Back</button>
              </div>
            </div>
          </div>
          <div class="column col-8" style="height: 100%; background: #f8f9fa">
            <div id="map" class="aci-map"></div>
          </div>
        </div>
      </div>
    </div>
  `);
}
