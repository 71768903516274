import { AConfig } from "../classes/AConfig.js";
import { AEngine } from "../core/AEngine.js";
import { AStatisticsService } from "./AStatisticsService.js";
export class AStatisticsSimpleService extends AStatisticsService {
    async fetchQueries(filters, options) {
        const { baseTable, onlyAllowFinalized, ignoreDetectionsOutsideSegment, extraWhereClause, groupA, groupB, skipSegmentsQuery } = options;
        const { DetectionDeviceId } = filters;
        AEngine.warn(`AStatisticsService overridden by AStatisticsSimpleService`);
        const conditions = [
            (DetectionDeviceId && DetectionDeviceId !== '%') ? 'df.DetectionDeviceId=:DetectionDeviceId' : null,
            (onlyAllowFinalized === true) ? 'FinalizedSuccess = 1' : null,
            (ignoreDetectionsOutsideSegment === true) ? 'se.SegmentTimeStamp IS NOT NULL' : null,
            (extraWhereClause) ? extraWhereClause : null,
        ].filter(v => v !== null);
        const additionalWhere = conditions?.length ? (' AND ' + conditions.join(' AND ')) : '';
        // filters["MinEnforcing"] = configService.get('general.minEnforcingForOccupancy')
        filters["MinEnforcing"] = AConfig.get(`general.minEnforcingForOccupancy`, 0.1);
        // ${extraGroupBy ? `${extraGroupBy} AS GroupB,` : ''}
        // ${gdgob.GeoId AS SegmentId}
        const sGroupByA = groupA ? groupA.columnSelect : undefined;
        const sGroupByB = groupB ? groupB.columnSelect : undefined;
        let detections = await requestService.query({
            Query: (`
        SELECT
          Digital,
          IllegallyParked,
          TimeLimitedParking,
          ParkingRight,
          Verification,
          DetectionState,
          count(*) as Total,
          ${sGroupByB ? `${sGroupByB} AS GroupB,` : ''}
          ${sGroupByA ? `${sGroupByA}` : 'dgob.GeoId'} AS GroupA
        FROM ${baseTable} df
        LEFT JOIN detection_geo_object_best dgob ON (df.DetectionId = dgob.DetectionId AND df.DetectionDeviceId = dgob.DetectionDeviceId AND dgob.GeoType='Segment')
        LEFT JOIN segment_entries se ON (se.SegmentTimeStamp = dgob.TimeStamp AND se.SegmentId = dgob.GeoId)
        ${filters.ZoneMulti ? `INNER JOIN detection_geo_object_best zone ON (df.DetectionId = zone.DetectionId AND df.DetectionDeviceId = zone.DetectionDeviceId AND zone.GeoType='Zone' AND zone.GeoId ${FilterManager.buildQueryFindInArray(filters.ZoneMulti)})` : ''}
        ${filters.AreaMulti ? `INNER JOIN detection_geo_object_best area ON (df.DetectionId = area.DetectionId AND df.DetectionDeviceId = area.DetectionDeviceId AND area.GeoType='Area' AND area.GeoId ${FilterManager.buildQueryFindInArray(filters.AreaMulti)})` : ''}
        WHERE
          DetectionTime BETWEEN :FromDate AND :ToDate
          ${additionalWhere}
        GROUP BY
          ${sGroupByA ? `${sGroupByA},` : 'dgob.GeoId,'}
          ${sGroupByB ? `${sGroupByB},` : ''}
          Digital,
          TimeLimitedParking,
          IllegallyParked,
          ParkingRight,
          Verification,
          DetectionState
      `),
            Params: filters
        });
        // const groupBySegments = [
        //   sGroupByB,
        //   (filters.ZoneMulti) ? 'gc.ToGeoId' : undefined,
        //   (filters.AreaMulti) ? 'gc2.ToGeoId' : undefined,
        // ].filter(v => v != null).join(', ')
        let segmentEntries = (skipSegmentsQuery) ? {
            Columns: [],
            ColumnsTranslated: [],
            Rows: []
        } : await requestService.query({
            Query: ( /*sql*/`
        SELECT
          IFNULL(AVG(OccupancyCount), 0) AS OccupancyAvg,
          AVG(Capacity) AS CapacityAvg,
          IFNULL(AVG(Capacity), 0) AS SeenCapacityAvg,
          COUNT(*) AS EntryCount,
          se.SegmentId AS GroupA
        FROM
          segment_entries se
          
          ${filters.ZoneMulti ? `LEFT JOIN geo_connections gc ON (se.SegmentId=gc.FromGeoId AND gc.FromGeoType='Segment' AND gc.ToGeoType='Zone' AND (gc.ToGeoId ${FilterManager.buildQueryFindInArray(filters.ZoneMulti)}))` : ''}
          ${filters.AreaMulti ? `LEFT JOIN geo_connections gc2 ON (se.SegmentId=gc2.FromGeoId AND gc2.FromGeoType='Segment' AND gc2.ToGeoType='Area' AND (gc2.ToGeoId ${FilterManager.buildQueryFindInArray(filters.AreaMulti)}))` : ''}
        WHERE
          se.SegmentTimeStamp BETWEEN :FromDate AND :ToDate AND
          se.Enforcing >= :MinEnforcing AND
          Capacity > 0
          ${filters.ZoneMulti ? `AND gc.FromGeoId IS NOT NULL` : ''}
          ${filters.AreaMulti ? `AND gc2.FromGeoId IS NOT NULL` : ''}
        GROUP BY
          se.SegmentId
      `),
            Params: filters
        });
        return { detections, segmentEntries };
    }
}
