import { AError } from "../../classes/AError.js";
import { ALoopTimer } from "../../classes/ALoopTimer.js";
import { AEngine } from "../../core/AEngine.js";
import { MAP_OPTIONS } from "../../core/maps/AMapStructs.js";
import { ADragService } from "../../services/ADragService.js";
import { ATimeService } from "../../services/ATimeService.js";
import { createMap } from "../../utils/maps.js";
import { AConvertToGridData, AShowTable, AStoreState, TransformObjectsToResponse, formatStringToPascal } from "../../utils/tools.js";
const timeService = AEngine.get(ATimeService);
export class APage {
    constructor() {
        this.SessionMarkers = {};
        this.map = createMap('map', {
            streetViewControl: true
        });
        const dragService = AEngine.getOrCreateInstance(ADragService);
        dragService.createDraggableGeneric({
            defaultSize: ['40%', '75%']
        });
    }
    async init() {
        this.refreshLoop = new ALoopTimer(() => this.grid?.refreshRows(), { loopLifeCycle: "PAGE", timeout: 1000 }).start();
        await mapHelperService.prepareMapItems(MAP_OPTIONS.All, {
            allowExport: false,
            showSearch: true
        }).catch(AError.handle);
        await timeService.prefetch();
        this.columns = await this.genColumns();
        nodeSessionService.bindSessionsToMap({
            interpolate: false,
            mapMarkers: this.SessionMarkers,
            map: this.map,
            onChange: () => this.refresh()
        });
    }
    async genColumns() {
        const columns = [
            { field: 'NodeName', text: '', hidden: true },
            { field: 'Device', text: '' },
            { field: 'User', text: '' },
            {
                field: 'Status',
                text: '',
                htmlEncode: false,
                renderer: (opt) => {
                    const style = ''; // opt.record.ValidLogin ? "--fa-animation-duration: 1s; --fa-beat-scale: 0.8;" : ''
                    return ( /*html*/`
            <div class="menu-label children-inline-block">
              <span class="statusbar ${opt.record.Status}">
                <i class="fa-solid fa-circle" style="${style}"></i>
                <span>${formatStringToPascal(opt?.record?.StatusString ?? '')}</span>
              </span>
            </div>
          `);
                }
            },
            {
                field: 'ComState',
                text: '',
                hidden: true,
                // ...COLUMN_ACTION({ iconCls: 'fa-regular fa-trash text-red', btnCls: 'btn-white' }),
            },
            {
                field: 'LastUpdated',
                text: '',
                renderer: (opt) => {
                    const timeAgo = timeService.agoSync(new Date(), new Date(opt?.record?.LastUpdated));
                    return timeAgo;
                }
            },
            { field: 'StatusString', text: '', hidden: true },
        ];
        await Translate.get(columns.map(c => c.field)).then((t) => {
            columns.map(c => c.text = t[c.field]);
        });
        return columns;
    }
    async refresh() {
        const sessionArr = await Promise.all(Object.keys(this.SessionMarkers).map(async (k) => {
            const session = this.SessionMarkers[k];
            const d = session.data ?? {};
            return {
                NodeName: d.NodeName,
                Device: await Translate.get(d.NodeName),
                User: d.UserDisplayName || d.User,
                Status: d.Status,
                StatusString: d.StatusString,
                ComState: d.ComState,
                LastUpdated: d.Gps.GpsTime,
                ValidLogin: d.ValidLogin,
            };
        }));
        const ares = await TransformObjectsToResponse(sessionArr);
        if (this.grid && this.grid.store.records.length > 0) {
            const storedState = AStoreState(this.grid, { primaryKey: 'NodeName' });
            this.grid.store.data = AConvertToGridData(ares, {});
            storedState.restore();
        }
        else {
            this.grid = AShowTable({
                appendTo: 'table-bryntum',
                columns: this.columns,
                data: AConvertToGridData(ares, {})
            });
        }
        this.combineTableWithMap();
    }
    combineTableWithMap() {
        Object.keys(this.SessionMarkers).map((NodeName) => {
            const m = this.SessionMarkers[NodeName];
            m.addListener("click", (e) => {
                if (e.src !== 'grid') {
                    const foundRecord = this.grid.store.find(record => {
                        return m.get('NodeName') === record.data.NodeName;
                    });
                    this.grid.selectedRecord = foundRecord;
                }
            });
        });
        this.grid.on('selectionchange', async (e) => {
            try {
                const record = this.grid.selectedRecord?.data;
                if ((e.source?.event?.button ?? 0) !== 0) {
                    this.grid.deselectAll();
                    await purgatoryService.closeInfoWindow();
                    return;
                }
                if (e.mode !== 'row' || !record)
                    return;
                const marker = this.SessionMarkers[record.NodeName];
                new google.maps.event.trigger(marker, 'click', { src: 'grid' });
            }
            catch (err) {
                AError.handle(err);
            }
        });
    }
}
export function render() {
    return ( /*html*/`
		<div class="splitter" style="position: relative; height: 100%;">
      <div class="drag-section part-one" style="background: #f1f0f0; height: 100%">
        <div id="firstPanel" class="list-container" style="overflow-y: auto; height: calc(100% - 45px)">
          <div id="table-bryntum" class="fh fw"></div>
        </div>
        <div id="lastPanel" class="list-container" style="background: #f8f9fa; height: 45px">
          <div id="count" class="text spacing01">
            Viewing
            <span>0</span>
            Devices
          </div>
        </div>
      </div>
      <div id="separator1" class="drag-seperator separator"></div>
      <div class="drag-section part-two">
        <div id="map" class="aci-map"></div>
      </div>
		</div>
	`);
}
