// @ts-nocheck
import { _arrayBufferToBase64 } from "../utils/tools.js";
import { aesjs } from "./aes.js";
/// <!--=======================================================================================================-->
///                              ACCCClient
/// <!----------------------------------------------------------------------------------------------------------->
export function ACCCClient(Settings) {
    this.Enabled = true;
    let Utf8Decoder = new TextDecoder('utf-8');
    let Utf8Encoder = new TextEncoder();
    let Confirm = Settings.CustomConfirm;
    let Alert = Settings.CustomAlert;
    if (!Confirm) {
        Confirm = function (Display, onOk, onCancel) {
            if (window.confirm(Display)) {
                if (onOk) {
                    onOk();
                }
            }
            else {
                if (onCancel) {
                    onCancel();
                }
            }
        };
    }
    if (!Alert) {
        Alert = function (Display) {
            alert(Display);
        };
    }
    let UseEncryption = Settings.UseEncryption !== "false" && Settings.UseEncryption !== false && Settings.UseEncryption !== null;
    let Key = aesjs.utils.hex.toBytes(Settings.Key);
    let HandleMsg = Settings.HandleMsg;
    let HandleComState = Settings.HandleComState;
    let HandleExit = Settings.HandleExit;
    let HandleSessionState = Settings.HandleSessionState;
    let ShowSettings = function () { window.location.href = "/settings.html"; };
    this.NodeType = Settings.NodeType;
    this.NodeIndexNumber = Settings.NodeIndexNumber;
    this.NodeCustomerNumber = Settings.NodeCustomerNumber;
    this.NodeProjectNumber = Settings.NodeProjectNumber;
    this.StreamRef = null;
    let SessionActive = false;
    let WaitingForSync = false;
    let Stream = null;
    let UseHeader = true;
    let BlockSize = 0x10000;
    let MessageOutQueue = [];
    let AcknowlageQueue = [];
    let PartialReadMessage = { MessageId: 0, DataOffset: 0, Data: null };
    let PartialWriteMessage = { MessageId: 0, DataOffset: 0, Data: null };
    let ASyncWrite = null;
    let ASyncStart = null;
    let Debug = false;
    let SyncRetry = 0;
    let CurrentWriteMessageId = 0;
    function Aligned(Value, AlignmentBytes) {
        return (Value % AlignmentBytes == 0) ? Value : (Value + (AlignmentBytes - (Value % AlignmentBytes)));
    }
    function ACCCHeader(arg) {
        this.Store = function (Buffer) {
            let View = new DataView(Buffer);
            // @ts-ignore
            View.setUint8(0, this.HeaderSize, true);
            // @ts-ignore
            View.setUint8(1, this.HeaderVersion, true);
            // @ts-ignore
            View.setUint8(2, this.Type, true);
            // @ts-ignore
            View.setUint8(3, this.Priority, true);
            View.setUint32(4, this.BlockSize, true);
            View.setUint32(8, this.MessageOffset % 0x100000000, true);
            View.setUint32(12, this.MessageOffset / 0x100000000, true);
            View.setUint32(16, this.MessageId % 0x100000000, true);
            View.setUint32(20, this.MessageId / 0x100000000, true);
            View.setUint32(24, this.MessageSize % 0x100000000, true);
            View.setUint32(28, this.MessageSize / 0x100000000, true);
        };
        this.ToBuffer = function () {
            let Buffer = new ArrayBuffer(32);
            this.Store(Buffer);
            return new Uint8Array(Buffer);
        };
        this.Load = function (Buffer) {
            let View = new DataView(Buffer);
            // @ts-ignore
            this.HeaderSize = View.getUint8(0, true);
            // @ts-ignore
            this.HeaderVersion = View.getUint8(1, true);
            // @ts-ignore
            this.Type = View.getUint8(2, true);
            // @ts-ignore
            this.Priority = View.getUint8(3, true);
            this.BlockSize = View.getUint32(4, true);
            this.MessageOffset = View.getUint32(8, true) + (View.getUint32(12, true) * 0x100000000);
            this.MessageId = View.getUint32(16, true) + (View.getUint32(20, true) * 0x100000000);
            this.MessageSize = View.getUint32(24, true) + (View.getUint32(28, true) * 0x100000000);
        };
        this.Log = function (Name) {
            if (!Debug)
                return;
            console.log("-----------");
            console.log(Name, "HeaderSize", this.HeaderSize);
            console.log(Name, "HeaderVersion", this.HeaderVersion);
            console.log(Name, "Type", this.Type);
            console.log(Name, "Priority", this.Priority);
            console.log(Name, "BlockSize", this.BlockSize);
            console.log(Name, "MessageOffset", this.MessageOffset);
            console.log(Name, "MessageId", this.MessageId);
            console.log(Name, "MessageSize", this.MessageSize);
            console.log("-----------");
        };
        if (arg) {
            this.Load(arg);
        }
        else {
            this.HeaderSize = 32;
            this.HeaderVersion = 1;
            this.Type = 0;
            this.Priority = 0;
            this.BlockSize = 0;
            this.MessageOffset = 0;
            this.MessageId = 0;
            this.MessageSize = 0;
        }
    }
    function DecodeUtf8(value) {
        if (typeof value == "string") {
            return value;
        }
        return Utf8Decoder.decode(value);
    }
    function EncodeUtf8(utf8) {
        return Utf8Encoder.encode(utf8);
    }
    /// <!--=======================================================================================================-->
    ///                              Decrypt (https://github.com/ricmoo/aes-js)
    /// <!----------------------------------------------------------------------------------------------------------->
    function Decrypt(Encrypted, DecryptedSize = 0) {
        if (!UseEncryption) {
            return Encrypted;
        }
        try {
            let IV = new Uint8Array(Encrypted.slice(0, 16));
            let Buffer = new Uint8Array(Encrypted.slice(16));
            let aesCbc = new aesjs.ModeOfOperation.cbc(Key, IV);
            let Padded = aesCbc.decrypt(Buffer);
            let Decrypted = (DecryptedSize == 0) ? aesjs.padding.pkcs7.strip(Padded) : Padded.slice(0, DecryptedSize);
            return Decrypted;
        }
        catch (e) {
            console.log("Decrypt error:", e);
            location.href = '/settings.html';
        }
    }
    /// <!--=======================================================================================================-->
    ///                              Encrypt (https://github.com/ricmoo/aes-js)
    /// <!----------------------------------------------------------------------------------------------------------->
    function Encrypt(Decrypted, Kcs7Padded = true) {
        if (!UseEncryption) {
            return Decrypted;
        }
        try {
            let IV = new Uint8Array(16);
            if (crypto.getRandomValues !== undefined) {
                crypto.getRandomValues(IV);
            }
            else {
                for (let i = 0; i < 16; i++) {
                    IV[i] = Math.random() * 256;
                }
            }
            let aesCbc = new aesjs.ModeOfOperation.cbc(Key, IV);
            let Padded = null;
            if (Kcs7Padded) {
                Padded = aesjs.padding.pkcs7.pad(Decrypted);
            }
            else if (Decrypted.length % 16 == 0) {
                Padded = Decrypted;
            }
            else {
                Padded = new Uint8Array(Decrypted.length + 16 - (Decrypted.length % 16));
                Padded.set(Decrypted);
            }
            let Buffer = aesCbc.encrypt(Padded);
            let Encrypted = new Uint8Array(16 + Buffer.length);
            Encrypted.set(IV);
            Encrypted.set(Buffer, 16);
            return Encrypted;
        }
        catch (e) {
            console.log("Encrypt error:", e);
        }
    }
    /// <!--=======================================================================================================-->
    ///                              InternalProcessQueue
    /// <!----------------------------------------------------------------------------------------------------------->
    function InternalProcessQueue() {
        ASyncWrite = null;
        if (!SessionActive) {
            return;
        }
        let QueueLength = MessageOutQueue.length;
        if (PartialWriteMessage.Data == null || PartialWriteMessage.Data.length == PartialWriteMessage.DataOffset) {
            if (!QueueLength) {
                return;
            }
            let Message = MessageOutQueue.shift();
            let Buffer = EncodeUtf8(JSON.stringify(Message));
            if (!UseHeader) {
                Stream.send(Encrypt(Buffer));
                return;
            }
            PartialWriteMessage.MessageId = ++CurrentWriteMessageId;
            PartialWriteMessage.Data = Buffer;
            PartialWriteMessage.DataOffset = 0;
            AcknowlageQueue.push({ MessageId: PartialWriteMessage.MessageId, Message: Message });
        }
        let RemainingBytes = PartialWriteMessage.Data.length - PartialWriteMessage.DataOffset;
        let Header = new ACCCHeader();
        Header.HeaderSize = 32;
        Header.HeaderVersion = 1;
        Header.Type = 0;
        Header.Priority = 0;
        Header.BlockSize = RemainingBytes < BlockSize ? RemainingBytes : BlockSize;
        Header.MessageOffset = PartialWriteMessage.DataOffset;
        PartialWriteMessage.DataOffset += Header.BlockSize;
        Header.MessageId = PartialWriteMessage.MessageId;
        Header.MessageSize = PartialWriteMessage.Data.length;
        Header.Log("Out");
        let Buffer = PartialWriteMessage.Data.slice(Header.MessageOffset, PartialWriteMessage.DataOffset);
        let Encrypted = Encrypt(Buffer, false);
        let Size = 32 + (UseEncryption ? Aligned(Header.BlockSize, 16) + 16 : Header.BlockSize);
        let Package = new Uint8Array(Size);
        Package.set(Header.ToBuffer());
        Package.set(Encrypted, 32);
        Stream.send(Package);
        ProcessQueue(QueueLength);
    }
    /// <!--=======================================================================================================-->
    ///                              ProcessQueue
    /// <!----------------------------------------------------------------------------------------------------------->
    function ProcessQueue(QueueLength) {
        if (ASyncWrite) { // process queue is busy
            return;
        }
        // const highlight = 'color: #00cdff',
        //          object = 'color: red',
        //          normal = 'color: black';
        // console.log(`%c[%cACCCClient%c] Communication Queue: %c${ QueueLength - 1 }`, normal, highlight, normal, object);
        ASyncWrite = setTimeout(InternalProcessQueue, 0);
    }
    /// <!--=======================================================================================================-->
    ///                              SendMessage
    /// <!----------------------------------------------------------------------------------------------------------->
    function SendMessageObject(Message) {
        let InsertIndex = MessageOutQueue.length;
        for (let i = 0; i < MessageOutQueue.length; i++) {
            if (MessageOutQueue[i].Priority < Message.Priority) {
                InsertIndex = i;
                break;
            }
        }
        MessageOutQueue.splice(InsertIndex, 0, Message);
        if (SessionActive) {
            ProcessQueue(MessageOutQueue.length);
        }
    }
    /// <!--=======================================================================================================-->
    ///                              SendMessage
    /// <!----------------------------------------------------------------------------------------------------------->
    function SendMessage(Type, Version, Data, Priority = 0, To = null) {
        let Time = new Date().toJSON();
        let Message = { Type: Type, Version: Version, Time: Time, Priority: Priority, Data: Data };
        if (To !== null) {
            Message.To = To;
        }
        SendMessageObject(Message);
    }
    /// <!--=======================================================================================================-->
    ///                              ParseHello
    /// <!----------------------------------------------------------------------------------------------------------->
    function ParseSync(Sync) {
        WaitingForSync = false;
        if (SyncRetry == 0) {
            SessionActive = true;
            if (HandleComState)
                HandleComState("SessionStarted");
            // No Sync needed
            return;
        }
        let SyncRead = new ACCCHeader(Sync.slice(0, 32));
        if (SyncRead.Type != 2) /// Sync
         {
            SetFatalError("Expected Sync!");
            return;
        }
        console.log("Sync received: ", SyncRead.MessageId, SyncRead.MessageOffset, "/", SyncRead.MessageSize);
        console.log("Write State: ", PartialWriteMessage.MessageId, PartialWriteMessage.DataOffset);
        console.log("AcknowlageQueue Count:", AcknowlageQueue.length);
        let Messages = AcknowlageQueue;
        AcknowlageQueue = [];
        while (Messages.length) {
            let Message = Messages.pop();
            console.log("MessageId:", Message.MessageId);
            if (Message.MessageId == SyncRead.MessageId) {
                CurrentWriteMessageId = SyncRead.MessageId;
                console.log(Message.Message.Data);
                PartialWriteMessage.Data = EncodeUtf8(JSON.stringify(Message.Message));
                PartialWriteMessage.DataOffset = SyncRead.MessageOffset;
                PartialWriteMessage.MessageId = SyncRead.MessageId;
                CurrentWriteMessageId = SyncRead.MessageId;
                if (SyncRead.MessageOffset != SyncRead.MessageSize) {
                    AcknowlageQueue.push(Message);
                }
            }
            else if (Message.MessageId > SyncRead.MessageId) {
                if (Message.MessageId == SyncRead.MessageId + 1) {
                    CurrentWriteMessageId = SyncRead.MessageId;
                }
                console.log(Message);
                SendMessageObject(Message.Message);
            }
        }
        if (SyncRead.MessageOffset == SyncRead.MessageSize) {
            CurrentWriteMessageId = SyncRead.MessageId;
            PartialWriteMessage = { MessageId: SyncRead.MessageId, DataOffset: 0, Data: null };
        }
        else if (SyncRead.MessageOffset == PartialWriteMessage.DataOffset) {
            CurrentWriteMessageId = SyncRead.MessageId;
            if (!PartialWriteMessage.Data) {
                console.error("!PartialWriteMessage.Data");
                HandleExit();
            }
        }
        else {
            SetFatalError("Couldn't restore connection. Out of sync.");
            return;
        }
        SyncRetry = 0;
        SessionActive = true;
        if (HandleComState)
            HandleComState("SessionContinued");
        ProcessQueue();
    }
    /// <!--=======================================================================================================-->
    ///                              OnStreamOpen
    /// <!----------------------------------------------------------------------------------------------------------->
    function OnStreamOpen(event) {
        if (HandleSessionState) {
            HandleSessionState(true);
        }
        WaitingForSync = true;
        let Sync = new ACCCHeader();
        Sync.HeaderSize = 32;
        Sync.HeaderVersion = 1;
        Sync.Type = 2;
        Sync.Priority = 0;
        Sync.BlockSize = 0;
        Sync.MessageOffset = PartialReadMessage.DataOffset;
        Sync.MessageId = PartialReadMessage.MessageId;
        Sync.MessageSize = PartialReadMessage.Data ? PartialReadMessage.Data.byteLength : 0;
        Stream.send(Sync.ToBuffer());
    }
    /// <!--=======================================================================================================-->
    ///                              OnStreamMessage
    /// <!----------------------------------------------------------------------------------------------------------->
    function SetFatalError(Error) {
        if (HandleComState)
            HandleComState("FatalError");
        Stop(Error, 3001);
        Alert("Fatal error in communication!\r\n" + Error);
    }
    /// <!--=======================================================================================================-->
    ///                              OnStreamMessage
    /// <!----------------------------------------------------------------------------------------------------------->
    function OnStreamMessage(event) {
        if (WaitingForSync) {
            ParseSync(event.data);
            return;
        }
        if (!UseHeader) {
            HandleMsg(JSON.parse(DecodeUtf8(Decrypt(event.data))));
            return;
        }
        if (event.data.byteLength < 32) {
            SetFatalError("Not enougth bytes, need at least 32 got " + event.data.byteLength);
            return;
        }
        //console.log( event.data);
        let Header = new ACCCHeader(event.data.slice(0, 32));
        let Data = new Uint8Array(event.data.slice(32));
        if (Header.HeaderSize != 32) {
            SetFatalError("Invalid header size.");
            return;
        }
        if (Header.HeaderVersion != 1) {
            SetFatalError("Invalid header version.");
            return;
        }
        if (Header.Type == 1) /// Acknowlage
         {
            var Ack = AcknowlageQueue.shift();
            if (Ack.MessageId != Header.MessageId) {
                /// Fatal Error
                console.error(Ack.MessageId, "!=", Header.MessageId);
                return;
            }
            return;
        }
        if (Header.Type != 0) /// Acknowlage
         {
            SetFatalError("Invalid header type.");
            return;
        }
        if (Header.BlockSize == 0) {
            /// Funny, no data!
            console.error(`ACCCClient received no data!`);
            return;
        }
        Header.Log("in");
        if (Header.MessageOffset == 0) {
            if (PartialReadMessage.Data && PartialReadMessage.DataOffset != PartialReadMessage.Data.byteLength) {
                SetFatalError("Last message not finished!");
                return;
            }
            if (PartialReadMessage.Data && PartialReadMessage.Data.byteLength != PartialReadMessage.DataOffset) {
                console.error("Header.MessageId", Header.MessageId);
                console.error("PartialReadMessage.MessageId", PartialReadMessage.MessageId);
                console.error("Header.MessageOffset", Header.MessageOffset);
                console.error("PartialReadMessage.DataOffset", PartialReadMessage.DataOffset);
                console.error("PartialReadMessage.Data.byteLength", PartialReadMessage.Data.byteLength);
                SetFatalError("Communication out of sync!");
                return;
            }
        }
        else {
            if (PartialReadMessage.MessageId != Header.MessageId) {
                console.error("Header.MessageId", Header.MessageId);
                console.error("PartialReadMessage.MessageId", PartialReadMessage.MessageId);
                console.error("Header.MessageOffset", Header.MessageOffset);
                console.error("PartialReadMessage.DataOffset", PartialReadMessage.DataOffset);
                console.error("PartialReadMessage.Data.byteLength", PartialReadMessage.Data.byteLength);
                SetFatalError("m_PartialReadMessage.MessageId != Header.MessageId");
                return;
            }
            if (Header.MessageOffset != PartialReadMessage.DataOffset) {
                console.error("Header.MessageId", Header.MessageId);
                console.error("PartialReadMessage.MessageId", PartialReadMessage.MessageId);
                console.error("Header.MessageOffset", Header.MessageOffset);
                console.error("PartialReadMessage.DataOffset", PartialReadMessage.DataOffset);
                console.error("PartialReadMessage.Data.byteLength", PartialReadMessage.Data.byteLength);
                SetFatalError("Communication out of sync!");
                return;
            }
        }
        let Decrypted = UseEncryption ? Decrypt(Data, Header.BlockSize) : Data;
        if (Header.MessageOffset == 0 && Header.MessageSize == Header.BlockSize) {
            PartialReadMessage.MessageId = Header.MessageId;
            PartialReadMessage.DataOffset = 0;
            PartialReadMessage.Data = Decrypted;
        }
        else {
            if (Header.MessageOffset == 0) {
                PartialReadMessage.MessageId = Header.MessageId;
                PartialReadMessage.DataOffset = 0;
                PartialReadMessage.Data = new Uint8Array(Header.MessageSize);
            }
            PartialReadMessage.Data.set(Decrypted, PartialReadMessage.DataOffset);
        }
        PartialReadMessage.DataOffset += Header.BlockSize;
        if (PartialReadMessage.Data.byteLength != PartialReadMessage.DataOffset) {
            return;
        }
        let Acknowlage = new ACCCHeader();
        Acknowlage.HeaderSize = 32;
        Acknowlage.HeaderVersion = 1;
        Acknowlage.Type = 1;
        Acknowlage.Priority = 0;
        Acknowlage.BlockSize = 0;
        Acknowlage.MessageOffset = 0;
        Acknowlage.MessageId = Header.MessageId;
        Acknowlage.MessageSize = 0;
        Stream.send(Acknowlage.ToBuffer());
        HandleMsg(JSON.parse(DecodeUtf8(PartialReadMessage.Data)));
    }
    /// <!--=======================================================================================================-->
    ///                              OnStreamError
    /// <!----------------------------------------------------------------------------------------------------------->
    function OnStreamError(event) {
        HandleExit();
        console.error("error", event);
    }
    /// <!--=======================================================================================================-->
    ///                              OnStreamClose
    /// <!----------------------------------------------------------------------------------------------------------->
    function OnStreamClose(event) {
        if (HandleSessionState) {
            HandleSessionState(false);
        }
        if ((event.code == 3000 || event.code == 1006) && SessionActive && BlockSize > 1024) {
            BlockSize = BlockSize / 2;
        }
        Reset();
        console.log("close", event.code, event.reason);
        if (event.code != 1000 && event.code != 3001 && event.code != 3002) {
            if (ASyncStart) {
                clearTimeout(ASyncStart);
            }
            ASyncStart = setTimeout(function () { Start.apply(this, [true]); }, 10);
            if (HandleComState)
                HandleComState("IOError");
            return;
        }
        if (HandleComState)
            HandleComState("SessionStopped");
        Clear();
        if (event.code == 3002) {
            if (UseEncryption && (!Key || Key.length != 32)) {
                Confirm("Invalid encryption key length, it should be 32 characters.\n\nDo you want edit the settings?", ShowSettings);
            }
            else {
                Confirm(event.reason + "\n\nDo you want edit the settings?", ShowSettings);
            }
        }
        else {
            Confirm(event.reason + "\n\nDo you want to restart?", function () {
                window.location.reload();
                // if (ASyncStart) {
                //    clearTimeout(ASyncStart);
                // }
                // ASyncStart = setTimeout(Start, 1000);
            });
        }
        HandleExit();
    }
    /// <!--=======================================================================================================-->
    ///                              Start
    /// <!----------------------------------------------------------------------------------------------------------->
    function Start(Restore) {
        ASyncStart = null;
        let Attributes = {};
        if (this === undefined) {
            Confirm(`Connection Interrupted!\n\nDo you want to restart?`, () => window.location.reload());
        }
        // Attributes.DeviceId = this.NodeType + this.NodeIndexNumber;
        Attributes.Hello = (UseEncryption && Key && Key.length == 32) ?
            _arrayBufferToBase64(Encrypt(EncodeUtf8("Hello"))) : "Hello";
        Attributes.BlockSize = BlockSize;
        Attributes.TimeoutSeconds = 30;
        if (Restore && (PartialReadMessage.MessageId || PartialReadMessage.MessageId) && SyncRetry < 10) {
            SyncRetry++;
            Attributes.Restore = "true";
        }
        else {
            Clear();
        }
        let WSUrl = '';
        const devConfig = JSON.parse($('#dev-config').text() || '{}');
        if (Object.keys(devConfig).length === 0) {
            let Root = window.location.href.split("/")[2];
            let Protocol = window.location.href.split("://")[0] == "https" ? "wss://" : "ws://";
            WSUrl = Protocol + Root + "/WebSocket.ccc?";
        }
        else {
            WSUrl = devConfig.websocket;
        }
        let First = true;
        for (var key in Attributes) {
            if (First) {
                First = false;
            }
            else {
                WSUrl += "&";
            }
            WSUrl += key + "=" + encodeURIComponent(Attributes[key]);
        }
        try {
            Stream = new WebSocket(WSUrl);
            this.StreamRef = Stream;
        }
        catch (e) {
            HandleExit();
            return console.error(e);
        }
        Stream.binaryType = 'arraybuffer';
        Stream.addEventListener('open', OnStreamOpen);
        Stream.addEventListener('error', OnStreamError);
        Stream.addEventListener('close', OnStreamClose);
        Stream.addEventListener('message', OnStreamMessage);
    }
    /// <!--=======================================================================================================-->
    ///                              Stop
    /// <!----------------------------------------------------------------------------------------------------------->
    function Stop(Reason, Code) {
        console.log('stopping stream');
        Stream.close(Code || 1000, Reason);
        Reset();
    }
    /// <!--=======================================================================================================-->
    ///                              Reset
    /// <!----------------------------------------------------------------------------------------------------------->  
    function Reset() {
        SessionActive = false;
        if (ASyncWrite) {
            clearTimeout(ASyncWrite);
            ASyncWrite = null;
        }
        if (ASyncStart) {
            clearTimeout(ASyncStart);
            ASyncStart = null;
        }
        if (Stream === null) {
            return;
        }
        Stream.removeEventListener('open', OnStreamOpen);
        Stream.removeEventListener('error', OnStreamError);
        Stream.removeEventListener('close', OnStreamClose);
        Stream.removeEventListener('message', OnStreamMessage);
        Stream = null;
    }
    /// <!--=======================================================================================================-->
    ///                              Clear
    /// <!----------------------------------------------------------------------------------------------------------->  
    function Clear() {
        PartialReadMessage = { MessageId: 0, DataOffset: 0, Data: null };
        PartialWriteMessage = { MessageId: 0, DataOffset: 0, Data: null };
        MessageOutQueue = [];
        AcknowlageQueue = [];
        CurrentWriteMessageId = 0;
        SyncRetry = 0;
    }
    /// <!--=======================================================================================================-->
    ///                              Login
    /// <!----------------------------------------------------------------------------------------------------------->
    function Login(aUserName, aPassword, aAuthenticatorCode, aGroupId, aSessionMode) {
        SendMessage("LoginRequest", 1, {
            Username: aUserName,
            Password: aPassword,
            GroupId: aGroupId,
            AuthenticatorCode: aAuthenticatorCode,
            SessionMode: aSessionMode,
            ListName: ""
        }, 5);
    }
    /// <!--=======================================================================================================-->
    ///                              beforeunload event
    /// <!----------------------------------------------------------------------------------------------------------->
    window.onbeforeunload = function () {
        console.log("window.onbeforeunload");
        return;
        /*
              if (SessionActive && ACCCClientThis.Enabled) {
                 HandleError("closed");
                 Stop("Tab closed", 1000);
                 setTimeout(Start, 1000);
                 return 'Session Closed. Restart?';
              }
        
              SessionActive = false;*/
    };
    Start.apply(this);
    this.SendMessage = SendMessage;
    this.Login = Login;
}
/// <!----------------------------------------------------------------------------------------------------------->
///
///
///
/// <!----------------------------------------------------------------------------------------------------------->
