import { AEngine } from "../../../core/AEngine.js";
import { AFormInstance } from "../../../core/form/AFormInstance.js";
import { AConfigManageService } from "../../../services/AConfigManageService.js";
import { isValidJson, ATryFormatDate } from "../../../utils/tools.js";
import { AConfigComponent } from "./AConfigComponent.js";
export class AConfigJSONComponent extends AConfigComponent {
    constructor(opt) {
        super(opt);
        this.opt = opt;
    }
    async preRender() {
        const cfgEntry = await configService.getVersioned(this.cfgPath);
        this.cfgData = cfgEntry ?? this.findConfigValueLegacy(this.cfgPath);
        this.cfgDataOriginal = JSON.parse(JSON.stringify(this.cfgData));
        // this.cfgDataOriginal = this.cfgData.map(v => Object.assign({}, v))
    }
    async postRender() {
        const cfgItem = this.getCfgItem();
        const formData = Object.assign(AEngine.get(AConfigManageService).emptyModel, cfgItem, {
            CfgValue: JSON.stringify(cfgItem.CfgValue, null, 4),
            ModificationTime: ATryFormatDate(cfgItem.ModificationTime ?? new Date())
        });
        const form = new AFormInstance({
            ignoreWildcards: false,
            defaultValue: AEngine.get(AConfigManageService).emptyModel,
            formInputs: [
                { id: 'CfgPath', type: 'text', minlength: 0, maxlength: 255, disabled: true, width: 'col-12', cls: 'hidden' },
                {
                    id: 'CfgValue',
                    type: 'jsontext',
                    minlength: 0,
                    maxlength: 4096,
                    rows: Math.min(formData.CfgValue.split('\n').length, 8),
                    label: this.getTitle(),
                    hasError: (opt) => !isValidJson(opt.formData.CfgValue),
                    // isDisabled: (opt) => !isValidJson(opt.formData.CfgValue),
                    width: 'col-12'
                },
                // {id: 'Version', type: 'number', step: '1', width: 'col-6', cls: {input: 'input-sm'} },
                // {id: 'ModificationUser', type: 'text', minlength: 0, maxlength: 255, disabled: true, width: 'col-6', cls: {input: 'input-sm'}},
                // {id: 'ModificationDevice', type: 'text', minlength: 0, maxlength: 255, disabled: true, width: 'col-6', cls: {input: 'input-sm'}},
                // {id: 'ModificationTime', type: 'text', minlength: 0, maxlength: 255, disabled: true, width: 'col-6', cls: {input: 'input-sm'}},
            ]
        });
        await form.generate({ translate: false, wrapInColumns: true });
        await form.injectFormData({ formData, triggerChange: true });
        await form.initFormValidation();
        form.attachTo(this.$ele());
        const $form = form.$form();
        $form.find(':input').on('change input', (originalEvent) => {
            // super.$ele().trigger('aci-change', [this])
            const formData = form.toJSON();
            super.validate(formData.CfgValue, { skipParse: true });
        });
    }
    async render() {
        return ( /*html*/`<div comp="${this.getInternalId()}" cfg="${this.cfgPath}" class="column col-12"></div>`);
    }
    toJSON() {
        return this.CfgData;
    }
}
