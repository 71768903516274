import { AError } from "../classes/AError.js";
import { EVENTS } from "./AEventService.js";
export var ROUTES;
(function (ROUTES) {
    ROUTES[ROUTES["Root"] = 0] = "Root";
    ROUTES[ROUTES["Login"] = 1] = "Login";
    ROUTES[ROUTES["Authenticator"] = 2] = "Authenticator";
    ROUTES[ROUTES["Home"] = 3] = "Home";
    ROUTES[ROUTES["Reload"] = 4] = "Reload";
})(ROUTES || (ROUTES = {}));
export class ARouteService {
    autoInit() {
        this.protocols = ['http', 'https'];
    }
    isLoggedIn() {
        return stateService.isLoggedIn();
    }
    isLoginPage(meta) {
        return meta.moduleHref.endsWith('/login.js') || meta.moduleHref.endsWith('/authenticator.js') || meta.moduleHref.endsWith('/linked.js');
        // return this.url.hash.endsWith('/login.html') || this.url.hash.endsWith('/authenticator.html')
    }
    isBackOffice() {
        // TODO: Make if statement better
        return (location.href.includes('backoffice') || document.title.toLowerCase().includes('backoffice'));
    }
    get baseTitle() {
        return globalThis.baseTitle || '';
    }
    requireAuthenticator() {
        // TODO: Check if authenticator is seperate page
        console.warn('TODO: Check if authenticator is seperate page!');
        this.navigateTo(ROUTES.Authenticator, {
            setBrowserUrl: false
        });
    }
    startListening() {
        $(window).on('hashchange', () => this.reload());
        this.reload();
    }
    /**
     * Updates url to the url shows in the browser
     */
    reload() {
        return this.refresh(location.href);
    }
    /**
     * Updates url underwater without affecting the url in the browser
     * @param route Route to navigate to
     */
    navigateTo(route, options) {
        if (options.setBrowserUrl === true) {
            const newHref = this.convertToUrl(route, false, true);
            console.log('location.href = ', newHref);
            location.href = newHref;
        }
        else {
            this.refresh(route);
        }
    }
    refresh(routeOrUrl) {
        const href = (typeof routeOrUrl === 'string') ? routeOrUrl : this.convertToUrl(routeOrUrl, true);
        this.url = this.examine(href);
        this.meta = this.examineMeta();
        this.setPageTitle();
        Events.tryInvoke(EVENTS.ROUTE_CHANGED, [this.url, this.meta]);
    }
    convertToUrl(route, includeEntireDomain, addAjaxPrefix = false) {
        let prefix = (includeEntireDomain) ? location.origin : ''; // location.host
        if (addAjaxPrefix && !prefix.includes('/#!'))
            prefix = prefix + '/#!';
        switch (route) {
            case ROUTES.Root: return (`${prefix}/`);
            case ROUTES.Login: return (`${prefix}/login.html`);
            case ROUTES.Authenticator: return (`${prefix}/authenticator.html`);
            case ROUTES.Reload: return (`${prefix}/reload.html`);
            // TODO: Read from config
            case ROUTES.Home:
                let { availableUrls } = menuService;
                // if (AEngine.isDevelopmentMode) {
                //   AEngine.log('AvailableUrls', availableUrls)
                // }
                return (`${prefix}${availableUrls[0] ?? '/profile.html'}`);
            default:
                throw new Error(`ARouteService.navigatoTo(route) didnt recognize route: "${route}"`);
        }
    }
    setPageTitle() {
        const item = this.meta.menuItem;
        const subTitle = (item && item.title && item.title.length) ? ` | ${item.title}` : '';
        document.title = this.baseTitle + subTitle;
    }
    examine(url) {
        const output = {
            full: url,
            host: '',
            hash: '',
            port: '',
            query: {},
            protocol: 'http'
        };
        try {
            const regex = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n?]+):?([0-9]+)?/img;
            const res = regex.exec(url);
            if (res == null) {
                throw new Error(`url "${url}" couldn't be parsed!`);
            }
            const [_, host, port] = [...res.values()];
            const [prefix, suffix] = url.split(`${host}${(port ? `:${port}` : '')}`);
            const [hash, queryStr = ''] = suffix.split('?');
            const [protocol] = prefix.split('://');
            queryStr.split('&').map(p => p.split('='))
                .map(([k, v]) => output.query[k] = v ? decodeURIComponent(v) : v);
            Object.assign(output, {
                host: host,
                port: this.formatPort(port),
                hash: this.formatHash(hash),
                protocol: this.formatProtocol(protocol)
            });
        }
        catch (err) {
            AError.handle(err);
        }
        return output;
    }
    shouldUseFallback(foundMenuItem) {
        if (foundMenuItem !== null) {
            return (!this.isLoggedIn() && foundMenuItem.bypass_login == false);
        }
        return true;
    }
    examineMeta() {
        const foundMenuItem = menuService.getMenuOptionsForUrl(this.url.hash);
        const fallbackMenuItem = { url: this.convertToUrl((this.isLoggedIn()) ? ROUTES.Home : ROUTES.Login, false) };
        let isFallback = this.shouldUseFallback(foundMenuItem);
        let menuItem = (isFallback) ? fallbackMenuItem : foundMenuItem;
        return {
            moduleHref: menuItem.url.replace('.html', '.js'),
            menuItem: menuItem,
            isFallback
        };
    }
    formatPort(port) {
        return port || '';
    }
    formatHash(hash) {
        hash = hash.startsWith('/#!') ? hash.substring(3) : hash;
        return (hash.length > 0) ? hash : '/';
    }
    formatProtocol(protocol) {
        if (!this.protocols.includes(protocol)) {
            this.surpressError(new Error(`Unknown protocol "${protocol}"`));
            return this.protocols[0];
        }
        return protocol;
    }
    surpressError(err) {
        return AError.handle({
            useAdminAlerts: true,
            useConsoleLog: true,
            useModal: false,
            err: [err]
        });
    }
}
