import { ACrypto } from "../classes/ACrypto.js";
import { AEngine } from "../core/AEngine.js";
import { secondsPassed, secondsToHHMMSS } from "../utils/tools.js";
export class AMeterRef {
    constructor(data) {
        this.data = data;
    }
    get id() { return this.data.id; }
    get $meter() { return this.data.$meter; }
    get date() { return this.data.date; }
    stop() {
        AEngine.get(AMeterService).remove(this.id);
    }
}
export class AMeterService {
    constructor() {
        this.refreshRate = 1000;
        this.started = false;
        this.items = {};
    }
    get currentDate() {
        return new Date();
    }
    get currentTime() {
        return Date.now();
    }
    get count() {
        return Object.keys(this.items).length;
    }
    get shouldStartLoop() {
        return this.count === 1 && !this.started;
    }
    get shouldContinueLoop() {
        return this.count >= 1 && this.started;
    }
    gen(opt) {
        const id = 't-' + ACrypto.randomHexString(5);
        const $meter = $(/*html*/ `
      <div id="${id}" class="custom-time-bar">
        <div class="bar-time-left">00:00:00</div>
        <div class="bar-progress"></div>
      </div>
    `);
        this.add(id, { $meter, ...opt });
        const meterRef = new AMeterRef({
            id,
            $meter: $meter,
            date: opt.expire
        });
        return meterRef;
    }
    add(id, opt) {
        this.items[id] = opt;
        if (opt.onStart) {
            opt.onStart({ $meter: opt.$meter, expire: opt.expire, progress: 0, timeleft: '' });
        }
        this.refresh(id, false);
        if (this.shouldStartLoop) {
            this.started = true;
            this.loop(false);
        }
    }
    remove(id) {
        delete this.items[id];
    }
    clear() {
        this.items = {};
    }
    loop(allowCallback) {
        for (let index in this.items) {
            this.refresh(index, allowCallback);
        }
        setTimeout(_ => {
            if (this.shouldContinueLoop) {
                this.loop(true);
            }
            else {
                this.started = false;
            }
        }, this.refreshRate);
    }
    /**
     * @param {*} id
     * @param {*} allowCallback removes the possibility of the detection to be removed before it has been added to the UI layer
     */
    refresh(id, allowCallback = true) {
        const item = this.items[id];
        const { $meter, expire, onRefresh, onFinish, timeout } = item;
        const diff = Math.max(secondsPassed(this.currentDate, expire), 0);
        const progress = diff * 100 / timeout;
        const timeleft = secondsToHHMMSS(diff);
        if (onRefresh) {
            onRefresh({
                $meter,
                expire,
                timeleft,
                progress
            });
        }
        $meter.attr('value', progress);
        if (progress <= 0 && allowCallback) {
            delete this.items[id];
            onFinish(id);
        }
    }
}
