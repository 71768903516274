import { AError } from "../../classes/AError.js";
import { ALERT_STATUS } from "../../services/AAlertService.js";
import { AFormatDate, AInputDate, AInputTime, AShowTable, getApiBaseUrl } from "../../utils/tools.js";
export class APage {
    constructor() {
    }
    get baseUrl() {
        // return window.location.href.split("://")[0] + "://api." + window.location.href.split("/")[2].split('.').splice(1).join('.');
        return getApiBaseUrl();
    }
    // get openApiUrl() {
    //    const openApiUrl = this.baseUrl + "/description/openapi.json";
    //    return openApiUrl;
    // }
    async init() {
        CCCClient.SendMessage("OpenApiJsonRequest", 1, {});
        this.refresh().catch(AError.handle);
    }
    /**
     * @param {{ apiEntry: string, parameters: any }} param0
     * @returns {Promise<{ DownloadLink: string }>}
     */
    async fetchApiDownload({ apiEntry, parameters }) {
        this.downloadRequestId = this.downloadRequestId ? ++(this.downloadRequestId) : 1;
        const generatedId = this.downloadRequestId.toString(16);
        CCCClient.SendMessage("ApiDownloadRequest", 1, {
            Name: generatedId,
            ApiEntry: apiEntry,
            ApiInputParameters: parameters
        });
        const msg = await Loading.waitForEvent(`ApiDownloadResponse->${generatedId}`, false);
        if (!msg.Success) {
            /// @ ivan Don't know how else to solve this without type errors.. it must return DownloadLink: string
            throw new Error(msg.Error);
        }
        return msg;
    }
    transformApiToGridData({ apiDescription }) {
        return Promise.all(Object.keys(apiDescription.paths).map(async (route) => {
            // route: '/detections' | '/detectioncount'
            // const { summary, parameters } = apiDescription.paths[route]
            return {
                name: await Translate.get(apiDescription.paths[route].summary),
                apiEntry: route,
                data: apiDescription.paths[route],
                action: this.translations['Generate Export']
            };
        }));
    }
    generateFilename() {
        const now = new Date();
        const formattedDate = AFormatDate(now, ' ', '-', '-');
        return (`${this.recordToExport.name} ${formattedDate}.csv`);
    }
    /**
     * @param {{ name: string, translatedName: string, schema: any, required: boolean }} args
     * @returns {{ elementHtml: string, elementId: string }}
     */
    genEle({ name, translatedName, schema, required }) {
        const topLevelClassnames = [];
        const extraAttributes = [];
        // Temp Code (Use Index in the future)
        if ((['Filename']).includes(name)) {
            topLevelClassnames.push('hidden');
            required = false;
            extraAttributes.push(`value="${this.generateFilename()}"`);
            extraAttributes.push(`disabled="disabled"`);
        }
        let overrideSelect = '';
        // Temp Code (Use Index in the future)
        if ((['Format']).includes(name)) {
            topLevelClassnames.push('hidden');
            overrideSelect = 'Csv';
            extraAttributes.push(`disabled="disabled"`);
        }
        /**
         * @param {string} paramName
         * @returns {[string, string]}
         */
        const getDateTimeValues = (paramName) => {
            let [_date, _time] = [AInputDate(new Date()), '00:00'];
            switch (paramName) {
                case 'StartTime':
                    _date = AInputDate(FilterManager.startOfPrevMonth);
                    _time = AInputTime(FilterManager.startOfPrevMonth);
                    break;
                case 'EndTime':
                    _date = AInputDate(FilterManager.startOfMonth);
                    _time = AInputTime(FilterManager.startOfMonth);
                    break;
            }
            return [_date, _time];
        };
        let elementHtml = '';
        const elementId = `__modal_${name}`;
        const prefix = required ? '*' : '';
        switch (schema.format) {
            case 'date-time':
                const [date, time] = getDateTimeValues(name);
                elementHtml = ( /*html*/`
              <div class="${topLevelClassnames.join(' ')}">
                 <label class="form-label" for="${elementId}">${prefix}${translatedName}</label>
                 <div class="columns">
                    <input class="form-input col-6" autocomplete="off" type="date" oid="${name}" id="${elementId}" value="${date}">
                    <input class="form-input col-6" autocomplete="off" type="time" id="${elementId}_2" value="${time}">
                 </div>
              </div>
           `);
                break;
            default:
                console.log('genEle switch default called: by schema', schema);
                if (schema.hasOwnProperty('enum')) {
                    elementHtml = ( /*html*/`
            <div class="${topLevelClassnames.join(' ')}">
              <label class="form-label" for="${elementId}">${prefix}${translatedName}</label>
              <div class="form-group">
                <select oid="${name}" id="${elementId}" class="form-select" ${extraAttributes.join('')}>
                  ${schema.enum.map(opt => opt === overrideSelect ? (`<option selected="selected">${opt}</option>`) : (`<option>${opt}</option>`)).join('')}
                </select>
              </div>
            </div>
          `);
                    break;
                }
                else {
                    elementHtml = ( /*html*/`
            <div class="${topLevelClassnames.join(' ')}">
              <div class="form-group">
                <label class="form-label" for="${elementId}">${prefix}${translatedName}</label>
                <input class="form-input" type="text" oid="${name}" id="${elementId}" placeholder="" ${extraAttributes.join('')}>
              </div>
            </div>
          `);
                }
                break;
        }
        return { elementHtml, elementId };
    }
    /**
     * @param {{ parameters: any[] }} args
     * @returns {Promise<{ elementIds: string[], formHtml: string, elementSchemas: any[] }>}
     */
    async generateFormHtml({ parameters }) {
        const elementIds = [];
        const elementSchemas = [];
        const elementHtmls = [];
        parameters = await Promise.all(parameters.map(async (param) => {
            param.translatedName = await Translate.get(param.name);
            return param;
        }));
        parameters.map((param) => {
            const { name, translatedName, required, schema, description } = param;
            const elementDescription = this.genEle({ name, translatedName, schema, required });
            elementIds.push(elementDescription.elementId);
            elementSchemas.push(schema);
            elementHtmls.push(elementDescription.elementHtml);
        });
        return {
            elementIds,
            formHtml: elementHtmls.join(''),
            elementSchemas
        };
    }
    async displayForm({ apiEntry, data, cellInfo }) {
        const form = await this.generateFormHtml({ parameters: data.data.parameters });
        const events = Alerts.show({
            translatedTitle: await Translate.get('Select Data range for downloading'),
            content: form.formHtml,
        });
        events.on(ALERT_STATUS.ON_ACTION_PROCEED, async () => {
            // let InputParameters = {
            //    Format: "Csv",
            //    StartTime: "2020-01-01T23:00:00.000Z",
            //    EndTime: "2021-01-01T23:00:00.000Z",
            //    Filename: "2020-01-01_2021-01-01.csv", 
            // }
            const transformParameters = () => {
                const output = {};
                form.elementIds.map((id, i) => {
                    const $ele = $(`#${id}`);
                    const schema = form.elementSchemas[i];
                    switch (schema.type) {
                        case 'string':
                            if (schema.format === 'date-time') {
                                const valDate = $ele.val();
                                const valTime = $(`#${id}_2`).val();
                                const dateTime = new Date(valDate + ' ' + valTime);
                                output[$ele.attr('oid') || ''] = dateTime.toJSON().toString();
                            }
                            else {
                                output[$ele.attr('oid') || ''] = ($ele.val() || '').toString();
                            }
                            break;
                        default:
                            output[$ele.attr('oid') || ''] = $ele.val();
                            break;
                    }
                });
                return output;
            };
            const parameters = transformParameters();
            try {
                const msg = await this.fetchApiDownload({ apiEntry, parameters });
                let DownloadLink = this.baseUrl + msg.DownloadLink;
                // console.log(DownloadLink, msg)
                window.open(DownloadLink, '_blank');
            }
            catch (e) {
                AError.handle(e);
            }
        });
    }
    async refresh() {
        this.translations = await Loading.waitForPromises(Translate.get(['Name', 'Download', 'Generate Export']));
        // const jsonService = AJsonService.get()
        return await Loading.waitForPromises(
        // jsonService.fetch({ url: this.openApiUrl, cache: false })
        // Promise.resolve(EXAMPLE_RESPONSE)
        globalThis.apiJson ? Promise.resolve(globalThis.apiJson) : Loading.waitForEvent(`OpenApiJsonResponse`)).then(async (apiDescription) => {
            const gridData = await this.transformApiToGridData({ apiDescription });
            this.grid = AShowTable({
                aci: {
                    resizeToFit: false
                },
                appendTo: 'table-bryntum',
                columns: [
                    {
                        flex: 8,
                        text: this.translations['Name'],
                        field: 'name',
                    },
                    {
                        flex: 4,
                        type: 'widget',
                        field: 'action',
                        text: '',
                        cellCls: 'np-i',
                        widgets: [{
                                type: 'button',
                                cls: 'b-blue b-raised',
                                onAction: ({ source }) => {
                                    const { cellInfo } = source;
                                    if (!cellInfo || !cellInfo.record)
                                        return;
                                    const { apiEntry, data } = cellInfo.record;
                                    this.recordToExport = cellInfo.record;
                                    Loading.waitForPromises(this.displayForm({ apiEntry, data, cellInfo })).catch(AError.handle);
                                }
                            }]
                    },
                    {
                        hidden: true,
                        field: 'data',
                        type: 'template',
                        template: (value) => '...',
                    },
                    {
                        hidden: true,
                        field: 'apiEntry',
                        type: 'template',
                        template: (value) => '...',
                    },
                ],
                data: gridData
            });
        });
    }
}
export function render() {
    return ( /*html*/`
    <div id="Rapport" class="bryntum-container has-footer-2">
      <div id="table-bryntum"></div>

      <div class="columns footer aci">
        <div class="column col-2">
          <div id="count" class="text">Viewing <span>0</span> Api Endpoints</div>
        </div>
      </div>
    </div>
  `);
}
