import { AConvertMillisecondsToHM, ARound } from "../utils/tools.js";
import { initToolTips } from "../utils/tooltip.js";
import { AKpiBlock, AKpiState } from "./AKpiBlock.js";
export class AKpiBlockDetectionStateTable extends AKpiBlock {
    constructor(opt) {
        super({
            refreshImplemented: true,
            optionsImplemented: true,
            ...opt
        });
    }
    async fetchDurations({ FromDate, ToDate }) {
        const res = await requestService.fetch({
            AssertValues: true,
            Query: ( /*SQL*/`
        SELECT
          PreviousDetectionState,
          AVG(PreviousDetectionStateDuration) as PreviousDetectionStateDuration,
          MIN(PreviousDetectionStateDuration) as PreviousDetectionStateDurationMin,
          MAX(PreviousDetectionStateDuration) as PreviousDetectionStateDurationMax
        FROM detections_final_history
        WHERE DetectionFinalTime BETWEEN :FromDate AND :ToDate AND PreviousDetectionState IS NOT NULL
        GROUP BY PreviousDetectionState
      `),
            Params: { FromDate, ToDate }
        });
        return res.map(v => {
            v.PreviousDetectionStateDuration = ARound(v.PreviousDetectionStateDuration, 2);
            v.PreviousDetectionStateDurationMin = ARound(v.PreviousDetectionStateDurationMin, 2);
            v.PreviousDetectionStateDurationMax = ARound(v.PreviousDetectionStateDurationMax, 2);
            return v;
        });
    }
    async refresh(filters = this.filterOption) {
        let durations = await this.fetchDurations(filters);
        durations = durations.filter((c) => {
            return c.PreviousDetectionState !== 'Done';
        });
        if (durations.length === 0) {
            return AKpiState.noResults;
        }
        const html = await requestService.translateDom(/*html*/ `
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Detection State</th>
            <th>Average Duration</th>
          </tr>
        </thead>
        <tbody>
        ${durations.map((row) => {
            const HM = AConvertMillisecondsToHM(Math.abs(row.PreviousDetectionStateDuration * 1000));
            const isNegative = row.PreviousDetectionStateDuration < 0 && HM !== '00:00:00';
            return ( /*html*/`
              <tr detectionstate="${row.PreviousDetectionState}">
                <td>${row.PreviousDetectionState}_SHORT</td>
                <td>${isNegative ? '- ' : ''}${HM}</td>
              </tr>
            `);
        }).join(' ')}
        </tbody>
      </table>
    `);
        // const $kpiView = this.$filter.closest('.kpi-block').find('.kpi-view')
        this.$kpiView.html(html);
        initToolTips();
    }
    async render() {
        return await super.renderView({
            title: 'Detection State Duration',
            viewHtml: ( /*html*/``),
        });
    }
}
