export class AGpsDevice {
    constructor(options) {
        this.options = options;
        this.startLoop();
    }
    startLoop() {
        if (navigator && navigator.geolocation) {
            let options = $.extend({ enableHighAccuracy: true, timeout: 5000, maximumAge: 0, frequency: 1000 }, this.options);
            navigator.geolocation.watchPosition((position) => {
                this.Precision = position.coords.accuracy;
                this.Direction = position.coords.heading;
                this.Speed = position.coords.speed;
                this.Latitude = position.coords.latitude;
                this.Longitude = position.coords.longitude;
                if (position.timestamp) {
                    let d = new Date(position.timestamp);
                    this.GpsTime = d.toJSON();
                }
                if (this.OnNewGps != undefined) {
                    this.OnNewGps(this);
                }
            }, undefined, options);
        }
    }
    OnNewGps(Gps) {
        let GpsStateStream = {
            Latitude: Gps.Latitude,
            Longitude: Gps.Longitude,
            Precision: Gps.Precision,
            GpsTime: Gps.GpsTime,
            Direction: Gps.Direction,
            Speed: Gps.Speed
        };
        CCCClient.SendMessage("GpsStateStream", 1, GpsStateStream, 10);
    }
}
