import { AFormInstance } from "../../../core/form/AFormInstance.js";
import { isVariablePrimitive } from "../../../utils/tools.js";
import { AConfigComponent } from "./AConfigComponent.js";
export class AConfigInputComponent extends AConfigComponent {
    get formInput() { return this.opt.formInput; }
    constructor(opt) {
        super(opt);
        this.opt = opt;
        this.init();
    }
    init() {
        let inp = this.formInput;
        this.opt.formInput.id = inp.id ?? this.cfgPath.split('.').pop();
        this.opt.formInput.cls = {};
    }
    async preRender() {
        const cfgEntry = await configService.getVersioned(this.cfgPath);
        this.cfgData = cfgEntry ?? this.findConfigValueLegacy(this.cfgPath);
        this.cfgDataOriginal = JSON.parse(JSON.stringify(this.cfgData));
    }
    async postRender() {
        const form = new AFormInstance({ ignoreWildcards: false, formInputs: [this.formInput] });
        await form.generate({ translate: false, wrapInColumns: true, classList: 'form-horizontal' });
        await form.injectFormData({ formData: { [this.formInput.id]: this.opt.cfgItem.CfgValue }, triggerChange: true });
        await form.initFormValidation();
        form.attachTo(this.$ele());
        this.form = form;
        const $form = form.$form();
        $form.find(':input').on('change input', (originalEvent) => {
            // super.$ele().trigger('aci-change', [this])
            const formData = form.toJSON();
            const val = formData[this.formInput.id];
            const skipParseJSON = isVariablePrimitive(formData[this.formInput.id]) && typeof val !== 'string';
            super.validate(val, { skipParse: skipParseJSON });
        });
    }
    async render() {
        return ( /*html*/`<div comp="${this.getInternalId()}" cfg="${this.cfgPath}" class="column col-12"></div>`);
    }
    toJSON() {
        return this.CfgData;
    }
}
