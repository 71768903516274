import { ADynamicChartSnapshotDetections } from "../charts/ADynamicChartSnapshotDetections.js";
import { AKpiBlock, AKpiState } from "./AKpiBlock.js";
export class AKpiBlockChannels extends AKpiBlock {
    get chartId() { return `${this.idFilter}-Chart`; }
    constructor(opt) {
        super({
            refreshImplemented: true,
            optionsImplemented: true,
            TranslateTitle: true,
            ...opt
        });
        // console.log(this)
        this.chartOptions = {
            DataSource: 'detections',
            ChartType: 'column',
            Horizontal: 'Chart_VerificationChannel_Full',
            Vertical: 'Chart_DetectionState_InProgress',
            IncludeTotals: false,
            Polar: false,
            Inverted: true,
            Filters: [],
            ShowLegend: true,
            IgnoreOutsideSegment: null,
            OnlyAllowFinalized: null
        };
        this.snapshot = new ADynamicChartSnapshotDetections(this.chartId, {
            calcDimensions: () => this.getSize(),
            allowExport: false
        });
    }
    async init() {
        super.init();
        this.translations = await Translate.get(['Done']);
    }
    async refresh(filters = this.filterOption) {
        const { hasData, chartData, instantiated } = await this.snapshot.update(this.chartOptions, { ...filters, DeviceName: '%' });
        if (chartData.Rows.length === 0) {
            return AKpiState.noResults;
        }
        // TODO: Update logic
        if (instantiated) {
            const s = this.snapshot.chart?.series.find(s => s.name == this.translations['Done']);
            s?.setVisible(false);
        }
        this.snapshot.resize();
    }
    async render() {
        return await super.renderView({
            title: 'Channel Queues',
            viewHtml: ( /*html*/`
        <div id="${this.chartId}"></div>
      `)
        });
    }
}
