import { ARound, createArray, lerp } from "../utils/tools.js";
import { AEngine } from "../core/AEngine.js";
import { AConfig } from "./AConfig.js";
import { ALogScale } from "./ALogScale.js";
import { AError } from "./AError.js";
// TODO: Remove Old Config Code
export function oldConfigToTransition(opt) {
    const { bounds, colors } = opt;
    return bounds.map((bound, i) => {
        return {
            x: bound,
            color: colors[i],
        };
    });
}
// TODO: Remove Old Config Code
export function getFromOldConfig(opt) {
    // TODO: Replace with configService:
    // transition: configService.get<AColorLerp>('drawing.routeSpeed')!,
    // transition: configService.get<AColorLerp>('drawing.routePrecision')!,
    if (AEngine.isDevelopmentMode) {
        AEngine.warn(`// TODO: Implement New Config Instead of ALegend.getFromOldConfig`);
    }
    // const colors: string[] = AConfig.get(`drawing & colors.${category ?? 'FALLBACK'}.colors`, ['#ff0000', '#ff7b00'])
    // const bounds: number[] = AConfig.get(`drawing & colors.${category ?? 'FALLBACK'}.bounds`, [0, 2])
    let colors = AConfig.get(opt.colorsKey, opt.colorsFallback);
    let bounds = opt.boundsKey !== undefined ? AConfig.get(opt.boundsKey, opt.boundsFallback) : opt.boundsFallback;
    if (bounds.length !== colors.length) {
        colors = opt.colorsFallback;
        bounds = opt.boundsFallback;
    }
    return oldConfigToTransition({ colors, bounds: bounds.map(num => Number(num)) });
}
// TODO: Remove Old Config Code
function getOldCategoryTransition(category) {
    let cat = category ?? 'FALLBACK';
    return;
}
const categoryToProfileMap = {
    speed: async (key) => ({
        title: await Translate.get(key),
        type: 'Linear',
        // transition: configService.get<AColorLerp>('drawing.routeSpeed')!,
        transition: getFromOldConfig({
            colorsKey: `drawing & colors.${key ?? 'FALLBACK'}.colors`,
            boundsKey: `drawing & colors.${key ?? 'FALLBACK'}.bounds`,
            colorsFallback: ['#ff0000', '#ff7b00'],
            boundsFallback: [10, 30]
        }),
        unit: 'km/h',
    }),
    precision: async (key) => ({
        title: await Translate.get(key),
        type: 'Linear',
        // transition: configService.get<AColorLerp>('drawing.routePrecision')!,
        transition: getFromOldConfig({
            colorsKey: `drawing & colors.${key ?? 'FALLBACK'}.colors`,
            boundsKey: `drawing & colors.${key ?? 'FALLBACK'}.bounds`,
            colorsFallback: ['#ff0000', '#ff7b00'],
            boundsFallback: [0, 2]
        }),
        unit: 'm',
        decimals: 1,
    }),
};
export async function genLegend(options) {
    if ('profileKey' in options) {
        const profile = await categoryToProfileMap[options.profileKey](options.profileKey);
        options['profile'] = Object.assign({
            amountOfColors: 6,
            decimals: 1,
            inverted: false,
            title: '',
            transition: [{ color: '#ff00a3', x: 0 }, { color: '#ff00a3', x: 100 }],
            unit: '%'
        }, profile);
    }
    const legend = new ALegend(options);
    await legend.createLegend();
    legend.redrawLegend();
    return legend;
}
export class ALegend {
    get profile() { return this.options.profile; }
    get $slider() { return this.$container.find('.slider'); }
    get $gradient() { return this.$container.find('.legend-color-arr'); }
    get $labels() { return this.$container.find('.legend-span-arr'); }
    get transition() { return this.profile.transition; }
    get firstPoint() { return this.profile.transition[0]; }
    get lastPoint() { return this.profile.transition[this.profile.transition.length - 1]; }
    get opacity() { return this.profile.opacity ?? 1; }
    get sliderValue() { return this.$slider.length > 0 ? this.$slider.get(0)['value'] : null; }
    get minColor() { return interpolateService.lerpTo({ transition: this.transition, value: this.firstPoint.x }).rgb; }
    get maxColor() { return interpolateService.lerpTo({ transition: this.transition, value: this.lastPoint.x }).rgb; }
    constructor(options) {
        this.options = Object.assign(DEFAULT_LEGEND_OPTIONS(), options || {});
    }
    setControls(...$eleArr) {
        const $controls = this.$container.find('.legend-gradient .controls');
        $controls.html('');
        for (let $ele of $eleArr) {
            if ($ele !== undefined)
                $controls.append($ele);
        }
    }
    setContent($eleArr) {
        const $content = this.$container.find('.legend-gradient .content');
        $content.html('');
        for (let $ele of $eleArr.filter(item => item !== undefined)) {
            if ($ele !== undefined)
                $content.append($ele);
        }
    }
    onClick(cbFn) {
        this.$container.find('.legend-span-arr').on('click', (e) => {
            Loading.waitForPromises(Promise.resolve().then(() => cbFn())).catch(AError.handle);
        });
    }
    genSliderLegendHtml(title, smallTitle) {
        return ( /*html*/`
      <div class="legend legend-one columns legend-slider mb-1" style="width: 100%">
        
        ${smallTitle === true ? `<label bind="slidertitle" class="legend-label">${title}</label>` : `<h5 bind="slidertitle">${title}</h5>`}
        
        <div class="controls">
          <div class="col-12">
            <input class="slider tooltip" type="range" min="0" max="24" value="0">
          </div>
          <div class="col-6 col-mr-auto text-left">
            <span>?</span>
          </div>
          <div class="col-6 col-ml-auto text-right">
            <span>?</span>
          </div>
        </div>
        <div class="content fw">
        </div>
      </div>
    `);
    }
    genGradientLegendHtml(title, smallTitle) {
        return ( /*html*/`
      <div class="legend legend-one columns legend-gradient" style="width: 100%">
        ${smallTitle === true ? `<label bind="slidertitle" class="legend-label">${title}</label>` : `<h5 bind="slidertitle">${title}</h5>`}
        <div class="controls">
        </div>
        <div class="content fw">
        </div>
        <div class="legend-group">
          <div class="legend-collection legend-color-arr">
            <div style="background: rgba(58, 217, 0, 0.8);"></div>
            <div style="background: rgba(98, 221, 1, 0.8);"></div>
            <div style="background: rgba(139, 224, 3, 0.8);"></div>
            <div style="background: rgba(180, 228, 4, 0.8);"></div>
            <div style="background: rgba(223, 232, 6, 0.8);"></div>
            <div style="background: rgba(236, 205, 7, 0.8);"></div>
            <div style="background: rgba(240, 169, 9, 0.8);"></div>
            <div style="background: rgba(244, 132, 10, 0.8);"></div>
            <div style="background: rgba(247, 94, 12, 0.8);"></div>
            <div style="background: rgba(251, 55, 14, 0.8);"></div>
            <div style="background: rgba(255, 15, 15, 0.8);"></div>
          </div>
          <div class="legend-collection legend-span-arr">
            <div style="">0.0%</div>
            <div style="">25.0%</div>
            <div style="">50.0%</div>
            <div style="">75.0%</div>
            <div style="">100.0%</div>
          </div>
        </div>
      </div>
    `);
    }
    async createLegend() {
        const { title } = this.profile;
        const { hasTimeline, smallTitle, sliderTitle } = this.options;
        this.$container = $(/*html*/ `
      <div class="legends" style="position: relative; min-width: 400px">
        ${this.genGradientLegendHtml(title, smallTitle)}
        ${hasTimeline ? this.genSliderLegendHtml(sliderTitle, smallTitle) : ''}
      </div>
    `);
        if (hasTimeline) {
            const $slider = this.$container.find('.legend-slider .slider');
            const slider = $slider.get(0);
            slider.addEventListener('input', () => {
                const timeframe = slider['value'];
                const formatTime = this.options.formatSlider(timeframe);
                const value = formatTime(timeframe);
                slider.setAttribute('value', value);
            });
        }
        this.destroy();
        const map = this.options.map;
        map.set('$legend', this.$container);
        map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(this.$container.get(0));
        return this.$container;
    }
    calcColor(opt) {
        if (this.profile.type === 'Exponential') {
            const precalc = this.options.precalc();
            var logScale = new ALogScale(precalc.left, precalc.right);
            opt.perc = logScale.logarithmicToLinear(opt.value) * 100;
        }
        return interpolateService.lerpTo({ transition: this.transition, value: opt.perc }).rgb;
    }
    update(opt) {
        if (opt.start !== undefined && opt.end !== undefined) {
            opt.transition = [{ color: opt.start.hexi, x: 0 }, { color: opt.end.hexi, x: 100 }];
        }
        Object.keys(opt).map(key => {
            if (!opt.hasOwnProperty(key) || opt[key] === undefined) {
                return;
            }
            this.profile[key] = opt[key];
        });
    }
    redrawLegend() {
        const { transition: t } = this.profile;
        const precalc = (this.options?.precalc != null) ? this.options.precalc() : null;
        let { min, max, unit } = Object.assign({ min: t[0].x, max: t[t.length - 1].x, unit: this.profile.unit ?? '' }, precalc);
        let left = precalc?.left ?? min, right = precalc?.right ?? max;
        const legendDecimals = this.profile.decimals;
        const amountOfColors = this.profile.amountOfColors ?? 10;
        const labelHtml = createArray(amountOfColors + 1).map((_, i) => {
            let t = (i / amountOfColors);
            const innerHTML = ARound(lerp(left, right, t), legendDecimals);
            return (`<div>${innerHTML} ${unit}</div>`);
        }).join('');
        var gradientHtml;
        if (this.profile.type === 'Exponential') {
            gradientHtml = createArray(100, (i) => i).map((i) => {
                const clr = this.calcColor({ value: lerp(left, right, i / 100.0) });
                return (`<div style="background: ${clr.hexi};"></div>`);
            }).join('');
        }
        else {
            gradientHtml = createArray(100, (i) => i).map((i) => {
                const [from, to] = [t[0].x, t[t.length - 1].x];
                const delta = (to - from) / 100;
                const clr = this.calcColor({ perc: from + (delta * i) });
                return (`<div style="background: ${clr.hexi};"></div>`);
            }).join('');
        }
        this.$labels.html(labelHtml);
        this.$gradient.html(gradientHtml);
    }
    destroy() {
        const map = this.options.map;
        if (map.get('$legend')) {
            map.get('$legend')?.remove();
        }
        // this.$container.remove()
        // this.containers.map($container => $container.remove())
    }
}
const DEFAULT_LEGEND_OPTIONS = () => ({
    // @ts-ignore
    map: null,
    category: 'speed',
    // profile: ,
    smallTitle: false,
    title: 'Legend Title',
    sliderTitle: 'Slider Title',
    // legendDecimals: 1.0,
    // minGpsDistance: 0.5,
    // maxTimeDifference: 6.0,
    // inverted: false,
    // opacity: 0.8,
    amountOfColors: 11,
    hasTimeline: false,
    $content: $('<div class="aci-placeholder-content hidden"></div>'),
    formatSlider: (a) => (v) => (v)
});
