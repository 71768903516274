import { AError } from "../../classes/AError.js";
import { ALoopTimer } from "../../classes/ALoopTimer.js";
import { ALoopTimerAsync } from "../../classes/ALoopTimerAsync.js";
import { AEngine, sleep } from "../../core/AEngine.js";
import { MAP_OPTIONS } from "../../core/maps/AMapStructs.js";
import { AKpiBlockSessions } from "../../kpi/AKpiBlockSessions.js";
import { AKpiSystem } from "../../kpi/AKpiSystem.js";
import { EVENTS } from "../../services/AEventService.js";
import { ATimeService } from "../../services/ATimeService.js";
import { createMap, ShowMapScans } from "../../utils/maps.js";
import { AConvertToGridData, AShowTable, AStoreState, TransformObjectsToResponse, formatStringToPascal, lerp } from "../../utils/tools.js";
// Go back 15 minutes in time
const START_OFFSET = 1000 * 60 * 15;
const timeService = AEngine.get(ATimeService);
export class APage {
    constructor() {
        this.SessionMarkers = {};
        this.pageLoadTime = new Date(Date.now() - START_OFFSET);
        this.lastVerificationTime = new Date(Date.now() - START_OFFSET);
        this.markersMap = {};
        const map = createMap('map');
        map.addListener('dragstart', () => this.grid.deselectAll());
        this.map = map;
        this.markers = [];
        this.fadeInMarkers = [];
        const kpiSystem = new AKpiSystem({
            showSubtitle: true,
            getView: () => '',
            onWidgetCreated: (widget) => { },
            onWidgetDeleted: (widget) => { },
            isOperationsPage: false,
            $btnCreate: $(),
            $container: $(),
            $showIfEmpty: $(),
            widgets: [],
            allowEdit: false,
            disableMenu: true,
        });
        this.kpiSessions = new AKpiBlockSessions({
            kpiSystem: kpiSystem,
            KpiType: '',
            Name: '',
            querySelector: '',
            Width: 'col-12',
            listenToSessions: false,
            autoReflow: false,
            blockList: ('.kpi-block-list'),
            blockWidth: 'col-12'
        });
        this.kpiChartTimer = new ALoopTimer(() => this.kpiSessions.refresh(), {
            loopLifeCycle: "PAGE",
            timeout: 1000
        }).start();
        Events.on(EVENTS.SESSION_CHANGE_STREAM, (SessionInfo) => {
            this.kpiSessions.updateSession(SessionInfo);
        });
        Sessions.map(info => this.kpiSessions.updateSession(info));
    }
    async init() {
        this.sessionLoop = new ALoopTimer(() => this.grid?.refreshRows(), { loopLifeCycle: "PAGE", timeout: 1000 }).start();
        this.refreshLoop = new ALoopTimer(() => this.refresh().catch(AError.handle), {
            loopLifeCycle: "PAGE",
            timeout: 1000
        }).start();
        mapHelperService.prepareMapItems(MAP_OPTIONS.Default, {
            showLegend: true,
            showSearch: true,
            allowExport: false,
        }).catch(AError.handle);
        this.kpiSessions.init().catch(AError.handle);
        // this.handleSessionsChanged()
        const vehiclePosUpdatePerSecond = 15;
        await timeService.prefetch();
        this.columns = await this.genColumns();
        nodeSessionService.bindSessionsToMap({
            interpolate: true,
            lerpSpeed: vehiclePosUpdatePerSecond,
            mapMarkers: this.SessionMarkers,
            map: this.map,
            onChange: () => {
                return this.handleSessionsChanged();
            }
        });
        this.followVehicleTimer = new ALoopTimer(() => this.followSelectedVehicle(), {
            loopLifeCycle: "PAGE",
            timeout: 1000.0 / vehiclePosUpdatePerSecond
        }).start();
        const detectionAttributes = {
            strokeOpacity: 0.733,
            strokeWeight: 3,
            fillOpacity: 0.35,
            zIndex: 10.0
        };
        this.animateTimer = new ALoopTimerAsync(async () => {
            const self = this;
            const frameReq = new Promise(requestAnimationFrame);
            const promises = [
                frameReq.then(function frameCallback(timestamp) {
                    self.fadeInMarkers = self.fadeInMarkers.filter((marker) => {
                        const { fillOpacity, strokeOpacity } = marker;
                        const lerpFinished = Math.abs(detectionAttributes.fillOpacity - fillOpacity) < 0.05;
                        if (lerpFinished) {
                            marker.setOptions({
                                fillOpacity: detectionAttributes.fillOpacity,
                                strokeOpacity: detectionAttributes.strokeOpacity
                            });
                            return false;
                        }
                        marker.setOptions({
                            fillOpacity: lerp(fillOpacity, detectionAttributes.fillOpacity, 0.03),
                            strokeOpacity: lerp(strokeOpacity, detectionAttributes.strokeOpacity, 0.03)
                        });
                        return true;
                    });
                }),
                sleep(1000.0 / 30)
            ];
            await Promise.all(promises);
        }, { loopLifeCycle: "PAGE" }).start();
    }
    async handleSessionsChanged() {
        const sessionArr = await Promise.all(Object.keys(this.SessionMarkers).map(async (k) => {
            const session = this.SessionMarkers[k];
            const d = session.data ?? {};
            return {
                NodeName: d.NodeName,
                Device: await Translate.get(d.NodeName),
                User: d.UserDisplayName || d.User,
                Status: d.Status,
                StatusString: d.StatusString,
                ComState: d.ComState,
                LastUpdated: d.Gps.GpsTime,
                ValidLogin: d.ValidLogin,
            };
        }));
        const ares = await TransformObjectsToResponse(sessionArr);
        if (this.grid && this.grid.store.records.length > 0) {
            const storedState = AStoreState(this.grid, { primaryKey: 'NodeId' });
            this.grid.store.data = AConvertToGridData(ares, {});
            storedState.restore();
        }
        else {
            this.grid = AShowTable({
                appendTo: 'table-bryntum',
                columns: this.columns,
                selectionMode: { preserveSelectionOnDatasetChange: true },
                data: AConvertToGridData(ares, {})
            });
        }
        this.combineTableWithMap();
    }
    async genColumns() {
        const columns = [
            { field: 'NodeName', text: '', hidden: true },
            { field: 'Device', text: '' },
            { field: 'User', text: '' },
            {
                field: 'Status',
                text: '',
                htmlEncode: false,
                renderer: (opt) => {
                    const style = ''; // opt.record.ValidLogin ? "--fa-animation-duration: 1s; --fa-beat-scale: 0.8;" : ''
                    return ( /*html*/`
            <div class="menu-label children-inline-block">
              <span class="statusbar ${opt.record.Status}">
                <i class="fa-solid fa-circle" style="${style}"></i>
                <span>${formatStringToPascal(opt?.record?.StatusString ?? '')}</span>
              </span>
            </div>
          `);
                }
            },
            {
                field: 'ComState',
                text: '',
                hidden: true,
                // ...COLUMN_ACTION({ iconCls: 'fa-regular fa-trash text-red', btnCls: 'btn-white' }),
            },
            {
                field: 'LastUpdated',
                text: '',
                renderer: (opt) => {
                    const timeAgo = timeService.agoSync(new Date(), new Date(opt?.record?.LastUpdated));
                    return timeAgo;
                }
            },
            { field: 'StatusString', text: '', hidden: true },
        ];
        await Translate.get(columns.map(c => c.field)).then((t) => {
            columns.map(c => c.text = t[c.field]);
        });
        return columns;
    }
    followSelectedVehicle() {
        if (this.grid?.selectedRecord) {
            const { NodeName } = this.grid.selectedRecord.data;
            const target = this.SessionMarkers[NodeName];
            const pos = target.getPosition();
            this.map.setCenter(pos);
        }
    }
    combineTableWithMap() {
        Object.keys(this.SessionMarkers).map((NodeName) => {
            const m = this.SessionMarkers[NodeName];
            m.addListener("click", (e) => {
                if (e.src !== 'grid') {
                    const foundRecord = this.grid.store.find(record => {
                        return m.get('NodeName') === record.data.NodeName;
                    });
                    this.grid.selectedRecord = foundRecord;
                }
            });
        });
        this.grid.on('selectionchange', async (e) => {
            try {
                if (e.source?.event.type === 'mouseout') {
                    // TODO: Fix infinite loop when grid.refreshRows() is called
                    return;
                }
                const record = this.grid.selectedRecord?.data;
                if (e.mode !== 'row' || !record) {
                    return;
                }
                if ((e.source?.event?.button ?? 0) !== 0) {
                    this.grid.deselectAll();
                    await purgatoryService.closeInfoWindow();
                    return;
                }
                if (e.source?.event.type === 'mousedown') {
                    const marker = this.SessionMarkers[record.NodeName];
                    new google.maps.event.trigger(marker, 'click', { src: 'grid' });
                }
            }
            catch (err) {
                AError.handle(err);
            }
        });
    }
    async refresh() {
        const response = await requestService.fetch({
            AssertValues: true,
            Query: (`
        SELECT
          df.DetectionId,
          df.DetectionDeviceId,
          df.DetectionFinalTime,
          df.Digital, df.Digital as keyDigital,
          df.TimeLimitedParking, df.TimeLimitedParking as keyTimeLimitedParking,
          df.IllegallyParked, df.IllegallyParked as keyIllegallyParked,
          df.ParkingRight, df.ParkingRight as keyParkingRight,
          df.Verification, df.Verification as keyVerification,
          df.DetectionState, df.DetectionState as keyDetectionState,
          ST_AsGeoJSON(geo.VehicleBounds) as VehicleBounds
        FROM detections_geo_bounds_original geo
        INNER JOIN detections_final df USING (DetectionId, DetectionDeviceId)
        WHERE DetectionTime > :PageLoadTime AND DetectionFinalTime > :LastVerificationTime
      `),
            Params: {
                PageLoadTime: this.pageLoadTime.toJSON(),
                LastVerificationTime: this.lastVerificationTime.toJSON()
            }
        });
        if (response.isEmpty) {
            return;
        }
        const dftIndex = response.Columns.indexOf('DetectionFinalTime');
        response.Rows.map((detection) => {
            const verificationTime = new Date(detection[dftIndex]);
            if (this.lastVerificationTime < verificationTime) {
                this.lastVerificationTime = verificationTime;
            }
            console.log({ LastVerificationTime: verificationTime });
        });
        const newAndChangedMarkers = ShowMapScans({
            response,
            map: null, // Map reference
        });
        const markersToReplace = newAndChangedMarkers.filter(m => {
            return this.markersMap.hasOwnProperty(m.get('Id'));
        });
        const markersToInsert = newAndChangedMarkers.filter(m => {
            return !this.markersMap.hasOwnProperty(m.get('Id'));
        });
        markersToReplace.map(m => {
            const markerToReplace = this.markersMap[m.get('Id')];
            markerToReplace.setMap(null);
            const markerIndex = this.markers.indexOf(markerToReplace);
            if (markerIndex !== -1) {
                this.markers.splice(markerIndex, 1);
            }
            this.markersMap[m.get('Id')] = m;
            m.setMap(this.map);
            this.markers.push(m);
        });
        markersToInsert.map(m => {
            this.markersMap[m.get('Id')] = m;
            m.setOptions({ fillOpacity: 0.0, strokeOpacity: 0.0 });
            m.setMap(this.map);
            this.markers.push(m);
            this.fadeInMarkers.push(m);
        });
    }
}
export function css() {
    return ( /*html*/`
    <style>
      .views {
        height: calc(100% - 102px);
      }

      .kpis .kpi-block-list > .column {
        margin-bottom: var(--u1);
      }
    </style>
  `);
}
export function render() {
    return ( /*html*/`
		<div class="kpis" style="position: relative; background: #f1f0f0; height: 100%">
			<div class="wrapper lg columns col-gapless">
				<div class="column col-4 custom-scroll" style="height: 100%">

          <div class="aci-tabs tabs-flex tabs-sticky tabs-fixed-md tabs-xl v-zero sharp-border" tabgroup="sideview">
            <button style="flex: 0 1 50%;" class="aci-tab active" tab="device-list">
              <i class="fa-solid fa-car-wrench"></i>
              <span>Device List</span>
            </button>
            <button style="flex: 0 1 50%;" class="aci-tab" tab="device-chart">
              <i class="fa-solid fa-route"></i>
              <span>Device Charts</span>
            </button>
          </div>
          
          <div class="views" style="overflow-y: auto">
            <div class="fh" tabgroup="sideview" tabview="device-list">
              <div id="firstPanel" class="list-container" style="overflow-y: auto;">
                <div id="table-bryntum" class="fh fw"></div>
              </div>
            </div>
            <div class="column col-12 mt-1" tabgroup="sideview" tabview="device-chart">
              <div class="columns kpi-block-list">
        
              </div>
            </div>
          </div>
          
          <div id="lastPanel" class="list-container" style="background: #f8f9fa; height: 45px">
            <div id="count" class="text spacing01">
              Viewing
              <span>0</span>
              Devices
            </div>
          </div>
				</div>
				<div class="column col-8" style="height: 100%; background: #f8f9fa">
					<div id="map" class="aci-map"></div>
				</div>
			</div>
		</div>
	`);
}
