import { ADynamicChartUtils } from "../charts/ADynamicChartUtils.js";
import { createMatrix, mergeDeep } from "../utils/tools.js";
import { AResponse } from "./AResponse.js";
export class AStatisticResponse {
    constructor(data, originalResponse) {
        this.data = data;
        this.originalResponse = originalResponse;
    }
    get isEmpty() {
        return this.data.length === 0;
    }
    getTotal() {
        let sum = 0;
        this.data.map(([obj, stats]) => {
            if (stats)
                sum += stats?.getTotal();
        });
        return sum;
    }
    toTuple() {
        return this.data;
    }
    defaultApply(stats) {
        return ({
            Total: stats.getTotal(),
            Followups: stats.getSuccessfulFollowUps(),
            Suspects: stats.getSuspects(),
            Sanctions: stats.getSanctions(),
        });
    }
    async toMatrix(options) {
        const { groups, mapTo, mapA, mapB, xKeys, yKeys } = await new ADynamicChartUtils().genGroupMapping([
            { group: options.groups[0], sort: true },
            { group: options.groups[1], sort: true }
        ]);
        const res = await this.toResponse(mergeDeep({
            mapTo: {
                // 'key': (([obj, stats, mapTo]) => )
                'key': ([obj, stats]) => mapTo(obj[options.groups[0]], obj[options.groups[1]]),
                [groups[0]]: ([obj, stats]) => mapA(obj[groups[0]]),
                [groups[1]]: ([obj, stats]) => mapB(obj[groups[1]]),
            }
        }, options));
        const ares = new AResponse(res);
        const matrix = createMatrix(xKeys.length + 1, yKeys.length + 1, () => 0);
        ares.map(({ key, Total }) => {
            const [xKey, yKey] = key.split(' ');
            const xIndex = xKeys.indexOf(xKey);
            const yIndex = yKeys.indexOf(yKey);
            matrix[xIndex + 1][yIndex + 1] = Total;
        });
        matrix[0][0] = 'Bars';
        xKeys.map((_, i) => { matrix[i + 1][0] = xKeys[i]; });
        yKeys.map((_, i) => { matrix[0][i + 1] = yKeys[i]; });
        return matrix;
    }
    /**
     * Converts data into IResponse
     */
    async toResponse(options) {
        const { mapTo, replaceNullWithUnknown } = Object.assign({
            replaceNullWithUnknown: false
        }, options);
        const keysNonStandard = this.data.length > 0 ? Object.keys(this.data[0][0]) : [];
        const Columns = [
            ...keysNonStandard,
            ...Object.keys(mapTo ?? {}).filter(k => !keysNonStandard.includes(k))
        ]; //, 'Total', 'Suspects', 'Followups', 'Sanctions']
        // Object.keys(mapTo ?? {}).map(k => (!Columns.includes(k)) ? Columns.push(k) : null)
        const translations = await Translate.get(Columns);
        return {
            Columns: Columns,
            ColumnsTranslated: Columns.map(c => translations[c]),
            Rows: this.data.map(([obj, stats]) => {
                return [
                    ...Columns.map(key => (mapTo?.hasOwnProperty(key)) ? mapTo[key]([obj, stats]) : obj[key]),
                    // stats.getTotal(),
                    // stats.getSuspects(),
                    // stats.getSuccessfulFollowUps(),
                    // stats.getSanctions(),
                ];
            })
        };
    }
    /**
     * Converts data into object array
     */
    toJSON(options) {
        const { apply, mapTo, replaceNullWithUnknown } = Object.assign({
            apply: this.defaultApply,
            replaceNullWithUnknown: false
        }, options);
        let output = this.data.map(([obj, stats]) => {
            const copy = Object.assign(apply(stats), obj);
            return copy;
        });
        if (mapTo !== undefined) {
            output = output.map((item) => {
                Object.keys(mapTo).map((key) => {
                    if (item.hasOwnProperty(key)) {
                        const mapToFn = mapTo[key];
                        item[key] = mapToFn(item[key]);
                    }
                });
                return item;
            });
        }
        if (replaceNullWithUnknown === true) {
            output = output.map((item) => {
                Object.keys(item).map(key => {
                    if (item[key] === null) {
                        item[key] = Translate.getCacheFast('unknown');
                    }
                });
                return item;
            });
        }
        return output;
    }
}
