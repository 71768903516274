import { AError } from "../classes/AError.js";
export class AEnforceUnitOrm {
    async find({ EnforceUnitCode }) {
        return await requestService.query({
            Query: `SELECT EnforceUnitCode FROM config_enforce_units WHERE EnforceUnitCode=:EnforceUnitCode`,
            Params: { EnforceUnitCode }
        });
    }
    async fetchAll() {
        const rows = await requestService.fetch({
            AssertValues: true,
            Query: (`SELECT EnforceUnitCode, EnforceUnitName FROM config_enforce_units`),
            Language: Language
        });
        return rows;
    }
    async create(options) {
        const found = await this.find(options);
        if (found.Rows.length > 0) {
            AError.handleSilent(`db entry already exists! config_enforce_units.EnforceUnitCode=${options.EnforceUnitCode}`, 'EnforceUnitCode Already Exists');
            return false;
        }
        const ModificationUser = stateService.User;
        const ModificationDevice = _.getSession().DeviceName;
        await requestService.query({
            Query: (`
        INSERT INTO config_enforce_units (EnforceUnitCode, EnforceUnitName, ModificationUser, ModificationDevice)
        VALUES (:EnforceUnitCode, :EnforceUnitName, :ModificationUser, :ModificationDevice)
      `),
            Params: { ...options, ...{ ModificationUser, ModificationDevice } }
        });
        return true;
    }
    async update(options) {
        const found = await this.find(options);
        if (found.Rows.length === 0) {
            AError.handleSilent(`Couldn't find db entry config_enforce_units.EnforceUnitCode=${options.EnforceUnitCode}`, 'EnforceUnitCode Not Found');
            return false;
        }
        const ModificationUser = stateService.User;
        const ModificationDevice = _.getSession().DeviceName;
        await requestService.query({
            Query: (`
        UPDATE config_enforce_units
        SET EnforceUnitName=:EnforceUnitName, ModificationUser=:ModificationUser, ModificationDevice=:ModificationDevice
        WHERE EnforceUnitCode=:EnforceUnitCode
      `),
            Params: { ...options, ...{ ModificationUser, ModificationDevice } }
        });
        return true;
    }
    async delete(options) {
        await requestService.query({
            Query: (`DELETE FROM config_enforce_units WHERE EnforceUnitCode=:EnforceUnitCode`),
            Params: {
                EnforceUnitCode: options.EnforceUnitCode
            }
        });
    }
}
