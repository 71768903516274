import { AError } from "../../classes/AError.js";
import { COLUMN_DATETIME, COLUMN_NUMBER, DATA_DATETIME } from "../../classes/AGridTypes.js";
import { AEngine } from "../../core/AEngine.js";
import { ATemplates } from "../../core/ATemplateService.js";
import { ATimeService } from "../../services/ATimeService.js";
import { AConvertToGridColumns, AConvertToGridDataAsync, AShowTable } from "../../utils/tools.js";
export class APage {
    constructor() {
        this.timeService = AEngine.get(ATimeService);
    }
    async init() {
        try {
            FilterManager.load();
            $('#RefreshButton').on('click', _ => FilterManager.showFilterWarning().then(_ => this.refresh().catch(AError.handle)));
            await this.refresh();
        }
        catch (err) {
            AError.handle(err);
        }
    }
    async refresh() {
        try {
            const filters = FilterManager.saveExplicit();
            FilterManager.setActive(false);
            let extraColumn = filters.GroupBy;
            const ares = await Loading.waitForPromises(requestService.fetch({
                AssertValues: true,
                Query: (`
            SELECT
              Target,
              ${extraColumn ? extraColumn + ',' : ''}
              COUNT(*) as Visits,
              MAX(CreatedAt) as LastVisit,
              MAX(CreatedAt) as LastVisitRelative
            FROM user_actions
            WHERE Action='VIEW' AND CreatedAt BETWEEN :FromDate AND :ToDate
            GROUP BY
              Category,
              Action,
              Target ${extraColumn ? `, ${extraColumn}` : ''}
            ORDER BY
              COUNT(*) DESC
          `),
                Params: filters,
                Language: Language
            }));
            const columns = AConvertToGridColumns(ares, {
                'Visits': COLUMN_NUMBER,
                'LastVisit': COLUMN_DATETIME
            });
            const data = await AConvertToGridDataAsync(ares, {
                'Target': (value) => value.toLowerCase(),
                'Visits': (value) => Number(value),
                'LastVisit': DATA_DATETIME,
                'LastVisitRelative': (value) => this.timeService.ago(new Date(value)),
            });
            this.grid = AShowTable({
                appendTo: 'table-bryntum',
                features: {
                    ...(filters.GroupBy ? { group: filters.GroupBy } : {})
                },
                columns,
                data,
            });
        }
        catch (err) {
            AError.handle(err);
        }
        finally {
            FilterManager.setActive(true);
        }
    }
}
export function render() {
    return ( /*html*/`
    <div id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column c-scroll col-12">
        <div class="form-group">
          <label class="form-label" for="FromDate">From</label>
          <input class="form-input" type="date" id="FromDate" required="required">
          <input class="form-input" type="time" id="FromTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="ToDate">To</label>
          <input class="form-input" type="date" id="ToDate" required="required">
          <input class="form-input" type="time" id="ToTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="GroupBy">GroupBy</label>
          <select class="form-select" id="GroupBy">
            <option value="%">None</option>
            <option value="User">User</option>
          </select>
        </div>

      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </div>
    <div id="Rapport" class="flex-child bryntum-container fh">
      <div template="${ATemplates.WaitingForInput}"></div>
      <div id="table-bryntum" class="hidden"></div>
    </div>
  `);
}
