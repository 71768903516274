var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { AEngine } from "../core/AEngine.js";
import { AFilterUnit } from "../tests/AFilterUnit.js";
import { APageVisitorUnit } from "../tests/APageVisitorUnit.js";
import { executeOrder } from "../types/attributes.js";
import { stringify } from "../utils/tools.js";
import { AAlertService, ALERT_BUTTONS, ALERT_STATUS, ALERT_TITLES } from "./AAlertService.js";
import { EVENTS } from "./AEventService.js";
function assert(expected, actual) {
    return expected === actual;
}
function assertError(callback) {
    let errorOccurred = false;
    let output = undefined;
    try {
        output = callback();
        errorOccurred = false;
    }
    catch (err) {
        errorOccurred = true;
        console.warn(`[UNIT_TEST] assertError Error Surpressed...`);
    }
    if (!errorOccurred) {
        throw new Error(`[UNIT_TEST] assertError Expected error but received ${output} ${stringify(output, 2, null, 2)}`);
    }
}
export let AUnitTestService = class AUnitTestService {
    constructor() { }
    async translate({ type }) {
        const toTranslate = ['Unit Test', 'Start', 'Cancel'];
        const translations = await Translate.get(toTranslate);
        if (type === 'OBJECT') {
            return translations;
        }
        return toTranslate.map(key => translations[key]);
    }
    async showDialog() {
        // Clear unit tests
        globalThis._unitTests = {};
        const unitTests = [
            APageVisitorUnit,
            AFilterUnit
        ];
        const [tUnitTest] = await this.translate({ type: 'ARRAY' });
        const events = Alerts.show({
            translatedTitle: tUnitTest,
            content: ( /*html*/`
        <div class="mt-1">${await Translate.get("Test Settings")}</div>
        <div>
          <select class="form-select mt-1" id="unitPeriod">
            <option value="week">This Week</option>
            <option value="month" selected="selected">This Month</option>
            <option value="default">Default</option>
          </select>
          <select class="form-select mt-1" id="unitTesting">${unitTests.map(unitTest => `<option>${unitTest.name}</option>`).join('')}</select>
        </div>
      `),
            buttons: ALERT_BUTTONS.startCancel
        });
        events.on(ALERT_STATUS.ON_ACTION_PROCEED, () => {
            const period = events.$ele.find('#unitPeriod').val();
            const index = events.$ele.find('#unitTesting').prop('selectedIndex');
            const TestingUnit = unitTests[index];
            this.startTest(new TestingUnit({ period }));
        });
    }
    async startTest(test) {
        try {
            this.isTesting = true;
            this.testModule = test;
            test.assertCorrectConfiguration();
            const { _setups, _tests } = test.getUnitTest();
            Events.h_once(EVENTS.ERROR, (err) => {
                test.error = err;
            });
            await Promise.resolve().then(_ => _setups[0]());
            // if (test.error !== null) throw test.error
            for (let testFn of _tests) {
                // if (test.error !== null) throw test.error
                await Promise.resolve().then(_ => testFn());
            }
            if (test.error !== null) {
                throw test.error;
            }
            this.isTesting = false;
            AEngine.get(AAlertService).show({ title: ALERT_TITLES.Success, content: (`Test Finished without problems!`) });
        }
        catch (err) {
            this.isTesting = false;
            console.error(err);
            AEngine.get(AAlertService).show({ title: ALERT_TITLES.Warning, content: (`Test Failed!`), });
        }
    }
};
AUnitTestService = __decorate([
    executeOrder(Number.POSITIVE_INFINITY)
], AUnitTestService);
