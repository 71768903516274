import { AEngine } from "../core/AEngine.js";
import { GetCookie, SetCookie } from "../utils/tools.js";
import { EVENTS } from "../services/AEventService.js";
export class AQuickLogin {
    static async run() {
        if (AEngine.isDevelopmentMode) {
            globalThis.setCredentials = (input) => {
                SetCookie('credentials', input, 365);
            };
            globalThis.delCredentials = () => {
                SetCookie('credentials', '', 0);
            };
            Events.h_once(EVENTS.PAGE_INITIALIZED, () => {
                const credentials = GetCookie('credentials');
                // console.log({ credentials })
                if (credentials) {
                    const [u, p] = credentials.split(':');
                    $('#Username').val(u);
                    $('#Password').val(p);
                    $('#Login').trigger('click');
                }
            });
        }
    }
}
