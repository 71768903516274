import { AEngine, sleep } from '../core/AEngine.js';
import { gpsstat_tableformatter } from '../utils/table_formatter.js';
import { AIsLatLngValid, getCenterAny } from '../utils/maps.js';
import { APascal, clamp, waitForChromeFrame } from '../utils/tools.js';
import { AInterpolateService } from './AInterpolateService.js';
import { AResponse } from '../classes/AResponse.js';
import { AError } from '../classes/AError.js';
import { AColor } from '../classes/AColor.js';
export class ARouteDrawingService {
    constructor() {
        this.degToRad = Math.PI / 180.0;
    }
    autoInit() {
        this.interpolateService = AEngine.get(AInterpolateService);
    }
    checkPageScriptRequirements(pageScript) {
        const requiredMembers = [
            'map',
        ];
        for (let member of requiredMembers) {
            if (!pageScript.hasOwnProperty(member)) {
                // @ts-ignore
                if (pageScript.__lookupGetter__(member) === undefined) {
                    throw new Error(`PageScript.${member} is required for ARouteDrawingService!`);
                }
            }
        }
    }
    tmpTransformPoint(point, heading, distanceInMeters) {
        return google.maps.geometry.spherical.computeOffset(point, distanceInMeters, heading);
    }
    parseMapRouteItem(item, thresholds) {
        const { Points, Latitude, Longitude, DeviceName, GpsTime, Precision, Speed } = item;
        let Path = [];
        switch (Points?.length ?? 0) {
            case 0:
            case 1:
                if (AIsLatLngValid([Latitude, Longitude])) {
                    const point = new google.maps.LatLng(Latitude, Longitude);
                    const headings = [0, 45, 90, 135, 180, 225, 270, 315, 360];
                    Path = headings.map(heading => this.tmpTransformPoint(point, heading, 0.5));
                }
                break;
            default:
                Path = Points.filter(p => AIsLatLngValid([p.lat, p.lng])).map((point) => new google.maps.LatLng(point.lat, point.lng));
                break;
        }
        return { GpsTime, DeviceName, Path, Speed, Precision };
    }
    async showMapRoute(map, data, opt) {
        const { category, fillerOpacity, strokeOpacity } = Object.assign({ fillerOpacity: 0.7, strokeOpacity: 1.0 }, opt);
        const { legend, bounds } = opt;
        const ares = new AResponse((data instanceof AResponse) ? data.Original : data);
        const routeItems = ares.map(item => {
            item.Points = item.Points.map(([lng, lat]) => ({ lat, lng }));
            return this.parseMapRouteItem(item, opt.thresholds);
        });
        // let prevRouteItem: AMapRouteItem|null = null
        // await AThread.prepare(routeItems, {
        //   prefix: 'showMapRoute',
        // }).asyncMap(async (item: AMapRouteItem, i: number, p: number) => {
        // await asyncMapArray<void, AMapRouteItem>(routeItems, 50, async (item: AMapRouteItem, i: number) => {
        const output = [];
        const polygonOptions = { geodesic: true, strokeWeight: 6 };
        if (opt.invisible === true) {
            polygonOptions.visible = false;
        }
        for (let i = 0; i < routeItems.length; i++) {
            let item = routeItems[i];
            const { GpsTime, DeviceName, Path } = item;
            if (Path.length === 0) {
                console.warn('Path.length === 0!', item);
            }
            const dataValue = item[APascal(category)];
            const kmlName = (`[${DeviceName}]${category}: ${dataValue}`);
            const dataObj = { GpsTime, DeviceName, [category]: dataValue };
            const color = legend.calcColor({ perc: dataValue }).rgba();
            let stroke = new google.maps.Polyline({
                map,
                path: Path,
                strokeColor: color,
                strokeOpacity: strokeOpacity ?? 1,
                ...polygonOptions,
            });
            // stroke.set('isFiller', false)
            stroke.set('kmlName', kmlName);
            stroke.data = dataObj;
            google.maps.event.addListener(stroke, 'click', function (event) {
                Loading.waitForPromises(purgatoryService.buildAndShowInfoWindowLegacy({
                    marker: this,
                    data: Object.assign({
                        index: i
                    }, item),
                    tableFormatter: gpsstat_tableformatter({
                        override: {
                            '*': { type: 'UNKNOWN' },
                            'Path': { type: 'HIDDEN' },
                            'Points': { type: 'HIDDEN' },
                            'Meta': { type: 'HIDDEN' },
                        }
                    }),
                    sorting: [],
                })).catch(AError.handle);
            });
            output.push(stroke);
            item.Meta = { Color: color, Polyline: stroke };
            // prevRouteItem = item
        }
        if (bounds) {
            output.map(polyline => bounds.extend(getCenterAny(polyline)));
        }
        AEngine.log(`Done Drawing Route`);
        return output;
    }
    async animateRouteVisibility(fps = 60) {
        const msTimeout = 1000.0 / fps;
        var ps = PageScript;
        for (let line of ps.RouteList) {
            line.setOptions({ visible: false });
        }
        let prevLine;
        for (let line of ps.RouteList) {
            line.setOptions({ visible: true });
            await sleep(msTimeout);
            // await new Promise((resolve) => setTimeout(resolve, 1000 / fps))
            if (prevLine)
                prevLine.setOptions({ visible: false });
            prevLine = line;
        }
        for (let line of ps.RouteList) {
            line.setOptions({ visible: true });
        }
        AEngine.log('Done Animating Route');
    }
    async animateRoute(fps = 24, animation = 'visibiliy') {
        // const msTimeout = 1000.0 / fps
        var ps = PageScript;
        async function foo(array, process) {
            const targetFps = 1000.0 / fps;
            const startTime = performance.now();
            let prevTime = startTime;
            var size = array.length;
            let timeout = -1;
            let deltaArr = [];
            let prevPerc = -5;
            for (let i = 0; i < size; i++) {
                process(array[i], i);
                let perc = (i * 100 / size);
                const delta = performance.now() - prevTime;
                deltaArr.push(delta);
                // if (delta > targetFps) {
                //   await waitForChromeFrame()
                // }
                if (perc - prevPerc > 5) {
                    prevPerc = perc;
                    console.log(`${perc}%`, { delta });
                    if (delta < targetFps) {
                        timeout = Math.max(targetFps - (performance.now() - prevTime), 0);
                        prevTime = performance.now();
                    }
                    else {
                        timeout = 0.1;
                    }
                    await waitForChromeFrame();
                }
                if (timeout !== -1) {
                    prevTime = performance.now();
                    await sleep(timeout);
                    timeout = -1;
                }
            }
            let endTime = performance.now();
            console.log(`100%`, { delta: performance.now() - prevTime, time: endTime - startTime, deltaArr });
        }
        await sleep(30);
        if (animation === 'weight') {
            await foo(ps.RouteList, (line, i) => {
                let prevWeight = line['strokeWeight'];
                line.setOptions({ strokeWeight: 10 });
                setTimeout(() => {
                    line.setOptions({ strokeWeight: prevWeight });
                }, 300);
            }).catch(err => console.error(err));
        }
        else {
            await foo(ps.RouteList, (line, i) => {
                line.setVisible(true);
            }).catch(err => console.error(err));
        }
        // if (prevLine) prevLine.setOptions({strokeWeight: prevWeight})
        // for (let line of ps.RouteList) {
        //   if (prevLine) prevLine.setOptions({strokeWeight: prevWeight})
        //   prevWeight = line['strokeWeight']!
        //   line.setOptions({strokeWeight: 10})
        //   await sleep(16.7)
        //   // await new Promise((resolve) => setTimeout(resolve, 1000 / fps))
        //   prevLine = line
        // }
        // if (prevLine) prevLine.setOptions({ strokeWeight: prevWeight})
        AEngine.log('Done Animating Route');
    }
    distanceMetersLon_X(LL1, LL2) {
        const EquatorialDistancePerDeg = 6378137.0 * this.degToRad; /// EquatorialRadius = 6378137.
        return (LL2.lng - LL1.lng) * (EquatorialDistancePerDeg * Math.cos((LL1.lat + LL2.lat) * 0.5 * this.degToRad));
    }
    distanceMetersLat_Y(LL1, LL2) {
        const NorthSouthDistancePerDeg = 6335439.0 * this.degToRad; /// NorthSouthRadius = 6335439.
        return (LL2.lat - LL1.lat) * NorthSouthDistancePerDeg;
    }
    /**
     * Distance in meters
     * @param LL1
     * @param LL2
     * @returns
     */
    distanceLL(LL1, LL2) {
        const DeltaLatY_SN_Meter = this.distanceMetersLat_Y(LL1, LL2);
        const DeltaLongX_WE_Meter = this.distanceMetersLon_X(LL1, LL2);
        return Math.sqrt(DeltaLatY_SN_Meter * DeltaLatY_SN_Meter + DeltaLongX_WE_Meter * DeltaLongX_WE_Meter);
    }
    gpsTimeDiff(a, b) {
        if (a == null || b == null)
            return 0;
        return (b.getTime() - a.getTime()) / 1000; // difference in seconds
    }
    calcColorNew(opt) {
        if (opt.transition !== undefined) {
            opt.colors = opt.transition.map(v => v.color);
            opt.bounds = opt.transition.map(v => v.x);
        }
        const inputColors = opt.colors ?? ['#3366cc', '#dc3912', '#ff9900']; //, '#109618', '#990099', '#0099c6', '#dd4477', '#66aa00', '#b82e2e', '#316395', '#994499']
        const bounds = opt.bounds ?? [25, 50, 75];
        const colors = inputColors.map(hex => new AColor(hex).hsv);
        if (opt.value <= bounds[0]) {
            // first
            return colors[0];
        }
        else if (opt.value >= bounds[bounds.length - 1]) {
            // last
            return colors[colors.length - 1];
        }
        // let left = -1, right = -1
        const val = clamp(opt.value, bounds[0], bounds[bounds.length - 1]);
        for (let i = 1; i < bounds.length; i++) {
            if (opt.value < bounds[i]) {
                // ['#3366cc', '#dc3912', '#ff9900']
                // [25, 50, 75]
                let bound = {
                    lower: bounds[i - 1],
                    upper: bounds[i]
                };
                let color = {
                    lower: colors[i - 1],
                    upper: colors[i]
                };
                const t = (val - bound.lower) / (bound.upper - bound.lower);
                return this.interpolateService.colorHSV(color.lower, color.upper, t);
            }
        }
        return new AColor('#ff00a3').hsv;
    }
    calcColor(opt) {
        let { start, end, t, inverted } = opt;
        t = Math.min(Math.max(t, 0), 1);
        return this.interpolateService.colorHSV(start, end, inverted ? 1 - t : t);
    }
    removeNullValues(points) {
        return points.filter((point) => point[0] != null && point[1] != null && point[0] !== 0 && point[1] !== 0);
    }
}
