import { ALoopTimer } from "../ALoopTimer.js";
export class ANodeSessionTracker {
    constructor(opt) {
        this.opt = opt;
    }
    parseGps(s) {
        const hasGps = s.Gps && s.Gps.Latitude && s.Gps.Longitude;
        return (hasGps) ? { lat: s.Gps.Latitude, lng: s.Gps.Longitude } : null;
    }
    init(SessionData) {
        // Initialize sessionMap
        Object.keys(SessionData).map((key) => {
            const s = SessionData[key];
            this.addMarker(s);
        });
        // Step/Update/Refresh Loop
        if (this.opt.interpolate) {
            this._looper = new ALoopTimer(() => {
                const sessionArr = Object.values(this.opt.mapMarkers) || [];
                for (const marker of sessionArr) {
                    if (marker.meta && marker.meta.target) {
                        const prevDest = marker.meta.prevTarget;
                        const dest = marker.meta.target;
                        const { avg, nextGpsExpectedAt } = this.calcGpsInterval(marker);
                        const t = nextGpsExpectedAt / avg;
                        const data = mapHelperService.lerp(prevDest, dest, mapHelperService.clamp01(1 - t));
                        marker.setPosition(data.pos);
                    }
                }
            }, { loopLifeCycle: "PAGE", timeout: 1000.0 / this.opt.lerpSpeed }).start();
        }
        return this;
    }
    onDataReceived(SessionData) {
        const { mapMarkers } = this.opt;
        Object.keys(SessionData).map((NodeName) => {
            const s = SessionData[NodeName];
            if (!mapMarkers.hasOwnProperty(NodeName)) {
                this.addMarker(s);
            }
            else {
                this.updateMarker(s);
            }
        });
        if (this.opt.onChange) {
            this.opt.onChange();
        }
    }
    addMarker(s) {
        const { mapMarkers, interpolate, map } = this.opt;
        const pos = this.parseGps(s);
        if (pos === null) {
            return;
        }
        const marker = new google.maps.Marker({
            position: pos,
            map: map,
            icon: {
                url: mapHelperService.getMapIcon(s.NodeType, ''),
                scaledSize: new google.maps.Size(48, 48)
            }
        });
        marker.set('NodeType', s.NodeType);
        marker.set('NodeName', s.NodeName);
        marker.meta = {
            interpolate: s.NodeType !== 'BackOffice' ? interpolate : false,
            gpsTimes: [new Date(s.Gps.GpsTime)],
            target: pos,
            prevTarget: pos
        };
        marker.data = s;
        google.maps.event.addListener(marker, "click", purgatoryService.clickTeamMember);
        mapMarkers[s.DeviceName] = marker;
    }
    updateMarker(s) {
        const { mapMarkers, interpolate, map } = this.opt;
        const pos = this.parseGps(s);
        if (pos === null) {
            return;
        }
        const marker = mapMarkers[s.DeviceName];
        marker.data = s;
        if (marker.meta === undefined || this.opt.interpolate !== true) {
            marker.setPosition(pos);
            return;
        }
        const newGpsTime = new Date(s.Gps?.GpsTime);
        const prevGpsTime = marker.meta.gpsTimes[marker.meta.gpsTimes.length - 1];
        if (prevGpsTime.getTime() !== newGpsTime.getTime()) {
            marker.meta.prevTarget = marker.meta.target;
            marker.meta.target = pos;
            marker.meta.gpsTimes.push(newGpsTime);
            if (marker.meta.gpsTimes.length > 5) {
                // Remove oldest element
                marker.meta.gpsTimes.shift();
            }
            marker.setPosition(marker.meta.prevTarget);
        }
    }
    calcGpsInterval(marker) {
        var arr = [];
        const gpsTimes = marker.meta?.gpsTimes || [];
        if (gpsTimes.length < 2) {
            return {
                avg: 1000,
                nextGpsExpectedAt: 0
            };
        }
        for (var i = 1; i < gpsTimes.length; i++) {
            arr.push(gpsTimes[i].getTime() - gpsTimes[i - 1].getTime());
        }
        const avg = arr.reduce((a, b) => a + b) / arr.length;
        const nextGpsExpectedAt = Math.max(0, avg - (Date.now() - gpsTimes[gpsTimes.length - 1].getTime()));
        return { avg, nextGpsExpectedAt };
    }
}
