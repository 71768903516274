import { AError } from "../../classes/AError.js";
import { sleep } from "../../core/AEngine.js";
import { MAP_OPTIONS } from "../../core/maps/AMapStructs.js";
import { ALERT_TITLES } from "../../services/AAlertService.js";
import { createMap } from "../../utils/maps.js";
export class APage {
    constructor() {
        this.markers = [];
        this.map = createMap('.aci-map', {
            zoom: 24
        });
        this.StartPosition = { Lat: 0.0, Lon: 0.0 };
        this.EndPosition = { Lat: 0.0, Lon: 0.0 };
        this.map.addListener("click", (mapsMouseEvent) => {
            this.onMapClicked(mapsMouseEvent);
        });
    }
    async init() {
        mapHelperService.prepareMapItems(MAP_OPTIONS.Default, {
            showLegend: false,
            showSearch: true,
            allowExport: false,
        }).catch(AError.handle);
        this.map.fit();
    }
    onMapClicked(clickEvent) {
        if (this.StartPosition.Lat == 0.0 && this.StartPosition.Lon == 0.0) {
            this.StartPosition.Lat = clickEvent.latLng.lat();
            this.StartPosition.Lon = clickEvent.latLng.lng();
            var marker = new google.maps.Marker({
                position: clickEvent.latLng,
                title: "Start",
                icon: {
                    url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png"
                },
                map: this.map
            });
            this.markers.push(marker);
        }
        else if (this.EndPosition.Lat == 0.0 && this.EndPosition.Lon == 0.0) {
            this.EndPosition.Lat = clickEvent.latLng.lat();
            this.EndPosition.Lon = clickEvent.latLng.lng();
            var marker = new google.maps.Marker({
                position: clickEvent.latLng,
                title: "Start",
                icon: {
                    url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png"
                },
                map: this.map
            });
            this.markers.push(marker);
        }
        else {
            for (let i = 0; i < this.markers.length; i++) {
                this.markers[i].setMap(null);
            }
            this.markers = [];
            this.StartPosition = { Lat: 0.0, Lon: 0.0 };
            this.EndPosition = { Lat: 0.0, Lon: 0.0 };
            this.route?.setMap(null);
        }
        if (this.StartPosition.Lat != 0.0 && this.StartPosition.Lon != 0.0
            && this.EndPosition.Lat != 0.0 && this.EndPosition.Lon != 0.0) {
            // Loading.waitForPromises(
            //   this.getRouteWaySegments(this.StartPosition, this.EndPosition)
            // ).then(response => {
            //   console.log(response)
            // }).catch(AError.handle)
            Loading.waitForPromises(this.getRoute(this.StartPosition, this.EndPosition)).then(response => {
                this.drawRouteOnMap(response);
            }).catch(AError.handle);
        }
    }
    getRoute(_StartPosition, _EndPosition) {
        const request = {
            "FromLat": _StartPosition.Lat,
            "FromLon": _StartPosition.Lon,
            "ToLat": _EndPosition.Lat,
            "ToLon": _EndPosition.Lon,
        };
        return new Promise((resolve) => {
            CCCClient.SendMessage("RouteRequest", 1, request, 0, {
                Type: "ControlCenter",
                IndexNumber: 1,
                CustomerNumber: CCCClient.NodeCustomerNumber,
                ProjectNumber: CCCClient.NodeProjectNumber //CCCClient.NodeProjectNumber
            });
            let hasResponse = false;
            Events.once(`RouteResponse`, response => {
                hasResponse = true;
                resolve(response);
            });
            sleep(5000).then(async (_) => {
                if (hasResponse === false) {
                    const events = Alerts.show({
                        title: ALERT_TITLES.Warning,
                        content: await Translate.get('RouteRequest failed')
                    });
                    resolve(false);
                }
            });
        });
    }
    getRouteWaySegments(_StartPosition, _EndPosition) {
        const request = {
            "FromLat": _StartPosition.Lat,
            "FromLon": _StartPosition.Lon,
            "ToLat": _EndPosition.Lat,
            "ToLon": _EndPosition.Lon,
        };
        return new Promise((resolve) => {
            CCCClient.SendMessage("RouteWaySegmentsRequest", 1, request, 0, {
                Type: "ControlCenter",
                IndexNumber: 1,
                CustomerNumber: CCCClient.NodeCustomerNumber,
                ProjectNumber: CCCClient.NodeProjectNumber //CCCClient.NodeProjectNumber
            });
            let hasResponse = false;
            Events.once(`RouteWaySegmentsResponse`, response => {
                hasResponse = true;
                resolve(response);
            });
            sleep(5000).then(async (_) => {
                if (hasResponse === false) {
                    const events = Alerts.show({
                        title: ALERT_TITLES.Warning,
                        content: await Translate.get('RouteRequest failed')
                    });
                    resolve(false);
                }
            });
        });
    }
    drawRouteOnMap(response) {
        if (response === null || response.Route === null || response.Route.length === 0) {
            const events = Alerts.show({
                title: ALERT_TITLES.Warning,
                content: 'No route found'
            });
            return;
        }
        let route = response.Route.coordinates;
        let routePaths = [];
        var bounds = new google.maps.LatLngBounds();
        if (route === null)
            return;
        for (let r = 0; r < route.length; r++) {
            let latlng = { lat: route[r][1], lng: route[r][0] };
            routePaths.push(latlng);
            bounds.extend(latlng);
        }
        // Construct the polygon, including both paths.
        let newRoute = new google.maps.Polyline({
            path: routePaths,
            geodesic: true,
            strokeColor: '#0000FF',
            strokeOpacity: 0.5,
            strokeWeight: 10
        });
        this.route = newRoute;
        this.map.fitBounds(bounds);
        newRoute.setMap(this.map);
    }
}
export function render() {
    return ( /*html*/`
    <div style="position: relative; height: 100%; width: 100%;">
      <div id="map" class="aci-map"></div>
    </div>
  `);
}
