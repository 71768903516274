import { AEngine } from "../core/AEngine.js";
import { ALL_GEO_TYPES } from "../core/maps/AMapStructs.js";
import { AStatisticsService } from "../services/AStatisticsService.js";
import { ADynamicChartSnapshotDetections } from "./ADynamicChartSnapshotDetections.js";
export class ADynamicChartUtils {
    static get dynamicChartSettings() {
        return {
            chartType: [
                { key: 'column', text: 'Column Chart' },
                { key: 'bar', text: 'Bar Chart' },
                { key: 'line', text: 'Line Chart' },
                { key: 'spline', text: 'Spline Chart' },
                { key: 'area', text: 'Area Chart' },
                { key: 'areaspline', text: 'Area Spline Chart' },
                { key: 'scatter', text: 'Scatter Chart' },
                { key: 'pie', text: 'Pie Chart' },
            ],
            mapTo: [
                { key: 'RouteArea', text: 'RouteArea' },
                { key: 'Area', text: 'Area' },
                { key: 'Zone', text: 'Zone' },
                { key: 'Street', text: 'Street' },
            ],
            verticalAxis: [
                { key: 'Detections', text: 'Detections' },
                { key: 'Suspects', text: 'Suspects' },
                { key: 'Sanctions', text: 'Sanctions' },
                // { key: 'Capacity', text: 'Capacity' },
                // { key: 'Occupancy', text: 'Occupancy' },
                { key: 'VisitorRate', text: 'VisitorRate' },
                { key: 'PermitRate', text: 'PermitRate' },
                { key: 'Compliancy', text: 'Compliancy' },
                { key: 'CompliancyVisitor', text: 'CompliancyVisitor' },
                { key: 'EnforcementIntensity', text: 'EnforcementIntensity' },
            ],
            horizontalAxis: [
                { key: 'HourOfDay', text: 'HourOfDay' },
                { key: 'DayOfWeek', text: 'DayOfWeek' },
                { key: 'MonthOfYear', text: 'MonthOfYear' },
                { key: 'Year', text: 'Year' },
                { key: '', text: '______' },
                { key: 'DetectionDeviceId', text: 'ScanDevice' },
                { key: 'ScanDeviceType', text: 'ScanDeviceType' },
                { key: 'VerificationDevice', text: 'VerificationDevice' },
                // { key: '', text: '______' },
                // { key: 'FollowupRate', text: 'FollowupRate' },
                // { key: 'SuspectRate', text: 'SuspectRate' },
                // { key: 'SanctionRate', text: 'SanctionRate' },
            ]
        };
    }
    constructor() { }
    isGeoAxis(axis) {
        if (axis === 'Street')
            return true;
        return ALL_GEO_TYPES.includes(axis);
    }
    async genStatisticsHashmapper(group, opt) {
        let geoHashMap;
        let geoHashMapPromise;
        const key = group;
        if (this.isGeoAxis(key) && opt.mapGeoUsingSegmentId === true) {
            // TODO: Add static caching of this hashmap [key: FromGeoType + ToGeoType]: data
            geoHashMapPromise = AEngine.get(AStatisticsService).fetchGeoConnectionNames({
                FromGeoType: 'Segment', ToGeoType: key
            });
            if (geoHashMap === undefined) {
                geoHashMap = await geoHashMapPromise;
            }
            return (Group) => geoHashMap.map[Group];
        }
        return ADynamicChartUtils.groupByMap[key] ?? ADynamicChartUtils.groupByMap['*'];
    }
    async genStatisticsHashmaps(groups) {
        // TODO: Merge this logic with genStatisticsHashmapper
        if (AEngine.isDevelopmentMode) {
            console.warn(`// TODO: Merge this logic with genStatisticsHashmapper`);
        }
        let geoHashMap;
        let geoHashMapPromise;
        const array = await Promise.all(groups.map(async ({ group, sort }) => {
            if (this.isGeoAxis(group)) {
                if (geoHashMapPromise === undefined) {
                    geoHashMapPromise = AEngine.get(AStatisticsService).fetchGeoConnectionNames({
                        FromGeoType: 'Segment', ToGeoType: group
                    });
                }
                if (geoHashMap === undefined) {
                    geoHashMap = await geoHashMapPromise;
                }
                return (Group) => geoHashMap.map[Group];
            }
            return ADynamicChartUtils.groupByMap[group] ?? ADynamicChartUtils.groupByMap['*'];
        }));
        return [array[0], array[1]];
    }
    async genGroupMapping(groups) {
        const keys = [[], []];
        // const groups: string[] = [...opt.groups]
        const [mapA, mapB] = await this.genStatisticsHashmaps(groups);
        const mapTo = (GroupA, GroupB) => {
            const y = mapA(GroupA);
            const x = mapB(GroupB);
            if (x == null || y == null) {
                return null;
            }
            if (x === '?' || y === '?') {
                console.warn('x and y are both equal to "?"!');
            }
            if (!keys[0].includes(x)) {
                keys[0].push(x);
            }
            if (!keys[1].includes(y)) {
                keys[1].push(y);
            }
            return `${x} ${y}`;
        };
        return {
            groups: groups.map(g => g.group),
            yKeys: groups[1].sort ? keys[1].sort() : keys[1],
            xKeys: groups[0].sort ? keys[0].sort() : keys[0],
            mapTo,
            mapA,
            mapB
        };
    }
    async transformAxisValues(options, map) {
        if (options.isHorizontal) {
            const [bars, ...columns] = options.res.Columns;
            const flipped = columns.map((col, colI) => {
                const flippedItem = options.res.Rows.map((row, rowI) => {
                    return row[colI + 1];
                });
                return [col].concat(flippedItem);
            });
            await Promise.all(flipped.map(async ([category, ...yValues]) => {
                const tmp = await Promise.resolve().then(() => map([category, ...yValues], options.res));
                if (tmp != null) {
                    const index = options.res.Columns.indexOf(category);
                    options.res.Rows = options.res.Rows.map((row, rowI) => {
                        // options.res.Rows[rowI][index] = tmp[rowI]
                        row[index] = tmp[rowI];
                        return row;
                        // return tmp[rowI]
                    });
                }
            }));
        }
        else {
        }
    }
    async transformAxisCategories(options, map) {
        let labelsToTranslate = new Set();
        const slicedColumns = options.res.Columns.slice(1);
        if (options.isHorizontal) {
            // options.isHorizontal ? [] : (res.Rows as any[])[index].slice(1, res.Columns.length)
            // TODO: FIX THIS BS
            // const yValues = options.res.Rows[index]
            const arrayRef = slicedColumns.map((col, colI) => col);
            options.res.Columns = [options.res.Columns[0], ...await Promise.all(slicedColumns.map(async (col, colI) => {
                    const column = await Promise.resolve().then(() => map(col, colI, arrayRef, options.res));
                    if (column)
                        labelsToTranslate.add(column);
                    return column;
                }))];
        }
        else {
            // const yValues = options.res.rows[y]
            // const flipped = slicedColumns.map((col, colI) => {
            //   return [col].concat(options.res.Rows.map((row, rowI) => row[colI + 1]))
            // })
            const arrayRef = options.res.Rows.map((row, rowI) => row[0]);
            options.res.Rows = await Promise.all(options.res.Rows.map(async (row, y) => {
                // const yValues = flipped[y]
                const label = await Promise.resolve().then(() => map(row[0], y, arrayRef, options.res));
                row[0] = label;
                if (row[0])
                    labelsToTranslate.add(row[0]);
                return row;
            }));
        }
        return {
            labelsToTranslate: [...labelsToTranslate]
        };
    }
    async transformTranslate(options, labelsToTranslate) {
        const { res, isHorizontal, IncludeTotals } = options;
        const translations = await Translate.get(labelsToTranslate);
        var regexAddSpaceBeforeNumber = /[^0-9](?=[0-9])/g;
        if (isHorizontal) {
            for (let i = 1; i < res.Columns.length - (IncludeTotals ? 1 : 0); i++) {
                const c = res.Columns[i];
                res.ColumnsTranslated[i] = translations[c] ?? translations[c.replace(regexAddSpaceBeforeNumber, '$& ')] ?? res.ColumnsTranslated[i] ?? '????';
            }
            // const translations = await Translate.get(res.Columns)
            // res.ColumnsTranslated = res.Columns.map((c, i) => translations[c] ?? translations[c.replace(regexAddSpaceBeforeNumber, '$& ')] ?? res.ColumnsTranslated[i] ?? '????')
        }
        else {
            for (let i = 0; i < res.Rows.length; i++) {
                res.Rows[i][0] = translations[res.Rows[i][0]] ?? translations[res.Rows[i][0].replace(regexAddSpaceBeforeNumber, '$& ')] ?? '????';
            }
        }
        return options;
    }
    async groupByAxis(options) {
        const order = [];
        const groups = {};
        const mergeToMap = {};
        if (options.isHorizontal) {
            options.res.Columns.map((name, i) => {
                if (!groups.hasOwnProperty(name)) {
                    groups[name] = i;
                    order.push(name);
                }
                else {
                    mergeToMap[i] = groups[name];
                }
            });
            options.res.Columns = order;
            const xOffsets = Object.keys(mergeToMap).map(str => Number(str)).sort((a, b) => b - a); // Sort Descending
            xOffsets.map((fromIndex) => {
                if (fromIndex !== 0 && mergeToMap.hasOwnProperty(fromIndex)) {
                    const toIndex = mergeToMap[fromIndex];
                    if (toIndex === 0)
                        return;
                    options.res.Rows = options.res.Rows.map((row, y) => {
                        row[toIndex] += row[fromIndex];
                        row[fromIndex] = null;
                        row.splice(fromIndex, 1);
                        return row;
                    });
                }
            });
        }
        else {
            const names = options.res.Rows.map((row) => row[0]);
            names.map((name, i) => {
                if (!groups.hasOwnProperty(name)) {
                    groups[name] = i;
                    order.push(name);
                }
                else {
                    mergeToMap[i] = groups[name];
                }
            });
            const yOffsets = Object.keys(mergeToMap).map(str => Number(str)).sort((a, b) => b - a); // Sort Descending
            yOffsets.map((fromIndex) => {
                if (mergeToMap.hasOwnProperty(fromIndex)) {
                    const toIndex = mergeToMap[fromIndex];
                    const fromRow = options.res.Rows[fromIndex];
                    const toRow = options.res.Rows[toIndex];
                    // options.res.Rows[toIndex] = options.res.Rows[toIndex]
                    fromRow.map((item, xOffset) => {
                        if (xOffset === 0)
                            return;
                        toRow[xOffset] += item;
                    });
                    options.res.Rows.splice(fromIndex, 1);
                }
            });
            // options.res.Rows = await Promise.all(options.res.Rows.map(async (row, i) => {
            //   // row[0] = await Promise.resolve().then(() => map(row[0], i, options.res))
            //   return row
            // }))
            console.log({ order, groups, mergeToMap });
        }
        return options.res;
    }
    async parseHour(options) {
        await this.transformAxisCategories(options, (curr, index, arrayRef, res) => {
            // const next = arrayRef[index+1]
            // return (next === undefined) ? `${curr}+` : `${curr} - ${next}`
            return (curr !== undefined) ? `${curr}:00` : curr;
        });
        options.res.ColumnsTranslated = options.res.Columns;
        return {
            data: options.res
        };
    }
    async parseRange(options) {
        await this.transformAxisCategories(options, (curr, index, arrayRef, res) => {
            const next = arrayRef[index + 1];
            return (next === undefined) ? `${curr}+` : `${curr} - ${next}`;
        });
        options.res.ColumnsTranslated = options.res.Columns;
        return {
            data: options.res
        };
    }
    async parseDetectionState(options) {
        await this.transformAxisCategories(options, (key) => {
            return Translate.get(key.replace('InProgress_', ''));
        });
        return { data: options.res };
    }
    async parseVerificationChannel(options) {
        await this.transformAxisCategories(options, (key) => {
            return Translate.get(key);
        });
        return { data: options.res };
    }
    async parseUnificationsExperimental(options) {
        const UnificationType = ADynamicChartSnapshotDetections.templates[options.key.replace('Chart_', '')];
        const unificationRef = new UnificationType();
        await this.transformAxisValues(options, async (axisValues, res) => {
            const [category, ...yValues] = axisValues;
            return yValues.map(yValue => {
                unificationRef.addOption(category, yValue);
                return yValue;
            });
        });
        console.log({ unificationRef });
        const depth = 1;
        const { labelsToTranslate } = await this.transformAxisCategories(options, async (key) => {
            // if (options.isHorizontal && index === 0) { // Don't do anything with bars
            //   return key
            // }
            const paths = key.split('_');
            let unify = unificationRef;
            paths.slice(0, depth).map(p => { unify = unify.Options[p]; });
            return unify.KeyShort;
        });
        await this.groupByAxis(options);
        await this.transformTranslate(options, labelsToTranslate);
        const v = unificationRef;
        let series = [{
                name: v.Name,
                colorByPoint: true,
                data: Object.keys(v.Options).map(key => {
                    const subOpt = v.Options[key];
                    return {
                        name: subOpt.KeyShort,
                        y: subOpt.Count,
                        drilldown: Object.keys(subOpt.Options).length && subOpt.Count > 0 ? subOpt.KeyShort : null,
                    };
                })
            }];
        let drilldown = {
            breadcrumbs: { position: { align: 'right' } },
            series: Object.keys(v.Options).map(key => {
                const subOpt = v.Options[key];
                return {
                    id: subOpt.KeyShort,
                    name: subOpt.KeyShort,
                    // y: subOpt.Count,
                    data: Object.keys(subOpt.Options).map(subKey => {
                        return [
                            subKey,
                            subOpt.Options[subKey].Count
                        ];
                    })
                };
            })
        };
        console.log({ series, drilldown });
        return {
            data: options.res,
            meta: {
                series,
                drilldown
            }
        };
    }
    /**
     * If TransformData is set to true in this.Selections the final dataset will be passed through this method
     * The reason for this implementation is to handle unifications
     */
    async parseUnifications(options) {
        const { key, res, isHorizontal } = options;
        const { templates } = ADynamicChartSnapshotDetections;
        const output = {
            Columns: [],
            ColumnsTranslated: [],
            Rows: []
        };
        const map = {};
        const UnificationType = templates[key.replace('Chart_', '')];
        const unificationRef = new UnificationType();
        if (isHorizontal) {
            res.Rows.map((row) => {
                const group = row[0];
                if (!map.hasOwnProperty(group)) {
                    map[group] = new UnificationType();
                }
                const statsEntry = map[group];
                res.Columns.slice(1).map((unificationKey, i) => (unificationKey !== 'Total' && statsEntry.addOption(unificationKey, row[i + 1])));
            });
            output.Columns = [res.Columns[0]]
                .concat(Object.keys(unificationRef.Options).map(k => unificationRef.Options[k].Key))
                .concat(options.IncludeTotals ? ['Total'] : []);
            const translations = await Translate.get(output.Columns);
            output.ColumnsTranslated = output.Columns.map(c => translations[c]);
            output.Rows = await Promise.all(Object.keys(map).map(async (mapId) => {
                return [mapId ?? '?']
                    .concat(Object.keys(unificationRef.Options).map(k => map[mapId].Options[k].Count))
                    .concat(options.IncludeTotals ? [map[mapId].Count] : []);
            }));
        }
        else {
            res.Columns.slice(1).map((group, i) => {
                if (!map.hasOwnProperty(group)) {
                    map[group] = new UnificationType();
                }
                const statsEntry = map[group];
                res.Rows.map((row) => statsEntry.addOption(row[0], row[i + 1]));
            });
            output.Columns = res.Columns;
            output.ColumnsTranslated = res.ColumnsTranslated;
            output.Rows = await Promise.all(Object.keys(unificationRef.Options).map(async (k) => {
                const { Key } = unificationRef.Options[k];
                return [Key ? await Translate.get(Key) : ''].concat(Object.keys(map).map(mapId => map[mapId].Options[k].Count));
            }));
            // output.Rows.push([
            //   'Total',
            //   ...output.Columns.slice(1).map((col, colI) => {
            //     let sum = 0
            //     output.Rows.map(row => {
            //       sum += row[colI+1]
            //     })
            //     return sum
            //   })
            // ])
        }
        return { data: output };
    }
}
ADynamicChartUtils.groupByMap = {
    '*': (Group) => `${Group}`,
    'DetectionDeviceId': (Group) => (DeviceIdToName.hasOwnProperty(Group)) ? DeviceIdToName[Group] : null,
    'ScanDeviceType': (Group) => (DeviceIdToName.hasOwnProperty(Group)) ? DeviceIdToName[Group].replace(/[0-9]+/g, '') : null,
    'VerificationDevice': (Group) => Group ? (Group || '').replace(/[0-9]+/g, '') : '?',
    'HourOfDay': (Group) => (Group != null) ? `${Group}:00` : '?',
    // 'VerificationUser': (Group: string) => Group,
    // 'FinalVerificationUser': (Group: string) => Group,
    // 'SanctionRate': (Group: string) => Group,
    // 'SuspectRate': (Group: string) => Group,
};
