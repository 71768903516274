export class APacketIdAllocator {
    constructor() {
        this.packetNum = 1000;
    }
    get _nextPacketId() {
        return this._addPadding(this.packetNum++);
    }
    _addPadding(s, len = 4) {
        s = s.toString();
        if (s.length < len) {
            s = ('0000000000' + s).slice(-len);
        }
        return s;
    }
    getNextPacketId(suffix = 'PACKET') {
        return `[${this._nextPacketId}]${suffix}`;
    }
}
