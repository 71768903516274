import { AError } from "../../classes/AError.js";
import { AExportMap } from "../../classes/AExportMap.js";
import { genLegend } from "../../classes/ALegend.js";
import { AEngine } from "../../core/AEngine.js";
import { MAP_OPTIONS } from "../../core/maps/AMapStructs.js";
import { ALERT_BUTTONS, ALERT_STATUS } from "../../services/AAlertService.js";
import { ARouteDrawingService } from "../../services/ARouteDrawingService.js";
import { createMap, RequestMapRouteFull } from "../../utils/maps.js";
import { DeviceMultiOptions, generateTreeDropdown, mergeDeep } from "../../utils/tools.js";
const routeDrawingService = AEngine.get(ARouteDrawingService);
export class APage {
    get category() { return $('#Category').val(); }
    // private precalc: AFormulaPrecalcInternal|null = null // = {min: 0, max: 100, unit: '%'}
    // private precalcFallback: AFormulaPrecalcInternal = {min: 1, max: 99, left: 1, right: 99, unit: '', hideOutsideBounds: false}
    // window: { $window: JQuery<HTMLElement>; elements: JQuery<HTMLElement>[] }
    // form: AFormInstance<ARouteThresholds>
    constructor() {
        this.RouteList = [];
        this.SessionMarkers = {};
        this.map = createMap('map', {
            zoom: 14,
            streetViewControl: true
        });
    }
    async init() {
        FilterManager.load();
        nodeSessionService.bindSessionsToMap({
            interpolate: false,
            mapMarkers: this.SessionMarkers,
            map: this.map
        });
        await Loading.waitForPromises([
            generateTreeDropdown('#DeviceMulti', DeviceMultiOptions(), {
                useIndices: false,
                attributeToUse: 'unificationindex',
            }),
        ]);
        await mapHelperService.prepareMapItems(MAP_OPTIONS.All, {
            showLegend: false,
            showSearch: true,
            createLabel: true
        });
        this.legend = await genLegend({
            map: this.map,
            smallTitle: true,
            profileKey: this.category,
        });
        this.legend.onClick(() => this.onLegendClick());
        this.map.fit();
        $('#RefreshButton').on('click', _ => FilterManager.showFilterWarning().then(_ => this.refresh()));
    }
    // private async embedInRefreshForm(opt: { execute: (event: any|null, thresholds: ARouteThresholds) => Promise<void> }) {
    //   const isFirstRun = !(this.form || this.window)
    //   if (isFirstRun) {
    //     let formData: ARouteThresholds = {
    //       minGpsDistance: PageScript.minGpsDistance ?? 0.5,
    //       maxGpsDistance: PageScript.maxGpsDistance ?? 50,
    //       maxTimeDifference: PageScript.maxTimeDifference ?? 6.0
    //     }
    //     const form = new AFormInstance<ARouteThresholds>({
    //       ignoreWildcards: true,
    //       formInputs: [
    //         {id: 'minGpsDistance', type: 'number', step: "0.1" },
    //         {id: 'maxGpsDistance', type: 'number', step: "0.1" },
    //         {id: 'maxTimeDifference', type: 'number', step: "0.1" },
    //       ],
    //     })
    //     const $form = await form.generate({classList: 'column', translate: false, wrapInColumns: true})
    //     await form.injectFormData({ formData, triggerChange: false })
    //     await form.initFormValidation()
    //     let w = await purgatoryService.showInfoWindowSingleCustom({
    //       nest: $('#AjaxContent'),
    //       parent: $('body'),
    //       table: '',
    //       options: {height: '500px', buttons: [{id: 'btn1', text: 'Apply'}]},
    //       hardwire: true,
    //     })
    //     w.$window.find('.table-content').html('')
    //     w.$window.find('.table-content').append($form)
    //     w.$window.find('#btn1').on('click', (e) => {
    //       opt.execute(e, form.extractFormData({cleanData: true, setInternalFormData: true})).catch(AError.handle)
    //     })
    //     this.window = w
    //     this.form = form
    //   }
    //   await opt.execute(null, this.form.extractFormData({cleanData: true, setInternalFormData: true})).catch(AError.handle)
    // }
    /**
     * Creates adjustment inputs for specific category
     */
    // private async genCustomForm(){
    //   const categoryInputInits = {
    //     'speed': async () => {
    //       const updateChanges = () => {
    //         try {
    //           const transformData = (target: any) => {
    //             const {min, left, right, max} = target?.minMaxEntryCount ?? { left: this.precalc?.left, right: this.precalc?.right }
    //             delete target.minMaxEntryCount
    //             return Object.assign(target, {min, left, right, max})
    //           }
    //           const newData = transformData(form.extractFormData({ cleanData: true, setInternalFormData: true }))
    //           if (this.precalc) {
    //             for (let key in newData) {
    //               if (newData[key] !== undefined) {
    //                 this.precalc[key] = newData[key]
    //               }
    //             }
    //           }
    //           this.legend.firstPoint.x = this.precalc?.left ?? this.precalc?.min ?? 0
    //           this.legend.lastPoint.x = this.precalc?.right ?? this.precalc?.max ?? 99
    //           this.legend.redrawLegend()
    //           this.redrawRoute()
    //         } catch (err) {
    //           AError.handle(err)
    //         }
    //       }
    //       type AFormCustom = {minMaxEntryCount: {min: number, left: number, right: number, max: number}, hideOutsideBounds: boolean}
    //       const {hideOutsideBounds, min, left, right, max, unit} = this.precalc || this.precalcFallback
    //       const form = new AFormInstance<AFormCustom>({
    //         ignoreWildcards: true,
    //         formInputs: [
    //           // {id: 'category', type: 'select', options: this.categories.map(({label, value}) => ({ id: value, text: label })), onChange: (input) => updateChanges(input)},
    //           {id: 'minMaxEntryCount', label: '', type: 'rangeslider', min, max, unit, onChange: (input) => updateChanges()},
    //           // {id: 'hideOutsideBounds', label: 'Hide Outside Minimum & Maximum', type: 'checkbox', onChange: (input) => updateChanges()},
    //         ],
    //       })
    //       await form.generate({ translate: true, wrapInColumns: true })
    //       await form.injectFormData({
    //         triggerChange: false,
    //         formData: {
    //           hideOutsideBounds: hideOutsideBounds,
    //           minMaxEntryCount: {min, left, right, max, unit}
    //         },
    //       })
    //       await form.initFormValidation()
    //       return form
    //     }
    //   }
    //   const form = (categoryInputInits.hasOwnProperty(this.category)) ? await categoryInputInits[this.category]() : undefined
    //   return form
    // }
    /**
     * Calculate additional data using statistics for example (min & max) for statistics
     */
    // private async preCalcData() {
    //   let output: AFormulaPrecalcInternal|null = this.precalcFallback
    //   switch (this.category) {
    //     case 'speed':
    //       const entryCounts = this.RouteList.map(p => p.data![this.category])
    //       let minEntries: number = Math.min.apply(null, entryCounts)
    //       let maxEntries: number = Math.max.apply(null, entryCounts)
    //       const min = (minEntries !== Infinity ? minEntries : undefined) ?? this.precalcFallback.min
    //       const max = (maxEntries !== -Infinity ? maxEntries : undefined) ?? this.precalcFallback.max
    //       output = Object.assign({}, output, {min, left: min, right: max, max})
    //       break
    //     default:
    //       output = null
    //       break;
    //   }
    //   this.precalc = output
    //   return this.precalc
    // }
    redrawRoute() {
        this.RouteList.map((poly) => {
            if (!poly.data) {
                debugger;
                return;
            }
            var dataValue = poly.data[this.category];
            const color = this.legend.calcColor({ perc: dataValue }).rgba();
            poly.setOptions({ strokeColor: color });
        });
    }
    async onLegendClick() {
        Alerts.show({
            translatedTitle: await Loading.waitForPromises(Translate.get('Edit Legend')),
            buttons: ALERT_BUTTONS.saveCancel,
            content: ( /*HTML*/`
        ${this.legend.transition.map((t) => {
                return ( /*HTML*/`
              <div class="form-group">
                <input type="number" value="${t.x}" class="form-input">
              </div>
            `);
            }).join('')}
      `)
        }).on(ALERT_STATUS.ON_ACTION_PROCEED, (context) => {
            const $eles = context.$modal?.find('input[type="number"]').toArray().map(ele => $(ele));
            const numbers = $eles?.map($ele => Number($ele.val()));
            const copy = this.legend.transition.map((item, i) => Object.assign({}, item, { x: numbers[i] }));
            this.legend.update({
                type: 'Linear',
                transition: copy
            });
            this.legend.redrawLegend();
            this.redrawRoute();
        });
    }
    refresh() {
        const filters = FilterManager.saveExplicit();
        FilterManager.setActive(false);
        return Loading.waitForPromises(RequestMapRouteFull(mergeDeep({
            ShowRoute: true,
            Limit: filters.RouteLimit,
        }, filters))).then(async (routeRes) => {
            mapHelperService.destroy(this.RouteList);
            AEngine.log(`Destroyed ps.RouteList`);
            if (routeRes.Rows.length === 0) {
                return Alerts.noResults();
            }
            this.bounds = new google.maps.LatLngBounds();
            this.legend = await genLegend({
                map: this.map,
                smallTitle: true,
                profileKey: this.category,
                // precalc: () => this.precalc
            });
            // await this.preCalcData()
            // const customForm = await this.genCustomForm()
            // this.legend.setContent([customForm?.$form()])
            this.legend.onClick(() => this.onLegendClick());
            this.RouteList = [];
            this.RouteList = await routeDrawingService.showMapRoute(this.map, routeRes, {
                legend: this.legend,
                category: this.category,
                bounds: this.bounds,
                // invisible: true,
            });
            // routeDrawingService.animateRoute().catch(AError.handleSilent)
            AEngine.log(`Populated ps.RouteList`);
            const exportMap = new AExportMap('gpsstats', { scales: true });
            exportMap.addLines(this.RouteList);
            exportMap.allowExport();
            this.map.fit(this.bounds);
        }).finally(() => {
            FilterManager.setActive(true);
        }).catch(AError.handle);
    }
}
export function render() {
    return ( /*html*/`
    <div id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column c-scroll col-12">
        <div class="form-group">
          <label class="form-label" for="FromDate">From</label>
          <input class="form-input" type="date" id="FromDate" required="required">
          <input class="form-input" type="time" id="FromTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="ToDate">To</label>
          <input class="form-input" type="date" id="ToDate" required="required">
          <input class="form-input" type="time" id="ToTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="DeviceMulti">Device</label>
          <div id="DeviceMulti" class="wrapper-dropdown tree-config dd-disallow-none noselect" maxlength="18">
            <span>All</span>
          </div>
        </div>

        <div class="form-group">
          <label class="form-label" for="Category">Category</label>
          <select class="form-select" id="Category">
            <option value="speed">Speed</option>
            <option value="precision">Precision</option>
          </select>
        </div>

        <div class="form-group">
          <label class="form-label" for="Limit">Max results</label>
          <input class="form-input" type="number" id="RouteLimit" value="5000">
        </div>
      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </div>
    <div class="flex-child">
      <div id="map" class="aci-map"></div>
    </div>
  `);
}
