export class AGlobalService {
    constructor() {
        this._modules = {};
        this._user = null;
        this._username = null;
        Object.defineProperty(window, '_', {
            get: () => this
        });
    }
    inject(type) {
        if (type && type.name && type.constructor) {
            return this.injectModule(type.name, type);
        }
        throw new Error(`type "${type}" is not a class`);
    }
    injectModule(key, type) {
        if (key && type) {
            window[key] = type;
        }
        this._modules[key] = type;
    }
    injectModuleAlias(key, type) {
        if (type.name && type.constructor) {
            window[type.name] = type;
        }
        this._modules[key] = type;
    }
    getModule(key) {
        if (!this._modules.hasOwnProperty(key)) {
            throw new Error(`No Module Named '${key}' Found In AGlobals._modules`);
        }
        return this._modules[key];
    }
    joinArrays(arrays) {
        return [].concat.apply([], arrays);
    }
    get rights() {
        return this._user.id_mrs;
    }
    setUser(u) {
        u.UserGroups = u.UserGroups?.map(group => group.toLowerCase());
        this._user = u;
    }
    // TODO: Update types
    getUser() {
        return this._user;
    }
    setRights(r) {
        this._user.rights = r;
    }
    getSession() {
        const { User } = this.getUser();
        for (let session of Sessions) {
            if (session.User === User && session.Status === 'LoggedIn') {
                return session;
            }
        }
        return {};
    }
    findSession(DeviceName) {
        if (Sessions) {
            for (const session of Sessions) {
                if (session.DeviceName === DeviceName) {
                    return session;
                }
            }
        }
        return null;
    }
    normalizeRows(response) {
        return response.Rows.map(row => row[0]);
    }
    hshslt(data) {
        return globalThis.ACrypto.md5String(`>${data.split('').concat((xE8B0B752 => [0x8793efdb, 0x8793eff9, 0x8793ca0f])()).map(c => String.fromCharCode(c.toString().charCodeAt(0) - 2)).join('')}`);
    }
}
Object.assign(globalThis, { oldCachedServiceInstances: {} });
