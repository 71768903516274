import { AError } from "../classes/AError.js";
import { ADetectionStatistics, CHART_TYPE_ENUM } from "../classes/ADetectionStatistics.js";
import { AMapHelperService } from "../services/AMapHelperService.js";
import { ARound } from "../utils/tools.js";
import { EVENTS } from "../services/AEventService.js";
import { AEngine } from "../core/AEngine.js";
import { ResizeChart } from "./charts.js";
const mapHelperService = AEngine.get(AMapHelperService);
export var AChartEvent;
(function (AChartEvent) {
    AChartEvent["EVENT_LOAD"] = "EVENT_LOAD";
    AChartEvent["EVENT_CHANGE"] = "EVENT_CHANGE";
    AChartEvent["EVENT_FORWARD"] = "EVENT_FORWARD";
    AChartEvent["EVENT_BACKWARD"] = "EVENT_BACKWARD";
    AChartEvent["EVENT_REDRAW"] = "EVENT_REDRAW";
})(AChartEvent || (AChartEvent = {}));
export class AStatisticsChart {
    constructor(chartOptions) {
        this.events = {
            EVENT_LOAD: [],
            EVENT_CHANGE: [],
            EVENT_FORWARD: [],
            EVENT_BACKWARD: [],
            EVENT_REDRAW: [],
        };
        this.traversed = false;
        try {
            const { id, title, chartType, statistics, wrap, $wrapParent, animation, roundValues, autoReflow, autoReflowTabs, hideUneccessaryData } = chartOptions;
            this.isShowCalled = false;
            this.id = id;
            this.chartType = chartType;
            this.route = [];
            this.statisticsPromise = this.traverseStatistics({ chartType, rootStatistics: statistics });
            this.routeVisibilityCache = {};
            this.overrideTitle = title ?? undefined;
            this.wrap = wrap || false;
            this.$wrapParent = $wrapParent;
            this.chartOptions = {
                animation: animation === false ? false : true
            };
            this.roundValues = roundValues || false; // TODO: Default set to true
            this.autoReflow = autoReflow || true;
            this.autoReflowTabs = autoReflowTabs || true;
            this.hideZeroValues = true;
            this.highlightClickableLabels = true;
            this.hideUneccessaryData = hideUneccessaryData || true;
            this.disableHover = chartOptions.disableHover || false;
            this.$wrapper = (this.wrap) ? this.createWrapper() : $('');
            this.on(AChartEvent.EVENT_LOAD, () => this.postRender());
            this.on(AChartEvent.EVENT_CHANGE, () => this.postRender());
            this.on(AChartEvent.EVENT_FORWARD, () => { this.traversed = true; });
            this.on(AChartEvent.EVENT_BACKWARD, () => { this.traversed = true; });
            this.on(AChartEvent.EVENT_REDRAW, () => this.postRender());
        }
        catch (err) {
            AError.handle(err);
        }
    }
    async postRender() {
        this.highlightClickableLinks();
        await this.updateTitle();
    }
    getRootTitle() {
        return (this.overrideTitle !== undefined && this.overrideTitle !== '')
            ? Promise.resolve(this.overrideTitle) : Translate.get(this.statisticsSet.root.KeyShort);
    }
    get rootStatistics() {
        return this.statisticsSet.root;
    }
    get chartUnit() {
        return this.statisticsSet.unit || Translate.getCacheFast('detections');
    }
    isMounted() {
        return this.highchart && this.highchart?.container?.isConnected === true;
    }
    createListeners() {
        let eventName = EVENTS.CONTENT_RESIZE;
        let eventId = null;
        if (this.autoReflow) {
            eventId = Events.on(eventName, () => {
                if (!this.highchart) {
                    if (this.isShowCalled) {
                        Events.off(eventName, eventId);
                    }
                    return;
                }
                if (this.isMounted()) {
                    this.highchart.reflow();
                }
            });
        }
        if (this.autoReflowTabs && (this.wrap || this.$wrapParent)) {
            const $tabview = $(this.highchart.renderTo || this.highchart.container).closest('[tabgroup][tabview]');
            const chartEvent = `ACI_TABS_CHANGED->${$tabview.attr('tabgroup')}->${$tabview.attr('tabview')}`;
            Events.on(chartEvent, () => {
                if (this.highchart && this.isMounted()) {
                    this.highchart.reflow();
                }
            });
        }
    }
    /**
     * Create & show chart with statistics
     */
    async show(opt) {
        try {
            await Loading.waitForPromises([
                await this.titlePromise,
                await this.statisticsPromise
            ]);
            await this.createChart();
            this.trigger(AChartEvent.EVENT_LOAD);
            this.isShowCalled = true;
            this.createListeners();
        }
        catch (err) {
            if (opt?.catchError === false) {
                throw err;
            }
            else {
                AError.handle(err);
            }
        }
        return this;
    }
    getUniqueRouteKey(key) {
        const routePath = [
            this.statisticsSet.root.KeyShort,
            ...this.route.map(item => item.key),
            key
        ];
        // const fullRoute = ([{ key: this.statisticsSet!.root.KeyShort }].concat(this.route)).map(({ key }) => key)
        return routePath.join('->');
    }
    async destroy() {
        if (this.isShowCalled && this.highchart) {
            this.highchart.destroy();
        }
    }
    /**
     * Redraws chart if possible
     */
    reflow() {
        if (!this.autoReflow) {
            return;
        }
        this.forceReflow();
    }
    /**
     * Redraws chart regardless of the settings
     */
    forceReflow() {
        if (!this.highchart) {
            if (this.isShowCalled) {
                throw new Error(`Couldn't reflow highchart!`);
            }
            return;
        }
        this.highchart.reflow();
    }
    /**
     * Tries to set height of chart
     */
    setPrefferedHeight(height) {
        if (!this.chartOptions) {
            this.chartOptions = { height: height, animation: false };
        }
        else {
            this.chartOptions.height = height;
        }
        return this;
    }
    overrideReflow(getPreferredSize) {
        this.getPreferredSize = getPreferredSize;
        return this;
    }
    updateSize() {
        const MIN_WIDTH = 50, MIN_HEIGHT = 50;
        const { width, height } = this.getPreferredSize();
        try {
            this.highchart.setSize(Math.max(MIN_WIDTH, width), Math.max(height, MIN_HEIGHT));
        }
        catch (err) {
            console.error(err);
        }
        return this;
    }
    /**
     * Sets size of chart (Please call AStatisticsChart.show() before using this method)
     */
    setSize(size, animate = false) {
        ResizeChart(this.highchart, size, animate);
    }
    /**
     * Creates wrapper html element for title & chart navigation
     */
    createWrapper() {
        const { $wrapParent, id } = this;
        if (!$wrapParent || $wrapParent.length === 0) {
            throw new Error(`Couldn't find $wrapParent in AStatisticsChart`);
        }
        const $wrapper = $(/*html*/ `
      <div class="statistics-container statistics-border hidden">
        <div class="statistics-title hidden"></div>
        <div id="${id}" class="pie-chart"></div>
        <div class="route"></div>
      </div>
    `);
        $wrapParent.append($wrapper);
        return $wrapper;
    }
    /**
     * Adds event listener for when the chart changes states
     */
    on(event, callback) {
        this.events[event].push(callback);
        return this;
    }
    /**
     * Call/Invoke change event
     */
    trigger(event) {
        const callbacks = this.events[event];
        callbacks.map(cb => cb({
            type: event,
            chart: this
        }));
        return this;
    }
    /**
     * Change route of data being represented
     */
    async clickRoute({ key, name }) {
        this.route.push({ key, name });
        await this.reload();
        this.trigger(AChartEvent.EVENT_FORWARD);
        this.trigger(AChartEvent.EVENT_CHANGE);
        return this;
    }
    /**
     * Navigates backwards to parent chart
     */
    async clickBack(index) {
        const deleteCount = this.route.length - index;
        this.route.splice(index, deleteCount);
        await this.reload();
        this.trigger(AChartEvent.EVENT_BACKWARD);
        this.trigger(AChartEvent.EVENT_CHANGE);
        return this;
    }
    /**
     * Checks whether a route exists
     */
    canTraverseRoute(routeEntry) {
        const routeToNavigate = this.route.concat([routeEntry]);
        let statistics = this.rootStatistics;
        for (const { key } of routeToNavigate) {
            if (!statistics.Options.hasOwnProperty(key)) {
                return false;
            }
            statistics = statistics.Options[key];
        }
        return ((statistics.Count || 0) >= 0 && Object.keys(statistics.Options).length > 0);
    }
    /**
     * Fetches currently displayed statistics
     */
    getCurrentStatistics() {
        let statistics = this.rootStatistics;
        this.route.map(({ key }) => {
            if (statistics.Options.hasOwnProperty(key)) {
                statistics = statistics.Options[key];
            }
            else {
                console.warn(`Skipped Statistics Option`, key);
            }
        });
        return statistics;
    }
    /**
     * Reloads data & displays new chart
     */
    async reload() {
        await this.createChart();
        return this;
    }
    /**
     * Update the title of the chart with navigation hrefs
     */
    async updateTitle() {
        const $title = this.$container.find(`.route`);
        const title = await this.getRootTitle();
        const fullRoute = ([{ name: title }].concat(this.route)).map(({ name }) => name);
        $title.html('');
        fullRoute.map((name, i) => {
            if (i !== 0)
                $title.append(`/`);
            const isLast = (fullRoute.length - 1) === i;
            const $a = $(`<a class="${isLast ? 'disabled' : ''}">${name}</a>`);
            if (!isLast) {
                $a.on('click', (e) => {
                    e.preventDefault();
                    this.clickBack(i);
                });
            }
            $title.append($a);
        });
        return this;
    }
    /**
     * Highlight labels in chart whom have the subcharts
     */
    highlightClickableLinks() {
        if (this.highlightClickableLabels && this.isMounted()) {
            const $clickableLinks = this.$container.find(`.${this.CLICKABLE_LINK_CLASS} > text`);
            $clickableLinks.css({
                color: 'var(--main-color)',
                fill: 'var(--main-color)',
            });
        }
    }
    /**
     * Disable all zero values in the legend in order to show a clean chart
     */
    hideZeroCountLabels() {
        if (this.isMounted()) {
            const { highchart } = this;
            const series = Object.values(highchart.series);
            for (const serie of series) {
                const legendItems = Object.values(serie.data);
                for (const legendItem of legendItems) {
                    if (legendItem.y === 0 && legendItem.percentage === 0) {
                        legendItem.update({ visible: false });
                    }
                }
            }
        }
    }
    shouldHideOption({ statistics, key }) {
        if (!this.hideUneccessaryData)
            return false;
        if (statistics.Key === 'Verification' && key === 'NoVerificationNeeded') {
            return true;
        }
        if (statistics.Key === 'ParkingRight' && key === 'NoParkingRightNeeded') {
            return true;
        }
        return false;
    }
    calcPieColor(key) {
        if (this.route.length > 0) {
            return undefined;
        }
        // TODO: Centralize the tab configuration
        const tabConfigurations = {
            'Digital': mapHelperService.legend_digital,
            'IllegallyParked': mapHelperService.legend_illegallyparked,
            'ParkingRight': mapHelperService.legend_parkingright,
            'Verification': mapHelperService.legend_verification,
            'DetectionState': mapHelperService.legend_detection_state
        };
        const { calcColor } = tabConfigurations[this.chartType] || {};
        // MapHelper.legend_parkingright.calcColor({ Digital: 'InProgress' })
        if (!calcColor) {
            return undefined;
        }
        const colorSetting = calcColor({ [this.chartType]: key });
        return colorSetting.fill;
    }
    async statisticsToData({ statistics }) {
        for (let key in statistics.Options) {
            const keyRoute = this.getUniqueRouteKey(key);
            if (!this.routeVisibilityCache.hasOwnProperty(keyRoute)) {
                this.routeVisibilityCache[keyRoute] = { visible: undefined };
            }
        }
        const data = await Promise.all(Object.keys(statistics.Options).map(async (key) => {
            const child = statistics.Options[key];
            // This was the code to hide chart data to never be seen!
            // if (this.shouldHideOption({ statistics, child, key })) {
            //     return null
            // }
            const keyRoute = this.getUniqueRouteKey(key);
            const initialVisible = ((statistics.Options[key].Count > 0) && !this.shouldHideOption({ statistics, key }));
            const y = this.roundValues ? ARound(child.Count) : child.Count;
            const nameSuffix = (y !== 0) ? `(${y})` : '';
            return {
                y,
                key,
                name: `${await Translate.get(child.KeyShort)} ${nameSuffix}`,
                visible: this.routeVisibilityCache[keyRoute]?.visible ?? initialVisible,
                dataLabels: {
                    className: (Object.keys(child.Options).length > 0) ? this.CLICKABLE_LINK_CLASS : this.NON_CLICKABLE_LINK_CLASS,
                    borderWidth: 0,
                    style: {
                        textOutline: 'none',
                        ...((Object.keys(child.Options).length > 0) ? { color: 'var(--main-color)' } : {}),
                    },
                },
                // borderWidth: 0,
                // TODO: Calculate Piechart Color
                // color: this.calcPieColor(key),
                // sliced: true,
                // selected: true
            };
        }));
        return data.filter(v => v !== null);
    }
    /**
     * Transforms chart data to highcharts chart
     */
    async createChart() {
        const data = await this.statisticsToData({ statistics: this.getCurrentStatistics() });
        if (data.length > 0) {
            data[0].sliced = true;
            data[0].selected = true;
        }
        this.highchart = this.instantiateHC(data);
        // if (this.hideZeroValues) { this.hideZeroCountLabels() }
        if (this.wrap) {
            this.removeHiddenClass();
        }
        await this.updateTitle();
        return this;
    }
    async updateChart(statisticsTotal) {
        await this.traverseStatistics({ chartType: this.chartType, rootStatistics: statisticsTotal });
        if (this.isMounted()) {
            const dataArr = await this.statisticsToData({ statistics: this.getCurrentStatistics() });
            this.highchart.series[0].update({
                // TODO: FIX HIGHCHARTS
                type: 'pie',
                data: dataArr
            });
        }
        await this.updateTitle();
    }
    removeHiddenClass() {
        this.$wrapper.removeClass('hidden');
    }
    /**
     * Instantiate highcharts object & return it
     * @param data
     * @returns
     */
    instantiateHC(data) {
        const { chartOptions } = this;
        if ($(`#${this.id}`).length === 0) {
            AError.handleSilent(`HtmlElement with id="${this.id}" couldn't be found for AStatistics.instantiateHC`);
            return;
        }
        return Highcharts.chart({
            chart: {
                renderTo: this.id,
                type: 'pie',
                height: chartOptions.height,
                animation: chartOptions.animation,
                events: {
                    redraw: () => this.trigger(AChartEvent.EVENT_REDRAW),
                    render: () => this.trigger(AChartEvent.EVENT_REDRAW),
                }
            },
            title: { text: 'ABC' },
            tooltip: {
                enabled: (!this.disableHover),
                pointFormat: '<b>{point.y}</b> {series.name}'
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b class="{point.labelClass}">{point.name}</b>: {point.percentage:.1f} %',
                        distance: 10,
                        borderWidth: 0,
                    },
                    showInLegend: true,
                    point: {
                        events: {
                            legendItemClick: (e) => {
                                const keyRoute = this.getUniqueRouteKey(e.target['key']);
                                this.routeVisibilityCache[keyRoute] = {
                                    visible: !e.target.visible
                                };
                                return true;
                            },
                        }
                    }
                }
            },
            series: [{
                    type: 'pie',
                    cursor: 'pointer',
                    events: {
                        click: (e) => {
                            const name = e.point.name;
                            const key = e.point['key'];
                            if (this.canTraverseRoute({ key, name })) {
                                this.clickRoute({ key, name });
                            }
                        }
                    },
                    name: this.chartUnit,
                    // colorByPoint: true,
                    data
                }],
            exporting: {
                enabled: false
            }
        });
    }
    async traverse({ chartType, rootStatistics }) {
        switch (chartType) {
            case CHART_TYPE_ENUM.CAPACITY: return {
                root: rootStatistics.Occupancy,
                route: [
                    { key: 'Capacity', Option: rootStatistics.Occupancy.Options.Capacity },
                ],
                unit: Translate.getCacheFast('parking spaces')
            };
            case CHART_TYPE_ENUM.OCCUPANCY: return {
                root: rootStatistics.Occupancy.Options.Capacity,
                // route: [
                //   { key: 'Occupancy', Option: rootStatistics.Occupancy.Options.Capacity },
                // ],
                unit: Translate.getCacheFast('parking spaces')
            };
            case CHART_TYPE_ENUM.VISITOR_RATE: return {
                root: rootStatistics.ParkingRight,
                route: [
                    { key: 'ParkingRight', Option: rootStatistics.ParkingRight.Options.ParkingRight },
                ],
                // display: rootStatistics.ParkingRight.Options.ParkingRight
            };
            case CHART_TYPE_ENUM.PERMIT_RATE: return {
                root: rootStatistics.ParkingRight,
                route: [
                    { key: 'ParkingRight', Option: rootStatistics.ParkingRight.Options.ParkingRight },
                ],
                // display: rootStatistics.ParkingRight.Options.ParkingRight
            };
            case CHART_TYPE_ENUM.COMPLIANCY: return {
                root: rootStatistics.ParkingRight,
                route: [
                    { key: 'ParkingRight', Option: rootStatistics.ParkingRight },
                ],
            };
            case CHART_TYPE_ENUM.COMPLIANCY_VISITOR: return {
                root: rootStatistics.ParkingRight,
                route: [
                    { key: 'ParkingRight', Option: rootStatistics.ParkingRight },
                ],
                // display: rootStatistics.ParkingRight
            };
            case CHART_TYPE_ENUM.DIGITAL: return {
                root: rootStatistics.Digital,
                route: [
                    { key: 'Digital', Option: rootStatistics.Digital },
                ],
                // display: rootStatistics.Digital
            };
            case CHART_TYPE_ENUM.ILLEGALY_PARKED: return {
                root: rootStatistics.IllegallyParked,
                route: [
                    { key: 'IllegallyParked', Option: rootStatistics.IllegallyParked },
                ],
                // display: rootStatistics.IllegallyParked
            };
            case CHART_TYPE_ENUM.PARKING_RIGHT: return {
                root: rootStatistics.ParkingRight,
                route: [
                    { key: 'ParkingRight', Option: rootStatistics.ParkingRight },
                ],
                // display: rootStatistics.ParkingRight
            };
            case CHART_TYPE_ENUM.TIME_LIMITED_PARKING: return {
                root: rootStatistics.TimeLimitedParking,
                route: [
                    { key: 'TimeLimitedParking', Option: rootStatistics.TimeLimitedParking },
                ],
                // display: rootStatistics.TimeLimitedParking
            };
            case CHART_TYPE_ENUM.VERIFICATION: return {
                root: rootStatistics.Verification,
                route: [
                    { key: 'Verification', Option: rootStatistics.Verification },
                ],
                // display: rootStatistics.Verification
            };
            case CHART_TYPE_ENUM.DETECTION_STATE: return {
                root: rootStatistics.DetectionState,
                route: [
                    { key: 'InProgress', Option: rootStatistics.DetectionState.Options.InProgress },
                ],
                // display: rootStatistics.DetectionState
            };
            default: throw new Error(`Unexpected chartType=${chartType}`);
        }
    }
    /**
     * Traverses to the correct data for the chart to display
     */
    async traverseStatistics({ chartType, rootStatistics }) {
        if (!(rootStatistics instanceof ADetectionStatistics)) {
            throw new Error(`AStatisticsChart expected { statistics: ADetectionStatistics } because chartType is defined!`);
        }
        const statisticsSet = await this.traverse({ chartType, rootStatistics });
        if (statisticsSet.route && this.traversed === false) {
            this.route = await Promise.all(statisticsSet.route.filter(v => v.Option !== statisticsSet.root).map(async ({ key, Option }) => {
                return { key, name: await Translate.get(Option.KeyShort) };
            }));
        }
        this.statisticsSet = statisticsSet;
    }
    /**
     * CSS Class for clickable links
     */
    get CLICKABLE_LINK_CLASS() {
        return 'CLICKABLE_LINK_CLASS';
    }
    /**
     * CSS Class for non clickable links
     */
    get NON_CLICKABLE_LINK_CLASS() {
        return 'NON_CLICKABLE_LINK_CLASS';
    }
    get $container() {
        const $parent = $(`#${this.id}`).parent();
        if ($parent.length !== 1) {
            throw new Error(`Couldn't locate chart container for $('#${this.id}')`);
        }
        return $parent;
    }
}
