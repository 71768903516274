import { sleep } from "../core/AEngine.js";
import { EVENTS } from "../services/AEventService.js";
import { _getEle$ } from "./maps.js";
export function shine(opt) {
    const margin = opt.margin ?? 0;
    const $t = _getEle$(opt.target);
    const $ov = $(/*html*/ `
    <div class="shine-overlay">
      <div class="fw fh" style="position: relative;">
        <div class="aci-generic-shine" style="--animation-width: 120vw"></div>
      </div>
    </div>
  `);
    const $sh = $ov.find('.aci-generic-shine');
    $ov.on('aci-redraw', (e) => {
        let w = ($t.outerWidth() ?? 150) + margin * 2;
        let h = ($t.outerHeight() ?? 150) + margin * 2;
        let { left, top } = $t.offset();
        let max = Math.max(w, h);
        $ov.css('left', left);
        $ov.css('top', top);
        $ov.css('width', w);
        $ov.css('height', h);
        $sh.css('width', max * 2);
        $sh.css('height', max * 2);
        $sh.css('left', -max / 2); // left - max*2)
        $sh.css('top', -max / 2);
        const gradient = [
            `rgba(255,255,255, .13) 0`,
            `rgba(255,255,255, .13) calc(90% - 36px)`,
            `rgba(255,255,255, .70) 90%`,
            `rgba(255,255,255, .00) calc(90% + 36px)`,
            `rgba(255,255,255, .00) 100%`
        ].join(', ');
        $sh.css('background', `linear-gradient(to right, ${gradient})`);
        if (w < 400) {
            $sh.css('animation-duration', '0.6s');
        }
        $sh.css('background-size', `${max}px ${max}px`);
    });
    Events.on(EVENTS.CONTENT_RESIZE, () => $ov.trigger('aci-redraw'));
    $ov.trigger('aci-redraw');
    sleep(1500).then(() => $ov.remove());
    $('body').append($ov);
}
Object.assign(globalThis, { shine });
