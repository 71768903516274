import { getCenterAny } from "../../utils/maps.js";
import { ACluster } from "./ACluster.js";
/**
 * A Marker Clusterer that clusters markers.
 *
 * @param map The Google map to attach to.
 * @param {Array.<google.maps.Marker>=} opt_markers Optional markers to add to
 *   the cluster.
 * @param {Object=} opt_options support the following options:
 *     'gridSize': (number) The grid size of a cluster in pixels.
 *     'maxZoom': (number) The maximum zoom level that a marker can be part of a
 *                cluster.
 *     'zoomOnClick': (boolean) Whether the default behaviour of clicking on a
 *                    cluster is to zoom into it.
 *     'averageCenter': (boolean) Whether the center of each cluster should be
 *                      the average of all markers in the cluster.
 *     'minimumClusterSize': (number) The minimum number of markers to be in a
 *                           cluster before the markers are hidden and a count
 *                           is shown.
 *     'styles': (object) An object that has style properties:
 *       'url': (string) The image url.
 *       'height': (number) The image height.
 *       'width': (number) The image width.
 *       'anchor': (Array) The anchor position of the label text.
 *       'textColor': (string) The text color.
 *       'textSize': (number) The text size.
 *       'backgroundPosition': (string) The position of the backgound x, y.
 * @constructor
 * @extends google.maps.OverlayView
 */
export class AMarkerClusterer {
    constructor(map, opt_markers, opt_options) {
        this.MARKER_CLUSTER_IMAGE_PATH_ = '../images/m';
        this.MARKER_CLUSTER_IMAGE_EXTENSION_ = 'png';
        this.sizes = [53, 56, 66, 78, 90];
        this.ready_ = false;
        this.map_ = map;
        this.clusters_ = [];
        this.markers_ = [];
        const opt = opt_options || {};
        this.gridSize_ = opt['gridSize'] || 60;
        this.minClusterSize_ = opt['minimumClusterSize'] || 2;
        this.maxZoom_ = opt['maxZoom'] || null;
        this.styles_ = opt['styles'] || [];
        this.imagePath_ = opt['imagePath'] || this.MARKER_CLUSTER_IMAGE_PATH_;
        this.imageExtension_ = opt['imageExtension'] || this.MARKER_CLUSTER_IMAGE_EXTENSION_;
        this.zoomOnClick_ = true;
        if (opt['zoomOnClick'] != undefined) {
            this.zoomOnClick_ = opt['zoomOnClick'];
        }
        this.averageCenter_ = false;
        if (opt['averageCenter'] != undefined) {
            this.averageCenter_ = opt['averageCenter'];
        }
        this.setupStyles_();
        this.setMap(map);
        this.prevZoom_ = this.map_.getZoom();
        // Add the map event listeners
        google.maps.event.addListener(this.map_, 'zoom_changed', () => {
            // Determines map type and prevent illegal zoom levels
            var zoom = this.map_.getZoom();
            var minZoom = this.map_['minZoom'] || 0;
            var maxZoom = Math.min(this.map_['maxZoom'] || 100, this.map_.mapTypes[this.map_.getMapTypeId()].maxZoom);
            zoom = Math.min(Math.max(zoom, minZoom), maxZoom);
            if (this.prevZoom_ != zoom) {
                this.prevZoom_ = zoom;
                this.resetViewport();
            }
        });
        google.maps.event.addListener(this.map_, 'idle', () => {
            this.redraw();
        });
        // Finally, add the markers
        if (opt_markers && (opt_markers.length || Object.keys(opt_markers).length)) {
            this.addMarkers(opt_markers, false);
        }
    }
    /**
     * Implementaion of the interface method.
     * @ignore
     */
    onAdd() {
        // super.onAdd()
        this.setReady_(true);
    }
    /**
     * Implementaion of the interface method.
     * @ignore
     */
    draw() {
        // super.draw()
    }
    /**
     * Sets up the styles object.
     *
     * @private
     */
    setupStyles_() {
        if (this.styles_.length) {
            return;
        }
        for (var i = 0, size; size = this.sizes[i]; i++) {
            this.styles_.push({
                url: this.imagePath_ + (i + 1) + '.' + this.imageExtension_,
                height: size,
                width: size
            });
        }
    }
    /**
     *  Fit the map to the bounds of the markers in the clusterer.
     */
    fitMapToMarkers() {
        var markers = this.getMarkers();
        var bounds = new google.maps.LatLngBounds();
        for (var i = 0, marker; marker = markers[i]; i++) {
            bounds.extend(getCenterAny(marker));
        }
        this.map_.fitBounds(bounds);
    }
    /**
     *  Sets the styles.
     *
     *  @param styles The style to set.
     */
    setStyles(styles) {
        this.styles_ = styles;
    }
    /**
     *  Gets the styles.
     */
    getStyles() {
        return this.styles_;
    }
    /**
     * Whether zoom on click is set.
     *
     * @return True if zoomOnClick_ is set.
     */
    isZoomOnClick() {
        return this.zoomOnClick_;
    }
    /**
     * Whether average center is set.
     *
     * @return True if averageCenter_ is set.
     */
    isAverageCenter() {
        return this.averageCenter_;
    }
    /**
     *  Returns the array of markers in the clusterer.
     *
     *  @return The markers.
     */
    getMarkers() {
        return this.markers_;
    }
    /**
     *  Returns the number of markers in the clusterer
     *
     *  @return The number of markers.
     */
    getTotalMarkers() {
        return this.markers_.length;
    }
    /**
     *  Sets the max zoom for the clusterer.
     *
     *  @param maxZoom The max zoom level.
     */
    setMaxZoom(maxZoom) {
        this.maxZoom_ = maxZoom;
    }
    /**
     *  Gets the max zoom for the clusterer.
     *
     *  @return The max zoom level.
     */
    getMaxZoom() {
        return this.maxZoom_;
    }
    /**
     *  The function for calculating the cluster icon image.
     *
     *  @param markers The markers in the clusterer.
     *  @param numStyles The number of styles available.
     *  @return A object properties: 'text' (string) and 'index' (number).
     *  @private
     */
    calculator_(markers, numStyles) {
        var index = 0;
        var count = markers.length;
        var dv = count;
        while (dv !== 0) {
            // @ts-ignore
            dv = parseInt(dv / 10, 10);
            index++;
        }
        index = Math.min(index, numStyles);
        return {
            text: count,
            index: index
        };
    }
    /**
     * Set the calculator function.
     *
     * @param {function(Array, number)} calculator The function to set as the
     *     calculator. The function should return a object properties:
     *     'text' (string) and 'index' (number).
     *
     */
    setCalculator(calculator) {
        this.calculator_ = calculator;
    }
    /**
     * Get the calculator function.
     *
     * @return {function(Array, number)} the calculator function.
     */
    getCalculator() {
        return this.calculator_;
    }
    /**
     * Add an array of markers to the clusterer.
     *
     * @param markers The markers to add.
     * @param opt_nodraw Whether to redraw the clusters.
     */
    addMarkers(markers, opt_nodraw) {
        if (markers.length) {
            for (let i = 0; i < markers.length; i++) {
                this.pushMarkerTo_(markers[i]);
            }
        }
        else if (Object.keys(markers).length) {
            for (var marker in markers) {
                this.pushMarkerTo_(markers[marker]);
            }
        }
        if (!opt_nodraw) {
            this.redraw();
        }
    }
    /**
     * Pushes a marker to the clusterer.
     *
     * @param marker The marker to add.
     * @private
     */
    pushMarkerTo_(marker) {
        marker['isAdded'] = false;
        if (marker['draggable']) {
            // If the marker is draggable add a listener so we update the clusters on
            // the drag end.
            google.maps.event.addListener(marker, 'dragend', () => {
                marker['isAdded'] = false;
                this.repaint();
            });
        }
        this.markers_.push(marker);
    }
    /**
     * Adds a marker to the clusterer and redraws if needed.
     *
     * @param marker The marker to add.
     * @param opt_nodraw Whether to redraw the clusters.
     */
    addMarker(marker, opt_nodraw) {
        this.pushMarkerTo_(marker);
        if (!opt_nodraw) {
            this.redraw();
        }
    }
    /**
     * Removes a marker and returns true if removed, false if not
     *
     * @param marker The marker to remove
     * @return Whether the marker was removed or not
     * @private
     */
    removeMarker_(marker) {
        var index = -1;
        if (this.markers_.indexOf) {
            index = this.markers_.indexOf(marker);
        }
        else {
            for (var i = 0, m; m = this.markers_[i]; i++) {
                if (m == marker) {
                    index = i;
                    break;
                }
            }
        }
        if (index == -1) {
            // Marker is not in our list of markers.
            return false;
        }
        marker.setMap(null);
        this.markers_.splice(index, 1);
        return true;
    }
    /**
     * Remove a marker from the cluster.
     *
     * @param marker The marker to remove.
     * @param opt_nodraw Optional boolean to force no redraw.
     * @return True if the marker was removed.
     */
    removeMarker(marker, opt_nodraw) {
        var removed = this.removeMarker_(marker);
        if (!opt_nodraw && removed) {
            this.resetViewport();
            this.redraw();
            return true;
        }
        else {
            return false;
        }
    }
    /**
     * Removes an array of markers from the cluster.
     *
     * @param markers The markers to remove.
     * @param opt_nodraw Optional boolean to force no redraw.
     */
    removeMarkers(markers, opt_nodraw) {
        var removed = false;
        for (var i = 0, marker; marker = markers[i]; i++) {
            var r = this.removeMarker_(marker);
            removed = removed || r;
        }
        if (!opt_nodraw && removed) {
            this.resetViewport();
            this.redraw();
            return true;
        }
    }
    /**
     * Sets the clusterer's ready state.
     *
     * @param ready The state.
     * @private
     */
    setReady_(ready) {
        if (!this.ready_) {
            this.ready_ = ready;
            this.createClusters_();
        }
    }
    /**
     * Returns the number of clusters in the clusterer.
     *
     * @return The number of clusters.
     */
    getTotalClusters() {
        return this.clusters_.length;
    }
    /**
     * Returns the google map that the clusterer is associated with.
     *
     * @return The map.
     */
    getMap() {
        return this.map_;
    }
    /**
     * Sets the google map that the clusterer is associated with.
     *
     * @param map The map.
     */
    setMap(map) {
        this.map_ = map;
    }
    /**
     * Returns the size of the grid.
     *
     * @return The grid size.
     */
    getGridSize() {
        return this.gridSize_;
    }
    /**
     * Sets the size of the grid.
     *
     * @param size The grid size.
     */
    setGridSize(size) {
        this.gridSize_ = size;
    }
    /**
     * Returns the min cluster size.
     *
     * @return The grid size.
     */
    getMinClusterSize() {
        return this.minClusterSize_;
    }
    /**
     * Sets the min cluster size.
     *
     * @param size The grid size.
     */
    setMinClusterSize(size) {
        this.minClusterSize_ = size;
    }
    /**
     * Extends a bounds object by the grid size.
     *
     * @param bounds The bounds to extend.
     * @return The extended bounds.
     */
    getExtendedBounds(bounds) {
        // @ts-ignore
        var projection = this.getProjection();
        // Turn the bounds into latlng.
        var tr = new google.maps.LatLng(bounds.getNorthEast().lat(), bounds.getNorthEast().lng());
        var bl = new google.maps.LatLng(bounds.getSouthWest().lat(), bounds.getSouthWest().lng());
        // Convert the points to pixels and the extend out by the grid size.
        var trPix = projection.fromLatLngToDivPixel(tr);
        trPix.x += this.gridSize_;
        trPix.y -= this.gridSize_;
        var blPix = projection.fromLatLngToDivPixel(bl);
        blPix.x -= this.gridSize_;
        blPix.y += this.gridSize_;
        // Convert the pixel points back to LatLng
        var ne = projection.fromDivPixelToLatLng(trPix);
        var sw = projection.fromDivPixelToLatLng(blPix);
        // Extend the bounds to contain the new bounds.
        bounds.extend(ne);
        bounds.extend(sw);
        return bounds;
    }
    /**
     * Determins if a marker is contained in a bounds.
     *
     * @param marker The marker to check.
     * @param bounds The bounds to check against.
     * @return True if the marker is in the bounds.
     * @private
     */
    isMarkerInBounds_(marker, bounds) {
        return bounds.contains(getCenterAny(marker));
    }
    /**
     * Clears all clusters and markers from the clusterer.
     */
    clearMarkers() {
        this.resetViewport(true);
        // Set the markers a empty array.
        this.markers_ = [];
    }
    /**
     * Clears all existing clusters and recreates them.
     * @param opt_hide To also hide the marker.
     */
    resetViewport(opt_hide) {
        // Remove all the clusters
        for (var i = 0, cluster; cluster = this.clusters_[i]; i++) {
            cluster.remove();
        }
        // Reset the markers to not be added and to be invisible.
        for (var i = 0, marker; marker = this.markers_[i]; i++) {
            marker.isAdded = false;
            if (opt_hide) {
                marker.setMap(null);
            }
        }
        this.clusters_ = [];
    }
    /**
     *
     */
    repaint() {
        var oldClusters = this.clusters_.slice();
        this.clusters_.length = 0;
        this.resetViewport();
        this.redraw();
        // Remove the old clusters.
        // Do it in a timeout so the other clusters have been drawn first.
        window.setTimeout(() => {
            for (var i = 0, cluster; cluster = oldClusters[i]; i++) {
                cluster.remove();
            }
        }, 0);
    }
    /**
     * Redraws the clusters.
     */
    redraw() {
        this.createClusters_();
    }
    /**
     * Calculates the distance between two latlng locations in km.
     * @see http://www.movable-type.co.uk/scripts/latlong.html
     *
     * @param p1 The first lat lng point.
     * @param p2 The second lat lng point.
     * @return The distance between the two points in km.
     * @private
    */
    distanceBetweenPoints_(p1, p2) {
        if (!p1 || !p2) {
            return 0;
        }
        var R = 6371; // Radius of the Earth in km
        var dLat = (p2.lat() - p1.lat()) * Math.PI / 180;
        var dLon = (p2.lng() - p1.lng()) * Math.PI / 180;
        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(p1.lat() * Math.PI / 180) * Math.cos(p2.lat() * Math.PI / 180) *
                Math.sin(dLon / 2) * Math.sin(dLon / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;
        return d;
    }
    /**
     * Add a marker to a cluster, or creates a new cluster.
     *
     * @param marker The marker to add.
     * @private
     */
    addToClosestCluster_(marker) {
        var distance = 40000; // Some large number
        var clusterToAddTo = null;
        var cluster;
        for (var i = 0, cluster; cluster = this.clusters_[i]; i++) {
            var center = cluster.getCenter();
            if (center) {
                var d = this.distanceBetweenPoints_(center, getCenterAny(marker));
                if (d < distance) {
                    distance = d;
                    clusterToAddTo = cluster;
                }
            }
        }
        if (clusterToAddTo && clusterToAddTo.isMarkerInClusterBounds(marker)) {
            clusterToAddTo.addMarker(marker);
        }
        else {
            cluster = new ACluster(this);
            cluster.addMarker(marker);
            this.clusters_.push(cluster);
        }
    }
    /**
     * Creates the clusters.
     *
     * @private
     */
    createClusters_() {
        if (!this.ready_) {
            return;
        }
        // Get our current map view bounds.
        // Create a new bounds object so we don't affect the map.
        var mapBounds = new google.maps.LatLngBounds(this.map_.getBounds().getSouthWest(), this.map_.getBounds().getNorthEast());
        var bounds = this.getExtendedBounds(mapBounds);
        for (var i = 0, marker; marker = this.markers_[i]; i++) {
            if (!marker.isAdded && this.isMarkerInBounds_(marker, bounds)) {
                this.addToClosestCluster_(marker);
            }
        }
    }
}
function extend(obj1, obj2) {
    return (function (object) {
        for (var property in object.prototype) {
            this.prototype[property] = object.prototype[property];
        }
        return this;
    }).apply(obj1, [obj2]);
}
extend(AMarkerClusterer, google.maps.OverlayView);
