import { AError } from "../../classes/AError.js";
import { COLUMN_DATETIME, DATA_DATETIME } from "../../classes/AGridTypes.js";
import { sleep } from "../../core/AEngine.js";
import { ATemplates } from "../../core/ATemplateService.js";
import { DetectionsFullRanged } from "../../utils/query.js";
import { AConvertToGridColumns, AConvertToGridData, AShowTable } from "../../utils/tools.js";
export class APage {
    constructor() {
        $('#RefreshButton').on('click', _ => FilterManager.showFilterWarning().then(_ => this.refresh()));
    }
    init() {
        FilterManager.load();
    }
    get query() {
        return (`
            SELECT
	            DetectionUser,
	            DetectionTime,
                VerificationUser,
                VerificationResult,
                VerificationResultText,
                VerificationStartTime,
                (UNIX_TIMESTAMP(VerificationEndTime) - UNIX_TIMESTAMP(VerificationStartTime)) as VerificationDuration,
	            CONCAT(VehicleCenterLatitude, '_', VehicleCenterLongitude) as VehicleLatLng,
                CONCAT(VerificationLatitude, '_', VerificationLongitude) as PdaLatLng,
                null as DistanceInMeters
            FROM (${DetectionsFullRanged}) full 
            WHERE VerificationResult IS NOT NULL
            ORDER BY DetectionTime DESC
            LIMIT :Limit
        `);
    }
    findColumnsByField(fieldNames) {
        const output = [];
        for (const record of this.grid.columns.records) {
            const index = fieldNames.indexOf(record.field);
            if (index !== -1) {
                output.push(fieldNames.splice(index, 1)[0]);
            }
        }
        return output;
    }
    findColumnByField(fieldName) {
        for (const record of this.grid.columns.records) {
            if (record.field === fieldName) {
                return record;
            }
        }
        return null;
    }
    switch(a = 'DetectionTime', b = 'VerificationResult') {
        const { visibleColumns } = this.grid.columns;
        const [column1, column2] = this.findColumnsByField([a, b]);
        this.grid.columns.remove(column1);
        this.grid.columns.insert(2, column1);
    }
    refresh() {
        FilterManager.setActive(false);
        const { Limit } = FilterManager.save();
        return Loading.waitForPromises(requestService.query({
            Query: this.query,
            Params: {
                FromDate: '2019-01-01',
                ToDate: FilterManager.tomorrow,
                Limit
            },
            Language: Language,
            Translate: ["Device", "Version"]
        }).then(async (response) => {
            FilterManager.setActive(true);
            // Check whether there a result for the current query
            if (response.Rows.length === 0) {
                return Alerts.noResults();
            }
            const indexes = requestService.indexes(response, [
                'VerificationDuration',
                'VehicleLatLng',
                'PdaLatLng',
                'DistanceInMeters'
            ]);
            response.Rows = await Promise.all(response.Rows.map(async (row) => {
                const vehicleLatLng = row[indexes['VehicleLatLng']];
                const pdaLatLng = row[indexes['PdaLatLng']];
                if (vehicleLatLng && pdaLatLng) {
                    const [vehicleLat, vehicleLng] = vehicleLatLng.split('_');
                    const [pdaLat, pdaLng] = pdaLatLng.split('_');
                    const vehiclePos = new google.maps.LatLng(vehicleLat, vehicleLng);
                    const pdaPos = new google.maps.LatLng(pdaLat, pdaLng);
                    row[indexes['DistanceInMeters']] = Math.round(google.maps.geometry.spherical.computeDistanceBetween(vehiclePos, pdaPos));
                }
                else {
                    row[indexes['DistanceInMeters']] = null;
                }
                return row;
            }));
            this.grid = AShowTable({
                appendTo: 'table-bryntum',
                columns: AConvertToGridColumns(response, {
                    'VehicleLatLng': { hidden: true },
                    'PdaLatLng': { hidden: true },
                    'DetectionTime': COLUMN_DATETIME,
                    'VerificationStartTime': COLUMN_DATETIME
                }),
                data: AConvertToGridData(response, {
                    'DetectionTime': DATA_DATETIME,
                    'VerificationStartTime': DATA_DATETIME
                })
            });
            // Loading is too fast to see the loading bar
            return sleep(100);
        })).catch(AError.handle);
    }
}
export function render() {
    return ( /*html*/`
    <div id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column col-12">
        <div class="form-group">
          <label class="form-label" for="FromDate">From</label>
          <input class="form-input" type="date" id="FromDate" required="required">
          <input class="form-input" type="time" id="FromTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="ToDate">To</label>
          <input class="form-input" type="date" id="ToDate" required="required">
          <input class="form-input" type="time" id="ToTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="VerificationUser">V-User</label>
          <div id="VerificationUser" class="copycat noselect" maxlength="18">
            <span>None</span>
            <ul class="dropdown"></ul>
          </div>
        </div>

        <div class="form-group">
          <label class="form-label" for="FineNumber">Fine Number</label>
          <input class="form-input" type="number" value="" maxlength="47" />
        </div>

        <div class="form-group">
          <label class="form-label" for="Limit">Max results</label>
          <input class="form-input" type="number" id="Limit" value="2000">
        </div>
      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </div>

    <div id="Rapport" class="flex-child bryntum-container has-footer-2">
      <div template="${ATemplates.WaitingForInput}"></div>
      <div id="table-bryntum" class="hidden"></div>
      <div class="footer aci columns">
        <div class="column col-2">
          <div id="count" class="text">
            Viewing
            <span>0</span>
            Verifications
          </div>
        </div>
        <div class="column col-2 col-ml-auto">
          <button id="export" class="btn btn-primary col-12" disabled="disabled">Export</button>
        </div>
      </div>
    </div>
  `);
}
