import { ACrypto } from "../../classes/ACrypto.js";
import { CHART_TYPE } from "../../classes/ADetectionStatistics.js";
import { AError } from "../../classes/AError.js";
import { AEngine } from "../../core/AEngine.js";
import { ATemplates } from "../../core/ATemplateService.js";
import { AStatisticsService } from "../../services/AStatisticsService.js";
import { ARound, AShowTable, createArray } from "../../utils/tools.js";
export class APage {
    constructor() {
        FilterManager.load();
        this.ignoreDetectionsOutsideSegment = true;
        $('#RefreshButton').on('click', _ => this.refresh().catch(AError.handle));
    }
    async init() { }
    calculateColumnWidth(columnArray, add = 2) {
        return ARound(100.0 / (columnArray.length + add), 2) + '%';
    }
    async createChart(name, columnName) {
        const streetData = this.statistics[name];
        const formulaHtml = await streetData.createFormulaHtml({
            category: columnName,
            // precalc
        });
        const option = streetData.getStatsChartOption(columnName);
        PageScript.chart = await streetData.createChart({
            id: ACrypto.md5String(name),
            title: name,
            category: columnName
                .replace('Detections', CHART_TYPE.VERIFICATION)
                .replace('Suspects', CHART_TYPE.VERIFICATION)
                .replace('FollowUps', CHART_TYPE.VERIFICATION)
                .replace('Sanctions', CHART_TYPE.VERIFICATION),
            formulaHtml
        }, option);
    }
    get tableColumnArray() {
        return [
            { field: 'Detections', type: 'number' },
            { field: 'Capacity', type: 'number' },
            { field: 'Occupancy', type: 'apercent' },
            { field: 'VisitorRate', type: 'apercent' },
            { field: 'PermitRate', type: 'apercent' },
            { field: 'Compliancy', type: 'apercent' },
            { field: 'CompliancyVisitor', type: 'apercent' },
            { field: 'EnforcementIntensity', type: 'number' },
        ];
    }
    /**
     * Transforms data for table
     */
    dynamicDataToRowData(name, streetEntry) {
        const Name = name.replace(/,/g, '|'), Detections = streetEntry.getTotal(), Occupancy = streetEntry.getOccupancy(), VisitorRate = streetEntry.getVisitorRate(), PermitRate = streetEntry.getPermitRate(), Compliancy = streetEntry.getCompliancy(), CompliancyVisitor = streetEntry.getCompliancyVisitors(), EnforcementIntensity = streetEntry.getEnforcementIntensity(), Capacity = streetEntry.getCapacity();
        return {
            Name,
            Detections,
            Occupancy: Occupancy !== null ? ARound(Occupancy * 100, 2) : null,
            VisitorRate: VisitorRate !== null ? ARound(VisitorRate * 100, 2) : null,
            PermitRate: PermitRate !== null ? ARound(PermitRate * 100, 2) : null,
            Compliancy: Compliancy !== null ? ARound(Compliancy * 100, 2) : null,
            CompliancyVisitor: CompliancyVisitor !== null ? ARound(CompliancyVisitor * 100, 2) : null,
            EnforcementIntensity: EnforcementIntensity !== null ? ARound(EnforcementIntensity, 2) : null,
            Capacity: Capacity !== null ? ARound(Capacity, 2) : null
        };
    }
    async refresh() {
        const filters = FilterManager.saveExplicit();
        FilterManager.setActive(false);
        let geoHashMap = { map: {}, imap: {} };
        let mapTo = (segmentId) => segmentId;
        let xKeys = [];
        let yKeys = [];
        if (filters.MapToGeo !== undefined) {
            geoHashMap = await Loading.waitForPromises(AEngine.get(AStatisticsService).fetchGeoConnectionNames({
                FromGeoType: 'Segment',
                ToGeoType: filters.MapToGeo,
            }));
            // mapTo = (segmentId) => geoHashMap.map[segmentId] || null
            mapTo = (segmentId, DetectionDeviceId) => {
                if (!geoHashMap.map.hasOwnProperty(segmentId)) {
                    return null;
                }
                if (!DeviceIdToName.hasOwnProperty(DetectionDeviceId)) {
                    return null;
                }
                const x = geoHashMap.map[segmentId];
                const y = DeviceIdToName[DetectionDeviceId];
                if (!xKeys.includes(x)) {
                    xKeys.push(x);
                }
                if (!yKeys.includes(y)) {
                    yKeys.push(y);
                }
                return `${x} ${y}`;
            };
            // mapTo = (segmentId: string, DetectionDeviceId?: string) => DeviceIdToName[DetectionDeviceId!]
        }
        const { statistics, processedDetections, skippedDetections, rowCounts } = await Loading.waitForPromises(AEngine.get(AStatisticsService).fetch(filters, {
            mapTo,
            ignoreDetectionsOutsideSegment: PageScript.ignoreDetectionsOutsideSegment,
            groups: [filters.MapToGeo]
        }));
        let matrix = createArray(xKeys.length + 1, () => {
            return createArray(yKeys.length + 1, () => 0);
        });
        Object.keys(statistics).map((key) => {
            const [xKey, yKey] = key.split(' ');
            const xIndex = xKeys.indexOf(xKey);
            const yIndex = yKeys.indexOf(yKey);
            const data = this.dynamicDataToRowData(key, statistics[key]);
            // values[xIndex+1][yIndex+1] = statistics[key].getTotal()
            matrix[xIndex + 1][yIndex + 1] = data[filters.VerticalAxis];
        });
        matrix[0][0] = 'Bars';
        xKeys.map((xKey, i) => { matrix[i + 1][0] = xKeys[i]; });
        yKeys.map((yKey, i) => { matrix[0][i + 1] = yKeys[i]; });
        console.log({ values: matrix });
        const matrixToResponse = async (matrix) => {
            const translations = await Translate.get(matrix[0]);
            const output = {
                Columns: matrix[0],
                ColumnsTranslated: matrix[0].map(k => translations[k] ?? k),
                Rows: matrix.slice(1)
            };
            return output;
        };
        console.log(await matrixToResponse(matrix));
        // for (let i = 0; i < values.length; i++) {
        //   values[0] = xKeys[]
        // }
        FilterManager.setActive(true);
        if (Object.keys(statistics).length === 0) {
            return Alerts.noResults();
        }
        const width = this.calculateColumnWidth(this.tableColumnArray);
        const data = Object.keys(statistics).map(mappedKey => {
            // const geoId = imap[mappedKey]
            const streetEntry = statistics[mappedKey];
            return this.dynamicDataToRowData(mappedKey, streetEntry);
        });
        this.grid = AShowTable({
            // aci: {
            //   overrideFooterText: ({ length, showLimit, limit }) => {
            //     const allDetections = processedDetections + skippedDetections
            //     return `Viewing ${length} Streets (${processedDetections} of the ${allDetections} detections mapped to a street)`
            //   }
            // },
            appendTo: 'table-bryntum',
            columns: [
                {
                    field: 'details',
                    type: 'widget',
                    cellCls: 'np-i',
                    widgets: [{
                            type: 'button',
                            icon: 'fa fa-info',
                            cls: 'b-blue b-raised',
                            onAction: async ({ source: btn }) => {
                                Loading.waitForPromises(this.createChart(btn.cellInfo.record.Name, 'Compliancy'));
                            }
                        }]
                },
                {
                    field: 'Name',
                    text: await Loading.waitForPromises(Translate.get(filters.MapToGeo || 'SegmentId')),
                    width
                },
                ...(await Loading.waitForPromises(this.tableColumnArray.map(async (columnData) => {
                    return Object.assign({
                        text: await Translate.get(columnData.field),
                        width,
                        lowThreshold: -1,
                        showValue: true
                    }, columnData);
                })))
            ],
            data: data
        });
        this.grid.on('cellclick', (a) => {
            const { record, column } = a;
            const { Name } = record;
            const { field } = column;
            if (Object.values(CHART_TYPE).indexOf(field) === -1 && field !== 'EnforcementIntensity') {
                return console.warn(`CHART_TYPE "${field}" doesn't have a chart variant!`);
            }
            Loading.waitForPromises(this.createChart(Name, field)).catch(AError.handle);
        });
        this.statistics = statistics;
    }
}
export function render() {
    return ( /*html*/`
    <div id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column col-12">
        <div class="form-group">
          <label class="form-label" for="FromDate">From</label>
          <input class="form-input" type="date" id="FromDate" required="required">
          <input class="form-input" type="time" id="FromTime" required="required">
        </div>
        <div class="form-group">
          <label class="form-label" for="ToDate">To</label>
          <input class="form-input" type="date" id="ToDate" required="required">
          <input class="form-input" type="time" id="ToTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="VerticalAxis">Y Axis</label>
          <select id="VerticalAxis" class="form-select">
          <option value="Capacity">Capacity</option>
          <option value="Occupancy">Occupancy</option>
          <option value="VisitorRate">VisitorRate</option>
          <option value="PermitRate">PermitRate</option>
          <option value="Compliancy">Compliancy</option>
          <option value="CompliancyVisitor">CompliancyVisitor</option>
          <option value="EnforcementIntensity">EnforcementIntensity</option>
          </select>
        </div>

        <div class="form-group">
          <label class="form-label" for="MapToGeo">Map To</label>
          <select id="MapToGeo" class="form-select">
            <option value="Street">Street</option>
            <option value="Area">Area</option>
            <option value="Zone">Zone</option>
            <option value="%">All</option>
  
            ${ /*GeoSegmentConnections.map(c => {
      return (`<option value="${c.ToGeoType}">${c.FromGeoType} => ${c.ToGeoType}</option>`)
    }).join('')*/''}
          </select>
        </div>

        <div class="form-group">
          <label class="form-label" for="AreaMulti">Area</label>
          <div id="AreaMulti" class="copycat noselect dd-disallow-none" maxlength="18">
            <span>None</span>
            <ul class="dropdown"></ul>
          </div>
        </div>

        <div class="form-group">
          <label class="form-label" for="ZoneMulti">Zone</label>
          <div id="ZoneMulti" class="copycat noselect dd-disallow-none" maxlength="18">
            <span>None</span>
            <ul class="dropdown"></ul>
          </div>
        </div>
        
      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </div>
    <div id="Rapport" class="flex-child bryntum-container has-footer-2">
      <div template="${ATemplates.WaitingForInput}"></div>
      <div id="table-bryntum" class="hidden"></div>

      <div class="columns footer aci">
        <div class="column col-2">
          <div id="count" class="text">Viewing <span>0</span> Streets</div>
        </div>
        <div class="column col-2 col-ml-auto">
          <button id="export" class="btn btn-primary col-12" disabled="disabled">Export</button>
        </div>
      </div>
    </div>
  `);
}
