import { sleep } from "../core/AEngine.js";
import { EVENTS } from "../services/AEventService.js";
import { _getEle$ } from "./maps.js";
import { toast } from "./toasts.js";
function updatePosition($ov, $t, margin) {
    let w = ($t.outerWidth() ?? 150) + margin * 2;
    let h = ($t.outerHeight() ?? 150) + margin * 2;
    let { left, top } = $t.offset();
    $ov.css('mask-size', `100vw 100vh,${w}px ${h}px`);
    $ov.css('mask-position', `0% 0%, calc(0% + ${left - margin}px) calc(0% + ${top - margin}px)`);
}
export function tip(opt) {
    let beforeFn;
    let afterFn;
    const self = { _data: opt };
    Object.assign(self, {
        _data: opt,
        exec: async () => {
            const context = {};
            if (beforeFn)
                await Promise.resolve().then(() => beforeFn(context));
            const t = opt.toastMsg ? toast({ msg: opt.toastMsg, timeout: Infinity, allowClose: false }) : null;
            await tipInternal(opt);
            t?.close();
            if (afterFn)
                await Promise.resolve().then(() => afterFn(context));
        },
        before: (cb) => { beforeFn = cb; return self; },
        after: (cb) => { afterFn = cb; return self; },
    });
    return self;
}
export async function tipInternal(opt) {
    let { closeCondition, target, margin } = Object.assign({ margin: 4 }, opt);
    let $ov = $('.tips-overlay'), $t = _getEle$(target);
    updatePosition($ov, $t, margin);
    $ov.toggleClass('active', true);
    Events.on(EVENTS.CONTENT_RESIZE, () => { updatePosition($ov, $t, margin); });
    Events.h_once(EVENTS.DESTRUCT, () => { $ov.toggleClass('active', false); });
    if (closeCondition === 'click') {
        closeCondition = new Promise((resolve) => $t.get(0)?.addEventListener('click', resolve, { once: true }));
    }
    if (closeCondition === 'time') {
        closeCondition = sleep(opt.timeout);
    }
    if (typeof closeCondition === 'function') {
        closeCondition = closeCondition();
    }
    await closeCondition ?? Promise.resolve();
    $ov.toggleClass('active', false);
    await sleep(300);
}
Object.assign(window, { tip });
