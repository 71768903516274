export class AMapOverlay {
    constructor(title, bounds, chartDOM, chart) {
        this.overlay = (google?.maps?.OverlayView !== undefined) ? new google.maps.OverlayView() : undefined;
        this.title = title;
        this.bounds = new google.maps.LatLngBounds();
        this.bounds.extend(bounds.getCenter());
        this.chartDOM = chartDOM;
        this.chart = chart;
    }
    onAdd() {
        this.div = document.createElement("div");
        this.div.style.borderStyle = "none";
        this.div.style.borderWidth = "0px";
        this.div.style.position = "absolute";
        const { chartDOM } = this;
        $(chartDOM).css({
            'position': 'absolute',
            'width': '200px',
            'height': '200px',
            'margin-left': '-100px',
            'margin-top': '-100px'
        });
        this.div.appendChild(chartDOM);
        // Add the element to the "overlayLayer" pane.
        const panes = this.overlay?.getPanes();
        if (panes != null) {
            panes.floatPane.appendChild(this.div);
        }
    }
    draw() {
        // We use the south-west and north-east
        // coordinates of the overlay to peg it to the correct position and size.
        // To do this, we need to retrieve the projection from the overlay.
        const overlayProjection = this.overlay?.getProjection();
        // Retrieve the south-west and north-east coordinates of this overlay
        // in LatLngs and convert them to pixel coordinates.
        // We'll use these coordinates to resize the div.
        const sw = overlayProjection?.fromLatLngToDivPixel(this.bounds.getSouthWest()) || { x: 0, y: 0 };
        const ne = overlayProjection?.fromLatLngToDivPixel(this.bounds.getNorthEast()) || { x: 0, y: 0 };
        // Resize the image's div to fit the indicated dimensions.
        if (this.div) {
            this.div.style.left = sw.x + "px";
            this.div.style.top = ne.y + "px";
            this.div.style.width = ne.x - sw.x + "px";
            this.div.style.height = sw.y - ne.y + "px";
        }
    }
    onRemove() {
        if (this.div) {
            // @ts-ignore
            this.div.parentNode.removeChild(this.div);
            delete this.div;
        }
    }
    setMap(map) {
        this.overlay?.setMap(map);
    }
}
