import { AError } from "../classes/AError.js";
import { EVENTS } from "../services/AEventService.js";
import { calculateWindowPosition, transformTextCls, waitForChromeFrame } from "../utils/tools.js";
export var AKpiState;
(function (AKpiState) {
    AKpiState[AKpiState["noResults"] = 0] = "noResults";
    AKpiState[AKpiState["error"] = 1] = "error";
})(AKpiState || (AKpiState = {}));
export class AKpiBlock {
    get Title() {
        const ClsType = this.constructor;
        return ((ClsType.Name) || (ClsType.extractName ? ClsType.extractName(this.opt.Settings) : null)) ?? this.Name ?? 'Unknown';
    }
    get shouldTranslateTitle() { return this.TranslateTitle; }
    get idFilter() { return this.qsFilter.substring(1); }
    get idBlock() { return this.qsBlock.substring(1); }
    get $kpiBlock() { return $('#AjaxContent').find(this.qsBlock); }
    get $kpiHead() { return this.$kpiBlock.find('.kpi-header'); }
    get $kpiView() { return this.$kpiBlock.find('.kpi-view:not(.view-load,.view-warn,.view-err)'); }
    get $filter() { return this.$kpiBlock.find(this.qsFilter); }
    constructor(opt) {
        this.opt = opt;
        this.qsBlock = opt.kpiSystem.idAllocator.getNextHexId({ prefix: '#qs-block-' });
        this.qsFilter = opt.querySelector;
        this.kpiSys = opt.kpiSystem;
        this.Name = opt.Name;
        this.KpiType = opt.KpiType;
        this.Width = opt.Width;
        this.TranslateTitle = opt.TranslateTitle ?? true;
        this.initialized = false;
        this.viewApplied = false;
    }
    async init() {
        if (!this.initialized) {
            this.initialized = true;
            this.prepareLazyLoad();
        }
    }
    toggleLoading(isLoading) {
        const $loadView = this.$kpiBlock.find('.kpi-view.view-load');
        if ($loadView.length === 0) {
            // throw new Error(`couldn't find kpi-view.view-load on kpi block ${this.Name} ${this.KpiType}`)
            return;
        }
        this.$kpiBlock.toggleClass('has-load', isLoading);
    }
    get filterOption() {
        const key = this.$filter.val();
        const index = key !== undefined ? Number(key) : 0;
        const filterOptions = this.getFilterOptions();
        if (Object.keys(filterOptions).length === 0) {
            return { FromDate: null, ToDate: null };
        }
        const found = Object.values(filterOptions).find(opt => opt.index === index);
        if (found === undefined) {
            throw new Error(`Unexpected kpi filter option key=${key} index=${index}`);
        }
        const filters = found.getFilters();
        return { index, ...filters };
    }
    getFilterOptions() {
        return AKpiBlock.defaultDateRangeOptions;
    }
    getFilterOptionKeys() {
        return Object.keys(this.getFilterOptions());
    }
    async render() {
        throw new Error(`Not Implemented Yet!`);
    }
    async ensureViewApplied() {
        if (!this.viewApplied) {
            this.viewApplied = true;
            const html = await requestService.translateDom(await this.render());
            this.$kpiBlock.replaceWith(html);
            if (!this.shouldTranslateTitle) {
                const $title = this.$kpiBlock.find('[original-title]');
                if ($title.length > 0) {
                    $title.text($title.attr('original-title'));
                }
            }
            if (!this.$kpiBlock.is('.kpi-skip-resize')) {
                const h = this.$kpiBlock.height();
                this.$kpiBlock.height(h);
            }
            // this.preparedHtml = ''
            try {
                await this.init();
            }
            catch (err) {
                throw err;
            }
            finally {
                this.initKpiOptions();
            }
        }
        // $kpiParent.find('.kpi-block').replaceWith(html)
    }
    async checkForAnimations() {
        const $kpiBlock = this.$kpiBlock;
        await waitForChromeFrame();
        if ($kpiBlock.hasClass('aci-animation')) {
            $kpiBlock.addClass('aci-play-animation');
        }
        const $children = $kpiBlock.find('.aci-animation');
        $children.addClass('aci-play-animation');
    }
    async renderPlaceholder() {
        this.$kpiParent = $(/*html*/ `
      <div class="column ${this.Width || 'col-4'}">
        <div id="${this.idBlock}" class="kpi-block kpi-vert-6 has-load">
          <div class="kpi-header">
            <div class="kpi-title">
              <label for="${this.idFilter}" class="legend-label">...</label>
            </div>
          </div>
          <div class="kpi-view view-load text-grey">
            <div>
              <div class="loading-circular size-xxl text-primary" style="--fa-animation-duration: 1s; --fa-thickness: 0.3rem;"></div>
            </div>
          </div>
        </div>
      </div>
    `);
        return this.$kpiParent;
    }
    async renderView(opt) {
        const { viewHtml, filterHtml, height } = Object.assign({
            viewHtml: '...',
            filterHtml: ( /*html*/`
        <select id="${this.idFilter}" class="legend-label kpi-select single-kpi-view">
          ${this.getFilterOptionKeys().map((title, i) => /*html*/ `<option value="${i}">${title}</option>`).join('')}
        </select>
      `),
            height: 'kpi-vert-6'
        }, opt);
        const classList = ['kpi-block', height, 'has-load'].join(' ');
        const style = [
            opt?.margin !== undefined ? `margin: ${opt.margin}px` : null,
            opt?.padding !== undefined ? `padding: ${opt.padding}px` : null,
        ].filter(v => v !== null).join('; ');
        return ( /*html*/`
      <div id="${this.idBlock}" class="${classList}">
        <div class="kpi-header">
          <div class="kpi-title">
            <label for="${this.idFilter}" class="legend-label" original-title="${(opt?.title ?? this.Name)}">
              ${this.shouldTranslateTitle ? (opt?.title ?? this.Name) : ''}
            </label>
            <span class="kpi-filter-text"></span>
          </div>
          ${filterHtml ?? ''}
        </div>
        <div class="kpi-view" style="${style}">
          ${viewHtml}
        </div>
        <div class="kpi-view view-load text-grey">
          <div>
            <!-- <i class="fa-light fa-spinner-third fa-spin fa-7x" style="animation-duration: 1s;"></i> -->
            <div class="loading-circular size-xxl text-primary" style="--fa-animation-duration: 1s; --fa-thickness: 0.3rem;"></div>
          </div>
        </div>
      </div>
    `);
    }
    refresh(filters, context) {
        throw new Error(`Not Implemented Yet!`);
    }
    prepareLazyLoad() {
        this.$filter.on('change', async (e) => {
            Loading.waitForPromises(this.kpiSys.invokeRefresh(this, undefined, { userInput: e.originalEvent != null, enableLoadingIndicators: true })).catch(err => {
                this.errorOccurred(err);
                AError.handleSilent(err);
            });
        });
    }
    initKpiOptions() {
        const $kpi = this.$kpiBlock;
        if (!$kpi.hasClass('kpi-has-options')) {
            const $headerItems = $(`<div class="kpi-overlay"></div>`);
            const $optionRefresh = $(`<a href="#" class="kpi-options"><i class="fa-solid fa-sync fa-fw" style="--fa-animation-duration: 1s"></i></a>`);
            $headerItems.append($optionRefresh);
            const $optionDots = $(`<a href="#" class="kpi-options"><i class="fa-solid fa-ellipsis fa-fw"></i></a>`);
            $headerItems.append($optionDots);
            $optionDots.on('click', (e) => this.handleKpiOptionsClick(e, $optionDots));
            $optionRefresh.on('click', (e) => this.handleKpiRefreshClick(e, $optionRefresh));
            $optionRefresh.toggleClass('inactive', !this.opt.refreshImplemented);
            $optionDots.toggleClass('inactive', !this.opt.optionsImplemented);
            if (this.$kpiHead.length) {
                this.$kpiHead.append($headerItems);
            }
            else {
                $kpi.prepend($headerItems);
            }
            $kpi.addClass('kpi-has-options');
        }
        if (this.kpiSys.ShowSubtitle) {
            this.$filter.on('change', e => {
                this.updateKpiSubtitle();
                eventService.tryInvoke(EVENTS.CONTENT_RESIZE);
            });
            this.updateKpiSubtitle();
        }
    }
    updateKpiSubtitle() {
        const $select = this.$filter;
        const subtitle = this.kpiSys.getSubtitle($select);
        const $block = $select.closest('.kpi-block');
        const $span = $block.find('label.legend-label + span');
        $span.text(subtitle ? ` | ${subtitle}` : ``);
    }
    handleKpiRefreshClick(e, $clicked) {
        e.preventDefault();
        console.log('$clicked', $clicked);
        const $fa = $clicked.is('i') ? $clicked : $clicked.find('i');
        $fa.addClass('fa-spin');
        this.kpiSys.invokeRefresh(this, undefined, { userInput: true, enableLoadingIndicators: true }).finally(() => {
            $fa.removeClass('fa-spin');
        });
    }
    handleKpiOptionsClick(e, $clicked) {
        e.preventDefault();
        const $list = this.kpiSys.$popover.find('ul');
        const $filters = $list.find('.kpi-label-filters');
        const $settings = $list.find('.kpi-label-settings');
        $settings.toggleClass('hidden', !this.kpiSys.AllowEdit);
        $list.find('li:not(.noselect)').remove();
        const $kpi = $clicked.closest('.kpi-block');
        const $kpiSelect = $kpi.find('.kpi-select');
        const $optionsToAdd = this.kpiSys.allowRealtimeWidgets ? $kpiSelect.find('option') : $();
        $filters.toggleClass('hidden', ($optionsToAdd.length === 0));
        $optionsToAdd.each((_, option) => {
            const val = $(option).attr('value');
            const text = $(option).text();
            const $li = $(`<li><a href="#">${text}</a></li>`);
            $li.find('a').on('click', (e) => {
                e.preventDefault();
                $kpiSelect.val(val);
                $kpiSelect.trigger('change');
                menuService.setVisible($('.menu-kpi'), false);
            });
            $li.insertBefore($settings);
        });
        const $fullscreen = $(/*html*/ `<li><a href="#">${this.kpiSys.translations['toggle fullscreen']}</a></li>`);
        $fullscreen.find('a').on('click', async (e) => {
            e.preventDefault();
            this.kpiSys.toggleFullScreen({
                $kpis: $('.kpis'),
                $kpi
            });
            menuService.setVisible($('.menu-kpi'), false);
        });
        $fullscreen.insertBefore($filters);
        if (this.opt.tableImplemented) {
            const $toggleTable = $(/*html*/ `<li><a href="#">${this.kpiSys.translations['toggle table view']}</a></li>`);
            $toggleTable.find('a').on('click', async (e) => {
                e.preventDefault();
                this.toggleTableView();
                menuService.setVisible($('.menu-kpi'), false);
            });
            $toggleTable.insertBefore($filters);
        }
        if ($kpi.closest('.configurable-kpis').length > 0) {
            const $addWidget = $(/*html*/ `<li class="${this.kpiSys.AllowEdit ? '' : 'hidden'}"><a href="#">${this.kpiSys.translations['add widget']}</a></li>`);
            const $editWidget = $(/*html*/ `<li class="${this.kpiSys.AllowEdit ? '' : 'hidden'}"><a href="#">${this.kpiSys.translations['edit widget']}</a></li>`);
            const $deleteWidget = $(/*html*/ `<li class="${this.kpiSys.AllowEdit ? '' : 'hidden'}"><a href="#">${this.kpiSys.translations['delete from view']}</a></li>`);
            $list.append($addWidget);
            $list.append($editWidget);
            $list.append($deleteWidget);
            $addWidget.on('click', async (e) => {
                e.preventDefault();
                this.kpiSys.enableRefreshing = false;
                this.kpiSys.showCreateModal();
            });
            $editWidget.on('click', async (e) => {
                e.preventDefault();
                this.kpiSys.enableRefreshing = false;
                this.kpiSys.showEditModal({ $kpi });
            });
            $deleteWidget.on('click', async (e) => {
                e.preventDefault();
                this.kpiSys.enableRefreshing = false;
                this.kpiSys.showDeleteModal({ $kpi });
            });
        }
        const $a = $clicked.is('.kpi-options') ? $clicked : $clicked.closest('.kpi-options');
        const offset = {
            top: -Math.round($a.height() / 2),
        };
        const { left, top } = calculateWindowPosition({
            $target: $clicked,
            $popover: this.kpiSys.$popover,
        }, offset);
        this.kpiSys.$popover.css({ left, top });
        menuService.setVisible($('.menu-kpi'), true);
    }
    toggleTableView() { }
    stripState() {
        this.$kpiBlock.removeClass('has-err has-warn has-load');
        this.$kpiBlock.find('.kpi-view.view-err').remove();
        this.$kpiBlock.find('.kpi-view.view-warn').remove();
        this.err = undefined;
    }
    hasError() { return this.err !== undefined; }
    errorOccurred(err) {
        this.err = err;
        if (this.$kpiBlock.hasClass('has-err')) {
            return;
        }
        if (this.$kpiBlock.hasClass('has-warn')) {
            return;
        }
        this.$kpiBlock.addClass('has-err');
        const colorCls = transformTextCls('orange');
        const errText = Translate.getCache('Something Went Wrong!');
        console.error(this.Name, this.$filter);
        this.$kpiBlock.append(/*HTML*/ `
      <div class="kpi-view view-err ${colorCls}">
        <i class="fa-solid fa-triangle-exclamation fa-5x mb-2 fa-flip" style="--fa-animation-iteration-count: 1; --fa-animation-delay: -0.25s;"></i>
        <span class="kpi-text">${errText}</span>
      </div>
    `);
    }
    noResults() {
        if (this.$kpiBlock.hasClass('has-err')) {
            return;
        }
        if (this.$kpiBlock.hasClass('has-warn')) {
            return;
        }
        this.$kpiBlock.addClass('has-warn');
        const colorCls = transformTextCls('grey');
        const errText = Translate.getCache('No Data!');
        console.log(this.Name, this.$filter);
        const $emptySet = $(/*HTML*/ `
      <div class="kpi-view view-warn ${colorCls}">
        <i class="fa-regular fa-empty-set fa-5x mb-2 fa-beat aci-animation aci-fade-in" style="--fa-animation-iteration-count: 1; --fa-beat-scale: 1.1;"></i>
        <span class="kpi-text">${errText}</span>
      </div>
    `);
        this.$kpiBlock.append($emptySet);
        // waitForChromeFrame().then(() => {
        //   $emptySet.find('i').addClass('aci-animate')
        // })
    }
    getHeightFromKpi($kpiBlock) {
        return Math.round((($kpiBlock || this.$kpiView).height() || 0)); // + 'px'
    }
    getWidthFromKpi($kpiBlock) {
        return Math.round((($kpiBlock || this.$kpiView).width() || 0)); // + 'px'
    }
    getSize(context) {
        const $kpiView = context;
        return {
            width: this.getWidthFromKpi($kpiView),
            height: this.getHeightFromKpi($kpiView),
        };
    }
    getOptions() {
        return this.opt.widgetOptions;
    }
    toJSON() {
        return {
            Name: this.Name,
            Title: this.Title,
            KpiType: this.KpiType,
            Width: this.Width,
            widgetOptions: this.opt.widgetOptions,
            Settings: this.opt.Settings,
            // FilterOption: this.filterOption
        };
    }
}
AKpiBlock.defaultDateRangeOptions = {
    // 'last hour': { index: 0, getFilters: () => FilterManager.betweenNowAndHourAgo },
    'today': { index: 0, getFilters: () => FilterManager.betweenTodayAndTomorrow },
    'yesterday': { index: 1, getFilters: () => FilterManager.betweenYesterdayAndToday },
    'last 7 days': { index: 2, getFilters: () => FilterManager.betweenLastWeekAndNowDay },
    'last 30 days': { index: 3, getFilters: () => FilterManager.betweenLastMonthAndNowDay },
    'last 6 months': { index: 4, getFilters: () => FilterManager.betweenLastHalfYearAndNowDay },
    'last 12 months': { index: 5, getFilters: () => FilterManager.betweenLastYearAndNowDay },
};
