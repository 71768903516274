import { AError } from "../../classes/AError.js";
import { COLUMN_ACTION, COLUMN_BOOLEAN, COLUMN_DATETIME, DATA_DATETIME } from "../../classes/AGridTypes.js";
import { ASearchHandler } from "../../classes/grid/ASearchHandler.js";
import { ALERTS } from "../../services/AAlertService.js";
import { AConvertToGridColumns, AConvertToGridData, AShowTable } from "../../utils/tools.js";
import { toast } from "../../utils/toasts.js";
export class APage {
    constructor() {
        this.searchHandler = new ASearchHandler();
    }
    async init() {
        Loading.waitForPromises(this.refresh());
    }
    fetch() {
        return requestService.fetch({
            AssertValues: true,
            Query: ( /*sql*/`
        SELECT
          GetDeviceName(DeviceId) as DeviceName,
          dv.Flavor,
          dv.OfficialRelease,
          dv.BuildNumber,
          dv.BuildDate,
          dv.FirstUsed,
          dv.DeviceId
        FROM (
          SELECT DeviceId, MAX(FirstUsed) AS FirstUsed
          FROM device_versions
          GROUP BY DeviceId
        ) Latest
        LEFT JOIN device_versions dv USING (DeviceId,FirstUsed)
        ORDER BY GetDeviceType(DeviceId), GetDeviceIndexNumber(DeviceId)
      `),
            Language: Language,
            Translate: ['DeviceName']
        });
    }
    async showHistory(data) {
        const ares = await requestService.fetch({
            AssertValues: true,
            Query: ( /*sql*/`
        SELECT
          Flavor,
          OfficialRelease,
          BuildNumber,
          BuildDate,
          FirstUsed,
          DeviceId
        FROM device_versions
        WHERE DeviceId=:DeviceId
        ORDER BY FirstUsed
      `),
            Params: { DeviceId: data.DeviceId },
            Language: Language,
        });
        Alerts.show({
            translatedTitle: data.DeviceName,
            content: ( /*html*/`<div id="modal-table-bryntum" style="height: ${Math.round(window.innerHeight / 3 * 2)}px"></div>`),
            type: ALERTS.Mega,
        });
        AShowTable({
            aci: { isModal: true },
            appendTo: 'modal-table-bryntum',
            columns: AConvertToGridColumns(ares, {
                OfficialRelease: COLUMN_BOOLEAN,
                BuildDate: COLUMN_DATETIME,
                FirstUsed: COLUMN_DATETIME,
            }),
            data: AConvertToGridData(ares, {
                BuildDate: DATA_DATETIME,
                FirstUsed: DATA_DATETIME,
            }),
        });
    }
    async refresh() {
        try {
            const ares = await this.fetch();
            ares.addColumns(['ActionHistory']);
            this.grid = AShowTable({
                appendTo: 'table-bryntum',
                aci: {
                    resizeToFit: true,
                    resizeToFitReverse: true,
                    flex: 1,
                },
                features: {
                    search: true
                },
                tbar: [
                    {
                        type: 'text',
                        ref: 'searchField',
                        clearable: true,
                        label: '<i class="b-icon b-icon-search"></i>',
                        showHitIndex: false,
                        listeners: {
                            // input: 'onSearchFieldInput',
                            change: 'onSearchFieldChange',
                            clear: 'onSearchFieldClear',
                            thisObj: this.searchHandler.bind({ ref: 'searchField' })
                        }
                    },
                ],
                selectionMode: {
                    multiSelect: false
                },
                columns: AConvertToGridColumns(ares, {
                    OfficialRelease: COLUMN_BOOLEAN,
                    BuildDate: COLUMN_DATETIME,
                    FirstUsed: COLUMN_DATETIME,
                    ActionHistory: {
                        field: 'Action1',
                        ...COLUMN_ACTION({
                            iconCls: 'fa-solid fa-clock-rotate-left',
                            btnCls: 'btn-white',
                            onClick: ({ record }) => {
                                this.showHistory(record.data).catch(AError.handle);
                                toast({ msg: 'Not Implemented Yet!' });
                            },
                        }),
                    },
                    // COLUMN_ACTION({ iconCls: 'fa-solid fa-pencil text-primary', btnCls: 'btn-white' }),
                    // 'ActionDelete': COLUMN_ACTION({ iconCls: 'fa-regular fa-trash text-red', btnCls: 'btn-white' }),
                }),
                data: AConvertToGridData(ares, {
                    BuildDate: DATA_DATETIME,
                    FirstUsed: DATA_DATETIME,
                }),
            });
            this.grid.on('search', (e) => this.searchHandler.applyFilter(e));
            this.grid.on('clearsearch', (e) => this.searchHandler.onSearchFieldClear(e));
        }
        catch (err) {
            AError.handle(err);
        }
    }
}
export function render() {
    return ( /*html*/`
    <div id="Rapport" class="flex-child bryntum-container has-footer-2 fw">
    
      <div id="table-bryntum" class="fh fw"></div>

      <div class="columns footer aci">
        <div class="column col-2">
          <div id="count" class="text">Viewing <span>0</span> Devices</div>
        </div>
      </div>
    </div>
  `);
}
