import { AError } from "../classes/AError.js";
import { AEngine } from "../core/AEngine.js";
import { isVariablePrimitive, mergeDeep } from "../utils/tools.js";
export class AConfigOrm {
    get emptyModel() {
        return {
            CfgPath: '',
            CfgValue: null,
            Version: 1,
        };
    }
    getEmptyModel() {
        const ModificationUser = stateService.User;
        const ModificationDevice = _.getSession().DeviceName;
        return {
            ...this.emptyModel,
            ModificationUser,
            ModificationDevice,
        };
    }
    getMetaModel() {
        return {
            ModificationUser: stateService.User,
            ModificationDevice: _.getSession().DeviceName,
        };
    }
    async validate() {
        const res = await this.fetchAll();
        if (res.isEmpty) {
            AEngine.log(`Database config is empty...`);
            // for (let cfgPath in configService.FALLBACK_FLAT) {
            //   const cfgModel = AEngine.get(AConfigManageService).genConfigModel(cfgPath as AConfigKey)
            //   // const CfgValue = configService.FALLBACK_FLAT[cfgPath]
            //   // const cfgModel: AConfigModel = {
            //   //   CfgPath: cfgPath,
            //   //   CfgValue: CfgValue,
            //   //   IsPrimitive: isPrimitive(CfgValue) ? 1 : 0,
            //   //   ModificationUser: 'ACCCServer',
            //   //   ModificationDevice: 'ACCCServer',
            //   //   Version: 1
            //   // }
            //   AEngine.log(`Config Insert ->`, cfgModel)
            //   await this.create(cfgModel)
            // }
        }
    }
    async exists(opt) {
        const res = await requestService.query({
            Query: `SELECT * FROM backoffice_config_exp WHERE CfgPath=:CfgPath`,
            Params: opt
        });
        return res.Rows.length > 0;
    }
    async fetchAll() {
        const ares = await requestService.fetch({
            AssertValues: true,
            Query: (`SELECT * FROM backoffice_config_exp`),
            Language: Language,
        }, {
            valueMapper: {
                CfgValue: (v) => (this.isJsonArrayText(v) || this.isJsonObjectText(v) || this.isJsonBooleanText(v)) ? JSON.parse(v) : v
            }
        });
        return ares;
    }
    async findOne(opt) {
        const rows = await requestService.fetch({
            AssertValues: true,
            Query: `SELECT * FROM backoffice_config_exp WHERE CfgPath=:CfgPath`,
            Params: opt
        }, {
            valueMapper: {
                CfgValue: (v) => (this.isJsonArrayText(v) || this.isJsonObjectText(v) || this.isJsonBooleanText(v)) ? JSON.parse(v) : v
            }
        });
        return rows.First;
    }
    async createOrUpdate(options) {
        const IsPrimitive = configService.isPrimitive(options.CfgPath);
        await requestService.query({
            Query: (`
        INSERT INTO backoffice_config_exp (CfgPath, CfgValue, IsPrimitive, Version, ModificationUser, ModificationDevice)
        VALUES (:CfgPath, :CfgValue, :IsPrimitive, :Version, :ModificationUser, :ModificationDevice)
        ON DUPLICATE KEY UPDATE
          CfgValue=:CfgValue,
          IsPrimitive=:IsPrimitive,
          Version=IFNULL(:Version, Version),
          ModificationUser=:ModificationUser,
          ModificationDevice=:ModificationDevice
      `),
            Params: mergeDeep(this.getEmptyModel(), options, {
                CfgValue: (IsPrimitive || typeof options.CfgValue === 'string') ? options.CfgValue : JSON.stringify(options.CfgValue),
                IsPrimitive: IsPrimitive ? 1 : 0,
                ...this.getMetaModel()
            })
        });
        return true;
    }
    async create(options) {
        const exists = await this.exists(options);
        if (exists) {
            AError.handleSilent(`db entry already exists! CfgPath=${options.CfgPath}`, 'CfgPath Already Exists');
            return false;
        }
        const IsPrimitive = isVariablePrimitive(options.CfgValue);
        await requestService.query({
            Query: (`
        INSERT INTO backoffice_config_exp (CfgPath, CfgValue, IsPrimitive, Version, ModificationUser, ModificationDevice)
        VALUES (:CfgPath, :CfgValue, :IsPrimitive, :Version, :ModificationUser, :ModificationDevice)
      `),
            Params: mergeDeep(this.getEmptyModel(), options, {
                CfgValue: (IsPrimitive) ? `${options.CfgValue}` : JSON.stringify(options.CfgValue),
                IsPrimitive: IsPrimitive ? 1 : 0,
                ...this.getMetaModel()
            })
        });
        return true;
    }
    async update(options) {
        const exists = await this.exists(options);
        if (!exists) {
            AError.handleSilent(`Couldn't find db entry backoffice_config.CfgPath=${options.CfgPath}`, 'CfgPath Not Found');
            return false;
        }
        const IsPrimitive = isVariablePrimitive(options.CfgValue);
        await requestService.query({
            Query: (`
        UPDATE backoffice_config_exp
        SET
          CfgValue=:CfgValue,
          IsPrimitive=:IsPrimitive,
          Version=IFNULL(:Version, Version),
          ModificationUser=:ModificationUser,
          ModificationDevice=:ModificationDevice
        WHERE CfgPath=:CfgPath
      `),
            Params: mergeDeep(this.getEmptyModel(), options, {
                CfgValue: (IsPrimitive || typeof options.CfgValue === 'string') ? `${options.CfgValue}` : JSON.stringify(options.CfgValue),
                IsPrimitive: IsPrimitive ? 1 : 0,
                ...this.getMetaModel()
            })
        });
        return true;
    }
    async delete(opt) {
        await requestService.query({
            Query: (`DELETE FROM backoffice_config_exp WHERE CfgPath=:CfgPath`),
            Params: opt
        });
    }
    isJsonObjectText(text) {
        if (typeof text === 'string') {
            let trimmed = text.trim();
            let couldBeObject = trimmed.startsWith('{') && trimmed.endsWith('}');
            if (!couldBeObject) {
                return false;
            }
            try {
                JSON.parse(trimmed);
            }
            catch (e) {
                return false;
            }
            return true;
        }
        return false;
    }
    isJsonArrayText(text) {
        if (typeof text === 'string') {
            let trimmed = text.trim();
            if (trimmed === '[object Object]') {
                return false;
            }
            let couldBeList = trimmed.startsWith('[') && trimmed.endsWith(']');
            if (!couldBeList) {
                return false;
            }
            try {
                JSON.parse(trimmed);
            }
            catch (e) {
                return false;
            }
            return true;
        }
        return false;
    }
    isJsonBooleanText(text) {
        if (typeof text === 'string' && (text === 'true' || text === 'false')) {
            return true;
        }
        return false;
    }
}
