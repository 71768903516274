import { AFormInstance } from "../core/form/AFormInstance.js";
import { AConfigOrm } from "../orm/AConfigOrm.js";
import { AConfigComponent } from "../pages/aci/config/AConfigComponent.js";
import { AConfigTransitionComponent } from "../pages/aci/config/cfg-comp-color-transition.js";
import { AConfigFormComponent } from "../pages/aci/config/cfg-comp-form.js";
import { AConfigInputComponent } from "../pages/aci/config/cfg-comp-input.js";
import { AConfigJSONComponent } from "../pages/aci/config/cfg-comp-json.js";
export class AConfigManageService {
    constructor() {
        this.orm = new AConfigOrm();
        this.cfgPathToComponentPartial = {
            '*': (cfgItem) => cfgItem.IsPrimitive ? new AConfigComponent({ cfgItem }) : new AConfigJSONComponent({ cfgItem }),
            'general.map': (cfgItem) => new AConfigJSONComponent({ cfgItem }),
            'general.langBryntum': (cfgItem) => new AConfigInputComponent({ cfgItem, formInput: {
                    type: 'select', options: [{ id: 'En', text: 'En' }]
                }
            }),
            'general.security.useStrictPasswordPolicy': (cfgItem) => new AConfigInputComponent({ cfgItem, formInput: {
                    type: 'checkbox', value: cfgItem.CfgValue
                }
            }),
            'general.centerMapOnClick': (cfgItem) => new AConfigInputComponent({ cfgItem, formInput: {
                    type: 'checkbox', value: cfgItem.CfgValue
                }
            }),
            // 'general.legendZoom': (cfgItem) => {
            //   return new AConfigInputComponent({cfgItem, formInput: {
            //     type: 'number', value: cfgItem.CfgValue, step: '0.1', min: 0.5, max: 1.5 }
            //   })
            // },
            'general.minEnforcingOccupancy': (cfgItem) => new AConfigInputComponent({ cfgItem, formInput: {
                    type: 'number', value: cfgItem.CfgValue, step: '0.1', min: 0.1, max: 0.9
                }
            }),
            'filters.warningThresholdWeeks': (cfgItem) => new AConfigInputComponent({ cfgItem, formInput: {
                    type: 'number', value: cfgItem.CfgValue, step: '1', min: 2, max: 52 * 10 /* max 10 years */
                }
            }),
            'filters.override': (cfgItem) => {
                const data = cfgItem.CfgValue;
                const [fromDate, toDate] = [new Date(data.fromDate), new Date(data.toDate)];
                return new AConfigFormComponent({ cfgItem,
                    form: new AFormInstance({
                        ignoreWildcards: false,
                        formInputs: [
                            { id: 'enabled', type: 'checkbox', label: 'Override Filters' },
                            { id: 'fromDate', type: 'date', width: 'col-6', value: fromDate, isDisabled: ({ formData }) => !formData.enabled },
                            { id: 'fromTime', type: 'time', width: 'col-6', value: fromDate, isDisabled: ({ formData }) => !formData.enabled, label: '' },
                            { id: 'toDate', type: 'date', width: 'col-6', value: toDate, isDisabled: ({ formData }) => !formData.enabled },
                            { id: 'toTime', type: 'time', width: 'col-6', value: toDate, isDisabled: ({ formData }) => !formData.enabled, label: '' },
                        ],
                    })
                });
            },
            'filters.maxResults': (cfgItem) => new AConfigFormComponent({ cfgItem,
                form: new AFormInstance({
                    ignoreWildcards: false,
                    formInputs: [
                        { id: 'maxResultsMaps', type: 'number', step: '1', width: 'col-6', min: 1 },
                        { id: 'maxResultsReports', type: 'number', step: '1', width: 'col-6', min: 1 },
                        { id: 'maxResultsDefault', type: 'number', step: '1', width: 'col-6', min: 1 },
                        { id: 'maxResultsCeiling', type: 'number', step: '1', width: 'col-6', min: 100, max: 100000 },
                    ]
                })
            }),
            'filters.enableParkingRightTypeFilter': (cfgItem) => new AConfigInputComponent({ cfgItem, formInput: {
                    type: 'checkbox', value: cfgItem.CfgValue
                }
            }),
            'filters.enableVerifyResultFilter': (cfgItem) => new AConfigInputComponent({ cfgItem, formInput: {
                    type: 'checkbox', value: cfgItem.CfgValue
                }
            }),
            'filters.showZonesInsteadOfAreas': (cfgItem) => new AConfigInputComponent({ cfgItem, formInput: {
                    type: 'checkbox', value: cfgItem.CfgValue
                }
            }),
            'thematic.occupancy': (cfgItem) => new AConfigTransitionComponent({ cfgItem, unit: '%', useBounds: true }),
            'thematic.visitorRate': (cfgItem) => new AConfigTransitionComponent({ cfgItem, unit: '%', useBounds: true }),
            'thematic.compliancy': (cfgItem) => new AConfigTransitionComponent({ cfgItem, unit: '%', useBounds: true }),
            'thematic.compliancyVisitor': (cfgItem) => new AConfigTransitionComponent({ cfgItem, unit: '%', useBounds: true }),
            'thematic.enforcementIntensity': (cfgItem) => new AConfigTransitionComponent({ cfgItem, unit: '%', useBounds: true }),
            'drawing.tableColors': (cfgItem) => {
                const data = cfgItem.CfgValue;
                return new AConfigFormComponent({ cfgItem,
                    form: new AFormInstance({
                        ignoreWildcards: false,
                        formInputs: [
                            { id: 'error', type: 'color', value: data.error },
                            { id: 'success', type: 'color', value: data.success },
                            { id: 'highlight', type: 'color', value: data.highlight },
                        ],
                    })
                });
            },
            'drawing.routePrecision': (cfgItem) => new AConfigTransitionComponent({ cfgItem, useBounds: true, unit: 'm', amountOfDecimals: 1 }),
            'drawing.routeSpeed': (cfgItem) => new AConfigTransitionComponent({ cfgItem, useBounds: true, unit: 'km/h' }),
        };
    }
    autoInit() { }
    /**
     * Generate Editable Component for editing complex config datastructures
     * @param cfgItem Config Entry Item
     * @returns Config Component
     */
    async genEditableComponent(cfgItem) {
        const genComponent = this.cfgPathToComponentPartial[cfgItem.CfgPath] ?? this.cfgPathToComponentPartial['*'];
        return genComponent(cfgItem);
    }
    createOrUpdate(options) {
        return this.orm.createOrUpdate(options);
    }
    get emptyModel() {
        return this.orm.emptyModel;
    }
}
