import { AFormatDate } from "../utils/tools.js";
import { initToolTips } from "../utils/tooltip.js";
import { AKpiBlock, AKpiState } from "./AKpiBlock.js";
export class AKpiBlockOldestDetectionTable extends AKpiBlock {
    constructor(opt) {
        super({
            refreshImplemented: true,
            optionsImplemented: true,
            ...opt
        });
    }
    async fetchAll({ FromDate, ToDate }) {
        const res = await requestService.fetch({
            AssertValues: true,
            Query: ( /*SQL*/`
        SELECT Min(DetectionTime) AS OldestDetection, DetectionState FROM detections_final WHERE DetectionState!='Done' GROUP BY DetectionState;
      `),
            Params: { FromDate, ToDate }
        });
        // return res.map(v => {
        //   v.PreviousDetectionStateDuration = ARound(v.PreviousDetectionStateDuration, 2)
        //   v.PreviousDetectionStateDurationMin = ARound(v.PreviousDetectionStateDurationMin, 2)
        //   v.PreviousDetectionStateDurationMax = ARound(v.PreviousDetectionStateDurationMax, 2)
        //   return v
        // })
        return res.toArray();
    }
    async refresh(filters = this.filterOption) {
        let rows = await this.fetchAll(filters);
        if (rows.length === 0) {
            return AKpiState.noResults;
        }
        const html = await requestService.translateDom(/*html*/ `
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Detection State</th>
            <th>Oldest Detection</th>
          </tr>
        </thead>
        <tbody>
        ${rows.map((row) => {
            // const HM = AConvertMillisecondsToHM(Math.abs(row.PreviousDetectionStateDuration * 1000))
            // const isNegative = row.PreviousDetectionStateDuration < 0 && HM !== '00:00:00'
            return ( /*html*/`
              <tr detectionstate="${row.DetectionState}">
                <td>${row.DetectionState}_SHORT</td>
                <td>${AFormatDate(new Date(row.OldestDetection))}</td>
              </tr>
            `);
        }).join(' ')}
        </tbody>
      </table>
    `);
        // const $kpiView = this.$filter.closest('.kpi-block').find('.kpi-view')
        this.$kpiView.html(html);
        initToolTips();
    }
    async render() {
        return await super.renderView({
            title: 'DetectionState Oldest Detection',
            viewHtml: ( /*html*/``),
        });
    }
}
