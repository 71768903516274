import { AError } from "../../classes/AError.js";
import { COLUMN_DATETIME, COLUMN_TEXT, DATA_DATETIME } from "../../classes/AGridTypes.js";
import { AEngine } from "../../core/AEngine.js";
import { ATemplates } from "../../core/ATemplateService.js";
import { ATimeService } from "../../services/ATimeService.js";
import { AConvertToGridColumns, AConvertToGridDataAsync, AInputDate, AShowTable } from "../../utils/tools.js";
const timeService = AEngine.get(ATimeService);
export class APage {
    constructor() {
        $('#RefreshButton').on('click', _ => this.refresh());
    }
    async init() {
        await Loading.waitForPromises(this.refresh());
    }
    test() {
        requestService.fetch({
            AssertValues: true,
            Query: (`
          SELECT User, MAX(Time) as LastActivity
          FROM sessionstatus s
          WHERE User NOT IN (
            SELECT User FROM sessionstatus
            WHERE Time > DATE_SUB(NOW(), INTERVAL :Months MONTH) AND User IS NOT NULL
            GROUP BY User
          )
          GROUP BY User
          ORDER BY LastActivity
      `),
            Params: { Months: 0 }
        }, { valueMapper: { LastActivity: (v) => AInputDate(new Date(v)) } }).then(console.log);
    }
    async refresh() {
        const filters = FilterManager.saveExplicit();
        return Loading.waitForPromises(requestService.fetch({
            AssertValues: true,
            Query: (`
          SELECT User, MIN(Time) AS FirstActivity, MAX(Time) AS LastActivity, MAX(Time) AS LastSeen
          FROM sessionstatus s
          GROUP BY User
          ORDER BY LastActivity
        `),
            Params: Object.assign({ Months: 0 }, filters),
            Language: Language
        })).then(async (ares) => {
            if (ares.isEmpty) {
                return Alerts.noResults();
            }
            this.grid = AShowTable({
                appendTo: 'table-bryntum',
                aci: {
                    resizeToFit: true,
                    resizeToFitReverse: true,
                },
                columns: AConvertToGridColumns(ares, {
                    'User': COLUMN_TEXT,
                    'FirstActivity': COLUMN_DATETIME,
                    'LastActivity': COLUMN_DATETIME,
                    'LastSeen': COLUMN_TEXT,
                }),
                data: await AConvertToGridDataAsync(ares, {
                    'FirstActivity': DATA_DATETIME,
                    'LastActivity': DATA_DATETIME,
                    'LastSeen': (value) => timeService.ago(new Date(), new Date(value)),
                }),
            });
        }).catch(AError.handle);
    }
}
export function render() {
    return ( /*html*/`
    <div id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column c-scroll col-12">

      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </div>

    <div id="Rapport" class="flex-child bryntum-container has-footer-2">
      <div template="${ATemplates.WaitingForInput}"></div>
      <div id="table-bryntum" class="hidden"></div>

      <div class="columns footer aci">
        <div class="column col-2">
          <div id="count" class="text">Viewing <span>0</span> Records</div>
        </div>
        <div class="column col-2 col-ml-auto">
          <button id="export" class="btn btn-primary col-12" disabled="disabled">Export</button>
        </div>
      </div>
    </div>
  `);
}
