import { AEngine } from "../../core/AEngine.js";
import { ALERTS, ALERT_BUTTONS, ALERT_STATUS, ALERT_TITLES } from "../../services/AAlertService.js";
import { AArrayService } from "../../services/AArrayService.js";
import { AJsonService } from "../../services/AJsonService.js";
import { ATimeService } from "../../services/ATimeService.js";
import { AUserManualService } from "../../services/AUserManualService.js";
import { escapeHtml } from "../../utils/json.js";
import { toast } from "../../utils/toasts.js";
import { AUrlEncodedImageFromBase64, debounce } from "../../utils/tools.js";
import { ACrypto } from "../ACrypto.js";
import { AError } from "../AError.js";
import { AWindowBase } from "../windows/AWindowBase.js";
const markdownExample = (`
# Markdown in the browser!

## Text & paragaphs:
Rendered in **bold!**.\\
Second line of paragraph!\\
Third line yay!

## Ordered List
1. Apple
2. Mango
3. Milk
4. Destruction of our enemies

## List
- Workout
- Make Appointment
- Make Dinner
- Destruction of our enemies

## Links

My favorite search engine is [Duck Duck Go](https://duckduckgo.com "The best search engine for privacy").

## Seperators
***
---
_________________

## Block Quote
> P.S. DON'T FORGET THE MILK!! :)
`).trim();
export class AUserManualWindow extends AWindowBase {
    constructor(opt) {
        super();
        this.opt = opt;
        this.timeService = AEngine.get(ATimeService);
        this.isSaved = true;
        this.userManualService = AEngine.get(AUserManualService);
        this.arrayService = AEngine.get(AArrayService);
        const LanguageCodes = jsonService.getFromCache({ url: AJsonService.JSON_FILES.LANGUAGE });
        this.availableLanguages = [
            { key: 'en', value: LanguageCodes['en'] },
            { key: 'nl', value: LanguageCodes['nl'] },
            { key: 'fr', value: LanguageCodes['fr'] },
        ];
    }
    get $input() {
        return this.$internal.find('#markdown-input');
    }
    get $renderer() {
        return this.$internal.find('.markdown-renderer');
    }
    async configureToolbar() {
        return [
            {
                title: 'File',
                children: [
                    {
                        title: 'New File',
                        action: () => this.newFile()
                    },
                    null,
                    {
                        title: 'Save',
                        action: () => this.showSaveModal()
                    },
                    {
                        title: 'Load',
                        children: Object.keys(this.userManualService.groupedEntries).map((id) => {
                            const f = this.userManualService.groupedEntries[id];
                            if (f.length === 1) {
                                return { title: f[0].Title, action: () => this.loadManualPopup(f[0]) };
                            }
                            return {
                                title: f[0].Title,
                                children: this.arrayService.orderedSort(f.map(it => {
                                    return { title: it.Lang, action: () => this.loadManualPopup(it) };
                                }), this.availableLanguages.map(l => l.key), 'title')
                            };
                        })
                    },
                    null,
                    { title: 'Exit', action: () => this.invokeClose() },
                ]
            },
            {
                title: 'Edit',
                children: [
                    { title: 'Add Image', action: () => this.showAddImagePopup() },
                    null,
                    {
                        title: 'Unlink from page',
                        enabled: this.currentItem?.Id_Mr != null,
                        action: async () => {
                            try {
                                if (this.currentItem != null) {
                                    await Loading.waitForPromises(this.userManualService.unlink(this.currentItem));
                                    toast({ msg: 'Unlinked Page' });
                                    this.currentItem.Id_Mr = null;
                                }
                                await this.reloadToolbar();
                            }
                            catch (err) {
                                AError.handle(err);
                            }
                        },
                    },
                    {
                        title: 'Link to page',
                        enabled: this.currentItem?.Id_Mr == null,
                        action: async () => {
                            if (!this.isSaved || this.currentItem == null) {
                                return Alerts.show({
                                    title: ALERT_TITLES.Warning,
                                    content: 'Please save before linking!',
                                    buttons: ALERT_BUTTONS.close
                                });
                            }
                            try {
                                const action = await this.userManualService.showLinkPopup({
                                    Id_Mr: this.currentItem.Id_Mr,
                                    MarkdownId: this.currentItem.MarkdownId
                                });
                                if (action === ALERT_STATUS.ON_ACTION_PROCEED) {
                                    const manual = this.userManualService.entries.find(e => e.MarkdownId == this.currentItem.MarkdownId && e.Lang == 'en');
                                    await this.loadManual(manual);
                                    toast({ msg: 'Linked Page' });
                                }
                            }
                            catch (err) {
                                AError.handle(err);
                            }
                        }
                    },
                ]
            },
            {
                title: 'Help',
                children: [
                    { title: 'Markdown Cheatsheet', href: 'https://www.markdownguide.org/basic-syntax/#overview' }
                ]
            },
        ];
    }
    async showAddImagePopup() {
        try {
            const ares = await this.userManualService.fetchAllImages();
            const images = ares.toArray();
            const events = Alerts.show({
                title: ALERT_TITLES.None,
                type: ALERTS.Medium,
                content: ( /*HTML*/`
          <div class="img-selector-wrap">
            ${images.map((img) => {
                    return ( /*HTML*/`
                <div class="image-preview">
                  <img alt="${img.AltText}" src="${AUrlEncodedImageFromBase64(img.SmallImage)}">
                  <div class="preview-label">${img.AltText}</div>
                </div>
              `);
                }).join('')}
            <div class="image-preview">
              <div class="image-preview-upload">
                <i class="fa-solid fa-cloud-arrow-up fa-2xl"></i>
              </div>
            </div>
          </div>
        `)
            });
            const { $ele } = events;
            $ele.find('.image-preview-upload').on('click', (e) => {
                AEngine.get(AUserManualService).showUploadForm({
                    onUploaded: (success) => {
                        if (success) {
                            toast({ msg: 'Image Uploaded' });
                        }
                        Alerts.closeAllActiveModals();
                        this.showAddImagePopup();
                    }
                });
            });
            const $previews = $ele.find('.image-preview');
            $previews.toArray().map(e => $(e)).map($ele => $ele.on('click', (e) => {
                $previews.removeClass('selected');
                $ele.addClass('selected');
            }));
            events.on(ALERT_STATUS.ON_ACTION_PROCEED, () => {
                const $selected = $ele.find('.image-preview.selected');
                if ($selected.length === 0) {
                    return false;
                }
                this.addImageUsingRef(images[$selected.index()]);
            });
        }
        catch (err) {
            AError.handle(err);
        }
    }
    async checkIfSaved() {
        if (this.isSaved) {
            return ALERT_STATUS.ON_ACTION_PROCEED;
        }
        var action = await new Promise((resolve) => {
            Alerts.show({
                title: ALERT_TITLES.Warning,
                content: 'You have unsaved changes to this file, are you sure you want to exit?',
                buttons: ALERT_BUTTONS.yesNo
            }).on(ALERT_STATUS.ON_MODAL_CLOSED, (result) => {
                resolve(result.action);
            });
        });
        return action;
    }
    newFile() {
        this.checkIfSaved().then((action) => {
            if (action === ALERT_STATUS.ON_ACTION_PROCEED) {
                this.$input.val('');
                this.currentItem = undefined;
                this.title = 'Untitled';
                Loading.waitForPromises(this.renderPreview({ Body: '' })).catch(AError.handle);
                this.isSaved = true;
                return this.reloadToolbar();
            }
        }).catch(AError.handle);
    }
    loadManualPopup(item) {
        this.checkIfSaved().then((action) => {
            if (action === ALERT_STATUS.ON_ACTION_PROCEED) {
                return this.loadManual(item);
            }
        }).catch(AError.handle);
    }
    async loadManual(item) {
        if (!item) {
            return;
        }
        this.$input.val(item.Body);
        this.currentItem = item;
        this.title = item.Title;
        Loading.waitForPromises(this.renderPreview(item)).catch(AError.handle);
        this.isSaved = true;
        await this.reloadToolbar();
    }
    addImageUsingRef(img) {
        const $inp = this.$input;
        const val = $inp.val() || '';
        var c1 = $inp.prop('selectionStart'), c2 = $inp.prop('selectionEnd');
        var left = val.substring(0, c1);
        var mid = val.substring(c1, c2);
        console.log('mid', mid);
        var right = val.substring(c2);
        $inp.val(left + `{IMG:${img.ImageId}}` + right);
        $inp.prop('selectionStart', c1),
            $inp.prop('selectionEnd', c2 - mid.length);
        $inp.trigger('keyup');
    }
    async show() {
        let id = idAllocatorService.getNextId({ prefix: 'win-table-' });
        await super.generate({
            content: ( /*html*/`
        <div id="${id}" class="fw fh" style="padding: 0.4rem">
          <div class="columns fh">
            <div class="column col-6 fh" style="overflow-y: auto">
              <div class="markdown-renderer"></div>
            </div>
            <div class="column col-6 fh">
              <textarea class="form-input fw-i fh-i" id="markdown-input" placeholder="Markdown"></textarea>
            </div>
          </div>
        </div>
      `),
            size: { width: 1200, height: 580 },
            minSize: { width: 740, height: 180 },
            alignTo: 'center',
            lifeCycle: 'CONTINUOUS'
        });
        this.init(this.$internal);
        return window;
    }
    /**
     * @deprecated
     */
    fetchCache() {
        let cache = preferenceService.load('tiptutorials_cached', [{ title: 'Example', body: markdownExample }]);
        for (let i = 0; i < cache.length; i++) {
            var item = cache[i];
            this.userManualService.create({ Lang: 'en', Title: item.title, Body: item.body });
        }
        if (cache.length > 0) {
            preferenceService.set('tiptutorials_cached', []);
            this.reloadToolbar();
        }
        return cache;
    }
    init($win) {
        const trigger = debounce(() => {
            this.isSaved = false;
            Loading.waitForPromises(this.renderPreview({ Body: this.$input.val() })).catch(AError.handle);
        }, 300);
        this.$input.on('keyup', () => { trigger(); });
        this.title = 'Untitled';
    }
    async showSaveModal() {
        let e = Alerts.show({
            translatedTitle: await Loading.waitForPromises(Translate.get(this.currentItem != null ? 'Edit User Manual' : 'Create User Manual')),
            buttons: ALERT_BUTTONS.saveCancel,
            content: ( /*HTML*/`
        <form>
          <label class="form-label" for="markdown-id">Markdown Id</label>
          <input id="markdown-id" class="form-input fw" type="text" value="${this.currentItem?.MarkdownId ?? ACrypto.uuid7String()}" disabled="disabled">

          <label class="form-label" for="markdown-title">Title</label>
          <input id="markdown-title" class="form-input fw" type="text" value="${this.currentItem?.Title ?? this.title ?? ''}">
          
          <label class="form-label" for="markdown-title">Language</label>
          <select id="markdown-lang" class="form-input fw">
            ${this.availableLanguages.map(({ key, value }) => {
                return ( /*HTML*/`<option value="${escapeHtml(key)}">${escapeHtml(value)}</option>`);
            }).join('')}
          </select>
        </form>
      `),
        });
        let $id = e.$ele.find('#markdown-id');
        let $title = e.$ele.find('#markdown-title');
        let $lang = e.$ele.find('#markdown-lang');
        $lang.val(this.currentItem?.Lang ?? this.availableLanguages[0].key);
        e.on(ALERT_STATUS.ON_ACTION_PROCEED, () => {
            let title = ($title.val() || '');
            if (title.length === 0) {
                return false;
            }
            const mId = $id.val();
            const Lang = $lang.val();
            this.userManualService.create({
                MarkdownId: mId,
                Lang: Lang,
                Title: $title.val(),
                Body: this.$input.val(),
            }).then(async (succeeded) => {
                if (!succeeded) {
                    return;
                }
                this.isSaved = true;
                const manual = this.userManualService.entries.find(e => e.MarkdownId == mId && e.Lang == Lang);
                await this.loadManual(manual);
            }).catch(AError.handle);
            // this.addToCache({title, body})
        });
    }
    async renderPreview(item) {
        await this.userManualService.prefetchImages(item.Body);
        await this.userManualService.renderMarkdown(item.Body).then((val) => {
            this.$renderer.html(val);
        });
    }
    invokeClose() {
        this.checkIfSaved().then((action) => {
            if (action === ALERT_STATUS.ON_ACTION_PROCEED) {
                this.handleClose();
            }
        });
    }
    handleClose() {
        this.$internal.remove();
        this.handler.destruct();
    }
}
