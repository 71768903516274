import { AError } from "../classes/AError.js";
import { MYSQL_FALSE, MYSQL_TRUE } from "../utils/query.js";
export class AFineGroupOrm {
    get emptyFineGroup() {
        return {
            FineGroupCode: '',
            FineGroupText: '',
            FineLimitDuration: '48:00:00',
            EnableFineLimitResetTime: false,
            FineLimitResetTime: '06:00:00',
        };
    }
    async find({ FineGroupCode }) {
        return await requestService.query({
            Query: `SELECT FineGroupCode FROM config_finegroups WHERE FineGroupCode=:FineGroupCode`,
            Params: { FineGroupCode }
        });
    }
    async fetchAll() {
        const rows = await requestService.fetch({
            AssertValues: true,
            Query: (`
        SELECT
          FineGroupCode,
          FineGroupText,
          CONCAT('D',FineLimitDuration) AS FineLimitDuration,
          IF(FineLimitResetTime IS NOT NULL, ${MYSQL_TRUE}, ${MYSQL_FALSE}) AS EnableFineLimitResetTime,
          CONCAT('D',FineLimitResetTime) AS FineLimitResetTime,
          FineLimitMethod,
          FineLimitMethod AS FineLimitMethodTranslated
        FROM config_finegroups
      `),
            Translate: ['FineLimitMethodTranslated'],
            Language: Language,
        }, {
            valueMapper: {
                FineLimitDuration(input) {
                    return input?.substring(1).split(':').slice(0, -1).join(':');
                },
                // EnableFineLimitResetTime(input: string) {
                //   return input === '1'
                // },
                FineLimitResetTime(input) {
                    return input?.substring(1).split(':').slice(0, -1).join(':');
                },
            }
        });
        return rows;
    }
    async create(options) {
        const found = await this.find(options);
        if (found.Rows.length > 0) {
            AError.handleSilent(`db entry already exists! config_finegroups.FineGroupCode=${options.FineGroupCode}`, 'FineGroupCode Already Exists');
            return false;
        }
        const ModificationUser = stateService.User;
        const ModificationDevice = _.getSession().DeviceName;
        await requestService.query({
            Query: (`
        INSERT INTO config_finegroups (FineGroupCode, FineGroupText, FineLimitDuration, FineLimitResetTime, FineLimitMethod, ModificationUser, ModificationDevice)
        VALUES (:FineGroupCode, :FineGroupText, :FineLimitDuration, :FineLimitResetTime, :FineLimitMethod, :ModificationUser, :ModificationDevice)
      `),
            Params: { ...options, ...{ ModificationUser, ModificationDevice } }
        });
        return true;
    }
    async update(options) {
        const found = await this.find(options);
        if (found.Rows.length === 0) {
            AError.handleSilent(`Couldn't find db entry config_finegroups.FineGroupCode=${options.FineGroupCode}`, 'FineGroupCode Not Found');
            return false;
        }
        const ModificationUser = stateService.User;
        const ModificationDevice = _.getSession().DeviceName;
        await requestService.query({
            Query: (`
        UPDATE config_finegroups
        SET
          FineGroupText=:FineGroupText,
          FineLimitDuration=:FineLimitDuration,
          FineLimitResetTime=:FineLimitResetTime,
          FineLimitMethod=:FineLimitMethod,
          ModificationUser=:ModificationUser,
          ModificationDevice=:ModificationDevice
        WHERE FineGroupCode=:FineGroupCode
      `),
            Params: { ...options, ...{ ModificationUser, ModificationDevice } }
        });
        return true;
    }
    async delete(options) {
        await requestService.query({
            Query: (`DELETE FROM config_finegroups WHERE FineGroupCode=:FineGroupCode`),
            Params: {
                FineGroupCode: options.FineGroupCode
            }
        });
    }
}
