import { hoursToHHMMSS, AFormatNumber, ARound, AShowTable } from "../utils/tools.js";
import { AKpiBlock } from "./AKpiBlock.js";
export class AKpiBlockTimeDistrubution extends AKpiBlock {
    get tableId() { return `${this.idFilter}-Table`; }
    constructor(opt) {
        super({
            refreshImplemented: true,
            optionsImplemented: true,
            TranslateTitle: true,
            ...opt,
        });
    }
    async fetchTravelData(params) {
        // const additionalWhere = '' //(params.DetectionDeviceId !== '%') ? 'AND DetectionDeviceId=:DetectionDeviceId' : ''
        const response = await Loading.waitForPromises(requestService.fetch({
            AssertValues: true,
            Query: (`
          SELECT
            SUM(TravelDuration) / 3600 AS OperationHours,
            SUM(IF((EnforcingLeft > 0 or EnforcingRight > 0 ), TravelDuration, 0)) / 3600 AS ScanHours,
            SUM(TravelDistance) / 1000 AS DistanceKM
          FROM waysegment_entries
          WHERE :ToDate > FromDateTime AND :FromDate < ToDateTime
        `),
            Params: params
        }));
        if (response.isEmpty) {
            return { 'OperationHours': { formatted: '-', value: 0 }, 'ScanHours': { formatted: '-', value: 0 }, 'DistanceKM': { formatted: '-', value: 0 } };
        }
        const { OperationHours, ScanHours, DistanceKM } = response.First;
        return {
            'OperationHours': { value: OperationHours, formatted: hoursToHHMMSS(OperationHours) },
            'ScanHours': { value: ScanHours, formatted: hoursToHHMMSS(ScanHours) },
            'DistanceKM': { value: DistanceKM, formatted: AFormatNumber(ARound(DistanceKM, 2)) + ' km' },
        };
    }
    async refresh(filters) {
        const travelData = await Loading.waitForPromises(this.fetchTravelData(filters));
        this.refreshTable({
            data: [{
                    name: `ScansHours`,
                    value: travelData.ScanHours.formatted
                }, {
                    name: `OperationHours`,
                    value: travelData.OperationHours.formatted
                }, {
                    name: 'DistanceTravelled',
                    value: travelData.DistanceKM.formatted
                }]
        });
    }
    async refreshTable(opt) {
        const translationKeys = opt.data.map(v => v.name);
        const translations = await Translate.get(translationKeys);
        const data = opt.data.map(v => Object.assign({}, v, { name: translations[v.name] }));
        if (this.grid) {
            const scrollTop = this.grid.storeScroll();
            this.grid.store.data = data;
            this.grid.restoreScroll(scrollTop);
        }
        else {
            this.grid = AShowTable({
                appendTo: this.tableId,
                columns: [{
                        field: 'name',
                        text: await Translate.get(`System Metrics`),
                    }, {
                        field: 'value',
                        text: '',
                        htmlEncode: false,
                        renderer: ({ record, value }) => {
                            return isNaN(value) ? record['originalData'].value : value === Infinity ? '-' : value;
                        }
                    }],
                data: data
            });
        }
    }
    async render() {
        return await super.renderView({
            title: this.Name,
            viewHtml: ( /*html*/`
        <div class="fh pb-2">
          <div id="${this.tableId}" class="fh bryntum-no-header"></div>
        </div>
      `)
        });
    }
}
