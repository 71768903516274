import { AWindowBase } from "../classes/windows/AWindowBase.js";
import { AEngine } from "../core/AEngine.js";
import { EVENTS } from "../services/AEventService.js";
import { ATimeService } from "../services/ATimeService.js";
import { AShowTable, TransformObjectsToResponse, AConvertToGridData } from "../utils/tools.js";
export class AUnitTestResultsWindow extends AWindowBase {
    async configureToolbar() {
        return null;
    }
    constructor(opt) {
        super();
        this.opt = opt;
        this.timeService = AEngine.get(ATimeService);
        this.prefetch = this.genColumns();
        this.prefetch.then((columns) => {
            this.columns = columns;
        });
    }
    async genColumns() {
        const columns = [
            { field: 'href' },
            { field: 'hasFilters' },
            { field: 'initType' },
            { field: 'loadTime' },
            { field: 'refreshResult' },
            { field: 'refreshType' },
            { field: 'err' },
        ];
        await Translate.get(columns.map(c => c.field)).then((t) => {
            columns.map(c => c.text = t[c.field]);
        });
        return columns;
    }
    async update(data) {
        const res = await TransformObjectsToResponse(Object.keys(data).map((k) => {
            return Object.assign({ href: k }, data[k]);
        }));
        this.grid.data = AConvertToGridData(res);
        return res;
    }
    async show() {
        await this.prefetch;
        let id = idAllocatorService.getNextId({ prefix: 'win-table-' });
        await super.generate({
            content: ( /*html*/`<div id="${id}" class="fw fh" style=""></div>`),
            alignTo: 'center',
            size: { width: 700, height: 320 },
            minSize: { width: 506, height: 295 },
            lifeCycle: 'CONTINUOUS'
        });
        this.grid = AShowTable({
            appendTo: id,
            aci: {
                resizeToFit: true,
                resizeToFitReverse: true
            },
            columns: this.columns,
            data: []
        });
        return window;
    }
    invokeClose() {
        this.handleClose();
    }
    handleClose() {
        this.$internal.remove();
        Events.off(EVENTS.SESSION_UPDATE_DATA, this.eventId);
        this.handler.destruct();
    }
}
