import { AConfig } from "../../classes/AConfig.js";
import { AError } from "../../classes/AError.js";
import { COLUMN_ACTION, COLUMN_DATETIME, COLUMN_HIDDEN, COLUMN_TEXT, DATA_DATETIME } from "../../classes/AGridTypes.js";
import { AEngine } from "../../core/AEngine.js";
import { AFormInstance } from "../../core/form/AFormInstance.js";
import { AUserOrm } from "../../orm/AUserOrm.js";
import { AOrm } from "../../orm/_AOrm.js";
import { ALERTS, ALERT_BUTTONS, ALERT_STATUS, ALERT_TITLES } from "../../services/AAlertService.js";
import { ATimeService } from "../../services/ATimeService.js";
import { AConvertToGridColumns, AConvertToGridData, AShowTable, AUrlEncodedImageFromBase64, createArray } from "../../utils/tools.js";
const timeService = AEngine.get(ATimeService);
export class APage {
    constructor() {
        this.translations = {};
        this.orm = new AUserOrm();
        $('#create-user').on('click', _ => this.createForm());
    }
    async init() {
        this.translations = await Loading.waitForPromises(Translate.get([
            'None',
            'New Account',
            'Edit Account',
            'edit two factor authentication',
            'require new authenticator code',
            'one time authenticator code',
            'Please fill out all required fields',
            'Apply',
            'Cancel'
        ]));
        await timeService.prefetch();
        await this.refresh();
    }
    get defaultGroup() {
        return this.translations['None'];
    }
    get minPasswordLength() {
        return AConfig.get(`general.security.useStrictPasswordPolicy`) === true ? 10 : 6;
        // return configService.get('general.security.useStrictPasswordPolicy') ? 10 : 6
    }
    async genFormInputs(record) {
        const inputs = [
            { id: 'User', label: 'Username', type: 'text', minlength: 1, maxlength: 254, disabled: record !== undefined },
            {
                id: 'UnsaltedPass', label: 'Password', type: 'text', minlength: this.minPasswordLength, maxlength: 64,
                overrideHasError: ($inp, hasError) => {
                    const str = $inp.val();
                    if (str.length === 0 && record !== undefined) {
                        return false;
                    }
                    return (record === undefined) && hasError;
                },
            },
            { type: 'seperator' },
            { id: 'FirstName', type: 'text', minlength: 1, maxlength: 255, width: 'col-6' },
            { id: 'LastName', type: 'text', minlength: 1, maxlength: 255, width: 'col-6' },
            { id: 'Function', label: 'Job Title', type: 'text', minlength: 1, maxlength: 255, width: 'col-6' },
            { id: 'ExternalId', type: 'text', minlength: 0, maxlength: 15, width: 'col-6' },
            { type: 'seperator' },
            {
                id: 'UserGroups',
                type: 'multiselect',
                disallowNone: true,
                options: Usergroups.map(g => {
                    return {
                        id: g.UserGroup,
                        text: g.UserGroupText,
                        checked: (record) ? ((record.UserGroups || []).includes(g.UserGroup) || record.UserGroups === null) : false
                    };
                })
            },
        ];
        return inputs;
    }
    async genForm(record) {
        const formInputs = await this.genFormInputs(record);
        const form = new AFormInstance({
            ignoreWildcards: false,
            formInputs: formInputs,
        });
        await form.generate({ translate: true, wrapInColumns: true, alignTop: true });
        await form.injectFormData({ formData: record ?? this.orm.emptyModel });
        await form.initFormValidation();
        // TODO: Implement hints inside AFormInstance class!
        const $textInputArr = form.$form().find('[aci-type="text"]').toArray().map(ele => $(ele));
        $textInputArr.map($inp => {
            const minlength = $inp.attr('minlength');
            if (minlength !== undefined) {
                const min = Number(minlength);
                $inp.after(`<p class="form-input-hint">Field requires atleast ${min} characters!</p>`);
            } /* else {
              $inp.after(`<p class="form-input-hint"></p>`)
            }*/
        });
        return form;
    }
    /**
     * @deprecated
     */
    async changePasswordModal(record) {
        const events = Alerts.show({
            title: ALERT_TITLES.None,
            buttons: ALERT_BUTTONS.saveCancel,
            content: ( /*html*/`
        <input type="text" id="UnsaltedPassword" name="UnsaltedPassword" minlength="10" placeholder="Password" class="form-input" value="">
      `)
        });
        const $pw = events.$ele.find('#UnsaltedPassword');
        events.on(ALERT_STATUS.ON_ACTION_PROCEED, async () => {
            const UnsaltedPass = $pw.val();
            await this.orm.update({ User: record.User }, { User: record.User, UnsaltedPass });
        });
    }
    async handleAuthBtn(record) {
        const t = this.translations;
        // AuthenticatorMustInit AuthenticatorInitCode AuthenticatorKey AuthenticatorQR
        const twoFactorSettings = await this.fetch2FA(record.User);
        const { AuthenticatorMustInit, AuthenticatorInitCode, AuthenticatorQR, html } = this.createAuthHtml(twoFactorSettings);
        const dialogHtml = (`
      <div class="form-group">
        <label class="form-switch">
          <input type="checkbox" id="twoFactorAuthentication" ${AuthenticatorMustInit ? 'checked="checked"' : ''} ${AuthenticatorQR ? '' : 'disabled="disabled"'}>
          <i class="form-icon"></i> ${t['require new authenticator code']}
        </label>
      </div>
      <div class="form-group">
        <label class="form-label" for="authenticatorCode">${t['one time authenticator code']}</label>
        <input class="form-input" type="text" id="authenticatorCode" placeholder="000000" value="${AuthenticatorInitCode}" maxlength="6" ${AuthenticatorMustInit ? '' : 'disabled'}>
      </div>
      ${html}
    `);
        const events = Alerts.show({
            translatedTitle: t['edit two factor authentication'],
            content: dialogHtml,
            buttons: ALERT_BUTTONS.okCancel
        });
        const { $ele } = events;
        $ele.find('#twoFactorAuthentication').on('change', function () {
            const $authCode = $ele.find('#authenticatorCode');
            // @ts-ignore
            if (this.checked) {
                $authCode.removeAttr('disabled');
                $authCode.val(PageScript.genOneTimeAuthCode());
            }
            else {
                $authCode.attr('disabled', 'disabled');
            }
        });
        events.on(ALERT_STATUS.ON_ACTION_PROCEED, () => {
            const mustInit = $('#twoFactorAuthentication').prop('checked');
            const initCode = $('#authenticatorCode').val();
            this.updateQRSettings(record.User, mustInit, initCode);
        });
    }
    genOneTimeAuthCode(AUTH_MAX_LENGTH = 6) {
        return createArray(AUTH_MAX_LENGTH, 0).map(() => Math.floor(Math.random() * 10)).join('');
    }
    updateQRSettings(user, mustInit, initCode) {
        return Loading.waitForPromises(requestService.query({
            Query: 'UPDATE users SET AuthenticatorMustInit=:MustInit, AuthenticatorInitCode=:InitCode WHERE User=:User',
            Params: {
                MustInit: mustInit,
                InitCode: initCode,
                User: user
            }
        }));
    }
    createAuthHtml(values) {
        const { AuthenticatorQR } = values;
        if (!AuthenticatorQR) {
            return Object.assign(values, { html: '' });
        }
        const htmlImage = (`
      <div style="position: relative; width: 100%; height: auto; padding: 16px;">
        <img src="${AUrlEncodedImageFromBase64(AuthenticatorQR)}" onclick="PageScript.enlargeImage(event)" style="width: calc(100% - 8px); height: calc(100% - 8px); cursor: pointer" />
      </div>
    `);
        return Object.assign(values, { html: htmlImage });
    }
    enlargeImage(e) {
        let src = $(e.target).attr('src');
        $('<div>').css({
            background: 'RGBA(0,0,0,.5) url(' + src + ') no-repeat center',
            backgroundSize: 'contain',
            width: '100%', height: '100%',
            position: 'fixed',
            zIndex: '10000',
            top: '0', left: '0',
            cursor: 'zoom-out'
        }).click(function () {
            $(this).remove();
        }).appendTo('body');
    }
    fetch2FA(user) {
        return Loading.waitForPromises(requestService.query({
            Query: `SELECT AuthenticatorMustInit, AuthenticatorInitCode, AuthenticatorKey, AuthenticatorQR FROM users WHERE User=:User LIMIT :Limit`,
            Params: {
                User: user,
                Limit: 1
            }
        })).then((res) => {
            return {
                AuthenticatorMustInit: res.Rows[0][0],
                AuthenticatorInitCode: res.Rows[0][1],
                AuthenticatorKey: res.Rows[0][2],
                AuthenticatorQR: res.Rows[0][3]
            };
        });
    }
    async createSelectOptions(record) {
        const allUsergroups = await permissionService.fetchAllUsergroupsFlat();
        const selected = record?.UserGroups || [];
        return ( /*HTML*/`
      <div class="form-group usergroup-options">
        <div class="columns">
          ${allUsergroups.Rows.map(usergroup => {
            return ( /*HTML*/`
                <label class="form-checkbox col-12">
                  <input type="checkbox" value="${usergroup[0]}"
                    ${selected.includes(usergroup[0]) ? 'checked="checked"' : ''}>
                  <i class="form-icon"></i> ${usergroup[1]}
                </label>
              `);
        }).join('')}
        </div>
      </div>
    `);
    }
    /**
     * @deprecated
     * // TODO: Remove entire function
     */
    async genFormDeprecated(opt) {
        const html = await Loading.waitForPromises(requestService.translateDom(/*html*/ `
      <form id="${opt.id}" class="form-group" style="margin: 0 0.4rem;">
        <div class="columns">
          <div class="column col-12">
            <div class="form-group">
              <label class="form-label" for="username">*Username</label>
              <input ${opt.record ? `disabled="disabled"` : ''} class="form-input" autocomplete="off" type="text" id="username" name="User" placeholder="Username" minlength="3" maxlength="254">
              <p class="form-input-hint">Field requires a minimum of 3 characters!</p>
            </div>
          </div>
          <div class="column col-12">
            <div class="form-group">
              <label class="form-label" for="password">*Password</label>
              <input class="form-input" autocomplete="off" type="text" id="password" name="UnsaltedPass" placeholder="Password" minlength="10" maxlength="64">
              <p class="form-input-hint">Field requires atleast 10 characters!</p>
            </div>
          </div>

          <div class="column col-12 col-mx-auto divider"></div>

          <div class="column col-6">
            <div class="form-group">
              <label class="form-label" for="firstName">*First Name</label>
              <input class="form-input" autocomplete="off" type="text" id="firstName" name="FirstName" placeholder="First Name" minlength="3" maxlength="255">
              <p class="form-input-hint">Field requires atleast 3 characters!</p>
            </div>
          </div>
          
          <div class="column col-6">
            <div class="form-group">
              <label class="form-label" for="lastName">*Last Name</label>
              <input class="form-input" autocomplete="off" type="text" id="lastName" name="LastName" placeholder="Last Name" minlength="3" maxlength="255">
              <p class="form-input-hint">Field requires atleast 3 characters!</p>
            </div>
          </div>
          <div class="column col-6">
            <div class="form-group">
              <label class="form-label" for="func">*Job Title</label>
              <input class="form-input" autocomplete="off" type="text" id="func" name="Function" placeholder="Job Title" minlength="3" maxlength="255">
              <p class="form-input-hint">Field requires atleast 3 characters!</p>
            </div>
          </div>
          
          <div class="column col-6">
            <div class="form-group">
              <label class="form-label" for="externalId">External id</label>
              <input class="form-input" autocomplete="off" type="text" id="externalId" name="ExternalId" placeholder="External id" maxlength="15">
            </div>
          </div>
          
          <div class="column col-12 col-mx-auto divider"></div>
        </div>

        
        <label class="form-label column col-auto" for="Usergroups">Usergroups</label>
        <div late-init="true" id="Usergroups" name="Usergroups" class="wrapper-dropdown dd-disallow-none copycat noselect">
          <span>None</span>
          <ul class="dropdown c-scroll"></ul>
        </div>

        <div class="column col-12 col-mx-auto divider"></div>
        
        <label class="form-label" for="usergroup">Usergroups</label>         
        ${await this.createSelectOptions(opt.record)}
      </form>
    `.replace(/\s\s+/g, ' ')));
        return html;
    }
    async createForm() {
        const form = await this.genForm();
        const $form = form.$form();
        const events = Alerts.show({
            translatedTitle: this.translations['New Account'],
            content: $form,
            buttons: ALERT_BUTTONS.createCancel
        });
        events.on(ALERT_STATUS.ON_ACTION_PROCEED, async () => {
            if (!form.validate()) {
                Alerts.incomplete();
                return false;
            }
            const formData = form.extractFormData({ cleanData: true, setInternalFormData: true });
            return await Loading.waitForPromises(this.orm.create(formData).then((res) => this.handleCreate(res, formData.User))).catch(AError.handle);
        });
    }
    async handleCreate(res, User) {
        if (!res.success) {
            Alerts.show({
                title: ALERT_TITLES.Error,
                content: res.msg.join('<br>\n'),
                buttons: ALERT_BUTTONS.ok,
                type: ALERTS.Error
            });
            return false;
        }
        await this.refresh(User);
    }
    async editForm(record) {
        const form = await this.genForm(record);
        const $form = form.$form();
        const events = Alerts.show({
            translatedTitle: this.translations['Edit Account'],
            content: $form,
            buttons: ALERT_BUTTONS.okCancel,
        });
        events.on(ALERT_STATUS.ON_ACTION_PROCEED, () => {
            if (!form.validate()) {
                Alerts.incomplete();
                return false;
            }
            const { changes, model } = AOrm.getChangesModel(form);
            if (Object.keys(model).length === 0) {
                return false;
            }
            Loading.waitForPromises(this.orm.update(record, model).then(() => this.refresh(record.User))).catch(AError.handle);
        });
    }
    refresh(user) {
        return Loading.waitForPromises(this.orm.fetchAll()).then(async (ares) => {
            ares.addColumns(['Auth', 'EditUser', 'DeleteUser']);
            this.grid = AShowTable({
                appendTo: 'table-bryntum',
                aci: {
                    flex: 1,
                    resizeToFit: false,
                },
                columns: AConvertToGridColumns(ares, {
                    'DisplayName': COLUMN_TEXT,
                    'User': COLUMN_TEXT,
                    'Pass': COLUMN_HIDDEN,
                    'UserGroups': {
                        htmlEncode: false,
                        renderer: (opt) => opt.value.join(', '),
                    },
                    'Function': COLUMN_TEXT,
                    'ExternalId': COLUMN_TEXT,
                    'FirstSeen': { ...COLUMN_DATETIME, ...COLUMN_HIDDEN },
                    'LastSeen': {
                        type: 'date',
                        htmlEncode: false,
                        renderer: (opt) => {
                            return (opt.value && opt.value.getTime() !== 0) ? timeService.agoSync(new Date(), opt.value) : '';
                        }
                    },
                    'LastAttempt': COLUMN_DATETIME,
                    'FirstName': COLUMN_HIDDEN,
                    'LastName': COLUMN_HIDDEN,
                    'Auth': COLUMN_ACTION({
                        iconCls: 'fa-regular fa-key text-grey',
                        btnCls: 'btn-white',
                        onClick: ({ record }) => {
                            this.handleAuthBtn(record.originalData);
                        }
                    }),
                    'EditUser': COLUMN_ACTION({
                        iconCls: 'fa-solid fa-pencil text-primary',
                        btnCls: 'btn-white',
                        onClick: (context) => {
                            const record = context.record;
                            // this.handleEditUserBtn(record)
                            this.editForm(record.originalData).catch(AError.handle);
                        }
                    }),
                    'DeleteUser': COLUMN_ACTION({
                        iconCls: 'fa-regular fa-trash text-red',
                        btnCls: 'btn-white',
                        onClick: async (context) => {
                            const record = context.record;
                            const message = await Translate.get(`Are you sure you want to delete { X }`);
                            const events = Alerts.show({
                                title: ALERT_TITLES.Warning,
                                content: message.replace(`{ X }`, record.originalData.DisplayName),
                                buttons: ALERT_BUTTONS.yesNo
                            });
                            events.on(ALERT_STATUS.ON_ACTION_PROCEED, async () => {
                                let hasErr = false;
                                try {
                                    // await this.deleteUser(record.User)
                                    await Loading.waitForPromises(this.orm.delete(record.originalData).then(() => this.refresh()));
                                }
                                catch (err) {
                                    hasErr = true;
                                    AError.handle(err);
                                }
                                finally {
                                    return !hasErr;
                                }
                            });
                        }
                    }),
                }),
                data: AConvertToGridData(ares, {
                    'FirstSeen': DATA_DATETIME,
                    'LastSeen': DATA_DATETIME,
                    'LastAttempt': DATA_DATETIME
                })
            });
            if (user !== undefined) {
                const rec = this.grid.store.records.find((value, i) => value.User === user);
                if (rec !== undefined) {
                    this.grid.selectedRecord = rec;
                }
            }
        }).catch(AError.handle);
    }
}
export function render() {
    return ( /*html*/`
    <div class="bryntum-container has-footer-2">
      <div id="table-bryntum"></div>
    </div>
    <div class="footer aci columns">
      <div class="column col-3 col-ml-auto">
        <button id="create-user" class="btn btn-primary col-12">
          <i class="fa-solid fa-plus"></i>
          Create User
        </button>
      </div>
    </div>
  `);
}
