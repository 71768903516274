export class AUnitTest {
    constructor({ period }) {
        this.period = period;
        this.error = null;
    }
    getId() {
        return this.constructor.name;
    }
    getUnitTest() {
        const id = this.getId();
        if (!globalThis._unitTests) {
            globalThis._unitTests = {};
        }
        if (!globalThis._unitTests.hasOwnProperty(id)) {
            globalThis._unitTests[id] = { _setups: [], _tests: [] };
        }
        return globalThis._unitTests[id];
    }
    addSetup(setup) {
        const testCfg = this.getUnitTest();
        testCfg._setups.push(setup);
    }
    addTest(test) {
        const testCfg = this.getUnitTest();
        testCfg._tests.push(test);
    }
    assertCorrectConfiguration() {
        const { _setups, _tests } = this.getUnitTest();
        if (!_setups || !_tests) {
            throw new Error(`AUnitTest has not been initialized!`);
        }
        if (_setups.length !== 1) {
            throw new Error(`AUnitTest requires exactly one function with the @beforeTests decorator!`);
        }
        if (_tests.length === 0) {
            throw new Error(`AUnitTests requires atleast one function with the @test decorator!`);
        }
    }
    selectTimeFrame() {
        switch (this.period) {
            case "week":
                const { startOfWeek, endOfWeek } = FilterManager;
                filterService.setFilterSettings({ FromDate: startOfWeek, endOfMonth: endOfWeek });
                break;
            case "month":
                const { startOfMonth, endOfMonth } = FilterManager;
                filterService.setFilterSettings({ FromDate: startOfMonth, endOfMonth: endOfMonth });
                break;
            default:
                break;
        }
    }
}
