import { AResponse } from "../classes/AResponse.js";
import { AEngine } from "../core/AEngine.js";
import { toast } from "../utils/toasts.js";
import { AUserManualOrm } from "./AUserManualOrm.js";
export class AFakeUserManualOrm extends AUserManualOrm {
    constructor() {
        super();
        AEngine.warn("USING Fake UserManualOrm!", this);
    }
    async fetchAll() {
        return new AResponse({
            Columns: ["MarkdownId", "Lang", "Title", "Body", "CreatedByUser", "CreatedByDisplayName", "CreatedAt", "Id_Mr"],
            ColumnsTranslated: ["MarkdownId", "Lang", "Title", "Body", "CreatedByUser", "CreatedByDisplayName", "CreatedAt", "Id_Mr"],
            Rows: []
        });
    }
    async create(options) {
        toast({ msg: 'Feature not available for this runtime environment!' });
        return false;
    }
    async link(id_mr, markdownId) {
        toast({ msg: 'Feature not available for this runtime environment!' });
    }
    async unlink(options) {
        toast({ msg: 'Feature not available for this runtime environment!' });
    }
}
