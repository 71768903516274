import { AResponse } from "../classes/AResponse.js";
import { AEngine } from "../core/AEngine.js";
import { toast } from "../utils/toasts.js";
import { AUploadedImageOrm } from "./AUploadedImageOrm.js";
export class AFakeUploadedImageOrm extends AUploadedImageOrm {
    constructor() {
        super();
        AEngine.warn("USING Fake UserManualOrm!", this);
    }
    async fetchAll() {
        return new AResponse({
            Columns: ["ImageId", "SmallImage", "AltText", "MimeType", "FileSize", "CreatedAt"],
            ColumnsTranslated: ["ImageId", "SmallImage", "AltText", "MimeType", "FileSize", "CreatedAt"],
            Rows: []
        });
    }
    async fetch(options) {
        toast({ msg: 'Feature not available for this runtime environment!' });
        return [];
    }
    async create(options) {
        toast({ msg: 'Feature not available for this runtime environment!' });
        return false;
    }
}
