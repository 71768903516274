// @ts-nocheck
function TextEncoderLite() { }
;
function TextDecoderLite() { }
;
function utf8ToBytes(a, b) {
    b = b || Infinity;
    for (var c, d = a.length, e = null, f = [], g = 0; g < d; g++) {
        if (c = a.charCodeAt(g), !(55295 < c && 57344 > c))
            e && (-1 < (b -= 3) && f.push(239, 191, 189), e = null);
        else if (e) {
            if (56320 > c) {
                -1 < (b -= 3) && f.push(239, 191, 189), e = c;
                continue;
            }
            else
                c = 65536 | (e - 55296 << 10 | c - 56320), e = null;
        }
        else if (56319 < c) {
            -1 < (b -= 3) && f.push(239, 191, 189);
            continue;
        }
        else if (g + 1 === d) {
            -1 < (b -= 3) && f.push(239, 191, 189);
            continue;
        }
        else {
            e = c;
            continue;
        }
        if (128 > c) {
            if (0 > (b -= 1))
                break;
            f.push(c);
        }
        else if (2048 > c) {
            if (0 > (b -= 2))
                break;
            f.push(192 | c >> 6, 128 | 63 & c);
        }
        else if (65536 > c) {
            if (0 > (b -= 3))
                break;
            f.push(224 | c >> 12, 128 | 63 & c >> 6, 128 | 63 & c);
        }
        else if (2097152 > c) {
            if (0 > (b -= 4))
                break;
            f.push(240 | c >> 18, 128 | 63 & c >> 12, 128 | 63 & c >> 6, 128 | 63 & c);
        }
        else
            throw new Error('Invalid code point');
    }
    return f;
}
function utf8Slice(a, b, c) { var d = '', e = ''; c = Math.min(a.length, c || Infinity), b = b || 0; for (var f = b; f < c; f++)
    127 >= a[f] ? (d += decodeUtf8Char(e) + String.fromCharCode(a[f]), e = '') : e += '%' + a[f].toString(16); return d + decodeUtf8Char(e); }
function decodeUtf8Char(a) { try {
    return decodeURIComponent(a);
}
catch (b) {
    return String.fromCharCode(65533);
} }
TextEncoderLite.prototype.encode = function (a) { var b; return b = 'undefined' == typeof Uint8Array ? utf8ToBytes(a) : new Uint8Array(utf8ToBytes(a)), b; },
    TextDecoderLite.prototype.decode = function (a) { return utf8Slice(a, 0, a.length); };
export { TextEncoderLite, TextDecoderLite };
