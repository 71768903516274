import { ACrypto } from "../../classes/ACrypto.js";
import { CHART_TYPE } from "../../classes/ADetectionStatistics.js";
import { AError } from "../../classes/AError.js";
import { COLUMN_ACTION } from "../../classes/AGridTypes.js";
import { AEngine } from "../../core/AEngine.js";
import { ATemplates } from "../../core/ATemplateService.js";
import { AStatisticsService } from "../../services/AStatisticsService.js";
import { AStatisticsSimpleService } from "../../services/AStatisticsSimpleService.js";
import { ARound, AShowTable } from "../../utils/tools.js";
export class APage {
    constructor() {
        FilterManager.load();
        this.ignoreDetectionsOutsideSegment = false;
        $('#RefreshButton').on('click', _ => this.refresh().catch(AError.handle));
    }
    async init() { }
    calculateColumnWidth(columnArray, add = 2) {
        return ARound(100.0 / (columnArray.length + add), 2) + '%';
    }
    async createChart(name, columnName) {
        const streetData = this.statistics[name];
        const formulaHtml = await streetData.createFormulaHtml({
            category: columnName,
        });
        const option = streetData.getStatsChartOption(columnName);
        PageScript.chart = await streetData.createChart({
            id: ACrypto.md5String(name),
            title: name,
            category: columnName
                .replace('Detections', CHART_TYPE.VERIFICATION)
                .replace('Suspects', CHART_TYPE.VERIFICATION)
                .replace('FollowUps', CHART_TYPE.VERIFICATION)
                .replace('Sanctions', CHART_TYPE.VERIFICATION),
            formulaHtml
        }, option);
    }
    get tableColumnArray() {
        return [
            { field: 'Detections', type: 'number' },
            { field: 'AverageOccupiedSpaces', type: 'number' },
            { field: 'Capacity', type: 'number' },
            { field: 'Occupancy', type: 'apercent' },
            { field: 'VisitorRate', type: 'apercent' },
            { field: 'PermitRate', type: 'apercent' },
            { field: 'Compliancy', type: 'apercent' },
            { field: 'CompliancyVisitor', type: 'apercent' },
            { field: 'EnforcementIntensity', type: 'number' },
        ];
    }
    /**
     * Transforms data for table
     */
    dynamicDataToRowData(name, streetEntry) {
        const Name = name.replace(/,/g, '|'), Detections = streetEntry.getTotal(), Occupancy = streetEntry.getOccupancy(), VisitorRate = streetEntry.getVisitorRate(), PermitRate = streetEntry.getPermitRate(), Compliancy = streetEntry.getCompliancy(), CompliancyVisitor = streetEntry.getCompliancyVisitors(), EnforcementIntensity = streetEntry.getEnforcementIntensity(), Capacity = streetEntry.getCapacity(), AverageOccupiedSpaces = streetEntry.Occupancy.Options.Capacity.Options.Occupied.Count ?? null;
        return {
            Name,
            Detections,
            Occupancy: Occupancy !== null ? ARound(Occupancy * 100, 2) : null,
            VisitorRate: VisitorRate !== null ? ARound(VisitorRate * 100, 2) : null,
            PermitRate: PermitRate !== null ? ARound(PermitRate * 100, 2) : null,
            Compliancy: Compliancy !== null ? ARound(Compliancy * 100, 2) : null,
            CompliancyVisitor: CompliancyVisitor !== null ? ARound(CompliancyVisitor * 100, 2) : null,
            EnforcementIntensity: EnforcementIntensity !== null ? ARound(EnforcementIntensity, 2) : null,
            Capacity: Capacity !== null ? ARound(Capacity, 2) : null,
            AverageOccupiedSpaces: AverageOccupiedSpaces !== null ? ARound(AverageOccupiedSpaces) : null
        };
    }
    async refresh() {
        const filters = FilterManager.saveExplicit();
        FilterManager.setActive(false);
        let geoHashMap = { map: {}, imap: {} };
        let mapTo = (segmentId) => segmentId;
        if (filters.MapToGeo !== undefined) {
            geoHashMap = await Loading.waitForPromises(AEngine.get(AStatisticsService).fetchGeoConnectionNames({
                FromGeoType: 'Segment',
                ToGeoType: filters.MapToGeo,
            }));
            mapTo = (segmentId) => geoHashMap.map[segmentId] || null;
        }
        const { statistics, processedDetections, skippedDetections, rowCounts } = await Loading.waitForPromises(AEngine.get(AStatisticsSimpleService).fetch(filters, {
            mapTo,
            ignoreDetectionsOutsideSegment: this.ignoreDetectionsOutsideSegment
        }));
        // console.log('rowCount', rowCounts)
        // const warnings: any = []
        // for (let segmentId of (geoHashMap?.map ? Object.keys(geoHashMap!.map) : Object.keys(statistics))) {
        //   let mappedKey = mapTo(segmentId)
        //   if (mappedKey !== null) {
        //     if (statistics.hasOwnProperty(mappedKey)) {
        //       statistics[mappedKey].addEntryPerGroup()
        //     }
        //   } else {
        //     warnings.push(`${segmentId}'`)
        //   }
        // }
        // if (warnings.length) {
        //   console.warn(`Couldn't link ${warnings.length} SegmentIds to streetnames \n`, warnings)
        // }
        FilterManager.setActive(true);
        if (Object.keys(statistics).length === 0) {
            return Alerts.noResults();
        }
        const width = this.calculateColumnWidth(this.tableColumnArray);
        const data = Object.keys(statistics).map(mappedKey => {
            // const geoId = imap[mappedKey]
            const streetEntry = statistics[mappedKey];
            return this.dynamicDataToRowData(mappedKey, streetEntry);
        });
        this.grid = AShowTable({
            // aci: {
            //   overrideFooterText: ({ length, showLimit, limit }) => {
            //     const allDetections = processedDetections + skippedDetections
            //     return `Viewing ${length} Streets (${processedDetections} of the ${allDetections} detections mapped to a street)`
            //   }
            // },
            appendTo: 'table-bryntum',
            aci: {
                resizeToFitReverse: true
            },
            columns: [
                // {
                //   field: 'details',
                //   type: 'widget',
                //   cellCls: 'np-i',
                //   widgets: [{
                //     type: 'button',
                //     icon: 'fa fa-info',
                //     cls: 'b-blue b-raised',
                //     onAction: async ({ source: btn }) => {
                //       Loading.waitForPromises(this.createChart(btn.cellInfo.record.Name, 'Compliancy'))
                //     }
                //   }]
                // },
                {
                    field: 'Name',
                    text: await Loading.waitForPromises(Translate.get(filters.MapToGeo || 'SegmentId')),
                    width
                },
                ...(await Loading.waitForPromises(this.tableColumnArray.map(async (columnData) => {
                    return Object.assign({
                        text: await Translate.get(columnData.field),
                        width,
                        lowThreshold: -1,
                        showValue: true
                    }, columnData);
                }))),
                {
                    field: 'details',
                    ...COLUMN_ACTION({
                        onClick: ({ record }) => {
                            Loading.waitForPromises(this.createChart(record.originalData.Name, 'Compliancy'));
                        },
                        iconCls: 'fa-solid fa-info text-primary',
                        btnCls: 'btn-white'
                    }),
                    // 'ActionDown': COLUMN_ACTION({ iconCls: 'fa-solid fa-chevron-down', btnCls: 'btn-white' }),
                }
            ],
            data: data
        });
        this.grid.on('cellclick', (a) => {
            const { record, column } = a;
            const { Name } = record;
            const { field } = column;
            if (Object.values(CHART_TYPE).indexOf(field) === -1 && field !== 'EnforcementIntensity') {
                return console.warn(`CHART_TYPE "${field}" doesn't have a chart variant!`);
            }
            Loading.waitForPromises(this.createChart(Name, field)).catch(AError.handle);
        });
        this.statistics = statistics;
    }
}
export function render() {
    return ( /*html*/`
    <div id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column c-scroll col-12">
        <div class="form-group">
          <label class="form-label" for="FromDate">From</label>
          <input class="form-input" type="date" id="FromDate" required="required">
          <input class="form-input" type="time" id="FromTime" required="required">
        </div>
        <div class="form-group">
          <label class="form-label" for="ToDate">To</label>
          <input class="form-input" type="date" id="ToDate" required="required">
          <input class="form-input" type="time" id="ToTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="MapToGeo">Map To</label>
          <select id="MapToGeo" class="form-select">
            <option value="Street">Street</option>
            <option value="Area">Area</option>
            <option value="Zone">Zone</option>
            <option value="%">All</option>
  
            ${ /*GeoSegmentConnections.map(c => {
      return (`<option value="${c.ToGeoType}">${c.FromGeoType} => ${c.ToGeoType}</option>`)
    }).join('')*/''}
          </select>
        </div>

        <div class="form-group">
          <label class="form-label" for="AreaMulti">Area</label>
          <div id="AreaMulti" class="copycat noselect dd-disallow-none" maxlength="18">
            <span>None</span>
            <ul class="dropdown c-scroll"></ul>
          </div>
        </div>

        <div class="form-group">
          <label class="form-label" for="ZoneMulti">Zone</label>
          <div id="ZoneMulti" class="copycat noselect dd-disallow-none" maxlength="18">
            <span>None</span>
            <ul class="dropdown c-scroll"></ul>
          </div>
        </div>
        
      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </div>
    <div id="Rapport" class="flex-child bryntum-container has-footer-2">
      <div template="${ATemplates.WaitingForInput}"></div>
      <div id="table-bryntum" class="hidden"></div>

      <div class="columns footer aci">
        <div class="column col-2">
          <div id="count" class="text">Viewing <span>0</span> Streets</div>
        </div>
        <div class="column col-2 col-ml-auto">
          <button id="export" class="btn btn-primary col-12" disabled="disabled">Export</button>
        </div>
      </div>
    </div>
  `);
}
