export var MAP_POSITION;
(function (MAP_POSITION) {
    MAP_POSITION[MAP_POSITION["TOP_LEFT"] = 0] = "TOP_LEFT";
    MAP_POSITION[MAP_POSITION["TOP_RIGHT"] = 1] = "TOP_RIGHT";
    MAP_POSITION[MAP_POSITION["BOTTOM_LEFT"] = 2] = "BOTTOM_LEFT";
    MAP_POSITION[MAP_POSITION["BOTTOM_RIGHT"] = 3] = "BOTTOM_RIGHT";
})(MAP_POSITION || (MAP_POSITION = {}));
export var MAP_OPTIONS;
(function (MAP_OPTIONS) {
    MAP_OPTIONS[MAP_OPTIONS["Region"] = 2] = "Region";
    MAP_OPTIONS[MAP_OPTIONS["Area"] = 4] = "Area";
    MAP_OPTIONS[MAP_OPTIONS["RouteArea"] = 8] = "RouteArea";
    MAP_OPTIONS[MAP_OPTIONS["Segment"] = 16] = "Segment";
    MAP_OPTIONS[MAP_OPTIONS["Zone"] = 32] = "Zone";
    MAP_OPTIONS[MAP_OPTIONS["ParkingSpace"] = 64] = "ParkingSpace";
    MAP_OPTIONS[MAP_OPTIONS["GeneratedParkingSpace"] = 128] = "GeneratedParkingSpace";
    MAP_OPTIONS[MAP_OPTIONS["SplitParkingSpace"] = 256] = "SplitParkingSpace";
    MAP_OPTIONS[MAP_OPTIONS["DirectedWaySegment"] = 512] = "DirectedWaySegment";
    MAP_OPTIONS[MAP_OPTIONS["WaySegment"] = 1024] = "WaySegment";
    MAP_OPTIONS[MAP_OPTIONS["RouteOption"] = 2048] = "RouteOption";
    MAP_OPTIONS[MAP_OPTIONS["RouteIntersection"] = 4096] = "RouteIntersection";
    MAP_OPTIONS[MAP_OPTIONS["Address"] = 8192] = "Address";
    MAP_OPTIONS[MAP_OPTIONS["TemporaryZone"] = 16384] = "TemporaryZone";
    MAP_OPTIONS[MAP_OPTIONS["ParkingMachine"] = 32768] = "ParkingMachine";
    MAP_OPTIONS[MAP_OPTIONS["ImageLocation"] = 65536] = "ImageLocation";
    MAP_OPTIONS[MAP_OPTIONS["All"] = -1] = "All";
    MAP_OPTIONS[MAP_OPTIONS["None"] = 0] = "None";
    MAP_OPTIONS[MAP_OPTIONS["Default"] = 122990] = "Default";
})(MAP_OPTIONS || (MAP_OPTIONS = {}));
export const ALL_MAP_OPTIONS = [
    MAP_OPTIONS.Region,
    MAP_OPTIONS.Area,
    MAP_OPTIONS.RouteArea,
    MAP_OPTIONS.Zone,
    MAP_OPTIONS.TemporaryZone,
    MAP_OPTIONS.ParkingSpace,
    MAP_OPTIONS.ParkingMachine,
    MAP_OPTIONS.Address,
    MAP_OPTIONS.Segment,
    MAP_OPTIONS.WaySegment,
    MAP_OPTIONS.DirectedWaySegment,
    MAP_OPTIONS.SplitParkingSpace,
    MAP_OPTIONS.GeneratedParkingSpace,
    MAP_OPTIONS.ImageLocation,
    MAP_OPTIONS.RouteOption,
    MAP_OPTIONS.RouteIntersection,
];
export const densityOptions = ['high', 'normal', 'low', 'hidden'];
export const mapStyleOptions = [
    { id: '0', text: 'Default' },
    { id: '1', text: 'Greyscale Light' },
    { id: '2', text: 'Greyscale Dark' },
    { id: '3', text: 'Solarized Dark' },
    { id: '4', text: 'Dark Orange' },
    { id: '5', text: 'Mustard' },
];
export const ALL_GEO_TYPES = ALL_MAP_OPTIONS.map(id => MAP_OPTIONS[id]);
export const ALL_GEO_MAPPING = ALL_MAP_OPTIONS.map((opt, i) => ({ mapOption: opt, geoType: ALL_GEO_TYPES[i] }));
export const UNLOAD_OPTIONS = {
    Default: 0,
    AllListeners: 1,
    HardDelete: 2,
    None: 3
};
