import { EVENTS } from "../services/AEventService.js";
export class ALoopTimer {
    constructor(callbackfn, options) {
        this.id = -1;
        this.callbackfn = callbackfn;
        this.options = options;
    }
    start() {
        if (this.id === -1) {
            this.id = setInterval(this.callbackfn, this.options.timeout);
            if (this.options.loopLifeCycle === "PAGE") {
                Events.h_once(EVENTS.DESTRUCT, () => this.stop());
            }
        }
        return this;
    }
    stop() {
        if (this.id !== -1) {
            clearInterval(this.id);
            this.id = -1;
        }
        return this;
    }
    restart() {
        this.stop();
        return this.start();
    }
    restartWith(options) {
        this.stop();
        Object.assign(this.options, options || {});
        return this.start();
    }
    changeTimeout(timeout) {
        const options = Object.assign({}, this.options, { timeout });
        return this.restartWith(options);
    }
}
