import { AChartTooltipFormatter, MakeColumnChart, QueryResponseToSeries, ResizeChart } from "./charts.js";
import { DetectionsFullRanged } from "../utils/query.js";
import { ADetectionState, ADigital, AIllegallyParked, AParkingRight, ATimeLimitedParking, AVerification } from "../classes/AUnificationTypes.js";
import { EVENTS } from "../services/AEventService.js";
import { ADynamicChartUtils } from "./ADynamicChartUtils.js";
export class ADynamicChartSnapshotDetections {
    get options() { return this.cachedOptions; }
    static genOptions() {
        return Object.keys(ADynamicChartSnapshotDetections.selections).map(key => {
            return ( /*HTML*/`<option value="${key}">${key.substring(6)}</option>`);
        }).join('');
    }
    constructor(chartId, opt) {
        this.chartId = chartId;
        this.opt = opt;
        this.chartUtils = new ADynamicChartUtils();
        Events.on(EVENTS.CONTENT_RESIZE, this.resize.bind(this));
    }
    isMounted() {
        return this.chart !== undefined && Object.keys(this.chart).length > 0 && this.chart.container.isConnected === true;
    }
    resize() {
        ResizeChart(this.chart, this.opt.calcDimensions(), false, { silentErrors: true });
    }
    setTitle(title) {
        this.chart?.setTitle({ text: title });
    }
    /**
     * Generates selection columns for second query
     * @returns string containing all columns for the second & final query, comma seperated
     */
    makeBarParts({ Key, Items, IncludeTotals }) {
        let result = [];
        for (let i = 0; i < Items.length; i++) {
            result.push(`SUM(IF(${Key} = '${Items[i]}',1,0)) + 0E0 as \`${Items[i]}\` `);
        }
        if (IncludeTotals || Items.length === 0) {
            result.push(' SUM(1.0) + 0E0 as `Total`');
        }
        return result.join(',\r\n');
    }
    async fetchHorizontal(filters, opt) {
        const { Query, OrderBy } = opt;
        const response = await requestService.query({
            Query: Query + " ORDER BY " + OrderBy,
            Name: 'FetchHorizontal',
            Params: filters
        });
        return Object.assign({}, opt, {
            Items: response.Rows.map(row => row[0]),
            ...filters
        });
    }
    async fetchChartData(opt, chartOpt) {
        const { IncludeTotals, OnlyAllowFinalized, IgnoreOutsideSegment, Horizontal, Vertical, Filters } = chartOpt;
        const { filterOpt, hSelection, vSelection } = opt;
        let BarParts = this.makeBarParts({ ...hSelection, IncludeTotals });
        let Bars = vSelection.Key;
        const conditions = [
            'DetectionDevice LIKE :DeviceName',
            (OnlyAllowFinalized === true) ? 'FinalizedSuccess = 1' : null,
            (IgnoreOutsideSegment === true) ? 'SegmentTimeStamp IS NOT NULL' : null,
            // TODO: Don't use exact value from HTML
            ...(Filters ?? []).filter(f => Array.isArray(f.Values)).map(f => {
                return `${f.Field} ${(f.Operator) === 'EQUALS' ? 'IN' : 'NOT IN'} ('${f.Values.join(`', '`)}')`;
            })
        ].filter(v => v !== null);
        const additionalWhere = conditions?.length ? (' AND ' + conditions.join(' AND ')) : '';
        const translateBars = vSelection.Translate;
        let chartData = await requestService.query({
            Query: (`
        SELECT
          IFNULL(NULLIF(${Bars}, ''), 'Unknown') as Bars,
          ${BarParts}
        FROM (${DetectionsFullRanged}) full
        LEFT JOIN config_channels ch ON (full.VerificationChannel = ch.ChannelCode)
        WHERE ${Bars} IS NOT NULL ${additionalWhere}
        GROUP BY Bars
        ORDER BY Bars
        `),
            // ORDER BY ${vSelection.OrderBy}
            Params: filterOpt,
            Translate: translateBars ? ["Bars"] : [],
            Language: Language,
        });
        let meta = undefined;
        if (hSelection.Translate === false && hSelection.TransformData) {
            const transformed = await hSelection.TransformData(this, this.chartUtils).apply(this.chartUtils, [{ res: chartData, key: Horizontal, isHorizontal: true, IncludeTotals }]);
            chartData = transformed.data;
            meta = transformed.meta;
            // res = await ADynamicChartSnapshot.parseUnifications({ key: Horizontal, res: res, isHorizontal: true })
        }
        if (vSelection.Translate === false && vSelection.TransformData) {
            const transformed = await vSelection.TransformData(this, this.chartUtils).apply(this.chartUtils, [{ res: chartData, key: Vertical, isHorizontal: false, IncludeTotals }]);
            chartData = transformed.data;
            meta = transformed.meta;
            // res = await ADynamicChartSnapshot.parseUnifications({ key: Vertical, res: res, isHorizontal: false })
        }
        return { chartData, meta };
    }
    async update(chartOpt, filterOpt, predicate) {
        this.cachedOptions = { chartOpt, filterOpt };
        const { Horizontal, Vertical, ChartType, Inverted, Polar, IgnoreOutsideSegment, OnlyAllowFinalized, ShowLegend } = chartOpt;
        const { selections } = ADynamicChartSnapshotDetections;
        const hSelection = await this.fetchHorizontal(filterOpt, selections[Horizontal]);
        const vSelection = Object.assign({}, selections[Vertical]);
        const { chartData, meta } = await this.fetchChartData({
            filterOpt,
            hSelection,
            vSelection,
        }, chartOpt);
        const hasData = (predicate !== undefined) ? predicate(chartData) : true;
        if (!hasData) {
            return { hasData, chartData, instantiated: false };
        }
        const categories = hSelection.Translate || hSelection.TransformData ? chartData.ColumnsTranslated.slice(1) : chartData.Columns.slice(1);
        const series = meta?.series ?? QueryResponseToSeries(chartData, { chartType: ChartType, meta });
        const unit = Translate.getCacheFast('detections');
        const chart = this.chart;
        const isMounted = this.isMounted();
        if (!isMounted) {
            this.chart = await MakeColumnChart({
                series: series,
                drilldown: meta?.drilldown,
                // categories: meta?.drilldown === undefined ? categories : undefined,
                allowExport: this.opt?.allowExport ?? true,
                title: '',
                bind: this.chartId,
                type: ChartType,
                flat: true,
                unit: unit,
                inverted: Inverted,
                polar: Polar,
                // translate: hSelection.Translate,
                xAxis: { categories },
                yAxis: { title: { text: unit } },
                tooltip: {
                    formatter: await AChartTooltipFormatter({ unit, addPercentage: true, hideZeroValues: true }),
                },
                legend: {
                    enabled: ShowLegend ?? true
                },
                ...vSelection,
            });
        }
        else {
            chart.update({
                chart: {
                    inverted: Inverted,
                    polar: Polar,
                    type: ChartType
                },
                series: series.map(s => {
                    // If new serie is added, make sure it's visible!
                    const foundSerie = chart?.series?.find(oldSerie => oldSerie.name === s.name);
                    if (foundSerie !== undefined) {
                        s.visible = foundSerie.visible ?? true;
                    }
                    return s;
                }),
                xAxis: { categories },
                yAxis: { title: { text: unit } },
                tooltip: {
                    formatter: await AChartTooltipFormatter({ unit, addPercentage: true, hideZeroValues: true })
                },
                legend: {
                    enabled: ShowLegend ?? true
                },
            }, true, true, true);
        }
        return { hasData, chartData, instantiated: !isMounted };
        // this.grid = AShowTable({
        //   appendTo: 'table-bryntum',
        //   columns: AConvertToGridColumns(chartData, { 'Bars': { text: $('#Vertical option:selected').text() } }),
        //   data: AConvertToGridData(chartData)
        // })
        // $('#print').prop('disabled', false)
        // FilterManager.setActive(true)
    }
    destroy() {
        this.chart?.destroy();
        delete this.chart;
    }
}
ADynamicChartSnapshotDetections.templates = {
    'ParkingRight': AParkingRight,
    'IllegallyParked': AIllegallyParked,
    'Verification': AVerification,
    'Digital': ADigital,
    'TimeLimitedParking': ATimeLimitedParking,
    'DetectionState': ADetectionState
};
ADynamicChartSnapshotDetections.selections = {
    // Name, Key is field                                  
    // Chart_All: { Items: [], Key: "'All Scans'", Query: "SELECT IF(DetectionId>0,'All Scans','All Scans') as 'All Scans' FROM (" + DetectionsFullRanged + ") full", OrderBy: "1", Translate: true },
    Chart_All: {
        Items: [],
        Key: "'All Scans'",
        Query: "SELECT 'All Scans'",
        OrderBy: "1",
        Translate: true
    },
    // SELECT
    //   SUM(TravelDuration) / 3600 AS OperationInHours,
    //   SUM( IF((EnforcingLeft > 0 or EnforcingRight > 0 ), TravelDuration, 0) ) / 3600 AS ScanInHours,
    //   SUM(TravelDistance) / 1000 AS DistanceInKM
    // FROM waysegment_entries
    // WHERE :ToDate > FromDateTime AND :FromDate < ToDateTime
    // Chart_OperationHours: {
    // Chart_AverageSpeed: {
    //   Items: [],
    //   Key: "FLOOR(AverageSpeed / 20.0) * 20.0",
    //   Query: "SELECT FLOOR(AverageSpeed / 20.0) * 20.0 FROM (" + DetectionsFullRanged + ") full INNER JOIN waysegment_entries USING (DetectionDeviceId) WHERE DetectionDevice IS NOT NULL GROUP BY FLOOR(AverageSpeed / 20.0) * 20.0",
    //   OrderBy: "FLOOR(AverageSpeed / 20.0) * 20.0",
    //   Translate: false
    // },
    Chart_RouteArea: {
        Items: [],
        Key: "RouteArea",
        Query: "SELECT IFNULL(NULLIF(RouteArea, ''), 'Unknown') FROM (" + DetectionsFullRanged + ") full GROUP BY IFNULL(NULLIF(RouteArea, ''), 'Unknown')",
        OrderBy: "RouteArea",
        Translate: false
    },
    Chart_Areas: {
        Items: [],
        Key: "Area",
        Query: "SELECT IFNULL(NULLIF(Area, ''), 'Unknown') FROM (" + DetectionsFullRanged + ") full GROUP BY IFNULL(NULLIF(Area, ''), 'Unknown')",
        OrderBy: "Area",
        Translate: false
    },
    Chart_Zones: {
        Items: [],
        Key: "Zone",
        Query: "SELECT IFNULL(NULLIF(Zone, ''), 'Unknown') FROM (" + DetectionsFullRanged + ") full GROUP BY IFNULL(NULLIF(Zone, ''), 'Unknown')",
        OrderBy: "Zone",
        Translate: false
    },
    Chart_ParkingSpaces: {
        Items: [],
        Key: "ParkingSpace",
        Query: "SELECT ParkingSpace FROM (" + DetectionsFullRanged + ") full GROUP BY ParkingSpace",
        OrderBy: "ParkingSpace",
        Translate: true
    },
    Chart_ScanDevice: {
        Items: [],
        Key: "DetectionDevice",
        Query: "SELECT DetectionDevice FROM (" + DetectionsFullRanged + ") full WHERE DetectionDevice IS NOT NULL GROUP BY DetectionDevice",
        OrderBy: "DetectionDevice",
        Translate: true
    },
    Chart_ScanDeviceType: {
        Items: [],
        Key: "CASE WHEN DetectionDevice LIKE 'ScanAuto%' THEN 'ScanAuto' WHEN DetectionDevice LIKE 'PDA%' THEN 'PDA' WHEN DetectionDevice LIKE 'CentralVerification%' THEN 'CentralVerification' WHEN DetectionDevice LIKE 'BackOffice%' THEN 'BackOffice' ELSE DetectionDevice END",
        Query: "SELECT CASE WHEN DetectionDevice LIKE 'ScanAuto%' THEN 'ScanAuto' WHEN DetectionDevice LIKE 'PDA%' THEN 'PDA' WHEN DetectionDevice LIKE 'CentralVerification%' THEN 'CentralVerification' WHEN DetectionDevice LIKE 'BackOffice%' THEN 'BackOffice' ELSE DetectionDevice END FROM (" + DetectionsFullRanged + ") full WHERE DetectionDevice IS NOT NULL GROUP BY CASE WHEN DetectionDevice LIKE 'ScanAuto%' THEN 'ScanAuto' WHEN DetectionDevice LIKE 'PDA%' THEN 'PDA' WHEN DetectionDevice LIKE 'CentralVerification%' THEN 'CentralVerification' WHEN DetectionDevice LIKE 'BackOffice%' THEN 'BackOffice' ELSE DetectionDevice END",
        OrderBy: "CASE WHEN DetectionDevice LIKE 'ScanAuto%' THEN 'ScanAuto' WHEN DetectionDevice LIKE 'PDA%' THEN 'PDA' WHEN DetectionDevice LIKE 'CentralVerification%' THEN 'CentralVerification' WHEN DetectionDevice LIKE 'BackOffice%' THEN 'BackOffice' ELSE DetectionDevice END",
        Translate: true
    },
    Chart_HasParkingRights: {
        Items: [],
        Key: "IF(HasParkingRight is NULL, 'Unknown', IF(HasParkingRight, 'Right', 'No Right'))",
        Query: "SELECT IF(HasParkingRight is NULL, 'Unknown', IF(HasParkingRight, 'Right', 'No Right')) FROM (" + DetectionsFullRanged + ") full  GROUP BY HasParkingRight",
        OrderBy: "HasParkingRight",
        Translate: false
    },
    Chart_IsIllegallyParked: {
        Items: [],
        Key: "IF(IsIllegallyParked is NULL, 'Unknown', IF(IsIllegallyParked, 'Illegally Parked', 'Legally Parked'))",
        Query: "SELECT IF(IsIllegallyParked is NULL, 'Unknown', IF(IsIllegallyParked, 'Illegally Parked', 'Legally Parked')) FROM (" + DetectionsFullRanged + ") full  GROUP BY IsIllegallyParked",
        OrderBy: "IsIllegallyParked",
        Translate: false
    },
    Chart_ParkingRightTypes: {
        Items: [],
        Key: "ParkingRightType",
        Query: "SELECT ParkingRightType FROM (" + DetectionsFullRanged + ") full  WHERE ParkingRightType IS NOT NULL GROUP BY ParkingRightType",
        OrderBy: "ParkingRightType",
        Translate: true
    },
    Chart_CountryCode: {
        Items: [],
        Key: "CountryCode",
        Query: "SELECT IFNULL(CountryCode, 'Unknown') FROM (" + DetectionsFullRanged + ") full GROUP BY CountryCode",
        OrderBy: "CountryCode",
        Translate: true
    },
    Chart_VerifyResults: {
        Items: [],
        Key: "VerificationResult",
        Query: "SELECT VerificationResult FROM (" + DetectionsFullRanged + ") full WHERE VerificationResult IS NOT NULL GROUP BY VerificationResult",
        OrderBy: "VerificationResult",
        Translate: true
    },
    Chart_GpsPrecision: {
        Items: [],
        Key: "GpsPrecisionGroup",
        Query: "SELECT GpsPrecisionGroup FROM (" + DetectionsFullRanged + ") full WHERE GpsPrecisionGroup IS NOT NULL GROUP BY GpsPrecisionGroup",
        OrderBy: "GpsPrecisionGroup",
        Translate: false
    },
    Chart_DetectionUsers: {
        Items: [],
        Key: "DetectionUser",
        Query: "SELECT DetectionUser FROM (" + DetectionsFullRanged + ") full WHERE DetectionUser IS NOT NULL and LENGTH(DetectionUser)>0 GROUP BY DetectionUser",
        OrderBy: "DetectionUser",
        Translate: false
    },
    Chart_VerificationUsers: {
        Items: [],
        Key: "VerificationUser",
        Query: "SELECT VerificationUser FROM (" + DetectionsFullRanged + ") full WHERE VerificationUser IS NOT NULL and LENGTH(VerificationUser)>0 GROUP BY VerificationUser",
        OrderBy: "VerificationUser",
        Translate: false
    },
    Chart_VerificationDevices: {
        Items: [],
        Key: "FinalVerificationDevice",
        Query: "SELECT FinalVerificationDevice FROM detections_final full WHERE FinalVerificationDevice IS NOT NULL and LENGTH(FinalVerificationDevice)>0 GROUP BY FinalVerificationDevice",
        OrderBy: "FinalVerificationDevice",
        Translate: false
    },
    Chart_CameraIds: {
        Items: [],
        Key: "BestCameraId",
        Query: "SELECT BestCameraId FROM (" + DetectionsFullRanged + ") full WHERE BestCameraId IS NOT NULL GROUP BY BestCameraId",
        OrderBy: "BestCameraId",
        Translate: false
    },
    Chart_Visitors: {
        Items: [],
        Key: "Visitor",
        Query: "SELECT Visitor FROM (" + DetectionsFullRanged + ") full WHERE Visitor IS NOT NULL  GROUP BY Visitor",
        OrderBy: "Visitor",
        Translate: true
    },
    Chart_Days: {
        Items: [],
        Key: "Day",
        Query: "SELECT Day FROM (" + DetectionsFullRanged + ") full WHERE Day IS NOT NULL GROUP BY Day",
        OrderBy: "Day",
        Translate: false
    },
    Chart_Weeks: {
        Items: [],
        Key: "Week",
        Query: "SELECT Week FROM (" + DetectionsFullRanged + ") full WHERE  Week IS NOT NULL GROUP BY Week",
        OrderBy: "Week",
        Translate: false
    },
    Chart_Months: {
        Items: [],
        Key: "Month",
        Query: "SELECT Month FROM (" + DetectionsFullRanged + ") full WHERE Month IS NOT NULL GROUP BY Month",
        OrderBy: "Month",
        Translate: true
    },
    Chart_WeekDay: {
        Items: [],
        Key: "WeekDay",
        Query: "SELECT WeekDay FROM (" + DetectionsFullRanged + ") full WHERE weekday IS NOT NULL GROUP BY WeekDay",
        OrderBy: "WeekdayNumber",
        Translate: true
    },
    Chart_Hour: {
        Items: [],
        Key: "HourOfDay",
        Query: "SELECT HourOfDay AS Hour FROM detections_final WHERE DetectionTime IS NOT NULL GROUP BY HourOfDay",
        OrderBy: "HourOfDay",
        // Translate: true
        Translate: false,
        TransformData: (snapshot, chartUtils) => chartUtils.parseHour.bind(chartUtils)
    },
    // TODO: Implement ScanVehicleSpeed
    // Chart_VehicleSpeed: {
    //   Items: [],
    //   Key: "FLOOR(VehicleSpeed / 20.0) * 20.0",
    //   Query: "SELECT IFNULL(FLOOR(VehicleSpeed / 20.0) * 20.0, 0) as VehicleSpeed FROM (" + DetectionsFullRanged + ") full WHERE DetectionTime IS NOT NULL GROUP BY FLOOR(VehicleSpeed / 20.0) * 20",
    //   OrderBy: "FLOOR(VehicleSpeed / 20.0) * 20.0",
    //   Translate: false,
    //   TransformData: (snapshot, chartUtils) => chartUtils!.parseRange.bind(chartUtils!)
    // },
    Chart_VerificationChannel_Full: {
        Items: [],
        Key: "VerificationChannel",
        Query: `SELECT IFNULL(VerificationChannel, 'No Channel') AS VerificationChannel FROM detections_final full INNER JOIN config_channels ON (VerificationChannel=ChannelCode) GROUP BY VerificationChannel`,
        OrderBy: 'IFNULL(VerificationChannel, "")',
        Translate: false,
        TransformData: (snapshot, chartUtils) => chartUtils.parseVerificationChannel.bind(chartUtils)
    },
    Chart_OffenceCode: {
        Items: [],
        Key: "OffenceCode",
        Query: "SELECT OffenceCode FROM (" + DetectionsFullRanged + ") full WHERE VerificationUser IS NOT NULL AND LENGTH(VerificationUser)>0 GROUP BY OffenceCode",
        OrderBy: "OffenceCode",
        Translate: false
    },
    // Chart_SanctionStatus: {
    //   Items: [],
    //   Key: `IF(Verification BETWEEN ${new AVerification().Options.Fined.FirstIndex} AND ${new AVerification().Options.Fined.LastIndex}, 'Fined', 'Other')`,
    //   Query: `SELECT IF(Verification BETWEEN ${new AVerification().Options.Fined.FirstIndex} AND ${new AVerification().Options.Fined.LastIndex}, 'Fined', 'Other') AS SanctionStatus FROM (${DetectionsFullRanged}) full WHERE VerificationUser IS NOT NULL AND LENGTH(VerificationUser)>0 GROUP BY IF(Verification BETWEEN ${new AVerification().Options.Fined.FirstIndex} AND ${new AVerification().Options.Fined.LastIndex}, 'Fined', 'Other')`,
    //   OrderBy: "Verification",
    //   Translate: false
    // },
    // TODO: Replace DetectionsFullRanged with detections_final in the future!
    Chart_ParkingRight: {
        Items: [],
        Key: "ParkingRight",
        Query: `SELECT ParkingRight FROM (${DetectionsFullRanged}) full GROUP BY ParkingRight`,
        OrderBy: 'ParkingRight',
        Translate: false,
        TransformData: (snapshot, chartUtils) => chartUtils.parseUnifications.bind(chartUtils)
    },
    Chart_Verification: {
        Items: [],
        Key: "Verification",
        Query: `SELECT Verification FROM (${DetectionsFullRanged}) full GROUP BY Verification`,
        OrderBy: 'Verification',
        Translate: false,
        TransformData: (snapshot, chartUtils) => chartUtils.parseUnifications.bind(chartUtils)
    },
    Chart_Digital: {
        Items: [],
        Key: "Digital",
        Query: `SELECT Digital FROM (${DetectionsFullRanged}) full GROUP BY Digital`,
        OrderBy: 'Digital',
        Translate: false,
        TransformData: (snapshot, chartUtils) => chartUtils.parseUnifications.bind(chartUtils)
    },
    Chart_TimeLimitedParking: {
        Items: [],
        Key: "TimeLimitedParking",
        Query: `SELECT TimeLimitedParking FROM (${DetectionsFullRanged}) full GROUP BY TimeLimitedParking`,
        OrderBy: 'TimeLimitedParking',
        Translate: false,
        TransformData: (snapshot, chartUtils) => chartUtils.parseUnifications.bind(chartUtils)
    },
    Chart_IllegallyParked: {
        Items: [],
        Key: "IllegallyParked",
        Query: `SELECT IllegallyParked FROM (${DetectionsFullRanged}) full GROUP BY IllegallyParked`,
        OrderBy: 'IllegallyParked',
        Translate: false,
        TransformData: (snapshot, chartUtils) => chartUtils.parseUnifications.bind(chartUtils)
    },
    Chart_ParkingRight_Full: {
        Items: [],
        Key: "ParkingRight",
        Query: `SELECT IFNULL(ParkingRight, 'Unknown') FROM detections_final full GROUP BY IFNULL(ParkingRight, 'Unknown')`,
        OrderBy: 'ParkingRight',
        Translate: true
    },
    Chart_Verification_Full: {
        Items: [],
        Key: "Verification",
        Query: `SELECT IFNULL(Verification, 'Unknown') FROM detections_final full GROUP BY IFNULL(Verification, 'Unknown')`,
        OrderBy: 'Verification',
        Translate: true
    },
    Chart_Digital_Full: {
        Items: [],
        Key: "Digital",
        Query: `SELECT IFNULL(Digital, 'Unknown') FROM detections_final full GROUP BY IFNULL(Digital, 'Unknown')`,
        OrderBy: 'Digital',
        Translate: true
    },
    Chart_TimeLimitedParking_Full: {
        Items: [],
        Key: "TimeLimitedParking",
        Query: `SELECT IFNULL(TimeLimitedParking, 'Unknown') FROM detections_final full GROUP BY IFNULL(TimeLimitedParking, 'Unknown')`,
        OrderBy: 'TimeLimitedParking',
        Translate: true
    },
    Chart_IllegallyParked_Full: {
        Items: [],
        Key: "IllegallyParked",
        Query: `SELECT IFNULL(IllegallyParked, 'Unknown') FROM detections_final full GROUP BY IFNULL(IllegallyParked, 'Unknown')`,
        OrderBy: 'IllegallyParked',
        Translate: true
    },
    Chart_DetectionState_Full: {
        Items: [],
        Key: "DetectionState",
        Query: `SELECT IFNULL(DetectionState, 'Unknown') FROM detections_final full GROUP BY IFNULL(DetectionState, 'Unknown')`,
        OrderBy: 'DetectionState',
        Translate: true
    },
    Chart_DetectionState_InProgress: {
        Items: [],
        Key: "DetectionState",
        Query: `SELECT IFNULL(DetectionState, 'Unknown') FROM detections_final full GROUP BY IFNULL(DetectionState, 'Unknown')`,
        OrderBy: 'DetectionState',
        Translate: false,
        TransformData: (snapshot, chartUtils) => chartUtils.parseDetectionState.bind(chartUtils)
    },
};
