import { AError } from "./AError.js";
export async function AGetServerChartOptions(translationMap) {
    const translations = translationMap || await Translate.get([
        "Detecting",
        "Scans",
        "Speed",
        "Verifications",
        "GeoQueue",
        "ImageQueue",
        "PRDBQueue",
        "PdaVerificationQueue",
        "CentralVerificationQueue",
        "AssignedPdaVerifications",
        "AssignedCentralVerifications",
        "StorageQueue",
        "Memory"
    ]);
    const colors = ['#3366cc', '#dc3912', '#ff9900', '#109618', '#990099', '#0099c6', '#dd4477', '#66aa00', '#b82e2e', '#316395', '#994499', '#22aa99', '#aaaa11', '#6633cc', '#e67300', '#8b0707', '#651067', '#329262', '#5574a6', '#3b3eac', '#b77322', '#16d620', '#b91383', '#f4359e', '#9c5935', '#a9c413', '#2a778d', '#668d1c', '#bea413', '#0c5922', '#743411'];
    const output = [
        {
            title: translations["GeoQueue"],
            min: 0.,
            format: `{value} ${translations['Scans']}`,
            unit: translations['Scans'],
            color: colors.shift(),
            fill: true,
            opacity: 1.,
            lineWidth: .8,
            yAxe: 0
        },
        {
            title: translations["ImageQueue"],
            min: 0.,
            format: `{value} ${translations['Scans']}`,
            color: colors.shift(),
            fill: true,
            opacity: 1.,
            lineWidth: .8,
            unit: translations['Scans'],
            yAxe: 0
        },
        {
            title: translations["PRDBQueue"],
            min: 0.,
            format: `{value} ${translations['Scans']}`,
            color: colors.shift(),
            fill: true,
            opacity: 1.,
            lineWidth: .8,
            unit: translations['Scans'],
            yAxe: 0
        },
        {
            title: translations["PdaVerificationQueue"],
            min: 0.,
            format: `{value} ${translations['Scans']}`,
            color: colors.shift(),
            fill: true,
            opacity: 1.,
            lineWidth: .8,
            unit: translations['Scans'],
            yAxe: 0
        },
        {
            title: translations["CentralVerificationQueue"],
            min: 0.,
            format: `{value} ${translations['Scans']}`,
            color: colors.shift(),
            fill: true,
            opacity: 1.,
            lineWidth: .8,
            unit: translations['Scans'],
            yAxe: 0
        },
        {
            title: translations["AssignedPdaVerifications"],
            min: 0.,
            format: `{value} ${translations['Scans']}`,
            color: colors.shift(),
            fill: true,
            opacity: 1.,
            lineWidth: .8,
            unit: translations['Scans'],
            yAxe: 0
        },
        {
            title: translations["AssignedCentralVerifications"],
            min: 0.,
            format: `{value} ${await Translate.get('Scans')}`,
            color: colors.shift(),
            fill: true,
            opacity: 1.,
            lineWidth: .8,
            unit: translations['Scans'],
            yAxe: 0
        },
        {
            type: 'spline',
            title: translations["StorageQueue"],
            min: 0.,
            format: '{value} Items',
            color: colors.shift(),
            fill: false,
            opacity: .5,
            lineWidth: .5,
            opposite: true,
            unit: "Items",
            yAxe: 1,
            visible: false
        },
        {
            type: 'spline',
            title: translations["Memory"],
            min: 0.,
            format: '{value} MB',
            color: colors.shift(),
            fill: false,
            opacity: .5,
            lineWidth: .5,
            opposite: true,
            unit: "MB",
            yAxe: 2,
            visible: false
        }
    ];
    return output;
}
export async function AMakeServerTimeChart(Dest, translations) {
    let Options = await AGetServerChartOptions(translations);
    let CurrentTime = Date.now();
    let YAxes = [];
    let Series = [];
    for (let i = 0; i < Options.length; i++) {
        let opt = Options[i];
        let Axe = {
            title: { text: '', style: { color: new Highcharts.Color(opt.color) } },
            visible: opt.showlable,
            floor: opt.min,
            ceiling: opt.max,
            opposite: opt.opposite,
            labels: {
                style: { color: new Highcharts.Color(opt.color) },
                format: opt.labels?.format,
            },
        };
        let hasType = (opt.type !== undefined);
        let Serie = {
            type: hasType ? opt.type : 'area',
            name: opt.title,
            labels: { format: opt.format },
            marker: { symbol: 'circle', radius: 5 },
            data: [],
            fillOpacity: opt.fill ? opt.opacity : 0.,
            lineWidth: opt.lineWidth,
            yAxis: opt.yAxe,
            color: new Highcharts.Color(opt.color).setOpacity(opt.opacity).get(),
            unit: opt.unit,
            floor: opt.min,
            ceiling: opt.max,
            visible: opt.visible === false ? false : true
        };
        YAxes.push(Axe);
        Series.push(Serie);
    }
    const destChart = document.getElementById(Dest);
    let hc = new Highcharts.Chart({
        title: { text: '' },
        chart: {
            renderTo: destChart,
            type: 'area',
            zooming: {
                type: 'x',
            },
            events: { load: function () { this.reflow(); } }
        },
        xAxis: {
            type: 'datetime',
            max: CurrentTime,
            min: CurrentTime - 60 * 60 * 1000,
            tickmarkPlacement: 'on',
            title: { text: '' }
        },
        yAxis: [
            { labels: { format: `{value} ${await Translate.get('scans')}` }, title: { text: null } },
            { labels: { format: `{value} ${await Translate.get('items')}` }, title: { text: null }, opposite: true },
            { labels: { format: `{value} MB` }, title: { text: null }, opposite: true }
        ],
        legend: { enabled: true },
        exporting: { enabled: false },
        plotOptions: {
            areaspline: { marker: { enabled: false } },
            area: {
                stacking: 'stream',
                lineColor: '#666666',
                lineWidth: 1,
                marker: { lineWidth: 1, lineColor: '#666666' }
            }
        },
        tooltip: {
            shared: true,
            useHTML: true,
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            borderWidth: 0,
            padding: 0,
            formatter: function () {
                let parts = [];
                let points = this.points ?? [];
                for (let point of points) {
                    parts.push((`
                <tr>
                  <td><span class="chart-dot" style="background-color: ${point.series.color}"></span> ${point.series.name}: </td>
                  <td><b>${point.y}</b> ${point.series.userOptions.unit}</td>
                </tr>
              `).trim());
                }
                return (`
              <table class="tooltip-chart-table">
                <thead>
                  <tr>
                    <th colspan="2">${new Date(points[0].x).toLocaleString()}</th>
                  </tr>
                </thead>
                <tbody>
                  ${parts.join('')}
                </tbody>
              </table>
            `);
            }
        },
        series: Series
    });
    // hc.onLoadPromise = onLoadPromise
    return hc;
}
export function ARequestServerStats(filters) {
    return requestService.query({
        Name: "ServerChart",
        Query: " SELECT floor(UNIX_TIMESTAMP(T3.Time)/:Interval) * :Interval as Time,"
            + " case when MAX(StorageQueue IS NULL) = 0 THEN 0.0 +max(StorageQueue) END AS StorageQueue,"
            + " case when MAX(GeoQueue IS NULL) = 0 THEN 0.0 +max(GeoQueue) END AS GeoQueue,"
            + " case when MAX(ImageQueue IS NULL) = 0 THEN 0.0 +max(ImageQueue) END AS ImageQueue,"
            + " case when MAX(PRDBQueue IS NULL) = 0 THEN 0.0 +max(PRDBQueue) END AS PRDBQueue,"
            + " case when MAX(PdaVerificationQueue IS NULL) = 0 THEN 0.0 +max(PdaVerificationQueue) END AS PdaVerificationQueue,"
            + " case when MAX(BackOfficeVerificationQueue IS NULL) = 0 THEN 0.0 +max(BackOfficeVerificationQueue) END AS CentralVerificationQueue,"
            + " case when MAX(AssignedPdaVerifications IS NULL) = 0 THEN 0.0 +max(AssignedPdaVerifications) END AS AssignedPdaVerifications,"
            + " case when MAX(AssignedBackofficeVerifications IS NULL) = 0 THEN 0.0 +max(AssignedBackofficeVerifications) END AS AssignedCentralVerifications,"
            + " case when MAX(ProcessMem IS NULL) = 0 THEN ROUND( (0.0 + Max(ProcessMem))/(1024*1024), 1)  END AS Memory"
            + " FROM  ( (select * from serverstats  where Time between :FromDate and :ToDate ) union  (select * from serverstats  where Time < :FromDate order by Time DESC Limit 1) union  (select * from serverstats  where Time > :ToDate order by Time ASC Limit 1) ) t3"
            + " group by floor(UNIX_TIMESTAMP(T3.Time)/:Interval) * :Interval order by Time;",
        Params: filters
    });
}
export function AUpdateServerChart(ServerChart, response, From, To, Interval) {
    if (!response.Success)
        return;
    let ServerData = [[], [], [], [], [], [], [], [], []];
    let LastTime = 0;
    for (let i = 0; i < response.Rows.length; i++) {
        let Time = response.Rows[i][0] * 1000;
        if (i) {
            for (let t = LastTime + Interval; t < Time; t += Interval) {
                ServerData[0].push([t, response.Rows[i - 1][1]]);
                ServerData[1].push([t, response.Rows[i - 1][2]]);
                ServerData[2].push([t, response.Rows[i - 1][3]]);
                ServerData[3].push([t, response.Rows[i - 1][4]]);
                ServerData[4].push([t, response.Rows[i - 1][5]]);
                ServerData[5].push([t, response.Rows[i - 1][6]]);
                ServerData[6].push([t, response.Rows[i - 1][7]]);
                ServerData[7].push([t, response.Rows[i - 1][8]]);
                ServerData[8].push([t, response.Rows[i - 1][9]]);
            }
        }
        ServerData[0].push([Time, response.Rows[i][1]]);
        ServerData[1].push([Time, response.Rows[i][2]]);
        ServerData[2].push([Time, response.Rows[i][3]]);
        ServerData[3].push([Time, response.Rows[i][4]]);
        ServerData[4].push([Time, response.Rows[i][5]]);
        ServerData[5].push([Time, response.Rows[i][6]]);
        ServerData[6].push([Time, response.Rows[i][7]]);
        ServerData[7].push([Time, response.Rows[i][8]]);
        ServerData[8].push([Time, response.Rows[i][9]]);
        LastTime = Time;
    }
    if (response.Rows.length) {
        const now = Date.now();
        for (let t = LastTime + Interval; t <= now; t += Interval) {
            ServerData[0].push([t, response.Rows[response.Rows.length - 1][1]]);
            ServerData[1].push([t, response.Rows[response.Rows.length - 1][2]]);
            ServerData[2].push([t, response.Rows[response.Rows.length - 1][3]]);
            ServerData[3].push([t, response.Rows[response.Rows.length - 1][4]]);
            ServerData[4].push([t, response.Rows[response.Rows.length - 1][5]]);
            ServerData[5].push([t, response.Rows[response.Rows.length - 1][6]]);
            ServerData[6].push([t, response.Rows[response.Rows.length - 1][7]]);
            ServerData[7].push([t, response.Rows[response.Rows.length - 1][8]]);
            ServerData[8].push([t, response.Rows[response.Rows.length - 1][9]]);
        }
    }
    ServerChart.series[0].setData(ServerData[1], false);
    ServerChart.series[1].setData(ServerData[2], false);
    ServerChart.series[2].setData(ServerData[3], false);
    ServerChart.series[3].setData(ServerData[4], false);
    ServerChart.series[4].setData(ServerData[5], false);
    ServerChart.series[5].setData(ServerData[6], false);
    ServerChart.series[6].setData(ServerData[7], false);
    ServerChart.series[7].setData(ServerData[0], false);
    ServerChart.series[8].setData(ServerData[8], false);
    ServerChart.axes[0].setExtremes(From, To, true, false);
}
export function ASetServerChart(ServerChart, From, To, interval = 5) {
    let Interval = interval * (To - From) / (3600000);
    let filters = { FromDate: new Date(From), ToDate: new Date(To), Interval };
    return ARequestServerStats(filters).then((response) => {
        AUpdateServerChart(ServerChart, response, From, To, Interval * 1000);
    }).catch(AError.handle);
}
