import { AError, ERROR_GROUPS } from "../classes/AError.js";
import { AUnifyTreeItem } from "../classes/ATreeBreakdown.js";
import { AEngine } from "../core/AEngine.js";
import { AStatisticsService } from "../services/AStatisticsService.js";
import { createTreeBreakdown } from "../utils/tools.js";
import { AKpiBlock } from "./AKpiBlock.js";
export class AKpiBlockTreeBreakdown extends AKpiBlock {
    get $visual() { return this.$kpiBlock.find('.kpi-breakdown-visual'); }
    constructor(opt) {
        super({
            refreshImplemented: true,
            optionsImplemented: true,
            ...opt
        });
    }
    async genTreeBreakdown(idPrefix, statistics) {
        const Total = statistics["Total"] || 0;
        const _InProgress = statistics["_InProgress"] || 0;
        const _NotProcessed = statistics["_NotProcessed"] || 0;
        const _Compliant = statistics["_Compliant"] || 0;
        const _Suspects = statistics["_Suspects"] || 0;
        const __InProgress = statistics["__InProgress"] || 0;
        const __NotProcessed = statistics["__NotProcessed"] || 0;
        const __FollowUps = statistics["__FollowUps"] || 0;
        const ___Fined = statistics["___Fined"] || 0;
        const ___Reprimanded = statistics["___Reprimanded"] || 0;
        const ___NotFined = statistics["___NotFined"] || 0;
        const ___Discarded = statistics["___Discarded"] || 0;
        if (Total !== _InProgress + _NotProcessed + _Compliant + _Suspects) {
            AError.handleSilent(
            // incorrect: (${Total} != ${_InProgress + _NotProcessed + _Compliant + _Suspects})`,
            `(POSSIBLY MISSING UNIFICATIONS) Tree breakdown for unification doesn't pass unit check! Total (${Total} != ${_InProgress + _NotProcessed + _Compliant + _Suspects})`, ERROR_GROUPS.TreeBreakdownError);
        }
        if (_Suspects !== __InProgress + __NotProcessed + __FollowUps) {
            AError.handleSilent(
            // incorrect: (${_Suspects} != ${__InProgress + __NotProcessed + __FollowUps})`,
            `(POSSIBLY MISSING UNIFICATIONS) Tree breakdown for unification doesn't pass unit check! Suspects (${_Suspects} != ${__InProgress + __NotProcessed + __FollowUps})`, ERROR_GROUPS.TreeBreakdownError);
        }
        if (__FollowUps !== ___Fined + ___NotFined + ___Discarded + ___Reprimanded) {
            AError.handleSilent(
            // incorrect: (${__FollowUps} != ${___Fined + ___NotFined + ___Discarded})`,
            `(POSSIBLY MISSING UNIFICATIONS) Tree breakdown for unification doesn't pass unit check! FollowUps (${__FollowUps} != ${___Fined + ___NotFined + ___Discarded + ___Reprimanded})`, ERROR_GROUPS.TreeBreakdownError);
        }
        const t = await Translate.get([
            'Detections',
            'InProgress',
            'NotProcessed',
            'Compliant',
            'Suspects',
            'FollowUps',
            'Sanctions',
            'Reprimanded',
            'Not Sanctioned',
            'Discarded',
        ]);
        const breakdownHtml = createTreeBreakdown({
            data: new AUnifyTreeItem({ Text: t['Detections'], Count: Total }),
            children: [{
                    data: new AUnifyTreeItem({ Text: t['InProgress'], Count: _InProgress })
                }, {
                    data: new AUnifyTreeItem({ Text: t['NotProcessed'], Count: _NotProcessed })
                }, {
                    data: new AUnifyTreeItem({ Text: t['Compliant'], Count: _Compliant })
                }, {
                    data: new AUnifyTreeItem({ Text: t['Suspects'], Count: _Suspects }),
                    children: [{
                            data: new AUnifyTreeItem({ Text: t['InProgress'], Count: __InProgress }),
                        }, {
                            data: new AUnifyTreeItem({ Text: t['NotProcessed'], Count: __NotProcessed }),
                        }, {
                            data: new AUnifyTreeItem({ Text: t['FollowUps'], Count: __FollowUps }),
                            collapse: true,
                            children: [{
                                    data: new AUnifyTreeItem({ Text: t['Sanctions'], Count: ___Fined }),
                                }, {
                                    data: new AUnifyTreeItem({ Text: t['Reprimanded'], Count: ___Reprimanded }),
                                }, {
                                    data: new AUnifyTreeItem({ Text: t['Not Sanctioned'], Count: ___NotFined }),
                                }, {
                                    data: new AUnifyTreeItem({ Text: t['Discarded'], Count: ___Discarded }),
                                },]
                        },]
                },]
        }, { idPrefix });
        return breakdownHtml;
    }
    async refresh(filters = this.filterOption) {
        const idPrefix = 'kpi-treebreakdown';
        const statistics = await AEngine.get(AStatisticsService).fetchTreeBreakDown(filters);
        const breakdownHtml = await this.genTreeBreakdown(idPrefix, statistics);
        const $newBreakdown = $(breakdownHtml);
        // Load checkbox states from previous tree breakdown
        const $oldBreakdown = $(`.tree_breakdown[prefix="${idPrefix}"]`);
        $oldBreakdown.find('[type="checkbox"]').toArray().map(e => $(e)).map($chk => {
            const id = $chk.attr('id');
            const checked = $chk.prop('checked');
            $newBreakdown.find(`#${id}`).prop('checked', checked);
        });
        this.$visual.html('');
        this.$visual.append($newBreakdown);
    }
    async render() {
        return await super.renderView({
            title: 'TreeBreakdown',
            viewHtml: ( /*html*/`
        <div class="kpi-breakdown-visual"></div>
      `),
        });
    }
}
