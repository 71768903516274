import { AError } from "../../classes/AError.js";
import { COLUMN_BOOLEAN, COLUMN_DATETIME, COLUMN_HIDDEN, COLUMN_IMAGE, COLUMN_NUMBER, COLUMN_TIME, DATA_DATETIME, DATA_IMAGE, DATA_TIME } from "../../classes/AGridTypes.js";
import { AIllegallyParked, AParkingRight, AVerification } from "../../classes/AUnificationTypes.js";
import { AEngine } from "../../core/AEngine.js";
import { ATemplates } from "../../core/ATemplateService.js";
import { ADetectionService } from "../../services/ADetectionService.js";
import { generateWhereClause } from "../../utils/maps.js";
import { DetectionsFullImagesRanged, DetectionsFullReportOptimized, ReportsImageSelectFields, UnificationTypes } from "../../utils/query.js";
import { AConvertToGridColumns, AConvertToGridData, appendResponseRows, AShowTable, generateTreeDropdown } from "../../utils/tools.js";
export class APage {
    constructor() { }
    async init() {
        FilterManager.load();
        await Loading.waitForPromises([
            generateTreeDropdown('#ParkingRight', new AParkingRight()),
            generateTreeDropdown('#Verification', new AVerification()),
            generateTreeDropdown('#IllegallyParked', new AIllegallyParked())
        ]);
        $('#RefreshButton').on('click', _ => FilterManager.showFilterWarning().then(_ => this.refresh().catch(AError.handle)));
    }
    fetchQueryParts(filters) {
        if (filters.Images) {
            return {
                subQuery: DetectionsFullImagesRanged,
                select: ReportsImageSelectFields
            };
        }
        else {
            return {
                subQuery: DetectionsFullReportOptimized,
                select: '*'
            };
        }
    }
    async refresh() {
        const filters = FilterManager.saveExplicit();
        FilterManager.setActive(false);
        const { select, subQuery } = this.fetchQueryParts(filters);
        const whereClause = generateWhereClause({ filters });
        const response = await Loading.waitForPromises(requestService.query({
            Query: (`
          SELECT ${select} FROM (${subQuery}) full
          WHERE ${whereClause}
          LIMIT :Limit
        `),
            Name: `Reports`,
            Params: filters,
            Language: Language,
            Translate: ["Device", "Right", "ParkingArea", "Result", "Moving"].concat(UnificationTypes)
        }));
        FilterManager.setActive(true);
        if (!response.Rows.length) {
            return Alerts.noResults();
        }
        appendResponseRows(response, ['Action1', 'Action2']);
        this.grid = AShowTable({
            appendTo: 'table-bryntum',
            columns: AConvertToGridColumns(response, {
                'DetectionTime': COLUMN_DATETIME,
                'Time': COLUMN_TIME,
                'Confidence': COLUMN_NUMBER,
                'GpsPrecision': COLUMN_NUMBER,
                'FinalVerificationDistanceToVehicle': COLUMN_NUMBER,
                'Image': COLUMN_IMAGE,
                'IsIllegallyParked': COLUMN_BOOLEAN,
                'HasParkingRight': COLUMN_BOOLEAN,
                'DetectionValid': COLUMN_BOOLEAN,
                'FineData': COLUMN_HIDDEN,
                'Action1': {
                    type: 'widget',
                    field: 'Action1',
                    width: 300,
                    cellCls: 'np-i',
                    widgets: [{
                            type: 'button',
                            cls: 'b-blue b-raised',
                            onAction: ({ source }) => {
                                const { cellInfo } = source;
                                if (!cellInfo || !cellInfo.record)
                                    return;
                                const { DetectionId, DetectionDeviceId } = cellInfo.record;
                                if (!DetectionId || !DetectionDeviceId) {
                                    return Alerts.noResults();
                                }
                                AEngine.get(ADetectionService).findHistory({
                                    DetectionDeviceId,
                                    DetectionId
                                });
                            }
                        }]
                },
                'Action2': {
                    type: 'widget',
                    field: 'Action2',
                    width: 300,
                    cellCls: 'np-i',
                    widgets: [{
                            type: 'button',
                            cls: 'b-blue b-raised',
                            onAction: ({ source }) => {
                                const { cellInfo } = source;
                                if (!cellInfo || !cellInfo.record)
                                    return;
                                const { DetectionId, DetectionDeviceId, DetectionTime } = cellInfo.record;
                                // menuHelperService.pressMenuItem('_mapsearch', { DetectionId, DetectionDeviceId, DetectionTime })
                                detectionService.modalShowOnMap({ DetectionId, DetectionDeviceId, DetectionTime }).catch(AError.handle);
                            }
                        }]
                }
            }),
            data: AConvertToGridData(response, {
                'Action1': () => Translate.getCacheFast('show history'),
                'Action2': () => Translate.getCacheFast('show on map'),
                'DetectionTime': DATA_DATETIME,
                'Time': DATA_TIME,
                'Image': DATA_IMAGE
            })
        });
    }
}
export function render() {
    return ( /*html*/`
    <div id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column col-12">
        <div class="form-group">
          <label class="form-label" for="FromDate">From</label>
          <input class="form-input" type="date" id="FromDate" required="required">
          <input class="form-input" type="time" id="FromTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="ToDate">To</label>
          <input class="form-input" type="date" id="ToDate" required="required">
          <input class="form-input" type="time" id="ToTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="ParkingRight">ParkingRight</label>
          <div id="ParkingRight" class="wrapper-dropdown tree-config noselect" maxlength="18">
            <span>All</span>
          </div>
        </div>

        <div class="form-group">
          <label class="form-label" for="Verification">Verification</label>
          <div id="Verification" class="wrapper-dropdown tree-config noselect" maxlength="18">
            <span>All</span>
          </div>
        </div>

        <div class="form-group">
          <label class="form-label" for="IllegallyParked">IllegallyParked</label>
          <div id="IllegallyParked" class="wrapper-dropdown tree-config noselect" maxlength="18">
            <span>All</span>
          </div>
        </div>

        <div class="form-group">
          <label class="form-label" for="DeviceName">Device</label>
          <select class="form-select" id="DeviceName">
            <option value="%">All</option>
          </select>
        </div>

        <div class="form-group">
          <label class="form-label" for="Area">Area</label>
          <select class="form-select" id="Area">
            <option value="%" SELECTED>All</option>
            <option value="Unknown">Unknown</option>
          </select>
        </div>
        <div class="form-group">
          <label class="form-label" for="Limit">Max results</label>
          <input class="form-input" type="number" id="Limit" value="2000">
        </div>
        <div class="form-group">
          <label class="form-switch">
            <input id="Images" type="checkbox">
            <i class="form-icon"></i> Images
          </label>
        </div>
      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </div>
    <div id="Rapport" class="flex-child bryntum-container has-footer-2">
      <div template="${ATemplates.WaitingForInput}"></div>
      <div id="table-bryntum" class="hidden"></div>

      <div class="columns footer aci">
        <div class="column col-2">
          <div id="count" class="text">Viewing <span>0</span> Scans</div>
        </div>
        <div class="column col-2 col-ml-auto">
          <button id="export" class="btn btn-primary col-12" disabled="disabled">Export</button>
        </div>
      </div>
    </div>
  `);
}
