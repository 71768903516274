import { AEngine } from './core/AEngine.js';
// BackOffice Initializer
import * as BackOffice from './backoffice-initializer-legacy.js';
// Runnables
import { ALoadBackground } from './runnables/ALoadBackground.js';
// Services
import { AAdminAlertsService } from './services/AAdminAlertsService.js';
import { AAlertService } from './services/AAlertService.js';
import { AEventService } from './services/AEventService.js';
import { AFilterService } from './services/AFilterService.js';
import { AInterpolateService } from './services/AInterpolateService.js';
import { AJsonService } from './services/AJsonService.js';
import { AMapHelperService } from './services/AMapHelperService.js';
import { AMapOverlayService } from './services/AMapOverlayService.js';
import { AMenuHelperService } from './services/AMenuHelperService.js';
import { AMenuService } from './services/AMenuService.js';
import { APermissionService } from './services/APermissionService.js';
import { APolicyService } from './services/APolicyService.js';
import { APreferenceService } from './services/APreferenceService.js';
import { ARequestService } from './services/ARequestService.js';
import { ARouteDrawingService } from './services/ARouteDrawingService.js';
import { AStatisticsService } from './services/AStatisticsService.js';
import { ATranslateService } from './services/ATranslateService.js';
import { AVerificationService } from './services/AVerificationService.js';
import { createEventHandlers } from './backoffice-initializer-legacy.js';
import { ADetectionService } from './services/ADetectionService.js';
import { AUserActionService } from './services/AUserActionService.js';
import { ALoadingService } from './services/ALoadingService.js';
import { AGlobalService } from './services/AGlobalService.js';
import { ADependencyService } from './services/ADependencyService.js';
import { initPolicies } from './policies.js';
import { ALoadHotReload } from './runnables/ALoadHotReload.js';
import { APurgatoryService } from './services/APurgatoryService.js';
import { AAutoRefreshService } from './services/AAutoRefreshService.js';
import { ARouteService } from './services/ARouteService.js';
import { AStateService } from './services/AStateService.js';
import { AQuickLogin } from './runnables/AQuickLogin.js';
import { AWidgetService } from './services/AWidgetService.js';
import { ADeveloperTodos } from './runnables/ADeveloperTodos.js';
import { AProductionTodos } from './runnables/AProductionTodos.js';
import { ATemplateService } from './core/ATemplateService.js';
import { AValidateEnforcementProcessService } from './services/AValidateEnforcementProcessService.js';
import { AConfigService } from './services/AConfigService.js';
import { AIdAllocatorService } from './core/allocator/AIdAllocatorService.js';
import { ARoutePlannerService } from './pages/routeplanner/ARoutePlannerStruct.js';
import { ANodeSessionService } from './services/ANodeSessionService.js';
import { AMapsValidate } from './runnables/AMapsValidate.js';
import { AUserManualService } from './services/AUserManualService.js';
async function run() {
    const services = [
        ADependencyService,
        AGlobalService,
        ARouteService,
        ALoadingService,
        AEventService,
        AMenuService,
        AAlertService,
        ATranslateService,
        ARequestService,
        APreferenceService,
        ARouteDrawingService,
        AVerificationService,
        AJsonService,
        AAdminAlertsService,
        AMapOverlayService,
        AStatisticsService,
        ANodeSessionService,
        APolicyService,
        AMapHelperService,
        AMenuHelperService,
        AFilterService,
        AInterpolateService,
        APermissionService,
        ADetectionService,
        AUserActionService,
        APurgatoryService,
        AAutoRefreshService,
        AStateService,
        AWidgetService,
        ATemplateService,
        AValidateEnforcementProcessService,
        AConfigService,
        AIdAllocatorService,
        AUserManualService,
        ARoutePlannerService
    ];
    const runnables = [
        ALoadBackground,
        ALoadHotReload,
        AMapsValidate,
        AQuickLogin,
        ADeveloperTodos,
        AProductionTodos
    ];
    await AEngine.executeServices(services);
    await AEngine.autoInitServices();
    await AEngine.executeRunnables(runnables);
    initPolicies();
    createEventHandlers();
}
window.addEventListener('load', async () => {
    try {
        await run();
        await BackOffice.run();
    }
    catch (err) {
        console.error(err);
    }
});
