import { AError } from "./AError.js";
export class AImageCropper {
    constructor(opt) {
        this.opt = opt;
    }
    get file() { return this.opt.file; }
    get mimeType() { return this.opt.mimeType; }
    get quality() { return this.opt.quality; }
    getSizeByScale(img, scale) {
        const map = {
            'large': { width: img.width, height: img.height },
            'medium': { width: 256, height: 256 },
            'small': { width: 128, height: 128 },
        };
        return map[scale];
    }
    async readImageFromDisk(file) {
        const img = new Image();
        const blobUrl = URL.createObjectURL(file);
        await Promise.resolve().then(() => {
            return new Promise((resolve) => {
                img.onload = function () {
                    URL.revokeObjectURL(img.src);
                    resolve();
                };
                img.src = blobUrl;
            });
        });
        return img;
    }
    async cropToScaled(opt) {
        const img = await this.readImageFromDisk(this.file);
        const srcSize = { width: img.width, height: img.height };
        const srcRatio = srcSize.width / srcSize.height; // ratio >= 1 if width is greater than height
        const scaleOrder = ['large', 'medium', 'small'];
        // let blobArr: (Blob|null)[] = [null, null, null]
        // let canvasArr: (HTMLCanvasElement|null)[] = [null, null, null]
        let output = {
            'large': {},
            'medium': {},
            'small': {},
        };
        await Promise.all(opt.renderTo.map(async ($img, i) => {
            const keyScale = scaleOrder[i];
            const destSize = this.getSizeByScale(img, keyScale);
            const destRatio = destSize.width / destSize.height; // ratio >= 1 if width is greater than height
            // Dynamically create a canvas element
            var canvas = document.createElement("canvas");
            canvas.width = destSize.width;
            canvas.height = destSize.height;
            // var canvas = document.getElementById("canvas")
            var ctx = canvas.getContext("2d");
            // Show resized image in preview element
            var dataurl = canvas.toDataURL(this.mimeType);
            const imgSection = new Image();
            var resizePromise = new Promise((resolve) => {
                imgSection.onload = function () {
                    // ctx!.drawImage(img, 0, 0, srcSize.width, srcSize.height, 0, 0, destSize.width, destSize.height)
                    let cropWidth, cropHeight, offsetX, offsetY;
                    // If the source aspect ratio doesn't match the target
                    if (srcRatio > destRatio) {
                        // Source is wider than target, we need to crop the width
                        cropHeight = srcSize.height; // Full height
                        cropWidth = cropHeight * destRatio; // Adjust width based on target ratio
                        offsetX = (srcSize.width - cropWidth) / 2; // Center crop horizontally
                        offsetY = 0; // No vertical offset
                    }
                    else if (srcRatio < destRatio) {
                        // Source is taller than target, we need to crop the height
                        cropWidth = srcSize.width; // Full width
                        cropHeight = cropWidth / destRatio; // Adjust height based on target ratio
                        offsetX = 0; // No horizontal offset
                        offsetY = (srcSize.height - cropHeight) / 2; // Center crop vertically
                    }
                    else {
                        // Aspect ratios match, no cropping needed
                        cropWidth = srcSize.width;
                        cropHeight = srcSize.height;
                        offsetX = 0;
                        offsetY = 0;
                    }
                    ctx.drawImage(img, offsetX, offsetY, cropWidth, cropHeight, 0, 0, canvas.width, canvas.height);
                    resolve();
                };
            });
            imgSection.src = dataurl;
            // Wait for all image sections to be rendered
            await Loading.waitForPromises(resizePromise);
            // Set image preview src
            $img.attr('src', canvas.toDataURL());
            const blob = await new Promise((resolve) => canvas.toBlob((blob) => resolve(blob), this.mimeType, this.quality));
            if (blob == null) {
                delete output[keyScale];
                AError.handle(new Error(`Couldn't transform canvas to blob for image upload keyScale=${keyScale}`));
                return;
            }
            output[keyScale] = new ACropResult({
                canvas: canvas,
                mimeType: this.mimeType,
                quality: this.quality,
                imageSize: destSize,
                fileSize: this.file.size,
                blob: blob,
            });
        }));
        return output;
    }
}
export class ACropResult {
    constructor({ canvas, blob, imageSize, fileSize, mimeType, quality }) {
        this.canvas = canvas;
        this._base64 = this.canvas.toDataURL(mimeType, quality);
        this.blob = blob;
        this._imageSize = imageSize;
        this._fileSize = fileSize;
        this._mimeType = mimeType;
        this._quality = quality;
        this.processBinaryData();
    }
    get imageSize() { return this._imageSize; }
    get fileSize() { return this._fileSize; }
    get mimeType() { return this._mimeType; }
    get quality() { return this._quality; }
    processBinaryData() {
        // ['data:image/png', 'base64', 'iVBORw0KGgoAAAANSUhEUgAAAgIAAADgg==']
        const [mimeType, ...rest] = this._base64.replace('data:', '').split(/[;,]/g);
        if (this._mimeType === undefined)
            this._mimeType = mimeType;
        this._binary64 = rest.pop();
    }
    toBase64(options) {
        return options.stripPrefix ? this._binary64 : this._base64;
    }
}
