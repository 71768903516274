import { AEngine } from "../../AEngine.js";
import { AFormatDate, AConvertMillisecondsToHM, ARound, AIsDate, escapeHtmlChars, mergeDeep } from "../../../utils/tools.js";
import { AError } from "../../../classes/AError.js";
import { ATableKey } from "./ATableKey.js";
import { GeoAttributes } from "../../../utils/table_formatter.js";
import { AErrorWithContext } from "../../errors.js";
export class ATableFormatter {
    get UNKNOWN() { return { type: 'UNKNOWN' }; }
    constructor(_fieldDefinitions) {
        this._fieldDefinitions = _fieldDefinitions;
        this.unknownTypes = ['UNKNOWN', 'DYNAMIC'];
    }
    get fieldDefinitions() {
        return this._fieldDefinitions ?? ATableFormatter.fallbackDefinitions;
    }
    genContext(opt, parent, depth) {
        const context = {
            tableKey: opt.tableKey,
            parentContext: parent,
            formatOpt: this.getColumnFormatOptions(opt.tableKey),
            depth: depth,
            data: {},
        };
        return context;
    }
    formatTableColumn(args) {
        return this.formatTableColumnRecursive(args, this.genContext(args, undefined, 0));
    }
    formatTableColumnRecursive(args, context) {
        try {
            this.correctColumnType(context, args.value);
            const item = this.applyColumnType(args, context);
            const children = ((context.childOptions ?? []).map((childOpt) => {
                return this.formatTableColumnRecursive(childOpt, this.genContext(childOpt, context, context.depth + 1));
            }).flat().filter(c => c !== undefined) ?? []);
            return [item, ...children];
        }
        catch (err) {
            AError.handleSilent(new Error(`Couldn't process tablekey: ${args.tableKey.traversary()}`));
            return [args.tableKey.getColumnTranslated(), '', context];
        }
    }
    getColumnFormatOptions(tableKey) {
        // if (!this.fieldDefinitions) {
        //   AEngine.warn(`No fieldDefinitions defined, using fallback`, this.getColumnFormatOptionsFallback(tableKey))
        //   return this.getColumnFormatOptionsFallback(tableKey)
        // }
        // type AFormatGetter = ((tableKey: ATableKey, context: AColumnFormatContext) => {formatOption: AFormatOption|undefined})
        // const formatOptionGetters: AFormatGetter[] = [
        //   (k, c) => this.traverseFormatOptions(k, c),
        //   // (k, c) => this.traverseFormatOptionsFallback(k, c),
        //   // (tableKey, context) => ({ formatOption: this.UNKNOWN }),
        // ]
        // TODO: Clean Up Code
        let contexts = [];
        // while (formatOptionGetters.length > 0) {
        const context = { keyParts: [], traverseArr: [] };
        // const traverse = formatOptionGetters.shift()!
        const { formatOption } = this.traverseFormatOptions(tableKey, context);
        if (formatOption) {
            return formatOption;
        }
        contexts.push(context);
        // }
        if (AEngine.isDevelopmentMode && Events.logLevel === 2) {
            AEngine.warn(`Couldn't traverse:%p`, contexts[0]?.keyParts?.join('->'));
        }
        return this.UNKNOWN;
    }
    correctColumnType(context, value) {
        let type = context.formatOpt.type;
        if (!this.unknownTypes.includes(type)) {
            return type;
        }
        const v = value;
        let prevType = type;
        let jsType = (v !== null && v !== undefined) ? (typeof v) : '';
        if (jsType === 'string') {
            if (AIsDate(v)) {
                type = 'DATETIME';
            }
            else if (this.isJsonObjectText(v)) {
                type = 'OBJECT';
            }
            else if (this.isJsonArrayText(v)) {
                type = 'ARRAY';
            }
            else {
                type = 'TEXT';
            }
        }
        else if (jsType === 'boolean') {
            type = 'BOOLEAN';
        }
        else if (jsType === 'object') {
            if (Array.isArray(v)) {
                type = 'ARRAY';
            }
            else if (v instanceof Date) {
                type = 'DATETIME';
            }
            else {
                type = 'OBJECT';
            }
        }
        if (v !== null && prevType === type) {
            if (!(['string', 'number'].includes(jsType)) && type !== 'DYNAMIC') {
                type = 'UNKNOWN';
            }
            if (jsType === 'string' && AIsDate(v)) {
                type = 'DATETIME';
            }
            if (this.isJsonArrayText(v)) {
                type = 'ARRAY';
            }
            if (this.isJsonObjectText(v)) {
                type = 'OBJECT';
            }
        }
        if (context.formatOpt.type !== type && Events.logLevel === 2) {
            AEngine.warn(`%stypedef: %p${context.formatOpt.type}%r => %p${type}`, context.tableKey.traversary());
        }
        context.ogType = context.formatOpt.type;
        context.formatOpt.type = type;
    }
    collectParentContexts(context) {
        const parents = [];
        let curr = context.parentContext;
        while (curr !== undefined) {
            parents.push(curr);
            curr = curr.parentContext;
        }
        // AEngine.warn(`Leaf Parents-Array Size: ${parents.length} Expected: ${context.depth}`)
        // const arr = createArray(context.depth + 1).map(() => { })
        return parents;
    }
    applyColumnType(opt, context) {
        let { tableKey, meta, value } = opt;
        const { checkForUnknownColumns } = meta.builder;
        const { formatOpt } = context;
        if (value === null || value === undefined || value === '') {
            return [tableKey.getColumnTranslated(), '', context];
        }
        switch (formatOpt.type) {
            case 'UNKNOWN':
                const parentContexts = this.collectParentContexts(context);
                const parentFormatTypes = parentContexts.map(p => p.formatOpt.type);
                const parentOgFormatTypes = parentContexts.map(p => p.ogType);
                if (Events.logLevel === 2) {
                    AEngine.error(new AErrorWithContext(`ATableFormatter Unknown ColumnType: ${formatOpt.type}!`, opt));
                    console.log(opt.tableKey.traversary({ removeArrayIndices: true }), {
                        parentContexts, parentFormatTypes, parentOgFormatTypes
                    });
                }
                if (checkForUnknownColumns) {
                    if (context.depth === 0 || parentContexts.find(p => p.ogType === 'DYNAMIC') === undefined) {
                        context.greyOut = true;
                        meta.missing.push(tableKey.traversary({ removeArrayIndices: true }));
                    }
                }
                break;
            case 'DYNAMIC':
                // context.err = true
                if (Events.logLevel === 2) {
                    AEngine.warn(`TableKey "${tableKey.traversary()}" has unexpected type: %p${formatOpt.type}`);
                }
                // if (checkForUnknownColumns) {
                //   meta.missing.push(tableKey.traversary({ removeArrayIndices: true }))
                // }
                // value = escapeHtmlChars((typeof value !== 'string') ? JSON.stringify(value, getCircularReplacer()) : value)
                break;
            case 'TEXT':
                value = escapeHtmlChars((typeof value !== 'string') ? JSON.stringify(value, getCircularReplacer()) : value);
                break;
            case 'REAL':
                value = Number(value);
                break;
            case 'NUMBER':
                value = ARound(value, 2);
                break;
            case 'BOOLEAN':
                let stringifiedValue = (value + '').toString();
                switch (stringifiedValue.toLowerCase()) {
                    case 'true':
                        value = true;
                        break;
                    case 'yes':
                        value = true;
                        break;
                    case '1':
                        value = true;
                        break;
                    case 'false':
                        value = false;
                        break;
                    case 'no':
                        value = false;
                        break;
                    case '0':
                        value = false;
                        break;
                    default:
                        throw new Error(`Boolean value not valid for column: ${tableKey.getColumn()} value: ${value}`);
                }
                value = value === true ? (`<i class="fa fa-check fa-lg"></i>`) : (`<i class="fa fa-times fa-lg"></i>`);
                break;
            case 'BLOB':
                if (formatOpt.onClick !== undefined) {
                    const dataStr = JSON.stringify(value);
                    const id = idAllocatorService.getNextId({ prefix: formatOpt.type });
                    value = ( /*html*/`<button id="${id}" class="btn btn-primary btn-xs aci-blob">${Translate.getCache(`View`)}</button>`);
                    Events.on(id, () => {
                        // TODO: Link onclick event
                        formatOpt.onClick($(`#${id}`), JSON.parse(dataStr));
                    });
                }
                break;
            case 'TIME':
                value = value.replace(/[ ]+/g, ':');
                break;
            case 'DATETIME':
                value = AFormatDate(new Date(value));
                break;
            case 'DURATION':
                value = AConvertMillisecondsToHM(value * 1000);
                break;
            case 'ARRAY':
            case 'OBJECT':
                if (this.isJsonObjectText(value) || this.isJsonArrayText(value)) {
                    value = JSON.parse(value);
                }
                const keys = Object.keys(value ?? {});
                if (keys.length > 0) {
                    context.childOptions = keys.map((k) => {
                        const childOpt = {
                            tableKey: new ATableKey(k, k, tableKey),
                            value: value[k],
                            meta: meta,
                        };
                        return childOpt;
                    });
                    context.hasChildren = keys.length > 0;
                }
                value = '';
                break;
            case 'CUSTOM':
                const id = idAllocatorService.getNextId({ prefix: Date.now().toString(16) });
                context.data['custom'] = { column: tableKey.getColumn(), id, type: formatOpt.type, value, mapTo: formatOpt.mapTo };
                value = (`<div rid="${id}">${(value != null) ? value : ''}</div>`);
                break;
            case 'HIDDEN':
                value = '';
                context.hidden = true;
                break;
            default:
                AEngine.warn(`%sUnexpected type: %p${formatOpt.type}%r at column '%p${tableKey.getColumn()}%r'`);
                value = JSON.stringify(value, getCircularReplacer());
                Object.assign(context, { greyOut: true, errText: `Unexpected type '${formatOpt.type}' at '${tableKey.traversary()}' with value '${value}'` });
                AError.handleSilent(new Error(context.errText));
                if (checkForUnknownColumns) {
                    meta.missing.push(tableKey.getColumn());
                }
                break;
        }
        const item = [tableKey.getColumnTranslated(), value, context];
        return item;
    }
    traverseFormatOptions(tableKey, context) {
        const c = context;
        let expanded = undefined;
        let formatOption;
        let formatOptionsMap = this.fieldDefinitions;
        let ancestry = tableKey.ancestry();
        c.keyParts = ancestry.map(tk => tk.getColumn());
        c.traverseArr = [`formatOptions`];
        for (let i = 0; i < ancestry.length; i++) {
            const keyPart = ancestry[i].getColumn();
            let key = /[0-9]+/g.test(keyPart) ? '*' : keyPart;
            if (formatOptionsMap[key] === undefined) {
                if (formatOptionsMap['*'] === undefined) {
                    c.traverseArr.push(`[column=${key} OR *]`);
                    formatOptionsMap = null;
                    formatOption = undefined;
                    break;
                }
                key = '*';
            }
            let tmp = formatOptionsMap[key];
            c.traverseArr.push(`[column=${key}]`);
            const isLast = (i === ancestry.length - 1);
            if ('formatter' in tmp) {
                if (tmp.expanded === true) {
                    expanded = true;
                }
            }
            if ('formatter' in tmp && !isLast) {
                c.traverseArr.push(`->formatter`);
                formatOptionsMap = tmp.formatter;
                // expanded = tmp.expanded ?? false
            }
            else {
                c.traverseArr.push(`___`);
                if (!isLast) {
                    formatOption = undefined;
                    break;
                }
                formatOptionsMap = tmp;
                formatOption = formatOptionsMap;
            }
        }
        if (typeof c.traverseArr !== 'string') {
            c.traverseArr = c.traverseArr.join('');
        }
        if (formatOption) {
            formatOption = Object.assign({}, { ...formatOption }, expanded ? { expanded: true } : {});
        }
        return { formatOption };
    }
    /**
     * @deprecated
     */
    traverseFormatOptionsFallback(tableKey, context) {
        const c = context;
        const columnName = tableKey.getColumn();
        const { fallbackDefinitions } = ATableFormatter;
        let formatOption;
        if (fallbackDefinitions.hasOwnProperty(columnName)) {
            c.traverseArr = `formatOptions[column="${columnName}"]`;
            formatOption = fallbackDefinitions[columnName];
        }
        return { formatOption };
    }
    isJsonObjectText(text) {
        if (typeof text === 'string') {
            let trimmed = text.trim();
            let couldBeObject = trimmed.startsWith('{') && trimmed.endsWith('}');
            // let couldBeList = trimmed.startsWith('[') && trimmed.endsWith(']')
            if (!couldBeObject) {
                return false;
            }
            try {
                JSON.parse(trimmed);
            }
            catch (e) {
                return false;
            }
            return true;
        }
        return false;
    }
    isJsonArrayText(text) {
        if (typeof text === 'string') {
            let trimmed = text.trim();
            // let couldBeObject = trimmed.startsWith('{') && trimmed.endsWith('}')
            if (trimmed === '[object Object]') {
                return false;
            }
            let couldBeList = trimmed.startsWith('[') && trimmed.endsWith(']');
            if (!couldBeList) {
                return false;
            }
            try {
                JSON.parse(trimmed);
            }
            catch (e) {
                return false;
            }
            return true;
        }
        return false;
    }
}
ATableFormatter.fallbackOrder = [
    "DetectionTime",
    "LicensePlate",
    "DetectionState",
    "ParkingRight",
    "IllegallyParked",
    "Verification",
    "TimeLimitedParking",
    "Digital",
    "DetectionUser",
    "Address",
    "IsSuspect",
    "Side",
    "Area",
    "IsFine",
    "VerificationChannel",
    "SessionVerificationChannels",
    "VerificationUserRemarks",
    "Label",
];
/**
 * Remove:
 * HasParkingRight
 * IsIllegallyParked
 * ParkingRightType
 * VerificationResult
 * VerificationResultText
 *
 * DetectionState
 * ParkingRight
 * IllegallyParked
 * TimeLimitedParking
 * Verification
 * IsSuspect
 * VerificationChannel (Translated)
 */
ATableFormatter.fallbackDefinitions = {
    "*": { type: 'UNKNOWN' },
    "BO.Color": { type: 'TEXT', style: 'color: {value};' },
    "DetectionTime": { type: 'DATETIME', isPriority: true },
    "LicensePlate": { type: 'TEXT', isPriority: true },
    "IsSuspect": { type: 'BOOLEAN', isPriority: true },
    "Digital": { type: "TEXT", isPriority: true },
    "TimeLimitedParking": { type: "TEXT", isPriority: true },
    "IllegallyParked": { type: "TEXT", isPriority: true },
    "ParkingRight": { type: "TEXT", isPriority: true },
    "Verification": { type: "TEXT", isPriority: true },
    "DetectionState": { type: "TEXT", isPriority: true },
    "DetectionUser": { type: "TEXT", isPriority: true },
    "Address": { type: 'TEXT', isPriority: true },
    "Area": { type: 'TEXT', isPriority: true },
    "Side": { type: 'TEXT', isPriority: true },
    // TODO: Remove temporary ccweesp logic
    ...(document.URL.includes('ccweesp') || document.URL.includes('.localhost') ? {
        "SplitParkingSpaceId": { type: 'TEXT', isPriority: true }
    } : {
        "SplitParkingSpaceId": { type: 'HIDDEN' },
    }),
    "DetectionStreet": { type: 'TEXT', isPriority: true },
    "MappedOnRoute": { type: 'BOOLEAN', isPriority: true },
    "UsedInOccupancy": { type: 'BOOLEAN', isPriority: true },
    "IsFine": { type: "BOOLEAN", isPriority: true },
    "VerificationChannel": { type: 'TEXT', isPriority: true },
    "SessionVerificationChannels": { type: "ARRAY", isPriority: true },
    "VerificationUserRemarks": { type: "TEXT", isPriority: true },
    "Label": { type: 'CUSTOM', isPriority: true, mapTo: 'generateLabelField' },
    "Active": { type: "BOOLEAN" },
    "DetectionValid": { type: 'BOOLEAN' },
    "ChannelCode": { type: "HIDDEN" },
    "CardinalDirection": { type: 'TEXT' },
    "VerificationResult": { type: 'TEXT' },
    "VerificationResultText": { type: 'TEXT' },
    "HasParkingRight": { type: 'BOOLEAN' },
    "IsIllegallyParked": { type: 'BOOLEAN' },
    "ParkingRightType": { type: 'TEXT' },
    "ParkingRightTypeText": { type: 'TEXT' },
    "ParkingRightAttributes": GeoAttributes,
    "ParkingRightError": { type: 'TEXT' },
    "Id": { type: 'TEXT' },
    // "36503218372149461",
    "DetectionId": { type: 'TEXT' },
    // "281629595598849",
    "DetectionDeviceId": { type: 'TEXT' },
    // "Unknown",
    "VehicleType": { type: "TEXT" },
    // "ScanAuto1",
    "DetectionDevice": { type: "TEXT" },
    // "18 48 29",
    "Time": { type: "TIME" },
    // 0.901,
    "Confidence": { type: "NUMBER" },
    // null,
    "DuplicateCodeLp": { type: "TEXT" },
    // null,
    "DuplicateCodeLpConf": { type: "TEXT" },
    // Array(1),
    "CameraIds": { type: "ARRAY" },
    // 213,
    "BestCameraId": { type: "TEXT" },
    // Array(0),
    "NearMatches": { type: "ARRAY" },
    // 8.11161442463566,
    "VehicleSpeed": { type: "NUMBER" },
    // {…},
    "VehicleBoundsJson": { type: "OBJECT" },
    // "No",
    "VehicleMoving": { type: "BOOLEAN" },
    // 59.9259468705078,
    "ScanDeviceLatitude": { type: "TEXT" },
    // 10.7575656701899,
    "ScanDeviceLongitude": { type: "TEXT" },
    // 1.0432681497923,
    "GpsPrecision": { type: "NUMBER" },
    // "1.0/1.1",
    "GpsPrecisionGroup": { type: "TEXT" },
    // 1,
    "GpsValid": { type: "BOOLEAN" },
    // 281.22096559014,
    "ScanDeviceDirection": { type: "NUMBER" },
    // "18",
    "Hour": { type: "TEXT" },
    // "2017-08-25",
    "Day": { type: "TEXT" },
    // "Week 34 2017",
    "Week": { type: "TEXT" },
    // "August 2017",
    "Month": { type: "TEXT" },
    // "Friday",
    "Weekday": { type: "TEXT" },
    // 0,
    "TaxRequired": { type: "BOOLEAN" },
    // null,
    "AreaConfidence": { type: "NUMBER" },
    // {…},
    "AreaAttributes": GeoAttributes,
    // null,
    "ParkingSpaceId": { type: "HIDDEN" },
    // "Unknown",
    "ParkingSpace": { type: "TEXT" },
    // null,
    "ParkingSpaceConfidence": { type: "NUMBER" },
    // {…},
    "ParkingSpaceAttributes": GeoAttributes,
    // 41183,
    "SegmentId": { type: "HIDDEN" },
    // "2017-08-25T16:48:25.700Z",
    "SegmentTimestamp": { type: "DATETIME" },
    // null,
    "SegmentConfidence": { type: "NUMBER" },
    // null,
    "SegmentAttributes": mergeDeep({}, GeoAttributes, {
        formatter: {
            "WaySegmentId": { type: "TEXT" },
            "WaySegmentSide": { type: "TEXT" },
        }
    }),
    // null,
    "ZoneId": { type: "HIDDEN" },
    // "Unknown",
    "Zone": { type: "TEXT" },
    // null,
    "ZoneConfidence": { type: "NUMBER" },
    // null,
    "ZoneTimestamp": { type: "DATETIME" },
    // {…},
    "ZoneAttributes": GeoAttributes,
    // "Markveien 12B",
    "address": { type: "TEXT" },
    // "DK",
    "CountryCode": { type: "TEXT" },
    // null,
    "ParkingRightResults": {
        type: "ARRAY",
        formatter: {
            "*": {
                type: "OBJECT",
                formatter: {
                    '*': { type: 'DYNAMIC' },
                    'ParkingRightEnd': { type: 'TEXT' },
                    'ParkingRightStart': { type: 'DATETIME' },
                }
            },
        }
    },
    // "No Right",
    "Visitor": { type: "TEXT" },
    // null,
    "VerificationUser": { type: "TEXT" },
    // null,
    "VerificationStartTime": { type: "DATETIME" },
    // "2017-08-25T17:18:36.000Z",
    "VerificationEndTime": { type: "DATETIME" },
    // null,
    "VerificationLatitude": { type: "TEXT" },
    // null,
    "VerificationLongitude": { type: "TEXT" },
    "FinalVerificationUser": { type: "TEXT" },
    "FinalVerificationDistanceToVehicle": { type: "TEXT" },
    "ParkingAreaType": { type: "TEXT" },
    "AreaId": { type: "HIDDEN" },
    "VehicleCenterLatitude": { type: "TEXT" },
    "VehicleCenterLongitude": { type: "TEXT" },
    "VehicleBounds": { type: "HIDDEN" },
    "SessionId": { type: "HIDDEN" },
    "PreviousDetectionState": { type: "TEXT" },
    "PreviousDetectionStateDuration": { type: "DURATION" },
    // "\"Unit2\""
    "EnforceUnit": { type: "TEXT" },
    // "\"2021-12-01T13:12:44.600Z\""
    "DetectionReceiveTime": { type: "DATETIME" },
    // "\"2021-12-01T13:12:44.402Z\""
    "DetectionSendTime": { type: "DATETIME" },
    // "\"5hAAAAEBAAAAekB5APN0JUBlgbJ7ivhNQA==\""
    "VehicleCenter": { type: "HIDDEN" },
    // "\"5hAAAAEBAAAATizL9PB0JUBfDzYnifhNQA==\""
    "ScanDeviceCenter": { type: "HIDDEN" },
    // "14.384799544996"
    "ScanDeviceSpeed": { type: "TEXT" },
    // "null"
    "DebugInfo": { type: "TEXT" },
    // "1"
    "Anonymized": { type: "BOOLEAN" },
    // "\"Enforce\""
    "SessionMode": { type: "TEXT" },
    // "null"
    "SessionName": { type: "TEXT" },
    // "\"2021-12-01T13:12:45.046Z\""
    "GeoCheckTime": { type: "DATETIME" },
    // "\"Vestgrensa\""
    "Street": { type: "TEXT" },
    // "\"8\""
    "HouseNumber": { type: "TEXT" },
    // "null"
    "City": { type: "TEXT" },
    // "[{\"Id\":138,\"Name\":\"G\",\"TimeStamp\":null,\"Attributes\":{\"dbid\":18,\"sone\":\"G\",\"zone\":\"Nordre Aker sone G\",\"bydel\":\"Nordre Aker\",\"objectid\":419,\"bydel_old\":null,\"bydel_old2\":\"Nordre Aker\",\"st_area(shape)\":2256537.12101401,\"st_perimeter(shape)\":9687.13651163797},\"Confidence\":0.999999436491758}]"
    "Areas": { type: "OBJECT" },
    // "\"G\""
    "AreaName": { type: "TEXT" },
    // "\"2021-12-01T12:44:18.504Z\""
    "AreaTimeStamp": { type: "DATETIME" },
    // "[{\"Id\":18106,\"Name\":\"Tax parking\",\"TimeStamp\":null,\"Attributes\":{\"P_type\":1,\"Fritekst\":null,\"GlobalID\":\"{8CF030FB-703D-4159-BAA5-B9853AC29243}\",\"OBJECTID\":243390,\"P_bredde\":\"2\",\"P_lengde\":null,\"Tellemetode\":1,\"StrekningsID\":105148,\"Takstgruppe1\":2300,\"Takstgruppe2\":2300,\"Befart_antall\":null,\"Dato_oppdatert\":\"1539674566000\",\"Beregnet_antall\":19,\"Dato_registrert\":\"1521645902000\",\"P_spesifisering\":37,\"Bruker_oppdatert\":\"GISEDITOR\",\"Shape.STLength()\":101.180598731446,\"Bruker_registrert\":\"GISEDITOR\",\"Beboerparkeringssone\":\"G\"},\"Confidence\":0.926730829265264}]"
    "ParkingSpaces": { type: "OBJECT" },
    // "\"Tax parking\""
    "ParkingSpaceName": { type: "TEXT" },
    // "\"2021-12-01T13:12:17.939Z\""
    "ParkingSpaceTimeStamp": { type: "DATETIME" },
    // "[{\"Id\":21922,\"Name\":\"Tax parking\",\"TimeStamp\":null,\"Attributes\":{\"Capacity\":5.36244623302013,\"Geo-Area\":96.5240321943624,\"SegmentId\":17173,\"Geo-Length\":100.813820486734},\"Confidence\":0.926730890247006}]"
    "SplitParkingSpaces": { type: "OBJECT" },
    // "null"
    "SplitParkingSpaceName": { type: "TEXT" },
    // "0.926730890247006"
    "SplitParkingSpaceConfidence": { type: "NUMBER" },
    // "{\"Capacity\":5.36244623302013,\"Geo-Area\":96.5240321943624,\"SegmentId\":17173,\"Geo-Length\":100.813820486734}"
    "SplitParkingSpaceAttributes": GeoAttributes,
    // "\"2021-12-01T13:12:44.939Z\""
    "SplitParkingSpaceTimeStamp": { type: "DATETIME" },
    // "[{\"Id\":1857,\"Name\":\"Yellow\",\"TimeStamp\":null,\"Attributes\":{\"dbid\":1603,\"navn\":\"C1047\",\"globalid\":\"{EA69D06C-2BF5-4243-B84C-9BFDAE8A9D26}\",\"objectid\":152817,\"takstsone\":\"GUL\",\"takstgruppe\":2300,\"created_date\":\"1539674229000\",\"created_user\":\"yem\",\"takstgruppe2\":null,\"st_area(shape)\":1.97538531202675e-8,\"last_edited_date\":\"1620285435000\",\"last_edited_user\":\"GISOWNER\",\"st_perimeter(shape)\":0.00112416697257701},\"Confidence\":0.894749989556078}]"
    "Zones": { type: "OBJECT" },
    // "\"Yellow\""
    "ZoneName": { type: "TEXT" },
    // "\"2021-12-01T13:12:17.939Z\""
    "ZoneTimeStamp": { type: "DATETIME" },
    // "\"2021-12-01T13:12:20.995Z\""
    "SegmentTimeStamp": { type: "DATETIME" },
    // "0"
    "IsDuplicate": { type: "BOOLEAN" },
    // "null"
    "LinkedDetectionId": { type: "TEXT" },
    // "null"
    "LinkedDetectionDeviceId": { type: "TEXT" },
    // "[{\"Id\":17173,\"Name\":\"Vestgrensa\",\"TimeStamp\":null,\"Attributes\":{\"name\":\"Vestgrensa\",\"highway\":\"residential\",\"Geo-Area\":3073.58869477433,\"Geo-Length\":252.709563431688,\"WaySegmentId\":394,\"WaySegmentSide\":1},\"Confidence\":1}]"
    "Segments": { type: "OBJECT" },
    // "394"
    "WaySegmentId": { type: "HIDDEN" },
    // "0.67001755076803"
    "WaySegmentConfidence": { type: "NUMBER" },
    // "{\"name\":\"Vestgrensa\",\"highway\":\"residential\",\"Geo-Area\":0,\"Geo-Length\":109.76404253097}"
    "WaySegmentAttributes": GeoAttributes,
    // "\"2021-12-01T13:12:20.995Z\""
    "WaySegmentTimeStamp": { type: "DATETIME" },
    // "[]"
    "WaySegments": { type: "ARRAY" },
    // "null"
    "AddressId": { type: "HIDDEN" },
    // "null"
    "AddressConfidence": { type: "NUMBER" },
    // "{}"
    "AddressAttributes": GeoAttributes,
    // "null"
    "AddressTimeStamp": { type: "HIDDEN" },
    // "[]"
    "Addresses": { type: "ARRAY" },
    // "[\"Nordre Aker sone G\",\"Alle soner\",\"2300\"]"
    "ParkingRightGeoRestrictions": { type: "OBJECT" },
    // "1"
    "GeoMappingProcessed": { type: "BOOLEAN" },
    // "\"2021-12-16T18:42:05.096Z\""
    "GeoMappingTime": { type: "DATETIME" },
    // "{\"name\":\"Vestgrensa\",\"highway\":\"residential\",\"Geo-Area\":0,\"Geo-Length\":109.76404253097}"
    "Attributes": GeoAttributes,
    // "1"
    "DetectionVersion": { type: "NUMBER" },
    // "1"
    "ParkingRightVersion": { type: "NUMBER" },
    // "null"
    "AreaCode": { type: "TEXT" },
    // "null"
    "ParkingSpaceCode": { type: "TEXT" },
    // "\"******\""
    "Match": { type: "TEXT" },
    // "\"2021-12-01T13:12:45.046Z\""
    "ParkingRightCheckTime": { type: "DATETIME" },
    // "\"Cecilie Gjersøe\""
    "ParkingRightRequestUser": { type: "TEXT" },
    // "\"ScanAuto2\""
    "ParkingRightRequestDevice": { type: "TEXT" },
    // "\"DetectionStream\""
    "ParkingRightRequestMessageType": { type: "TEXT" },
    // "1"
    "VerificationVersion": { type: "NUMBER" },
    // "\"281629606871043\""
    "VerificationDeviceId": { type: "TEXT" },
    // "\"Pda173\""
    "VerificationDevice": { type: "TEXT" },
    // "null"
    "FineNumber": { type: "TEXT" },
    // "{}"
    "FineData": { type: "OBJECT" },
    // "0"
    "FineIsPaid": { type: "BOOLEAN" },
    // "null"
    "FinePaidReference": { type: "HIDDEN" },
    // "null"
    "FinePaidTime": { type: "DATETIME" },
    // "null"
    "FineImage": { type: "HIDDEN" },
    // "\"Unit2\""
    "VerificationEnforceUnit": { type: "TEXT" },
    // "null"
    "FineAmount": { type: "TEXT" },
    // "0"
    "FineValid": { type: "BOOLEAN" },
    "keyDigital": { type: "HIDDEN" },
    "keyTimeLimitedParking": { type: "HIDDEN" },
    "keyIllegallyParked": { type: "HIDDEN" },
    "keyParkingRight": { type: "HIDDEN" },
    "keyVerification": { type: "HIDDEN" },
    "keyDetectionState": { type: "HIDDEN" },
    "OffenceCode": { type: 'TEXT' },
    "OffenceText": { type: 'TEXT' },
    "OffenceDescription": { type: 'TEXT' },
    "OffencePrice": { type: 'TEXT' },
    "SessionVerificationChannel": { type: 'TEXT' },
    "SessionEnforceUnit": { type: 'TEXT' },
    "HasCapacity": { type: 'BOOLEAN' },
    "MustFollowUp": { type: 'BOOLEAN' },
    "ForceFollowUp": { type: 'BOOLEAN' },
    "SegmentName": { type: 'TEXT' },
    "WaySegmentName": { type: 'TEXT' },
    "AddressName": { type: 'TEXT' },
    "Regions": { type: 'ARRAY' },
    "Region": { type: 'TEXT' },
    "RegionId": { type: 'HIDDEN' },
    "RegionName": { type: 'TEXT' },
    "RegionConfidence": { type: 'NUMBER' },
    "RegionAttributes": GeoAttributes,
    "RegionTimeStamp": { type: 'DATETIME' },
    "RouteAreas": { type: 'ARRAY' },
    "RouteArea": { type: 'TEXT' },
    "RouteAreaId": { type: 'HIDDEN' },
    "RouteAreaName": { type: 'TEXT' },
    "RouteAreaConfidence": { type: 'NUMBER' },
    "RouteAreaAttributes": GeoAttributes,
    "RouteAreaTimeStamp": { type: 'DATETIME' },
    "CorrectionDeviceId": { type: 'TEXT' },
    "CorrectionDevice": { type: 'TEXT' },
    "CorrectionUser": { type: 'TEXT' },
    "CorrectionTime": { type: 'DATETIME' },
    "FinalVerificationDevice": { type: 'TEXT' },
    "DetectionExpireTime": { type: 'DATETIME' },
    "CallingCode": { type: 'TEXT' },
    "CountryCodeISO2": { type: 'TEXT' },
    "CountryCodeISO3": { type: 'TEXT' },
    "GeoType": { type: 'TEXT' },
    "GeoId": { type: 'NUMBER' },
    "GeoVersion": { type: 'DATETIME' },
    "GeoCreated": { type: 'DATETIME' },
    "HourOfDay": { type: 'NUMBER' },
    "FinalizedSuccess": { type: 'BOOLEAN' },
};
function getCircularReplacer() {
    const ancestors = [];
    return function (key, value) {
        if (typeof value !== "object" || value === null) {
            return value;
        }
        // `this` is the object that value is contained in,
        // i.e., its direct parent.
        while (ancestors.length > 0 && ancestors.at(-1) !== this) {
            ancestors.pop();
        }
        if (ancestors.includes(value)) {
            return "[Circular]";
        }
        ancestors.push(value);
        return value;
    };
}
