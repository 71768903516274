import { AError } from "../../classes/AError.js";
import { COLUMN_DATETIME, COLUMN_HIDDEN, COLUMN_TEXT, DATA_DATETIME } from "../../classes/AGridTypes.js";
import { AEngine } from "../../core/AEngine.js";
import { ATemplates } from "../../core/ATemplateService.js";
import { AUserManualService } from "../../services/AUserManualService.js";
import { AConvertToGridColumns, AConvertToGridData, AFormatBytes, AShowTable, AUrlEncodedImageFromBase64 } from "../../utils/tools.js";
export class APage {
    constructor() {
        this.userManualService = AEngine.get(AUserManualService);
    }
    async init() {
        $('#upload-image').on('click', (e) => this.userManualService.showUploadForm({ onUploaded: () => this.refresh() }));
        await this.refresh();
    }
    async refresh() {
        return Loading.waitForPromises(this.userManualService.fetchAllImages()).then(async (ares) => {
            this.grid = AShowTable({
                appendTo: 'table-bryntum',
                aci: { resizeToFit: true, resizeToFitReverse: true, skipResizeColumns: ['AltText'] },
                columns: AConvertToGridColumns(ares, {
                    ImageId: COLUMN_HIDDEN,
                    SmallImage: {
                        text: '',
                        cellCls: 'np-i',
                        searchable: false,
                        htmlEncode: false,
                        renderer: ({ value, size }) => {
                            size.width = 128;
                            size.height = 128;
                            return `<img width="128px" height="128px" src="${AUrlEncodedImageFromBase64(value)}" />`;
                        }
                    },
                    AltText: {
                        ...COLUMN_TEXT,
                        flex: 1,
                    },
                    FileSize: {
                        htmlEncode: false,
                        renderer: ({ value }) => `${AFormatBytes(value ?? 0)}`
                    },
                    CreatedAt: COLUMN_DATETIME,
                }),
                data: AConvertToGridData(ares, {
                    CreatedAt: DATA_DATETIME,
                }),
            });
            this.grid.on('cellclick', ({ record, column }) => {
                // console.log(record.originalData)
                if (!record || record.id && record.id?.indexOf('group-header') !== -1) {
                    return;
                }
                AEngine.log('clicked', record.data);
            });
        }).catch(AError.handle);
    }
}
export function render() {
    return ( /*HTML*/`
    <div id="Rapport" class="flex-child bryntum-container has-footer-2">
      <div class="flex-content">
        <div template="${ATemplates.WaitingForInput}"></div>
        <div id="table-bryntum" class="fh hidden"></div>
      </div>
      
      <div class="columns footer aci">
        <div class="column col-2 col-ml-auto">
          <button id="upload-image" class="btn btn-primary col-12">Upload Image</button>
          <button id="export" class="btn btn-primary col-12 hidden" disabled="disabled">Export</button>
        </div>
      </div>
    </div>
  `);
}
