import { AError } from "../../classes/AError.js";
import { COLUMN_ACTION, COLUMN_BOOLEAN, COLUMN_DATETIME, COLUMN_HIDDEN, COLUMN_IMAGE, COLUMN_NUMBER, COLUMN_PRICE, DATA_DATETIME, DATA_HHMMSS, DATA_IMAGE } from "../../classes/AGridTypes.js";
import { AEngine } from "../../core/AEngine.js";
import { ATemplates } from "../../core/ATemplateService.js";
import { ADetectionService } from "../../services/ADetectionService.js";
import { generateWhereClause } from "../../utils/maps.js";
import { DetectionsFullImagesRanged, DetectionsFullRanged, ReportSelectFieldsArr, ReportsImageSelectFields, ReportsSelectFields, UnificationTypes } from "../../utils/query.js";
import { AConvertToGridColumns, AConvertToGridData, AShowTable, appendResponseRows } from "../../utils/tools.js";
export class APage {
    constructor() {
        FilterManager.load();
        $('#RefreshButton').on('click', _ => FilterManager.showFilterWarning().then(_ => this.refresh().catch(AError.handle)));
    }
    fetchQueryParts(filters) {
        if (filters.Images) {
            return {
                subQuery: DetectionsFullImagesRanged,
                select: ReportsImageSelectFields
            };
        }
        else {
            return {
                subQuery: DetectionsFullRanged,
                select: ReportsSelectFields
            };
        }
    }
    async refresh() {
        const filters = FilterManager.saveExplicit();
        FilterManager.setActive(false);
        const { select, subQuery } = this.fetchQueryParts(filters);
        const whereClause = generateWhereClause({ filters });
        const response = await Loading.waitForPromises(requestService.query({
            Query: (`
          SELECT ${select} FROM (${subQuery}) full
          WHERE ${whereClause}
          LIMIT :Limit
        `),
            Name: `Reports`,
            Params: filters,
            Language: Language,
            Translate: ["Device", "Right", "ParkingArea", "Result", "Moving"].concat(UnificationTypes)
        }));
        FilterManager.setActive(true);
        if (!response.Rows.length) {
            return Alerts.noResults();
        }
        appendResponseRows(response, ['Action1', 'Action2']);
        const scrollTop = (this.grid) ? this.grid.storeScroll() : null;
        this.grid = AShowTable({
            appendTo: 'table-bryntum',
            columns: AConvertToGridColumns(response, {
                MappedOnRoute: COLUMN_BOOLEAN,
                UsedInOccupancy: COLUMN_BOOLEAN,
                DetectionTime: COLUMN_DATETIME,
                // Time: COLUMN_TIME_HHMMSS,
                Confidence: COLUMN_NUMBER,
                GpsPrecision: COLUMN_NUMBER,
                FinalVerificationDistanceToVehicle: COLUMN_NUMBER,
                Image: { ...COLUMN_IMAGE, hidden: filters.Images === false },
                DetectionValid: COLUMN_BOOLEAN,
                OffenceCode: COLUMN_HIDDEN,
                OffencePrice: COLUMN_PRICE,
                IllegallyParked: {
                    htmlEncode: false,
                    renderer: ({ value, record, cellElement }) => {
                        if (record.keyIllegallyParked === illegallyParkedRef.Options.NotIllegallyParked.Key) {
                            cellElement?.classList?.add('text-grey-i');
                        }
                        return value;
                    },
                },
                TimeLimitedParking: {
                    htmlEncode: false,
                    renderer: ({ value, record, cellElement }) => {
                        if (record.keyTimeLimitedParking === timeLimitedParkingRef.Options.NotTimeLimited.Key) {
                            cellElement?.classList?.add('text-grey-i');
                        }
                        return value;
                    },
                },
                Verification: {
                    htmlEncode: false,
                    renderer: ({ value, record, cellElement }) => {
                        if (record.keyVerification === verificationRef.Options.NoVerificationNeeded.Key) {
                            cellElement?.classList?.add('text-grey-i');
                        }
                        return value;
                    },
                },
                Action1: {
                    field: 'Action1',
                    ...COLUMN_ACTION({
                        iconCls: 'fa-solid fa-clock-rotate-left',
                        btnCls: 'btn-white',
                        onClick: ({ record }) => {
                            const { DetectionId, DetectionDeviceId } = record.originalData;
                            if (!DetectionId || !DetectionDeviceId) {
                                return Alerts.noResults();
                            }
                            AEngine.get(ADetectionService).findHistory({ DetectionDeviceId, DetectionId });
                        },
                    }),
                },
                Action2: {
                    field: 'Action2',
                    ...COLUMN_ACTION({
                        iconCls: 'fa-regular fa-map-location',
                        btnCls: 'btn-white',
                        onClick: ({ record }) => {
                            const { DetectionId, DetectionDeviceId, DetectionTime } = record.originalData;
                            detectionService.modalShowOnMap({ DetectionId, DetectionDeviceId, DetectionTime }).catch(AError.handle);
                        }
                    }),
                }
            }, ReportSelectFieldsArr),
            data: AConvertToGridData(response, {
                Action1: () => '',
                Action2: () => '',
                DetectionTime: DATA_DATETIME,
                Time: DATA_HHMMSS,
                Image: DATA_IMAGE
            })
        });
        if (scrollTop) {
            this.grid.restoreScroll(scrollTop);
        }
    }
}
export function render() {
    return ( /*html*/`
    <div id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column c-scroll col-12">
        <div class="form-group">
          <label class="form-label" for="FromDate">From</label>
          <input class="form-input" type="date" id="FromDate" required="required">
          <input class="form-input" type="time" id="FromTime" required="required">
        </div>
        <div class="form-group">
          <label class="form-label" for="ToDate">To</label>
          <input class="form-input" type="date" id="ToDate" required="required">
          <input class="form-input" type="time" id="ToTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="DetectionDeviceId">DeviceId</label>
          <input class="form-input" type="text" id="DetectionDeviceId" placeholder="00000000" maxlength="20">
        </div>
        <div class="form-group">
          <label class="form-label" for="DetectionId">DetectionId</label>
          <input class="form-input" type="text" id="DetectionId" placeholder="00000000" maxlength="20">
        </div>
        <div class="form-group">
          <label class="form-label" for="LicensePlate">LicensePlate</label>
          <input class="form-input" type="text" id="LicensePlate" placeholder="XXXXXX" maxlength="32" LicensePlate>
        </div>
        <div class="form-group">
          <label class="form-label" for="Limit">Max results</label>
          <input class="form-input" type="number" id="Limit" value="2000">
        </div>
        <div class="form-group">
          <label class="form-switch">
            <input id="Images" type="checkbox">
            <i class="form-icon"></i> Images
          </label>
        </div>
      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </div>
    <div id="Rapport" class="flex-child bryntum-container has-footer-2">
      <div template="${ATemplates.WaitingForInput}"></div>
      <div id="table-bryntum" class="hidden"></div>

      <div class="columns footer aci">
        <div class="column col-2">
          <div id="count" class="text">Viewing <span>0</span> Scans</div>
        </div>
        <div class="column col-2 col-ml-auto">
          <button id="export" class="btn btn-primary col-12" disabled="disabled">Export</button>
        </div>
      </div>
    </div>
  `);
}
