export class AWidgetOrm {
    async fetchAll(opt) {
        const rows = await requestService.fetch({
            AssertValues: true,
            Query: (`SELECT * FROM backoffice_widgets WHERE View=:View ORDER BY Sort`),
            Params: { ...opt },
            Language: Language
        });
        return rows;
    }
    async find(opt) {
        const rows = await requestService.fetch({
            AssertValues: true,
            Query: (`
        SELECT * FROM backoffice_widgets
        WHERE View=:View AND Name=:Name AND KpiType=:KpiType
        ORDER BY Sort
        LIMIT 1
      `),
            Params: { ...opt },
            Language: Language
        });
        return rows.First;
    }
    async create(options) {
        await requestService.query({
            Query: (`
        INSERT INTO backoffice_widgets
          (View, Name, KpiType, Sort, Width, Settings)
        VALUES
          (:View, :Name, :KpiType, :Sort, :Width, :Settings)
        ON DUPLICATE KEY UPDATE
          Sort=:Sort, Width=:Width, Settings=:Settings, Active=1
      `),
            Params: {
                View: options.View,
                Name: options.Name,
                KpiType: options.KpiType,
                Sort: options.Sort || 0,
                Width: options.Width,
                Settings: options.Settings || null
            }
        });
    }
    async update(options, fields) {
        const allowedColumns = ['View', 'Name', 'KpiType', 'Sort', 'Width', 'Settings', 'Active'];
        // Prevent Accidental Injection
        const onDuplicateUpdate = fields.filter(v => allowedColumns.includes(v));
        await requestService.query({
            Query: (`
        INSERT INTO backoffice_widgets
          (View, Name, KpiType, Sort, Width, Settings, Active)
        VALUES
          (:View, :Name, :KpiType, :Sort, :Width, :Settings, :Active)
        ON DUPLICATE KEY UPDATE
          ${onDuplicateUpdate.map(key => key + '=:' + key).join(', ')}
      `),
            // TODO: Replace dynamic update statement with predefined list:
            //    View=:View,
            //    Name=:Name,
            //    KpiType=:KpiType,
            //    Sort=:Sort,
            //    Width=:Width,
            //    Settings=:Settings,
            //    Active=:Active
            Params: options
        });
    }
    async disableWidget(options) {
        // DELETE FROM backoffice_widgets
        // WHERE View=:View AND Name=:Name AND KpiType=:KpiType
        await requestService.query({
            Query: (`
        INSERT INTO backoffice_widgets (View, Name, KpiType, Settings, Sort, Width, Active)
        VALUES (:View, :Name, :KpiType, :Settings, :Sort, :Width, 0)
        ON DUPLICATE KEY UPDATE Active=0, Sort=100
      `),
            Params: options
        });
    }
    async deleteWidgetsInView(options) {
        await requestService.query({
            Query: (`
        DELETE FROM backoffice_widgets
        WHERE View=:View
      `),
            Params: options
        });
    }
}
