import { AError } from "../../classes/AError.js";
import { ALERT_TITLES } from "../../services/AAlertService.js";
export class APage {
    constructor() {
        this.IgnoredColumns = ["Created", "Modified"];
        this.InitSucces = this.Init();
        this.ShowUnificationParkingRightType(document.querySelector('#ParkingRightType'));
        this.ShowUnificationVerificationResult(document.querySelector('#VerificationResult'));
    }
    async Init() {
        this.UnificationParkingRightType = undefined;
        this.UnificationVerificationResult = undefined;
        this.UnificationParkingRightTypeValues = undefined;
        this.UnificationVerificationResultValues = undefined;
        function LoadOptions(QueryResult) {
            let Options = {};
            for (let Row of QueryResult.Rows) {
                let Key = Row[0].toUpperCase();
                if (Options[Key] == undefined)
                    Options[Key] = [];
                Options[Key].push(Row[1]);
                Options[Key].push(Row[2]);
            }
            for (let Key in Options) {
                Options[Key] = Options[Key].filter(function (value, index, self) {
                    return self.indexOf(value) === index && value !== null;
                }).join("\r\n");
            }
            return Options;
        }
        function LoadUnifications(QueryResult) {
            let Unifications = {};
            for (let Row of QueryResult.Rows) {
                let Obligatory = Row[2] != "YES";
                let MysqlType = Row[1];
                let Column = Row[0];
                let Default = Row[4];
                let Type;
                let Options = null;
                if (MysqlType.startsWith("enum(")) {
                    Type = "Enum";
                    Options = MysqlType.slice(5, MysqlType.length - 1).split(",").map(Quoted => { return Quoted.slice(1, Quoted.length - 1); });
                }
                else if (MysqlType.startsWith("tinyint")) {
                    Type = "Bool";
                }
                else {
                    Type = "String";
                }
                if (!PageScript.IgnoredColumns.includes(Column)) {
                    Unifications[Column] = { Type: Type, Options: Options, Obligatory: Obligatory, Default: Default };
                }
            }
            return Unifications;
        }
        function LoadUnificationValues(QueryResult) {
            let UnificationValues = {};
            for (let Row of QueryResult.Rows) {
                let Key = Row[0].toUpperCase();
                UnificationValues[Key] = {};
                for (let c = 1; c < QueryResult.Columns.length; c++) {
                    let Column = QueryResult.Columns[c];
                    if (!PageScript.IgnoredColumns.includes(Column)) {
                        UnificationValues[Key][QueryResult.Columns[c]] = Row[c];
                    }
                }
            }
            return UnificationValues;
        }
        try {
            let ParkingRightTypesPromise = requestService.query({
                Query: "select distinct ParkingRightType,ParkingRightTypeText, ParkingRightType as ParkingRightTypeTranslated from  parkingrights ORDER BY ParkingRightType",
                Name: 'ParkingRightTypes',
                Language: Language,
                Translate: ["ParkingRightTypeText", "ParkingRightTypeTranslated"]
            });
            let VerificationResultPromise = requestService.query({
                Query: "select distinct VerificationResult, VerificationResultText, VerificationResult as VerificationResultTranslated from verifications ORDER BY VerificationResult",
                Name: 'VerificationResults',
                Language: Language,
                Translate: ["VerificationResultText", "VerificationResultTranslated"]
            });
            let UnificationParkingRightTypeDescribePromise = requestService.query({
                Query: "DESCRIBE unification_parkingright_type",
                Name: 'DESCRIBE_unification_parkingright_type'
            });
            let UnificationVerificationResultDescribePromise = requestService.query({
                Query: "DESCRIBE unification_verification_result",
                Name: 'DESCRIBE_unification_verification_result'
            });
            let UnificationParkingRightTypeValuesPromise = requestService.query({
                Query: "SELECT * FROM unification_parkingright_type",
                Name: 'SELECT_unification_parkingright_type'
            });
            let UnificationVerificationResultValuesPromise = requestService.query({
                Query: "SELECT * FROM unification_verification_result",
                Name: 'SELECT_unification_verification_result'
            });
            $('#loading').addClass('show1');
            await Promise.all([ParkingRightTypesPromise, VerificationResultPromise, UnificationParkingRightTypeDescribePromise, UnificationParkingRightTypeValuesPromise, UnificationVerificationResultValuesPromise]);
            $('#loading').removeClass('show1');
            this.Options = {
                ParkingRightType: LoadOptions(await ParkingRightTypesPromise),
                VerificationResult: LoadOptions(await VerificationResultPromise)
            };
            this.UnificationParkingRightType = LoadUnifications(await UnificationParkingRightTypeDescribePromise);
            this.UnificationVerificationResult = LoadUnifications(await UnificationVerificationResultDescribePromise);
            this.UnificationParkingRightTypeValues = await LoadUnificationValues(await UnificationParkingRightTypeValuesPromise);
            this.UnificationVerificationResultValues = await LoadUnificationValues(await UnificationVerificationResultValuesPromise);
            console.log("Done");
            return true;
        }
        catch (e) {
            console.error(e);
            return false;
        }
    }
    async RunUnificationForm(Dst, UnificationType, UnificationData, Values, Options) {
        let Table = document.createElement("table");
        let HeaderRow = document.createElement("tr");
        let ColCount = 0;
        for (let Unification in UnificationData) {
            let UnificationInfo = UnificationData[Unification];
            let HeaderColumn = document.createElement("th");
            HeaderColumn.innerText = Unification;
            HeaderRow.appendChild(HeaderColumn);
            ++ColCount;
        }
        let HeaderColumn = document.createElement("th");
        HeaderRow.appendChild(HeaderColumn);
        ++ColCount;
        Table.appendChild(HeaderRow);
        let PreviousUption = null;
        let OriginalValues = {};
        for (let Option in Options[UnificationType]) {
            let HelpText = Options[UnificationType][Option];
            let Row = document.createElement("tr");
            for (let Unification in UnificationData) {
                let UnificationInfo = UnificationData[Unification];
                if (Values[Option] == undefined) {
                    Values[Option] = {};
                }
                // let Enabled = true;
                // let CheckBox = null;
                // let ControlField = null;
                let Column = document.createElement("td");
                if (HelpText) {
                    Column.title = HelpText;
                }
                // console.log(UnificationType, Unification)
                if (Unification == UnificationType) {
                    Column.innerText = Option;
                    Column.classList.add("OptionTitleColumn");
                }
                else {
                    if (Values[Option][Unification] == undefined) {
                        Values[Option][Unification] = UnificationInfo.Default;
                    }
                    if (OriginalValues[Option] == undefined) {
                        OriginalValues[Option] = {};
                    }
                    OriginalValues[Option][Unification] = Values[Option][Unification];
                    let Value = Values[Option][Unification];
                    switch (UnificationInfo.Type) {
                        case "Enum":
                            {
                                let InvalidOption = null;
                                let Select = document.createElement("select");
                                // ControlField = Select;
                                Select.id = Option + "_" + Unification + "_value";
                                if (UnificationInfo.Obligatory) {
                                    if (Value === null) {
                                        InvalidOption = document.createElement("option");
                                        InvalidOption.innerText = "Select ...";
                                        // @ts-ignore
                                        InvalidOption.value = null;
                                        InvalidOption.disabled = true;
                                        InvalidOption.selected = true;
                                        Select.appendChild(InvalidOption);
                                        Select.classList.add("error");
                                    }
                                }
                                else {
                                    let EmptyOption = document.createElement("option");
                                    EmptyOption.innerText = "-";
                                    // @ts-ignore
                                    EmptyOption.value = null;
                                    if (Value === null) {
                                        EmptyOption.selected = true;
                                    }
                                    Select.appendChild(EmptyOption);
                                }
                                for (let EnumOption of UnificationInfo.Options) {
                                    let SelectOption = document.createElement("option");
                                    SelectOption.value = EnumOption;
                                    SelectOption.innerText = await Translate.get(EnumOption);
                                    if (Value == EnumOption) {
                                        SelectOption.selected = true;
                                    }
                                    Select.appendChild(SelectOption);
                                }
                                Select.onchange = function () {
                                    Values[Option][Unification] = Select.value === "null" ? null : Select.value;
                                    if (InvalidOption) {
                                        Select.removeChild(InvalidOption);
                                        InvalidOption = null;
                                        Select.classList.remove("error");
                                    }
                                    // console.log(Select.value);
                                };
                                Column.appendChild(Select);
                            }
                            break;
                        case "Bool":
                            {
                                let Select = document.createElement("select");
                                // ControlField = Select;
                                Select.id = Option + "_" + Unification + "_value";
                                let InvalidOption = 0;
                                if (UnificationInfo.Obligatory) {
                                    if (Value === null) {
                                        InvalidOption = document.createElement("option");
                                        InvalidOption.innerText = "Select ...";
                                        ;
                                        InvalidOption.value = null;
                                        InvalidOption.disabled = true;
                                        InvalidOption.selected = true;
                                        Select.appendChild(InvalidOption);
                                        Select.classList.add("error");
                                    }
                                }
                                else {
                                    let EmptyOption = document.createElement("option");
                                    EmptyOption.innerText = "-";
                                    // @ts-ignore
                                    EmptyOption.value = null;
                                    if (Value === null) {
                                        EmptyOption.selected = true;
                                    }
                                    Select.appendChild(EmptyOption);
                                }
                                let TrueOption = document.createElement("option");
                                TrueOption.innerText = "Yes";
                                // @ts-ignore
                                TrueOption.value = 1;
                                if (Value) {
                                    TrueOption.selected = true;
                                }
                                Select.appendChild(TrueOption);
                                let FalseOption = document.createElement("option");
                                FalseOption.innerText = "No";
                                // @ts-ignore
                                FalseOption.value = 0;
                                if (Value === 0) {
                                    FalseOption.selected = true;
                                }
                                Select.appendChild(FalseOption);
                                Select.onchange = function () {
                                    Values[Option][Unification] = Select.value;
                                    if (InvalidOption) {
                                        Select.removeChild(InvalidOption);
                                        InvalidOption = null;
                                        Select.classList.remove("error");
                                    }
                                    // console.log(Select.value);
                                };
                                Column.appendChild(Select);
                            }
                            break;
                    }
                }
                Row.appendChild(Column);
            }
            let CopyColumn = document.createElement("td");
            if (PreviousUption !== null) {
                let CopyOption = PreviousUption;
                let CopyButton = document.createElement("button");
                CopyButton.innerHTML = "<i class='fa fa-copy'></i>";
                CopyButton.onclick = function () {
                    let CopyUnifications = Values[CopyOption];
                    // console.log(CopyUnifications);
                    for (let CopyUnification in CopyUnifications) {
                        if (CopyUnification != UnificationType) {
                            Values[Option][CopyUnification] = CopyUnifications[CopyUnification];
                            let Id = Option + "_" + CopyUnification + "_value";
                            let Edit = document.getElementById(Id);
                            if (!Edit) {
                                console.error("No ", Id);
                            }
                            else {
                                Edit.value = CopyUnifications[CopyUnification];
                                // console.log(CopyUnification, Edit.value, UnificationData[CopyUnification].Obligatory, Edit.value !== null, UnificationData[CopyUnification].Obligatory !== true)
                                if (CopyUnifications[CopyUnification] !== null || UnificationData[CopyUnification].Obligatory !== true) {
                                    Edit.classList.remove("error");
                                }
                            }
                        }
                    }
                };
                CopyColumn.appendChild(CopyButton);
            }
            Row.appendChild(CopyColumn);
            Table.appendChild(Row);
            PreviousUption = Option;
        }
        let ButtonRow = document.createElement("tr");
        let EmptyColumn = document.createElement("td");
        EmptyColumn.setAttribute("colspan", (`${ColCount - 2}`));
        let ButtonColumn = document.createElement("td");
        ButtonColumn.setAttribute("colspan", `${2}`);
        let ApplyButton = document.createElement("button");
        ApplyButton.innerText = "Apply";
        ApplyButton.className += "btn btn-primary btn-sm fw";
        ButtonColumn.appendChild(ApplyButton);
        ButtonRow.appendChild(EmptyColumn);
        ButtonRow.appendChild(ButtonColumn);
        Table.appendChild(ButtonRow);
        while (Dst.firstChild) {
            Dst.removeChild(Dst.firstChild);
        }
        Dst.appendChild(Table);
        let resolveFunction = null;
        let rejectFunction = null;
        // console.log(Values);
        let ThisTemp = this;
        ApplyButton.onclick = function () {
            let Errors = [];
            let NewValues = {};
            for (let Option in Values) {
                let Store = false;
                for (let Unification in Values[Option]) {
                    console.log(Unification);
                    if (Values[Option][Unification] === null && UnificationData[Unification].Obligatory) {
                        Errors.push(Option + " - " + Unification);
                        Store = false;
                        break;
                    }
                    else if (OriginalValues[Option] === undefined || OriginalValues[Option][Unification] === undefined || OriginalValues[Option][Unification] !== Values[Option][Unification]) {
                        //console.log(Unification, " | ", OriginalValues[Option][Unification], ' => ', Values[Option][Unification], OriginalValues[Option], Values[Option]);
                        Store = true;
                    }
                }
                if (Store) {
                    NewValues[Option] = {};
                    for (let Unification in Values[Option]) {
                        NewValues[Option][Unification] = Values[Option][Unification];
                    }
                }
            }
            if (Object.keys(NewValues).length == 0) {
                console.log(NewValues);
                // alert("Nothing changed");
                Alerts.show({
                    title: ALERT_TITLES.Success,
                    content: 'Nothing changed'
                });
            }
            else if (!Errors.length) {
                // console.log(Values);
                resolveFunction(NewValues);
            }
            else {
                alert("Please fill in the remaining values:\r\n" + Errors.join("\r\n"));
                resolveFunction(NewValues);
            }
            for (let Option in NewValues) {
                for (let Unification in NewValues[Option]) {
                    OriginalValues[Option][Unification] = NewValues[Option][Unification];
                }
            }
        };
        return new Promise(function (resolve, reject) {
            resolveFunction = resolve;
            rejectFunction = reject;
        });
    }
    InsertToDB(Type, Table, Result, UnificationData) {
        let Keys = Object.keys(UnificationData);
        // for (let Option in Result) {
        //    let KeyIndex = Keys.indexOf(Option);
        //    if(KeyIndex==-1)
        //    {
        //       console.log(Option,Keys );
        //       delete Result[Option];
        //    }
        // }
        let Query = "REPLACE INTO " + Table + " (" + Keys.join(", ") + ") VALUES";
        let Count = 0;
        let Values = {};
        for (let Option in Result) {
            if (Count) {
                Query += ", ";
            }
            Count++;
            let Ids = [];
            let Id = Type + Count;
            Ids.push(":" + Id);
            Values[Id] = Option;
            for (let Value in Result[Option]) {
                let Id = Value + Count;
                Ids.push(":" + Id);
                Values[Id] = Result[Option][Value];
            }
            Query += "(" + Ids.join(", ") + ")";
        }
        console.log(Query);
        console.log(Values);
        let ReplacePromise = requestService.query({
            Query: Query,
            Name: 'Replace ParkingRightTypes',
            Params: Values
        });
        return ReplacePromise;
    }
    async ShowUnificationParkingRightType(Dst) {
        while (true) {
            if (!await this.InitSucces) {
                console.error("Page not Initialized");
                return;
            }
            let Result = await this.RunUnificationForm(Dst, "ParkingRightType", this.UnificationParkingRightType, this.UnificationParkingRightTypeValues, this.Options);
            try {
                await this.InsertToDB("ParkingRightType", "unification_parkingright_type", Result, this.UnificationParkingRightType);
                Alerts.show({
                    title: ALERT_TITLES.Success,
                    content: await Translate.get('Stored to database')
                });
            }
            catch (err) {
                AError.handle(err);
            }
            this.InitSucces = this.Init();
        }
    }
    async ShowUnificationVerificationResult(Dst) {
        while (true) {
            if (!await this.InitSucces) {
                console.error("Page not Initialized");
                return;
            }
            let Result = await this.RunUnificationForm(Dst, "VerificationResult", this.UnificationVerificationResult, this.UnificationVerificationResultValues, this.Options);
            try {
                await this.InsertToDB("VerificationResult", "unification_verification_result", Result, this.UnificationVerificationResult);
                // alert("Stored to database");
                Alerts.show({
                    title: ALERT_TITLES.Success,
                    content: await Translate.get('Stored to database')
                });
            }
            catch (e) {
                alert(e);
            }
            this.InitSucces = this.Init();
        }
    }
}
export function css() {
    return ( /*html*/`
    <style>
      .UnificationForm {
        font-size: calc(.775vw - 1.5px);
      }

      .UnificationForm .OptionTitleColumn {
        width: 16.5vw;
      }

      .UnificationForm th {
        padding: 2px;
        text-align: center;
        font-weight: bold;
      }

      .UnificationForm td {
        padding: 2px;
      }

      .UnificationForm tr {
        border-top: 1px solid #aaa;
      }

      .UnificationForm select {
        font-family: OpenSans;
        font-weight: 1.2vw;
        width: auto;
      }

      #loading.show1 {
        opacity: 1;
      }
    </style>
  `);
}
export function render() {
    return ( /*html*/`
    <div id="unify-container">
      <h2 class="m-2">ParkingRightType</h2>
      <div id="ParkingRightType" class="UnificationForm"></div>
      <br>
      <h2 class="m-2">VerificationResult</h2>
      <div id="VerificationResult" class="UnificationForm"></div>
    </div>
  `);
}
