import { ADetectionStatistics } from "../../classes/ADetectionStatistics.js";
async function StatisticsTest() {
    let Segments = {};
    let Streets = {};
    let TotalDetectionStatistics = new ADetectionStatistics();
    let QueriesStart = performance.now();
    let DetectionsFinal = await requestService.query('SELECT Digital, IllegallyParked, TimeLimitedParking, ParkingRight, Verification, DetectionState, count(*) as Total, segmentid from detections_final where FinalizedSuccess = 1 group by Segmentid, Digital, TimeLimitedParking, IllegallyParked, ParkingRight,Verification;');
    let SegmentEntries = await requestService.query('SELECT SUM(OccupancyCount) AS OccupancySum, SUM(Capacity) AS CapacitySum, count(*) as EntryCount, SegmentId FROM segment_entries group by SegmentId');
    let SegmentIdToStreetResult = await requestService.query('SELECT SegmentId, Name FROM geo_waysegments inner join geo_segments2waysegments using(WaySegmentId) group by SegmentId;');
    let QueriesEnd = performance.now();
    console.log("Queries done in:", QueriesEnd - QueriesStart, " milliseconds");
    let SegmentIdToStreet = {};
    let Start = performance.now();
    for (let Row of SegmentIdToStreetResult.Rows) {
        SegmentIdToStreet[Row[0]] = Row[1];
    }
    for (let Row of DetectionsFinal.Rows) {
        let SegmentId = Row[6];
        if (Segments[SegmentId] === undefined) {
            Segments[SegmentId] = new ADetectionStatistics();
        }
        let Street = SegmentIdToStreet[SegmentId];
        if (Streets[Street] === undefined) {
            Streets[Street] = new ADetectionStatistics();
        }
        Segments[SegmentId].addDetectionFinalCount(Row[0], Row[1], Row[2], Row[3], Row[4], Row[5], Number(Row[6]));
        Streets[Street].addDetectionFinalCount(Row[0], Row[1], Row[2], Row[3], Row[4], Row[5], Number(Row[6]));
        TotalDetectionStatistics.addDetectionFinalCount(Row[0], Row[1], Row[2], Row[3], Row[4], Row[5], Number(Row[6]));
    }
    for (let Row of SegmentEntries.Rows) {
        let SegmentId = Row[2];
        if (Segments[SegmentId] === undefined) {
            Segments[SegmentId] = new ADetectionStatistics();
        }
        let Street = SegmentIdToStreet[SegmentId];
        if (Streets[Street] === undefined) {
            Streets[Street] = new ADetectionStatistics();
        }
        Streets[Street].addOccupancy(Row[0], Number(Row[1]));
        Segments[SegmentId].addOccupancy(Row[0], Number(Row[1]));
        throw new Error(`Code needs to be updated below!`);
        // v
        // TotalDetectionStatistics.addOccupancy(Row[0], Number(Row[1]))
        // ^
    }
    let End = performance.now();
    console.log("Speed:", End - Start, " milliseconds");
    console.log("Total Statistics:", TotalDetectionStatistics);
    console.log("Segment Statistics:", Segments);
    console.log("Streets Statistics:", Streets);
    let SegmentSimplified = {};
    for (let SegmentId in Segments) {
        SegmentSimplified[SegmentId] = {
            Occupancy: Segments[SegmentId].getOccupancy(),
            VisitorRate: Segments[SegmentId].getVisitorRate()
        };
    }
    console.log("Simplified Segment Statistics:", SegmentSimplified);
}
export class APage {
    init() {
        StatisticsTest();
    }
}
export function render() {
    return ( /*html*/`
    <h3>See Console</h3>
   `);
}
