import { AEngine } from "../core/AEngine.js";
import { ADynamicChartUtils } from "../charts/ADynamicChartUtils.js";
import { AStatisticsService } from "../services/AStatisticsService.js";
import { AConvertToGridData, AShowTable, AConvertToGridColumns, hoursToHHMMSS, AFormatNumber, ARound, secondsToHHMMSS } from "../utils/tools.js";
import { AKpiBlock, AKpiState } from "./AKpiBlock.js";
import { DeviceTypeIdToDeviceType, DeviceTypeToDeviceTypeId } from "../backoffice-initializer-legacy.js";
import { COLUMN_HIDDEN } from "../classes/AGridTypes.js";
import { ADetectionState } from "../classes/AUnificationTypes.js";
export class AKpiBlockPerformance extends AKpiBlock {
    get chartId() { return `${this.idFilter}-Chart`; }
    get tableId() { return `${this.idFilter}-Table`; }
    constructor(opt) {
        super({
            refreshImplemented: true,
            optionsImplemented: true,
            TranslateTitle: true,
            ...opt
        });
        this.viewTable = true;
        this.chartUtils = new ADynamicChartUtils();
        this.mapTo = opt.Settings['mapTo'];
        this.verticalAxis = opt.Settings['verticalAxis'];
        this.groupBy = opt.Settings['GroupBy'];
        // this.createResizeListener()
    }
    async init() {
        super.init();
        this.translations = await Translate.get(['System']);
    }
    async fetchChartData_CentralVerification(filters) {
        const statisticService = AEngine.get(AStatisticsService);
        // new AVerification().Options.NotProcessed.Options.
        const dynamicStats = await statisticService.fetchDynamic({
            ...filters,
            DeviceTypeId: DeviceTypeToDeviceTypeId('CentralVerification'),
            DetachedFromPda: detectionStateRef.Options.InProgress.Options.DetachedFromPda.FirstIndex,
            DetachedFromCentralVerification: detectionStateRef.Options.InProgress.Options.DetachedFromCentralVerification.FirstIndex,
        }, {
            baseTable: `detections_final_history`,
            columns: [{
                    name: 'VerificationUser',
                    select: `NULLIF(df.FinalVerificationUser, '')`,
                    groupBy: true,
                }, {
                    name: 'DeviceType',
                    select: 'IF(v.VerificationDeviceId IS NULL, NULL, (v.VerificationDeviceId & 0x000000000000ffff))',
                    groupBy: true
                }, {
                    name: 'VerificationDuration',
                    select: 'SUM(TIMESTAMPDIFF(SECOND, v.VerificationStartTime, IFNULL(v.VerificationEndTime, NOW())))',
                    method: 'average',
                    groupBy: false,
                }],
            joinTables: [`LEFT JOIN verifications v USING (DetectionId, DetectionDeviceId, VerificationVersion)`],
            where: [
                // `(v.VerificationDeviceId=0 OR (v.VerificationDeviceId & 0x000000000000ffff)=:DeviceTypeId)`,
                `df.DetectionState=:DetachedFromCentralVerification`
            ]
        });
        const data = dynamicStats.toResponse({
            mapTo: {
                'VerificationUser': ([obj, stats]) => obj['VerificationUser'],
                'DeviceType': ([obj, stats]) => DeviceTypeIdToDeviceType(Number(obj['DeviceType'])),
                // 'Suspects': ([obj, stats]) => stats.getSuspects(),
                'Followups': ([obj, stats]) => stats.getSuccessfulFollowUps(),
                'Sanctions': ([obj, stats]) => stats.getSanctions(),
                'Discarded': ([obj, stats]) => stats.getUserDiscardedFollowUps(),
                'SystemDiscarded': ([obj, stats]) => stats.getSystemNotProcessedFollowUps(),
            }
        });
        return data;
    }
    async fetchChartData_Pda(filters) {
        const statisticService = AEngine.get(AStatisticsService);
        // new AVerification().Options.NotProcessed.Options.
        const dynamicStats = await statisticService.fetchDynamic({
            ...filters,
            DeviceTypeId: DeviceTypeToDeviceTypeId('Pda'),
            DetachedFromPda: new ADetectionState().Options.InProgress.Options.DetachedFromPda.FirstIndex,
            DetachedFromCentralVerification: new ADetectionState().Options.InProgress.Options.DetachedFromCentralVerification.FirstIndex,
        }, {
            baseTable: `detections_final_history`,
            columns: [{
                    name: 'VerificationUser',
                    select: `NULLIF(df.FinalVerificationUser, '')`,
                    groupBy: true,
                }, {
                    name: 'DeviceType',
                    select: 'IF(v.VerificationDeviceId IS NULL, NULL, (v.VerificationDeviceId & 0x000000000000ffff))',
                    groupBy: true
                }, {
                    name: 'VerificationDuration',
                    select: 'SUM(TIMESTAMPDIFF(SECOND, v.VerificationStartTime, IFNULL(v.VerificationEndTime, NOW())))',
                    method: 'average',
                    groupBy: false,
                }, {
                    name: 'DistanceToVehicle',
                    select: 'SUM(IFNULL(df.FinalVerificationDistanceToVehicle, 0)) / SUM(IF(df.FinalVerificationDistanceToVehicle IS NOT NULL, 1, 0))',
                    method: 'average',
                    groupBy: false,
                }],
            joinTables: [`LEFT JOIN verifications v USING (DetectionId, DetectionDeviceId, VerificationVersion)`],
            where: [
                // `(v.VerificationDeviceId=0 OR (v.VerificationDeviceId & 0x000000000000ffff)=:DeviceTypeId)`,
                `df.DetectionState=:DetachedFromPda`
            ]
        });
        const data = await dynamicStats.toResponse({
            mapTo: {
                'VerificationUser': ([obj, stats]) => obj['VerificationUser'],
                'DistanceToVehicle': ([obj, stats]) => obj['DistanceToVehicle'],
                'DeviceType': ([obj, stats]) => DeviceTypeIdToDeviceType(Number(obj['DeviceType'])),
                // 'Total': ([obj, stats]) => stats.getTotal(),
                // 'Suspects': ([obj, stats]) => stats.getSuspects(),
                'Followups': ([obj, stats]) => stats.getSuccessfulFollowUps(),
                'Sanctions': ([obj, stats]) => stats.getSanctions(),
                'Discarded': ([obj, stats]) => stats.getUserDiscardedFollowUps(),
                'SystemDiscarded': ([obj, stats]) => stats.getSystemNotProcessedFollowUps(),
            }
        });
        return data;
    }
    async fetchChartData_DetectionUser(filters) {
        const statisticService = AEngine.get(AStatisticsService);
        const usernameMap = await statisticService.fetchUsernameMap();
        const f = {
            ...filters,
            DetachedFromPda: new ADetectionState().Options.InProgress.Options.DetachedFromPda.FirstIndex,
            DetachedFromCentralVerification: new ADetectionState().Options.InProgress.Options.DetachedFromCentralVerification.FirstIndex,
        };
        const statisticsMap = await statisticService.fetchGroupedByDetectionUser(f, {
            usernameMap,
            // where: [
            //   `DetectionState`
            // ],
        });
        const hoursMap = await statisticService.fetchDriverHours(filters, { usernameMap });
        const output = {};
        await Promise.all(Object.keys(statisticsMap).map(async (User) => {
            if (!hoursMap.hasOwnProperty(User)) {
                console.warn(`hoursMap, doesnt have key: ${User}`);
                return;
            }
            const { OperationHours, ScanHours, DistanceKM } = hoursMap[User];
            output[User] = {
                Username: User ?? Translate.getCache('unknown') ?? 'unknown',
                Statistics: statisticsMap[User],
                ScanHours: ScanHours,
                OperationHours: OperationHours,
                DistanceKM: DistanceKM,
            };
        }));
        const columns = ['User', 'ScanHours', 'OperationHours', 'DistanceTravelled', 'Total', 'Followups', 'Sanctions'];
        const translations = await Translate.get(columns);
        const res = {
            Columns: columns,
            ColumnsTranslated: columns.map(c => translations[c]),
            Rows: Object.values(output).map(({ Username, ScanHours, OperationHours, DistanceKM, Statistics }) => {
                return [
                    Username,
                    ScanHours,
                    OperationHours,
                    DistanceKM,
                    Statistics.getTotal(),
                    // Statistics.getSuspects(),
                    Statistics.getSuccessfulFollowUps(),
                    Statistics.getSanctions()
                ];
            })
        };
        return res;
    }
    async refresh(filters = this.filterOption) {
        switch (this.groupBy) {
            case 'CentralVerification':
                this.cachedChartData = await this.fetchChartData_CentralVerification(filters);
                break;
            case 'Pda':
                this.cachedChartData = await this.fetchChartData_Pda(filters);
                break;
            default:
                this.cachedChartData = await this.fetchChartData_DetectionUser(filters);
                break;
        }
        return await this.refreshTable();
    }
    async refreshTable() {
        if (!this.viewTable || this.cachedChartData === undefined || this.cachedChartData.Rows.length === 0) {
            return AKpiState.noResults;
        }
        const data = AConvertToGridData(this.cachedChartData);
        console.log('refreshTable', this.tableId, this.grid);
        if (this.grid) {
            const scrollTop = this.grid.storeScroll();
            this.grid.store.data = data;
            this.grid.restoreScroll(scrollTop);
        }
        else {
            this.grid = AShowTable({
                appendTo: this.tableId,
                columns: AConvertToGridColumns(this.cachedChartData, {
                    'Bars': { text: '' },
                    VerificationUser: {
                        htmlEncode: false,
                        renderer: ({ record, value }) => {
                            console.log(record.VerificationUser, record);
                            return (record.VerificationUser == null) ? this.translations['System'] : record.VerificationUser;
                        }
                    },
                    DeviceType: COLUMN_HIDDEN,
                    OperationHours: { htmlEncode: false, renderer: ({ value }) => { return value ? hoursToHHMMSS(value) : ''; } },
                    ScanHours: { htmlEncode: false, renderer: ({ value }) => { return value ? hoursToHHMMSS(value) : ''; } },
                    DistanceTravelled: { htmlEncode: false, renderer: ({ value }) => { return value ? AFormatNumber(ARound(value, 2)) + ' km' : ''; } },
                    VerificationDuration: { htmlEncode: false, renderer: (r) => { return r.value ? secondsToHHMMSS(r.value) : ''; } },
                    DistanceToVehicle: (this.groupBy === 'CentralVerification') ? COLUMN_HIDDEN : { htmlEncode: false, renderer: (r) => { return r.value ? AFormatNumber(ARound(r.value, 2)) + ' m' : ''; } },
                }),
                data: data
            });
        }
        // this.cachedChartData = undefined
    }
    async render() {
        return await super.renderView({
            title: this.Name,
            viewHtml: ( /*html*/`
        <div filter="chart" class="fh hidden">
          <div id="${this.chartId}"></div>
        </div>
        <div filter="table" class="fh pb-2">
          <div id="${this.tableId}" class="fh"></div>
        </div>
      `),
        });
    }
}
