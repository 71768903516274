import { AError } from "../../classes/AError.js";
import { COLUMN_DATETIME, DATA_DATETIME } from "../../classes/AGridTypes.js";
import { ATemplates } from "../../core/ATemplateService.js";
import { AConvertToGridColumns, AConvertToGridData, AShowTable } from "../../utils/tools.js";
export class APage {
    init() {
        FilterManager.load();
        // this.refresh(true)
        $('#RefreshButton').on('click', _ => FilterManager.showFilterWarning().then(_ => this.refresh()));
    }
    refresh(first) {
        FilterManager.setActive(false);
        FilterManager.save();
        return Loading.waitForPromises(requestService.query({
            Query: "SELECT SessionId, `Time`,`DeviceName`, if(LF_Detecting=1, if(LF_Connected=1, 'On', 'Error'), 'Off') as 'LeftFront', if(LS_Detecting=1, if(LS_Connected=1, 'On', 'Error'), 'Off') as 'LeftSideway',if(LB_Detecting=1, if(LB_Connected=1, 'On', 'Error'), 'Off') as 'LeftBack', if(RF_Detecting=1, if(RF_Connected=1, 'On', 'Error'), 'Off') as 'RightFront', if(RS_Detecting=1, if(RS_Connected=1, 'On', 'Error'), 'Off') as 'RightSideway',if(RB_Detecting=1, if(RB_Connected=1, 'On', 'Error'), 'Off') as 'RightBack' FROM camstatus where Time between :FromDate and :ToDate and DeviceName like :DeviceName LIMIT :Limit",
            Params: {
                FromDate: FilterSettings.FromDate,
                ToDate: FilterSettings.ToDate,
                DeviceName: FilterSettings.DeviceName,
                Limit: FilterSettings.Limit
            },
            Language: Language,
            Translate: ["DeviceName"]
        })).then((response) => {
            FilterManager.setActive(true);
            if (response.Rows.length === 0 && !first) {
                return Alerts.noResults();
            }
            const standard = /*AConfig.get('drawing & colors.tables.highlight', */ 'blue';
            const green = /*AConfig.get('drawing & colors.tables.success', */ 'green';
            const red = /*AConfig.get('drawing & colors.tables.error', */ 'red';
            const colorize = {
                type: 'template',
                template: (data) => {
                    return `<b style="color:${(data.value === 'On') ? green
                        : (data.value === 'Error') ? red : standard}">${data.value}</b>`;
                }
            };
            this.grid = AShowTable({
                appendTo: 'table-bryntum',
                columns: AConvertToGridColumns(response, {
                    'SessionId': { hidden: true },
                    'Time': COLUMN_DATETIME,
                    'LeftFront': colorize,
                    'LeftSideway': colorize,
                    'LeftBack': colorize,
                    'RightFront': colorize,
                    'RightSideway': colorize,
                    'RightBack': colorize
                }),
                data: AConvertToGridData(response, {
                    'Time': DATA_DATETIME
                })
            });
        }).catch(AError.handle);
    }
}
export function render() {
    return ( /*html*/`
    <div id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column c-scroll col-12">
        <div class="form-group">
          <label class="form-label" for="FromDate">From</label>
          <input class="form-input" type="date" id="FromDate" required="required">
          <input class="form-input" type="time" id="FromTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="ToDate">To</label>
          <input class="form-input" type="date" id="ToDate" required="required">
          <input class="form-input" type="time" id="ToTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="DeviceName">Device</label>
          <select class="form-select" id="DeviceName">
            <option value="%">All</option>
          </select>
        </div>

        <div class="form-group">
          <label class="form-label" for="Limit">Max results</label>
          <input class="form-input" type="number" id="Limit" value="2000">
        </div>
      </div>
      <div class="column col-12">
          <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </div>
    <div id="Rapport" class="flex-child bryntum-container has-footer-2">
      <div template="${ATemplates.WaitingForInput}"></div>
      <div id="table-bryntum" class="hidden"></div>
      
      <div class="columns footer aci">
          <div class="column col-2">
              <div id="count" class="text">Viewing <span>0</span> Records</div>
          </div>
          <div class="column col-2 col-ml-auto">
              <button id="export" class="btn btn-primary col-12" disabled="disabled">Export</button>
          </div>
      </div>
    </div>
  `);
}
