import { ACSV } from "../../classes/ACSV.js";
import { AError } from "../../classes/AError.js";
import { COLUMN_DATETIME, DATA_DATETIME } from "../../classes/AGridTypes.js";
import { sleep } from "../../core/AEngine.js";
import { ATemplates } from "../../core/ATemplateService.js";
import { DetectionsFullImagesRanged, DetectionsFullSingle } from "../../utils/query.js";
import { AConvertToGridColumns, AConvertToGridData, AShowTable, AUrlEncodedImageFromBase64 } from "../../utils/tools.js";
export class APage {
    constructor() {
        this.currentPage = 1;
        this.identifiers = [];
        this.edited = 0;
        this.full = 0;
        FilterManager.load();
    }
    async init() {
        this.correction = await Loading.waitForPromises(Translate.get('Correction'));
        $('#RefreshButton').on('click', _ => FilterManager.showFilterWarning().then(_ => this.refresh()));
    }
    get query() {
        const optional = $("#hideAmbiguous").prop("checked") ? ` AND (LENGTH(cameraids) <= 5 OR LENGTH(NearMatches) > LENGTH(LicensePlate) + 4)` : ``;
        const Query = (`
            SELECT
                DetectionId,
                DetectionDeviceId,
                Image,
                LicensePlate,
                Label,
                DetectionTime,
                confidence,
                NearMatches,
                cameraids,
                bestcameraid
            FROM (${DetectionsFullImagesRanged}) full                
            WHERE
                Confidence BETWEEN :MinConf AND :MaxConf
                AND Image IS NOT NULL
                AND LicensePlate <> '######'
	            ${optional}
			ORDER BY Label DESC, DetectionTime	
            LIMIT :Limit
        `);
        return Query;
    }
    appendToTable($tbody, html) {
        const $row = $(html);
        $tbody.append($row);
        return $row;
    }
    set label(val) {
        $('#count span').text(val);
    }
    exportCSV() {
        Loading.waitForPromises(this.identifiers.map(identifier => {
            const [DetectionDeviceId, DetectionId] = identifier.split(`_`);
            return requestService.query({
                Query: `SELECT DetectionTime, Label, LicensePlate, NearMatches, Confidence, BestCameraId, CameraIds, DetectionId, DetectionDeviceId FROM (${DetectionsFullSingle}) f`,
                Params: {
                    DetectionDeviceId,
                    DetectionId
                },
                Language: Language
            });
        })).then(responses => {
            if (!responses.length)
                return Alerts.noResults();
            const csv = new ACSV(responses[0].ColumnsTranslated);
            for (const response of responses) {
                if (response.Rows.length > 0) {
                    const row = response.Rows[0];
                    csv.add([
                        row[0],
                        row[1],
                        row[2],
                        row[3].join(' '),
                        row[4],
                        row[5],
                        row[6].join(' '),
                        row[7].toString(),
                        row[8].toString() // Detection Device Id
                    ]);
                }
            }
            csv.createDownload();
        }).catch(AError.handle);
    }
    refresh() {
        const filters = FilterManager.save();
        FilterManager.setActive(false);
        this.identifiers = [];
        this.edited = 0;
        this.rowCount = 0;
        return Loading.waitForPromises(requestService.query({
            Query: this.query,
            Name: `Reports`,
            Params: filters,
            Language: Language
        })).then(async (response) => {
            FilterManager.setActive(true);
            const labelIndex = response.Columns.indexOf('Label');
            if (response.Rows.length === 0) {
                return Alerts.noResults();
            }
            response.Rows.map((row) => {
                if (row[labelIndex] && row[labelIndex].length > 0) {
                    this.edited++;
                }
            });
            this.rowCount = response.Rows.length;
            this.label = (`${this.edited}/${this.rowCount}`);
            const cellRenderer = function ({ record, value, size }) {
                // @ts-ignore
                if (!size && this.field === 'Image') {
                    return '';
                }
                size.height = 120;
                return value;
            };
            this.grid = AShowTable({
                aci: {
                    resizeToFit: false
                },
                appendTo: 'table-bryntum',
                columns: AConvertToGridColumns(response, {
                    'DetectionId': { hidden: true },
                    'DetectionDeviceId': { hidden: true },
                    'DetectionTime': Object.assign({
                        flex: 3
                    }, COLUMN_DATETIME),
                    'LicensePlate': {
                        flex: 3
                    },
                    'Image': {
                        flex: 3,
                        htmlEncode: false,
                        renderer: cellRenderer
                    },
                    'Label': {
                        flex: 3,
                        editor: 'text'
                    },
                }),
                data: AConvertToGridData(response, {
                    'DetectionTime': DATA_DATETIME,
                    'Image': (base64) => (`<img src="${AUrlEncodedImageFromBase64(base64)}" />`)
                }),
                features: {
                    cellEdit: {
                        disabled: false,
                        onEditorBeforeComplete: (obj) => {
                            const { value, oldValue, record } = obj;
                            const { DetectionId, DetectionDeviceId } = record;
                            Loading.waitForPromises([
                                requestService.query({
                                    Query: (`
                    INSERT INTO detections_usertags
                      (DetectionId, DetectionDeviceId, Label)
                    VALUES
                      (:DetectionId, :DetectionDeviceId, :NewValue)
                    ON DUPLICATE KEY UPDATE
                      Label=:NewValue
                  `),
                                    Params: {
                                        DetectionDeviceId,
                                        DetectionId,
                                        NewValue: value
                                    }
                                }),
                                sleep(80)
                            ]).then((r) => {
                                if (oldValue.length > 0 && value.length === 0) {
                                    this.edited--;
                                    this.label = (`${this.edited}/${this.rowCount}`);
                                }
                                else if (oldValue.length === 0) {
                                    this.edited++;
                                    this.label = (`${this.edited}/${this.rowCount}`);
                                }
                            }).catch(AError.handle);
                        }
                    }
                }
            });
        }).catch(AError.handle);
    }
}
export function render() {
    //   <style>
    //   .footer {
    //     background: rgb(248, 248, 248);
    //     border-top: 1px solid #eeeeee;
    //     width: calc(100% + 8px);
    //     padding: 7px 0;
    //   }
    //   .footer .text {
    //     padding-left: 8px;
    //     line-height: 35px;
    //   }
    // </style>
    return ( /*html*/`
    <div id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column col-12">
        <div class="form-group">
          <label class="form-label" for="FromDate">From</label>
          <input class="form-input" type="date" id="FromDate" required="required">
          <input class="form-input" type="time" id="FromTime" required="required">
        </div>
        <div class="form-group">
          <label class="form-label" for="ToDate">To</label>
          <input class="form-input" type="date" id="ToDate" required="required">
          <input class="form-input" type="time" id="ToTime" required="required">
        </div>
        <div class="form-group">
          <label class="form-label" for="Confidence">Min Confidence</label>
          <input class="form-input" id="MinConf" type="number" value="0" />
        </div>
        <div class="form-group">
          <label class="form-label" for="Confidence">Max Confidence</label>
          <input class="form-input" id="MaxConf" type="number" value="1" />
        </div>
        <div class="form-group">
          <label class="form-label" for="Limit">Max results</label>
          <input class="form-input" type="number" id="Limit" value="200">
        </div>
        <div class="form-group">
          <div class="col-ml-auto">
            <label class="form-switch">
              <input id="hideAmbiguous" type="checkbox" checked>
              <i class="form-icon"></i> Hide Unambiguous
            </label>
          </div>
        </div>
      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </div>
    <div id="Rapport" class="flex-child bryntum-container has-footer-2">
      <div template="${ATemplates.WaitingForInput}"></div>
      <div id="table-bryntum" class="hidden"></div>

      <div class="columns footer aci">
        <div class="column col-2">
          <div id="count" class="text">Viewing <span>0</span> Streets</div>
        </div>
        <div class="column col-2 col-ml-auto">
          <button id="export" class="btn btn-primary col-12" disabled="disabled">Export</button>
        </div>
      </div>
    </div>
  `);
}
