export class APolicy {
    constructor(method) {
        this.method = method;
        this.result = false;
        this.details = null;
    }
    get formattedResult() {
        const { result, details } = this;
        return { result, details };
    }
    async run() {
        try {
            const result = await Promise.resolve().then(_ => this.method());
            this.result = result;
            if (result === true) {
                return this;
            }
        }
        catch (err) {
            this.details = err;
        }
        return this.formattedResult;
    }
}
