import { ADetectionStatistics } from "../classes/ADetectionStatistics.js";
import { AError } from "../classes/AError.js";
export class AValidateUnificationService {
    constructor() {
        this.detectionStatistics = new ADetectionStatistics();
    }
    get unificationColumns() {
        return [
            'Digital',
            'IllegallyParked',
            'TimeLimitedParking',
            'ParkingRight',
            'Verification',
            'DetectionState',
        ];
    }
    async validate() {
        await Promise.all(this.unificationColumns.map(async (col) => {
            if (this.detectionStatistics[col] === undefined) {
                throw new Error(`Unification not found! Column=${col}`);
            }
            const unification = this.detectionStatistics[col];
            const dbEnumValues = this.arrayToHashMap(await this.fetchDatabaseEnumValues(unification));
            const localEnumValues = this.arrayToHashMap(this.getLocalEnumValues(unification));
            Object.keys(dbEnumValues).map(key => {
                if (dbEnumValues.hasOwnProperty(key)) {
                    if (!localEnumValues.hasOwnProperty(key)) {
                        throw new Error(`Unifications out of sync! DB has unification=${key} but in code it doesn't exist.`);
                    }
                    if (dbEnumValues[key] !== localEnumValues[key]) {
                        throw new Error(`Unifications out of sync! Enum Indexes do not match for unification=${key}`);
                    }
                }
            });
        })).catch(err => {
            AError.handle({
                err: err,
                useModal: false,
                useConsoleLog: true,
                useAdminAlerts: true,
                useCentralServerLogging: true,
                adminAlertGroup: 'UNIFICATION_OUT_OF_SYNC'
            });
        });
    }
    arrayToHashMap(rows) {
        const output = {};
        rows.map(({ Key, EnumValue }) => {
            output[Key] = EnumValue;
        });
        return output;
    }
    async fetchDatabaseEnumValues({ Key }) {
        const now = performance.now();
        const rows = await requestService.fetch({
            AssertValues: true,
            Query: ( /*SQL*/`
        SELECT t.Key, t.Key + 0 EnumValue from (SELECT DISTINCT ${Key} AS \`Key\` FROM detections_final WHERE ${Key} IS NOT NULL) as t
      `)
        });
        // console.log(`fetchDatabaseEnumValues({ Key: ${Key}}) finished in ${(performance.now() - now)}`)
        return rows.map(v => v);
    }
    getLocalEnumValues(unification) {
        if (Object.keys(unification.Options).length === 0) {
            return [{
                    Key: unification.Key,
                    EnumValue: unification.FirstIndex
                }];
        }
        const rows = Object.keys(unification.Options).map(key => {
            return this.getLocalEnumValues(unification.Options[key]);
        }).flat();
        return rows;
    }
}
