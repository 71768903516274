import { AColorHSV } from "./AColorHSV.js";
export class AColorMapper {
    static mapToColors(colorKeysInput, opt) {
        const { useOpacity, calcColorKey, overrideMap } = opt;
        const overrideMapKeys = Object.keys(overrideMap);
        const colorKeys = [...colorKeysInput];
        overrideMapKeys.map(t => {
            const i = colorKeys.indexOf(t);
            if (i !== -1 && overrideMap.hasOwnProperty(t)) {
                colorKeys.splice(i, 1);
                const insertIndex = overrideMap[t].pos === 'top' ? 0 : colorKeys.length;
                colorKeys.splice(insertIndex, 0, t);
            }
        });
        let colorKeysPartial = colorKeys.filter((k, i) => !overrideMapKeys.includes(k));
        const calcTemplate = {};
        const legendItems = {};
        const mappedColors = AColorMapper.genColorHashmap(colorKeysPartial, { ...opt, brightness: 90, saturation: 100, hex: false });
        const mappedStrokeColors = AColorMapper.genColorHashmap(colorKeysPartial, { ...opt, brightness: 30, saturation: 100, hex: false });
        const isMatch = opt.isMatch ?? ((legendKey, unificationKey) => legendKey === unificationKey || unificationKey.startsWith(legendKey + '_'));
        const calcColor = (data) => {
            const key = data[calcColorKey];
            if (key === null) {
                return null;
            }
            let [mappedKey, ...rest] = Object.keys(calcTemplate).filter(k => isMatch(k, key));
            if (mappedKey === undefined) {
                throw new Error(`Couldn't find color associated with calcTemplate[${calcColorKey}][${key}]`);
            }
            else if (rest.length > 0) {
                throw new Error(`Found multiple color associations with calcTemplate[${calcColorKey}][${key}] namely: [${[mappedKey, ...rest].join(', ')}]`);
            }
            return (calcTemplate.hasOwnProperty(mappedKey)) ? calcTemplate[mappedKey] : undefined;
        };
        colorKeys.map((k, i) => {
            if (mappedColors.hasOwnProperty(k)) {
                const fill = useOpacity ? mappedColors[k].rgba(0.5) : mappedColors[k].hexi;
                const stroke = mappedStrokeColors[k].hexi;
                calcTemplate[k] = { fill, stroke, index: i };
                legendItems[k] = { fill, stroke, index: i };
            }
            else if (overrideMap.hasOwnProperty(k)) {
                const colorOverride = overrideMap[k];
                const { fill, stroke } = colorOverride.f(useOpacity);
                calcTemplate[k] = { fill, stroke, index: i };
                legendItems[k] = { fill, stroke, index: i };
            }
        });
        return { calcTemplate, legendItems, calcColor };
    }
    static genColorHashmap(keys, opt) {
        const mappedColors = {};
        if (keys.length === 0) {
            return mappedColors;
        }
        const hueStep = 360.0 / keys.length;
        let { saturation, brightness, hue, hueDir, offset } = Object.assign({
            saturation: 100,
            brightness: 70,
            hue: 0.0,
            hueDir: 1,
            offset: 0,
        }, opt);
        for (let i = 0; i < keys.length; i++) {
            const color = new AColorHSV(opt?.transformHue ? opt.transformHue(hue) : hue, saturation, brightness);
            let index = (i + offset);
            while (index < 0 && keys.length > 0) {
                index = (index + keys.length);
            }
            index %= keys.length;
            mappedColors[keys[index]] = (opt?.hex === true) ? color.hexi : color.rgb;
            hue += (hueDir === 1) ? hueStep : -hueStep;
            while (hue > 360.0)
                hue -= 360.0;
            while (hue < 0.0)
                hue += 360.0;
        }
        return mappedColors;
    }
}
