import { AError } from "../../classes/AError.js";
import { COLUMN_DATETIME, DATA_DATETIME } from "../../classes/AGridTypes.js";
import { ATemplates } from "../../core/ATemplateService.js";
import { AConvertToGridColumns, AConvertToGridData, AShowTable } from "../../utils/tools.js";
export class APage {
    constructor() {
        $('#RefreshButton').on('click', _ => FilterManager.showFilterWarning().then(_ => this.refresh()));
        FilterManager.load();
    }
    refresh() {
        FilterManager.setActive(false);
        const Filters = FilterManager.save();
        return Loading.waitForPromises(requestService.query({
            Query: "SELECT CONCAT(`Time`) as `Time`,`FromDevice`,`ToDevice`,`Level`,`Level` AS LevelOriginal,`Message` FROM `log` where Time  Between :FromDate and :ToDate and FromDevice like :DeviceName LIMIT :Limit",
            Params: Filters,
            Language: Language,
            Translate: ["FromDevice", "ToDevice", "Level"]
        })).then((response) => {
            FilterManager.setActive(true);
            if (response.Rows.length === 0) {
                return Alerts.noResults();
            }
            const standard = 'blue'; // AConfig.get('drawing & colors.tables.highlight',
            const green = 'green'; // AConfig.get('drawing & colors.tables.success',
            const orange = 'orange'; // AConfig.get('drawing & colors.tables.orange',
            const red = 'red'; // AConfig.get('drawing & colors.tables.error',
            // Time, FromDevice, ToDevice, Message
            this.grid = AShowTable({
                appendTo: 'table-bryntum',
                columns: AConvertToGridColumns(response, {
                    'LevelOriginal': {
                        hidden: true
                    },
                    'Level': {
                        renderer: (renderData) => {
                            const { cellElement, row, value } = renderData;
                            const level = renderData.record.LevelOriginal;
                            let color = null;
                            switch (level) {
                                case 'Warning':
                                    color = orange;
                                    break;
                                case 'Error':
                                case 'FatalError':
                                case 'InvalidLogin':
                                case 'InvalidCount':
                                case 'Red':
                                    color = red;
                                    break;
                                case 'On':
                                case 'ReadyToCount':
                                case 'ReadyToEnforce':
                                case 'ReadyToFollowUp':
                                case 'LoggedIn':
                                case 'Green':
                                    color = green;
                                    break;
                                case 'Off':
                                case 'Info':
                                case 'SessionStarted':
                                case 'SessionContinued':
                                case 'Connected':
                                case 'Blue':
                                    color = standard;
                                    break;
                                default:
                                    color = standard;
                                    break;
                            }
                            if (cellElement && cellElement.style) {
                                cellElement.style.color = color;
                            }
                            if (row && row.cells) {
                                for (const cell of row.cells) {
                                    cell.style.color = color;
                                }
                            }
                            return value;
                        }
                    },
                    'Time': COLUMN_DATETIME
                }),
                data: AConvertToGridData(response, {
                    'Time': DATA_DATETIME,
                    'Message': (value) => {
                        if (typeof value === 'string' || value instanceof String) {
                            return value;
                        }
                        else {
                            return JSON.stringify(value);
                        }
                    }
                })
            });
        }).catch(AError.handle);
    }
}
export function render() {
    return ( /*html*/`
    <div id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column c-scroll col-12">
        <div class="form-group">
          <label class="form-label" for="FromDate">From</label>
          <input class="form-input" type="date" id="FromDate" required="required">
          <input class="form-input" type="time" id="FromTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="ToDate">To</label>
          <input class="form-input" type="date" id="ToDate" required="required">
          <input class="form-input" type="time" id="ToTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="DeviceName">Device</label>
          <select class="form-select" id="DeviceName" load="all">
            <option value="%">All</option>
          </select>
        </div>

        <div class="form-group">
          <label class="form-label" for="Limit">Max results</label>
          <input class="form-input" type="number" id="Limit" value="2000">
        </div>
      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </div>
    <div id="Rapport" class="flex-child bryntum-container has-footer-2">
      <div template="${ATemplates.WaitingForInput}"></div>
      <div id="table-bryntum" class="hidden"></div>

      <div class="columns footer aci">
        <div class="column col-2">
          <div id="count" class="text">Viewing <span>0</span> Messages</div>
        </div>
        <div class="column col-2 col-ml-auto">
          <button id="export" class="btn btn-primary col-12" disabled="disabled">Export</button>
        </div>
      </div>
    </div>
  `);
}
