// This script will include all unification types which do not directly relate to the database columns //
export class AOccupancy {
    constructor() {
        this.Key = "Occupancy";
        this.KeyShort = "Occupancy";
        this.OccupancyAvg = 0.;
        this.CapacityAvg = 0.;
        this.SeenCapacityAvg = 0.;
        this.EntryCount = 0;
    }
    get Perc() {
        return Math.round(this.OccupancyAvg * 100) / Math.round(this.SeenCapacityAvg * 100);
    }
    get Options() {
        return {
            Capacity: {
                Key: "Capacity",
                KeyShort: "Capacity",
                Count: Math.round(this.CapacityAvg * 100) / 100,
                Description: "",
                Options: { Occupied: {
                        Key: "Avg_Occupied_Spaces",
                        KeyShort: "Avg_Occupied_Spaces",
                        Count: Math.round(this.OccupancyAvg * 100) / 100,
                        Description: "",
                        Options: {}
                    },
                    NotOccupied: {
                        Key: "Avg_Free_Spaces",
                        KeyShort: "Avg_Free_Spaces",
                        Count: (Math.round(this.SeenCapacityAvg * 100) - Math.round(this.OccupancyAvg * 100)) / 100,
                        Description: "",
                        Options: {}
                    },
                    NotSeen: {
                        Key: "Avg_Not_Seen_Spaces",
                        KeyShort: "Avg_Not_Seen_Spaces",
                        Count: (Math.round(this.CapacityAvg * 100) - Math.round(this.SeenCapacityAvg * 100)) / 100,
                        Description: "",
                        Options: {}
                    } }
            },
        };
    }
}
