import { EVENTS } from "../services/AEventService.js";
import { AKpiBlock } from "./AKpiBlock.js";
import { sleep } from "../core/AEngine.js";
import { initToolTips } from "../utils/tooltip.js";
import { ADeviceChartSnapshot, ASessionChartSerieEnum } from "../charts/ADeviceChartSnapshot.js";
import { ARound, formatNodeName, toShortNodeName } from "../utils/tools.js";
import { DeviceTypeOrder } from "../backoffice-initializer-legacy.js";
export class AKpiBlockSessions extends AKpiBlock {
    get $blockList() { return $(this.options.blockList); }
    get $cardList() { return $(this.options.cardList); }
    get blockWidthCls() { return this.options.blockWidth; }
    get styling() { return 'kpi-horizontal'; }
    constructor(opt) {
        super({
            refreshImplemented: false,
            optionsImplemented: true,
            ...opt
        });
        this.SessionInfos = {};
        this.SessionVisuals = {};
        this.SessionVisualPromises = {};
        this.options = Object.assign({
            listenToSessions: true,
            blockList: ('.kpi-block-list'),
            cardList: ('.kpi-card-list'),
            blockWidth: 'col-4',
            autoReflow: true
        }, opt);
    }
    async init() {
        super.init();
        if (this.options.listenToSessions === true) {
            Events.on(EVENTS.SESSION_CHANGE_STREAM, (SessionInfo) => this.updateSession(SessionInfo));
            Sessions.map(info => this.updateSession(info));
        }
        this.createResizeListener();
    }
    disconnectedSessionInfo({ NodeName, NodeType }) {
        return {
            // SessionInfo: {
            NodeName: NodeName,
            NodeType: NodeType,
            Status: "Disconnected",
            StatusString: Translate.getCacheFast("disconnected"),
            ComState: "SessionStopped",
        };
        // IsVisible: false
        // } as AKpiNodeValue
    }
    createResizeListener() {
        Events.on(EVENTS.CONTENT_RESIZE, async () => {
            // Object.values(PageScript.NodeData).map(v => v.Chart).filter(v => v !== undefined).map(chart => chart.reflow())
            await sleep(1);
            Object.values(this.SessionVisuals).map((visual) => {
                visual.Snapshot?.resize();
            });
        });
    }
    addBlockToList(chartId, ref) {
        const { NodeName, NodeNameShort, Status, StatusTranslated } = ref;
        const $block = $(/*html*/ `
      <div class="column ${this.blockWidthCls}">
        <div class="kpi-block">
          <div class="kpi-header">
            <div class="kpi-title">
              <label class="legend-label">${Translate.getCacheFast('session')}</label>
              <span>|</span>
              <span class="card-node-index">${NodeNameShort}</span>
              <span>|</span>
              <span uid="status_${NodeName}" class="Status ${Status}">${StatusTranslated}</span>
            </div>
          </div>
          <div class="kpi-view">
            <div class="Chart" id="${chartId}"></div>
          </div>
        </div>
      </div>
    `);
        this.$blockList.append($block);
        return $block;
    }
    async addCardtoList(ref) {
        const { UserDisplayName, User, Status, StatusTranslated, IconCls } = ref;
        const { NodeIndex, NodeName, NodeNameShort, NodeType } = await toShortNodeName(ref.NodeName, { translate: true });
        // AEngine.log('Sorting', {NodeIndex, NodeName, NodeNameShort, NodeType})
        const cardCls = [
            'card-backoffice',
            'has-node-index'
            // ...(matches ? ['has-node-index'] : [])
        ].join(' ');
        const TooltipNodeName = formatNodeName(NodeName);
        const ToolTipCardInfo = [
            (UserDisplayName || User || undefined),
            StatusTranslated || undefined
        ].filter(v => v !== undefined).join('<br>');
        const $card = $(/*html*/ `
      <div nodetype="${DeviceTypeOrder[NodeType]}" id="${NodeName}" class="column col-auto card-wrapper">
        <div class="${cardCls}">
          <div class="columns col-gapless">
            <div class="column card-icon-parent col-auto" style="position: relative;" atooltip="${TooltipNodeName}">
              <div class="${IconCls}"></div>
              <div class="card-node-index">${NodeNameShort}</div>
            </div>
            <div class="column card-info" atooltip="${ToolTipCardInfo}">
              <div class="card-center">
                <div class="device-name hidden">${NodeType}</div>
                <div class="operator-name">${(UserDisplayName || User || '')}</div>
                <div uid="status_${NodeName}" class="device-status ${Status}">${StatusTranslated}</div>
              </div>
            </div>
            <div class="card-jump hidden">
              <i class="fas fa-search"></i>
            </div>
          </div>
        </div>
      </div>
    `);
        // const $cards = this.$cardList.find('.card-wrapper').toArray().map(c => $(c))
        // for (let $c of $cards) {
        //   const diff = $c.attr('sortkey')!.localeCompare($card.attr('sortkey')!)
        // }
        $card.addClass('hidden');
        this.$cardList.append($card);
        this.$cardList.find('.card-wrapper').sort((a, b) => {
            const d = Number($(a).attr("nodetype")) - Number($(b).attr("nodetype"));
            return (d !== 0) ? d : $(a).attr("id").localeCompare($(b).attr("id"));
        }).appendTo(this.$cardList);
        $card.removeClass('hidden');
        // const sortKeys: string[] = [...this.$cardList.find('.card-wrapper[sortkey]').toArray().map(e => $(e).attr('sortkey'))! as any, $card.attr('sortkey')! as string]
        // sortKeys.sort((a, b) => b.localeCompare(a))
        // const insertIndex = sortKeys.indexOf(SortKey)
        // if (insertIndex === 0) {
        //   this.$cardList.prepend($card)
        // } else {
        //   const $cards = this.$cardList.find(`.card-wrapper`)
        //   $cards.eq(insertIndex).after($card)
        // }
        initToolTips(this.$cardList.find('[atooltip]'), { align: 'left' });
        return $card;
    }
    async getRenderData(SessionInfo) {
        let { NodeType, Gps, ComState, Status, StatusString, CamState, UserDisplayName, User } = SessionInfo;
        const LeftDetecting = (CamState && CamState.Left) ? (CamState.Left.DetectingCount > 0 ? "LeftDetecting" : "LeftOff") : '';
        const RightDetecting = (CamState && CamState.Right) ? (CamState.Right.DetectingCount > 0 ? "RightDetecting" : "RightOff") : '';
        const { NodeIndex, NodeName, NodeNameShort } = await toShortNodeName(SessionInfo.NodeName, { translate: true });
        return Object.assign({}, SessionInfo, {
            NodeIndex,
            NodeName,
            NodeType,
            NodeNameShort,
            Name: UserDisplayName || User || '',
            ComState: (ComState == "IOError" || ComState == "FatalError") ? ComState : '',
            Status: (ComState == "IOError" || ComState == "FatalError") ? ComState : Status,
            StatusTranslated: await Translate.get(Status),
            StatusString: StatusString ? await Translate.get(StatusString) : '',
            SpeedString: (Gps && Gps.Speed && Status != "Disconnected") ? Math.floor(Gps.Speed) + " km/h" : '',
            IconCls: `Icon ico-v2 ${NodeType} ${LeftDetecting} ${RightDetecting}`
        });
    }
    async getOrCreateVisual(SessionInfo, RenderData) {
        const { NodeType, NodeName } = SessionInfo;
        if (this.SessionVisualPromises.hasOwnProperty(NodeName)) {
            return await this.SessionVisualPromises[NodeName];
        }
        if (!this.SessionVisuals.hasOwnProperty(NodeName)) {
            this.SessionVisualPromises[NodeName] = Promise.resolve().then(async () => {
                let output = {
                    Card: await this.addCardtoList(RenderData),
                    Block: $()
                };
                if (ADeviceChartSnapshot.chartAllowed({ NodeType })) {
                    const Snapshot = new ADeviceChartSnapshot(SessionInfo);
                    const Block = this.addBlockToList(Snapshot.chartId, RenderData);
                    await Snapshot?.makeChart();
                    Object.assign(output, { Block, Snapshot });
                    this.initCardClickEvent(output);
                }
                return output;
            });
            this.SessionVisuals[NodeName] = await this.SessionVisualPromises[NodeName];
        }
        return this.SessionVisuals[NodeName];
    }
    initCardClickEvent(visual) {
        const { Card, Snapshot } = visual;
        Card?.addClass('is-clickable');
        Card?.on('click', (e) => {
            e.preventDefault();
            const $kpis = $('.kpis');
            const $focusKpi = $(`#${Snapshot?.chartId}`).closest('.kpi-block');
            $focusKpi.addClass('kpi-focus');
            $kpis.addClass('kpis-focus');
            const offset = $focusKpi.offset()?.top ?? undefined;
            if (offset !== undefined) {
                $('.flex-content').animate({
                    scrollTop: offset + $('.flex-content').scrollTop()
                }, 300, () => {
                    sleep(600).then(() => {
                        $focusKpi.removeClass('kpi-focus');
                        $kpis.removeClass('kpis-focus');
                    });
                });
            }
        });
    }
    updateBlockAndCard(SessionVisual, RenderData) {
        const { Name, NodeName, Unit, IconCls, Status, StatusString } = RenderData;
        const $card = SessionVisual.Card; // $(`#${NodeName}`)
        const $block = SessionVisual.Block;
        const visuals = [$card, $block];
        visuals.map($parent => {
            const $status = $parent.find(`[uid=status_${NodeName}]`);
            $status.removeClass();
            $status.addClass('device-status');
            $status.addClass(Status);
            $status.text(StatusString);
        });
        $card.find('.card-info').attr('atooltip', [(Name || undefined), StatusString || undefined].filter(v => v !== undefined).join('<br>'));
        $card.find('.operator-name').text(Name || '');
        $card.find('.operator-name').toggleClass('hidden', Name ? false : true);
        $card.find('.Icon').attr('class', IconCls);
        initToolTips($card.find('[atooltip]'), { align: 'left' });
    }
    async updateSession(SessionInfo) {
        this.SessionInfos[SessionInfo.NodeName] = SessionInfo;
        const RenderData = await this.getRenderData(SessionInfo);
        const SessionVisual = await this.getOrCreateVisual(SessionInfo, RenderData);
        this.updateBlockAndCard(SessionVisual, RenderData);
    }
    async fetchChartData() {
        const chartData = {};
        let Detecting = {};
        let Scans = {};
        let Verifications = {};
        let Speed = {};
        await Promise.all([
            requestService.fetch({
                AssertValues: true,
                Name: "DetectingChart",
                Query: ( /*SQL*/`
          SELECT
            DeviceName as Device,
            0.0 + floor(UNIX_TIMESTAMP(Time)/5) * 5 as Time,
            0.0 + IF(SUM(Left_DetectingCount) > 0 OR SUM(Right_DetectingCount) > 0, 0, NULL) as Detecting
          FROM camstatus
          WHERE Time > timestamp(DATE_SUB(NOW(), INTERVAL 60 MINUTE))
          GROUP BY Time, DeviceName
        `),
            }).then(res => Detecting = res),
            requestService.fetch({
                AssertValues: true,
                Name: "ScansChart",
                Query: ( /*SQL*/`
          SELECT
            DetectionDevice as Device,
            0.0 + floor(UNIX_TIMESTAMP(detectiontime)/5) * 5 as Time,
            0.0 + count(*) as Detections
          FROM detections
          WHERE detectiontime > timestamp(DATE_SUB(NOW(), INTERVAL 60 MINUTE))
          GROUP BY Time, Device
        `),
            }).then(res => Scans = res),
            requestService.fetch({
                AssertValues: true,
                Name: "VerificationChart",
                Query: ( /*SQL*/`
          SELECT
            VerificationDevice as Device,
            0.0 + floor(UNIX_TIMESTAMP(VerificationEndTime)/5) * 5 as Time,
            0.0 + count(*) as Verifications
          FROM verifications
          WHERE VerificationDeviceId != 0 AND VerificationEndTime > timestamp(DATE_SUB(NOW(), INTERVAL 60 MINUTE))
          GROUP BY Time, Device
        `),
            }).then(res => Verifications = res),
            requestService.fetch({
                AssertValues: true,
                Name: "SpeedChart",
                Query: ( /*SQL*/`
          SELECT
            DeviceName as Device,
            0.0 + floor(UNIX_TIMESTAMP(GpsTime)/5) * 5 as Time,
            0.0 + avg(Speed) as Speed,
            SessionId
          FROM gps_original
          WHERE GpsTime > timestamp(DATE_SUB(NOW(), INTERVAL 60 MINUTE))
          GROUP BY Time, Device, SessionId
        `),
            }, {
                valueMapper: {
                    Speed: (input) => ARound(input, 2)
                }
            }).then(res => Speed = res)
        ]);
        Detecting.map(({ Device, Time, Detecting }) => {
            if (!chartData.hasOwnProperty(Device))
                chartData[Device] = [[], [], [], []];
            chartData[Device][ASessionChartSerieEnum.Detecting].push([Time * 1000, Detecting]);
        });
        Scans.map(({ Device, Time, Detections }) => {
            // if (Device.startsWith('ControlCenter')) { return }
            if (!chartData.hasOwnProperty(Device))
                chartData[Device] = [[], [], [], []];
            chartData[Device][ASessionChartSerieEnum.Scans].push([Time * 1000, Detections]);
        });
        Verifications.map(({ Device, Time, Verifications }) => {
            if (!chartData.hasOwnProperty(Device))
                chartData[Device] = [[], [], [], []];
            chartData[Device][ASessionChartSerieEnum.Verifications].push([Time * 1000, Verifications]);
        });
        const prevDeviceSessionId = {};
        Speed.map(({ Device, Time, Speed, SessionId }) => {
            const hasNoSpeed = (prevDeviceSessionId[Device] == undefined || prevDeviceSessionId[Device] != SessionId);
            prevDeviceSessionId[Device] = SessionId;
            if (!chartData.hasOwnProperty(Device))
                chartData[Device] = [[], [], [], []];
            chartData[Device][ASessionChartSerieEnum.Speed].push([Time * 1000, hasNoSpeed ? null : Speed]);
        });
        return chartData;
    }
    async refresh() {
        const chartData = await this.fetchChartData();
        const VisualsWithChart = Object.values(this.SessionVisuals).filter(v => v.Snapshot !== undefined);
        VisualsWithChart.map(({ Snapshot }) => {
            Snapshot?.setExtremes();
        });
        Object.keys(chartData).map((NodeName) => {
            // const SessionInfo = this.SessionInfos[NodeName]
            const SessionVisual = this.SessionVisuals[NodeName];
            SessionVisual?.Snapshot?.update(chartData[NodeName]);
            Events.tryInvoke(EVENTS.CONTENT_RESIZE, { caller: 'AKpiBlockSessions.showNode' });
        });
        // await this.requestAndUpdateChart()
    }
    async render() {
        return ( /*html*/`
      <div id="${this.idBlock}" class="kpi-block sessions-kpi-block kpi-disable-options kpi-skip-resize kpi-no-style ${this.styling}">
        <div class="kpi-header hidden">
          <label for="${this.idFilter}" class="legend-label">Sessions<span> | </span></label>
          <select id="${this.idFilter}" class="legend-label kpi-select">
            <option value="%">None</option>
          </select>
        </div>
        <div class="kpi-view columns col-gapless col-online card-list kpi-card-list list-margin">
          <div nodetype="0" id="AControlCenter" class="column col-auto card-wrapper">
            <div class="card-backoffice has-node-index">
              <div class="columns col-gapless">
                <div class="column card-icon-parent col-auto" style="position: relative;" atooltip="Control Center">
                  <div class="Icon ico-v2 BackOffice"></div>
                  <div class="card-node-index" >CC</div>
                </div>
                <div class="column card-info">
                  <div class="card-center">
                    <div class="hidden">ControlCenter</div>
                    <div class="operator-name">ControlCenter</div>
                    <div class="device-status LoggedIn">Online</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    `);
    }
}
