/// <!--=======================================================================================================-->
///                              ADigital
/// <!----------------------------------------------------------------------------------------------------------->
export class ADigital {
    constructor() {
        this.Key = "Digital";
        this.KeyShort = "Digital";
        this.FirstIndex = 1;
        this.LastIndex = 4;
        this.Name = "Digital";
        this.FullName = "Digital";
        this.Description = "";
        this.Count = 0;
        this.Perc = 0.;
        this.Options = {
            InProgress: {
                Key: "InProgress",
                KeyShort: "InProgress",
                FirstIndex: 1,
                LastIndex: 1,
                Name: "In Progress",
                FullName: "In Progress",
                Description: "",
                Count: 0,
                Perc: 0.,
                Options: {}
            },
            Digital: {
                Key: "Digital",
                KeyShort: "Digital",
                FirstIndex: 2,
                LastIndex: 2,
                Name: "Digital",
                FullName: "Digital",
                Description: "",
                Count: 0,
                Perc: 0.,
                Options: {}
            },
            NotDigital: {
                Key: "NotDigital",
                KeyShort: "NotDigital",
                FirstIndex: 3,
                LastIndex: 3,
                Name: "Not Digital",
                FullName: "Not Digital",
                Description: "",
                Count: 0,
                Perc: 0.,
                Options: {}
            },
            NotProcessed: {
                Key: "NotProcessed",
                KeyShort: "NotProcessed",
                FirstIndex: 4,
                LastIndex: 4,
                Name: "Not Processed",
                FullName: "Not Processed",
                Description: "",
                Count: 0,
                Perc: 0.,
                Options: {}
            },
        };
    }
    addOption(key, Count) {
        let OptionAncestors = {
            InProgress: ["InProgress"],
            Digital: ["Digital"],
            NotDigital: ["NotDigital"],
            NotProcessed: ["NotProcessed"],
        };
        let Ancestors = OptionAncestors[key];
        if (!Array.isArray(Ancestors))
            throw Error(key + ' not an option for Digital');
        let Current = this;
        Current.Count += Count;
        Current.Perc = Current.Count ? 1. : 0.;
        let ParentCount = Current.Count;
        for (let Ancestor of Ancestors) {
            Current = Current.Options[Ancestor];
            Current.Count += Count;
            Current.Perc = Current.Count / ParentCount;
            ParentCount = Current.Count;
        }
    }
}
/// <!--=======================================================================================================-->
///                              ATimeLimitedParking
/// <!----------------------------------------------------------------------------------------------------------->
export class ATimeLimitedParking {
    constructor() {
        this.Key = "TimeLimitedParking";
        this.KeyShort = "TimeLimitedParking";
        this.FirstIndex = 1;
        this.LastIndex = 6;
        this.Name = "Time Limited Parking";
        this.FullName = "Time Limited Parking";
        this.Description = "";
        this.Count = 0;
        this.Perc = 0.;
        this.Options = {
            InProgress: {
                Key: "InProgress",
                KeyShort: "InProgress",
                FirstIndex: 1,
                LastIndex: 1,
                Name: "In Progress",
                FullName: "In Progress",
                Description: "",
                Count: 0,
                Perc: 0.,
                Options: {}
            },
            NotTimeLimited: {
                Key: "NotTimeLimited",
                KeyShort: "NotTimeLimited",
                FirstIndex: 2,
                LastIndex: 2,
                Name: "Not Time Limited",
                FullName: "Not Time Limited",
                Description: "",
                Count: 0,
                Perc: 0.,
                Options: {}
            },
            Indecisive: {
                Key: "Indecisive",
                KeyShort: "Indecisive",
                FirstIndex: 3,
                LastIndex: 3,
                Name: "Indecisive",
                FullName: "Indecisive",
                Description: "vehicle first seen",
                Count: 0,
                Perc: 0.,
                Options: {}
            },
            TimeValid: {
                Key: "TimeValid",
                KeyShort: "TimeValid",
                FirstIndex: 4,
                LastIndex: 4,
                Name: "Time Valid",
                FullName: "Time Valid",
                Description: "vehicle still within time limit",
                Count: 0,
                Perc: 0.,
                Options: {}
            },
            TimeExperired: {
                Key: "TimeExperired",
                KeyShort: "TimeExperired",
                FirstIndex: 5,
                LastIndex: 5,
                Name: "Time Experired",
                FullName: "Time Experired",
                Description: "",
                Count: 0,
                Perc: 0.,
                Options: {}
            },
            NotProcessed: {
                Key: "NotProcessed",
                KeyShort: "NotProcessed",
                FirstIndex: 6,
                LastIndex: 6,
                Name: "Not Processed",
                FullName: "Not Processed",
                Description: "",
                Count: 0,
                Perc: 0.,
                Options: {}
            },
        };
    }
    addOption(key, Count) {
        let OptionAncestors = {
            InProgress: ["InProgress"],
            NotTimeLimited: ["NotTimeLimited"],
            Indecisive: ["Indecisive"],
            TimeValid: ["TimeValid"],
            TimeExperired: ["TimeExperired"],
            NotProcessed: ["NotProcessed"],
        };
        let Ancestors = OptionAncestors[key];
        if (!Array.isArray(Ancestors))
            throw Error(key + ' not an option for TimeLimitedParking');
        let Current = this;
        Current.Count += Count;
        Current.Perc = Current.Count ? 1. : 0.;
        let ParentCount = Current.Count;
        for (let Ancestor of Ancestors) {
            Current = Current.Options[Ancestor];
            Current.Count += Count;
            Current.Perc = Current.Count / ParentCount;
            ParentCount = Current.Count;
        }
    }
}
/// <!--=======================================================================================================-->
///                              AIllegallyParked
/// <!----------------------------------------------------------------------------------------------------------->
export class AIllegallyParked {
    constructor() {
        this.Key = "IllegallyParked";
        this.KeyShort = "IllegallyParked";
        this.FirstIndex = 1;
        this.LastIndex = 6;
        this.Name = "Illegally Parked";
        this.FullName = "Illegally Parked";
        this.Description = "";
        this.Count = 0;
        this.Perc = 0.;
        this.Options = {
            InProgress: {
                Key: "InProgress",
                KeyShort: "InProgress",
                FirstIndex: 1,
                LastIndex: 1,
                Name: "In Progress",
                FullName: "In Progress",
                Description: "",
                Count: 0,
                Perc: 0.,
                Options: {}
            },
            NotIllegallyParked: {
                Key: "NotIllegallyParked",
                KeyShort: "NotIllegallyParked",
                FirstIndex: 2,
                LastIndex: 2,
                Name: "Not Illegally Parked",
                FullName: "Not Illegally Parked",
                Description: "",
                Count: 0,
                Perc: 0.,
                Options: {}
            },
            IllegallyParked: {
                Key: "IllegallyParked",
                KeyShort: "IllegallyParked",
                FirstIndex: 3,
                LastIndex: 5,
                Name: "Illegally Parked",
                FullName: "Illegally Parked",
                Description: "",
                Count: 0,
                Perc: 0.,
                Options: {
                    OutsideParkingSpace: {
                        Key: "IllegallyParked_OutsideParkingSpace",
                        KeyShort: "IllegallyParked_OutsideParkingSpace_Short",
                        FirstIndex: 3,
                        LastIndex: 3,
                        Name: "Outside parking space",
                        FullName: "Illegally Parked | Outside parking space",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    NoParking: {
                        Key: "IllegallyParked_NoParking",
                        KeyShort: "IllegallyParked_NoParking_Short",
                        FirstIndex: 4,
                        LastIndex: 4,
                        Name: "No parking",
                        FullName: "Illegally Parked | No parking",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    Unknown: {
                        Key: "IllegallyParked_Unknown",
                        KeyShort: "IllegallyParked_Unknown_Short",
                        FirstIndex: 5,
                        LastIndex: 5,
                        Name: "Unknown",
                        FullName: "Illegally Parked | Unknown",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                }
            },
            NotProcessed: {
                Key: "NotProcessed",
                KeyShort: "NotProcessed",
                FirstIndex: 6,
                LastIndex: 6,
                Name: "Not Processed",
                FullName: "Not Processed",
                Description: "",
                Count: 0,
                Perc: 0.,
                Options: {}
            },
        };
    }
    addOption(key, Count) {
        let OptionAncestors = {
            InProgress: ["InProgress"],
            NotIllegallyParked: ["NotIllegallyParked"],
            IllegallyParked_OutsideParkingSpace: ["IllegallyParked", "OutsideParkingSpace"],
            IllegallyParked_NoParking: ["IllegallyParked", "NoParking"],
            IllegallyParked_Unknown: ["IllegallyParked", "Unknown"],
            NotProcessed: ["NotProcessed"],
        };
        let Ancestors = OptionAncestors[key];
        if (!Array.isArray(Ancestors))
            throw Error(key + ' not an option for IllegallyParked');
        let Current = this;
        Current.Count += Count;
        Current.Perc = Current.Count ? 1. : 0.;
        let ParentCount = Current.Count;
        for (let Ancestor of Ancestors) {
            Current = Current.Options[Ancestor];
            Current.Count += Count;
            Current.Perc = Current.Count / ParentCount;
            ParentCount = Current.Count;
        }
    }
}
/// <!--=======================================================================================================-->
///                              AParkingRight
/// <!----------------------------------------------------------------------------------------------------------->
export class AParkingRight {
    constructor() {
        this.Key = "ParkingRight";
        this.KeyShort = "ParkingRight";
        this.FirstIndex = 1;
        this.LastIndex = 51;
        this.Name = "Parking Right";
        this.FullName = "Parking Right";
        this.Description = "";
        this.Count = 0;
        this.Perc = 0.;
        this.Options = {
            InProgress: {
                Key: "InProgress",
                KeyShort: "InProgress",
                FirstIndex: 1,
                LastIndex: 1,
                Name: "In Progress",
                FullName: "In Progress",
                Description: "",
                Count: 0,
                Perc: 0.,
                Options: {}
            },
            NoParkingRightNeeded: {
                Key: "NoParkingRightNeeded",
                KeyShort: "NoParkingRightNeeded",
                FirstIndex: 2,
                LastIndex: 20,
                Name: "No Parking Right Needed",
                FullName: "No Parking Right Needed",
                Description: "",
                Count: 0,
                Perc: 0.,
                Options: {
                    MovingVehicle: {
                        Key: "NoParkingRightNeeded_MovingVehicle",
                        KeyShort: "NoParkingRightNeeded_MovingVehicle_Short",
                        FirstIndex: 2,
                        LastIndex: 2,
                        Name: "Moving Vehicle",
                        FullName: "No Parking Right Needed | Moving Vehicle",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    BlueZone: {
                        Key: "NoParkingRightNeeded_BlueZone",
                        KeyShort: "NoParkingRightNeeded_BlueZone_Short",
                        FirstIndex: 3,
                        LastIndex: 3,
                        Name: "Blue Zone",
                        FullName: "No Parking Right Needed | Blue Zone",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    FreeParking: {
                        Key: "NoParkingRightNeeded_FreeParking",
                        KeyShort: "NoParkingRightNeeded_FreeParking_Short",
                        FirstIndex: 4,
                        LastIndex: 4,
                        Name: "Free Parking",
                        FullName: "No Parking Right Needed | Free Parking",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    ReservedZone: {
                        Key: "NoParkingRightNeeded_ReservedZone",
                        KeyShort: "NoParkingRightNeeded_ReservedZone_Short",
                        FirstIndex: 5,
                        LastIndex: 5,
                        Name: "Reserved Zone",
                        FullName: "No Parking Right Needed | Reserved Zone",
                        Description: "(BE: inname publieke domeinen)",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    PrivateParking: {
                        Key: "NoParkingRightNeeded_PrivateParking",
                        KeyShort: "NoParkingRightNeeded_PrivateParking_Short",
                        FirstIndex: 6,
                        LastIndex: 6,
                        Name: "Private Parking",
                        FullName: "No Parking Right Needed | Private Parking",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    Whitelisted: {
                        Key: "NoParkingRightNeeded_Whitelisted",
                        KeyShort: "NoParkingRightNeeded_Whitelisted_Short",
                        FirstIndex: 7,
                        LastIndex: 12,
                        Name: "Whitelisted",
                        FullName: "No Parking Right Needed | Whitelisted",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {
                            PoliceAmbulanceFire: {
                                Key: "NoParkingRightNeeded_Whitelisted_PoliceAmbulanceFire",
                                KeyShort: "NoParkingRightNeeded_Whitelisted_PoliceAmbulanceFire_Short",
                                FirstIndex: 7,
                                LastIndex: 7,
                                Name: "Police Ambulance Fire",
                                FullName: "No Parking Right Needed | Whitelisted | Police Ambulance Fire",
                                Description: "",
                                Count: 0,
                                Perc: 0.,
                                Options: {}
                            },
                            Diplomats: {
                                Key: "NoParkingRightNeeded_Whitelisted_Diplomats",
                                KeyShort: "NoParkingRightNeeded_Whitelisted_Diplomats_Short",
                                FirstIndex: 8,
                                LastIndex: 8,
                                Name: "Diplomats",
                                FullName: "No Parking Right Needed | Whitelisted | Diplomats",
                                Description: "",
                                Count: 0,
                                Perc: 0.,
                                Options: {}
                            },
                            ElectricVehicle: {
                                Key: "NoParkingRightNeeded_Whitelisted_ElectricVehicle",
                                KeyShort: "NoParkingRightNeeded_Whitelisted_ElectricVehicle_Short",
                                FirstIndex: 9,
                                LastIndex: 9,
                                Name: "Electric Vehicle",
                                FullName: "No Parking Right Needed | Whitelisted | Electric Vehicle",
                                Description: "",
                                Count: 0,
                                Perc: 0.,
                                Options: {}
                            },
                            CarSharing: {
                                Key: "NoParkingRightNeeded_Whitelisted_CarSharing",
                                KeyShort: "NoParkingRightNeeded_Whitelisted_CarSharing_Short",
                                FirstIndex: 10,
                                LastIndex: 10,
                                Name: "Car Sharing",
                                FullName: "No Parking Right Needed | Whitelisted | Car Sharing",
                                Description: "",
                                Count: 0,
                                Perc: 0.,
                                Options: {}
                            },
                            SpecialVehicle: {
                                Key: "NoParkingRightNeeded_Whitelisted_SpecialVehicle",
                                KeyShort: "NoParkingRightNeeded_Whitelisted_SpecialVehicle_Short",
                                FirstIndex: 11,
                                LastIndex: 11,
                                Name: "Special vehicle",
                                FullName: "No Parking Right Needed | Whitelisted | Special vehicle",
                                Description: "",
                                Count: 0,
                                Perc: 0.,
                                Options: {}
                            },
                            Unknown: {
                                Key: "NoParkingRightNeeded_Whitelisted_Unknown",
                                KeyShort: "NoParkingRightNeeded_Whitelisted_Unknown_Short",
                                FirstIndex: 12,
                                LastIndex: 12,
                                Name: "Unknown",
                                FullName: "No Parking Right Needed | Whitelisted | Unknown",
                                Description: "",
                                Count: 0,
                                Perc: 0.,
                                Options: {}
                            },
                        }
                    },
                    LoadUnload: {
                        Key: "NoParkingRightNeeded_LoadUnload",
                        KeyShort: "NoParkingRightNeeded_LoadUnload_Short",
                        FirstIndex: 13,
                        LastIndex: 13,
                        Name: "Load Unload",
                        FullName: "No Parking Right Needed | Load Unload",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    Disabled: {
                        Key: "NoParkingRightNeeded_Disabled",
                        KeyShort: "NoParkingRightNeeded_Disabled_Short",
                        FirstIndex: 14,
                        LastIndex: 14,
                        Name: "Disabled",
                        FullName: "No Parking Right Needed | Disabled",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    WrongStreetSign: {
                        Key: "NoParkingRightNeeded_WrongStreetSign",
                        KeyShort: "NoParkingRightNeeded_WrongStreetSign_Short",
                        FirstIndex: 15,
                        LastIndex: 15,
                        Name: "Wrong Street Sign",
                        FullName: "No Parking Right Needed | Wrong Street Sign",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    AlreadyFined: {
                        Key: "NoParkingRightNeeded_AlreadyFined",
                        KeyShort: "NoParkingRightNeeded_AlreadyFined_Short",
                        FirstIndex: 16,
                        LastIndex: 16,
                        Name: "Already Fined",
                        FullName: "No Parking Right Needed | Already Fined",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    NotAVehicle: {
                        Key: "NoParkingRightNeeded_NotAVehicle",
                        KeyShort: "NoParkingRightNeeded_NotAVehicle_Short",
                        FirstIndex: 17,
                        LastIndex: 17,
                        Name: "Not A Vehicle",
                        FullName: "No Parking Right Needed | Not A Vehicle",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    DefectVendingMachine: {
                        Key: "NoParkingRightNeeded_DefectVendingMachine",
                        KeyShort: "NoParkingRightNeeded_DefectVendingMachine_Short",
                        FirstIndex: 18,
                        LastIndex: 18,
                        Name: "Defect Vending Machine",
                        FullName: "No Parking Right Needed | Defect Vending Machine",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    Excemption: {
                        Key: "NoParkingRightNeeded_Excemption",
                        KeyShort: "NoParkingRightNeeded_Excemption_Short",
                        FirstIndex: 19,
                        LastIndex: 19,
                        Name: "Excemption",
                        FullName: "No Parking Right Needed | Excemption",
                        Description: "Excemption was given by the Follow Up user",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    Unknown: {
                        Key: "NoParkingRightNeeded_Unknown",
                        KeyShort: "NoParkingRightNeeded_Unknown_Short",
                        FirstIndex: 20,
                        LastIndex: 20,
                        Name: "Unknown",
                        FullName: "No Parking Right Needed | Unknown",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                }
            },
            ParkingRight: {
                Key: "ParkingRight",
                KeyShort: "ParkingRight",
                FirstIndex: 21,
                LastIndex: 31,
                Name: "Parking Right",
                FullName: "Parking Right",
                Description: "",
                Count: 0,
                Perc: 0.,
                Options: {
                    Visitor: {
                        Key: "ParkingRight_Visitor",
                        KeyShort: "ParkingRight_Visitor_Short",
                        FirstIndex: 21,
                        LastIndex: 25,
                        Name: "Visitor",
                        FullName: "Parking Right | Visitor",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {
                            PayByPhone: {
                                Key: "ParkingRight_Visitor_PayByPhone",
                                KeyShort: "ParkingRight_Visitor_PayByPhone_Short",
                                FirstIndex: 21,
                                LastIndex: 21,
                                Name: "Pay by Phone",
                                FullName: "Parking Right | Visitor | Pay by Phone",
                                Description: "",
                                Count: 0,
                                Perc: 0.,
                                Options: {}
                            },
                            Ticket: {
                                Key: "ParkingRight_Visitor_Ticket",
                                KeyShort: "ParkingRight_Visitor_Ticket_Short",
                                FirstIndex: 22,
                                LastIndex: 22,
                                Name: "Ticket",
                                FullName: "Parking Right | Visitor | Ticket",
                                Description: "Machine",
                                Count: 0,
                                Perc: 0.,
                                Options: {}
                            },
                            Permit: {
                                Key: "ParkingRight_Visitor_Permit",
                                KeyShort: "ParkingRight_Visitor_Permit_Short",
                                FirstIndex: 23,
                                LastIndex: 23,
                                Name: "Permit",
                                FullName: "Parking Right | Visitor | Permit",
                                Description: "Visitor permit",
                                Count: 0,
                                Perc: 0.,
                                Options: {}
                            },
                            PaperTicket: {
                                Key: "ParkingRight_Visitor_PaperTicket",
                                KeyShort: "ParkingRight_Visitor_PaperTicket_Short",
                                FirstIndex: 24,
                                LastIndex: 24,
                                Name: "Paper Ticket",
                                FullName: "Parking Right | Visitor | Paper Ticket",
                                Description: "Never a digital option, must only be set after a verification",
                                Count: 0,
                                Perc: 0.,
                                Options: {}
                            },
                            Unknown: {
                                Key: "ParkingRight_Visitor_Unknown",
                                KeyShort: "ParkingRight_Visitor_Unknown_Short",
                                FirstIndex: 25,
                                LastIndex: 25,
                                Name: "Unknown",
                                FullName: "Parking Right | Visitor | Unknown",
                                Description: "",
                                Count: 0,
                                Perc: 0.,
                                Options: {}
                            },
                        }
                    },
                    Permit: {
                        Key: "ParkingRight_Permit",
                        KeyShort: "ParkingRight_Permit_Short",
                        FirstIndex: 26,
                        LastIndex: 29,
                        Name: "Permit",
                        FullName: "Parking Right | Permit",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {
                            Business: {
                                Key: "ParkingRight_Permit_Business",
                                KeyShort: "ParkingRight_Permit_Business_Short",
                                FirstIndex: 26,
                                LastIndex: 26,
                                Name: "Business",
                                FullName: "Parking Right | Permit | Business",
                                Description: "",
                                Count: 0,
                                Perc: 0.,
                                Options: {}
                            },
                            Resident: {
                                Key: "ParkingRight_Permit_Resident",
                                KeyShort: "ParkingRight_Permit_Resident_Short",
                                FirstIndex: 27,
                                LastIndex: 27,
                                Name: "Resident",
                                FullName: "Parking Right | Permit | Resident",
                                Description: "",
                                Count: 0,
                                Perc: 0.,
                                Options: {}
                            },
                            PaperTicket: {
                                Key: "ParkingRight_Permit_PaperTicket",
                                KeyShort: "ParkingRight_Permit_PaperTicket_Short",
                                FirstIndex: 28,
                                LastIndex: 28,
                                Name: "Paper Ticket",
                                FullName: "Parking Right | Permit | Paper Ticket",
                                Description: "Never a digital option, must only be set after a verification",
                                Count: 0,
                                Perc: 0.,
                                Options: {}
                            },
                            Unknown: {
                                Key: "ParkingRight_Permit_Unknown",
                                KeyShort: "ParkingRight_Permit_Unknown_Short",
                                FirstIndex: 29,
                                LastIndex: 29,
                                Name: "Unknown",
                                FullName: "Parking Right | Permit | Unknown",
                                Description: "",
                                Count: 0,
                                Perc: 0.,
                                Options: {}
                            },
                        }
                    },
                    PaperTicket: {
                        Key: "ParkingRight_PaperTicket",
                        KeyShort: "ParkingRight_PaperTicket_Short",
                        FirstIndex: 30,
                        LastIndex: 30,
                        Name: "Paper Ticket",
                        FullName: "Parking Right | Paper Ticket",
                        Description: "Never a digital option, must only be set after a verification",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    Unknown: {
                        Key: "ParkingRight_Unknown",
                        KeyShort: "ParkingRight_Unknown_Short",
                        FirstIndex: 31,
                        LastIndex: 31,
                        Name: "Unknown",
                        FullName: "Parking Right | Unknown",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                }
            },
            NoParkingRight: {
                Key: "NoParkingRight",
                KeyShort: "NoParkingRight",
                FirstIndex: 32,
                LastIndex: 32,
                Name: "No Parking Right",
                FullName: "No Parking Right",
                Description: "",
                Count: 0,
                Perc: 0.,
                Options: {}
            },
            Indecisive: {
                Key: "Indecisive",
                KeyShort: "Indecisive",
                FirstIndex: 33,
                LastIndex: 36,
                Name: "Indecisive",
                FullName: "Indecisive",
                Description: "Couldn't determine if the vehicle has a parking right, count as no parkingright",
                Count: 0,
                Perc: 0.,
                Options: {
                    PrdbError: {
                        Key: "Indecisive_PrdbError",
                        KeyShort: "Indecisive_PrdbError_Short",
                        FirstIndex: 33,
                        LastIndex: 33,
                        Name: "Prdb Error",
                        FullName: "Indecisive | Prdb Error",
                        Description: "The check in at least one of the parking right databases failed",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    PositionInaccurate: {
                        Key: "Indecisive_PositionInaccurate",
                        KeyShort: "Indecisive_PositionInaccurate_Short",
                        FirstIndex: 34,
                        LastIndex: 34,
                        Name: "Position Inaccurate",
                        FullName: "Indecisive | Position Inaccurate",
                        Description: "Unable to determine if the vehicle is in the location where the parking right is valid",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    DetectionInaccurate: {
                        Key: "Indecisive_DetectionInaccurate",
                        KeyShort: "Indecisive_DetectionInaccurate_Short",
                        FirstIndex: 35,
                        LastIndex: 35,
                        Name: "Detection Inaccurate",
                        FullName: "Indecisive | Detection Inaccurate",
                        Description: "a car has been seen, either by the distance sensor or by the ulpr with a low confidance",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    Unknown: {
                        Key: "Indecisive_Unknown",
                        KeyShort: "Indecisive_Unknown_Short",
                        FirstIndex: 36,
                        LastIndex: 36,
                        Name: "Unknown",
                        FullName: "Indecisive | Unknown",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                }
            },
            NotProcessed: {
                Key: "NotProcessed",
                KeyShort: "NotProcessed",
                FirstIndex: 37,
                LastIndex: 51,
                Name: "Not Processed",
                FullName: "Not Processed",
                Description: "",
                Count: 0,
                Perc: 0.,
                Options: {
                    DuplicateDetection: {
                        Key: "NotProcessed_DuplicateDetection",
                        KeyShort: "NotProcessed_DuplicateDetection_Short",
                        FirstIndex: 37,
                        LastIndex: 37,
                        Name: "Duplicate Detection",
                        FullName: "Not Processed | Duplicate Detection",
                        Description: "The Detection is a Duplicate",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    DuplicateSuspect: {
                        Key: "NotProcessed_DuplicateSuspect",
                        KeyShort: "NotProcessed_DuplicateSuspect_Short",
                        FirstIndex: 38,
                        LastIndex: 38,
                        Name: "Duplicate Suspect",
                        FullName: "Not Processed | Duplicate Suspect",
                        Description: "The Suspect is a Duplicate",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    BlueZone: {
                        Key: "NotProcessed_BlueZone",
                        KeyShort: "NotProcessed_BlueZone_Short",
                        FirstIndex: 39,
                        LastIndex: 39,
                        Name: "Blue Zone",
                        FullName: "Not Processed | Blue Zone",
                        Description: "No parking right check",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    IllegallyParked: {
                        Key: "NotProcessed_IllegallyParked",
                        KeyShort: "NotProcessed_IllegallyParked_Short",
                        FirstIndex: 40,
                        LastIndex: 40,
                        Name: "Illegally Parked",
                        FullName: "Not Processed | Illegally Parked",
                        Description: "No parking right check",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    DetectionInvalid: {
                        Key: "NotProcessed_DetectionInvalid",
                        KeyShort: "NotProcessed_DetectionInvalid_Short",
                        FirstIndex: 41,
                        LastIndex: 41,
                        Name: "Detection Invalid",
                        FullName: "Not Processed | Detection Invalid",
                        Description: "Low Detection Confidence",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    PositionError: {
                        Key: "NotProcessed_PositionError",
                        KeyShort: "NotProcessed_PositionError_Short",
                        FirstIndex: 42,
                        LastIndex: 42,
                        Name: "Position Error",
                        FullName: "Not Processed | Position Error",
                        Description: "No valid gps",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    OutsideParkingSpace: {
                        Key: "NotProcessed_OutsideParkingSpace",
                        KeyShort: "NotProcessed_OutsideParkingSpace_Short",
                        FirstIndex: 43,
                        LastIndex: 43,
                        Name: "Outside Parking Space",
                        FullName: "Not Processed | Outside Parking Space",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    UnknownParkingRegime: {
                        Key: "NotProcessed_UnknownParkingRegime",
                        KeyShort: "NotProcessed_UnknownParkingRegime_Short",
                        FirstIndex: 44,
                        LastIndex: 44,
                        Name: "Unknown Parking Regime",
                        FullName: "Not Processed | Unknown Parking Regime",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    InvalidParkingRegime: {
                        Key: "NotProcessed_InvalidParkingRegime",
                        KeyShort: "NotProcessed_InvalidParkingRegime_Short",
                        FirstIndex: 45,
                        LastIndex: 45,
                        Name: "Invalid Parking Regime",
                        FullName: "Not Processed | Invalid Parking Regime",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    PrdbError: {
                        Key: "NotProcessed_PrdbError",
                        KeyShort: "NotProcessed_PrdbError_Short",
                        FirstIndex: 46,
                        LastIndex: 46,
                        Name: "Prdb Error",
                        FullName: "Not Processed | Prdb Error",
                        Description: "Not Used statistics",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    OutdatedControlCenter: {
                        Key: "NotProcessed_OutdatedControlCenter",
                        KeyShort: "NotProcessed_OutdatedControlCenter_Short",
                        FirstIndex: 47,
                        LastIndex: 47,
                        Name: "Outdated Control Center",
                        FullName: "Not Processed | Outdated Control Center",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    OutdatedExternal: {
                        Key: "NotProcessed_OutdatedExternal",
                        KeyShort: "NotProcessed_OutdatedExternal_Short",
                        FirstIndex: 48,
                        LastIndex: 48,
                        Name: "Outdated External",
                        FullName: "Not Processed | Outdated External",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    NotEnforcing: {
                        Key: "NotProcessed_NotEnforcing",
                        KeyShort: "NotProcessed_NotEnforcing_Short",
                        FirstIndex: 49,
                        LastIndex: 49,
                        Name: "Not Enforcing",
                        FullName: "Not Processed | Not Enforcing",
                        Description: "detection scanned in an other mode then enforcing (test, parkingsessions, parkingPressure )",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    NoOverviewImages: {
                        Key: "NotProcessed_NoOverviewImages",
                        KeyShort: "NotProcessed_NoOverviewImages_Short",
                        FirstIndex: 50,
                        LastIndex: 50,
                        Name: "No Overview Images",
                        FullName: "Not Processed | No Overview Images",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    Unknown: {
                        Key: "NotProcessed_Unknown",
                        KeyShort: "NotProcessed_Unknown_Short",
                        FirstIndex: 51,
                        LastIndex: 51,
                        Name: "Unknown",
                        FullName: "Not Processed | Unknown",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                }
            },
        };
    }
    addOption(key, Count) {
        let OptionAncestors = {
            InProgress: ["InProgress"],
            NoParkingRightNeeded_MovingVehicle: ["NoParkingRightNeeded", "MovingVehicle"],
            NoParkingRightNeeded_BlueZone: ["NoParkingRightNeeded", "BlueZone"],
            NoParkingRightNeeded_FreeParking: ["NoParkingRightNeeded", "FreeParking"],
            NoParkingRightNeeded_ReservedZone: ["NoParkingRightNeeded", "ReservedZone"],
            NoParkingRightNeeded_PrivateParking: ["NoParkingRightNeeded", "PrivateParking"],
            NoParkingRightNeeded_Whitelisted_PoliceAmbulanceFire: ["NoParkingRightNeeded", "Whitelisted", "PoliceAmbulanceFire"],
            NoParkingRightNeeded_Whitelisted_Diplomats: ["NoParkingRightNeeded", "Whitelisted", "Diplomats"],
            NoParkingRightNeeded_Whitelisted_ElectricVehicle: ["NoParkingRightNeeded", "Whitelisted", "ElectricVehicle"],
            NoParkingRightNeeded_Whitelisted_CarSharing: ["NoParkingRightNeeded", "Whitelisted", "CarSharing"],
            NoParkingRightNeeded_Whitelisted_SpecialVehicle: ["NoParkingRightNeeded", "Whitelisted", "SpecialVehicle"],
            NoParkingRightNeeded_Whitelisted_Unknown: ["NoParkingRightNeeded", "Whitelisted", "Unknown"],
            NoParkingRightNeeded_LoadUnload: ["NoParkingRightNeeded", "LoadUnload"],
            NoParkingRightNeeded_Disabled: ["NoParkingRightNeeded", "Disabled"],
            NoParkingRightNeeded_WrongStreetSign: ["NoParkingRightNeeded", "WrongStreetSign"],
            NoParkingRightNeeded_AlreadyFined: ["NoParkingRightNeeded", "AlreadyFined"],
            NoParkingRightNeeded_NotAVehicle: ["NoParkingRightNeeded", "NotAVehicle"],
            NoParkingRightNeeded_DefectVendingMachine: ["NoParkingRightNeeded", "DefectVendingMachine"],
            NoParkingRightNeeded_Excemption: ["NoParkingRightNeeded", "Excemption"],
            NoParkingRightNeeded_Unknown: ["NoParkingRightNeeded", "Unknown"],
            ParkingRight_Visitor_PayByPhone: ["ParkingRight", "Visitor", "PayByPhone"],
            ParkingRight_Visitor_Ticket: ["ParkingRight", "Visitor", "Ticket"],
            ParkingRight_Visitor_Permit: ["ParkingRight", "Visitor", "Permit"],
            ParkingRight_Visitor_PaperTicket: ["ParkingRight", "Visitor", "PaperTicket"],
            ParkingRight_Visitor_Unknown: ["ParkingRight", "Visitor", "Unknown"],
            ParkingRight_Permit_Business: ["ParkingRight", "Permit", "Business"],
            ParkingRight_Permit_Resident: ["ParkingRight", "Permit", "Resident"],
            ParkingRight_Permit_PaperTicket: ["ParkingRight", "Permit", "PaperTicket"],
            ParkingRight_Permit_Unknown: ["ParkingRight", "Permit", "Unknown"],
            ParkingRight_PaperTicket: ["ParkingRight", "PaperTicket"],
            ParkingRight_Unknown: ["ParkingRight", "Unknown"],
            NoParkingRight: ["NoParkingRight"],
            Indecisive_PrdbError: ["Indecisive", "PrdbError"],
            Indecisive_PositionInaccurate: ["Indecisive", "PositionInaccurate"],
            Indecisive_DetectionInaccurate: ["Indecisive", "DetectionInaccurate"],
            Indecisive_Unknown: ["Indecisive", "Unknown"],
            NotProcessed_DuplicateDetection: ["NotProcessed", "DuplicateDetection"],
            NotProcessed_DuplicateSuspect: ["NotProcessed", "DuplicateSuspect"],
            NotProcessed_BlueZone: ["NotProcessed", "BlueZone"],
            NotProcessed_IllegallyParked: ["NotProcessed", "IllegallyParked"],
            NotProcessed_DetectionInvalid: ["NotProcessed", "DetectionInvalid"],
            NotProcessed_PositionError: ["NotProcessed", "PositionError"],
            NotProcessed_OutsideParkingSpace: ["NotProcessed", "OutsideParkingSpace"],
            NotProcessed_UnknownParkingRegime: ["NotProcessed", "UnknownParkingRegime"],
            NotProcessed_InvalidParkingRegime: ["NotProcessed", "InvalidParkingRegime"],
            NotProcessed_PrdbError: ["NotProcessed", "PrdbError"],
            NotProcessed_OutdatedControlCenter: ["NotProcessed", "OutdatedControlCenter"],
            NotProcessed_OutdatedExternal: ["NotProcessed", "OutdatedExternal"],
            NotProcessed_NotEnforcing: ["NotProcessed", "NotEnforcing"],
            NotProcessed_NoOverviewImages: ["NotProcessed", "NoOverviewImages"],
            NotProcessed_Unknown: ["NotProcessed", "Unknown"],
        };
        let Ancestors = OptionAncestors[key];
        if (!Array.isArray(Ancestors))
            throw Error(key + ' not an option for ParkingRight');
        let Current = this;
        Current.Count += Count;
        Current.Perc = Current.Count ? 1. : 0.;
        let ParentCount = Current.Count;
        for (let Ancestor of Ancestors) {
            Current = Current.Options[Ancestor];
            Current.Count += Count;
            Current.Perc = Current.Count / ParentCount;
            ParentCount = Current.Count;
        }
    }
}
/// <!--=======================================================================================================-->
///                              AVerification
/// <!----------------------------------------------------------------------------------------------------------->
export class AVerification {
    constructor() {
        this.Key = "Verification";
        this.KeyShort = "Verification";
        this.FirstIndex = 1;
        this.LastIndex = 23;
        this.Name = "Verification";
        this.FullName = "Verification";
        this.Description = "";
        this.Count = 0;
        this.Perc = 0.;
        this.Options = {
            InProgress: {
                Key: "InProgress",
                KeyShort: "InProgress",
                FirstIndex: 1,
                LastIndex: 1,
                Name: "In Progress",
                FullName: "In Progress",
                Description: "",
                Count: 0,
                Perc: 0.,
                Options: {}
            },
            NoVerificationNeeded: {
                Key: "NoVerificationNeeded",
                KeyShort: "NoVerificationNeeded",
                FirstIndex: 2,
                LastIndex: 2,
                Name: "No Verification Needed",
                FullName: "No Verification Needed",
                Description: "",
                Count: 0,
                Perc: 0.,
                Options: {}
            },
            Fined: {
                Key: "Fined",
                KeyShort: "Fined",
                FirstIndex: 3,
                LastIndex: 7,
                Name: "Fined",
                FullName: "Fined",
                Description: "",
                Count: 0,
                Perc: 0.,
                Options: {
                    NoParkingRight: {
                        Key: "Fined_NoParkingRight",
                        KeyShort: "Fined_NoParkingRight_Short",
                        FirstIndex: 3,
                        LastIndex: 3,
                        Name: "No Parking Right",
                        FullName: "Fined | No Parking Right",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    IllegallyParked: {
                        Key: "Fined_IllegallyParked",
                        KeyShort: "Fined_IllegallyParked_Short",
                        FirstIndex: 4,
                        LastIndex: 4,
                        Name: "Illegally Parked",
                        FullName: "Fined | Illegally Parked",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    TimeLimitedParkingExperired: {
                        Key: "Fined_TimeLimitedParkingExperired",
                        KeyShort: "Fined_TimeLimitedParkingExperired_Short",
                        FirstIndex: 5,
                        LastIndex: 5,
                        Name: "Time Limited Parking Experired",
                        FullName: "Fined | Time Limited Parking Experired",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    InProgress: {
                        Key: "Fined_InProgress",
                        KeyShort: "Fined_InProgress_Short",
                        FirstIndex: 6,
                        LastIndex: 6,
                        Name: "In Progress",
                        FullName: "Fined | In Progress",
                        Description: "PRDB recheck and/or cancelation option",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    Unknown: {
                        Key: "Fined_Unknown",
                        KeyShort: "Fined_Unknown_Short",
                        FirstIndex: 7,
                        LastIndex: 7,
                        Name: "Unknown",
                        FullName: "Fined | Unknown",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                }
            },
            Reprimanded: {
                Key: "Reprimanded",
                KeyShort: "Reprimanded",
                FirstIndex: 8,
                LastIndex: 12,
                Name: "Reprimanded",
                FullName: "Reprimanded",
                Description: "",
                Count: 0,
                Perc: 0.,
                Options: {
                    DriverWarned: {
                        Key: "Reprimanded_DriverWarned",
                        KeyShort: "Reprimanded_DriverWarned_Short",
                        FirstIndex: 8,
                        LastIndex: 8,
                        Name: "Driver Warned",
                        FullName: "Reprimanded | Driver Warned",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    VehicleStickered: {
                        Key: "Reprimanded_VehicleStickered",
                        KeyShort: "Reprimanded_VehicleStickered_Short",
                        FirstIndex: 9,
                        LastIndex: 9,
                        Name: "Vehicle Stickered",
                        FullName: "Reprimanded | Vehicle Stickered",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    VehicleClamped: {
                        Key: "Reprimanded_VehicleClamped",
                        KeyShort: "Reprimanded_VehicleClamped_Short",
                        FirstIndex: 10,
                        LastIndex: 10,
                        Name: "Vehicle Clamped",
                        FullName: "Reprimanded | Vehicle Clamped",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    VehicleUnclamped: {
                        Key: "Reprimanded_VehicleUnclamped",
                        KeyShort: "Reprimanded_VehicleUnclamped_Short",
                        FirstIndex: 11,
                        LastIndex: 11,
                        Name: "Vehicle Unclamped",
                        FullName: "Reprimanded | Vehicle Unclamped",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    Unknown: {
                        Key: "Reprimanded_Unknown",
                        KeyShort: "Reprimanded_Unknown_Short",
                        FirstIndex: 12,
                        LastIndex: 12,
                        Name: "Unknown",
                        FullName: "Reprimanded | Unknown",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                }
            },
            NotFined: {
                Key: "NotFined",
                KeyShort: "NotFined",
                FirstIndex: 13,
                LastIndex: 13,
                Name: "Not Fined",
                FullName: "Not Fined",
                Description: "",
                Count: 0,
                Perc: 0.,
                Options: {}
            },
            NotProcessed: {
                Key: "NotProcessed",
                KeyShort: "NotProcessed",
                FirstIndex: 14,
                LastIndex: 23,
                Name: "Not Processed",
                FullName: "Not Processed",
                Description: "",
                Count: 0,
                Perc: 0.,
                Options: {
                    VehicleNotFound: {
                        Key: "NotProcessed_VehicleNotFound",
                        KeyShort: "NotProcessed_VehicleNotFound_Short",
                        FirstIndex: 14,
                        LastIndex: 14,
                        Name: "Vehicle Not Found",
                        FullName: "Not Processed | Vehicle Not Found",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    WrongRecognition: {
                        Key: "NotProcessed_WrongRecognition",
                        KeyShort: "NotProcessed_WrongRecognition_Short",
                        FirstIndex: 15,
                        LastIndex: 15,
                        Name: "Wrong Recognition",
                        FullName: "Not Processed | Wrong Recognition",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    NoOverviewImages: {
                        Key: "NotProcessed_NoOverviewImages",
                        KeyShort: "NotProcessed_NoOverviewImages_Short",
                        FirstIndex: 16,
                        LastIndex: 16,
                        Name: "No Overview Images",
                        FullName: "Not Processed | No Overview Images",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    OutdatedControlCenter: {
                        Key: "NotProcessed_OutdatedControlCenter",
                        KeyShort: "NotProcessed_OutdatedControlCenter_Short",
                        FirstIndex: 17,
                        LastIndex: 17,
                        Name: "Outdated Control Center",
                        FullName: "Not Processed | Outdated Control Center",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    OutdatedExternal: {
                        Key: "NotProcessed_OutdatedExternal",
                        KeyShort: "NotProcessed_OutdatedExternal_Short",
                        FirstIndex: 18,
                        LastIndex: 18,
                        Name: "Outdated External",
                        FullName: "Not Processed | Outdated External",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    Rejected: {
                        Key: "NotProcessed_Rejected",
                        KeyShort: "NotProcessed_Rejected_Short",
                        FirstIndex: 19,
                        LastIndex: 19,
                        Name: "Rejected",
                        FullName: "Not Processed | Rejected",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    FineFailed: {
                        Key: "NotProcessed_FineFailed",
                        KeyShort: "NotProcessed_FineFailed_Short",
                        FirstIndex: 20,
                        LastIndex: 20,
                        Name: "Fine Failed",
                        FullName: "Not Processed | Fine Failed",
                        Description: "Failed to create a fine",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    DuplicateFine: {
                        Key: "NotProcessed_DuplicateFine",
                        KeyShort: "NotProcessed_DuplicateFine_Short",
                        FirstIndex: 21,
                        LastIndex: 21,
                        Name: "Duplicate Fine",
                        FullName: "Not Processed | Duplicate Fine",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    FineCanceled: {
                        Key: "NotProcessed_FineCanceled",
                        KeyShort: "NotProcessed_FineCanceled_Short",
                        FirstIndex: 22,
                        LastIndex: 22,
                        Name: "Fine Canceled",
                        FullName: "Not Processed | Fine Canceled",
                        Description: "user has canceled the fine",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    Unknown: {
                        Key: "NotProcessed_Unknown",
                        KeyShort: "NotProcessed_Unknown_Short",
                        FirstIndex: 23,
                        LastIndex: 23,
                        Name: "Unknown",
                        FullName: "Not Processed | Unknown",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                }
            },
        };
    }
    addOption(key, Count) {
        let OptionAncestors = {
            InProgress: ["InProgress"],
            NoVerificationNeeded: ["NoVerificationNeeded"],
            Fined_NoParkingRight: ["Fined", "NoParkingRight"],
            Fined_IllegallyParked: ["Fined", "IllegallyParked"],
            Fined_TimeLimitedParkingExperired: ["Fined", "TimeLimitedParkingExperired"],
            Fined_InProgress: ["Fined", "InProgress"],
            Fined_Unknown: ["Fined", "Unknown"],
            Reprimanded_DriverWarned: ["Reprimanded", "DriverWarned"],
            Reprimanded_VehicleStickered: ["Reprimanded", "VehicleStickered"],
            Reprimanded_VehicleClamped: ["Reprimanded", "VehicleClamped"],
            Reprimanded_VehicleUnclamped: ["Reprimanded", "VehicleUnclamped"],
            Reprimanded_Unknown: ["Reprimanded", "Unknown"],
            NotFined: ["NotFined"],
            NotProcessed_VehicleNotFound: ["NotProcessed", "VehicleNotFound"],
            NotProcessed_WrongRecognition: ["NotProcessed", "WrongRecognition"],
            NotProcessed_NoOverviewImages: ["NotProcessed", "NoOverviewImages"],
            NotProcessed_OutdatedControlCenter: ["NotProcessed", "OutdatedControlCenter"],
            NotProcessed_OutdatedExternal: ["NotProcessed", "OutdatedExternal"],
            NotProcessed_Rejected: ["NotProcessed", "Rejected"],
            NotProcessed_FineFailed: ["NotProcessed", "FineFailed"],
            NotProcessed_DuplicateFine: ["NotProcessed", "DuplicateFine"],
            NotProcessed_FineCanceled: ["NotProcessed", "FineCanceled"],
            NotProcessed_Unknown: ["NotProcessed", "Unknown"],
        };
        let Ancestors = OptionAncestors[key];
        if (!Array.isArray(Ancestors))
            throw Error(key + ' not an option for Verification');
        let Current = this;
        Current.Count += Count;
        Current.Perc = Current.Count ? 1. : 0.;
        let ParentCount = Current.Count;
        for (let Ancestor of Ancestors) {
            Current = Current.Options[Ancestor];
            Current.Count += Count;
            Current.Perc = Current.Count / ParentCount;
            ParentCount = Current.Count;
        }
    }
}
/// <!--=======================================================================================================-->
///                              ADetectionState
/// <!----------------------------------------------------------------------------------------------------------->
export class ADetectionState {
    constructor() {
        this.Key = "DetectionState";
        this.KeyShort = "DetectionState";
        this.FirstIndex = 1;
        this.LastIndex = 22;
        this.Name = "DetectionState";
        this.FullName = "DetectionState";
        this.Description = "";
        this.Count = 0;
        this.Perc = 0.;
        this.Options = {
            InProgress: {
                Key: "InProgress",
                KeyShort: "InProgress",
                FirstIndex: 1,
                LastIndex: 21,
                Name: "In Progress",
                FullName: "In Progress",
                Description: "",
                Count: 0,
                Perc: 0.,
                Options: {
                    Detected: {
                        Key: "InProgress_Detected",
                        KeyShort: "InProgress_Detected_Short",
                        FirstIndex: 1,
                        LastIndex: 1,
                        Name: "Detected",
                        FullName: "In Progress | Detected",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    Received: {
                        Key: "InProgress_Received",
                        KeyShort: "InProgress_Received_Short",
                        FirstIndex: 2,
                        LastIndex: 2,
                        Name: "Received",
                        FullName: "In Progress | Received",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    RemoteCorrectionQueue: {
                        Key: "InProgress_RemoteCorrectionQueue",
                        KeyShort: "InProgress_RemoteCorrectionQueue_Short",
                        FirstIndex: 3,
                        LastIndex: 3,
                        Name: "Remote Correction Queue",
                        FullName: "In Progress | Remote Correction Queue",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    GeoQueue: {
                        Key: "InProgress_GeoQueue",
                        KeyShort: "InProgress_GeoQueue_Short",
                        FirstIndex: 4,
                        LastIndex: 4,
                        Name: "Geo Queue",
                        FullName: "In Progress | Geo Queue",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    ImageQueue: {
                        Key: "InProgress_ImageQueue",
                        KeyShort: "InProgress_ImageQueue_Short",
                        FirstIndex: 5,
                        LastIndex: 5,
                        Name: "Image Queue",
                        FullName: "In Progress | Image Queue",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    Deferred: {
                        Key: "InProgress_Deferred",
                        KeyShort: "InProgress_Deferred_Short",
                        FirstIndex: 6,
                        LastIndex: 6,
                        Name: "Deferred",
                        FullName: "In Progress | Deferred",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    External: {
                        Key: "InProgress_External",
                        KeyShort: "InProgress_External_Short",
                        FirstIndex: 7,
                        LastIndex: 7,
                        Name: "External",
                        FullName: "In Progress | External",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    PrdbQueue: {
                        Key: "InProgress_PrdbQueue",
                        KeyShort: "InProgress_PrdbQueue_Short",
                        FirstIndex: 8,
                        LastIndex: 8,
                        Name: "Prdb Queue",
                        FullName: "In Progress | Prdb Queue",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    PrdbCheck: {
                        Key: "InProgress_PrdbCheck",
                        KeyShort: "InProgress_PrdbCheck_Short",
                        FirstIndex: 9,
                        LastIndex: 9,
                        Name: "Prdb Check",
                        FullName: "In Progress | Prdb Check",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    PdaVerificationQueue: {
                        Key: "InProgress_PdaVerificationQueue",
                        KeyShort: "InProgress_PdaVerificationQueue_Short",
                        FirstIndex: 10,
                        LastIndex: 10,
                        Name: "Pda Verification Queue",
                        FullName: "In Progress | Pda Verification Queue",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    CentralVerificationQueue: {
                        Key: "InProgress_CentralVerificationQueue",
                        KeyShort: "InProgress_CentralVerificationQueue_Short",
                        FirstIndex: 11,
                        LastIndex: 11,
                        Name: "Central Verification Queue",
                        FullName: "In Progress | Central Verification Queue",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    AssignedToPda: {
                        Key: "InProgress_AssignedToPda",
                        KeyShort: "InProgress_AssignedToPda_Short",
                        FirstIndex: 12,
                        LastIndex: 12,
                        Name: "Assigned To Pda",
                        FullName: "In Progress | Assigned To Pda",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    AssignedToCentralVerification: {
                        Key: "InProgress_AssignedToCentralVerification",
                        KeyShort: "InProgress_AssignedToCentralVerification_Short",
                        FirstIndex: 13,
                        LastIndex: 13,
                        Name: "Assigned To Central Verification",
                        FullName: "In Progress | Assigned To Central Verification",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    DetachedFromPda: {
                        Key: "InProgress_DetachedFromPda",
                        KeyShort: "InProgress_DetachedFromPda_Short",
                        FirstIndex: 14,
                        LastIndex: 14,
                        Name: "Detached From Pda",
                        FullName: "In Progress | Detached From Pda",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    DetachedFromCentralVerification: {
                        Key: "InProgress_DetachedFromCentralVerification",
                        KeyShort: "InProgress_DetachedFromCentralVerification_Short",
                        FirstIndex: 15,
                        LastIndex: 15,
                        Name: "Detached From Central Verification",
                        FullName: "In Progress | Detached From Central Verification",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    AwaitingFineCancelation: {
                        Key: "InProgress_AwaitingFineCancelation",
                        KeyShort: "InProgress_AwaitingFineCancelation_Short",
                        FirstIndex: 16,
                        LastIndex: 16,
                        Name: "Awaiting Fine Cancelation",
                        FullName: "In Progress | Awaiting Fine Cancelation",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    FineRecheckQueue: {
                        Key: "InProgress_FineRecheckQueue",
                        KeyShort: "InProgress_FineRecheckQueue_Short",
                        FirstIndex: 17,
                        LastIndex: 17,
                        Name: "Fine Recheck Queue",
                        FullName: "In Progress | Fine Recheck Queue",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    ProcessFineQueue: {
                        Key: "InProgress_ProcessFineQueue",
                        KeyShort: "InProgress_ProcessFineQueue_Short",
                        FirstIndex: 18,
                        LastIndex: 18,
                        Name: "Process Fine Queue",
                        FullName: "In Progress | Process Fine Queue",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    ProcessVerificationQueue: {
                        Key: "InProgress_ProcessVerificationQueue",
                        KeyShort: "InProgress_ProcessVerificationQueue_Short",
                        FirstIndex: 19,
                        LastIndex: 19,
                        Name: "Process Verification Queue",
                        FullName: "In Progress | Process Verification Queue",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    FineErrorRetryQueue: {
                        Key: "InProgress_FineErrorRetryQueue",
                        KeyShort: "InProgress_FineErrorRetryQueue_Short",
                        FirstIndex: 20,
                        LastIndex: 20,
                        Name: "Fine Error Retry Queue",
                        FullName: "In Progress | Fine Error Retry Queue",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                    Unknown: {
                        Key: "InProgress_Unknown",
                        KeyShort: "InProgress_Unknown_Short",
                        FirstIndex: 21,
                        LastIndex: 21,
                        Name: "Unknown",
                        FullName: "In Progress | Unknown",
                        Description: "",
                        Count: 0,
                        Perc: 0.,
                        Options: {}
                    },
                }
            },
            Done: {
                Key: "Done",
                KeyShort: "Done",
                FirstIndex: 22,
                LastIndex: 22,
                Name: "Done",
                FullName: "Done",
                Description: "",
                Count: 0,
                Perc: 0.,
                Options: {}
            },
        };
    }
    addOption(key, Count) {
        let OptionAncestors = {
            InProgress_Detected: ["InProgress", "Detected"],
            InProgress_Received: ["InProgress", "Received"],
            InProgress_RemoteCorrectionQueue: ["InProgress", "RemoteCorrectionQueue"],
            InProgress_GeoQueue: ["InProgress", "GeoQueue"],
            InProgress_ImageQueue: ["InProgress", "ImageQueue"],
            InProgress_Deferred: ["InProgress", "Deferred"],
            InProgress_External: ["InProgress", "External"],
            InProgress_PrdbQueue: ["InProgress", "PrdbQueue"],
            InProgress_PrdbCheck: ["InProgress", "PrdbCheck"],
            InProgress_PdaVerificationQueue: ["InProgress", "PdaVerificationQueue"],
            InProgress_CentralVerificationQueue: ["InProgress", "CentralVerificationQueue"],
            InProgress_AssignedToPda: ["InProgress", "AssignedToPda"],
            InProgress_AssignedToCentralVerification: ["InProgress", "AssignedToCentralVerification"],
            InProgress_DetachedFromPda: ["InProgress", "DetachedFromPda"],
            InProgress_DetachedFromCentralVerification: ["InProgress", "DetachedFromCentralVerification"],
            InProgress_AwaitingFineCancelation: ["InProgress", "AwaitingFineCancelation"],
            InProgress_FineRecheckQueue: ["InProgress", "FineRecheckQueue"],
            InProgress_ProcessFineQueue: ["InProgress", "ProcessFineQueue"],
            InProgress_ProcessVerificationQueue: ["InProgress", "ProcessVerificationQueue"],
            InProgress_FineErrorRetryQueue: ["InProgress", "FineErrorRetryQueue"],
            InProgress_Unknown: ["InProgress", "Unknown"],
            Done: ["Done"],
        };
        let Ancestors = OptionAncestors[key];
        if (!Array.isArray(Ancestors))
            throw Error(key + ' not an option for DetectionState');
        let Current = this;
        Current.Count += Count;
        Current.Perc = Current.Count ? 1. : 0.;
        let ParentCount = Current.Count;
        for (let Ancestor of Ancestors) {
            Current = Current.Options[Ancestor];
            Current.Count += Count;
            Current.Perc = Current.Count / ParentCount;
            ParentCount = Current.Count;
        }
    }
}
