import { ARound } from "../AThread.js";
export class APointInternal {
    constructor(opt) {
        this.x = opt.x;
        this.y = opt.y;
    }
}
export class ACubicBezier {
    constructor(opt) {
        this.opt = opt;
    }
    get(t) {
        const { p1, cp1, cp2, p2 } = this.opt;
        const [x0, y0] = [p1.x, p1.y];
        const [x1, y1] = [cp1.x, cp1.y];
        const [x2, y2] = [cp2.x, cp2.y];
        const [x3, y3] = [p2.x, p2.y];
        return {
            x: Math.pow(1 - t, 3) * y0 +
                3 * Math.pow(1 - t, 2) * t * y1 +
                3 * (1 - t) * Math.pow(t, 2) * y2 +
                Math.pow(t, 3) * y3,
            y: Math.pow(1 - t, 3) * x0 +
                3 * Math.pow(1 - t, 2) * t * x1 +
                3 * (1 - t) * Math.pow(t, 2) * x2 +
                Math.pow(t, 3) * x3,
        };
    }
    x(t) {
        return this.get(t).x;
    }
    y(t) {
        return this.get(t).y;
    }
    test() {
        const res = [];
        const steps = 6;
        for (let i = 0; i < steps; i++) {
            let t = i / steps;
            const valX = this.x(t);
            const valY = this.y(t);
            res.push({ x: ARound(valX, 2), y: ARound(valY, 2) });
        }
        res.push({ x: 1, y: 0 });
        console.log(res.map((p, i) => {
            return [`${i.toFixed(0)}`, p.x, p.y];
        }));
        return res;
    }
}
export const APoint = (opt) => new APointInternal(opt);
export const ABezier = function (pt1, cpt1, cpt2, pt2) {
    const args = [pt1, cpt1, cpt2, pt2];
    const [p1, cp1, cp2, p2] = args.map(p => p instanceof APointInternal ? p : APoint(p));
    return new ACubicBezier({ p1, cp1, cp2, p2 });
};
export const ABezierFromString = (opt) => {
    opt = opt.replace(/[^0-9,]/g, '');
    opt = opt.replace(/,,/g, '');
    var split = opt.split(",").map(str => Number(str));
    if (split.length !== 8)
        throw new Error(`Invalid Bezier String Expected size=8 but received size=${split.length}`);
    return ABezier({ x: 1 * split[0], y: 1 * split[1] }, { x: 1 * split[2], y: 1 * split[3] }, { x: 1 * split[4], y: 1 * split[5] }, { x: 1 * split[6], y: 1 * split[7] });
};
export function testExponentialZeroToHundred() {
    var b = ABezier({ x: 0, y: 1 }, { x: 0, y: 0 }, { x: 0, y: 0 }, { x: 1, y: 0 });
    for (let x = 0; x <= 50; x++) {
        let v = b.y(x === 0 ? 0 : (x / 50));
        console.log(`${x}\t ${(x / 50).toFixed(2)}\t`, v.toFixed(2), ''.padStart(v * 100, '='));
    }
    return b;
}
export function testExponentialHundredToZero() {
    var b = ABezier({ x: 0, y: 1 }, { x: 0.5, y: 0 }, { x: 1, y: 0 }, { x: 1, y: 0 });
    for (let x = 0; x <= 50; x++) {
        let v = b.x(x === 0 ? 0 : (x / 50));
        console.log(`${x}\t ${(x / 50).toFixed(2)}\t`, v.toFixed(2), ''.padStart(v * 100, '='));
    }
    return b;
}
Object.assign(globalThis, { APointInternal, ACubicBezier, APoint, ABezier, ABezierFromString });
