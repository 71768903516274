import { sleep } from '../core/AEngine.js';
import { AFormatDateTimezone } from '../utils/tools.js';
import { EVENTS } from './AEventService.js';
export var LoopLifeCycle;
(function (LoopLifeCycle) {
    LoopLifeCycle[LoopLifeCycle["UNKNOWN"] = 0] = "UNKNOWN";
    LoopLifeCycle[LoopLifeCycle["PAGE"] = 1] = "PAGE";
    LoopLifeCycle[LoopLifeCycle["CONTINUOUS"] = 2] = "CONTINUOUS";
})(LoopLifeCycle || (LoopLifeCycle = {}));
export class AAutoRefreshService {
    autoInit() {
        this.startLoop({
            call: () => $('.timebar').text(AFormatDateTimezone(new Date())),
            lifecycle: LoopLifeCycle.CONTINUOUS,
            interval: 1000,
            args: []
        });
        $('.timebar').removeClass('hidden');
    }
    startLoop(options) {
        let _shouldContinue = true;
        const stopLoop = () => _shouldContinue = false;
        const shouldContinue = () => _shouldContinue;
        const opt = { ...options, ...{ stopLoop, shouldContinue } };
        this.initLoopLifeCycle(opt);
        const firstPromise = this.loop(opt);
        return { stopLoop, shouldContinue, firstPromise };
    }
    initLoopLifeCycle({ lifecycle, stopLoop }) {
        const cycleConfigs = {
            [LoopLifeCycle.PAGE]: () => Events.once(EVENTS.ROUTE_CHANGED, () => stopLoop()),
            [LoopLifeCycle.CONTINUOUS]: () => { }
        };
        if (!cycleConfigs.hasOwnProperty(lifecycle)) {
            throw new Error(`Unknown lifecyle "${lifecycle}"!`);
        }
        const initCycleConfig = cycleConfigs[lifecycle];
        initCycleConfig();
    }
    async loop({ call, context, args, interval, shouldContinue, stopLoop }) {
        if (!shouldContinue()) {
            return;
        }
        await Promise.resolve().then(_ => call.apply(context, args));
        await sleep(interval);
        this.loop({ call, context, args, interval, shouldContinue, stopLoop });
    }
}
