import { AUrlEncodedImageFromBase64 } from "../utils/tools.js";
export class APhotoFetcher {
    constructor() { }
    async fetchPhotos(fetchType, opt) {
        const { DetectionId, DetectionDeviceId } = (opt.marker === undefined) ? opt : opt.marker.get('PK');
        const ids = { DetectionId: DetectionId, DetectionDeviceId: DetectionDeviceId };
        const images = await Loading.waitForPromises([
            (fetchType === 'all' || fetchType === 'plate') ? this.fetchPlateImages(ids) : Promise.resolve([]),
            (fetchType === 'all' || fetchType === 'overview') ? this.fetchOverviewImages(ids) : Promise.resolve([]),
        ]);
        return images.flat();
    }
    async fetchPlateImages(opt) {
        const ares = await Loading.waitForPromises(requestService.fetch({
            AssertValues: true,
            Query: (`
          SELECT DetectionTime, LicensePlate, Image, PlateBounds
          FROM plateimages
          WHERE DetectionId=:DetectionId AND DetectionDeviceId=:DetectionDeviceId
        `),
            Name: 'fetchPlateImages',
            Params: opt,
            Language: Language
        }));
        return ares.map(({ Image }, i) => {
            return {
                Index: i + 1,
                Caption: 'LicensePlate',
                src: AUrlEncodedImageFromBase64(Image),
            };
        });
    }
    async fetchOverviewImages(opt) {
        const ares = await Loading.waitForPromises(requestService.fetch({
            AssertValues: true,
            Query: (`
          SELECT \`Index\`, Caption, Image
          FROM overviewimages
          WHERE DetectionId=:DetectionId AND DetectionDeviceId=:DetectionDeviceId
        `),
            Name: 'fetchOverviewImages',
            Params: opt,
            Language: Language
        }));
        return ares.map(({ Index, Caption, Image }, i) => {
            return {
                Index: Index ?? 0,
                Caption: Caption ?? 'Overview',
                src: AUrlEncodedImageFromBase64(Image),
            };
        });
    }
}
