import { sleep } from "../../core/AEngine.js";
import { EVENTS } from "../../services/AEventService.js";
// type APopoverRef = {$ele: JQuery, destroy: () => void}
export class AWindowToolbar {
    // private popovers: (APopoverRef|undefined)[] = []
    constructor(parent, opt) {
        this.parent = parent;
        this.rootItems = opt.map((item) => new AWindowToolbarItem({ ...item, root: true, parent: this, toolbar: this }));
        this.openContextMenus = [];
    }
    findContextMenu(contextMenuId) {
        var buffer = [];
        this.rootItems.map(c => this.getAllChildren(c, buffer));
        return buffer.find(item => item.contextMenuId == contextMenuId);
    }
    closeContextMenus() {
        if (this.openContextMenus.length > 0) {
            this.findContextMenu(this.openContextMenus[0]).closeContextMenu();
        }
    }
    getAllChildren(item, buffer) {
        buffer.push(item);
        if (item.hasChildren) {
            item.children.map(c => {
                if (c != null) {
                    this.getAllChildren(c, buffer);
                }
            });
        }
    }
    attachTo($win) {
        const $target = $win.find('.toolbar-collection');
        $target.html('');
        $target.append(this.rootItems.map((item, i) => item.generateAsRootItem(`toolbar-menu-${i}`)));
        $target.removeClass('hidden');
    }
}
export class AWindowToolbarItem {
    constructor(opt) {
        this.toolbar = opt.toolbar;
        this.root = opt.root ?? false;
        this.title = opt.title;
        this.href = opt.href;
        this.action = opt.action;
        this.parent = opt.parent;
        this.enabled = opt.enabled ?? true;
        this.children = (opt?.children ?? []).map(c => (c !== null) ? new AWindowToolbarItem({ ...c, parent: this, toolbar: this.toolbar }) : null);
    }
    get hasAction() {
        return this.action !== undefined;
    }
    get hasChildren() {
        return this.children.length > 0;
    }
    get hasValidHref() {
        return this.href !== undefined && this.href !== '#';
    }
    generateAsMenuItem() {
        const attrs = [`href="${this.href ?? '#'}"`, this.href ? `target="blank"` : '', this.enabled ? '' : `disabled="disabled"`].filter(v => v != '').join(' ');
        const icon = this.hasChildren ? `<i class="fa-solid fa-chevron-right fa-xs"></i>` : '';
        const $btn = $(/*html*/ `<a ${attrs}><div class="m-item noselect">${this.title} ${icon}</div></a>`)
            .on('click', (e) => (!this.hasValidHref) ? e.preventDefault() : undefined);
        this.$btn = this.initMenuItem($btn);
        return this.$btn;
    }
    generateAsRootItem(id) {
        const $btn = $(/*html*/ `<span id="${id}" class="awin-toolbar-item noselect">${this.title}</span>`);
        this.$btn = this.initMenuItem($btn);
        return this.$btn;
    }
    initMenuItem($btn) {
        $btn.on('mousedown', (e) => {
            if (e.button !== 0)
                return;
            const { hasValidHref, hasAction, hasChildren } = this;
            console.log({ hasValidHref, hasAction, hasChildren });
            if (this.hasValidHref) {
                sleep(80).then(() => this.toolbar.closeContextMenus());
                return;
            }
            if (this.hasAction) {
                this.action(this);
                return this.toolbar.closeContextMenus();
            }
            if (this.hasChildren) {
                if (this.contextMenuId === undefined) {
                    this.openContextMenu();
                    this.initContextMenu();
                }
                else {
                    this.closeContextMenu();
                }
            }
        });
        return $btn;
    }
    openContextMenu() {
        const newSize = this.getAncestry().length;
        if (this.toolbar.openContextMenus.length >= newSize) {
            const toRemove = this.toolbar.openContextMenus[newSize - 1];
            this.toolbar.findContextMenu(toRemove)?.closeContextMenu();
        }
        this.contextMenuId = idAllocatorService.getNextId({ prefix: 'popover-' });
        this.toolbar.openContextMenus.push(this.contextMenuId);
        this.$contextMenu = $(/*html*/ `<div id="${this.contextMenuId}" class="popover-menu-experimental"></div>`).appendTo('body');
        this.children.map(c => {
            if (c === null) {
                return $(/*html*/ `<a class="noselect"><div class="m-item noselect"></div></a>`).appendTo(this.$contextMenu);
            }
            return c.generateAsMenuItem().appendTo(this.$contextMenu);
        });
        const $btn = this.$btn;
        $btn.attr('childpid', this.contextMenuId);
        const options = { anchor: 'left' };
        let bo = ($btn.offset() || { left: 0, top: 0 });
        let mt = this.root ? 3 : -4;
        this.$contextMenu.css((options.anchor === 'left')
            ? { left: bo.left + (this.root ? 0 : $btn.outerWidth()), top: bo.top + ((this.root ? $btn.outerHeight() : 0)) + mt }
            : { right: innerWidth - bo.left - ($btn.innerWidth() || 0), top: bo.top + ($btn.outerHeight() || 0) + mt });
        this.$contextMenu.addClass('popover-open');
        console.log('openContextMenu after:', this.toolbar.openContextMenus, this.getAncestry().length);
    }
    getAncestry() {
        var arr = [], ids = [];
        let curr = this;
        do {
            arr.unshift(curr);
            ids.unshift(curr.contextMenuId);
            curr = curr.parent;
        } while (curr != null && curr instanceof AWindowToolbarItem);
        return ids;
    }
    initContextMenu() {
        this.closeEventId = Events.hardwire(EVENTS.MOUSE_CLICK, ($clicked) => {
            var childpid = $clicked.attr('childpid');
            var isSamePid = $clicked.is(`[childpid=${this.contextMenuId}]`);
            var popoverPid = $clicked.closest('.popover-open').attr('id') ?? childpid;
            var isPartOfAncestry = this.getAncestry().includes($clicked.closest('[childpid]').attr('childpid'));
            var shouldBeOpen = this.toolbar.openContextMenus.includes(this.contextMenuId);
            if (popoverPid !== undefined && (isSamePid || isPartOfAncestry || shouldBeOpen)) {
                return;
            }
            if (this.closeEventId !== undefined) {
                this.closeContextMenu();
            }
        });
    }
    closeContextMenu() {
        const index = this.toolbar.openContextMenus.indexOf(this.contextMenuId);
        if (index !== -1) {
            // Delete current item from array
            this.toolbar.openContextMenus.splice(index, 1)[0];
            // Get child if exists
            const childToRemove = this.toolbar.openContextMenus[index];
            if (childToRemove !== undefined) {
                this.children.find(c => c?.contextMenuId == childToRemove)?.closeContextMenu();
            }
        }
        this.$btn.removeAttr('childpid');
        this.$contextMenu?.remove();
        this.$contextMenu = undefined;
        this.contextMenuId = undefined;
        if (this.closeEventId) {
            Events.off(EVENTS.MOUSE_CLICK, this.closeEventId);
            this.closeEventId = undefined;
        }
        console.log('closeContextMenu after:', this.toolbar.openContextMenus, this.getAncestry().length);
    }
}
