import { AError } from "../classes/AError.js";
import { compareObjects } from "../utils/tools.js";
export function getModelChanges() {
}
export function stringifyChanges(model, editableColumns) {
    const obfuscationArr = Object.keys(editableColumns).filter(k => editableColumns[k] === true);
    return Object.keys(model).map(k => `${k}=${obfuscationArr.includes(k) ? '********' : model[k]}`).join(', ');
}
export async function logToDatabase(logData) {
    // Set all empty values to empty string 
    Object.keys(logData).map(k => logData[k] = logData[k] ?? '');
    let { category: Category, action: Action, target: Target, description: Description } = logData;
    try {
        const MAX_USERNAME_LENGTH = 32;
        const MAX_CATEGORY_LENGTH = 64;
        const MAX_ACTION_LENGTH = 64;
        const MAX_TARGET_LENGTH = 64;
        const MAX_DESCRIPTION_LENGTH = 1024;
        let User = stateService.User;
        if (User.length > MAX_USERNAME_LENGTH) {
            AError.handle(`Username <${User}> length can't exceed ${MAX_USERNAME_LENGTH} characters`);
            User = User.substring(0, MAX_USERNAME_LENGTH);
        }
        if (Category.length > MAX_CATEGORY_LENGTH) {
            AError.handle(`Category <${Category}> length can't exceed ${MAX_CATEGORY_LENGTH} characters`);
            Category = Category.substring(0, MAX_CATEGORY_LENGTH);
        }
        if (Action.length > MAX_ACTION_LENGTH) {
            AError.handle(`Text <${Action}> length can't exceed ${MAX_ACTION_LENGTH} characters`);
            Action = Action.substring(0, MAX_ACTION_LENGTH);
        }
        if (Target.length > MAX_TARGET_LENGTH) {
            AError.handle(`Text <${Target}> length can't exceed ${MAX_TARGET_LENGTH} characters`);
            Target = Target.substring(0, MAX_TARGET_LENGTH);
        }
        if (Description.length > MAX_DESCRIPTION_LENGTH) {
            AError.handle(`Text <${Description}> length can't exceed ${MAX_DESCRIPTION_LENGTH} characters`);
            Description = Description.substring(0, MAX_DESCRIPTION_LENGTH);
        }
        await requestService.query({
            Query: (`INSERT INTO user_actions (User, Category, Action, Target, Description) VALUES (:User, :Category, :Action, :Target, :Description)`),
            Params: { User, Category, Action, Target, Description }
        });
        AError.sendLogStreamToCentralServer({ Level: 'Info', Message: Description });
    }
    catch (err) {
        console.error(err);
        AError.handle(`Couldn't log user changes!`);
    }
}
export class AOrm {
    static getChangesModel(form) {
        const oldData = form.formData;
        const newData = form.extractFormData({ cleanData: true, setInternalFormData: true });
        const compared = compareObjects(oldData, newData, { onlyUseRightKeys: true });
        const output = Object.assign({}, newData);
        Object.keys(output).map(key => {
            if (!compared.hasOwnProperty(key)) {
                delete output[key];
            }
        });
        // AEngine.log('Model Before', oldData)
        // AEngine.log('Changes', output)
        // AEngine.log('Model Current', newData)
        return { changes: output, model: newData };
    }
}
