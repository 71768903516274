import { AConfigComponent } from "./AConfigComponent.js";
export class AConfigFormComponent extends AConfigComponent {
    constructor(opt) {
        super(opt);
        this.opt = opt;
    }
    async preRender() {
        const cfgEntry = await configService.getVersioned(this.cfgPath);
        this.cfgData = cfgEntry ?? this.findConfigValueLegacy(this.cfgPath);
        this.cfgDataOriginal = JSON.parse(JSON.stringify(this.cfgData));
    }
    async postRender() {
        const { form, cfgItem } = this.opt;
        await form.generate({ translate: false, wrapInColumns: true });
        await form.injectFormData({ formData: cfgItem.CfgValue, triggerChange: true });
        await form.initFormValidation();
        form.attachTo(this.$ele());
        const $form = form.$form();
        $form.find(':input').on('change input', (originalEvent) => {
            // super.$ele().trigger('aci-change', [this])
            super.validate(form.toJSON());
        });
    }
    async render() {
        return ( /*html*/`<div comp="${this.getInternalId()}" cfg="${this.cfgPath}" class="column col-12"></div>`);
    }
    toJSON() {
        return this.CfgData;
    }
}
