import { AFormatDate, base64EncodingUTF8 } from "../utils/tools.js";
const CSV_SEPERATOR = ',';
export class ACSV {
    constructor(titles) {
        this.requiredLength = titles.length;
        this.values = [titles.map(title => `"${title}"`)];
    }
    add(values) {
        if (this.requiredLength !== values.length) {
            throw new Error(`CSV expected ${this.requiredLength} values, but instead got ${values.length}`);
        }
        values = values.map(value => value !== null && value.constructor.name === 'String' ? `"${value}"` : value);
        this.values.push(values);
        return this;
    }
    export() {
        return this.values
            .map(values => values.replace(/,/g, ''))
            .map(values => values.join(CSV_SEPERATOR))
            .map(values => values != null ? values : '')
            .join('\r\n');
    }
    createDownload(filename = 'detections') {
        const prefix = `data:application/csv;base64,`;
        const temp1 = this.export();
        const temp2 = prefix + base64EncodingUTF8(temp1);
        const encoded = encodeURI(temp2);
        const link = document.createElement("a");
        link.setAttribute("href", encoded);
        link.setAttribute("download", `${filename}_${AFormatDate(new Date(), '__', '_', '_')}.csv`);
        link.innerHTML = "Click Here to download";
        document.body.appendChild(link); // Required for FF
        link.click();
    }
}
