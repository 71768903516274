import { AError } from "../../classes/AError.js";
import { COLUMN_DATETIME, DATA_DATETIME } from "../../classes/AGridTypes.js";
import { ATemplates } from "../../core/ATemplateService.js";
import { AConvertToGridColumns, AConvertToGridData, AShowTable } from "../../utils/tools.js";
export class APage {
    constructor() {
        FilterManager.load();
        $('#RefreshButton').on('click', _ => this.refresh());
    }
    refresh() {
        FilterManager.setActive(false);
        const { Limit } = FilterManager.save();
        return Loading.waitForPromises(requestService.query({
            Query: (`
          SELECT
            ExecutionStartTime,
            ScriptName,
            Success,
            TIMEDIFF(ExecutionEndTime, ExecutionStartTime) as Duration,
            REPLACE(REPLACE(Error, '\r', ''), '\n', '') as Error,
            REPLACE(REPLACE(Script, '\r', ''), '\n', '') as Script                        
          FROM schema_update_scripts
          ORDER BY ExecutionStartTime DESC
          LIMIT :Limit
        `),
            Params: { Limit },
            Language: Language
        })).then(async (response) => {
            // Enable UI
            FilterManager.setActive(true);
            // Check whether there a result for the current query
            if (response.Rows.length == 0) {
                $('#export').attr('disabled', 'disabled');
                return Alerts.noResults();
            }
            this.grid = AShowTable({
                appendTo: 'table-bryntum',
                aci: { resizeToFit: false },
                columns: AConvertToGridColumns(response, {
                    'Success': { hidden: false },
                    'ExecutionStartTime': COLUMN_DATETIME
                }),
                data: AConvertToGridData(response, {
                    'ExecutionStartTime': DATA_DATETIME
                })
            });
            // Allow export button being pressed
            $('#export').removeAttr('disabled');
        }).catch(AError.handle);
    }
}
export function render() {
    return ( /*html*/`
    <div id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column c-scroll col-12">
        <div class="form-group">
          <label class="form-label" for="Limit">Max results</label>
          <input class="form-input" type="number" id="Limit" value="2000">
        </div>
      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </div>
    <div id="Rapport" class="flex-child bryntum-container has-footer-2">
      <div template="${ATemplates.WaitingForInput}"></div>
      <div id="table-bryntum" class="hidden"></div>

      <div class="columns footer aci">
        <div class="column col-2">
          <div id="count" class="text">Viewing <span>0</span> Queries</div>
        </div>
        <div class="column col-2 col-ml-auto">
          <button id="export" class="btn btn-primary col-12" disabled="disabled">Export</button>
        </div>
      </div>
    </div>
  `);
}
