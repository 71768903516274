import { ACSV } from "../../classes/ACSV.js";
import { AError } from "../../classes/AError.js";
import { ATemplates } from "../../core/ATemplateService.js";
import { AFormatDate } from "../../utils/tools.js";
export class APage {
    constructor() {
        FilterManager.load();
        $('#export').on('click', _ => this.exportCSV());
        $('#RefreshButton').on('click', _ => FilterManager.showFilterWarning().then(_ => this.refresh()));
    }
    fetchList(filters) {
        const VehicleType = $('#VehicleType option:selected').val();
        const GeoId = $('#GeoId').val();
        const ParkingRightType = $('#ParkingRightType').val() + '%';
        const { FromDate, ToDate, Limit } = filters;
        return requestService.query({
            Query: ( /*SQL*/`
        SELECT PrdbId, PrdbDeviceId, LicensePlate, VehicleType, ParkingRightType, StartTime, StopTime, GeoType, GeoId, Amount FROM prdb p INNER JOIN (
          SELECT prdbId, prdbdeviceId, MAX(PrdbVersion) PrdbVersion FROM prdb
          WHERE :FromDate < StopTime AND :ToDate > StartTime
          GROUP BY prdbId, prdbdeviceId
        ) t 
        USING (prdbId, prdbdeviceId, PrdbVersion)
        WHERE Removed = 0
        ${VehicleType !== '%' ? 'AND VehicleType=:VehicleType' : ''}
        ${GeoId !== '' && GeoId !== '0' ? 'AND GeoId=:GeoId' : ''}
        ${ParkingRightType !== '%' ? ' AND ParkingRightType LIKE :ParkingRightType' : ''}
        ORDER BY StartTime, LicensePlate
        LIMIT :Limit
      `),
            Name: `Prdb-list`,
            Params: {
                VehicleType,
                GeoId,
                ParkingRightType,
                FromDate,
                ToDate,
                Limit
            },
            Language: Language
        }).then((response) => {
            this.cachedResponse = Object.assign({}, response);
            return response;
        });
    }
    appendToTable($tbody, html) {
        const $row = $(html);
        $tbody.append($row);
        return $row;
    }
    exportCSV() {
        const csv = new ACSV(this.cachedResponse.ColumnsTranslated);
        const $collection = $('tr[identifier]');
        $collection.each((i, ele) => { csv.add($(ele).data('prdb')); });
        csv.createDownload('prdb');
    }
    refresh() {
        const filters = FilterManager.save();
        FilterManager.setActive(false);
        return Loading.waitForPromises(this.fetchList(filters)).then((response) => {
            FilterManager.setActive(true);
            if (!response.Rows.length) {
                $('#export').attr('disabled', 'disabled');
                return Alerts.noResults();
            }
            $('#export').removeAttr('disabled');
            console.log('response', response);
            // $('#count span').text(response.Rows.length)
            // PrdbId, PrdbDeviceId, LicensePlate, VehicleType, ParkingRightType, StartTime, StopTime, GeoType, GeoId, Amount
            const PrdbIdI = response.Columns.indexOf('PrdbId');
            const PrdbDeviceIdI = response.Columns.indexOf('PrdbDeviceId');
            const LicensePlateI = response.Columns.indexOf('LicensePlate');
            const VehicleTypeI = response.Columns.indexOf('VehicleType');
            const ParkingRightTypeI = response.Columns.indexOf('ParkingRightType');
            const StartTimeI = response.Columns.indexOf('StartTime');
            const StopTimeI = response.Columns.indexOf('StopTime');
            const GeoTypeI = response.Columns.indexOf('GeoType');
            const GeoIdI = response.Columns.indexOf('GeoId');
            const AmountI = response.Columns.indexOf('Amount');
            const $table = $('table');
            const $tbody = $table.find('tbody');
            $table.show();
            $('.empty').hide();
            $tbody.html(`
        <tr>
          <th>${response.ColumnsTranslated[LicensePlateI]}</th>
          <th>${response.ColumnsTranslated[VehicleTypeI]}</th>
          <th>${response.ColumnsTranslated[ParkingRightTypeI]}</th>
          <th>${response.ColumnsTranslated[StartTimeI]}</th>
          <th>${response.ColumnsTranslated[StopTimeI]}</th>
          <th>${response.ColumnsTranslated[GeoTypeI]}</th>
          <th>${response.ColumnsTranslated[GeoIdI]}</th>
          <th>${response.ColumnsTranslated[AmountI]}</th>
        </tr>
      `);
            for (let row of response.Rows) {
                const PrdbId = row[PrdbIdI];
                const PrdbDeviceId = row[PrdbDeviceIdI];
                const LicensePlate = row[LicensePlateI];
                const VehicleType = row[VehicleTypeI];
                const ParkingRightType = row[ParkingRightTypeI];
                const StartTime = AFormatDate(new Date(row[StartTimeI]));
                const StopTime = AFormatDate(new Date(row[StopTimeI]));
                const GeoType = row[GeoTypeI];
                const GeoId = row[GeoIdI];
                const Amount = row[AmountI];
                const identifier = `${PrdbId}_${PrdbDeviceId}`;
                const $row = this.appendToTable($tbody, `
          <tr identifier="${identifier}">
            <td>${LicensePlate}</td>
            <td>${VehicleType}</td>
            <td>${ParkingRightType}</td>
            <td>${StartTime}</td>
            <td>${StopTime}</td>
            <td>${GeoType}</td>
            <td>${GeoId}</td>
            <td>${Amount}</td>
          </tr>
        `);
                $row.data('prdb', [
                    PrdbId, PrdbDeviceId, LicensePlate, VehicleType, ParkingRightType, StartTime, StopTime, GeoType, GeoId, Amount
                ]);
            }
        }).catch(AError.handle);
    }
}
// export function css() {
//   return (/*html*/`
//     <style>
//     #Rapport table img {
//       width: 100%;
//       height: auto;
//     }
//     #Rapport table label {
//       font-size: 2em;
//       line-height: 1.3em;
//     }
//     .empty {
//       background: #fff;
//     }
//     .footer {
//       background: rgb(248, 248, 248);
//       border-top: 1px solid #eeeeee;
//       width: calc(100% + 8px);
//       padding: 7px 0;
//     }
//     .footer .text {
//       padding-left: 8px;
//       line-height: 35px;
//     }
//     </style>
//   `)
// }
export function css() {
    return ( /*html*/`
    <style>
    #Rapport table img {
      width: 100%;
      height: auto;
    }
    #Rapport table label {
      font-size: 2em;
      line-height: 1.3em;
    }
    .empty {
      background: #fff;
    }
    .footer {
      background: rgb(248, 248, 248);
      border-top: 1px solid #eeeeee;
      width: calc(100% + 8px);
      padding: 7px 0;
    }
    .footer .text {
      padding-left: 8px;
      line-height: 35px;
    }
    </style>
  `);
}
export function render() {
    return ( /*html*/`
    <div id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column col-12">
        <div class="form-group">
          <label class="form-label" for="FromDate">From</label>
          <input class="form-input" type="date" id="FromDate" required="required">
          <input class="form-input" type="time" id="FromTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="ToDate">To</label>
          <input class="form-input" type="date" id="ToDate" required="required">
          <input class="form-input" type="time" id="ToTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="VehicleType">VehicleType</label>
          <select id="VehicleType" type="text" class="form-select"></select>
        </div>

        <div class="form-group">
          <label class="form-label" for="GeoId">GeoId</label>
          <input class="form-input" id="GeoId" type="number" value="0" />
        </div>

        <div class="form-group">
          <label class="form-label" for="ParkingRightType">Parking Right Type</label>
          <select id="ParkingRightType" class="form-select">
            <option value="%">All</option>
          </select>
        </div>

        <div class="form-group">
          <label class="form-label" for="Limit">Max results</label>
          <input class="form-input" type="number" id="Limit" value="2000">
        </div>
      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </div>
    <div id="Rapport" class="flex-child bryntum-container has-footer-2">
      <div class="flex-content">
        <div template="${ATemplates.WaitingForInput}"></div>
        <table class="table table-striped" style="display: none">
          <tbody></tbody>
        </table>
      </div>
      <div class="columns footer aci">
        <div class="column col-2">
          <div id="count" class="text">Viewing <span>0</span> Rights</div>
        </div>
        <div class="column col-2 col-ml-auto">
          <button id="export" class="btn btn-primary col-12" disabled="disabled">Export</button>
        </div>
      </div>
    </div>
  `);
}
